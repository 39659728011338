import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './CinderellaPanelStyle.scss'
import moment from 'moment-timezone'

const DeadlineCountdown = ({ threshholdHours = 24 }) => {

    const pickDeadline = useSelector(state => state.cinderella.pickDeadline)
    const year = useSelector(state => state.cinderella.year)
    const [displayCountdown, setDisplayCountdown] = useState('')
    const [secondsLeft, setSecondsLeft] = useState(0)

    useEffect(() => {
        tick()
        let timerId = setInterval(() => tick(), 1000)
        return () => clearInterval(timerId)
    }, [])

    const tick = () => {
        if (pickDeadline && moment(pickDeadline).isValid()) {
            setDisplayCountdown(moment(pickDeadline).fromNow())
            setSecondsLeft(moment(pickDeadline).diff(moment(), 'seconds'))
        }
    }

    if (!pickDeadline || !moment(pickDeadline).isValid() || !year || secondsLeft <= 0 || secondsLeft > threshholdHours * 60 * 60) return null
    return (
        <div className={s.container}>
            <div className={s.header}>Pick Deadline</div>
            <div className={s.tally}>{displayCountdown}</div>
        </div>
    )
}

export default DeadlineCountdown
