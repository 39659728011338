// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OscarsAdmin__container___gwBfr .OscarsAdmin__dashboardButtonContainer___MtSpL button{margin-bottom:1.5rem}.OscarsAdmin__container___gwBfr .OscarsAdmin__posterDeck___T0zzg{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:flex-start}.OscarsAdmin__container___gwBfr .OscarsAdmin__posterDeck___T0zzg .OscarsAdmin__posterContainer___Sib4o{flex:0 0 160px;margin-bottom:1.5rem}.OscarsAdmin__container___gwBfr .OscarsAdmin__posterDeck___T0zzg .OscarsAdmin__posterContainer___Sib4o .OscarsAdmin__img___JHXFP{max-height:225px}.OscarsAdmin__container___gwBfr .OscarsAdmin__posterDeck___T0zzg .OscarsAdmin__posterContainer___Sib4o .OscarsAdmin__deleteButton___q9dEV{float:right;padding:1px 6px;margin-right:-10px;margin-top:-2px}`, "",{"version":3,"sources":["webpack://./src/routes/Oscars/Admin/OscarsAdmin.scss"],"names":[],"mappings":"AAGQ,sFACI,oBAAA,CAIR,iEACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,sBAAA,CAEA,uGACI,cAAA,CACA,oBAAA,CACA,iIACI,gBAAA,CAEJ,0IACI,WAAA,CACA,eAAA,CACA,kBAAA,CACA,eAAA","sourcesContent":[".container {\n\n    .dashboardButtonContainer {\n        button {\n            margin-bottom: 1.5rem;\n        }\n    }\n\n    .posterDeck {\n        display: flex;\n        flex-flow: row wrap;\n        justify-content: space-between;\n        align-items: flex-start;\n\n        .posterContainer {\n            flex: 0 0 160px;\n            margin-bottom: 1.5rem;\n            .img {\n                max-height: 225px;\n            }\n            .deleteButton {\n                float: right;\n                padding: 1px 6px;\n                margin-right: -10px;\n                margin-top: -2px;\n            }\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
export var container = `OscarsAdmin__container___gwBfr`;
export var dashboardButtonContainer = `OscarsAdmin__dashboardButtonContainer___MtSpL`;
export var posterDeck = `OscarsAdmin__posterDeck___T0zzg`;
export var posterContainer = `OscarsAdmin__posterContainer___Sib4o`;
export var img = `OscarsAdmin__img___JHXFP`;
export var deleteButton = `OscarsAdmin__deleteButton___q9dEV`;
export default ___CSS_LOADER_EXPORT___;
