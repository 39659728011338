import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, ListGroup } from 'react-bootstrap'
import moment from 'moment-timezone'
import * as s from './CinderellaEntries.scss'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { getEntries } from '@severed-links/common.redherrings-reducers/cinderella'
import RedHerringsPayPalButton from '../../Payment/RedHerringsPayPalButton'
import { range, forEach } from 'lodash'

const CinderellaEntryPaymentSummary = () => {

    const dispatch = useDispatch()
    const { _id: playerId } = useSelector(state => state.account)
    const { year, entries, pickDeadline } = useSelector(state => state.cinderella)
    const paymentInfo = entries?.paymentInfo || {}
    const [isInCheckingForPaymentPeriod, setIsInCheckingForPaymentPeriod] = useState(false)

    const onAuthorizedCinderellaPayment = () => {
        dispatch(createNotification({ headline: "Entry Payment", message: "Payment successful!", type: "success", timeout: 3000 }))
        fireOffEntryChecks()
    }

    const onCancelledCinderellaPayment = () => {
        dispatch(createNotification({ headline: "Entry Payment", message: "Payment cancelled!", type: "warning", timeout: 3000 }))
        fireOffEntryChecks()
    }

    const fireOffEntryChecks = () => {
        setIsInCheckingForPaymentPeriod(true)
        dispatch(getEntries())
        forEach(range(1, 10, 1), i => {
            setTimeout(() => {
                dispatch(getEntries())
            }, 3500 * i)
        })
        setTimeout(() => setIsInCheckingForPaymentPeriod(false), 3500 * 10)
    }

    const p = paymentInfo
    const afterDeadline = moment(pickDeadline).isBefore(moment(), 'min')
    if (afterDeadline && !p.balanceDue) return null
    return (
        <Card className={s.paymentCard}>
            <Card.Header className={s.header + ' ' + (p.balanceDue > 0.00 ? s.balanceDue : s.noBalanceDue)}><h4 className={s.headerTitle}>Payment Details</h4></Card.Header>
            <ListGroup className={s.list} variant='flush'>
                <ListGroup.Item className={s.item}>
                    <div className={s.description}>Entries</div>
                    <div className={s.amount}>{p.entries}</div>
                </ListGroup.Item>
                <ListGroup.Item className={s.item}>
                    <div className={s.description}>Entry Fee (per entry)</div>
                    <div>{p.entryFeeDisplay}</div>
                </ListGroup.Item>
                <ListGroup.Item className={s.item}>
                    <div className={s.description}>Total Due</div>
                    <div>{p.totalDueDisplay}</div>
                </ListGroup.Item>
                <ListGroup.Item className={s.item}>
                    <div className={s.description}>Prior Payments {p.refunds !== 0.00 ? <span style={{ marginLeft: '5px' }}>{' '}({p.refundsDisplay} refunded)</span> : null}</div>
                    <div>{p.paymentDisplay}</div>
                </ListGroup.Item>
                <ListGroup.Item className={s.item + ' ' + (p.balanceDue > 0.00 ? s.balanceDue : s.noBalanceDue)}>
                    <div className={s.description}>Balance Due</div>
                    <div>{p.balanceDueDisplay}</div>
                </ListGroup.Item>
            </ListGroup>
            <Card.Footer className={s.footer + ' ' + (afterDeadline ? s.afterDeadline : s.beforeDeadline) + ' ' + (p.balanceDue > 0.00 ? s.balanceDue : s.noBalanceDue)}>
                <div className={s.alert}>
                    <div>{!isInCheckingForPaymentPeriod || p.balanceDue === 0.00 ? p.message : 'Waiting for payment update...'}</div>
                    {p.balanceDue > 0.00 ? 
                    <div className={s.button}>
                        <RedHerringsPayPalButton
                            contest={"Cinderella"}
                            itemNumber={playerId}
                            receiverEmail={p.businessEmail}
                            description={`Cinderella ${year}`}
                            amount={p.balanceDue >= 0.00 ? p.balanceDue : 0.00}
                            year={year}
                            playerId={playerId}
                            onAuthorizedPayment={onAuthorizedCinderellaPayment}
                            onCancelledPayment={onCancelledCinderellaPayment}
                            disabled={isInCheckingForPaymentPeriod} />
                    </div>
                    : null}
                </div>
            </Card.Footer>
        </Card>
    )
}

export default CinderellaEntryPaymentSummary