import React, { useEffect, useState, useRef } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Button, FormGroup, InputGroup, FormControl, Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { ContentState, Editor, EditorState, RichUtils, AtomicBlockUtils } from 'draft-js'
import { stateToHTML } from 'draft-js-export-html'
import { stateFromHTML } from 'draft-js-import-html'
import 'draft-js/dist/Draft.css'
import * as s from './HtmlEditor.scss'
import { setEditorValue } from '@severed-links/common.redherrings-reducers/editors'
import { trim } from 'lodash'
import Modal from '@severed-links/common.modal'

const InsertedImage = (props) => {
    const entity = props.contentState.getEntity(props.block.getEntityAt(0))
    const { src } = entity.getData()
    const type = entity.getType() || ''

    let media = null
    if (type.toLowerCase() === 'image') {
      media = <Image rounded src={src} className={s.insertedImage} />
    } 
    return media
}

const HtmlEditor = ({ name = 'RedHerringsHtmlEditor', content = '', placeholder, showToolbar = true, isTall = false, noBorder = false, showImageButton = false }) => {

  const dispatch = useDispatch()
  const [editorState, setEditorState] = useState(EditorState.createEmpty())
  const [showUrlPrompt, setShowUrlPrompt] = useState(false)
  const [imageUrl, setImageUrl] = useState('')
  const _urlInput = useRef(null)

  useEffect(() => { setEditorState(trim(content) !== '' ? EditorState.createWithContent(stateFromHTML(content)) : EditorState.createEmpty()) }, [trim(content || '')])
  useEffect(() => { dispatch(setEditorValue(name, stateToHTML(editorState ? editorState.getCurrentContent() : EditorState.createEmpty()))) }, [editorState])
  useEffect(() => { showUrlPrompt ? setImageUrl('') : null }, [showUrlPrompt])

  const onEditorChange = _editorState => setEditorState(_editorState)

  const onShowUrlModal = () => {
    setShowUrlPrompt(true)
    setTimeout(() => _urlInput.current?.focus(), 50)
  }

  const onInsertImage = e => {
    e.preventDefault()
    const contentState = editorState.getCurrentContent()
    const contentStateWithEntity = contentState.createEntity('image', 'IMMUTABLE', { src: imageUrl })
    const entityKey = contentStateWithEntity.getLastCreatedEntityKey()
    const newEditorState = EditorState.set(editorState, { currentContent: contentStateWithEntity })
    setEditorState(AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, ' '))
    setShowUrlPrompt(false)
  }

  const onStyleClick = styleName => onEditorChange(RichUtils.toggleInlineStyle(editorState, styleName))

  const insertImageBlockRenderer = block => {
    if (block.getType() === 'atomic') {
      return {
        component: InsertedImage,
        editable: false,
      }
    }
    return null
  }

  return (
    <div className={s.editor + (showToolbar ? ` ${s.hasToolbar}` : ` ${s.noToolbar}`) + (noBorder ? ` ${s.noBorder}` : '')}>
      {showToolbar ?
      <div className={s.toolbar}>
        <Button size='sm' variant='light' tabIndex={-1} onClick={() => onStyleClick('BOLD')}><FontAwesomeIcon name='bold' /></Button>
        <Button size='sm' variant='light' tabIndex={-1} onClick={() => onStyleClick('ITALIC')}><FontAwesomeIcon name='italic' /></Button>
        <Button size='sm' variant='light' tabIndex={-1} onClick={() => onStyleClick('UNDERLINE')}><FontAwesomeIcon name='underline' /></Button>
        {showImageButton ? <Button size='sm' variant='light' tabIndex={-1} onClick={() => onShowUrlModal()}><FontAwesomeIcon name='image' /></Button> : null}
      </div> : null}
      <div className={s.editorContainer + (isTall ? ` ${s.tall}` : '')}>
        <Editor editorState={editorState} 
            placeholder={placeholder}
            onChange={e => onEditorChange(e)}
            blockRendererFn={insertImageBlockRenderer} />
      </div>

      <Modal show={showUrlPrompt} onClose={() => setShowUrlPrompt(false)}
        heading={`Enter Image URL`}
        actionButtonText='insert image from URL'
        actionButtonIcon='image'
        actionButtonDisabled={!imageUrl}
        actionButtonOnClick={e => onInsertImage(e)}>
        <FormGroup>
          <InputGroup>
            <InputGroup.Text><FontAwesomeIcon name='image' /></InputGroup.Text>
            <FormControl value={imageUrl} ref={_urlInput}
              onChange={e => setImageUrl(e.target.value)} />
          </InputGroup>
        </FormGroup>
      </Modal>
    </div>
  )
}

export default HtmlEditor
