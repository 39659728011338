import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Table } from 'react-bootstrap'
import * as s from './EnterPicksGameMatchupTable.scss'

const EnterPicksGameMatchupTable = ({ matchUp, homeFavorite = true }) => {

    if (!matchUp) return null
    return (
        <div>
            <Table size='sm' className={s.table}>
                <thead>
                    <tr>
                        <th>Overall</th>
                        <th>{homeFavorite ? "Home" : "Away"}</th>
                        <th>&nbsp;</th>
                        <th>{homeFavorite ? "Away" : "Home"}</th>
                        <th>Overall</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>{matchUp.favoriteRecord}</td>
                        <td>{matchUp.favoriteRecordAH}</td>
                        <td><strong>Record</strong></td>
                        <td>{matchUp.underdogRecordAH}</td>
                        <td>{matchUp.underdogRecord}</td>
                    </tr>
                    <tr>
                        <td>{matchUp.favoriteSpreadRecord}</td>
                        <td>{matchUp.favoriteSpreadRecordAH}</td>
                        <td><strong>vs. Spread</strong></td>
                        <td>{matchUp.underdogSpreadRecordAH}</td>
                        <td>{matchUp.underdogSpreadRecord}</td>
                    </tr>
                    <tr>
                        <td>{matchUp.favoritePF.toFixed(1)}</td>
                        <td>{matchUp.favoritePFAH.toFixed(1)}</td>
                        <td><strong>Offense</strong></td>
                        <td>{matchUp.underdogPFAH.toFixed(1)}</td>
                        <td>{matchUp.underdogPF.toFixed(1)}</td>
                    </tr>
                    <tr>
                        <td>{matchUp.favoritePA.toFixed(1)}</td>
                        <td>{matchUp.favoritePAAH.toFixed(1)}</td>
                        <td><strong>Defense</strong></td>
                        <td>{matchUp.underdogPAAH.toFixed(1)}</td>
                        <td>{matchUp.underdogPA.toFixed(1)}</td>
                    </tr>
                    <tr>
                        <td>{matchUp.favoriteSOS}</td>
                        <td></td>
                        <td><strong>SOS</strong></td>
                        <td></td>
                        <td>{matchUp.underdogSOS}</td>
                    </tr>
                </tbody>
            </Table>
        </div>
    )
}
    
export default EnterPicksGameMatchupTable