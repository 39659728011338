import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, FormLabel, FormControl, Alert } from 'react-bootstrap'
import { isEmpty } from 'validator'
import * as s from './SignUp.scss'
import { checkPassphrase, setPassphrase, setUserInfo } from '@severed-links/common.redherrings-reducers/siteRegistration'

const PassphraseStep1 = forwardRef(({ jumpToStep }, _ref) => {

    const dispatch = useDispatch()
    const siteRegistration = useSelector(state => state.siteRegistration)
    const [passphrase, doSetPassphrase] = useState('')
    const _isValid = siteRegistration.passphraseIsValid && !isEmpty(siteRegistration.contest || '')

    useEffect(() => {
        if (siteRegistration.nextDisabled !== !_isValid) {
            dispatch(setUserInfo({ nextDisabled: !_isValid }))
        }
    }, [siteRegistration.nextDisabled, _isValid])

    useEffect(() => { setPassphrase(siteRegistration.passphrase || '') }, [siteRegistration.passphrase || ''])

    const handleChange = e => {
        const _passphrase = e.target.value
        doSetPassphrase(_passphrase)
        dispatch(setPassphrase(_passphrase))
        if (_passphrase && _passphrase.length >= 5) {
            dispatch(checkPassphrase(_passphrase))
        }
    }

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValid
    }))

    if (!siteRegistration) return null
    return (
        <div className={s.step1 + ' ' + s.step}>
            <FormGroup className={s.passphraseContainer}>
                <FormLabel>Enter your contest passphrase</FormLabel>
                <FormControl size='lg' autoFocus autoComplete='off' type='text' 
                    name='passphrase' value={passphrase} 
                    required isValid={siteRegistration.passphraseIsValid}
                    isInvalid={!siteRegistration.passphraseIsValid}
                    onChange={e => handleChange(e)} />
                <FormControl.Feedback />
                <div className={s.alertContainer}>
                    {_isValid ?
                    <Alert variant='success'>This is a valid passphrase for the {siteRegistration.contest} contest.</Alert>
                    : null}
                </div>
            </FormGroup>
        </div>
    )
})

export default PassphraseStep1
