import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { ButtonGroup, Button, ButtonToolbar } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Confirm from '@severed-links/common.confirm'
import { setLeagueSchedulerState, saveLeagueSchedulerSchedule } from '@severed-links/common.redherrings-reducers/footballAdmin'
import moment from 'moment-timezone'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import * as s from './LeagueSchedulerControls.scss'

const LeagueSchedulerControls = ({ seasonId = null, seasonName = null, isRunning = false, isDone = false, scheduleToSave = {}, createAutoPicks = false, WAIT_MS = 20 }) => {

    const dispatch = useDispatch()

    const startScheduler = () => dispatch(setLeagueSchedulerState(seasonId, { isRunning: true, startTime: moment() }))
    const stopScheduler = () => dispatch(setLeagueSchedulerState(seasonId, { isRunning: false }))

    const saveSeasonSchedule = () => {
        if (scheduleToSave) {
            dispatch(saveLeagueSchedulerSchedule(seasonId, scheduleToSave, createAutoPicks))
            .then(action => dispatch(createNotification({ type: action.payload.messageType, 
                headline: 'Save League Schedule', 
                message: action.payload.message, 
                timeout: 6000 
            })))
        } else {
            dispatch(createNotification({ type: 'error', 
                headline: 'Save Team Data', 
                message: 'No schedule data to save.', 
                timeout: 6000 
            }))
        }
    }


    return (
        <ButtonToolbar size='sm' className={s.container}>
            <ButtonGroup className={s.buttonGroup}>
                <Button className={s.button} variant='primary' disabled={isRunning} onClick={() => startScheduler()}><FontAwesomeIcon name='play' /></Button>
                <Button className={s.button} variant='danger' disabled={!isRunning} onClick={() => stopScheduler()}><FontAwesomeIcon name='stop' /></Button>
            </ButtonGroup>
            <ButtonGroup className={s.buttonGroup}>
                <Confirm variant='info' onConfirm={() => saveSeasonSchedule()}
                    title={'League Scheduler -- Save Generated Schedule'}
                    confirmText='save season schedule'
                    body={'Are you sure you want to save this generated schedule?'}
                    buttonClassName={s.button} disabled={!isDone}
                    buttonIcon='check' />
            </ButtonGroup>
        </ButtonToolbar>
    )
}

export default LeagueSchedulerControls