import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Row, Col, FormLabel, FormControl, Badge, FormGroup, InputGroup, InputGroupAddon, Alert, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import YearSelector from '../../Oscars/YearSelector'
import { getRegions, getRounds, saveRoundsAndRegions } from '@severed-links/common.redherrings-reducers/cinderellaAdmin'
import { getDetails } from '@severed-links/common.redherrings-reducers/cinderella'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import moment from 'moment-timezone'
import * as s from './CinderellaRegionRoundConfig.scss'

const CinderellaRegionRoundConfig = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const [year, setYear] = useState(moment().year())
    const [rounds, setRounds] = useState([])
    const [regions, setRegions] = useState([])

    useEffect(() => { getData() }, [year])

    const getData = () => {
        dispatch(getRegions(year))
        .then(action => setRegions(action.payload))
        dispatch(getRounds(year))
        .then(action => setRounds(action.payload))
    }

    const changeYear = _year => setYear(parseInt(_year))

    const updateRegionName = (e, index) => {
        var _regions = [...regions]
        _regions[index].regionName = e.target.value
        setRegions(_regions)
    }

    const updateRoundName = (e, index) => {
        var _rounds = [...rounds]
        _rounds[index].roundName = e.target.value
        setRounds(_rounds)
    }

    const updateRoundMultiplier = (e, index) => {
        var _rounds = [...rounds]
        _rounds[index].multiplier = e.target.value
        setRounds(_rounds)
    }

    const save = () => {
        const postData = { regions, rounds: rounds.map(r => ({ ...r, multiplier: parseInt(r.multiplier) })) }
        dispatch(saveRoundsAndRegions(postData))
        .then(action => {
            dispatch(createNotification({ headline: action.payload.subject, message: action.payload.message, type: action.payload.messageType, timeout: 5000 }))
            dispatch(getDetails())
            navigate('/cinderella/admin')
        })
    }

    return (
        <div className={s.container}>
            <div className={s.regionConfigEditYearSelectorContainer}>
                <YearSelector defaultYear={year} onChangeYear={changeYear} />
            </div>
            <Row>
                <Col xs={6}>
                    <h4>{year} Region Configuration</h4>
                    {regions.map((r, index) =>
                        <FormGroup key={r.region}>
                            <InputGroup>
                                <InputGroup.Text>{r.region}</InputGroup.Text>
                                <FormControl name={'region' + r.region} 
                                    onChange={e => updateRegionName(e, index)} 
                                    value={regions[index].regionName} />
                            </InputGroup>
                        </FormGroup>
                    )}
                    <Alert variant='info'>
                        <strong>Note:</strong> Make sure that region #1 plays region #2 and region #3 plays region #4 in the Final Four&reg;.
                    </Alert>
                </Col>
                <Col xs={6}>
                    <h4>{year} Round Configuration</h4>
                    {rounds.map((r, index) =>
                    <Row key={r.round}>
                        <Col sm={8} xs={12}>
                            <FormGroup key={r.round}>
                                <InputGroup>
                                    <InputGroup.Text>{r.round}</InputGroup.Text>
                                    <FormControl name={'round_name' + r.round} 
                                        onChange={e => updateRoundName(e, index)} 
                                        value={rounds[index].roundName} />
                                </InputGroup>
                            </FormGroup>
                        </Col>
                        <Col sm={4} xs={12}>
                            <FormGroup key={r.round}>
                                <InputGroup>
                                    <FormControl name={'round_multiplier' + r.round} 
                                        onChange={e => updateRoundMultiplier(e, index)} 
                                        style={{ textAlign: 'right' }}
                                        value={rounds[index].multiplier} />
                                    <InputGroup.Text>x</InputGroup.Text>
                                </InputGroup>
                            </FormGroup>
                        </Col>
                    </Row>
                    )}
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Button variant='primary' onClick={() => save()}><FontAwesomeIcon name='check' /> save regions/rounds</Button>
                </Col>
            </Row>
        </div>
    )
}

export default CinderellaRegionRoundConfig