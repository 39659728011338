import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Button, Badge, Table, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import * as s from './CheckPlayerPickStatus.scss'
import { checkPlayerPickStatus } from '@severed-links/common.redherrings-reducers/footballAdmin'
import PlayerTeamListItem from '../PlayerTeamListItem'
import moment from 'moment-timezone'
import { FRIENDLY_SHORT_TIME_FORMAT, FRIENDLY_SHORT_DATE_FORMAT } from '@severed-links/common.redherrings-constants'
import { startsWith } from 'lodash'

const CheckPlayerPickStatus = ({ show = false, onClose = () => void(0) }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const seasons = useSelector(state => state.football.seasons)
    const seasonName = params.seasonName || null
    const season = seasons.find(i => i.seasonName === seasonName) || {}
    const seasonId = season.seasonId || null
    const weekParam = (params.week || '0').toLowerCase()
    const isPlayoffWeek = location.pathname.toLowerCase().indexOf('postseason') > -1
    const seasonMode = isPlayoffWeek ? 'Postseason' : 'Regular'
    const week = parseInt(weekParam || '0')
    const _playerPickStatuses = useSelector(state => state.footballAdmin.playerPickStatus) || []
    const playerPickStatus = (_playerPickStatuses.find(i => i.seasonName === seasonName && i.isPlayoffWeek === isPlayoffWeek && i.week === week) || {}).playerPickStatus || []

    useEffect(() => { getData() }, [seasonId, seasonMode, week])

    const getData = () => {
        if (seasonId && seasonMode && week) {
            dispatch(checkPlayerPickStatus(seasonId, seasonMode, week))
        }
    }

    return (
        <div>
            <Modal heading={`Check Player Pick Status: ${seasonName} ${seasonMode} Week ${week}`}
                show={show}
                onClose={onClose ? () => onClose() : null}>
                <div className={s.status}>
                    <ListGroup className={s.statusList}>
                    {playerPickStatus && playerPickStatus.map(i =>
                    <PlayerTeamListItem key={`player-pick-status-item-${seasonMode}-${week}-${i.teamId}`}
                        teamId={i.teamId} teamName={i.teamName} avatarUrl={i.avatarUrl}
                        right={<div className={s.rightContainer}>
                            {i.isAutoPicks ? <div className={s.autoPickBadge}><Badge bg={'info'}>Auto</Badge></div> : null}
                            <div className={s.date}>
                                <div>{moment(i.lastPickDate).format(FRIENDLY_SHORT_DATE_FORMAT)}</div>
                                <div>{moment(i.lastPickDate).format(FRIENDLY_SHORT_TIME_FORMAT)}</div>
                            </div>                                    
                        </div>} />
                    )}
                    </ListGroup>
                </div>
            </Modal>
        </div>
    )
}

export default CheckPlayerPickStatus