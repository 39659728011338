// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RedHerringsPayPalButton__container___VNIWZ{margin-bottom:0px}.RedHerringsPayPalButton__paymentButton___dIbIg{white-space:nowrap}`, "",{"version":3,"sources":["webpack://./src/routes/Payment/RedHerringsPayPalButton.scss"],"names":[],"mappings":"AAAA,4CACI,iBAAA,CAGJ,gDACI,kBAAA","sourcesContent":[".container {\n    margin-bottom: 0px;\n}\n\n.paymentButton {\n    white-space: nowrap;\n}"],"sourceRoot":""}]);
// Exports
export var container = `RedHerringsPayPalButton__container___VNIWZ`;
export var paymentButton = `RedHerringsPayPalButton__paymentButton___dIbIg`;
export default ___CSS_LOADER_EXPORT___;
