import React, { useEffect, useState } from 'react'
import { Table, ListGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import * as s from './CaptureWinners.scss'
import NCAATeamIcon from '../Cinderella/NCAATeam/NCAATeamIcon'

const WinnerList = ({ winners = [], showHeader = false }) => (
    winners && winners.length ?
    <div>
    {showHeader ? <h4>Results</h4> : null}
    <ListGroup className={s.captureWinnersList}>
    {winners.map(w => (
    <ListGroup.Item className={s.item} key={`winner-${w.code}`}>
        <div className={s.description}>{w.description}</div>
        {w.players && w.players.map(p =>
        <div className={s.playerContainer} key={`winner-${w.code}-player-${p._id}`}>
            <div className={s.teamIconContainer}>
            {p.teams && p.teams.map(t =>
            <div className={s.teamIcon} key={`winner-${w.code}-player-${p._id}-team-${t._id}`}>
                <NCAATeamIcon {...t} size='sm' />
            </div>
            )}
            </div>
            <div className={s.playerName}>{p.playerName}</div>
        </div>
        )}
        {!w.players || !w.players.length ?
        <div className={s.playerName + ' ' + s.notAwarded}>Not awarded yet.</div> : null}
    </ListGroup.Item>
    ))}
    </ListGroup>
    </div>
    : null
)

const CaptureWinners = ({ winners = [] }) => {

    const [show, setShow] = useState(false)

    return (
        <div className={s.container}>
            <div className={s.showLarge}>
                <WinnerList winners={winners} showHeader />
            </div>
            <Button variant='info' className={s.modalButton} 
                onClick={() => setShow(true)}>
                <FontAwesomeIcon name='trophy' />
                {' '}
                <span>show results</span>
            </Button>
            <Modal show={show} onClose={() => setShow(false)}
                heading={'Results'} showFooter={false}>
                <WinnerList winners={winners} />
            </Modal>
        </div>
    )
}

export default CaptureWinners