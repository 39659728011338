import React, { useEffect } from 'react'
import CloudinaryImage from '../Cloudinary/CloudinaryImage'
import { Badge, Image } from 'react-bootstrap'
import * as s from './Player.scss'
import { playerUrl } from '@severed-links/common.redherrings-constants'

const Player = ({ playerId, firstName = '', lastName = '', fullName = '', avatarUrl = null, isLarge = false, onlyAvatar = false, onlyName = false }) => {

    return (
        <span key={playerId} className={s.playerContainer + ' ' + (isLarge ? s.isLarge : null)} title={fullName || `${firstName} ${lastName}`}>
            {onlyAvatar || !onlyName ?
            <Image className={s.playerAvatar} src={playerUrl(avatarUrl)} alt={fullName || `${firstName} ${lastName}`} />
            : null}
            {!onlyAvatar || onlyName ?
            <span className={s.playerName}>
                {fullName || `${firstName} ${lastName}`}
            </span> 
            : null}
        </span>
    )
}

export default Player