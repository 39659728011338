import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import CaptureContestPicker from './CaptureContestPicker'
import NCAATeam from '../../Cinderella/NCAATeam/NCAATeam'
import { getTeamDrawPrint } from '@severed-links/common.redherrings-reducers/captureAdmin'
import * as s from './CaptureTeamDraw.scss'

const CaptureTeamDrawPrint = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const [contest, setContest] = useState(null)
    const [teams, setTeams] = useState([])
    const contestId = params.contestId || null

    useEffect(() => { loadData() }, [contestId])

    const loadData = () => {
        dispatch(getTeamDrawPrint(contestId))
        .then(action => {
            setContest(action.payload.contest)
            setTeams(action.payload.teams)
        })
    }

    return (
        <div className={s.container}>
            <Row>
                <Col xs={12} className='hidden-print'>
                    <h2>
                        <span style={{ float: 'right' }}><CaptureContestPicker /></span>
                        Team Draw Print: {contest ? contest.contestName : null}
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                {teams && teams.map(t => 
                    <div className={s.printContainer} key={t.teamId}>
                        <h4 className='text-muted text-center'>{t.regionName} Region</h4>
                        <NCAATeam teamId={t.teamId} name={t.teamName} 
                            size='lg'
                            showAvatar avatarUrl={t.avatarUrl}
                            seed={t.seed} />
                    </div>
                )}
                </Col>
            </Row>
        </div>
    )
}

export default CaptureTeamDrawPrint