// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ManageUsersLetterBar__letterBar___ViHEh{margin-bottom:10px}.ManageUsersLetterBar__letterBar___ViHEh .ManageUsersLetterBar__letterPill___j0h3f{padding:4px 8px}`, "",{"version":3,"sources":["webpack://./src/routes/Main/Admin/ManageUsersLetterBar.scss"],"names":[],"mappings":"AAAA,yCACI,kBAAA,CACA,mFACI,eAAA","sourcesContent":[".letterBar {\n    margin-bottom: 10px;\n    .letterPill {\n        padding: 4px 8px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var letterBar = `ManageUsersLetterBar__letterBar___ViHEh`;
export var letterPill = `ManageUsersLetterBar__letterPill___j0h3f`;
export default ___CSS_LOADER_EXPORT___;
