import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ListGroup, Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './PlayerListItem.scss'
import { playerUrl } from '@severed-links/common.redherrings-constants'
import { startsWith, endsWith } from 'lodash'

const PlayerListItem = ({ player, badge = null, detail = null, nameBadge = null, leftDetail = null, rightDetail = null, onClick = null, variant = '', className = '', children = null }) => {

    if (!player) return null
    const { firstName, lastName, fullName, avatarUrl } = player
    return (
        <ListGroup.Item className={s.container + (className ? ` ${className}` : '')} tabIndex={-1}
            {...(variant ? { variant } : {})}
            action={!!onClick} onClick={onClick ? onClick : void(0)}>
            <div className={s.player}>
                {leftDetail ? <div className={s.leftDetail}>{leftDetail}</div> : null}
                <div className={s.avatar} style={avatarUrl ? { backgroundImage: `url(${playerUrl(avatarUrl)})` } : {}}>
                    &nbsp;
                </div>
                <div className={s.nameOverallContainer}>
                    <div className={s.nameContainer}>
                        <div className={s.firstName}>{firstName} {badge}</div>
                        <div className={s.lastName}>{lastName || fullName}</div>
                        {detail ? <div className={s.detail}>{detail}</div> : null}
                    </div>
                    <div className={s.nameBadge}>{nameBadge}</div>
                </div>
                {rightDetail ? <div className={s.rightDetail}>{rightDetail}</div> : null}
            </div>
            {children}
        </ListGroup.Item>
    )
}

export default PlayerListItem
