import React, { useState, useEffect, useLayoutEffect } from 'react'
import { Alert, Button, Image } from 'react-bootstrap'
import { useNavigate, useParams } from 'react-router-dom'
import moment from 'moment-timezone'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './DeadlineAlertContainer.scss'
import { teamUrl } from '@severed-links/common.redherrings-constants'

const DeadlineAlertContainer = ({ deadline, teamId, teamName, avatarUrl, showPickButton = false, postseason = false, setPickTeamId }) => {

    const navigate = useNavigate()
    const params = useParams()
    const [deadlineHours, setDeadlineHours] = useState(99)
    const [deadlineMinutes, setDeadlineMinutes] = useState(99)
    const [deadlineSeconds, setDeadlineSeconds] = useState(99)
    const [deadlineDisplay, setDeadlineDisplay] = useState({})
    const [pickDeadlineDateDisplay, setPickDeadlineDateDisplay] = useState('')
    const [pickDeadlineTimeDisplay, setPickDeadlineTimeDisplay] = useState('')
    const [pickDeadlineFromNow, setPickDeadlineFromNow] = useState('')
    const [alertStyle, setAlertStyle] = useState('info')
    const afterDeadline = deadline && moment(deadline).isValid() ? moment().isSameOrAfter(moment(deadline), 'second') : true
    const seasonName = params.seasonName || ''
    const redirectPath = seasonName ? `/football/${!postseason ? 'regular-season' : 'postseason'}/${seasonName}/enter-picks` : null

    useLayoutEffect(() => {
        tick()
        let timerId = setInterval(() => tick(), 10 * 1000)
        return () => clearInterval(timerId)
    }, [])

    useLayoutEffect(() => { tick() }, [deadline, teamId])

    const tick = () => {
        var _duration = moment.duration(moment(deadline).diff(moment(), 'second'))
        var _deadlineHours = Math.round(_duration.asHours(), 0)
        var _deadlineMinutes = Math.round(_duration.asMinutes())
        var _deadlineSeconds = Math.round(_duration.asSeconds())
        var _deadlineDisplay = moment.duration(_deadlineSeconds, 'seconds')
        var _pickDeadlineDateDisplay = moment(deadline).local().format('dddd, MMMM d, YYYY')
        var _pickDeadlineTimeDisplay = moment(deadline).local().format('h:mm A')
        var _pickDeadlineFromNow = moment(deadline).local().fromNow()
        var _alertStyle = deadlineMinutes > 60 ? 'info' : 'danger'

        setDeadlineHours(_deadlineHours)
        setDeadlineMinutes(_deadlineMinutes)
        setDeadlineSeconds(_deadlineSeconds)
        setDeadlineDisplay(_deadlineDisplay)
        setPickDeadlineDateDisplay(_pickDeadlineDateDisplay)
        setPickDeadlineTimeDisplay(_pickDeadlineTimeDisplay)
        setPickDeadlineFromNow(_pickDeadlineFromNow)
        setAlertStyle(_alertStyle)
    }

    const onClick = () => {
        if (setPickTeamId) setPickTeamId(teamId)
        if (redirectPath) navigate(redirectPath) 
    }

    return (
        !afterDeadline ? 
        <Alert variant={alertStyle} className={s.container}>
            <div className={s.content}>
                {avatarUrl ? <div className={s.avatar}><Image src={teamUrl(avatarUrl)} /></div> : null}
                <div className={s.teamName}>
                    <strong>{teamName}:</strong> Pick deadline is {pickDeadlineFromNow}
                </div>
                {showPickButton && !afterDeadline ?
                <div className={s.control}>
                    <Button variant={alertStyle} onClick={() => onClick()}>
                        <FontAwesomeIcon name='check' />
                        {' '}
                        enter my picks
                    </Button>
                </div>
                : null}
            </div>
            
        </Alert>
        : null
    )
}

export default DeadlineAlertContainer