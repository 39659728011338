import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ListGroup, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './PlayerTeamListItem.scss'
import { img, teamUrl } from '@severed-links/common.redherrings-constants'

const PlayerTeamListItem = ({
    playerId = null, teamId = null, teamNameRaw, teamName = '', avatarUrl = null, 
    playoffSeed, variant = null, showPlayoffSeed = false,
    showAvatar = true, miniHeader = null, week = null, division = null, showDivision = false, 
    left = null, middle = null, right = null, detail = null, icon = null, rank = null,
    showRank = false, showWeek = false, size = 'md', onClick = undefined,
    clickDisabled = false, className = null, 
}) => {

    const navigate = useNavigate()

    const handleClick = () => {
        if (!onClick || typeof onClick !== 'function') {
            navigate(`/football/team-profile/${teamId}`)
        } else {
            onClick()
        }
    }

    return (
        <ListGroup.Item  tabIndex={-1} className={s.container + ' ' + s[size] + (className ? ` ${className}` : '')}
            action={!clickDisabled} onClick={!clickDisabled ? () => handleClick() : null} 
            variant={variant} title={teamNameRaw}>
            {miniHeader ? <div className={s.miniHeader}>{miniHeader}</div> : null}
            <div className={s.content}>
                {showRank && rank ? <div className={s.rankContainer}>{rank}</div> : null}
                {showWeek && week ? <div className={s.weekContainer}><div className={s.weekHeader}>Week</div><div className={s.weekNumber}>{week}</div></div> : null}
                {left ? <div className={s.leftContainer}>{left}</div> : null}
                {showAvatar ? <div className={s.avatarContainer} style={{ backgroundImage: `url(${teamUrl(avatarUrl)})` }} /> : null}
                <div className={s.teamNameSuperContainer}>
                    <div className={s.teamNameContainer}>
                        <div className={s.teamName}>
                            {teamName}
                            {' '}
                            {showPlayoffSeed && playoffSeed ? <Badge bg='info' className={s.badge}>{playoffSeed}</Badge> : null}
                        </div>
                        {showDivision ? <div className={s.division}>{division} Division</div> : null}
                        {detail ? <div className={s.detail}>{detail}</div> : null}
                    </div>
                    {middle ? <div className={s.middle}>{middle}</div> : null}
                </div>
                {right ? <div className={s.rightContainer}>{right}</div> : null}
                {icon ? <div className={s.iconContainer}>{icon}</div> : null}
            </div>
        </ListGroup.Item>
    )
}


export default PlayerTeamListItem
