import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import PlayerListItem from '../../Player/PlayerListItem'
import * as s from './CaptureAdmins.scss'

const CaptureAdmins = () => {

    const admins = useSelector(state => state.captureAdmin.admins || [])

    return (
        <ListGroup className={s.container}>
            <h4>Capture Admins</h4>
            {admins && admins.map((p, index) => (
                <PlayerListItem player={p} 
                    rightDetail={<span>{p.userName}</span>}
                    key={`capture-admin-${p._id}-${index}`} />
            ))}
        </ListGroup>
    )
}

export default CaptureAdmins