import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col, Alert } from 'react-bootstrap'
import { getNflPlayoffScoreboard } from '@severed-links/common.redherrings-reducers/football'
import { find, filter } from 'lodash'
import moment from 'moment-timezone'
import NFLScoreboardGame from './NFLScoreboardGame'
import SeasonSelector from '../../SeasonSelector'
import * as s from './NFLScoreboard.scss'
import { getFootballUrlParams } from '@severed-links/common.redherrings-constants'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'

const NFLPlayoffs = ({ showHeading = true }) => {

    const dispatch = useDispatch()
    const params = useParams()
    const seasons = useSelector(state => state.football.seasons)
    const nflPlayoffScoreboards = useSelector(state => state.football.nflPlayoffScoreboards)
    const c = getFootballUrlParams(params, seasons)
    const sb = find(nflPlayoffScoreboards, x => x.seasonId === c.seasonId)

    useEffect(() => { c.seasonId ? dispatch(getNflPlayoffScoreboard(c.seasonId)) : null }, [c.seasonId])

    return (
            <div className={s.container}>
                {showHeading ?
                <div className={s.heading}>
                    <h2 className={s.title}>{c.seasonName} NFL Playoffs</h2>
                    <div className={s.controls}>
                        <span className={s.seasonSelector}><SeasonSelector seasons={seasons} season={c.season} /></span>
                    </div>
                </div>
                : null}
                <div className={s.gamesContainer}>
                {sb && sb.weeks && filter(sb.weeks || [], w => w.games && w.games.length > 0).map((d, index) => 
                    <div className={s.dayContainer} key={`nfl-playoffs-week-${d.heading}`}>
                        <h3>{d.heading}</h3>
                        <Row>
                        {d.games && d.games.map((g, index) => 
                            <Col md={4} sm={6} xs={12} key={'playoff-game-' + index}>
                                <h4>{moment(g.date).local().format('dddd, MMMM Do')}</h4>
                                <NFLScoreboardGame game={g} seasonName={sb.seasonName} seasonMode={sb.seasonMode} showLine />
                            </Col>
                        )}
                        </Row>
                    </div>
                )}
                {!sb ? <div className={s.spinnerContainer}><FontAwesomeIcon name='circle-notch' spin size='3x' /></div> 
                :
                !sb.weeks || filter(sb.weeks || [], w => w.games && w.games.length > 0).length === 0 ? 
                    <Alert variant='info'>No {c.seasonName} playoff games located.</Alert>
                : null}
                </div>
            </div>
    )
}

export default NFLPlayoffs