import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Player from '../../Player/Player'
import PlayerTeam from '../PlayerTeam'
import Modal from '@severed-links/common.modal'
import TeamDataEditTeam from './TeamDataEditTeam'
import PlayerTeamListItem from '../PlayerTeamListItem'
import { setTeamDataCloseOnSave } from '@severed-links/common.redherrings-reducers/footballAdmin'
import * as s from './TeamDataTeamList.scss'

const TeamDataTeamList = ({ teams = [], divisions = [], players = [] }) => {

    const dispatch = useDispatch()
    const teamDataCloseOnSave = useSelector(state => state.footballAdmin.teamDataCloseOnSave)
    const [teamToEdit, setTeamToEdit] = useState({})
    const [showEditTeam, setShowEditTeam] = useState(false)

    useEffect(() => {
        if (teamDataCloseOnSave) {
            handleResetEditTeam()
            dispatch(setTeamDataCloseOnSave(false))
        }
    }, [teamDataCloseOnSave])

    const handleShowEditTeam = _teamToEdit => {
        setTeamToEdit(_teamToEdit)
        setShowEditTeam(true)
    }

    const handleResetEditTeam = () => {
        setTeamToEdit({})
        setShowEditTeam(false)
    }

    return (
        <div className={s.container}>
            <ListGroup>
            {teams && teams.map(t => 
                <PlayerTeamListItem key={t._id}
                    teamId={t._id} teamName={t.teamName} avatarUrl={t.avatarUrl}
                    teamNameRaw={t.teamName}
                    right={<Badge className={s.divisionName} bg='light' text='dark'>{t.divisionName}</Badge>}
                    detail={<Player playerId={t.playerId} firstName={t.firstName} lastName={t.lastName} fullName={t.fullName} avatarUrl={t.playerAvatarUrl} />}
                    icon={<span><FontAwesomeIcon name={t.paid ? 'check' : 'times'} /></span>}
                    onClick={() => handleShowEditTeam(t)}
                    />   
            )}
            </ListGroup>

            <Modal heading={'Edit Team Data: ' + teamToEdit.teamName}
                show={showEditTeam}
                onClose={() => handleResetEditTeam()}
                actionButtonText={null}>
                <TeamDataEditTeam editTeam={teamToEdit} divisions={divisions} players={players} />
            </Modal>

        </div>
    )
}

export default TeamDataTeamList
