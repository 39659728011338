import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import * as s from './EliminatorAdminManualPickEntry.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { nflTeamImage } from '@severed-links/common.redherrings-constants'

const EliminatorAdminManualEntryPickButton = ({ p, onPlayerSelect, icon, variant, disabled }) => (
<Button className={s.manualEntryPickButton} 
    disabled={disabled} 
    variant={variant || 'light'} 
    onClick={() => onPlayerSelect(p)}>
    <div className={s.content}>
        <div className={s.icon} style={p.teamId && p.mascot ? { backgroundImage: `url(${nflTeamImage(p.mascot)})` } : {}}>{!p.teamId ? <FontAwesomeIcon name={icon || 'trash'} /> : null}</div>
        <div className={s.teamNameContainer}>
            {p.teamId ? <div className={s.city}>{p.city}</div> : null}
            <div className={s.mascot}>{p.teamId ? p.mascot : `[No pick...]` }</div>
        </div>
    </div>
</Button>
)

export default EliminatorAdminManualEntryPickButton
