import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { ListGroup, Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './PlayerBackground.scss'
import { playerUrl } from '@severed-links/common.redherrings-constants'
import NflTeam from '../NflTeam'

const PlayerBackground = ({ profile = {} }) => {

    if (!profile) return null
    const p = profile
    const bg = [
        ...(p.city || p.state ? [{ _id: 2, title: 'Location', value: `${p.city || ''}${(p.city && p.state ? ', ' : '')}${p.state || ''}` }] : []),
        ...(p.occupation ? [{ _id: 3, title: 'Occupation', value: p.occupation }] : []),
    ]
    return (
        <div className={s.container}>
            <h4>{p.firstName} {p.lastName}</h4>
            <ListGroup className={s.backgroundItems}>
            <ListGroup.Item className={s.backgroundItem}>
                <div className={s.multi}>
                {p.playerAvatarUrl ? <div className={s.avatar}><Image src={playerUrl(p.playerAvatarUrl)} /></div> : null}
                {bg && bg.map(i =>
                    <div key={`profile-${p._id}-${i._id}`} className={s.backgroundItem}>
                        <div className={s.title}>{i.title}</div>
                        <div className={s.value}>{i.value}</div>
                    </div>
                )}
                {p.favoriteProTeamId ? 
                    <div key={`profile-${p._id}-pro-team`} className={s.backgroundItem}>
                        <div className={s.title}>Favorite NFL team</div>
                        <div className={s.value}><NflTeam teamId={p.favoriteProTeamId} teamName={p.favoriteTeamName} mascot={p.favoriteTeamMascot} /></div>
                    </div>
                : null}
                </div>
            </ListGroup.Item>
            {p.backgroundInfo ?
                <ListGroup.Item className={s.backgroundItem}>
                    <div className={s.value + ' ' + s.background} 
                        dangerouslySetInnerHTML={{ __html: p.backgroundInfo }} />
                </ListGroup.Item>
            : null}
            </ListGroup>
        </div>
    )
}

export default PlayerBackground