import React, { useState, useEffect } from 'react'
import { ListGroup, ProgressBar } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './LeagueSchedulerSummary.scss'
import moment from 'moment-timezone'
import pluralize from 'pluralize'

const LeagueSchedulerSummary = ({ startTime = null, seasonName = null, weeks = [], teams = [], divisions = [], gamesToSchedule = 0, progress = 0, resets = 0 }) => {

    const elapsedTime = startTime ? moment.utc(moment() - startTime).format("mm:ss") : '0:00'
    const _items = [
        { title: `Weeks`, subtitle: `${seasonName} Season`, right: `${weeks.length}` },
        { title: `${pluralize('Team', teams.length, true)}`, subtitle: `${pluralize('Division', divisions.length, true)}`, right: `${gamesToSchedule}` },
    ].map((i, index) => ({ ...i, id: (index + 1) }))
    return (
        <div className={s.container}>
            <ListGroup className={s.list}>
                {_items && _items.map(i =>
                <ListGroup.Item className={s.item} key={`league-scheduler-summary-item-${i.id}`}>
                    <div className={s.content}>
                        <div className={s.title}>{i.title}</div>
                        <div className={s.subtitle}>{i.subtitle}</div>
                    </div>
                    <div className={s.right}>{i.right}</div>
                </ListGroup.Item>
                )}
                <ListGroup.Item className={s.item + ' ' + s.progressBarItem}>
                    <ProgressBar now={progress} variant={progress >= 98 ? 'danger' : progress >= 95 ? 'warning' : null} />
                </ListGroup.Item>
                <ListGroup.Item className={s.item}>
                    <div className={s.content}>
                        <div className={s.title}>Resets</div>
                    </div>
                    <div className={s.right}>{resets}</div>
                </ListGroup.Item>
                <ListGroup.Item className={s.item}>
                    <div className={s.content}>
                        <div className={s.title}>Elapsed time</div>
                    </div>
                    <div className={s.right}>{elapsedTime}</div>
                </ListGroup.Item>
            </ListGroup>
        </div>
    )
}

export default LeagueSchedulerSummary