import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { ListGroup, Button } from 'react-bootstrap'
import moment from 'moment-timezone'
import * as s from './FAQ.scss'
import Payouts from './Payouts'
import { getFootballFaq } from '@severed-links/common.redherrings-reducers/football'

const FAQ = () => {

    const params = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const faq = useSelector(state => state.football.faq)

    useEffect(() => { dispatch(getFootballFaq()) }, [])

    return (
        <div className={s.container}>
            <h2 className={s.title}>Frequently-Asked Questions</h2>
            <ListGroup className={s.questionList}>
            {faq && faq.map(i =>
            <ListGroup.Item className={s.questionItem} key={`football-faq-${i._id}`}>
                {i.question ? <h4 className={s.question}>{i.question}</h4> : null}
                {i.answer ? <div className={s.answer}>{i.answer}</div> : null}
                {i.link ? <div><a href={i.link} target='_blank'>{i.linkTitle || 'Read more...'}</a></div> : null}
                {i.button ? <div><Button onClick={() => navigate(i.button.webPath)}>{i.button.title}</Button></div> : null}
            </ListGroup.Item>
            )}
            </ListGroup>
            <h4 className={s.question}>Enjoy.</h4>

        </div>
    )
}

export default FAQ