import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Alert, Button, Badge, Form, FormGroup, 
    FormLabel, FormControl, FormControlFeedback, Media, Image, ListGroup } from 'react-bootstrap'
import Confirm from '@severed-links/common.confirm'
import TextareaAutosize from 'react-autosize-textarea'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getMyTickets, getOthersTickets, getHelperRoles, toggleTicketModal, 
    setActiveTicket, updateActiveTicket, saveTicket, addComment, deleteComment, 
    loadActiveTicket } from '@severed-links/common.redherrings-reducers/help'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import moment from 'moment-timezone'
import { orderBy, startsWith, endsWith } from 'lodash'
import * as s from './HelpCenter.scss'
import Modal from '@severed-links/common.modal'
import { img } from '@severed-links/common.redherrings-constants'
import FormCheck from 'react-bootstrap/FormCheck'
import Linkify from 'react-linkify'

const EditTicket = () => {

    const dispatch = useDispatch()
    const { activeTicket, showActiveTicket, helperRoles } = useSelector(state => state.help)
    const [comment, setComment] = useState('')
    const [isMounted, setMounted] = useState(false)

    useEffect(() => { setMounted(true) }, [])

    const loadTickets = () => {
        dispatch(getMyTickets())
        dispatch(getOthersTickets())
        dispatch(getHelperRoles())
    }

    const handleTextChange = e => dispatch(updateActiveTicket(e.target.name, e.target.value))
    const handleSelectChange = e => dispatch(updateActiveTicket(e.target.name, e.target.value))
    const handleSwitch = (el, checkedState) => dispatch(updateActiveTicket(el.target.name, checkedState))
    const handleCommentChange = e => setComment(e.target.value)
    const handleKeyPress = e => {
        if (e.key === 'Enter') {            
            addCommentToTicket()
            e.preventDefault()
        }
    }

    const addCommentToTicket = () => {
        dispatch(addComment({ helpTicketId: activeTicket._id, comment }))
        setComment('')
    }

    const doDeleteComment = commentId => dispatch(deleteComment(commentId))

    const doSaveTicket = () => {
        const ticket = { 
            ...activeTicket, 
            createDate: activeTicket._id === 0 ? moment().utc().toISOString() : moment(activeTicket.createDate).utc().toISOString(),
            updateDate: moment().utc().toISOString() 
        }
        dispatch(toggleTicketModal(false))
        dispatch(saveTicket(ticket))
    }

    const getAvatarUrl = avatarUrl => img.BaseUrl + (!endsWith(img.BaseUrl, '/') && !startsWith(avatarUrl || img.NoImageAvailable, '/') ? '/' : '') + (avatarUrl || img.NoImageAvailable)

    if (!activeTicket) return null
    return (
        <div className={s.container}>

            <Row>
                <Col xs={6}>
                    <FormGroup>
                        <FormLabel>Site area</FormLabel>
                        <FormControl plaintext readOnly value={activeTicket.app} />
                    </FormGroup>
                </Col>
                <Col xs={6}>
                    <FormGroup>
                        <FormLabel>Issue Type</FormLabel>
                        <FormControl as='select' 
                            onChange={e => handleSelectChange(e)}
                            name='ticketType' 
                            required value={activeTicket.ticketType}>
                            <option value=''>[Select a type...]</option>
                            <option value='Bug'>Something isn't working right</option>
                            <option value='Feature Request'>I have a feature request</option>
                            <option value='Comment'>I have a comment on the site</option>
                        </FormControl>
                        <FormControl.Feedback />
                    </FormGroup>
                </Col>
            </Row>

            <FormGroup>
                <FormLabel>Subject</FormLabel>
                <FormControl type='text' name='subject' className='form-control'
                    placeholder='[Subject matter of issue...]' 
                    value={activeTicket.subject || ''}
                    required onChange={e => handleTextChange(e)} />
                <FormControl.Feedback />
            </FormGroup>

            <FormGroup>
                <FormLabel>Description of issue</FormLabel>
                <TextareaAutosize name='description' className='form-control'
                    placeholder='[Describe your issue here...]' 
                    value={activeTicket.description || ''}
                    required onChange={e => handleTextChange(e)} rows={2}
                    onResize={e => {}} />
                <FormControl.Feedback />
            </FormGroup>

            {isMounted ?
            <Row>
                <Col sm={3} xs={6}>
                    <FormGroup style={{ textAlign: 'center' }}>
                        <FormLabel style={{ width: '100%' }}>Completed?</FormLabel>
                        <FormCheck type='switch' onChange={e => handleSwitch(e, e.target.checked)}
                            name='isCompletedTicket' checked={activeTicket.isCompletedTicket} />
                    </FormGroup>
                </Col>
                <Col sm={3} xs={6}>
                    <FormGroup style={{ textAlign: 'center' }}>
                        <FormLabel style={{ width: '100%' }}>Delete?</FormLabel>
                        <FormCheck type='switch' onChange={e=> handleSwitch(e, e.target.checked)}
                            name='isDeletedTicket' checked={activeTicket.isDeletedTicket} />
                    </FormGroup>
                </Col>
                <Col sm={6} xs={12}>
                    <FormGroup style={{ textAlign: 'right' }}>
                        <FormLabel style={{ width: '100%' }}>&nbsp;</FormLabel>
                        <Button variant='light' onClick={() => dispatch(toggleTicketModal(false))}><FontAwesomeIcon name='times' /> cancel</Button>
                        {' '}
                        <Button variant='primary' 
                            disabled={!activeTicket.description || !activeTicket.ticketType}
                            onClick={() => doSaveTicket()}>
                            <FontAwesomeIcon name='check' /> 
                            {' '} 
                            save ticket
                        </Button>
                    </FormGroup>
                </Col>
            </Row>
            : null}

            {activeTicket._id ? 
            <div>
                <h5>Comments</h5>
                {activeTicket.comments.length === 0 ?
                    <Alert variant='info'>No comments on this ticket.</Alert>
                : null}
                <ListGroup className={s.commentsList}>
                    {activeTicket.comments && orderBy(activeTicket.comments, ['createDate'], ['desc']).map(c => 
                        <ListGroup.Item key={c._id} className={s.commentItem}>
                            <Image className={'mr-3 ' + s.avatar}
                                src={getAvatarUrl((c.player || {}).avatarUrl)}
                                roundedCircle />
                            <div className={s.content}>
                                {c.createDate && moment(c.createDate).isValid() ? <span className={s.date}>{moment(c.createDate).fromNow()}</span> : null}
                                <span className={s.delete}>
                                    <Confirm variant='danger' onConfirm={() => dpDeleteComment(c._id)}
                                        title={'Delete Comment'}
                                        confirmText='delete comment'
                                        body={'Are you sure you want to delete this comment?'}
                                        size='sm' buttonClassName={s.deleteButton} buttonIcon='trash' />
                                </span>
                                <h5>{c.player.fullName}</h5>
                                <p>
                                    <Linkify properties={{ target: '_blank' }}>{c.comment}</Linkify>
                                </p>
                            </div>
                        </ListGroup.Item>
                    )}
                        <ListGroup.Item className={s.newCommentListItem}>
                        <TextareaAutosize name='Description'
                            placeholder='[Add a comment...]' 
                            value={comment} className={'form-control ' + s.newCommentTextBox}
                            onKeyPress={e => handleKeyPress(e)}
                            onChange={e => handleCommentChange(e)} rows={1}
                            onResize={(e) => {}} />
                        </ListGroup.Item>
                </ListGroup>
            </div>
            : null}
        </div>
    )
}

export default EditTicket