import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Table, Alert, FormControl, Button, 
    Popover, OverlayTrigger, Badge, FormLabel, FormCheck, 
    InputGroup, FormGroup, Tooltip, ListGroup } from 'react-bootstrap'
import moment from 'moment-timezone'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import SeasonSelector from '../SeasonSelector'
import Modal from '@severed-links/common.modal'
import PlayerTeam from '../PlayerTeam'
import { getTeamData, getTeamDataPendingRequests, setTeamDataReload, setNewTeamDataCloseOnSave } from '@severed-links/common.redherrings-reducers/footballAdmin'
import { find, orderBy, forEach, uniq, filter, startsWith, countBy, forOwn, sumBy, isEqual } from 'lodash'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import * as s from './FootballAdmin.scss'
import TeamDataTeamList from './TeamDataTeamList'
import TeamDataPlayersFromLastYear from './TeamDataPlayersFromLastYear'
import TeamDataPendingRequests from './TeamDataPendingRequests'
import TeamDataAddNewTeam from './TeamDataAddNewTeam'
import Spinner from '@severed-links/common.spinner'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const TeamData = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const seasonName = params.seasonName || null
    const seasons = useSelector(state => state.football.seasons || [])
    const pendingRequests = useSelector(state => state.footballAdmin.pendingRequests)
    const season = seasons.find(i => i.seasonName === seasonName)
    const seasonId = (season || {}).seasonId || null
    const _teamDataList = useSelector(state => state.footballAdmin.teamData) || []
    const teamData = _teamDataList.find(i => i.seasonName === seasonName) || {}
    const teamDataReload = useSelector(state => state.footballAdmin.teamDataReload) || false
    const teamDataNewCloseOnSave = useSelector(state => state.footballAdmin.teamDataNewCloseOnSave)
    const [firstLoad, setFirstLoad] = useState(false)
    const [showAddNewTeam, setShowAddNewTeam] = useState(false)
    const [isLoading, setLoading] = useState(false)

    useEffect(() => { fetchTeamData() }, [seasonId])

    useEffect(() => {
        if (teamDataNewCloseOnSave) {
            dispatch(setNewTeamDataCloseOnSave(false))
            resetAddNewTeam()
        }
    }, [teamDataNewCloseOnSave])

    useEffect(() => {
        if (seasonId && teamDataReload) {
            fetchTeamData()
            dispatch(setTeamDataReload(false))
        }
    }, [seasonId, teamDataReload])

    const resetAddNewTeam = () => setShowAddNewTeam(false)

    const fetchTeamData = () => {
        if (seasonId) {
            setLoading(true)
            dispatch(getTeamData(seasonId))
            .then(action => { 
                setFirstLoad(true)
                dispatch(setTeamDataReload(false))
                setLoading(false)
            })
            dispatch(getTeamDataPendingRequests())
        }
    }

    const { teams, missingPlayers, divisions, players, divisionCounts, slotsLeft, teamCount, teamCap } = teamData || {}
    const divisionItems = []
    forOwn(divisionCounts, (value, key) => divisionItems.push({ divisionName: key, count: value }))
    const popoverTop = (
        <Popover id='popover-teamdata-division-counts'>
            <Popover.Header>Division Counts</Popover.Header>
            <Popover.Body className={s.divisionCountsPopoverBody}>
                <Table size='sm' striped style={{ margin: '0px' }} className={s.divisionCountsPopoverTable}>
                    <tbody>
                    {orderBy(divisionItems || [], ['divisionName']).map(d => <tr key={d.divisionName}><td>{d.divisionName}</td><td className='text-end'>{d.count}</td></tr>)}
                    </tbody>
                    <tfoot>
                        <tr><td><b>Total</b></td><td className='text-end'><b>{teamCount}</b></td></tr>
                        <tr><td><b>Capacity</b></td><td className='text-end'><b>{teamCap}</b></td></tr>
                        <tr><td><b>Slots Left</b></td><td className='text-end'><b>{slotsLeft}</b></td></tr>
                    </tfoot>
                </Table>
            </Popover.Body>
        </Popover>
    )
    return (
        season ?
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}>Edit {season.seasonName} League Team Data</h2>
                <div className={s.controls}>
                    <Button onClick={() => setShowAddNewTeam(true)}><FontAwesomeIcon name='plus' /></Button>
                    <span className={s.seasonSelector}><SeasonSelector seasons={seasons} season={season} /></span>                </div>
                </div>
            <Row>
            {pendingRequests && pendingRequests.length > 0 ? 
                <Col xs={12}>
                    <h4>Pending Team Registrations</h4>
                    <TeamDataPendingRequests requests={pendingRequests} />
                </Col>
            : null}
            </Row>
            <Row>
            {teams && teams.length > 0 ? 
                <Col xs={12}>
                    <div className={s.heading}>
                        <h4 className={s.title}>Team Data</h4>
                        <div className={s.controls}>
                            <OverlayTrigger trigger='click' placement='bottom' overlay={popoverTop}>
                                <Button size='sm' variant='light'><FontAwesomeIcon name='sort-alpha-up' /> division counts</Button>
                            </OverlayTrigger>
                            <Button size='sm' variant='light' onClick={() => navigate(`/football/admin/preseason/division-organizer/${season.seasonName}`)}><FontAwesomeIcon name='sort-alpha-up' /> division organizer</Button>
                        </div>
                    </div>
                    <TeamDataTeamList teams={teams} divisions={divisions} players={players || []} />
                </Col>
            : !isLoading ? <Alert>No teams loaded for {season.seasonName}</Alert>
            : isLoading && (!teams || !teams.length) ? <Spinner size='3x' />
            : null}
            </Row>

            <Row>
                {missingPlayers && missingPlayers.length > 0 ? 
                <Col xs={12}>
                    <div className={s.missingPlayersHeading}>
                        <h4 className={s.missingPlayersTitle}>Players From Last Year Who Have Not Signed Up For This Year ({missingPlayers ? missingPlayers.length : 0})</h4>
                        <CopyToClipboard text={missingPlayers.map(i => i.emailAddress).join(';')} onCopy={() => onCopyButtonClick()}>
                            <Button className={s.copyButton}><FontAwesomeIcon name='envelope' /></Button>
                        </CopyToClipboard>
                        <CopyToClipboard text={missingPlayers.map(i => i.cellPhone).join(',')} onCopy={() => onCopyButtonClick()}>
                            <Button className={s.copyButton}><FontAwesomeIcon name='mobile' /></Button>
                        </CopyToClipboard>
                    </div>
                    <TeamDataPlayersFromLastYear missingPlayers={missingPlayers} />
                </Col>
                : null}
            </Row>

            <Modal heading={`Add New ${season.seasonName} Team`}
                show={showAddNewTeam}
                onClose={() => resetAddNewTeam()}
                actionButtonText={null}>
                <TeamDataAddNewTeam divisions={divisions} 
                    seasonId={season.seasonId}
                    seasonName={seasonName}
                    players={players} />
            </Modal>
        </div>
        : null
    )
}

export default TeamData