import React from 'react'
import { Image, Tooltip, OverlayTrigger, Badge } from 'react-bootstrap'
import * as s from './OnlineUsers.scss'
import { img } from '@severed-links/common.redherrings-constants'
import moment from 'moment-timezone'
import { minBy, startsWith, endsWith } from 'lodash'

const OnlineUserTooltipContent = ({ user }) => (
  <span>{user.playerName}{' '}{moment(user.connectedSince).local().format('h:mm A')}</span>
)

const OnlineUsers = ({ users = [], right = false, noBottomMargin  = false }) => {

  const imageUrl = avatarUrl => `${img.BaseUrl}${(!endsWith(img.BaseUrl, '/') && !startsWith(avatarUrl || img.NoImageAvailable, '/') ? '/' : '')}${(avatarUrl || img.NoImageAvailable)}`

  return (
    <div className={s.usersContainer + (right ? ` ${s.end}` : '') + (noBottomMargin ? ` ${s.noBottomMargin}` : '')}>
    {users && users.map(u =>
      <div className={s.avatar} key={`online-user-container-${u.playerId}`}>
          <OverlayTrigger placement="top" user={u} overlay={<Tooltip id={`online-user-tooltip-${u.playerId}`}><OnlineUserTooltipContent user={u} /></Tooltip>}>
            <div className={s.avatar} style={u.avatarUrl ? { backgroundImage: `url(${imageUrl(u.avatarUrl)})` } : {}}>
              {!u.avatarUrl ? 
              <div className={s.initials}>{(u.initials)}</div>
              : null}
            </div>
          </OverlayTrigger>
          {u.connectionCount > 1 ? 
            <Badge bg='primary' pill className={s.connectionCount}>{u.connectionCount}</Badge>
          : null}
      </div>
    )}
    </div>
  )
}

export default OnlineUsers
