import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'

import moment from 'moment-timezone'

export const VisibleBeforeDate = ({ deadline, children = null }) => {
    const _currentTime = moment()
    const _deadline = moment(deadline)
    if (!deadline || !_deadline.isValid() && !_currentTime.isBefore(_deadline)) return null
    return children
}

export const VisibleOnlyToRoles = ({ roles = [], children = null }) => {
    const _userRoles = useSelector(state => state.account.roles || [])
    const _isPermitted = _userRoles.some(r => roles.includes(r))
    return _isPermitted ? children : null
}

export const VisibleOnlyToCaptureAdmin = ({ children }) => <VisibleOnlyToRoles roles={['CaptureAdmin']}>{children}</VisibleOnlyToRoles>
export const VisibleOnlyToCinderellaAdmin = ({ children }) => <VisibleOnlyToRoles roles={['CinderellaAdmin']}>{children}</VisibleOnlyToRoles>
export const VisibleOnlyToEliminatorAdmin = ({ children }) => <VisibleOnlyToRoles roles={['EliminatorAdmin']}>{children}</VisibleOnlyToRoles>
export const VisibleOnlyToFootballAdmin = ({ children }) => <VisibleOnlyToRoles roles={['FootballAdmin']}>{children}</VisibleOnlyToRoles>
export const VisibleOnlyToOscarsAdmin = ({ children }) => <VisibleOnlyToRoles roles={['OscarsAdmin']}>{children}</VisibleOnlyToRoles>
