// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RegisterContest__container___s8cL5 .RegisterContest__contestList___Eiy1p{margin-bottom:2rem}.RegisterContest__container___s8cL5 .RegisterContest__formGroup___Ocigf{margin-bottom:1rem}.RegisterContest__container___s8cL5 .RegisterContest__formGroup___Ocigf .RegisterContest__infoAlert___KMIJT{margin-top:2rem;margin-bottom:1rem}`, "",{"version":3,"sources":["webpack://./src/routes/Main/RegisterContest.scss"],"names":[],"mappings":"AACI,0EACI,kBAAA,CAGJ,wEACI,kBAAA,CAEA,4GACI,eAAA,CACA,kBAAA","sourcesContent":[".container {\n    .contestList {\n        margin-bottom: 2rem;\n    }\n\n    .formGroup {\n        margin-bottom: 1rem;\n\n        .infoAlert {\n            margin-top: 2rem;\n            margin-bottom: 1rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `RegisterContest__container___s8cL5`;
export var contestList = `RegisterContest__contestList___Eiy1p`;
export var formGroup = `RegisterContest__formGroup___Ocigf`;
export var infoAlert = `RegisterContest__infoAlert___KMIJT`;
export default ___CSS_LOADER_EXPORT___;
