import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './EliminatorWinners.scss'

const EliminatorWinnerCardHeader = ({ seasonName }) => (
    <Card.Header className={s.header}>
        <h4 className={s.seasonName}>{seasonName}</h4>
        <FontAwesomeIcon name='trophy' className={s.icon} size='2x' />
    </Card.Header>
)

export default EliminatorWinnerCardHeader
