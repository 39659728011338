import React, { useEffect } from 'react'
import { ListGroup } from 'react-bootstrap'
import { getNflStandings } from '@severed-links/common.redherrings-reducers/football'
import { find } from 'lodash'
import moment from 'moment-timezone'
import NflTeam from '../../NflTeam'
import NflTeamListItem from '../../NflTeamListItem'
import Line from '../../Line'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './NFLTeamAnalysisSeasonSummaries.scss'
import { setScoreboard } from '@severed-links/common.redherrings-reducers/cinderella'

const SeasonInfoItem = ({ heading = '', value = '' }) => (
    heading || value ?
    <div className={s.infoItem}>
        {heading ? <div className={s.infoHeading}>{heading}</div> : null}
        {value ? <div className={s.infoValue}>{value}</div> : null}
    </div>
    : null
)

const SeasonListItem = ({ x = null }) => (
    x ?
    <>
    <ListGroup.Item className={s.item}>
        <div className={s.content}>
            <div className={s.title}>Home</div>
            <SeasonInfoItem heading='Record' value={x.homeRecord} />
            <SeasonInfoItem heading='PF/G' value={x.hpfpg.toFixed(1)} />
            <SeasonInfoItem heading='PA/G' value={x.hpapg.toFixed(1)} />
        </div>
        <div className={s.content + ' ' + s.marginTop}>
            <div className={s.title + ' ' + s.isIndented + ' ' + s.isMini}>ATS</div>
            <SeasonInfoItem heading='Record' value={x.spreadHomeRecord} />
            <SeasonInfoItem heading='As Favorite' value={x.favoriteHomeRecord} />
            <SeasonInfoItem heading='As Underdog' value={x.underdogHomeRecord} />
        </div>
    </ListGroup.Item>
    <ListGroup.Item className={s.item}>
        <div className={s.content}>
            <div className={s.title}>Away</div>
            <SeasonInfoItem heading='Record' value={x.awayRecord} />
            <SeasonInfoItem heading='PF/G' value={x.apfpg.toFixed(1)} />
            <SeasonInfoItem heading='PA/G' value={x.apapg.toFixed(1)} />
        </div>
        <div className={s.content + ' ' + s.marginTop}>
            <div className={s.title + ' ' + s.isIndented + ' ' + s.isMini}>ATS</div>
            <SeasonInfoItem heading='Record' value={x.spreadAwayRecord} />
            <SeasonInfoItem heading='As Favorite' value={x.favoriteAwayRecord} />
            <SeasonInfoItem heading='As Underdog' value={x.underdogAwayRecord} />
        </div>
    </ListGroup.Item>
    <ListGroup.Item className={s.item}>
        <div className={s.content}>
            <div className={s.title}>Total {x.strengthOfScheduleRank ? ` (SOS: ${x.strengthOfScheduleRank})` : ''}</div>
            <SeasonInfoItem heading='Record' value={x.record} />
            <SeasonInfoItem heading='PF/G' value={x.pfpg.toFixed(1)} />
            <SeasonInfoItem heading='PA/G' value={x.papg.toFixed(1)} />
        </div>
        <div className={s.content + ' ' + s.marginTop}>
            <div className={s.title + ' ' + s.isIndented + ' ' + s.isMini}>ATS</div>
            <SeasonInfoItem heading='Record' value={x.spreadRecord} />
            <SeasonInfoItem heading='As Favorite' value={x.favoriteRecord} />
            <SeasonInfoItem heading='As Underdog' value={x.underdogRecord} />
        </div>
    </ListGroup.Item>
    </>
    : null
)

const NFLTeamAnalysisSeasonSummaries = ({ seasonName = '', summaries = [] }) => {

    return (
            <div className={s.container}>
                <h4>Record Summaries</h4>
                <ListGroup className={s.list}>
                {summaries && summaries.map(x => [
                    <ListGroup.Item className={s.item + ' ' + s.miniHeader} key={`season-header-${seasonName}-${x.displaySeason}`}>{x.displaySeason}</ListGroup.Item>,
                    <SeasonListItem x={x} key={`season-item-${x.displaySeason}`} />
                ])}
                </ListGroup>
            </div>
    )
}

export default NFLTeamAnalysisSeasonSummaries
