import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { img } from '@severed-links/common.redherrings-constants'
import CloudinaryImage from './CloudinaryImage'
import * as s from './CloudinaryUploader.scss'
import sha1 from 'sha1'
import scriptLoader from 'react-async-script-loader'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { requestUploadSignature, getCloudinaryConfig } from '@severed-links/common.redherrings-reducers/cloudinary'

const CloudinaryUploader = ({ 
    isScriptLoadSucceed, isScriptLoaded, onUploadSuccess = () => void(0), 
    originalId, folder, transformationName, 
    showImage = true, showButton = true, showButtonSize = null, 
    showButtonIsBlock = true, showAccessory = false, showClickLabel = true, 
    buttonClassName = null, showButtonIcon = true, showButtonLabel = true, 
}) => {


    const dispatch = useDispatch()
    const cloudName = useSelector(state => state.cloudinary.cloudName)
    const apiKey = useSelector(state => state.cloudinary.apiKey)
    const [isInitialized, setInitialized] = useState(false)
    const [_uploadWidget, setUploadWidget] = useState(null)

    useEffect(() => {
        dispatch(getCloudinaryConfig())
    }, [])

    useEffect(() => {
        if (apiKey && cloudName && isScriptLoaded && isScriptLoadSucceed && !isInitialized) {
            createWidget()
        }
    }, [apiKey, cloudName, isScriptLoaded, isScriptLoadSucceed, isInitialized])

    const generateSignature = (cb, params_to_sign) => {
        dispatch(requestUploadSignature(params_to_sign))
        .then(action => cb(action.payload.signature))
    }

    const createWidget = () => {
        const uploadWidget = cloudinary.createUploadWidget({
            cloudName, uploadPreset: 'redherrings_avatar', public_id: null, multiple: false,
            cropping: true, folder, theme: 'minimal', showPoweredBy: false,
            apiKey, 
            uploadSignature: generateSignature,
            clientAllowedFormats: ['image'],
        }, (_err, _result) => { 
            if (_err && _err.statusText) {
                dispatch(createNotification({ message: `Error uploading image: ${_err.statusText}`, messageType: 'danger', subject: 'Upload Player Avatar' }))
            } else if (_result && _result.event === 'success' && _result.info) {
                if (onUploadSuccess) {
                    onUploadSuccess(_result.info)
                }
            }
        })
        setInitialized(true)
        setUploadWidget(uploadWidget)
    }
    if (!isInitialized || !_uploadWidget) return null
    return (
        <div className={s.container + (showAccessory ? ` ${s.hasAccessory}` : ``)}>
            {showImage ? 
            <CloudinaryImage 
                className={showButton || showAccessory ? s.hasButton : s.noButton}
                onClick={!showButton ? () => _uploadWidget.open() : () => void(0)}
                transformationName={transformationName || null}
                publicId={originalId || img.NoImageAvailable} /> 
            : null}
            {showButton ?
            <div className='text-center'>
                <Button variant={`outline-primary`}
                    size={showButtonSize || null}
                    className={`${s.uploadButton} ${buttonClassName}`}
                    onClick={showButton ? () => _uploadWidget.open() : () => void(0)}>
                    {showButtonIcon ? <FontAwesomeIcon name='upload' /> : null}
                    {showButtonLabel ? <span className='d-none d-md-inline' style={showButtonIcon ? { marginLeft: '4px'} : null}>select new</span> : null}
                </Button>
            </div> 
            : showClickLabel ? <div style={{ textAlign: 'center' }}><i><small>click to change</small></i></div>
            : showAccessory ? <div className={s.accessory}><Button onClick={() => _uploadWidget.open()}><FontAwesomeIcon name='pencil-alt' /></Button></div>
            : null
            }
        </div>
    )
}

export default scriptLoader('https://upload-widget.cloudinary.com/global/all.js')(CloudinaryUploader)
