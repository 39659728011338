import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup } from 'react-bootstrap'
import * as s from './CinderellaAdminsList.scss'
import PlayerListItem from '../../Player/PlayerListItem'
import moment from 'moment-timezone'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { orderBy } from 'lodash'

const CinderellaAdminsList = () => {

    const dispatch = useDispatch()
    const admins = useSelector(state => state.cinderellaAdmin.admins)

    return (
        <div className={s.container}>
            <h4>Cinderella Admins</h4>
            <ListGroup className={s.list}>
            {admins && orderBy(admins, ['LastLogin'], ['desc']).map((p, index) => (
                <PlayerListItem player={p} 
                    className={s.item}
                    rightDetail={<div className={s.rightDetail}>
                        <div className={s.userName}>{p.userName}</div>
                        <div className={s.lastLogin}>{p.lastLogin ? moment(p.lastLogin).format('M/D/YYYY h:mm A') : ''}</div>
                    </div>}
                    key={`cinderella-admin-${p._id}-${index}`} />
            ))}
            </ListGroup>
        </div>
    )
}

export default CinderellaAdminsList