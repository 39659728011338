import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import BaseAuthenticatedMenu from './BaseAuthenticatedMenu'
import { Nav } from 'react-bootstrap'
import { img } from '@severed-links/common.redherrings-constants'
import CloudinaryImage from '../routes/Cloudinary/CloudinaryImage'
import { VisibleOnlyToOscarsAdmin } from '../components/Authorization'
import { VisibleBeforeDate } from '../components/VisibilityBeforeAfterDate'
import * as s from './Menu.scss'
import * as s2 from './OscarsMenuSpinner.scss'
import { getOscarsDetails, getOscarsYears } from '@severed-links/common.redherrings-reducers/oscars'
import moment from 'moment-timezone'
import { after, isEqual } from 'lodash'

const OscarsMenu = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const isAuthenticated = useSelector(state => state.account.isAuthenticated)
    const year = useSelector(state => state.oscars.year)
    const pickDeadline = useSelector(state => state.oscars.pickDeadline)
    const entryDeadlineDate = useSelector(state => state.oscars.entryDeadlineDate)
    const dateTimeFormat = useSelector(state => state.oscars.dateTimeFormat)
    const isFetching = useSelector(state => state.oscars.isFetching || state.oscarsAdmin.isFetching)
    const timeZone = useSelector(state => state.account.time_zone)
    const isHome = location.pathname.toLowerCase() === '/oscars'
    const [afterDeadline, setAfterDeadline] = useState(false)
    const [deadlineStatus, setDeadlineStatus] = useState('')

    useLayoutEffect(() => {
        if (isAuthenticated) {
            dispatch(getOscarsYears())
            checkDeadlines()
        }
        let timerId = setInterval(() => checkDeadlines(), 30 * 1000)
        return () => clearInterval(timerId)
    }, [isAuthenticated])

    useLayoutEffect(() => {
        if (isAuthenticated) {
            dispatch(getOscarsDetails())
            checkDeadlines()
        }
    }, [isAuthenticated, year, entryDeadlineDate])

    useLayoutEffect(() => {
        if (isAuthenticated) {
            reRouteOnAfterDeadline()
        }
    }, [isAuthenticated, afterDeadline, location.pathname])

    const checkDeadlines = () => {
        if (entryDeadlineDate) {
            setDeadlineStatus(pickDeadlineStatus(entryDeadlineDate))
            setAfterDeadline(moment.utc().isAfter(moment(entryDeadlineDate)))
        }
    }

    const reRouteOnAfterDeadline = () => {
        if (afterDeadline && location.pathname.indexOf('/oscars/enter-picks') > -1) {
            navigate('/oscars')
        }
    }

    const pickDeadlineStatus = date => {
        var deadline = moment(date)
        var duration = moment.duration(deadline.diff(moment()))
        var iscurrentDate = deadline.isSame(moment(), 'day')
        var status = '---'
        if (!moment().isBefore(deadline)) {
            status = 'Passed'
        } else if (duration.asMinutes() <= 60) {
            status = deadline.fromNow()
        } else if (iscurrentDate) {
            status = deadline.tz(timeZone).format('h:mm a')
        } else {
            status = deadline.tz(timeZone).format('M/D/YYYY h:mm a')
        }
        return status
    }

    const navbarBrand = (
        <Link to={{ pathname: '/oscars' }}>
            <CloudinaryImage publicId={img.OscarsHeader} />
        </Link>
    )

    const AdminOnlyNavItem = () => <VisibleOnlyToOscarsAdmin><Nav.Item className={s.item} key={5}><Nav.Link eventKey={5} onClick={() => navigate('/oscars/admin')}>Admin</Nav.Link></Nav.Item></VisibleOnlyToOscarsAdmin>

    const OscarsEnterPicksMenuItem = moment().isBefore(moment(entryDeadlineDate)) ?
        <VisibleBeforeDate key={25} deadline={entryDeadlineDate}>
            <Nav.Item key={25.1}><Nav.Link eventKey={25.1} className={s.item}
                onClick={() => navigate(`/oscars/enter-picks/start`)}>
                Enter picks
            </Nav.Link></Nav.Item>
        </VisibleBeforeDate> : null

    const menuItems = [
        OscarsEnterPicksMenuItem,
        <Nav.Item className={s.item} key={1}><Nav.Link eventKey={1} onClick={() => navigate(`/oscars/nominees/${year}`)}>Nominees</Nav.Link></Nav.Item>,
        <Nav.Item className={s.item} key={2}><Nav.Link eventKey={2} onClick={() => navigate(`/oscars/view-picks/${year}`)}>View Picks</Nav.Link></Nav.Item>,
        <Nav.Item className={s.item} key={3}><Nav.Link eventKey={3} onClick={() => navigate(`/oscars/scoreboard/${year}`)}>Scoreboard</Nav.Link></Nav.Item>,
        <Nav.Item className={s.item} key={4}><Nav.Link eventKey={4} onClick={() => navigate(`/oscars/standings`)}>Standings</Nav.Link></Nav.Item>,
        <AdminOnlyNavItem key={5} />,
        isFetching ? <span key={999.99} className={s2.oscarsSpinner}><FontAwesomeIcon name='circle-notch' spin /></span> : null
    ]
    return (
        <BaseAuthenticatedMenu backgroundImageUrl={img.CloudinaryBaseUrl + img.OscarsHeaderBackground}
            navbarBrand={navbarBrand}
            expand='lg'
            menuItems={menuItems} />
    )
}

export default OscarsMenu
