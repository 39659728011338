import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useParams, useMatch } from 'react-router-dom'
import { FormGroup, FormControl, FormLabel, Button, ButtonGroup, 
    InputGroup, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { sendPushNotification, getIconFromMessageType } from '@severed-links/common.redherrings-reducers/notifications'
import TextareaAutosize from 'react-autosize-textarea'
import Confirm from '@severed-links/common.confirm'
import { find, startsWith } from 'lodash'
import { upperCaseFirst } from 'upper-case-first'
import Modal from '@severed-links/common.modal'
import Notification from './Notification'
import * as s from './SendPushNotification.scss'

const PushPreview = ({ subject, message, messageType }) => (
    message && messageType && subject ?
    <div className={s.pushPreviewContainer}>
        <h4>Preview</h4>
        <Notification notification={{ subject, message, messageType, icon: getIconFromMessageType(messageType) }} />
    </div>
    : null
)

const SendPushNotification = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const location = useLocation()
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')
    const [messageType, setMessageType] = useState('info')
    const [_id, setId] = useState('10000')
    const [showPushModal, setShowPushModal] = useState(false)
    const _match = useMatch(`/:appName`)
    const appName = _match && _match.params ? upperCaseFirst((_match.params.appName || '').toLowerCase().replace('/admin', '')) : ''

    const send = () => {
        dispatch(sendPushNotification({ subject, message, messageType }, appName))
        setSubject('')
        setMessage('')
        setShowPushModal(false)
    }

    const styles = ['info','success','danger','warning']
    return (
        <span>
            <Button variant='light' onClick={() => setShowPushModal(true)}><FontAwesomeIcon name='bolt' /></Button>
            <Modal heading={'Send Push Notification to All Online Users'}
                show={showPushModal} enforceFocus={false}
                onClose={() => setShowPushModal(false)}
                showFooter={false}>
                <div className={s.modalContainer}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>Headline</FormLabel>
                        <FormControl value={subject} placeholder={'[Subject...]'}
                            onChange={e => setSubject(e.target.value)} />
                    </FormGroup>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>Message</FormLabel>
                        <TextareaAutosize name='Description' className='form-control'
                            placeholder='[Type your message here...]' 
                            value={message}
                            onChange={e => setMessage(e.target.value)} rows={2}
                            onResize={(e) => {}} />
                    </FormGroup>
                    <FormGroup className={s.formGroup}>
                        <ButtonGroup>
                        {styles && styles.map(i =>
                            <Button key={`notification-style-${i}`} variant={i} onClick={() => setMessageType(i)}>{i}</Button>
                        )}
                        </ButtonGroup>
                    </FormGroup>
                    <PushPreview subject={subject} message={message} messageType={messageType} />
                    <FormGroup className={s.formGroup}>
                        <Confirm variant={messageType} onConfirm={() => send()}
                            title={'Confirm Push Notification'} enforceFocus={false}
                            confirmText='confirm send'
                            body={<div>Are you sure you are ready to send this to all online users?<br/><br/><PushPreview subject={subject} message={message} messageType={messageType} /></div>}
                            disabled={!message || !subject || !messageType}
                            buttonIcon='bolt' buttonText='send' />
                    </FormGroup>
                </div>
            </Modal>

        </span>
    )
}

export default SendPushNotification