import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './RecapByLine.scss'
import moment from 'moment-timezone'
import { playerUrl } from '@severed-links/common.redherrings-constants'

const RecapByLine = ({ lastEdited, author = {} }) => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)

    useEffect(() => {
    }, [])

    return (
        <div className={s.container}>
            {author.avatarUrl ?
            <div className={s.avatar}><Image src={playerUrl(author.avatarUrl)} /></div>
            : null}
            <div className={s.author}>
                <div className={s.name}>{author.firstName} {author.lastName}</div>
                {moment(lastEdited).isValid() ?
                <div className={s.lastEdited}>{moment(lastEdited).fromNow()}</div>
                : null}
            </div>
        </div>
    )
}

export default RecapByLine
