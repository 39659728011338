import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Row, Col, FormGroup, ListGroup } from 'react-bootstrap'
import { filter, map } from 'lodash'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Alert from '@severed-links/common.alert'
import Button from '@severed-links/common.button'
import Modal from '@severed-links/common.modal'
import TextInput from '@severed-links/common.text-input'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { login, getMe } from '@severed-links/common.redherrings-reducers/account'
import { contestRegistration } from '@severed-links/common.redherrings-reducers/main'
import * as s from './RegisterContest.scss'

const RegisterContest = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { userName, _id: playerId, roles } = useSelector(state => state.account)
    const [passphrase, setPassphrase] = useState('')
    const [password, setPassword] = useState('')
    const [isLoggingIn, setLoggingIn] = useState(false)

    const reLogin = () => {
        dispatch(login(userName, password))
        .then(action => {
            if (action.payload.access_token) {
                setLoggingIn(false)
                setPassword('')
                setPassphrase('')
                dispatch(getMe())
                .then(() => {
                    navigate('/main')
                })
            } else {
                dispatch(createNotification({ type: 'error', 
                    headline: 'Contest Registration -- Re-Login', 
                    message: 'Incorrect password', 
                    timeout: 6000
                }))
            }
        })
    }

    const checkPassphrase = () => {
        dispatch(contestRegistration(passphrase))
        .then(action => {
            dispatch(createNotification({ type: action.payload.messageType, 
                headline: 'Contest Registration', 
                message: action.payload.message, 
                timeout: 6000
            }))
            if (action.payload.messageType === 'success') {
                setLoggingIn(true)
            } else if (action.payload.messageType === 'info') {
                navigate('/main')
            }
        })
    }

    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <h2>Register for another Redherrings Contest</h2>
                </Col>
            </Row>

            <Row>
                <Col md={{ span: 6, offset: 3 }} sm={{ span: 8, offset: 2 }} xs={12}>
                    <div>
                        <p>
                            You are currently registered for the following Redherrings contests:
                        </p>
                        <ListGroup className={s.contestList}>
                            {roles && roles.filter(r => r.indexOf('Player') > -1).map(r => r.replace('Player', '')).map(r =>
                                <ListGroup.Item key={r}>{r}</ListGroup.Item>
                            )}
                            {!roles?.length ? <ListGroup.Item>No contests located.</ListGroup.Item> : null}
                        </ListGroup>
                        <div>
                            <FormGroup size='lg' className={s.formGroup}>
                                <TextInput name='passphrase' value={passphrase || ''}
                                    leftIcon='lock' autoCapitalize='none' autoFocus
                                    required isValid={passphrase && passphrase.length > 3}
                                    showValidState onChange={_text => setPassphrase(_text)}
                                    label='[Enter contest passphrase that you were provided...]'
                                    buttonProps={{ variant: 'primary', icon: 'check', title: 'register', onClick: () => checkPassphrase(), disabled: !passphrase || passphrase.length <= 3 }} />
                            </FormGroup>

                            <Alert className={s.infoAlert} small variant='info' icon='info-circle' message={<div><strong>Note:</strong> You will be asked to enter your password after a successful contest registration to obtain new permissions.</div>} />
                        </div>
                    </div>
                </Col>
            </Row>
            <Modal heading='Enter your password'
                show={isLoggingIn}
                onClose={() => setLoggingIn(false)}
                actionButtonText={null}>
                <FormGroup>
                    <TextInput type='password' name='password' 
                        value={password || ''}
                        autoFocus autoCapitalize='none'
                        label='[Re-enter your password...]'
                        onChange={_text => setPassword(_text)}
                        buttonProps={{
                            variant: 'primary',
                            icon: 'check',
                            title: 're-login',
                            disabled: !password || password.length <= 3,
                            onClick: () => reLogin(),
                        }} />
                </FormGroup>
            </Modal>
        </div>
    )
}

export default RegisterContest