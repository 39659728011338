import React, { useEffect } from 'react'
import { Badge, Image } from 'react-bootstrap'
import * as s from './EnterPicksRecentGame.scss'
import { nflTeamImage } from '@severed-links/common.redherrings-constants'
import NflTeam from '../NflTeam'

const EnterPicksRecentGame = ({ game = {}, index = -1 }) => {

    return (
        <div className={s.container} key={index}>
            <Badge className={s.recentGameTime}>{game.dateDisplay}</Badge>
            <div className={s.recentGameTeamContainer}>
                <div className={s.recentGameTeam + ' ' + s.visitor}>
                    <Image src={nflTeamImage(game.visitorMascot)} className={s.teamImage} />
                    <div className={s.recentGameScore}>{game.visitorScore}</div>
                </div>
                <div className={s.recentGameTeam + ' ' + s.home}>
                    <Image src={nflTeamImage(game.homeMascot)} className={s.teamImage} />
                    <div className={s.recentGameScore}>{game.homeScore}</div>
                </div>
            </div>
        </div>
    )
}
    
export default EnterPicksRecentGame