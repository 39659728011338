import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, FormGroup, FormControl, InputGroup, InputGroupButton, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { API_URL } from '@severed-links/common.redherrings-constants'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { teamDrawPlayerLookup } from '@severed-links/common.redherrings-reducers/captureAdmin'
import * as s from './CaptureTeamDraw.scss'

const CaptureTeamDrawAddPlayer = ({ addPlayer = () => void(0) }) => {

    const dispatch = useDispatch()
    const teamDraw = useSelector(state => state.captureAdmin.teamDraw)
    const [playerName, setPlayerName] = useState('')
    const [isLoading, setLoading] = useState(false)
    const [options, setOptions] = useState([])
    const _addNewPlayerTypeahead = useRef(null)

    const handleChange = e => setPlayerName(e && e.length > 0 ? e[0] : '')

    const handleSearch = (_query = '') => {
        setPlayerName(_query)
        setLoading(true)
        dispatch(teamDrawPlayerLookup(_query))
        .then(action => {
            setOptions(action.payload)
            setLoading(false)
        })
    }

    const processAddPlayer = () => {
        addPlayer(playerName)
        _addNewPlayerTypeahead.current?.clear()
        setPlayerName('')
    }

    if (!teamDraw) return null
    return (
        <div className={s.addPlayerContainer}>
            <Row>
                <Col xs={12}>
                <FormGroup className={s.formGroup}>
                    <InputGroup>
                        <InputGroup.Text><FontAwesomeIcon name='user' /></InputGroup.Text>
                        <AsyncTypeahead id='team-draw-add-player-typeahead'
                            ref={_addNewPlayerTypeahead}
                            onSearch={handleSearch}
                            selected={playerName ? [playerName] : []}
                            isLoading={isLoading} 
                            minLength={2} multiple={false}
                            options={options} value={playerName} 
                            className={s.typeahead}
                            onChange={handleChange} />
                        <Button variant='light' onClick={() => processAddPlayer()} 
                            disabled={!playerName}><FontAwesomeIcon name='plus-circle' /> add player</Button>
                    </InputGroup>
                </FormGroup>
                </Col>
            </Row>
        </div>
    )
}

export default CaptureTeamDrawAddPlayer