import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './LineContainer.scss'
import Line from '../Line'

const LineContainer = ({ game = {}, isMini = false }) => {

    return (
        <div className={s.container}>
            {!isMini ?
            <Badge bg='light' text='dark' className={'d-none d-sm-inline'}>
                {game.gameTime}
            </Badge>
            : null}
            <div className={s.lineNumber}>
                <Line line={!isMini ? (game.pick === 'Favorite' ? -1.0 : 1.0) * parseFloat(game.line) : game.line} showSign={!isMini} />
            </div>
        </div>
    )
}

export default LineContainer
