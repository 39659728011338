import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { ListGroup, Image, Button, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getNflInjuryReport } from '@severed-links/common.redherrings-reducers/football'
import { find, forEach, orderBy } from 'lodash'
import moment from 'moment-timezone'
import * as s from './NFLInjuryReport.scss'
import NFLTeamSelector from '../../NFLTeamSelector'
import { nflTeamImage } from '@severed-links/common.redherrings-constants'

const NFLInjuryReport = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const mascot = (params.mascot || '').toLowerCase()
    const nflInjuryReport = useSelector(state => state.football.nflInjuryReport)
    const nflTeams = useSelector(state => state.football.nflTeams)
    const nflTeam = nflTeams.find(t => t.mascot.toLowerCase() === mascot) || {}
    const _nflTeamId = (nflTeam || {})._id || null
    const report = nflInjuryReport.find(r => r.teamId === nflTeam.teamId)

    useEffect(() => { !report && _nflTeamId ? dispatch(getNflInjuryReport(nflTeam)) : null }, [report, _nflTeamId])

    const { items, injuryReportUrl, teamName } = report || {}
    return (
        <div className={s.container}>
            <div className={s.heading}>
                <div className={s.teamIcon}>
                    <Image src={nflTeamImage(mascot)} />
                </div>
                <h2 className={s.title}>NFL Injury Report: {teamName}</h2>
                <div className={s.controls}>
                    {injuryReportUrl ? <Button className={s.externalLinkButton} onClick={() => window.open(injuryReportUrl, '_blank')}><FontAwesomeIcon name='external-link-alt' /></Button> : null}
                    <span className={s.teamSelector}><NFLTeamSelector selectedTeam={nflTeam} /></span>
                </div>
            </div>
            <ListGroup className={s.reportList}>
            {items && items.map(i => 
            <>
            <ListGroup.Item key={`injury-date-${i.date}`} className={s.dateHeader}><h3>{i.date}</h3></ListGroup.Item>
            {i.injuries && i.injuries.map(x =>
            <ListGroup.Item key={`injury-date-${i.date}-${x.playerId}`} 
                className={s.injuryItem} action={!!x.linkUrl} onClick={x.linkUrl ? () => window.open(x.linkUrl, '_blank') : undefined}>
                <div className={s.content}>
                    
                    <div className={s.iconContainer}>
                        {x.imageUrl ? <Image src={x.imageUrl} /> : null}
                        {x.position ? <Badge bg='light' text='dark' className={s.positionBadge}>{x.position}</Badge> : null}
                    </div>
                    <div className={s.playerContainer}>
                        <div className={s.playerNameContainer}>
                            <h4 className={s.playerName}>{x.name}</h4>
                            {x.status ? 
                            <Badge className={s.statusBadge} 
                                bg={x.statusColor === 'red' ? 'danger' : x.statusColor === 'yellow' ? 'warning' : 'info'}
                                text={x.statusColor === 'red' ? 'light' : x.statusColor === 'yellow' ? 'dark' : 'light'}>
                                {x.status}
                            </Badge>
                            : null}
                        </div>
                        {x.description ? <div><p>{x.description}</p></div> : null}
                    </div>
                </div>
            </ListGroup.Item>
            )}
            </>
            )}
            {!items || !items.length ? <ListGroup.Item>No injuries reported.</ListGroup.Item> : null}
            </ListGroup>
        </div>
    )
}

export default NFLInjuryReport