import React, { useEffect } from 'react'
import { ListGroup } from 'react-bootstrap'
import PlayerListItem from '../../Player/PlayerListItem'
import { CURRENCY_FORMAT } from '@severed-links/common.redherrings-constants'
import numeral from 'numeral'

const UpdateManagerPayPalFrame = ({ week = 0, winners = [], entryFee = 0.00 }) => {

    const perCapita = winners && winners.length > 0 ? Math.round((parseFloat(entryFee) / parseFloat(winners.length)),2) : 0.00
    return (
        <div>
            <div style={{ marginBottom: '40px' }}>
                <h4>Weekly Winners for Week {week}</h4>
                <ListGroup>
                {winners && winners.map((w, index) => 
                <PlayerListItem key={`paypal-winner-${w._id}`}
                    player={w} detail={w.emailAddress}
                    rightDetail={numeral(w.amount).format(CURRENCY_FORMAT)} />
                )}
                {!winners || winners.length == 0 ? <ListGroup.Item key={999}>No winners located.</ListGroup.Item> : null}
                </ListGroup>
            </div>
            <div style={{ marginBottom: '40px', textAlign: 'center' }}>
                <a href='https://www.paypal.com/myaccount/home' target='_blank'>Launch payment in new window</a>
            </div>
        </div>
    )
}

export default UpdateManagerPayPalFrame
