import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { ListGroup, Badge, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './CinderellaSetupButtonList.scss'

const CinderellaSetupButtonList = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const year = useSelector(state => state.cinderellaAdmin.year)

    const steps = [
        { icon: 'pencil-alt', title: 'edit configuration', onClick: () => navigate('/cinderella/admin/utility/edit-configuration') },
        { icon: 'map', title: 'enter regions, multipliers', onClick: () => navigate('/cinderella/admin/utility/edit-rounds-and-regions') },
        { icon: 'trophy', title: 'enter bracket teams', onClick: () => navigate(`/cinderella/admin/tournament-teams/${year}`) },
        { icon: 'pencil-alt', title: 'write recap', onClick: () => navigate(`/cinderella/admin/recap/editor`) },
        { icon: 'envelope', title: 'send email', onClick: () => navigate('/cinderella/admin/utility/send-mail') }
    ]
    
    return (
        <div className={s.container}>
            <h4>New Tourney Setup Checklist</h4>
            <ListGroup className={s.setupButtonList}>
            {steps.map((x, index) =>
                <ListGroup.Item className={s.setupButtonListItem} 
                    action onClick={x.onClick} key={index}>
                    <div className={s.setupButtonListStepNumber}><Badge bg='secondary'>{index + 1}</Badge></div>
                    <div className={s.icon}><FontAwesomeIcon name={x.icon} /></div>
                    <div className={s.title}>{x.title}</div>
                </ListGroup.Item>
            )}
            </ListGroup>
        </div>
    )
}

export default CinderellaSetupButtonList