import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { useNavigate, useLocation } from 'react-router-dom'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import BaseAuthenticatedMenu from './BaseAuthenticatedMenu'
import { Row, Col } from 'react-bootstrap'
import * as s from './FootballMenuStatusBar.scss'
import moment from 'moment-timezone'
import * as menuStyle from './Menu.scss'
import { isEqual } from 'lodash'

const EliminatorMenuStatusBar = () => {

    const navigate = useNavigate()
    const location = useLocation()
    const seasonId = useSelector(state => state.eliminator.seasonId)
    const seasonName = useSelector(state => state.eliminator.seasonName)
    const seasonMode = useSelector(state => state.eliminator.seasonMode)
    const week = useSelector(state => state.eliminator.week)
    const playoffWeek = useSelector(state => state.eliminator.playoffWeek)
    const pickDeadline = useSelector(state => state.eliminator.pickDeadline)
    const playoffPickDeadline = useSelector(state => state.eliminator.playoffPickDeadline)
    const isFetching = useSelector(state => state.eliminator.isFetching || state.eliminatorAdmin.isFetching)
    const timeZone = useSelector(state => state.account.time_zone)
    const isRegularSeason = seasonMode === 'Regular'
    const isPostseason = seasonMode === 'Postseason'
    const [deadlineStatus, setDeadlineStatus] = useState('')
    const [afterDeadline, setAfterDeadline] = useState(false)
    const [afterPlayoffDeadline, setAfterPlayoffDeadline] = useState(false)
    const [playoffDeadlineStatus, setPlayoffDeadlineStatus] = useState('')
    const [timerId, setTimerId] = useState(null)
    const [urgentDeadline, setUrgentDeadline] = useState(false)
    const [currentTime, setCurrentTime] = useState(moment().toISOString(true))

    const checkDeadlines = () => {
        if (pickDeadline) {
            setDeadlineStatus(pickDeadlineStatus(pickDeadline))
            setAfterDeadline(moment().isAfter(moment(pickDeadline)))
        }
        if (playoffPickDeadline) {
            setPlayoffDeadlineStatus(pickDeadlineStatus(playoffPickDeadline))
            setAfterPlayoffDeadline(moment().isAfter(moment(playoffPickDeadline)))
        }

        //re-route on expired
        if ((seasonName === 'Regular' && afterDeadline && location.pathname === '/eliminator/enter-pick') ||
            (seasonName === 'Postseason' && afterPlayoffDeadline && location.pathname === '/eliminator/enter-pick')) {
                navigate('/eliminator')
            }
    }

    const pickDeadlineStatus = date => {
        if (!date || !moment(date).isValid() || !timeZone) return '---'

        var deadline = moment(date)
        var duration = moment.duration(deadline.diff(moment()))
        var iscurrentDate = deadline.isSame(moment(), 'day')

        var status = '---'
        if (!moment().isBefore(deadline)) {
            status = 'Passed'
        } else if (duration.asMinutes() <= 60) {
            status = deadline.fromNow()
        } else if (iscurrentDate) {
            status = deadline.tz(timeZone).format('h:mm a')
        } else {
            status = deadline.tz(timeZone).format('M/D/YYYY h:mm a')
        }
        return status
    }

    useEffect(() => {
        checkDeadlines()
        let timerId = setInterval(() => setCurrentTime(moment().toISOString(true)), 5 * 1000)
        return () => clearInterval(timerId)
    }, [])

    useEffect(() => { checkDeadlines() }, [seasonId, seasonMode, week, currentTime, pickDeadline, playoffPickDeadline])

    return (
        <div className={s.statusBar}>
            <div className={s.container}>
                {isFetching ? <FontAwesomeIcon name='circle-notch' spin className={s.spinner} /> : null}
                <span className={s.details}><b><FontAwesomeIcon name='leaf' /> <span className={s.hideSmall}>Season:</span></b> <span className={s.hideSmall}>{seasonName}</span> {seasonMode}</span>

                {isRegularSeason ? <span className={s.details}><b><FontAwesomeIcon name='calendar' /> Week:</b> {week}</span> : null}
                {isRegularSeason ? <span className={s.details + ' ' + (urgentDeadline ? s.urgent : null)}><b><FontAwesomeIcon name='clock' /> <span className={s.hideSmall}>Pick Deadline:</span></b> {deadlineStatus}</span> : null}

                {isPostseason ? <span className={s.details}><b><FontAwesomeIcon name='calendar' /> Week:</b> {playoffWeek}</span> : null}
                {isPostseason ? <span className={s.details + ' ' + (urgentDeadline ? s.urgent : null)}><b><FontAwesomeIcon name='clock' /> <span className={s.hideSmall}>Pick Deadline:</span></b> {playoffDeadlineStatus}</span> : null}
            </div>
        </div>
    )
}

export default EliminatorMenuStatusBar
