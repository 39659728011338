import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './TestSpinner.scss'

const TestSpinner = () => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)

    useEffect(() => {
    }, [])

    return (
        <div className={s.container}>
            <div className={s.spinnerContainer}>
                <FontAwesomeIcon name='circle-notch' spin />
            </div>
        </div>
    )
}

export default TestSpinner
