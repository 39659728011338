import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Row, Col, FormGroup, FormLabel, FormControl, Button, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { createAccount } from '@severed-links/common.redherrings-reducers/siteRegistration'
import * as s from './SignUp.scss'
import moment from 'moment-timezone'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'

const RegistrationDoneStep6 = forwardRef(({ jumpToStep }, _ref) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const siteRegistration = useSelector(state => state.siteRegistration)
    const _isValid = siteRegistration.registrationDone

    useEffect(() => {
        const postData = {
            acceptingNewRegistrations: true,
            passphrase: siteRegistration.passphrase,
            contestToEnter: siteRegistration.contest,
            userName: siteRegistration.userName,
            password: siteRegistration.password,
            confirmPassword: siteRegistration.password,
            emailAddress: siteRegistration.email,
            firstName: siteRegistration.firstName,
            lastName: siteRegistration.lastName,
            timezone: moment.tz.guess(),
        }
        dispatch(createAccount(postData))
        .then(action => {
            if (action.payload.messageType === 'error') {
                dispatch(createNotification({ headline: 'Create Account', message: action.payload.message, type: action.payload.messageType, timeout: 4000 }))
            }
        })
    }, [])

    useEffect(() => {
        if (siteRegistration.registrationDone) {
            setTimeout(() => navigate('/login'), 4000)
        }
    }, [siteRegistration.registrationDone])

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValid
    }))

    if (!siteRegistration) return null
    return (
        <div className={s.step}>
            <div className={s.alertContainer}>
                {!_isValid ?
                <Alert variant='info'>Sending your registration...</Alert>
                : null}
            </div>
            <div className={s.alertContainer}>
                {_isValid ?
                <Alert variant='success'>Your account has been created.  We'll send you to the login form now.</Alert>
                : null}
            </div>
        </div>
    )
})

export default RegistrationDoneStep6
