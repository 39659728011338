import React, { useEffect } from 'react'
import { ListGroup, Table } from 'react-bootstrap'
import { getNflStandings } from '@severed-links/common.redherrings-reducers/football'
import { find } from 'lodash'
import moment from 'moment-timezone'
import * as s from './NFLStandings.scss'
import NflTeam from '../../NflTeam'
import NflTeamListItem from '../../NflTeamListItem'
import Line from '../../Line'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as _style from './NFLTeamAnalysisGameResults.scss'

const NflTeamAnalysisLeftContainer = ({ x }) => (
    <div className={_style.leftContainer}>
        <div className={_style.weekContainer}>
            <div className={_style.weekHeading}>Week</div>
            <div className={_style.weekNumber}>{x.week}</div>
        </div>
        <div className={_style.dateContainer}>
            <div className={_style.month}>{moment(x.gameDate).local().format('MMM')}</div>
            <div className={_style.day}>{moment(x.gameDate).local().format('D')}</div>
        </div>
        <div className={_style.vsContainer} title={x.isHome ? 'Home' : 'Away'}>{x.isHome ? 'H' : 'A'}</div>
    </div>
)

const NflTeamAnalysisRightContainer = ({ x, isFlat = false }) => (
    <div className={_style.rightContainer + (isFlat ? ' ' + _style.isFlat : '')}>
        <div className={_style.scoreContainer + ' ' + _style[(x.resultAbbreviation || '').toLowerCase()]}>
            <div className={_style.scoreHeading}>{x.result}</div>
            <div className={_style.score}>{x.scoreString}</div>
        </div>                            
        <div className={_style.coverContainer + ' ' + _style[x.isCover ? 'cover' : x.isPush ? 'push' : 'noCover']}
            title={x.spreadString}>
            <div className={_style.spreadHeading}>{x.isCover || x.isNotCover || x.isPush ? x.spreadResult : ' '}</div>
            <div className={_style.spread} dangerouslySetInnerHTML={{ __html: x.displayLine }} />
        </div>
    </div>
)

const NFLTeamAnalysisGameResults = ({ seasonName = '', games = [], playoffGames = [] }) => {

    return (
            <div>
                <h4>{seasonName} Games</h4>
                <ListGroup>
                {games && games.map(x => 
                <NflTeamListItem key={`nfl-team-analysis-season-result-${x.teamId}-${seasonName}-${x.seasonMode}-week-${x.week}-${x.oppId}`}
                    teamId={x.oppId} teamName={x.oppName} mascot={x.oppMascot} city={x.oppCity}
                    seasonName={seasonName} miniHeader={x.miniHeader}
                    left={<NflTeamAnalysisLeftContainer x={x} />}
                    right={<NflTeamAnalysisRightContainer x={x} />}
                    detail={<NflTeamAnalysisRightContainer x={x} isFlat />} />
                )}
                {playoffGames && playoffGames.length > 0 ? 
                    <ListGroup.Item><h4 style={{ margin: '0px' }}>Postseason Results</h4></ListGroup.Item>
                : null}
                {playoffGames && playoffGames.map(x => 
                <NflTeamListItem key={`nfl-team-analysis-postseason-result-${x.teamId}-${seasonName}-${x.seasonMode}-week-${x.week}-${x.oppId}`}
                    teamId={x.oppId} teamName={x.oppName} mascot={x.oppMascot} city={x.oppCity}
                    seasonName={seasonName} miniHeader={x.miniHeader}
                    left={<NflTeamAnalysisLeftContainer x={x} />}
                    right={<NflTeamAnalysisRightContainer x={x} />} />
                )}
                </ListGroup>
            </div>
    )
}

export default NFLTeamAnalysisGameResults