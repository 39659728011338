import { useEffect } from 'react'
import { useSocket } from 'socket.io-react-hook'
import { SOCKET_URL } from '@severed-links/common.redherrings-constants'
import { useSelector } from 'react-redux'

export const useAuthenticatedSocket = () => {

    const isAuthenticated = useSelector(state => state.account.isAuthenticated)
    const access_token = useSelector(state => state.account.access_token)
    const isMyProfileLoaded = useSelector(state => state.account.isMyProfileLoaded)
    const _isEnabled = isAuthenticated && isMyProfileLoaded && !!access_token
    
    const _socket = useSocket(SOCKET_URL, {
        auth: { token: `Bearer ${access_token}` },
        enabled: _isEnabled,
    })

    return _socket
}
