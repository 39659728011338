import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'
import { getNFLCoverTrends } from '@severed-links/common.redherrings-reducers/football'
import { find } from 'lodash'
import SeasonSelector from '../../SeasonSelector'
import * as s from './NFLCoverTrends.scss'
import pluralize from 'pluralize'
import numeral from 'numeral'
import { List } from 'immutable'
import { getFootballUrlParams } from '@severed-links/common.redherrings-constants'

const BarItem = ({ label, pct, total }) => (
    total ?
    <div className={s.bar + ' ' + s[`${(label || '').toLowerCase()}`]} style={{ width: `${pct * 100.0}%` }}>
        <div className={s.header}>{pluralize(label, total, false)}</div>
        <div className={s.value}>{total}</div>
    </div>
    : null
)

const NFLCoverTrends = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const seasonName = params.seasonName || null
    const seasons = useSelector(state => state.football.seasons)
    const c = getFootballUrlParams(params, seasons)
    const [report, setReport] = useState(null)

    useEffect(() => {
        if (c.seasonId) {
            dispatch(getNFLCoverTrends(c.seasonId))
            .then(action => setReport(action.payload))
        }
    }, [c.seasonId])

    if (!c.season || !report) return null
    const { seasonId, items, totalGames, 
        totalFavorites, totalUnderdogs, totalPushes, 
        favPct, undPct, pushPct } = report
    const _legendItems = [
        { label: `Favorites`, total: totalFavorites, pct: favPct },
        { label: `Underdogs`, total: totalUnderdogs, pct: undPct },
        { label: `Pushes`, total: totalPushes, pct: pushPct },
    ]
    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}>{seasonName} NFL Cover Trends</h2>
                <div className={s.controls}>
                    <span><SeasonSelector seasons={seasons} season={c.season} /></span>
                </div>
            </div>
            <div className={s.legendContainer}>
                <div className={s.legend}>
                {_legendItems && _legendItems.map(i =>
                <div className={s.legendItem + ' ' + s[`${(i.label || '').toLowerCase()}`]} key={`nfl-cover-trends-legned-${i.label.toLowerCase()}`}>
                    {`${pluralize(i.label, i.total, true)} (${numeral(i.pct).format('0.0%')})`}
                </div>
                )}
                </div>
            </div>
            <div className={s.listContainer}>
                <ListGroup className={s.reportList}>
                {items && items.map(i =>
                <ListGroup.Item className={s.reportItem} key={`nfl-cover-trends-${seasonId}-week-${i.week}`}>
                    <div className={s.content}>
                        <div className={s.weekContainer}>
                            <div className={s.header}>Week</div>
                            <div className={s.value}>{i.week}</div>
                        </div>
                        <div className={s.gamesContainer}>
                            <div className={s.header}>Games</div>
                            <div className={s.value}>{i.gameCount}</div>
                        </div>
                        <div className={s.barContainer}>
                            <BarItem label={'Favorite'} total={i.favorites} pct={i.favPct} />
                            <BarItem label={'Underdog'} total={i.underdogs} pct={i.undPct} />
                            <BarItem label={'Push'} total={i.pushes} pct={i.pushPct} />
                        </div>
                    </div>
                </ListGroup.Item>
                )}
                {!items || !items.length ? <ListGroup.Item className={s.reportItem}>No completed games located for this season.</ListGroup.Item> : null}
                </ListGroup>
            </div>
        </div>
    )
}

export default NFLCoverTrends