import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './EliminatorWinners.scss'

const EliminatorWinnerNames = ({ isActiveSeason, hasWinner, hasTie, seasonName, winners, ...props }) => (
    (hasWinner || hasTie) && winners && winners.length ?
    <ListGroup variant='flush' className={s.winnerList}>
    {winners.map(i =>
    <ListGroup.Item className={s.winnerItem} key={`winner-${seasonName}-${i.playerId}`}>
        {i.firstName} {i.lastName}
    </ListGroup.Item>
    )}
    </ListGroup>
    : null
)

export default EliminatorWinnerNames
