import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Alert, Badge } from 'react-bootstrap'
import * as s from './Preseason.scss'
import PreseasonDivision from './PreseasonDivision'
import PreseasonCountdown from './PreseasonCountdown'
import PreseasonTeamRegistration from './PreseasonTeamRegistration'
import { loadPreseason, divisionStandings, teamRegistrationReset,
    checkTeamNameAvailability, teamRegistrationDivisionSelection, 
    teamRegistrationIsPaid, teamRegistrationRegisterTeam } from '@severed-links/common.redherrings-reducers/football'
import { forEach, some, uniq, sumBy, flatten, sum } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import pluralize from 'pluralize'

const Preseason = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const seasonName = params.seasonName || null
    const seasons = useSelector(state => state.football.seasons)
    const playerId = useSelector(state => state.account._id)
    const userName = useSelector(state => state.account.userName)
    const season = seasons.find(i => i.seasonName === seasonName)
    const seasonId = (season || {}).seasonId || null
    const preseason = useSelector(state => state.football.preseasons)[`${seasonId}`] || {}
    const leagueStandings = useSelector(state => state.football.leagueStandings)
    const standings = (leagueStandings.find(x => seasonId && x.seasonId === seasonId) || {}).leagueStandings || []
    const teamRegistration = useSelector(state => state.football.teamRegistration)
    const isTeamRegistrationOpen = useSelector(state => state.football.isTeamRegistrationOpen)
    const paymentEmail = useSelector(state => state.football.paymentEmail)
    const _allStandings = flatten((standings || []).map(i => i.standings))
    const hasTeamRegistered = _allStandings.some(i => i.playerId == playerId)
    const [registrationCheckCompleted, setRegistrationCheckCompleted] = useState(false)
    const [isInitialLoadComplete, setInitialLoadComplete] = useState(false)

    useEffect(() => { loadPreseasonData(seasonId) }, [seasonId])

    const getStandings = () => dispatch(divisionStandings(seasonId))

    const loadPreseasonData = () => {
        if (seasonId) {
            dispatch(loadPreseason(seasonId))
            .then(() => {
                getStandings(seasonId)
                setRegistrationCheckCompleted(true)
                setInitialLoadComplete(true)
            })
        }
    }

    if (!preseason) return null
    const { nflKickoff, divisions, entryFee, maxRegisteredTeams } = preseason
    var arrStandings = []
    if (standings) {
        forEach(standings, (d, index) => { 
            arrStandings.push(<Col lg={4} md={6} sm={6} xs={12} key={d.divisionCode} className={s.divisionCode}><PreseasonDivision division={d} playerId={playerId} /></Col>)
        })
    }
    const countTotals = (standings || []).map(d => (d.standings || []).length)
    const totalRegistrations = sum(countTotals)
    const spotsLeft = Math.max(0, maxRegisteredTeams - totalRegistrations)
    const counts = uniq(countTotals)
    const multipleLengths = counts.length > 1
    var spotsLeftVariant = 'info'
    if (spotsLeft <= 5) spotsLeftVariant = 'danger'
    else if (spotsLeft <= 10) spotsLeftVariant = 'warning'
    return (
        <div className={s.container}>
            <h2 className={s.preseasonHeading}>
                <div className={s.title}>Redherrings {seasonName} Preseason!</div>
                {isInitialLoadComplete && spotsLeft ? <Badge bg={spotsLeftVariant} text={['light','warning'].includes(spotsLeftVariant) ? 'dark' : 'light'} className={s.spotsLeft}>{pluralize('spot', spotsLeft, true)} left!</Badge> : null}
            </h2>
            {registrationCheckCompleted ?
            <Row>
                <Col sm={6} xs={12} className={s.centerCol}>
                    <PreseasonCountdown nflKickoff={nflKickoff}
                        prefix='The NFL season kicks off in...' 
                        smallPrefix='NFL in...'
                        elapsed='Game on!' />
                </Col>
                <Col sm={6} xs={12} className={s.centerCol}>

                {hasTeamRegistered ? 
                <Alert variant='success' className={s.alert}>
                    You have a team registered this season.
                    Stay tuned for details on entering your Week 1 picks
                    closer to the kickoff of this season!
                </Alert>
                : null}

                {isTeamRegistrationOpen && !hasTeamRegistered && spotsLeft ? 
                    <PreseasonTeamRegistration 
                        teamRegistration={teamRegistration} 
                        divisions={divisions} 
                        entryFee={entryFee}
                        loadPreseasonData={loadPreseasonData}
                        checkTeamNameAvailability={checkTeamNameAvailability} 
                        playerId={playerId}
                        paymentEmail={paymentEmail}
                        userName={userName}
                        teamRegistrationReset={teamRegistrationReset}
                        teamRegistrationDivisionSelection={teamRegistrationDivisionSelection}
                        teamRegistrationIsPaid={teamRegistrationIsPaid}
                        teamRegistrationRegisterTeam={teamRegistrationRegisterTeam}
                        seasonId={seasonId} 
                        seasonName={seasonName} />
                : null}

                {!isTeamRegistrationOpen && spotsLeft > 0 ? 
                <Alert variant='info' className={s.alert}>
                    Check back later for the {seasonName} season.  Preseason team registration is not open right now.
                </Alert>
                : null}

                {!hasTeamRegistered && spotsLeft == 0 ? 
                <Alert variant='danger' className={s.alert}>
                    There are no spots left in the league for the {seasonName} season.  Try back next year!
                </Alert>
                : null}

                </Col>
            </Row>
            : null}
            <Row>
                {arrStandings}
            </Row>
            <Row>
                <Col xs={12} className={s.centerCol}>
                {multipleLengths ? 
                    <Alert variant='warning' className={s.multipleLengthsAlert}>
                        We may have to move teams around to different divisions 
                        to equal out the number of teams in each division.  This will 
                        likely occur without notice as we get the team roster ready 
                        for the season.
                    </Alert>
                : null}
                </Col>
            </Row>
        </div>
    )
}

export default Preseason