import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { Row, Col, Alert } from 'react-bootstrap'
import * as s from './TeamProfile.scss'
import { getTeamProfile } from '@severed-links/common.redherrings-reducers/football'
import CloudinaryImage from '../../Cloudinary/CloudinaryImage'
import TeamProfileHeader from './TeamProfileHeader'
import DivisionStandings from '../DivisionStandings'
import YouMayRememberMeAs from './YouMayRememberMeAs'
import SeasonSchedule from './SeasonSchedule'
import { getFootballUrlParams } from '@severed-links/common.redherrings-constants'
import { find } from 'lodash'
import Spinner from '@severed-links/common.spinner'

const TeamProfile = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const playerId = useSelector(state => state.account._id)
    const seasons = useSelector(state => state.football.seasons)
    const seasonName = useSelector(state => state.football.seasonName)
    const teamProfiles = useSelector(state => state.football.teamProfiles)
    const c = getFootballUrlParams(params, seasons)

    useEffect(() => { 
        getProfile()
    }, [c.teamId])

    const getProfile = () => c.teamId ? dispatch(getTeamProfile(c.teamId)) : null

    const profile = find(teamProfiles, x => x._id === c.teamId)
    const isMe = profile && profile._id && playerId === profile.playerId

    return (
        profile && !profile.error && profile.teamName ?
        <div>
            <Row>
                <Col lg={8} md={6} sm={12} xs={12}>
                    <Row>
                        <Col xs={12}>
                            <TeamProfileHeader profile={profile} playerId={playerId} isMe={isMe} />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg={6} md={12}>
                            <DivisionStandings division={profile.divisionStandings}
                                playerId={playerId} shortForm />
                        </Col>
                        <Col lg={6} md={12}>
                            <YouMayRememberMeAs youMayRememberMeAs={profile.youMayRememberMeAs} />
                        </Col>
                    </Row>
                </Col>
                <Col lg={4} md={6} sm={12} xs={12}>
                    <SeasonSchedule schedule={profile.seasonSchedule} seasonName={seasonName} teamName={profile.teamName} />
                </Col>
            </Row>
        </div>
        : profile && profile.error ? <Alert variant={profile.messageType}>{profile.message}</Alert>
        : <div style={{ padding: '150px 25px', textAlign: 'center' }}><Spinner size='3x' /></div>
    )
}

export default TeamProfile