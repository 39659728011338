import React, { useEffect } from 'react'
import { Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './PostseasonPastMatchup.scss'
import { first, last } from 'lodash'
import PlayerTeam from '../PlayerTeam'

const PostseasonPastMatchup = ({ matchup = {} }) => {

    if (!matchup || !matchup.teams || matchup.teams.length !== 2) return null
    var t1 = first(matchup.teams)
    var t2 = last(matchup.teams)
    if (t1.score > 0 && t2.score > 0 && t2.score > t1.score) [t1, t2] = [t2, t1]
    return (
        <div className={s.container}>
            <div className={s.gameItem}>
                <div className={s.teamItem}>
                    <div className={s.seed}><Badge bg='light' text='dark'>{t1.displaySeed}</Badge></div>
                    <div className={s.team}><PlayerTeam teamId={t1.teamId} teamName={t1.name} avatarUrl={t1.avatarUrl} /></div>
                    <div className={s.score}>{t1.score}</div>
                </div>
                <div className={s.teamItem + ' ' + s.second}>
                    <div className={s.seed}><Badge bg='light' text='dark'>{t2.displaySeed}</Badge></div>
                    <div className={s.team}><PlayerTeam teamId={t2.teamId} teamName={t2.name} avatarUrl={t2.avatarUrl} /></div>
                    <div className={s.score}>{t2.score}</div>
                </div>
            </div>
        </div>
    )
}

export default PostseasonPastMatchup