import React, { useEffect, useState } from 'react'
import CloudinaryImage from '../Cloudinary/CloudinaryImage'
import { Badge } from 'react-bootstrap'
import * as s from './NflTeam.scss'

const OverUnder = ({ overUnder = null }) => {

    if (!overUnder) return null
    return (
        <span>
            {!isNaN(overUnder) && overUnder !== parseInt(overUnder, 10) ? parseInt(overUnder,10).toString() + '\u00BD' : overUnder.toString()}
        </span>
    )
}

export default OverUnder