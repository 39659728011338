import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Row, Col, Form, FormGroup, FormControl, FormLabel, InputGroup, Button, 
    Table, Badge, ButtonToolbar, ButtonGroup, ListGroup, Card } from 'react-bootstrap'
import * as s from './OscarsNominees.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { getNominees, getNomineesByCategory, updateNominees } from '@severed-links/common.redherrings-reducers/oscarsAdmin'
import { trim, endsWith, forEach, orderBy } from 'lodash'
import YearSelector from '../YearSelector'
import Confirm from '@severed-links/common.confirm'

const OscarsNominees = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const params = useParams()
    const navigate = useNavigate()
    const _year = useSelector(state => state.oscars.year)
    const year = parseInt(params.year || '0')
    const [categories, setCategories] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [nominees, setNominees] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [bulkLoad, setBulkLoad] = useState('')

    useEffect(() => { getData() }, [year])

    useEffect(() => { doBulkLoad() }, [bulkLoad])

    const getData = () => {
        setSelectedCategory(null)
        setNominees([])
        setLoading(true)
        dispatch(getNominees(year))
        .then(action => {
            setCategories(action.payload.categories)
            setSelectedCategory(null)
            setNominees([])
            setLoading(false)
        })
    }

    const selectCategory = _selectedCategory => {
        setLoading(true)
        setNominees([])
        setSelectedCategory(_selectedCategory)
        dispatch(getNomineesByCategory(year, _selectedCategory.num))
        .then(action => {
            setNominees([...action.payload])
            setLoading(false)
        })
    }

    const clearSelectedCategory = () => {
        setSelectedCategory(null)
        setNominees([])
        setLoading(false)
    }

    const doBulkLoad = () => {
        const lines = bulkLoad.split(/\n|\r/)
        var noms = [...nominees]
        forEach(lines, (l, index) => {
            const trimmedLine = trim(l).replace(/\“|\”/, '')
            if (trimmedLine) {
                noms.push({ choice: String.fromCharCode(65 + noms.length), choiceText: trimmedLine, num: selectedCategory.num, year, isEditing: true })
            }
        })
        setNominees([...noms])
        setBulkLoad('')
    }

    const handleChange = (e, index) => {
        var noms = [...nominees]
        noms[index][e.target.name] = e.target.value
        setNominees(orderBy([...noms], ['choice'], ['asc']))
    }

    const setEditMode = (index, isEditing) => {
        var noms = [...nominees]
        noms[index]['isEditing'] = isEditing
        setNominees(orderBy([...noms], ['choice'], ['asc']))
    }

    const deleteNomineeFromList = index => {
        var noms = [...nominees]
        noms.splice(index, 1)
        forEach(noms, (n, i) => noms[i].choice = String.fromCharCode(65 + i))
        setNominees(orderBy([...noms], ['choice'], ['asc']))
    }

    const saveChanges = () => {
        const postData = { year, num: selectedCategory.num, nominees: orderBy([...nominees], ['Choice'], ['asc']) }
        setSelectedCategory(null)
        setNominees([])
        dispatch(updateNominees(postData))
        .then(action => {
            dispatch(createNotification({ headline: 'Update Nominees', message: action.payload.message, type: action.payload.messageType, timeout: 3000 }))
            getData()
        })
    }

    const addItem = () => {
        var noms = [...nominees]
        noms.push({ choice: String.fromCharCode(65 + noms.length), choiceText: '', num: selectedCategory.num, year, isEditing: true })
        setNominees(noms)
    }

    return (
        <div className={s.container}>
            <div className={s.header}>
                <h2 className={s.title}>{year} Nominees</h2>
                <div className={s.controls}><YearSelector defaultYear={year} /></div>
            </div>
            <Row>
                <Col sm={6} xs={12}>
                    <ListGroup className={s.categoryList}>
                    {categories && categories.map(c =>
                        <ListGroup.Item className={s.categoryItem + ' ' + (c.noms ? s.isComplete : null)} 
                            action onClick={() => selectCategory(c)}
                            key={`nominess-admin-${year}-category-${c.num}`}>
                            <div className={s.num}>{c.num}</div>
                            <div className={s.category}>{c.category}</div>
                            <div className={s.noms}>{c.noms}</div>
                        </ListGroup.Item>
                    )}

                    </ListGroup>
                </Col>
                {selectedCategory ?
                <Col sm={6} xs={12}>
                    <Card>
                        <Card.Header as='h4'>Edit Nominees: {selectedCategory.category}</Card.Header>
                        <ListGroup variant='flush' className={s.choiceList}>

                        {!isLoading && (!nominees || nominees.length === 0) ?
                            <ListGroup.Item className={s.choiceItem}>
                                No nominees located for '{selectedCategory.category}'.
                            </ListGroup.Item>
                        : null}

                        {nominees && nominees.map((n, index) =>
                            <ListGroup.Item className={s.choiceItem + (n.isEditing ? ' ' + s.isEditing : '')} key={`choice-${year}-nominees-${n.choice}`}>
                            {n.isEditing ? 
                                <>
                                    <div className={s.choiceIndicator}>
                                        <FormControl name='choice' value={nominees[index].choice} 
                                            className='text-center'
                                            onChange={e => handleChange(e, index)} />
                                    </div>
                                    <div className={s.choiceText}>
                                        <InputGroup>
                                            <FormControl name='choiceText' value={nominees[index].choiceText} 
                                                onChange={e => handleChange(e, index)} />
                                            <Button variant='primary' onClick={() => setEditMode(index, false)}>
                                                <FontAwesomeIcon name='check' />
                                            </Button>
                                            <Confirm variant='danger' onConfirm={() => deleteNomineeFromList(index)}
                                                title={'Delete Nominee from List'}
                                                confirmText='confirm delete nominee'
                                                body={'Are you sure you want to delete this nominee?'}
                                                buttonIcon='trash' />
                                        </InputGroup>
                                    </div>
                                </>
                            :
                                <>
                                    <div className={s.choiceIndicator}><Badge bg='light' text='dark'>{n.choice}</Badge></div>
                                    <div className={s.choiceText}>{n.choiceText}</div>
                                    <div className={s.choiceEdit}><Button variant='light' onClick={() => setEditMode(index, true)}><FontAwesomeIcon name='pencil-alt' /></Button></div>
                                    <div className={s.choiceDelete}>
                                        <Confirm variant='danger' onConfirm={() => deleteNomineeFromList(index)}
                                            title={'Delete Nominee from List'}
                                            confirmText='confirm delete nominee'
                                            body={'Are you sure you want to delete this nominee?'}
                                            buttonIcon='trash' />
                                    </div>
                                </>
                            }
                            </ListGroup.Item>
                        )}

                        </ListGroup>
                        {!isLoading && (!nominees || nominees.length === 0) ?
                        <Card.Body>
                            <FormGroup>
                                <FormLabel>Bulk import list of nominees</FormLabel>
                                <FormControl as='textarea' rows={6} 
                                    onChange={e => setBulkLoad((e.target.value || '').replace(/\“|\”/g, ''))}
                                    placeholder='[Paste your nominees for this category here here...]' />
                            </FormGroup>
                        </Card.Body>
                        : null}
                        <Card.Footer className={s.choiceFooter}>
                            <Button variant='light' onClick={() => clearSelectedCategory()}>
                                <FontAwesomeIcon name='times' /> cancel
                            </Button>
                            <Button onClick={() => saveChanges()}>
                                <FontAwesomeIcon name='check' /> save changes
                            </Button>
                            <Button variant='light' onClick={() => addItem()}>
                                <FontAwesomeIcon name='plus-circle' /> add nominee
                            </Button>
                        </Card.Footer>
                    </Card>
                    <div>
                    <ul className={s.choiceList}>
                    {nominees && nominees.map((n, index) =>
                        <li key={n.choice} className={s.choiceItem}>
                        </li>
                    )}
                    </ul>
                    </div>
                    </Col>
                : null}
            </Row>
        </div>
    )
}

export default OscarsNominees