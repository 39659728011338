import React, { useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'

const EnterPostseasonPicksAutoGenerationAlert = ({ isAutoGenerated = false }) => {

    return (
        isAutoGenerated ?
        <Alert variant='warning'>
            These picks are auto generated and will not be saved unless you press the save button.
        </Alert>
        : null
    )
}

export default EnterPostseasonPicksAutoGenerationAlert
