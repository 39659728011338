// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OscarsBroadcastMessage__container___twEWa .OscarsBroadcastMessage__formGroup___k9yhJ{margin-bottom:1rem}.OscarsBroadcastMessage__container___twEWa .OscarsBroadcastMessage__formGroup___k9yhJ .OscarsBroadcastMessage__readOnlyInput____GPpJ{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/routes/Oscars/Admin/OscarsBroadcastMessage.scss"],"names":[],"mappings":"AACI,sFACI,kBAAA,CAEA,qIACI,cAAA","sourcesContent":[".container {\n    .formGroup {\n        margin-bottom: 1rem;\n\n        .readOnlyInput {\n            cursor: pointer;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `OscarsBroadcastMessage__container___twEWa`;
export var formGroup = `OscarsBroadcastMessage__formGroup___k9yhJ`;
export var readOnlyInput = `OscarsBroadcastMessage__readOnlyInput____GPpJ`;
export default ___CSS_LOADER_EXPORT___;
