import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Row, Col, Button, Nav, Navbar, FormGroup, Form, InputGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getTeamsFromEspnWebsite, getBracketBuilderTournamentDates } from '@severed-links/common.redherrings-reducers/cinderellaAdmin'
import Datetime from 'react-datetime'
import * as s from './CinderellaAdminRound1Dates.scss'
import moment from 'moment-timezone'
import { find, isEqual } from 'lodash'
import YearSelector from '../../Oscars/YearSelector'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'

const CinderellaAdminRound1Dates = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const bracketBuilderTournamentDates = useSelector(state => state.cinderellaAdmin.bracketBuilderTournamentDates)
    const bracketBuilderTournamentDatesLoaded = useSelector(state => state.cinderellaAdmin.bracketBuilderTournamentDatesLoaded)
    const [startDate, setStartDate] = useState(moment().format('M/D/YYYY'))
    const [endDate, setEndDate] = useState(moment().format('M/D/YYYY'))
    const year = parseInt(params.year || '0')

    useEffect(() => {
        if (year) {
            dispatch(getBracketBuilderTournamentDates())
        }
    }, [year])

    useEffect(() => {
        if (year && bracketBuilderTournamentDatesLoaded) {
            loadDefaultStartEndDates(true)            
        }
    }, [year, bracketBuilderTournamentDatesLoaded])

    useEffect(() => { loadEspnTeams() }, [startDate, endDate])

    const loadDefaultStartEndDates = (autoFetch = false) => {
        const tournamentDates = bracketBuilderTournamentDates.find(i => i.year === year)
        if (bracketBuilderTournamentDatesLoaded && tournamentDates) {
            setStartDate(tournamentDates.startDateDisplay)
            setEndDate(tournamentDates.endDateDisplay)
        } else if (bracketBuilderTournamentDatesLoaded && !tournamentDates) {
            dispatch(createNotification({ headline: 'Tournament Dates Needed', type: 'error', message: 'Tournament dates for ' + year + ' not located.', timeout: 5000 }))
        }
    }

    const loadEspnTeams = () => dispatch(getTeamsFromEspnWebsite(year))

    const handleDateChange = (dt, fieldName) => {
        if (fieldName === 'startDate') {
            setStartDate(moment(dt, 'M/D/YYYY').format('M/D/YYYY'))
            setEndDate(moment(dt, 'M/D/YYYY').add(1, 'day').format('M/D/YYYY'))
        } else if (fieldName === 'endDate') {
            setEndDate(moment(dt, 'M/D/YYYY').format('M/D/YYYY'))
        }
    }

    return (
        <Navbar bg='light' variant='light' expand='md' className={s.container}>
            <Navbar.Brand>Round 1</Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse>
                <Form className={s.form}>
                    <FormGroup className='mr-2'>
                        <InputGroup>
                        <InputGroup.Text><FontAwesomeIcon name='calendar' /></InputGroup.Text>
                        <Datetime value={startDate} closeOnSelect
                            title={'Round 1 Thursday for ESPN Lookup'}
                            dateFormat='M/D/YYYY' 
                            timeFormat={false}
                            className={s.datepickerContainer}
                            isValidDate={(currentDate, selectedDate) => { return moment(selectedDate).isAfter(`3/1/${year}`) && moment(selectedDate).isBefore(`3/31/${year}`)}}
                            onChange={dt => handleDateChange(dt, 'startDate')} />
                        </InputGroup>
                    </FormGroup>
                    <FormGroup className='mr-2'>
                        <InputGroup>
                        <InputGroup.Text><FontAwesomeIcon name='calendar' /></InputGroup.Text>
                        <Datetime value={endDate} closeOnSelect
                            title={'Round 1 Friday for ESPN Lookup'}
                            dateFormat='M/D/YYYY' 
                            timeFormat={false}
                            className={s.datepickerContainer}
                            isValidDate={(currentDate, selectedDate) => { return moment(selectedDate).isAfter(`3/1/${year}`) && moment(selectedDate).isBefore(`3/31/${year}`)}}
                            onChange={dt => handleDateChange(dt, 'endDate')} />
                        <Button variant='primary' title='Download teams from espn.com'
                            onClick={() => loadEspnTeams()}>
                            <FontAwesomeIcon name='download' />
                        </Button>
                        </InputGroup>                            
                    </FormGroup>
                </Form>
                <Form className={s.form + ' ' + s.editRoundDatesYearSelectorContainer} title='Change yeat for brackets'>
                    <YearSelector defaultYear={year} 
                        buttonTitle={null} buttonname={'calendar'} />
                </Form>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default CinderellaAdminRound1Dates