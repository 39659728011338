// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CaptureGameLog__container___GNKee{margin-bottom:1.5rem}.CaptureGameLog__container___GNKee .CaptureGameLog__heading___a9O5F{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center}.CaptureGameLog__container___GNKee .CaptureGameLog__heading___a9O5F .CaptureGameLog__backIcon___EhceD{flex:0 0 0;margin-right:1.5rem}.CaptureGameLog__container___GNKee .CaptureGameLog__heading___a9O5F .CaptureGameLog__title___D5qxD{flex:1 1 auto}`, "",{"version":3,"sources":["webpack://./src/routes/Capture/CaptureGameLog.scss"],"names":[],"mappings":"AAAA,mCACI,oBAAA,CAEA,oEACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CAEA,sGACI,UAAA,CACA,mBAAA,CAGJ,mGACI,aAAA","sourcesContent":[".container {\n    margin-bottom: 1.5rem;\n\n    .heading {\n        display: flex;\n        flex-flow: row nowrap;\n        justify-content: space-between;\n        align-items: center;\n\n        .backIcon {\n            flex: 0 0 0;\n            margin-right: 1.5rem;\n        }\n\n        .title {\n            flex: 1 1 auto;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `CaptureGameLog__container___GNKee`;
export var heading = `CaptureGameLog__heading___a9O5F`;
export var backIcon = `CaptureGameLog__backIcon___EhceD`;
export var title = `CaptureGameLog__title___D5qxD`;
export default ___CSS_LOADER_EXPORT___;
