// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LeagueSchedulerControls__container___QzCv9{margin:1rem 0px;flex-flow:row nowrap;justify-content:space-between;align-items:center}.LeagueSchedulerControls__container___QzCv9 .LeagueSchedulerControls__buttonGroup___H27R0{flex:1 1 auto;margin-right:1rem}.LeagueSchedulerControls__container___QzCv9 .LeagueSchedulerControls__buttonGroup___H27R0:last-of-type{margin-right:0px}.LeagueSchedulerControls__container___QzCv9 .LeagueSchedulerControls__buttonGroup___H27R0 .LeagueSchedulerControls__button___Spjff{flex:1 0 0}`, "",{"version":3,"sources":["webpack://./src/routes/Football/Admin/LeagueSchedulerControls.scss"],"names":[],"mappings":"AAAA,4CACI,eAAA,CAEA,oBAAA,CACA,6BAAA,CACA,kBAAA,CAEA,0FACI,aAAA,CACA,iBAAA,CAEA,uGACI,gBAAA,CAGJ,mIACI,UAAA","sourcesContent":[".container {\n    margin: 1rem 0px;\n\n    flex-flow: row nowrap;\n    justify-content: space-between;\n    align-items: center;\n\n    .buttonGroup {\n        flex: 1 1 auto;\n        margin-right: 1rem;\n\n        &:last-of-type {\n            margin-right: 0px;\n        }\n\n        .button {\n            flex: 1 0 0;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `LeagueSchedulerControls__container___QzCv9`;
export var buttonGroup = `LeagueSchedulerControls__buttonGroup___H27R0`;
export var button = `LeagueSchedulerControls__button___Spjff`;
export default ___CSS_LOADER_EXPORT___;
