import React, { useEffect } from 'react'
import { Button, Badge, Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { nflTeamImage } from '@severed-links/common.redherrings-constants'
import * as s from './FavoriteUnderdogButton.scss'
import NflTeamListItem from '../NflTeamListItem'
import NflTeam from '../NflTeam'

const FavoriteUnderdogButton = ({ game = {}, pick = 'Favorite', onClick, isMini = false }) => {

    const _isPicked = game.pick === pick
    const _isHome = (game.homeFavorite && pick === 'Favorite') || (!game.isHomeFavorite && pick !== 'Favorite')
    const _homePrefix = _isHome ? '@' : ''
    const _isFavorite = pick === 'Favorite'
    return (
        <div className={s.container}>
            <NflTeamListItem className={s.mainButton + (_isPicked ? ' ' + s.isPicked : '')}
                teamId={game[`${pick}TeamId`]} 
                teamName={game[`${pick.toLowerCase()}Team`]}
                mascot={game[`${pick.toLowerCase()}Mascot`]} 
                record={game[`${pick.toLowerCase()}Record`]} 
                right={_isHome && _isFavorite ? <div className={s.homeIconContainer + (_isPicked ? ' ' + s.isPicked : '')}><FontAwesomeIcon name='home' /></div> : null}
                left={_isHome && !_isFavorite ? <div className={s.homeIconContainer + (_isPicked ? ' ' + s.isPicked : '')}><FontAwesomeIcon name='home' /></div> : null}
                variant={_isPicked ? 'info' : 'light'}
                alignRight={!_isFavorite}
                onClick={() => onClick(game.game, pick)} />
            <Button className={s.miniButton + ' ' + (_isFavorite ? s.isFavorite : s.isUnderdog) + (_isPicked ? ' ' + s.isPicked : '')}
                onClick={() => onClick(game.game, pick)}>
                <Image className={s.teamImage} src={`${nflTeamImage(game[`${pick.toLowerCase()}Mascot`])}`} />
                <div className={s.teamAbbrev}>{`${_homePrefix}${game[`${pick.toLowerCase()}TeamMobile`]}`}</div>
            </Button>
        </div>
    )
}

export default FavoriteUnderdogButton
