import React, { useEffect, useState } from 'react'
import { Button } from 'react-bootstrap'
import * as s from '../RegularSeason/EnterPicksGameItem.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { filter } from 'lodash'
import FavoriteUnderdogButton from '../RegularSeason/FavoriteUnderdogButton'
import LineContainer from '../RegularSeason/LineContainer'
import RankButtonsContainer from '../RegularSeason/RankButtonsContainer'

import { useDrag } from 'react-dnd'
import { DRAG_AND_DROP_ITEM_TYPES } from '@severed-links/common.redherrings-constants'
import { useDispatch } from 'react-redux'

const EnterPostseasonPicksGameItem = ({ seasonName = null, game = {}, pickGameData, ranks = [], addCommand }) => {

    const dispatch = useDispatch()
    const [showGameData, setShowGameData] = useState(false)
    const [showRankOptions, setShowRankOptions] = useState(false)
    const [tiebreaker, setTiebreaker] = useState('0')

    const [{ isDragging }, _dragRef, _previewRef] = useDrag(() => ({
        type: DRAG_AND_DROP_ITEM_TYPES.POSTSEASON_GAME_PICK,
        item: { game: game.game },
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            if (dropResult && addCommand) {
                addCommand({ type: 'changeRank', ...dropResult, game: game.game, oldRank: game.rank })
            }    
        }
    }), [game.game, game.rank])
    
    const handleClick = (game, pick) => addCommand({ type: 'makePick', game, pick })

    const getGameData = () => {
        setShowGameData(true)
        if (!pickGameData && seasonName && game) {
            dispatch(getEnterPicksNFLMatchUp(seasonName, game.week, game.game))
        }
    }

    const hideGameData = () => setShowGameData(false)

    const toggleMiniRankMenu = (e, _showRankOptions) => {
        setShowRankOptions(_showRankOptions)
        e.stopPropagation()
    }

    const handleMiniRankClick = (e, newRank) => {
        toggleMiniRankMenu(e, false)
        addCommand({ type: 'changeRank', ...{ newRank }, game: game.game, oldRank: game.rank })
    }

    const isMini = window.innerWidth <= 768
    return (
        <div ref={_previewRef} className={s.container + (isDragging ? ' ' + s.isDragging : '')}>

            <div ref={_dragRef} className={s.sortHandleContainer} onClick={e => toggleMiniRankMenu(e, !showRankOptions)}>
                <FontAwesomeIcon name='sort' />
            </div>

            {showRankOptions ?
            <div>
                <div>
                    {filter(ranks, r => r !== game.rank).map(i => <Button variant='light' onClick={e => handleMiniRankClick(e, i)} key={`rank-button-${game.game}-${i}`}>{i}</Button>)}
                </div>
            </div>
            : null}
            
            <RankButtonsContainer ranks={ranks} 
                game={game}
                show={showRankOptions} 
                onClick={handleMiniRankClick} 
                onClose={toggleMiniRankMenu} />
            <FavoriteUnderdogButton game={game} pick='Favorite' isMini={isMini} onClick={handleClick} />
            <LineContainer game={game} isMini={isMini} />
            <FavoriteUnderdogButton game={game} pick='Underdog' isMini={isMini} onClick={handleClick} />
        </div>
    )
}
    
export default EnterPostseasonPicksGameItem
    