import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import SeasonSelector from '../SeasonSelector'
import * as s from './FootballAdmin.scss'
import { find, sumBy } from 'lodash'
import { getPayouts } from '@severed-links/common.redherrings-reducers/football'
import { CURRENCY_FORMAT } from '@severed-links/common.redherrings-constants'
import numeral from 'numeral'
import Payouts from '../RegularSeason/Payouts'
import PayoutChecklist from './PayoutChecklist'
import Modal from '@severed-links/common.modal'

const ShowPayouts = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const seasons = useSelector(state => state.football.seasons)
    const seasonName = params.seasonName || null
    const season = seasons.find(i => i.seasonName === seasonName)
    const seasonId = (season || {}).seasonId || null
    const payouts = useSelector(state => state.football.payouts.find(i => i.seasonName === seasonName))
    const [showPayoutChecklist, setShowPayoutChecklist] = useState(false)

    useEffect(() => { getPayoutData() }, [seasonId])

    const getPayoutData = () => seasonId ? dispatch(getPayouts(seasonId)) : null

    if (!season || !payouts) return null
    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}>Show {payouts.seasonName} Payouts</h2>
                <div className={s.controls}>
                    <Button onClick={() => setShowPayoutChecklist(true)}><FontAwesomeIcon name='list-alt' /> show payout checklist</Button>
                    <span className={s.seasonSelector}><SeasonSelector seasons={seasons} season={season} /></span>
                </div>
            </div>
            <Payouts />
            <Modal show={showPayoutChecklist}
                heading={`${seasonName} Payout Checklist`}
                showFooter={false} onClose={() => setShowPayoutChecklist(false)}>
                <PayoutChecklist seasonId={seasonId} />
            </Modal>
        </div>
    )
}

export default ShowPayouts