import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Table, Alert, Navbar, Nav, NavDropdown, Dropdown, Card, ListGroup } from 'react-bootstrap'
import { filter, forEach, find, indexOf } from 'lodash'
import * as s from './ViewPicks.scss'
import { viewPicks } from '@severed-links/common.redherrings-reducers/oscars'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Player from '../Player/Player'
import moment from 'moment-timezone'

const ViewPicksNavbar = ({ players, selectedPlayer, categories, selectedCategory, onSelectPlayer, onSelectCategory }) => (
<Navbar bg='light' variant='light' className={s.navbar}>
    <Nav className={s.nav} onClick={e => e.stopPropagation()}>
        <NavDropdown className={s.playerDropdown} title={<span>{selectedPlayer ? <Player playerId={selectedPlayer.playerId} avatarUrl={selectedPlayer.avatarUrl} firstName={selectedPlayer.firstName} lastName={selectedPlayer.lastName} fullName={selectedPlayer.fullName} /> : <span>[Select a user...]</span>}</span>} id='oscars-view-picks-nav-by-user'>
        {players && players.map(p =>
            <Dropdown.Item key={p.playerId} eventKey={p.playerId} 
                onClick={() => onSelectPlayer(p)}>
                <Player playerId={p.playerId} avatarUrl={p.avatarUrl} 
                    firstName={p.firstName} lastName={p.lastName} 
                    fullName={p.fullName} />
            </Dropdown.Item>
        )}
        </NavDropdown>
        <NavDropdown title={<span>{selectedCategory ? <span><b>Category</b>: {selectedCategory.category}</span> : <span>[Select a category...]</span>}</span>} id='oscars-view-picks-nav-by-category'>
        {categories && categories.map(c =>
            <Dropdown.Item key={c.num} eventKey={c.num.toString()}
                onClick={() => onSelectCategory(c)}>
                {c.category}
            </Dropdown.Item>
        )}
        </NavDropdown>
    </Nav>
</Navbar>

)

const ViewPicks = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const _year = useSelector(state => state.oscars.year)
    const playerId = useSelector(state => state.account._id)
    const [year, setYear] = useState(_year)
    const [_id, setId] = useState('')
    const [picks, setPicks] = useState({})
    const [selectedUser, setSelectedUser] = useState(null)
    const [selectedCategory, setSelectedCategory] = useState(null)
    const players = picks.players || []
    const categories = filter(picks.categories || [], i => i.num > 0)

    useEffect(() => { initializeData() }, [_year])
    useEffect(() => { fetchData() }, [year, _id])

    const initializeData = () => {
        var newYear = parseInt((params || {}).year, 10)
        if (!_id || year !== newYear) {
            setId(playerId)
            setYear(newYear)
        }
    }

    const fetchData = () => {
        if (year && _id) {
            dispatch(viewPicks(year, _id))
            .then(action => {
                setPicks(action.payload)
                if (action.payload.listToDisplay === 'ByPlayer') {
                    setSelectedUser(action.payload.players.find(i => i.fullName === action.payload.selectedPlayer))
                    setSelectedCategory(null)
                } else if (action.payload.listToDisplay === 'ByCategory') {
                    setSelectedCategory(action.payload.categories.find(i => `${i.num}` === `${action.payload.selectedItem}`))
                    setSelectedUser(null)
                }
            })
        }
    }


    return (
        <div className={s.container}>
            <h2>{year} Picks</h2>
            <ViewPicksNavbar players={players} categories={categories}
                selectedPlayer={selectedUser} selectedCategory={selectedCategory} 
                onSelectPlayer={p => setId(p.playerId)} 
                onSelectCategory={c => setId(`${c.num}`)} />
            
            {picks.response && picks.response.message && picks.response.messageType ? 
                <Alert variant={picks.response.messageType} className={s.alert}>
                    {picks.response.message}
                </Alert>
            : null}
            
            {selectedCategory ? 
            <Card>
                <Card.Header as='h4'>Category Picks: {selectedCategory.category}</Card.Header>
                <ListGroup variant='flush' className={s.categoryList}>
                {picks.categoryPicks && picks.categoryPicks.map(p => 
                    <ListGroup.Item key={`view-pick-by-category-${p.num}-${p.choice}`} className={s.categoryItem + (p.result === 'Correct' ? ' ' + s.correct : '') + (p.result === 'Incorrect' ? ' ' + s.incorrect : '')}>
                        <div className={s.categoryContent}>
                            <div className={s.nominee}>{p.choiceText}</div>
                            <div className={s.pickedBy}>
                            {p.pickedBy && p.pickedBy.map(player =>
                                <div className={s.player} key={`picked-by-category-${p.num}-${p.choice}-${player.playerId}`}>
                                    <Player playerId={player.playerId} firstName={player.firstName} lastName={player.lastName} fullName={player.fullName} avatarUrl={player.avatarUrl} />
                                </div>
                            )}
                            {!p.pickedBy || !p.pickedBy.length ? <div className={s.player + ' ' + s.noPlayers}>NONE.</div> : null}
                            </div>
                        </div>
                        <td>
                        </td>
                        <div className={s.icon}>
                            {p.result === 'Correct' ? <FontAwesomeIcon name='check' /> : null}
                            {p.result === 'Incorrect' ? <FontAwesomeIcon name='times' /> : null}
                        </div>
                    </ListGroup.Item>
                )}
                </ListGroup>
            </Card>
            : null}
            
            {selectedUser ? 
            <Card>
                <Card.Header as='h4'>Player Picks: <Player playerId={selectedUser.playerId} avatarUrl={selectedUser.avatarUrl} firstName={selectedUser.firstName} lastName={selectedUser.lastName} fullName={selectedUser.fullName} /></Card.Header>
                <ListGroup variant='flush' className={s.playerList}>
                {picks.playerPicks && picks.playerPicks.map(p => 
                    <ListGroup.Item className={s.playerItem + (p.result === 'Correct' ? ' ' + s.correct : '') + (p.result === 'Incorrect' ? ' ' + s.incorrect : '')}
                        key={`player-pick-${selectedUser.userName}-${p.num}`}>
                        <div className={s.playerContent}>
                            <div className={s.category}>{p.category}</div>
                            <div>{p.playerText}</div>
                            {p.result === 'Incorrect' ? <div className={s.correct}><b>Correct</b>: {p.correctText}</div> : null}
                        </div>
                        <div className={s.icon}>
                            {p.result === 'Correct' ? <FontAwesomeIcon name='check' /> : null}
                            {p.result === 'Incorrect' ? <FontAwesomeIcon name='times' /> : null}
                        </div>
                    </ListGroup.Item>
                )}
                </ListGroup>
            </Card>
            : null}
        </div>
    )
}

export default ViewPicks