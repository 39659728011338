import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, ListGroup, Card, Badge } from 'react-bootstrap'
import { some } from 'lodash'
import * as s from './Scoreboard.scss'
import { getStandings } from '@severed-links/common.redherrings-reducers/oscars'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import PlayerListItem from '../Player/PlayerListItem'
import pluralize, { plural } from 'pluralize'

const Standings = () => {

    const dispatch = useDispatch()
    const year = useSelector(state => state.oscars.year)
    const standings = useSelector(state => state.oscars.standings)
    const onlineUsers = useSelector(state => state.oscars.onlineUsers)

    useEffect(() => { dispatch(getStandings()) }, [year])

    const _historical = ((standings || {}).historical || [])
        .map(i => ({ ...i, isOnline: some(onlineUsers, u => u.playerId === i.playerId) }))
    const _yearWinners = ((standings || {}).yearWinners || [])
        .map(i => ({ ...i, isOnline: some(onlineUsers, u => u.playerId === i.playerId) }))

    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <h2>Standings</h2>
                </Col>
            </Row>
            <Row>
                <Col md={6} xs={12}>
                    <Card className={s.scoreboardCard}>
                        <Card.Header as='h3' className={s.scoreboardHeader}>Top Overall Picks</Card.Header>
                        <ListGroup className={s.scoreboardList} variant='flush'>
                        {_historical.map((i, index) =>
                        <PlayerListItem key={`oscars-scoreboard-historical-${i._id || i.playerId || index.toString()}`}
                            player={i} nameBadge={i.isOnline ? <FontAwesomeIcon name='bolt' className={s.onlineIcon} title={'User is online'} /> : null}
                            rightDetail={<div className={s.rightDetail}>
                                <div className={s.appearances}><Badge bg='secondary' text='light'>{pluralize('appearance', i.appearances, true)}</Badge></div>
                                <div className={s.score}>{i.score}</div>
                            </div>} />
                        )}                            
                        </ListGroup>
                    </Card>
                </Col>
                <Col md={6} xs={12}>
                    <Card className={s.scoreboardCard}>
                        <Card.Header as='h3' className={s.scoreboardHeader}>Yearly Winners</Card.Header>
                        <ListGroup className={s.scoreboardList} variant='flush'>
                        {_yearWinners.map((i, index) =>
                        <PlayerListItem key={`oscars-scoreboard-year-winners-${i.year}-${i._id || i.playerId || index.toString()}`}
                            player={i} nameBadge={i.isOnline ? <FontAwesomeIcon name='bolt' className={s.onlineIcon} title={'User is online'} /> : null}
                            leftDetail={<div><Badge bg='info' text='light'>{i.year}</Badge></div>}
                            rightDetail={<div className={s.rightDetail}>
                                <div className={s.score}>{i.score}</div>
                            </div>} />
                        )}                            
                        </ListGroup>
                    </Card>
                </Col>
            </Row>
        </div>
    )
}

export default Standings