import React, { useEffect } from 'react'
import { withRouter } from 'react-router-dom'
import { Card, ListGroup } from 'react-bootstrap'
import { connect } from 'react-redux'
import NflTeamListItem from '../../NflTeamListItem'
import Line from '../../Line'
import OverUnder from '../../OverUnder'
import * as s from './NFLScoreboard.scss'
import moment from 'moment-timezone'

const LineHeader = ({ homeFavorite, homeMascot, visitorMascot, line }) => (
    (homeFavorite === true || homeFavorite === false) && line !== null ?
    <div className={s.lineContainer}>
        {homeFavorite ? homeMascot : visitorMascot}
        {' '}
        {'by'}
        &nbsp;
        <Line line={line} showSign={false} />
    </div>
    : null
)

const NFLScoreboardGame = ({ seasonId = null, seasonName = '', seasonMode = '', game = {}, showLine = false }) => {

    if (!game) return null
    const { week, visitor, visitorTeam, visitorCity, visitorMascot, visitorScore, visitorRecord,
        home, homeTeam, homeCity, homeMascot, homeScore, homeRecord, 
        homeFavorite, line, ou: overUnder, game: gameNumber, gameTime, date,
        isAfterKickoff, gameClock, gameQuarter } = game
    const status = ((game || {}).status || '')
        .replace(/\&nbsp\;/gi, ' ')
        .replace(/\&\#34\;/gi, '"') 
    const _teams = [
        { teamId: visitor, teamName: visitorTeam, city: visitorCity, mascot: visitorMascot, score: visitorScore, record: visitorRecord, showLine: false, showOverUnder: false },
        { teamId: home, teamName: homeTeam, city: homeCity, mascot: homeMascot, score: homeScore, record: homeRecord, showLine: false, showOverUnder: false },
    ]
    return (
        <Card className={s.gameContainer}>
            <Card.Header className={s.gameHeader}>
                <Card.Title className={s.gameTitle}>
                    <div className={s.gameTimeContainer}>
                        {moment(date).local().format('h:mm A')}
                    </div>
                    {showLine && visitor && home && homeFavorite !== null ?
                    <div className={s.lineContainer}>
                        <LineHeader homeFavorite={homeFavorite} line={line}
                            homeMascot={homeMascot} visitorMascot={visitorMascot} />
                        {seasonMode === 'Postseason' ? <div className={s.overUnderContainer}>O/U: <OverUnder overUnder={overUnder} /></div> : null}
                    </div>
                    : null}
                </Card.Title>
            </Card.Header>
            <ListGroup variant='flush'>
            {_teams && _teams.map((t, _teamIndex) => 
                <NflTeamListItem {...t} right={<div className={s.gameScore}>{t.score}</div>}
                    key={`nfl-scoreboard-game-${seasonId}-${seasonMode}-${week}-${gameNumber}-${t.teamId || _teamIndex}`} />
            )}
            </ListGroup>
            {status && isAfterKickoff ? <Card.Footer className={s.gameFooter}>{status}</Card.Footer> : null}
        </Card>
    )
}

export default NFLScoreboardGame
