import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import pluralize from 'pluralize'
import * as s from './EliminatorWinners.scss'
import { playerUrl } from '@severed-links/common.redherrings-constants'

const EliminatorWinnerRunnerUpNames = ({ isActiveSeason, hasRunnersUp, seasonName, runnersUp, ...props }) => (
    hasRunnersUp && runnersUp && runnersUp.length ?
    <ListGroup variant='flush' className={s.runnerUpList}>
    <ListGroup.Item className={s.smallHeadingItem}>
        {pluralize('Runner', runnersUp.length, false)} Up
    </ListGroup.Item>
    {runnersUp.map(i =>
    <ListGroup.Item className={s.runnerUpItem} key={`runner-up-${seasonName}-${i.playerId}`}>
        <Image src={playerUrl(i.avatarUrl)} className={s.avatar} />
        <div className={s.name}>{i.firstName} {i.lastName}</div>
    </ListGroup.Item>
    )}
    </ListGroup>
    : null
)

export default EliminatorWinnerRunnerUpNames
