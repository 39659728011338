import React, { useState, useLayoutEffect, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import BaseAuthenticatedMenu from './BaseAuthenticatedMenu'
import { Nav } from 'react-bootstrap'
import { img } from '@severed-links/common.redherrings-constants'
import CloudinaryImage from '../routes/Cloudinary/CloudinaryImage'
import { VisibleOnlyToCinderellaAdmin } from '../components/Authorization'
import * as s from './Menu.scss'

import { getDetails, getRecap, getFrontPageData, getRecapLikeTypes } from '@severed-links/common.redherrings-reducers/cinderella'
import { checkProfile, getTimeZone } from '@severed-links/common.redherrings-reducers/account'

const CinderellaMenu = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const isAuthenticated = useSelector(state => state.account.isAuthenticated)
    const year = useSelector(state => state.cinderella.year)
    const isHome = location.pathname.toLowerCase() === '/cinderella'

    useLayoutEffect(() => {
        if (isAuthenticated) {
            dispatch(getTimeZone())
            dispatch(getRecap())
            dispatch(checkProfile())
            dispatch(getDetails())
            dispatch(getFrontPageData())
            dispatch(getRecapLikeTypes())
        }
    }, [])

    const navbarBrand = (       
        <Link to={{ pathname: '/cinderella' }}>
            <CloudinaryImage publicId={img.CinderellaHeader} />
        </Link>
    )

    const AdminOnlyNavItem = () => <VisibleOnlyToCinderellaAdmin><Nav.Item className={s.item} key={5}><Nav.Link eventKey={5} onClick={() => navigate('/cinderella/admin')}>Admin</Nav.Link></Nav.Item></VisibleOnlyToCinderellaAdmin>

    const menuItems = [
        <Nav.Item className={s.item} key={1}><Nav.Link eventKey={1} onClick={() => navigate('/cinderella/entries')}>Entries</Nav.Link></Nav.Item>,
        year ? <Nav.Item className={s.item} key={2}><Nav.Link eventKey={2} onClick={() => navigate(`/cinderella/scoreboard/${year}/page/1`)}>Scoreboard</Nav.Link></Nav.Item> : null,
        year ? <Nav.Item className={s.item} key={3}><Nav.Link eventKey={3} onClick={() => navigate(`/cinderella/brackets/${year}`)}>Brackets</Nav.Link></Nav.Item> : null,
        <Nav.Item className={s.item} key={4}><Nav.Link eventKey={4} onClick={() => navigate('/cinderella/past-champs')}>Past Champs</Nav.Link></Nav.Item>,
        <AdminOnlyNavItem key={5} />
    ].filter (i => i)
    return (
        <BaseAuthenticatedMenu navbarBrand={navbarBrand} 
            menuItems={menuItems} />
    )
}

export default CinderellaMenu
