import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import CinderellaPaymentLogItem from './CinderellaPaymentLogItem'
import { getDuplicatePayments, deleteDuplicatePayments, getPayments } from '@severed-links/common.redherrings-reducers/cinderellaAdmin'
import * as s from './CinderellaPayments.scss'
import { reject, filter, some } from 'lodash'
import Confirm from '@severed-links/common.confirm'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import pluralize from 'pluralize'

const CinderellaDuplicatePayments = ({ year = 0 }) => {

    const dispatch = useDispatch()
    const duplicatePayments = useSelector(state => state.cinderellaAdmin.duplicatePayments)
    const [selectedItems, setSelectedItems] = useState([])

    useEffect(() => { dispatch(getDuplicatePayments(year)) }, [year])

    const onSelectItem = deleteKey => {
        var _selectedItems = [...selectedItems]
        if (some(_selectedItems, i => i == deleteKey)) {
            _selectedItems = reject(_selectedItems, i => i === deleteKey)
        } else {
            _selectedItems = [..._selectedItems, deleteKey]
        }
        setSelectedItems(_selectedItems)
    }

    const deleteSelectedItems = () => {
        const items = filter(duplicatePayments, i => selectedItems.indexOf(i.deleteKey) > -1).map(i => ({ _id: i._id }))
        dispatch(deleteDuplicatePayments(year, items))
        .then(action => {
            dispatch(createNotification({ ...action.payload }))
            if (action.payload.messageType === 'success') {
                setSelectedItems([])
            }
            dispatch(getDuplicatePayments(year))
            dispatch(getPayments(year))
        })
    }

    return (
        <div className={s.container}>
            <ListGroup className={s.paymentLog}>
                <ListGroup.Item variant={selectedItems.length === 0 ? 'light' : 'danger'} 
                    className={s.item + ' ' + s.noRecords}>
                    <div className={s.content}>
                        <Confirm variant={selectedItems.length === 0 ? 'outline-danger' : 'danger'} 
                            onConfirm={() => deleteSelectedItems()}
                            title={'Delete Selected Log Items'}
                            confirmText={`delete selected ${pluralize('item', selectedItems.length, false)}`}
                            body={`Are you sure you want to delete ${pluralize('item', selectedItems.length, true)}?`}
                            size='sm'
                            buttonIcon='trash' buttonText={`delete selected ${pluralize('item', selectedItems.length, false)}`} />
                    </div>
                </ListGroup.Item>
            {duplicatePayments && duplicatePayments.map(i =>
                <CinderellaPaymentLogItem isDuplicateItem
                    checked={some(selectedItems, x => x == i.deleteKey)}
                    onSelect={onSelectItem}
                    item={i} key={`duplicate-payment-item-${year}-${i.deleteKey}`} />
            )}
            {duplicatePayments && duplicatePayments.length > 0 ? 
                <ListGroup.Item variant='danger' className={s.item + ' ' + s.noRecords}>
                    <div className={s.content}>Only delete the second and subsequent Payment and Payment Log items (leave the original entries in each table).</div>
                </ListGroup.Item>
            : null}
            {!duplicatePayments || duplicatePayments.length === 0 ? 
                <ListGroup.Item variant='info' className={s.item + ' ' + s.noRecords}>
                    <div className={s.content}>No duplicate transactrion IDs located.</div>
                </ListGroup.Item>
            : null}
            </ListGroup>
        </div>
    )
}

export default CinderellaDuplicatePayments