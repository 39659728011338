import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './EliminatorFrontPageMessage.scss'

const EliminatorFrontPageMessage = () => {

    const dispatch = useDispatch()
    const frontPageMessage = useSelector(state => state.eliminator.frontPageMessage)

    useEffect(() => {
    }, [])

    return (
        frontPageMessage ?
        <Alert className={s.container}>
            <FontAwesomeIcon className={s.icon} name='info-circle' />
            <div className={s.message}>{frontPageMessage}</div>
        </Alert>
        : null
    )
}

export default EliminatorFrontPageMessage
