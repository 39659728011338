import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Row, Col, Button, ButtonGroup } from 'react-bootstrap'
import * as s from './EliminatorAdmin.scss'
import Logins from '../../Main/Admin/Logins'
import EliminatorConfigSummary from './EliminatorConfigSummary'
import EliminatorSetActiveSeason from './EliminatorSetActiveSeason'
import EliminatorEditConfiguration from './EliminatorEditConfiguration'
import EliminatorBroadcastMessage from './EliminatorBroadcastMessage'
import Modal from '@severed-links/common.modal'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { resetEditConfig, saveConfig, getConfig } from '@severed-links/common.redherrings-reducers/eliminatorAdmin'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import OnlineUsers from '../../../components/OnlineUsers'
import SendPushNotification from '../../Notifications/SendPushNotification'

const EliminatorAdminDashboard = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const seasonId = useSelector(state => state.eliminator.seasonId)
    const seasonName = useSelector(state => state.eliminator.seasonName)
    const seasonMode = useSelector(state => state.eliminator.seasonMode)
    const isPlayoffWeek = seasonMode === 'Postseason'
    const week = useSelector(state => state.eliminator.week)
    const playoffWeek = useSelector(state => state.eliminator.playoffWeek)
    const loginInfo = useSelector(state => state.eliminatorAdmin.loginInfo)
    const editConfig = useSelector(state => state.eliminatorAdmin.editConfig)
    const onlineUsers = useSelector(state => state.eliminatorAdmin.onlineUsers)
    const [showActiveSeasonModal, setShowActiveSeasonModal] = useState(false)
    const [showEditConfig, setShowEditConfig] = useState(false)
    const [showBroadcastMessage, setShowBroadcastMessage] = useState(false)

    const saveConfiguration = () => {
        const stateToSave = { ...editConfig }
        dispatch(saveConfig(stateToSave))
        .then(action => {
            dispatch(createNotification(action.payload))
            dispatch(getConfig(action.payload.seasonId))
        })
        dispatch(resetEditConfig())
        setShowEditConfig(false)
    }

    const buttons = [
        { _id: 1, icon: 'leaf', title: 'set active season', onClick: () => setShowActiveSeasonModal(true) },
        { _id: 2, icon: 'cog', title: 'edit configuration', onClick: () => setShowEditConfig(true) },
        { _id: 3, icon: 'envelope', title: 'send email', onClick: () => setShowBroadcastMessage(true) },
        { _id: 4, icon: 'user-plus', title: 'register players', onClick: () => navigate(`/eliminator/admin/register-players/${seasonName}`) },
        { _id: 5, icon: 'list-alt', title: 'reminder log', onClick: () => navigate(`/eliminator/admin/reminder-log/season/${seasonName}/${isPlayoffWeek ? 'postseason' : 'regular'}/week/${isPlayoffWeek ? playoffWeek : week}`) },
        { _id: 6, icon: 'money-bill-alt', title: 'view payment log', onClick: () => navigate(`/eliminator/admin/payments/${seasonName}`) },
        { _id: 7, icon: 'check', title: 'manual pick entry', onClick: () => navigate(`/eliminator/admin/manual-entry/${seasonName}/${seasonMode}/${(seasonMode === 'Regular' ? `week/${week}` : `playoff-week/${playoffWeek}`)}`) },
    ]
    return (
        <div className={s.container}>
            <h2>Eliminator Dashboard</h2>
            <Row>
                <Col sm={6} xs={12}>
                    <EliminatorConfigSummary />
                    <ButtonGroup className={s.dashboardButtonContainer} variant='light'>
                    {buttons && buttons.map(i =>
                        <Button key={`config-action-${i._id}`} 
                            onClick={() => i.onClick()}
                            variant='light' className={s.button}>
                            <FontAwesomeIcon name={i.icon} />
                            {' '}
                            {i.title}
                        </Button>
                    )}
                    </ButtonGroup>
                </Col>
                <Col sm={6} xs={12}>
                    <h4>
                        Online Users
                        {' '}
                        <SendPushNotification />
                    </h4>
                        <OnlineUsers users={onlineUsers} />
                        <Logins isDashboard />                        
                </Col>
            </Row>

            <Modal heading='Set active season' size={'sm'}
                show={showActiveSeasonModal}
                onClose={() => setShowActiveSeasonModal(false)}
                actionButtonText={null}>
                <EliminatorSetActiveSeason />
            </Modal>

            <Modal heading={'Edit ' + seasonName + ' Season configuration'} size={null}
                show={showEditConfig}
                onClose={() => { setShowEditConfig(false); dispatch(resetEditConfig())}}
                actionButtonOnClick={() => saveConfiguration()}
                actionButtonText={'save configuration'}>
                <EliminatorEditConfiguration />
            </Modal>

            <Modal heading='Broadcast Message'
                show={showBroadcastMessage}
                onClose={() => setShowBroadcastMessage(false)}
                actionButtonText={null}>
                <EliminatorBroadcastMessage />
            </Modal>

        </div>
    )
}

export default EliminatorAdminDashboard