import React, { useEffect } from 'react'
import { Row, Col, ListGroup } from 'react-bootstrap'
import * as s from './TeamDataPlayersFromLastYear.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Player from '../../Player/Player'
import PlayerListItem from '../../Player/PlayerListItem'

const TeamDataPlayersFromLastYear = ({ missingPlayers = [] }) => {

    return (
        <ListGroup className={s.container}>
        {missingPlayers && missingPlayers.map(p => 
            <PlayerListItem key={`missing-player-${p._id}`} player={p} className={s.item}
                rightDetail={<div className={s.rightContainer}>
                    {p.formattedCellPhone ? <div className={s.cellPhone}>{p.formattedCellPhone}</div> : null}
                    {p.emailAddress ? <div className={s.emailAddress}>{p.emailAddress}</div> : null}
                </div>} />
                    
        )}  
        </ListGroup>
    )
}

export default TeamDataPlayersFromLastYear
