import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, FormGroup, FormLabel, FormControl, InputGroup, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { trim } from 'lodash'
import * as s from './ForgotPassword.scss'
import { requestResetCode, setUserInfo } from '@severed-links/common.redherrings-reducers/forgotPassword'

const ForgotPasswordEnterCode = forwardRef(({ jumpToStep }, _ref) => {

    const dispatch = useDispatch()
    const fp = useSelector(state => state.forgotPassword)
    const [code, setCode] = useState('')
    const _isValid = fp.tokenRequested && fp.tokenSent && !fp.tokenError && 
        fp.enteredToken.length === 5

    useEffect(() => {
        dispatch(setUserInfo({ nextDisabled: true }))
        setCode(fp.enteredToken || '')
    }, [])

    useEffect(() => {
        if (!fp.tokenRequested && 
            ((fp.sendBy == 'Email' && fp.emailIsValid) ||
            (fp.sendBy == 'Text' && fp.cellPhoneIsValid))) {
            const postData = { kind: 'password_reset', to: fp.sendBy === 'Email' ? fp.email : fp.cellPhone, address: fp.sendBy === 'Email' ? 'email' : 'text' }
            dispatch(requestResetCode(postData))
        }

    }, [fp.tokenRequested, fp.sendBy, fp.emailIsValid, fp.cellPhoneIsValid])

    useEffect(() => {
        if (fp.nextDisabled !== !_isValid) dispatch(setUserInfo({ nextDisabled: !_isValid }))
    }, [fp.nextDisabled, _isValid])

    const handleChange = e => {
        var data = { [e.target.name]: trim(e.target.value || '') }
        setCode(data.code)
        dispatch(setUserInfo({ 
            enteredToken: data.code, 
            enteredTokenIsValid: data.code && data.code.length === 5
        }))
    }

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValid
    }))

    return (
        <div>
            {!fp.tokenRequested && !fp.tokenSent ? 
            <Alert variant='info' style={{ marginTop: '40px' }}>Requesting reset token...</Alert>
            : null}
            {fp.tokenRequested && fp.tokenError ?
            <Alert variant='danger' style={{ marginTop: '40px' }}>{fp.tokenErrorMessage}</Alert>
            : null}
            {fp.tokenRequested && fp.tokenSent ?
            <FormGroup>
                <FormLabel>Enter the code you received here</FormLabel>
                <InputGroup size='lg'>
                    <InputGroup.Text><FontAwesomeIcon name='list-ol' /></InputGroup.Text>
                    <FormControl autoFocus autoComplete='off' 
                        name='code' type='number' maxLength={5} 
                        value={code} pattern='[0-9]'
                        required isValid={fp.enteredTokenIsValid}
                        onChange={e => handleChange(e)} />
                    <FormControl.Feedback />
                </InputGroup>
            </FormGroup>
            : null}
        </div>
    )
})

export default ForgotPasswordEnterCode
