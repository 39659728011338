import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useLocation } from 'react-router-dom'
import { Row, Col, Nav, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getTournamentGames, getTournamentGamesForRound, saveTournamentUpdates } from '@severed-links/common.redherrings-reducers/cinderellaAdmin'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { filter, find, first, orderBy, flatten } from 'lodash'
import CaptureAdminGameEditor from '../../Capture/Admin/CaptureAdminGameEditor'
import moment from 'moment-timezone'
import CinderellaEditGameToolbar from './CinderellaEditGameToolbar'
import * as s from './CinderellaEditGameScores.scss'
import pluralize from 'pluralize'
import CinderellaBadGameDataAlert from './CinderellaBadGameDataAlert'

const CinderellaEditGameScores = ({ showCaptureForceUpdate = false }) => {

    const dispatch = useDispatch()
    const params = useParams()
    const tournamentGames = useSelector(state => state.cinderellaAdmin.tournamentGames)
    const [round, setRound] = useState(1)
    const [updates, setUpdates] = useState([])
    const [isSaving, setSaving] = useState(false)
    const [isForcingUpdate, setForcingUpdate] = useState(false)
    const [toolbarDate, setToolbarDate] = useState(moment().toDate())
    const year = parseInt(params.year || '0')

    useEffect(() => { loadData() }, [year])
    useEffect(() => { loadGames() }, [year, round])

    const saveUpdates = () => {
        setSaving(true)
        dispatch(saveTournamentUpdates(updates))
        .then(action => { 
            dispatch(createNotification({ message: action.payload.message, headline: action.payload.subject, type: action.payload.messageType, timeout: 2000 }))
            setUpdates([])
            setSaving(false)
            loadGames()
        })
    }

    const onAutoUpdateCallback = () => {
        loadData()
        loadGames()
    }

    const loadData = () => {
        if (year) {
            dispatch(getTournamentGames(year))
        }
    }

    const loadGames = () => {
        if (year && round) {
            dispatch(getTournamentGamesForRound(year, round))
            .then(action => {
                const _gameTimes = orderBy(flatten((action.payload || []).map(i => i.games)).map(i => i.gameTime), [], [])
                if (_gameTimes && _gameTimes.length) {
                    const _firstGameTime = first(_gameTimes)
                    setToolbarDate(moment(_firstGameTime).toDate())
                }
            })
        }
    }

    const handleRoundChange = _round => setRound(parseInt(_round))

    const saveGameTime = (game, editGameTime) => {
        const data = { year: game.year, game: game.game, data: 'gameTime', values: [{ teamId: null, value: moment(editGameTime).utc().toISOString() } ] }
        setUpdates([...updates, data])
    }

    const handleForceUpdate = () => {
        setForcingUpdate(true)
        dispatch(forceLineGameTimeUpdate(year, round))
        .then(() => setForcingUpdate(false))
    }

    const saveScores = (game, scores) => {
        if (scores && scores.length === 2) {
            const data = { 
                year: game.year, 
                game: game.game, 
                data: 'scores', 
                values: [
                    { teamId: scores[0].teamId, value: scores[0].score },
                    { teamId: scores[1].teamId, value: scores[1].score }
                ] 
            }
            setUpdates([...updates, data])
        }
    }

    const saveLineAndFavorite = (game, line, favoriteTeam) => {
        const data1 = { year: game.year, game: game.game, data: 'line', values: [{ teamId: null, value: line.toString() } ] }
        const data2 = { year: game.year, game: game.game, data: 'favoriteTeamId', values: [{ teamId: favoriteTeam.teamId, value: favoriteTeam.teamId.toString() } ] }
        setUpdates([...updates, data1, data2])
    }

    if (!tournamentGames) return null
    const { regions, rounds, games } = tournamentGames || {}
    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}>Edit Game Times, Scores</h2>
                {updates.length > 0 ? 
                <div className={s.controls}>
                    <Button variant='primary' disabled={isSaving} 
                        onClick={() => saveUpdates()}>
                        <FontAwesomeIcon name={!isSaving ? 'check' : 'circle-notch'} />
                        {' '}
                        <span>{!isSaving ? `save ${pluralize('update', updates.length, false)}` : 'saving...'}</span>
                    </Button>
                </div>
                : null}
                <div className={s.controls}>
                    <CinderellaEditGameToolbar date={toolbarDate} 
                        onAutoUpdate={onAutoUpdateCallback} />
                </div>
            </div>
            <Nav className={s.roundNav} variant='pills' activeKey={round} onSelect={e => handleRoundChange(e)} onClick={e => e.stopPropagation()}>
            {rounds && filter(rounds, r => r.round <= 6).map(r =>
                <Nav.Item key={r._id}><Nav.Link eventKey={r.round} >{r.roundName}</Nav.Link></Nav.Item>
            )}
            </Nav>
            <CinderellaBadGameDataAlert showBadGameTimes games={games} round={round} />
            {round <= 4 ? 
            <div>
            {regions && regions.map(region => 
                <div key={region._id}>
                    <Row>
                        <Col xs={12}>
                        <h4 className={s.regionName}>{region.regionName} Region</h4>
                        <Row>
                        {games && filter(games, { region: region.region }).map(regionGames =>
                            regionGames.games && regionGames.games.map(g =>
                                <Col xs={12} sm={6} md={3} key={`region-${region._id}-game-${g._id}`}>
                                    <CaptureAdminGameEditor game={g} showGameTime
                                        saveGameTime={saveGameTime}
                                        saveScores={saveScores}
                                        saveLineAndFavorite={saveLineAndFavorite} />
                                </Col>
                            )
                        )}
                        </Row>
                    </Col>
                </Row>
                </div>
                )}
            </div>
            :
            <Row key={'finals' + round}>
                <Col xs={12} sm={6} md={3}>
                {games && games.map((regionGames, index) =>
                    regionGames.games && regionGames.games.map(g =>
                        <div key={'region-games-' + index + '-' + g._id}>
                            <CaptureAdminGameEditor game={g} 
                                saveGameTime={saveGameTime} 
                                showGameTime showLine={false}
                                saveScores={saveScores}
                                saveLineAndFavorite={saveLineAndFavorite} />
                        </div>
                    )
                )}
                </Col>
            </Row>
        }
        </div>
    )
}

export default CinderellaEditGameScores