import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Button, Nav, Navbar, FormGroup, Form } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import YearSelector from '../../Oscars/YearSelector'
import Datetime from 'react-datetime'
import * as s from './CinderellaEditGameToolbar.scss'
import moment from 'moment-timezone'
import { runAutomaticScoreUpdater } from '@severed-links/common.redherrings-reducers/cinderellaAdmin'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'

const CinderellaEditGameToolbar = ({ date = null, onAutoUpdate = () => void(0) }) => {

    const dispatch = useDispatch()
    const params = useParams()
    const year = parseInt(params.year || '0')
    const [_date, setDate] = useState(moment().format('M/D/YYYY'))

    useEffect(() => {
        if (moment(date).isValid()) {
            setDate(moment(date).format('M/D/YYYY'))
        }
    }, [date])

    const handleDateChange = dt => setDate(moment(dt, 'M/D/YYYY').format('M/D/YYYY'))

    const runUpdater = () => {
        dispatch(runAutomaticScoreUpdater(_date))
        .then(action => {
            dispatch(createNotification(action.payload))
            onAutoUpdate()
        })
    }

    return (
        <Navbar className={s.editGamesToolBar} bg='light' variant='light'>
            <Navbar.Collapse>
                <Form className={s.form}>
                    <FormGroup className={s.datepickerContainer}>
                        <Datetime value={_date} 
                            title={'Run automatic score updater'}
                            dateFormat='M/D/YYYY' 
                            timeFormat={false}
                            onChange={dt => handleDateChange(dt)} />
                    </FormGroup>
                    <FormGroup className={s.buttonContainer}>
                        <Button variant='primary' title='run automatic score updater'
                            onClick={() => runUpdater()}>
                            <FontAwesomeIcon name='rotate' />
                            {' '}
                            run update
                        </Button>
                    </FormGroup>
                    <FormGroup className={s.yearSelectorContainer} title='change year'>
                        <YearSelector defaultYear={year}
                            buttonTitle={null} buttonIcon={'calendar'} />
                    </FormGroup>
                </Form>
            </Navbar.Collapse>
        </Navbar>
    )
}

export default CinderellaEditGameToolbar