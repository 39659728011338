import React, { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { Nav, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './ManageUsersLetterBar.scss'

const ManageUsersLetterBar = ({ letters = [], roleFilters = [], lockedUserCount = 0 }) => {

    const navigate = useNavigate()
    const params = useParams()
    const app = params.appName
    const roleFilter = params.roleFilter || 'AllRoles'
    const selectedLetter = params.letter || 'A'

    return (
        <Nav variant='pills' activeKey={selectedLetter} 
            className={s.letterBar} 
            onClick={e => e.stopPropagation()}>
            {letters && letters.map(x =>
            <Nav.Item key={`manage-users-letter-bar-pill-${x.letter}`}>
                <Nav.Link eventKey={x.letter} className={s.letterPill}
                    onClick={() => navigate(`/${app}/admin/manage-users/filter/${x.letter}/${roleFilter}`)}>
                    {x.letter === 'Locked' ?
                        <>
                        <FontAwesomeIcon name='lock' />
                        {lockedUserCount > 0 ? <Badge bg='danger'>{lockedUserCount}</Badge> : null}
                        </>
                    : x.letter}
                </Nav.Link>
            </Nav.Item>
            )}
        </Nav>
    )
}

export default ManageUsersLetterBar
