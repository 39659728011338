// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScoreboardTitleEntryInfo__container___jGErD{margin:0px}@media screen and (max-width: 991px){.ScoreboardTitleEntryInfo__container___jGErD.ScoreboardTitleEntryInfo__hide___Bs1M8{display:none}}@media screen and (min-width: 992px){.ScoreboardTitleEntryInfo__container___jGErD.ScoreboardTitleEntryInfo__hide___Bs1M8{visibility:hidden}}`, "",{"version":3,"sources":["webpack://./src/routes/Cinderella/Scoreboard/ScoreboardTitleEntryInfo.scss"],"names":[],"mappings":"AAGA,6CACI,UAAA,CAEI,qCADJ,oFAEQ,YAAA,CAAA,CAEJ,qCAJJ,oFAKQ,iBAAA,CAAA","sourcesContent":["@use '../../../styles/variables/colors' as colors;\n@use '../../../styles/variables/breakpoints' as breakpoints;\n\n.container {\n    margin: 0px;\n    &.hide {\n        @media screen and (max-width: breakpoints.$screen-md-max) {\n            display: none;\n        }\n        @media screen and (min-width: breakpoints.$screen-lg-min) {\n            visibility: hidden;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `ScoreboardTitleEntryInfo__container___jGErD`;
export var hide = `ScoreboardTitleEntryInfo__hide___Bs1M8`;
export default ___CSS_LOADER_EXPORT___;
