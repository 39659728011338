// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Logins__container___U6t3a .Logins__headingContainer___gMcGA{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center;margin-bottom:.5rem}.Logins__container___U6t3a .Logins__headingContainer___gMcGA .Logins__title___nRZ78{flex:1 1 auto;margin:0px}.Logins__container___U6t3a .Logins__headingContainer___gMcGA .Logins__controls___jLs2N{margin-left:1rem;flex:0 0 0}.Logins__container___U6t3a .Logins__manageUsersButton___Tmbj1{white-space:nowrap}`, "",{"version":3,"sources":["webpack://./src/routes/Main/Admin/Logins.scss"],"names":[],"mappings":"AACI,6DACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CACA,mBAAA,CAEA,oFACI,aAAA,CACA,UAAA,CAGJ,uFACI,gBAAA,CACA,UAAA,CAIR,8DACI,kBAAA","sourcesContent":[".container {\n    .headingContainer {\n        display: flex;\n        flex-flow: row nowrap;\n        justify-content: space-between;\n        align-items: center;\n        margin-bottom: 0.5rem;\n\n        .title {\n            flex: 1 1 auto;\n            margin: 0px;\n        }\n\n        .controls {\n            margin-left: 1rem;\n            flex: 0 0 0;\n        }\n    }\n\n    .manageUsersButton {\n        white-space: nowrap;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `Logins__container___U6t3a`;
export var headingContainer = `Logins__headingContainer___gMcGA`;
export var title = `Logins__title___nRZ78`;
export var controls = `Logins__controls___jLs2N`;
export var manageUsersButton = `Logins__manageUsersButton___Tmbj1`;
export default ___CSS_LOADER_EXPORT___;
