import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getFrontPageData } from '@severed-links/common.redherrings-reducers/cinderella'
import * as s from './CinderellaConfigSummary.scss'
import { find } from 'lodash'
import moment from 'moment-timezone'

const CinderellaEntryTally = () => {

    const dispatch = useDispatch()
    const frontPageData = useSelector(state => state.cinderella.frontPageData)
    const year = useSelector(state => state.cinderella.year)
    const pickDeadline = useSelector(state => state.cinderella.pickDeadline)
    const [isChanged, setChanged] = useState(false)
    const entryCount = (find((frontPageData || {}).yearlyEntries || [], { groupByOfYear: year }) || {}).countOfEntryId || 0
    const afterDeadline = moment(pickDeadline).isBefore(moment(), 'min')

    useEffect(() => { dispatch(getFrontPageData()) }, [])

    useEffect(() => {
        if (entryCount && year && pickDeadline && !afterDeadline) {
            setChanged(true)
            const _timeoutId = setTimeout(() => setChanged(false), 2000)
            return () => clearTimeout(_timeoutId)    
        }
    }, [entryCount, pickDeadline, afterDeadline, year])

    return (
        <ListGroup.Item className={s.item + ' ' + s.hasAnimation + (isChanged ? ' ' + s.isChanged : '')}>
            <div>{year} Entries</div>
            <div className={s.value}>{entryCount}</div>
        </ListGroup.Item>
    )
}

export default CinderellaEntryTally