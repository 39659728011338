import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Button, FormGroup, FormLabel, FormControl, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './CinderellaRecaps.scss'
import CinderellaRecapEditor from './CinderellaRecapEditor'
import CinderellaRecapList from './CinderellaRecapList'
import { getRecaps, setActiveRecap } from '@severed-links/common.redherrings-reducers/cinderellaAdmin'

const CinderellaRecaps = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const recapId = params.recapId || null
    const homeRecapPath = `/cinderella/admin/recap/editor`
    const newRecapPath = `/cinderella/admin/recap/editor/new`
    const editRecapPath = `/cinderella/admin/recap/editor/:recapId/edit`
    const isNew = location.pathname.toLowerCase() === newRecapPath
    const isHome = location.pathname.toLowerCase() === homeRecapPath
    
    useEffect(() => { loadRecaps() }, [])
    useEffect(() => { 
        if (isHome) { 
            loadRecaps()
        }
    }, [isHome])

    const loadRecaps = () => dispatch(getRecaps())

    const onSelectRecap = _recapId => {
        navigate(editRecapPath.replace(':recapId', _recapId))
    }

    const onCancelEditRecap = _recapId => {
        if (_recapId) {
            loadRecaps().then(() => onSelectRecap(_recapId))            
        } else {
            navigate(homeRecapPath)
        }
    }
    
    const onSetActiveRecap = _recapId => {
        dispatch(setActiveRecap(_recapId))
        .then(() => dispatch(getRecaps()))
    }


    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}>Recaps</h2>
                {isHome ?
                <div className={s.controls}>
                    <Button onClick={() => navigate(newRecapPath)} size='sm'><FontAwesomeIcon name='plus' /></Button>
                </div>
                : null}
            </div>
            <div className={s.content}>
                <div className={s.listContainer + (!isHome ? ` ${s.showEditor}` : '')}>
                    <CinderellaRecapList onSelectRecap={onSelectRecap} activeRecapId={recapId} />
                </div>
                <div className={s.editorContainer + (!isHome ? ` ${s.showEditor}` : '')}>
                    <CinderellaRecapEditor isNew={isNew} recapId={recapId}
                        onCancelEditRecap={onCancelEditRecap}
                        onSetActiveRecap={onSetActiveRecap} />
                </div>
            </div>
        </div>
    )
}

export default CinderellaRecaps