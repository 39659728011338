import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Card, Grid } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './SeasonScheduleLarge.scss'
import CloudinaryImage from '../../Cloudinary/CloudinaryImage'
import { startsWith, trim, filter } from 'lodash'
import { img } from '@severed-links/common.redherrings-constants'

const MatchupResultIndicator = ({ seasonName, teamName, matchup, onClick }) => { 
    const key = `season-schedule-large-week-${matchup.week}-game-${matchup.game}-opponent-${matchup.oppId}`
    return trim(matchup.displayResult) ? 
        <>
        <div key={`${key}-score-update`} onClick={() => onClick()}>
            {matchup.displayResult}
        </div>
        <div key={`${key}-score-overlay`} />
        </>
        : 
        <span onClick={() => onClick()}>matchup</span>
}

const SeasonScheduleLarge = ({ teamName = '', seasonName = '', schedule = [], week = 0 }) => {

    const navigate = useNavigate()

    const getVariantClass = matchup => {
        const isWin = startsWith(matchup.displayResult, 'Won')
        const isLoss = startsWith(matchup.displayResult, 'Lost')
        const isTie = startsWith(matchup.displayResult, 'Tied')
        const isCurrent = !isWin && !isLoss && !isTie && week === matchup.week

        return isCurrent ? ' ' + s.current : 
            isWin ? ' ' + s.win :
            isLoss ? ' ' + s.loss :
            isTie ? ' ' + s.tie :
            ''
    }
    const _schedule = [...filter(schedule, i => i.week >= week), ...filter(schedule, i => i.week < week)]
    if (!_schedule || _schedule.length === 0) return null
    return (
        <div className={s.container}>
            <h3>{teamName} {seasonName} Season Schedule</h3>
            <div className={s.seasonContainer}>
            <Row>
            {_schedule && _schedule.map((i, index) => 
                <Col key={`season-sched-large-${i.oppPlayerId}`}>
                <Card className={s.matchUpCard + getVariantClass(i)}>
                    <Card.Header className={s.cardHeader}>
                        <div className={s.weekNumber}>Week {i.week}</div>
                        <div className={s.result}>
                            <MatchupResultIndicator seasonName={seasonName}
                                teamName={teamName} matchup={i} 
                                onClick={() => navigate(`/football/regular-season/match-up/${seasonName}/week/${i.week}/game/${i.game}/${(teamName || '').toLowerCase()}-vs-${(i.oppTeamName || '').toLowerCase()}`)} />
                        </div>
                    </Card.Header>
                    <CloudinaryImage publicId={i.oppAvatarUrl}
                        className={s.cardImage + ' card-img-top'}
                        transformationName='large_avatar_200' />
                    <Card.Body className={s.cardBody}>
                        <h4 className={s.teamName}>{i.oppTeamName}</h4>
                    </Card.Body>
                    <Card.Footer className={s.cardFooter}>
                        <h5 className={s.playerName}>{i.oppPlayerName}</h5>
                        {i.oppPlayerAvatarUrl ? <CloudinaryImage className={s.playerAvatar} publicId={i.oppPlayerAvatarUrl} transformationName='large_avatar_200' alt={i.oppPlayerName} /> : null}
                    </Card.Footer>
                </Card>
                </Col>
            )}
            </Row>
            </div>
        </div>
    )
}

export default SeasonScheduleLarge