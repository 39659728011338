import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Table, FormControl, Badge, FormLabel } from 'react-bootstrap'
import * as s from './Preseason.scss'
import { loadPreseason, divisionStandings, teamRegistrationReset,
    checkTeamNameAvailability, teamRegistrationDivisionSelection, 
    teamRegistrationIsPaid, teamRegistrationRegisterTeam } from '@severed-links/common.redherrings-reducers/football'

import StepZilla from 'react-stepzilla'

import PreseasonRegistrationStep1 from './PreseasonRegistrationStep1'
import PreseasonRegistrationStep2 from './PreseasonRegistrationStep2'
import PreseasonRegistrationStep3 from './PreseasonRegistrationStep3'
import PreseasonRegistrationStep4 from './PreseasonRegistrationStep4'
import PreseasonRegistrationStep5 from './PreseasonRegistrationStep5'

const PreseasonTeamRegistration = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const seasonName = params.seasonName || null
    const seasons = useSelector(state => state.football.seasons)
    const playerId = useSelector(state => state.account._id)
    const userName = useSelector(state => state.account.userName)
    const season = seasons.find(i => i.seasonName === seasonName)
    const seasonId = (season || {}).seasonId || null
    const teamRegistration = useSelector(state => state.football.teamRegistration)
    const preseason = useSelector(state => state.football.preseasons)[`${seasonId}`] || {}

    const steps = [
        { name: 'Register', component: <PreseasonRegistrationStep1 /> },
        { name: 'Team name', component: <PreseasonRegistrationStep2 /> },
        { name: 'Division', component: <PreseasonRegistrationStep3 /> },
        { name: 'Payment', component: <PreseasonRegistrationStep4 /> },
        { name: 'Done', component: <PreseasonRegistrationStep5 /> },
    ]
    return (
        <div>
            <StepZilla steps={steps} stepsNavigation={false}
                showNavigation={false} preventEnterSubmission={false}
                nextTextOnFinalActionStep={'Register'} />
        </div>
    )
}

export default PreseasonTeamRegistration