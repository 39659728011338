import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, FormLabel, FormControl, Alert, ProgressBar } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { trim } from 'lodash'
import {  } from '@severed-links/common.redherrings-reducers/siteRegistration'
import * as s from './SignUp.scss'
import { setUserInfo, checkPasswordStrength } from '@severed-links/common.redherrings-reducers/siteRegistration'
import { PASSWORD_VALIDATOR, PASSWORD_SCORE, MINIMUM_PASSWORD_SCORE } from '@severed-links/common.redherrings-constants'
import PasswordValidationRules from '../ForgotPassword/PasswordValidationRules'

const PasswordStep3 = forwardRef(({ jumpToStep }, _ref) => {

    const dispatch = useDispatch()
    const siteRegistration = useSelector(state => state.siteRegistration)
    const [password, setPassword] = useState('')
    const [progressBar, setProgressBar] = useState({ length: 0, style: 'danger' })
    const [failedRules, setFailedRules] = useState([])
    const _progressBarLength = PASSWORD_SCORE(password.length, siteRegistration.passwordStrength.score)
    const _isValid = siteRegistration.passwordIsValid

    useEffect(() => { setPassword(siteRegistration.password || '') }, [])
    useEffect(() => {
        if (siteRegistration.nextDisabled !== !_isValid) {
            dispatch(setUserInfo({ nextDisabled: !_isValid }))
        }
    }, [siteRegistration.nextDisabled, _isValid])
    useEffect(() => { dispatch(checkPasswordStrength(password)) }, [password])
    useEffect(() => { validatePassword() }, [password])
    useEffect(() => { updateProgressBar(_progressBarLength) }, [_progressBarLength])

    const validatePassword = () => setFailedRules(password ? PASSWORD_VALIDATOR.validate(password, { list: true }) : [])

    const handleChange = e => {
        const _password = e.target.value || ''
        var data = { password: trim(_password) }
        setPassword(_password)
        dispatch(setUserInfo(data))
    }

    const updateProgressBar = _score => {
        var style = 'danger'
        if (_score >= MINIMUM_PASSWORD_SCORE) style = 'success'
        else if (_score >= MINIMUM_PASSWORD_SCORE / 2.0) style = 'warning'
        setProgressBar({ length: _score, style })
        dispatch(setUserInfo({ passwordIsValid: _score >= MINIMUM_PASSWORD_SCORE }))
    }

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValid
    }))

    if (!siteRegistration) return null
    return (
        <div className={s.step3 + ' ' + s.step}>
            <FormGroup className={s.passwordContainer}>
                <FormLabel>Enter your password</FormLabel>
                <FormControl size='lg' autoFocus autoComplete='off' type='text' 
                    name='password' value={password} 
                    required isValid={siteRegistration.passwordIsValid}
                    isInvalid={!siteRegistration.passwordIsValid}
                    onChange={e => handleChange(e)} />
                <FormControl.Feedback />
                <ProgressBar now={progressBar.length} variant={progressBar.style} />
                <div className={s.alertContainer}>
                    <PasswordValidationRules show={!!password} 
                        failedRules={failedRules} 
                        score={progressBar.length} />
                </div>
            </FormGroup>
        </div>
    )
})

export default PasswordStep3
