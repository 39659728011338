import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Alert } from 'react-bootstrap'
import * as s from './TeamProfile.scss'
import { lookupTeamProfile } from '@severed-links/common.redherrings-reducers/football'

const TeamProfileRedirector = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const seasonId = useSelector(state => state.football.seasonId)
    const playerId = useSelector(state => state.account._id)
    const [noSeasonTeams, setNoSeasonTeams] = useState(false)

    useEffect(() => { lookupProfile() }, [playerId, seasonId])

    const lookupProfile = () => {
        if (seasonId && playerId) {
            dispatch(lookupTeamProfile(seasonId, playerId))
            .then(response => { 
                if (response.payload.teamId) {
                    navigate(`/football/team-profile/${response.payload.teamId}`, { replace: true })
                } else if (response.payload.noSeasonTeams) {
                    setNoSeasonTeams(true)
                }
            })
        }
    }

    return (
        !noSeasonTeams ?
        <div className='hide'>
            Looking up player {playerId} in season {seasonId}
        </div>
        :
        <Alert variant='info'>No teams located for this season</Alert>
    )
}

export default TeamProfileRedirector