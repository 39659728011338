import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './CaptureGameTime.scss'
import moment from 'moment-timezone'

const CaptureGameTime = ({ gameTime = null, timeOnly = false, highlight = false }) => {

    const timeZone = useSelector(state => state.account.time_zone || 'America/New_York')

    if (!gameTime || !moment(gameTime).isValid()) return null
    const _isToday = moment.tz(timeZone).isSame(moment.tz(gameTime, timeZone), 'day')
    return (
        <div className={s.container}>
            <FontAwesomeIcon name='clock' className={s.icon} />
            <span>{moment.tz(gameTime, timeZone).format(`${!_isToday && !timeOnly ? `M/D ` : ''}h:mm A`)}</span>
        </div>
    )
}

export default CaptureGameTime
