import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import moment from 'moment-timezone'

const ScoreboardRedirector = () => {

    const navigate = useNavigate()
    const year = useSelector(state => state.cinderella.year)

    useEffect(() => { navigate(`/cinderella/scoreboard/${year || moment().year()}/page/1`, { replace: true }) }, [])
    
    return null
}

export default ScoreboardRedirector