import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { getNflPlayoffGames } from '@severed-links/common.redherrings-reducers/footballAdmin'
import { uniqBy, filter, orderBy } from 'lodash'
import EditNflPlayoffGame from './EditNflPlayoffGame'
import * as s from './EditNflPlayoffGames.scss'

const EditNflPlayoffGames = ({ seasonName = null, defaultWeek = 0, isModal = false }) => {

    const dispatch = useDispatch()
    const seasons = useSelector(state => state.football.seasons)
    const seasonId = (seasons.find(i => i.seasonName === seasonName) || {}).seasonId || null
    const _nflPlayoffGames = useSelector(state => state.footballAdmin.nflPlayoffGames)[`${seasonId}`] || {}
    const nflPlayoffGames = _nflPlayoffGames.playoffGames || []
    const nflPlayoffWeeks = _nflPlayoffGames.playoffWeeks || []
    const updateNflPlayoffGamesWeek = useSelector(state => state.footballAdmin.updateNflPlayoffGamesWeek)
    const currentWeek = defaultWeek || updateNflPlayoffGamesWeek || 0
    const _currentWeek = useRef(null)

    useEffect(() => { seasonId ? dispatch(getNflPlayoffGames(seasonId)) : null }, [seasonId])
    useEffect(() => { setTimeout(() => _currentWeek.current?.scrollIntoView({ behavior: 'smooth' }), 100) }, [currentWeek])

    return (
        <div className={s.container + (isModal ? ` ${s.isModal}` : '')}>
            {nflPlayoffWeeks && nflPlayoffWeeks.map(w => 
            <div className={s.weekContainer} key={`edit-nfl-games-week-${w.week}`}>
                <h4 ref={currentWeek && w.week === currentWeek ? _currentWeek : null} className={s.weekHeading}>Week {w.week}: {w.heading}</h4>
                {nflPlayoffGames && orderBy(nflPlayoffGames.filter(i => i.week === w.week), ['game'], ['asc']).map(game =>
                    <EditNflPlayoffGame game={game} key={`edit-nfl-game-${game._id}`} />
                )}
                {!nflPlayoffGames || !nflPlayoffGames.length ?
                <ListGroup>
                    <ListGroup.Item>No games located for week {w.week}.</ListGroup.Item>
                </ListGroup>
                : null}
            </div>
            )}
        </div>
    )
}

export default EditNflPlayoffGames