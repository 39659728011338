import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './ScoreboardListGroup.scss'

const ScoreboardListHeader = ({ afterDeadline = false }) => {

    return (
        <ListGroup.Item className={s.header}>
            <div className={s.rank}>Rank</div>
            <div className={s.avatar}></div>
            <div className={s.entry}>Entry</div>
            {afterDeadline ? <div className={s.teams}><span className={s.headerText}>Teams</span></div> : null}
            <div className={s.score}>Score</div>
            <div className={s.teamsLeft}>#Left</div>
        </ListGroup.Item>
    )
}

export default ScoreboardListHeader
