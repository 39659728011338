// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.NFLTeamAnalysis__container___sgozP .NFLTeamAnalysis__tabsContainer___wq_wy .NFLTeamAnalysis__tabPane___YfXVN{padding-top:1rem}`, "",{"version":3,"sources":["webpack://./src/routes/Football/RegularSeason/NFL/NFLTeamAnalysis.scss"],"names":[],"mappings":"AAGQ,8GACI,gBAAA","sourcesContent":[".container {\n\n    .tabsContainer {\n        .tabPane {\n            padding-top: 1rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `NFLTeamAnalysis__container___sgozP`;
export var tabsContainer = `NFLTeamAnalysis__tabsContainer___wq_wy`;
export var tabPane = `NFLTeamAnalysis__tabPane___YfXVN`;
export default ___CSS_LOADER_EXPORT___;
