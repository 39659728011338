// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DivisionStandingsView__container___ZZlmI .DivisionStandingsView__seasonSelector___zm4BF,.DivisionStandingsView__container___ZZlmI .DivisionStandingsView__weekSelector___UE3GC{float:right;white-space:nowrap;margin-left:10px}.DivisionStandingsView__container___ZZlmI .DivisionStandingsView__seasonSelector___zm4BF .dropdown.btn-group,.DivisionStandingsView__container___ZZlmI .DivisionStandingsView__weekSelector___UE3GC .dropdown.btn-group{text-align:left}`, "",{"version":3,"sources":["webpack://./src/routes/Football/DivisionStandingsView.scss"],"names":[],"mappings":"AAGI,gLACI,WAAA,CACA,kBAAA,CACA,gBAAA,CAEI,wNACI,eAAA","sourcesContent":[".container {\n\n\n    .seasonSelector, .weekSelector {\n        float: right;\n        white-space: nowrap;\n        margin-left: 10px;\n        :global {\n            .dropdown.btn-group {\n                text-align: left;\n            }\n        }\n    }\n    \n    \n\n}"],"sourceRoot":""}]);
// Exports
export var container = `DivisionStandingsView__container___ZZlmI`;
export var seasonSelector = `DivisionStandingsView__seasonSelector___zm4BF`;
export var weekSelector = `DivisionStandingsView__weekSelector___UE3GC`;
export default ___CSS_LOADER_EXPORT___;
