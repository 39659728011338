import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import { Nav, Navbar, NavDropdown, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './Menu.scss'
import CloudinaryImage from '../routes/Cloudinary/CloudinaryImage'
import { img } from '@severed-links/common.redherrings-constants'

const PublicMenu = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const redHerringsSmallLogo = useSelector(state => state.cloudinary.redHerringsSmallLogo)

    const handleSelect = e => navigate(e)

    return (
        <div className={s.container}>
            <Navbar collapseOnSelect fixed='top' expand='md'
                className={s.baseMenu + ' ' + s.main}>
                <Navbar.Brand className={s.navbarBrand}>
                    <Link to={{ pathname: '/' }}>
                        <CloudinaryImage publicId={redHerringsSmallLogo} />
                    </Link>
                </Navbar.Brand>
                <Navbar.Toggle className={s.navbarToggle}>
                    <FontAwesomeIcon name='bars' />
                </Navbar.Toggle>
                <Navbar.Collapse className={s.navbarCollapse}>
                    <Nav className={s.nav} onSelect={e => handleSelect(e)} onClick={e => e.stopPropagation()}>
                        <Nav.Item className={s.item}><Nav.Link eventKey={`/login`}>Login</Nav.Link></Nav.Item>
                        <Nav.Item className={s.item}><Nav.Link eventKey={`/forgot-username`}>Forgot Username</Nav.Link></Nav.Item>
                        <Nav.Item className={s.item}><Nav.Link eventKey={`/forgot-password`}>Forgot Password</Nav.Link></Nav.Item>
                        <Nav.Item className={s.item}><Nav.Link eventKey={`/signup`}>Sign Up</Nav.Link></Nav.Item>
                    </Nav>
                </Navbar.Collapse>
            </Navbar>
        </div>
    )
}

export default PublicMenu
