import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { FormGroup, FormControl, Badge, FormLabel, Button, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './PreseasonRegistrationStep1.scss'
import { teamRegistrationReset } from '@severed-links/common.redherrings-reducers/football'

const PreseasonRegistrationStep1 = forwardRef(({ jumpToStep }, _ref) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const seasonName = params.seasonName || null
    const seasons = useSelector(state => state.football.seasons)
    const playerId = useSelector(state => state.account._id)
    const userName = useSelector(state => state.account.userName)
    const season = seasons.find(i => i.seasonName === seasonName)
    const seasonId = (season || {}).seasonId || null
    const teamRegistration = useSelector(state => state.football.teamRegistration)
    const preseason = useSelector(state => state.football.preseasons)[`${seasonId}`] || {}
    const { nflKickoff, spotsLeft, divisions, entryFee } = preseason

    useEffect(() => { dispatch(teamRegistrationReset()) }, [])

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValid
    }))

    return (
        <Alert variant='primary' className={s.container}>
            <div className={s.content}>Press Next to register a team for the {seasonName} season.</div>
            <Button className={s.button} variant='primary' onClick={() => jumpToStep(1)}>Next <FontAwesomeIcon name='chevron-right' /></Button>
        </Alert>
    )
})

export default PreseasonRegistrationStep1