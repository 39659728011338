import React, { useEffect } from 'react'
import { Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'

const LeagueSchedulerOddTeamWarning = ({ teamCount = 0 }) => {

    if (!teamCount) return null
    return (
        teamCount % 2 !== 0 ? 
            <Alert variant='warning' style={{ marginTop: '1.5rem' }}>
                <p><strong>Odd team number: {teamCount} teams</strong></p>
                <p>Will truncate grid to use even number of teams. Make sure there is an 
                even number of teams once the final season schedule is generated on this page.</p>
            </Alert>
        : null
    )
}

export default LeagueSchedulerOddTeamWarning