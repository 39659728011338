import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Button } from 'react-bootstrap'
import { img } from '@severed-links/common.redherrings-constants'
import CloudinaryImage from '../Cloudinary/CloudinaryImage'
import { filter, startsWith, endsWith, replace, trim, some, forEach } from 'lodash'
import * as s from './MainView.scss'
import queryString from 'query-string'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon';
import { isEqual } from 'lodash'
import MobileApps from '../MobileApps/MobileApps'
import { registerLastLoginRole, checkProfile } from '@severed-links/common.redherrings-reducers/account'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'

const MainView = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const roles = useSelector(state => state.account.roles || [])
    const [roleIsSelected, setRoleIsSelected] = useState(false)
    const [selectedRole, setSelectedRole] = useState(null)
    const [timeoutId, setTimeoutId] = useState(null)

    useEffect(() => {
        setRoleIsSelected(false)
        setSelectedRole(null)
        dispatch(checkProfile())
    }, [])
    
    useEffect(() => {
        setTimeout(() => accessRole(selectedRole), 750)
    }, [selectedRole])
    
    const selectRole = role => {
        if (selectedRole !== role) {
            setRoleIsSelected(true)
            setSelectedRole(role)
        } else {
            if (timeoutId) clearTimeout(timeoutId)
            setTimeoutId(null)
            setSelectedRole(null)
            setRoleIsSelected(false)
        }
    }

    const accessRole = () => {
        if (selectedRole) {
            dispatch(registerLastLoginRole(`${selectedRole || ''}`))
            .then(action => {
                if (action.payload.messageType === 'success') {
                    const path = `/${replace(selectedRole.toLowerCase(), 'player', '')}`
                    setTimeout(() => navigate(path), 250)
                } else {
                    createNotification({
                        type: 'error', 
                        headline: 'Not Authorized', 
                        message: 'You are not authorized to enter the ' + 
                            trim(replace(selectedRole,'Player', '')) + ' area.', 
                        timeout: 6000
                    })
                    if (timeoutId) clearTimeout(timeoutId)
                    setRoleIsSelected(false)
                    setSelectedRole(null)
                    setTimeoutId(null)
                }         
            })
        }
    }

    const contests = filter(roles, role => endsWith(role, 'Player'))
    return (
        <div>
            <h2>Welcome to Redherrings!</h2>
            <h4>Pick your contest</h4>
            <Row>
            {contests && contests.map((c, index) =>
                <Col key={`role-button-${index}`} sm={4} 
                    className={s.contestLinkContainer}>
                    <Button className={s.button + ' ' + (roleIsSelected && selectedRole === c ? ' ' + s.active : roleIsSelected && selectedRole !== c ? s.inactive : s.notSelected) + ' ' + s[replace(c.toLowerCase(), 'player', '')]} key={`main-view-contest-${c}`}  
                        style={{ backgroundImage: 'url(' + img.CloudinaryBaseUrl + img[c.replace('Player', '') + 'HeaderMobile'] + ')' }}
                        disabled={roleIsSelected && selectedRole !== c}
                        onClick={() => selectRole(c)}>
                    </Button>
                    <div className={s.loadingContainer + ' ' + s[replace(c.toLowerCase(), 'player', '')] + (roleIsSelected && selectedRole === c ? ' ' + s.show : '')}>
                        <div className={s.loading}>Loading... <FontAwesomeIcon name='circle-notch' spin /></div>
                    </div>
                </Col>
            )}
            </Row>
            <Row>
                <Col xs={12}>
                    <MobileApps />
                </Col>
            </Row>
        </div>
    )
}

export default MainView