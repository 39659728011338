// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditNflPlayoffGamesContainer__container___Hiqnd{margin-bottom:1rem}.EditNflPlayoffGamesContainer__container___Hiqnd .EditNflPlayoffGamesContainer__heading___jo_cS{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center}.EditNflPlayoffGamesContainer__container___Hiqnd .EditNflPlayoffGamesContainer__heading___jo_cS .EditNflPlayoffGamesContainer__title___HpJJZ{flex:1 1 auto}.EditNflPlayoffGamesContainer__container___Hiqnd .EditNflPlayoffGamesContainer__heading___jo_cS .EditNflPlayoffGamesContainer__seasonSelector___luLeQ{margin-left:1rem}`, "",{"version":3,"sources":["webpack://./src/routes/Football/Admin/EditNflPlayoffGamesContainer.scss"],"names":[],"mappings":"AAAA,iDACI,kBAAA,CAEA,gGACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CAEA,6IACI,aAAA,CAGJ,sJACI,gBAAA","sourcesContent":[".container {\n    margin-bottom: 1rem;\n\n    .heading {\n        display: flex;\n        flex-flow: row nowrap;\n        justify-content: space-between;\n        align-items: center;\n\n        .title {\n            flex: 1 1 auto;\n        }\n\n        .seasonSelector {\n            margin-left: 1rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `EditNflPlayoffGamesContainer__container___Hiqnd`;
export var heading = `EditNflPlayoffGamesContainer__heading___jo_cS`;
export var title = `EditNflPlayoffGamesContainer__title___HpJJZ`;
export var seasonSelector = `EditNflPlayoffGamesContainer__seasonSelector___luLeQ`;
export default ___CSS_LOADER_EXPORT___;
