import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './Logout.scss'
import { logout } from '@severed-links/common.redherrings-reducers/account'
import SpinnerOverlay from '@severed-links/common.spinner-overlay'

const Logout = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()

    useEffect(() => {
        dispatch(logout())
        setTimeout(() => navigate('/login', { replace: true }), 500)        
    }, [])

    return (
        <div className={s.container}>
            <SpinnerOverlay show message='Logging out...' />
        </div>
    )
}

export default Logout
