import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { FormGroup, FormControl, Badge, InputGroup, FormLabel, Button, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './PreseasonRegistrationStep2.scss'
import RedHerringsPayPalButton from '../../Payment/RedHerringsPayPalButton'
import { teamRegistrationIsPaid, teamRegistrationRegisterTeam } from '@severed-links/common.redherrings-reducers/football'

const PreseasonRegistrationStep4 = forwardRef(({ jumpToStep }, _ref) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const seasonName = params.seasonName || null
    const seasons = useSelector(state => state.football.seasons)
    const playerId = useSelector(state => state.account._id)
    const userName = useSelector(state => state.account.userName)
    const season = seasons.find(i => i.seasonName === seasonName)
    const seasonId = (season || {}).seasonId || null
    const teamRegistration = useSelector(state => state.football.teamRegistration)
    const preseason = useSelector(state => state.football.preseasons)[`${seasonId}`] || {}
    const { nflKickoff, spotsLeft, divisions, entryFee } = preseason
    const _isValid = teamRegistration.isPaid || false
    const [showButton, setShowButton] = useState(false)
    const paymentEmail = useSelector(state => state.football.paymentEmail)

    const onAuthorizedFootballPayment = () => {
        dispatch(teamRegistrationIsPaid(true))
        dispatch(teamRegistrationRegisterTeam({ seasonId, requestedTeamName: teamRegistration.teamName, requestedDivision: teamRegistration.division }))
        .then(() => jumpToStep(4))        
    }

    const onCancelledFootballPayment = () => {
        dispatch(teamRegistrationIsPaid(false))
        jumpToStep(0)
    }

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValid
    }))

    return (
        <Alert variant='primary' className={s.container}>
            <FormGroup className={s.formGroup}>
                <InputGroup>
                    <Button variant='light' onClick={() => jumpToStep(0)}><FontAwesomeIcon name='chevron-left' /> Cancel</Button>
                    <div className={s.paypalContainer}>
                        <RedHerringsPayPalButton
                            contest={'Football'}
                            itemNumber={playerId}
                            receiverEmail={paymentEmail}
                            description={'RedHerrings Football ' + seasonName}
                            amount={entryFee}
                            seasonId={seasonId}
                            playerId={playerId}
                            teamName={teamRegistration.teamName}
                            // TODO: CAN I GET new TeamId HERE??
                            onAuthorizedPayment={onAuthorizedFootballPayment}
                            onCancelledPayment={onCancelledFootballPayment} />
                    </div>
                </InputGroup>
            </FormGroup>
        </Alert>
    )
})

export default PreseasonRegistrationStep4
