// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.MobileApps__container___rzrHC{padding:10px;width:100%}.MobileApps__container___rzrHC .MobileApps__title___DhFRl{text-align:center}.MobileApps__container___rzrHC .MobileApps__linkContainer___jRhCa{display:flex;flex-flow:row wrap;justify-content:center;align-items:center}.MobileApps__container___rzrHC .MobileApps__linkContainer___jRhCa .MobileApps__link___qNfHr .MobileApps__img___Zyg8L{height:60px;margin-bottom:10px;margin-right:10px}.MobileApps__container___rzrHC .MobileApps__linkContainer___jRhCa .MobileApps__link___qNfHr .MobileApps__img___Zyg8L:not(:first-of-type){margin-right:10px}`, "",{"version":3,"sources":["webpack://./src/routes/MobileApps/MobileApps.scss"],"names":[],"mappings":"AAGA,+BACI,YAAA,CACA,UAAA,CAEA,0DACI,iBAAA,CAGJ,kEACI,YAAA,CACA,kBAAA,CACA,sBAAA,CACA,kBAAA,CAGI,qHACI,WAAA,CACA,kBAAA,CACA,iBAAA,CACA,yIACI,iBAAA","sourcesContent":["@use '../../styles/variables/breakpoints' as breakpoints;\n@use '../../styles/variables/colors' as colors;\n\n.container {\n    padding: 10px;\n    width: 100%;\n\n    .title {\n        text-align: center;\n    }\n\n    .linkContainer {\n        display: flex;\n        flex-flow: row wrap;\n        justify-content: center;\n        align-items: center;\n\n        .link {\n            .img {\n                height: 60px;\n                margin-bottom: 10px;\n                margin-right: 10px;\n                &:not(:first-of-type) {\n                    margin-right: 10px;\n                }\n            }\n        }    \n    }\n\n}"],"sourceRoot":""}]);
// Exports
export var container = `MobileApps__container___rzrHC`;
export var title = `MobileApps__title___DhFRl`;
export var linkContainer = `MobileApps__linkContainer___jRhCa`;
export var link = `MobileApps__link___qNfHr`;
export var img = `MobileApps__img___Zyg8L`;
export default ___CSS_LOADER_EXPORT___;
