import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { sendFinishPicks, emailPicks } from '@severed-links/common.redherrings-reducers/oscars'

const EnterOscarsPicksFinish = ({ afterDeadline = false }) => {

    const dispatch = useDispatch()
    const { isAuthenticated } = useSelector(state => state.account)
    const { year } = useSelector(state => state.oscars)

    useEffect(() => {
        if (isAuthenticated && year) {
            dispatch(sendFinishPicks(year))
        }
    }, [isAuthenticated, year])

    const _handleEmailButtonClick = () => {
        dispatch(emailPicks(year))
    }

    return (
        <div>
            <Alert variant='success'>
                Thank you for your entry.  Be sure to watch the fun on Oscar night!
            </Alert>
            <p>Press the button below to send yourself an email copy of your picks.</p>
            <p>
                <Button variant='primary' onClick={() => _handleEmailButtonClick()}><FontAwesomeIcon icon='envelope' /> email me my picks!</Button>
            </p>
        </div>
    )
}
    
export default EnterOscarsPicksFinish