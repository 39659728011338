import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useMatch, useLocation } from 'react-router-dom'
import { Row, Col, Table, Alert, Card } from 'react-bootstrap'
import { filter, forEach } from 'lodash'
import * as s from './OscarsFrontPage.scss'
import moment from 'moment-timezone'
import { getEnterPicks, savePick, setOscarsStateValue,
    setOscarsEnterPicksTransitionState } from '@severed-links/common.redherrings-reducers/oscars'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import EnterOscarsPicksNavBar from './EnterOscarsPicksNavBar'
import EnterOscarsPicksStart from './EnterOscarsPicksStart'
import EnterOscarsPicksCategory from './EnterOscarsPicksCategory'
import EnterOscarsPicksFinish from './EnterOscarsPicksFinish'
import { last } from 'lodash'

const EnterOscarsPicks = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const year = useSelector(state => state.oscars.year)
    const pickDeadline = useSelector(state => state.oscars.pickDeadline)
    const isAuthenticated = useSelector(state => state.account.isAuthenticated)
    const enterPicks = useSelector(state => state.oscars.enterPicks)
    const categoryParam = (params.category || '').toLowerCase()
    const category = categoryParam === 'start' ? 0 : categoryParam === 'end' ? 25 : categoryParam && !isNaN(categoryParam) ? parseInt(categoryParam) : 0
    const { hasNominees, num, steps: _steps, category: categoryName,
        afterDeadline, choices, pick, isSavingPick, isTransitioning } = enterPicks
    const steps = (_steps || []).map(i => ({ ...i, isCurrent: category === i.step }))

    useEffect(() => { isAuthenticated && year ? dispatch(getEnterPicks(0)) : null }, [isAuthenticated, year])

    useEffect(() => {
        dispatch(setOscarsStateValue({ enterPicks: { ...enterPicks, steps } }))
        if (isAuthenticated && year && category !== 0 && category < 25) {
            dispatch(getEnterPicks(category))
            .then(action => {
                if (action.payload.afterDeadline) {
                    navigate('/oscars')
                }
            })
        }
    }, [category, isAuthenticated, year])

    const onRouting = step => {
        const routingUrl = `/oscars/enter-picks/${(step === 0 ? 'start' : step === (last(steps) || {}).step ? 'end' : step)}`
        dispatch(setOscarsEnterPicksTransitionState(true))
        setTimeout(() => {
            navigate(routingUrl)
        }, 600)
    }

    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <h2>Enter Your Picks</h2>
                </Col>
            </Row>
            {hasNominees ? 
            <div>
            <Row>
                <Col sm={{ span: 10, offset: 1 }} xs={12}>
                {steps ? 
                    <EnterOscarsPicksNavBar menuItems={steps} 
                        activeKey={`${num}`} 
                        onSelect={onRouting} />
                    : null}
                </Col>
            </Row>
            <Row>
                <Col md={{ span: 6, offset: 3 }} sm={{ span: 10, offset: 1 }} xs={12}>
                    <div>
                        {category === 0 ? <EnterOscarsPicksStart afterDeadline={afterDeadline} /> : null}
                        {category > 0 && category < 25 ? 
                            <EnterOscarsPicksCategory category={categoryName} 
                                num={num} choices={choices}
                                pick={pick} year={year}
                                afterDeadline={afterDeadline} 
                                createNotification={notification => createNotification(notification)}
                                savePick={savePick}
                                isSavingPick={isSavingPick}
                                setTransitionState={isTransitioning => setOscarsEnterPicksTransitionState(isTransitioning)}
                                isTransitioning={isTransitioning} />
                        : null}
                        {category >= 25 ? <EnterOscarsPicksFinish afterDeadline={afterDeadline} /> : null}
                    </div>
                </Col>
            </Row>
            </div>
            : 
            <Row>
                <Col xs={12}>
                    <Alert>No {year} nominees located yet. Stay tuned!</Alert>
                </Col>
            </Row>
            }
        </div>
    )
}
    
export default EnterOscarsPicks