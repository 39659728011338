import React, { useEffect } from 'react'
import { Row, Col, Button, Nav } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { filter, uniqBy } from 'lodash'
import NCAATeam from '../NCAATeam/NCAATeam'
import * as s from './Brackets.scss'

const BracketRounds14 = ({ year = 0, region = 0, rounds = [], bracket = [] }) => {

    return (
        <div className={s.bracketContainer}>
        {rounds && rounds.map(r =>
            <div key={`bracket-${year}-round-${r.round}`} className={s.roundContainer}>
                <h4 className={s.roundHeader}>{r.roundName}</h4>
                <div className={s.gamesContainer + ' ' + s['round' + r.round]}>
                {uniqBy(filter(bracket, b => b.round === r.round), 'game').map(g => 
                    <div key={'game-' + g.game} className={s.gameContainer}>
                        {filter(bracket, b => b.game == g.game).map((t, index) =>
                            <NCAATeam teamId={t.teamId || 0} name={t.teamName || ''} avatarUrl={t.avatarUrl}
                                seed={t.seed || null} gameScore={t.score || null} showGameScore showAvatar
                                key={`bracket-${year}-region-${region}-round-${r}-game-${g.game}-team-${t.teamId}-seed-${t.seed}-index-${index}`} />
                        )}
                    </div>
                )}
                </div>
            </div>
        )}
        </div>
    )
}

export default BracketRounds14