import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './CaptureNcaaGameLists.scss'
import CaptureNcaaGameList from './CaptureNcaaGameList'
import { getNcaaGames } from '@severed-links/common.redherrings-reducers/capture'
import moment from 'moment-timezone'

const CaptureNcaaGameLists = ({ year = 0 }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const contestId = params.contestId || null
    const ncaaGames = useSelector(state => state.capture.ncaaGames)
    const _year = parseInt(params.year || year || moment().year())

    useEffect(() => {
        if (_year) {
            dispatch(getNcaaGames(_year))
        }
    }, [_year])

    if (!ncaaGames || !_year) return null
    const { inProgress, upcoming, completed } = ncaaGames
    return (
        <div className={s.container}>
            {params.year && contestId ?
            <div>
                <Button variant='light' onClick={() => navigate(`/capture/${contestId}/scoreboard`)}><FontAwesomeIcon name='chevron-left' /></Button>
            </div>
            : null}

            <CaptureNcaaGameList title='In Progress Games' games={inProgress} />
            <CaptureNcaaGameList title='Upcoming Games' games={upcoming} />
            <CaptureNcaaGameList title='Completed Games' games={completed} />
        </div>
    )
}

export default CaptureNcaaGameLists
