import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, ListGroup, Card, Image } from 'react-bootstrap'
import { some } from 'lodash'
import * as s from './Scoreboard.scss'
import { getScoreboard } from '@severed-links/common.redherrings-reducers/oscars'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { img } from '@severed-links/common.redherrings-constants'
import PlayerListItem from '../Player/PlayerListItem'

const Scoreboard = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const year = useSelector(state => state.oscars.year)
    const onlineUsers = useSelector(state => state.oscars.onlineUsers)
    const scoreboard = useSelector(state => state.oscars.scoreboard)
    const _yearParam = parseInt(params.year || '0')

    useEffect(() => { dispatch(getScoreboard(_yearParam)) }, [_yearParam])

    const _scoreboard = ((scoreboard || {}).scoreboard || [])
        .map(i => ({ ...i, isOnline: some(onlineUsers, u => u.playerId === i.playerId) }))

    return (
        <div className={s.container}>
            <h2>{year} Scoreboard</h2>
            <Row>
                <Col md={6} xs={12}>
                    <Card className={s.scoreboardCard}>
                        <Card.Header as='h3' className={s.scoreboardHeader}>
                            <div className={s.players}>{scoreboard.playersEntered} {scoreboard.playersEntered !== 1 ? "players" : "player"}</div>
                            <div className={s.possible}>{scoreboard.pointsPossible} {scoreboard.pointsPossible !== 1 ? "points" : "point"} possible</div>
                        </Card.Header>
                        <ListGroup className={s.scoreboardList} variant='flush'>
                        {_scoreboard.map((i, index) =>
                            <PlayerListItem key={`scoreboard-oscars-${i._id || i.playerId || index.toString()}`}
                                player={i} nameBadge={i.isOnline ? <FontAwesomeIcon name='bolt' className={s.onlineIcon} title={'User is online'} /> : null}
                                rightDetail={<div className={s.rightDetail}>
                                    <div className={s.score}>{i.score}</div>
                                </div>} />
                        )}                            
                        {!_scoreboard.length ?
                            <ListGroup.Item variant='light'>No {year} players located.</ListGroup.Item>
                        : null}
                        </ListGroup>
                    </Card>
                </Col>
                <Col md={6} xs={12}>
                {scoreboard.lastOscarAwarded && scoreboard.lastOscarAwarded.category ? 
                <Card className={s.lastOscarAwarded}>
                    <Card.Img className={s.icon} src={img.BaseUrl + '/oscars/oscar-info-icon'}/>
                    <Card.Body className={s.body}>
                        <Card.Title as='h2' className={s.title}>Last Oscar Awarded</Card.Title>
                        <Card.Subtitle as='h3' className={s.subtitle}>{scoreboard.lastOscarAwarded.category}</Card.Subtitle>
                        <h4 className={s.category}>{scoreboard.lastOscarAwarded.choiceText}</h4>
                        <h5 className={s.winner}><b>Picked by:</b>  {(scoreboard.lastOscarAwarded.pickedBy || []).length ? (scoreboard.lastOscarAwarded.pickedBy || []).map(i => i.playerName).join(', ') : 'No one.'}</h5>
                    </Card.Body>
                </Card>
                : null}
                </Col>
            </Row>
        </div>
    )
}

export default Scoreboard