import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import moment from 'moment-timezone'

const LastTicketUpdate = ({ dateUpdated = new Date() }) => {

    const [currentTime, setCurrentTime] = useState(moment().toDate())

    useEffect(() => {
        tick()
        let timerId = setInterval(() => tick(), 1000)
        return () => clearInterval(timerId)
    }, [])

    const tick = () => setCurrentTime(moment().toDate())

    if (!dateUpdated) return null
    return (
        <span>{moment(dateUpdated).fromNow()}</span>
    )
}

export default LastTicketUpdate
