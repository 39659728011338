import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { ListGroup, DropdownButton, Dropdown } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './LeagueSchedulerSettings.scss'
import { setLeagueSchedulerState } from '@severed-links/common.redherrings-reducers/footballAdmin'
import FormCheck from 'react-bootstrap/FormCheck'

const LeagueSchedulerSettings = ({ seasonId = null, isRunning = false, createAutoPicks = false, WAIT_MS = 20 }) => {

    const dispatch = useDispatch()
    const _intervals = [{ value: 10 }, { value: 20 }, { value: 50 }, { value: 100 }, { value: 1000 }].map(i => ({ ...i, title: i.value < 1000 ? `${i.value}ms` : `${i.value / 1000}s` }))
    const _i = _intervals.find(i => i.value === WAIT_MS)

    return (
        <div className={s.container}>
            <ListGroup className={s.list}>
                <ListGroup.Item className={s.item}>
                    <div className={s.title}>Create week 1 auto picks?</div>
                    <div className={s.switch}>
                        <FormCheck type='switch' checked={createAutoPicks} disabled={isRunning}
                            onChange={e => dispatch(setLeagueSchedulerState(seasonId, { createAutoPicks: e.target.checked }))} />
                    </div>
                </ListGroup.Item>
                <ListGroup.Item className={s.item}>
                    <div className={s.title}>Grid refresh rate</div>
                    <div className={s.switch}>
                        <DropdownButton onSelect={e => dispatch(setLeagueSchedulerState(seasonId, { WAIT_MS: parseInt(e) }))}
                            title={_i.title} variant='secondary'>
                        {_intervals && _intervals.map(i =>
                            <Dropdown.Item eventKey={i.value} key={`ms-wait-interval-${i.title}`}>{i.title}</Dropdown.Item>
                        )}
                        </DropdownButton>
                    </div>
                </ListGroup.Item>
            </ListGroup>
        </div>
    )
}

export default LeagueSchedulerSettings