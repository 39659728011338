import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, FormGroup, InputGroup, 
    Button, Image, FormControl, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './RecapComments.scss'
import { getRecapComments, addRecapComment, deleteRecapComment } from '@severed-links/common.redherrings-reducers/cinderella'
import moment from 'moment-timezone'
import pluralize from 'pluralize'
import { first } from 'lodash'
import { playerUrl } from '@severed-links/common.redherrings-constants'
import Confirm from '@severed-links/common.confirm'

const NewRecapComment = ({ newComment = '', avatarUrl = null, onChange, onPost, onCancelEdit, editMode = false }) => (
    <div className={s.newCommentContainer}>
        <FormGroup className={s.formGroup}>
            <InputGroup className={s.inputGroup}>                    
                <InputGroup.Text className={s.newCommentLabel}>Comment</InputGroup.Text>
                <InputGroup.Text className={s.newCommentAvatar}><Image src={playerUrl(avatarUrl)} /></InputGroup.Text>
                <FormControl value={newComment} multiple
                    className={s.newCommentTextBox}
                    onKeyUp={e => e.key === 'Enter' && newComment ? onPost() : null}
                    onChange={e => onChange(e)} />
                {editMode ?
                <Button className={s.cancelButton} onClick={() => onCancelEdit()}>
                    <FontAwesomeIcon name='times' />
                </Button>
                : null}
                <Button onClick={() => onPost()} disabled={!newComment}>
                    <FontAwesomeIcon name='comment' />
                    <span className={s.newCommentButtonLabel}>post</span>
                </Button>
            </InputGroup>
        </FormGroup>
    </div>
)

const RecapComment = ({ currentTime = null, _id, comment = '', firstName, lastName, avatarUrl, createdAt, cinderellas = 0, onDelete, canDelete = false, canEdit = false, onEdit, onCancelEdit, isEditing = false, isEdited = false, isAdmin = false }) => (
    <ListGroup.Item className={s.commentItem + (isEditing ? ` ${s.isEditing}` : '')}>
        <div className={s.heading}>
            <div className={s.avatar}>
                <Image src={playerUrl(avatarUrl)} />
            </div>
            <div className={s.nameContainer}>
                <div className={s.name}>{firstName} {lastName}{isAdmin ? <Badge className={s.adminBadge} bg={'light'} text={'dark'}>Admin</Badge> : null}</div>
                <div className={s.cinderellas}>{pluralize('Cinderella', cinderellas, true)}</div>
            </div>
            <RecapUpdateTime createdAt={createdAt} currentTime={currentTime} />
        </div>
        <div className={s.comment}>{comment}{isEdited ? <span className={s.isEdited}>(edited)</span> : null}</div>
        <div className={s.buttonContainer}>
        {canEdit ?
        <Button className={s.editButton} onClick={() => onEdit(_id)}>
            <FontAwesomeIcon name='pencil-alt' />
        </Button>
        : null}
        {canDelete ?
        <div className={s.deleteButtonContainer}>
            <Confirm variant={'danger'} 
                onConfirm={e => onDelete(_id)}
                title={`Delete Comment`}
                confirmText='delete comment'
                body={`Are you sure you want to delete this comment?`}
                buttonIcon='times' buttonClassName={s.deleteButton} />
        </div>
        : null}
        </div>
    </ListGroup.Item>
)

const RecapUpdateTime = ({ currentTime = null, createdAt = null }) => (
    <div className={s.createdAt}>{moment(createdAt).fromNow()}</div>
)

const RecapComments = ({ recapId = null }) => {

    const dispatch = useDispatch()
    const { _id: playerId, avatarUrl, roles } = useSelector(state => state.account)
    const isCinderellaAdmin = (roles || []).includes('CinderellaAdmin')
    const comments = (useSelector(state => state.cinderella.recapComments)[`${recapId}`] || {}).comments || []
    const [newComment, setNewComment] = useState('')
    const [editCommentId, setEditCommentId] = useState(null)
    const [currentTime, setCurrentTime] = useState(moment().toISOString())
    const _bottomOfComments = useRef(null)

    useEffect(() => {
        let timerId = setInterval(() => setCurrentTime(moment().toISOString()), 5000)
        return () => clearInterval(timerId)
    }, [])

    useEffect(() => { 
        if (recapId) { 
            dispatch(getRecapComments(recapId))
        }
    }, [recapId])

    const scrollToBottom = () => {
        _bottomOfComments.current?.scrollIntoView({ behavior: 'smooth' })
    }

    const handleCommentPost = () => {
        if (newComment) {
            setNewComment('')
            setEditCommentId(null)
            dispatch(addRecapComment(recapId, playerId, newComment, editCommentId))
            .then(() => scrollToBottom())
        }
    }

    const handleDeleteComment = recapCommentId => {
        dispatch(deleteRecapComment(recapCommentId))
        .then(() => scrollToBottom())
    }

    const handleCancelEdit = () => {
        setNewComment('')
        setEditCommentId(null)
    }

    const handleEdit = _editCommentId => {
        setNewComment((comments.find(i => i._id === _editCommentId) || {}).comment || '')
        setEditCommentId(_editCommentId)
    }

    if (!recapId) return null
    return (
        <div className={s.container}>
            <ListGroup className={s.commentList}>
            {comments && comments.map(i =>
                <RecapComment {...i} key={`recap-comment-${i._id}`}
                    currentTime={currentTime}
                    canDelete={isCinderellaAdmin || (playerId === i.playerId)}
                    canEdit={playerId === i.playerId}
                    onEdit={handleEdit}
                    isEditing={editCommentId === i._id}
                    onDelete={handleDeleteComment} />
            )}
            {!comments || !comments.length ?
                <ListGroup.Item className={s.commentItem}>
                    <div className={s.comment + ' ' + s.noComments}>No comments for this recap located</div>
                </ListGroup.Item>
            : null}
            </ListGroup>
            <NewRecapComment newComment={newComment} 
                avatarUrl={avatarUrl} onPost={handleCommentPost}
                onCancelEdit={handleCancelEdit} editMode={!!editCommentId}
                onChange={e => setNewComment(e.target.value)} />
            <div ref={_bottomOfComments} />
        </div>
    )
}

export default RecapComments
