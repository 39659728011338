import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Button, ListGroup, Image, Tooltip, OverlayTrigger } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import { getReminderLog } from '@severed-links/common.redherrings-reducers/manageUsers'
import * as s from './ReminderLog.scss'
import { capitalCase } from 'change-case'
import { isEqual, startsWith } from 'lodash'
import moment from 'moment-timezone'
import { FRIENDLY_SHORT_DATE_FORMAT, FRIENDLY_SHORT_TIME_FORMAT,
    teamUrl, playerUrl } from '@severed-links/common.redherrings-constants'
import PlayerTeamListItem from '../../Football/PlayerTeamListItem'
import SeasonSelector from '../../Football/SeasonSelector'
import WeekSelector from '../../Football/WeekSelector'
import PlayerListItem from '../../Player/PlayerListItem'
import ResetAllEliminatorReminders from '../../Eliminator/Admin/ResetAllEliminatorReminders'

const ReminderDetail = ({ dateSent = null, sentTextReminder = false, sentEmailReminder = false, sentPushReminder = false }) => (
    <div className={s.rightDetail}>
        {sentEmailReminder ? <FontAwesomeIcon className={s.icon} name='envelope' /> : null}
        {sentTextReminder ? <FontAwesomeIcon className={s.icon} name='sms' /> : null}
        {sentPushReminder ? <FontAwesomeIcon className={s.icon} name='mobile' /> : null}
        {dateSent && moment(dateSent).isValid() ?
        <div className={s.dateSent}>
            <div>{moment(dateSent).format(FRIENDLY_SHORT_DATE_FORMAT)}</div>
            <div>{moment(dateSent).format(FRIENDLY_SHORT_TIME_FORMAT)}</div>
        </div>
        : null}
    </div>
)

const ReminderLog = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const app = params.appName || null
    const seasons = useSelector(state => (state[`${(app || '').toLowerCase() || 'football'}`] || {}).seasons || [])
    const reminderLog = useSelector(state => state.manageUsers.reminderLog)
    const seasonName = params.seasonName || null
    const seasonMode = capitalCase(params.seasonMode || '')
    const week = parseInt(params.week || '0')
    const season = seasons.find(i => i.seasonName === seasonName) || {}
    const seasonId = season.seasonId || null

    useEffect(() => { getReminders() }, [app, seasonId, seasonMode, week])

    const getReminders = () => {
        if (app && seasonMode && seasonId && week) {
            console.log({ app, seasonId, seasonMode, week })
            dispatch(getReminderLog(app, seasonId, seasonMode, week))
        }
    }

    const handleWeekSelect = e => {
        const _isPlayoffWeek = startsWith(e, 'PW')
        const _newWeek = e.replace(/\D+/gi, '')
        const _newPath = location.pathname
            .replace(`/${seasonMode.toLowerCase}/`, `/${_isPlayoffWeek ? 'postseason' : 'regular'}/`)
            .replace(/\/week\-\d+$/gi, `/week/${_newWeek}`)
        navigate(_newPath)
    }

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <div className={s.goBack} onClick={() => navigate(-1)}>
                    <h3><FontAwesomeIcon name='chevron-left' /></h3>
                </div>
                <h3 className={s.header}>{capitalCase(app)} {seasonName} {seasonMode} {(seasonMode == 'regular' ? 'Season' : '')} Reminder Log: Week {week}</h3>
                {(app || '').toLowerCase() === 'eliminator' ?
                <div className={s.controls}>
                    <ResetAllEliminatorReminders seasonId={seasonId} />
                </div>
                : null}
                <div className={s.controls}>
                    <SeasonSelector seasons={seasons} season={season} />
                </div>
                <div className={s.controls}>
                    <WeekSelector seasons={seasons} season={season} 
                        week={`${(seasonMode === 'Preseason' ? 'Pre' : seasonMode === 'Postseason' ? 'PW' : 'W')}${week}`}
                        showPlayoffWeeks={seasonMode === 'Postseason'}
                        showPreseasonWeeks={seasonMode === 'Preseason'}
                        showRegularSeasonWeeks={seasonMode === 'Regular'}
                        onSelect={e => handleWeekSelect(e)} />
                </div>
            </div>
            <ListGroup className={s.reminderList}>
            {reminderLog && reminderLog.reminders && reminderLog.reminders.map(i =>
                i.hasTeam ?
                <PlayerTeamListItem {...i} 
                    detail={i.playerName}
                    right={<ReminderDetail {...i} />}
                    key={`reminder-log-${i._id}`} />
                :
                <PlayerListItem player={i}
                    rightDetail={<ReminderDetail {...i} />}
                    key={`reminder-log-${i._id}`} />
            )}
            {!reminderLog || !reminderLog.reminders || reminderLog.reminders.length === 0 ? <ListGroup.Item>No reminders located</ListGroup.Item> : null}
            </ListGroup>
        </div>
    )
}

export default ReminderLog