import React from 'react'
import { Route, Routes, useLocation } from 'react-router-dom'

import AuthenticatedRoute from '../components/AuthenticatedRoute'

/* bring in menus */
import BlankMenu from '../menus/BlankMenu'
import PublicMenu from '../menus/PublicMenu'
import MainMenu from '../menus/MainMenu'
import CaptureMenu from '../menus/CaptureMenu'
import CaptureAdminMenu from '../menus/CaptureAdminMenu'
import CinderellaMenu from '../menus/CinderellaMenu'
import CinderellaAdminMenu from '../menus/CinderellaAdminMenu'
import EliminatorMenu from '../menus/EliminatorMenu'
import EliminatorAdminMenu from '../menus/EliminatorAdminMenu'
import FootballMenu from '../menus/FootballMenu'
import FootballAdminMenu from '../menus/FootballAdminMenu'
import OscarsMenu from '../menus/OscarsMenu'
import OscarsAdminMenu from '../menus/OscarsAdminMenu'

/* import components for routing */
import NotFound from '@severed-links/common.not-found'
import HomeView from './Public/components/HomeView'
import TestSpinner from './Public/components/TestSpinner'

import Login from './Account/Login'
import Logout from './Account/Logout'
import ForgotPassword from './ForgotPassword/ForgotPassword'
import ForgotUsername from './ForgotUsername/ForgotUsername'

import SignUp from './SiteRegistration/SignUp'

import MainView from './Main/MainView'
import RegisterContest from './Main/RegisterContest'
import EditMyProfile from './Main/EditMyProfile'
import ChangeMyPassword from './Main/ChangeMyPassword'
import AccountDeletionPortal from './AccountDeletion/AccountDeletionPortal'

import Logins from './Main/Admin/Logins'

import CaptureFrontPage from './Capture/CaptureFrontPage'
import CaptureTourneyGames from './Capture/CaptureTourneyGames'
import CaptureScoreboard from './Capture/CaptureScoreboard'
import CaptureGameLog from './Capture/CaptureGameLog'
import CaptureNcaaGameLists from './Capture/CaptureNcaaGameLists'

import CaptureAdminDashboard from './Capture/Admin/CaptureAdminDashboard'
import CaptureContests from './Capture/Admin/CaptureContests'
import CaptureTeamDraw from './Capture/Admin/CaptureTeamDraw'
import CaptureTeamDrawPrint from './Capture/Admin/CaptureTeamDrawPrint'
import CaptureGameSpreads from './Capture/Admin/CaptureGameSpreads'

import RecapView from './Cinderella/Recap/RecapView'
import CinderellaEntries from './Cinderella/Entries/CinderellaEntries'
import CinderellaEditEntry from './Cinderella/Entries/CinderellaEditEntry'
import ScoreboardRedirector from './Cinderella/Scoreboard/ScoreboardRedirector'
import Scoreboard from './Cinderella/Scoreboard/Scoreboard'
import Brackets from './Cinderella/Brackets/Brackets'
import PastChamps from './Cinderella/PastChamps/PastChamps'

import CinderellaAdminDashboard from './Cinderella/Admin/CinderellaAdminDashboard'
import CinderellaAdminBracketBuilder from './Cinderella/Admin/CinderellaAdminBracketBuilder'
import CinderellaPayments from './Cinderella/Admin/CinderellaPayments'
import CinderellaEditGameScores from './Cinderella/Admin/CinderellaEditGameScores'
import CinderellaRecaps from './Cinderella/Admin/CinderellaRecaps'
import BracketBuilderTournamentStartDates from './Cinderella/Admin/BracketBuilderTournamentStartDates'

import EliminatorIntro from './Eliminator/EliminatorIntro'
import EliminatorEnterPick from './Eliminator/EliminatorEnterPick'
import EliminatorStandings from './Eliminator/EliminatorStandings'
import EliminatorNflScoreboard from './Eliminator/EliminatorNflScoreboard'
import EliminatorWinners from './Eliminator/EliminatorWinners'

import EliminatorAdminDashboard from './Eliminator/Admin/EliminatorAdminDashboard'
import EliminatorPayments from './Eliminator/Admin/EliminatorPayments' 
import EliminatorRegisterPlayers from './Eliminator/Admin/EliminatorRegisterPlayers' 
import EliminatorAdminManualPickEntry from './Eliminator/Admin/EliminatorAdminManualPickEntry'

import FootballRedirector from './Football/FootballRedirector'
import FAQ from './Football/RegularSeason/FAQ'
import Payouts from './Football/RegularSeason/Payouts'
import Preseason from './Football/Preseason/Preseason'
import RegularSeason from './Football/RegularSeason/RegularSeason'
import Postseason from './Football/Postseason/Postseason'
import PostseasonMatchupDetail from './Football/Postseason/PostseasonMatchupDetail'
import TeamProfileRedirector from './Football/TeamProfile/TeamProfileRedirector'
import TeamProfile from './Football/TeamProfile/TeamProfile'
import EditTeamProfile from './Football/TeamProfile/EditTeamProfile'
import MatchUp from './Football/RegularSeason/MatchUp'
import LeagueScoreboard from './Football/RegularSeason/LeagueScoreboard'
import NFLStandings from './Football/RegularSeason/NFL/NFLStandings'
import NFLScoreboard from './Football/RegularSeason/NFL/NFLScoreboard'
import NFLPlayoffs from './Football/RegularSeason/NFL/NFLPlayoffs'
import NFLTeamAnalysis from './Football/RegularSeason/NFL/NFLTeamAnalysis'
import NFLNews from './Football/RegularSeason/NFL/NFLNews'
import NFLInjuryReport from './Football/RegularSeason/NFL/NFLInjuryReport'
import NFLCoverTrends from './Football/RegularSeason/NFL/NFLCoverTrends'
import FootballLeagueReport from './Football/Reports/FootballLeagueReport'
import PickPercentageReport from './Football/Reports/PickPercentageReport'
import PickTrendsReport from './Football/Reports/PickTrendsReport'
import TopScoreOddsReport from './Football/Reports/TopScoreOddsReport'
import PlayoffRaceReport from './Football/Reports/PlayoffRaceReport'
import PoolGeographyReport from './Football/Reports/PoolGeographyReport'
import FavoriteNflTeamsReport from './Football/Reports/FavoriteNflTeamsReport'
import DivisionStandingsView from './Football/DivisionStandingsView'
import EnterRegularSeasonFootballPicks from './Football/RegularSeason/EnterRegularSeasonFootballPicks'
import EnterPostseasonFootballPicks from './Football/Postseason/EnterPostseasonFootballPicks'

import FootballAdminDashboard from './Football/Admin/FootballAdminDashboard'
import ManageUsers from './Main/Admin/ManageUsers' 
import ReminderLog from './Main/Admin/ReminderLog' 

import FootballPayments from './Football/Admin/FootballPayments' 
import NflScheduler from './Football/Admin/NflScheduler'
import EditNflTeams from './Football/Admin/EditNflTeams'
import LeagueScheduler from './Football/Admin/LeagueScheduler'
import TeamData from './Football/Admin/TeamData'
import UpdateManager from './Football/Admin/UpdateManager'
import UpdateRegularSeasonLinesView from './Football/Admin/UpdateRegularSeasonLinesView'
import ShowPayouts from './Football/Admin/ShowPayouts'
import SetupLeaguePlayoffs from './Football/Admin/SetupLeaguePlayoffs'
import EditNflPlayoffGamesContainer from './Football/Admin/EditNflPlayoffGamesContainer'
import DivisionOrganizer from './Football/Admin/DivisionOrganizer'
import EditSeasons from './Football/Admin/EditSeasons'

import OscarsFrontPage from './Oscars/OscarsFrontPage'
import Nominees from './Oscars/Nominees'
import OscarsScoreboard from './Oscars/Scoreboard'
import OscarsStandings from './Oscars/Standings'
import OscarsViewPicks from './Oscars/ViewPicks'
import EnterOscarsPicks from './Oscars/EnterOscarsPicks'

import OscarsAdminDashboard from './Oscars/Admin/OscarsAdminDashboard'
import OscarsAwardCategories from './Oscars/Admin/OscarsAwardCategories'
import OscarsNominees from './Oscars/Admin/OscarsNominees'
import OscarsWinners from './Oscars/Admin/OscarsWinners'
import OscarsPosters from './Oscars/Admin/OscarsPosters'

import HelpCenter from './Help/HelpCenter'

export const Empty = () => null

export const MenuRoutes = () => (
  <Routes>
    <Route path='/main/*' element={<MainMenu />} />
    <Route path='/capture/admin/*' element={<CaptureAdminMenu />} />
    <Route path='/capture/:contestId/*' element={<CaptureMenu />} />
    <Route path='/capture' element={<CaptureMenu />} />
    <Route path='/cinderella/admin/*' element={<CinderellaAdminMenu />} />
    <Route path='/cinderella/*' element={<CinderellaMenu />} />
    <Route path='/eliminator/admin/*' element={<EliminatorAdminMenu />} />
    <Route path='/eliminator/*' element={<EliminatorMenu />} />
    <Route path='/football/admin/*' element={<FootballAdminMenu />} />
    <Route path='/football/*' element={<FootballMenu />} />
    <Route path='/oscars/admin/*' element={<OscarsAdminMenu />} />
    <Route path='/oscars/*' element={<OscarsMenu />} />
    <Route path='*' element={<PublicMenu />} />
  </Routes>
)

export const ContentRoutes = () => (
  <Routes>

    <Route path='/' element={<HomeView />} />
    <Route path='/test-spinner' element={<TestSpinner />} />

    <Route path='/login' element={<Login />} />
    <Route path='/forgot-password' element={<ForgotPassword />} />
    <Route path='/forgot-username' element={<ForgotUsername />} />
    <Route path='/signup' element={<SignUp />} />
    <Route path='/logout' element={<Logout />} />

    <Route path='/main' element={<AuthenticatedRoute />}>
      <Route path='' element={<MainView />} />      
      <Route path='register-contest' element={<RegisterContest />} />
    </Route>

    <Route path='/:appName/user-profile' element={<AuthenticatedRoute />}>
      <Route path='edit-profile' element={<EditMyProfile />} />
      <Route path='change-password' element={<ChangeMyPassword />} />
      <Route path='account-deletion' element={<AccountDeletionPortal />} />
    </Route>

    <Route path='/:appName/help-center' element={<AuthenticatedRoute />}>
      <Route path='' element={<HelpCenter />} />      
    </Route>

    <Route path='/:appName/admin/logins' element={<AuthenticatedRoute roles={['CaptureAdmin','CinderellaAdmin','EliminatorAdmin','FootballAdmin','OscarsAdmin']} />}>
      <Route path='' element={<Logins />} />      
    </Route>

    <Route path='/:appName/admin/manage-users' element={<AuthenticatedRoute roles={['CaptureAdmin','CinderellaAdmin','EliminatorAdmin','FootballAdmin','OscarsAdmin']} />}>
      <Route path='' element={<ManageUsers />} />      
      <Route path='filter/:letter/:roleFilter/:playerId/edit' element={<ManageUsers />} />
      <Route path='filter/:letter/:roleFilter' element={<ManageUsers />} />
      <Route path='filter/:letter' element={<ManageUsers />} />
    </Route>

    <Route path='/:appName/admin/reminder-log' element={<AuthenticatedRoute roles={['CaptureAdmin','CinderellaAdmin','EliminatorAdmin','FootballAdmin','OscarsAdmin']} />}>
      <Route path='season/:seasonName' element={<ReminderLog />} />
      <Route path='season/:seasonName/:seasonMode' element={<ReminderLog />} />
      <Route path='season/:seasonName/:seasonMode/week/:week' element={<ReminderLog />} />
    </Route>

    <Route path='/capture/:contestId' element={<AuthenticatedRoute allowedRoles={['CapturePlayer','CaptureAdmin']} />}>
      <Route path='' element={<CaptureFrontPage />} />
      <Route path='tourney-games/:currentDate' element={<CaptureTourneyGames />} />
      <Route path='tourney-games' element={<CaptureTourneyGames />} />
      <Route path='scoreboard' element={<CaptureScoreboard />} />
      <Route path='game-log' element={<CaptureGameLog />} />
      <Route path='ncaa-games/:year' element={<CaptureNcaaGameLists />} />
    </Route>

    <Route path='/capture' element={<AuthenticatedRoute allowedRoles={['CapturePlayer','CaptureAdmin']} />}>
      <Route path='' element={<CaptureFrontPage />} />

      <Route path='admin' element={<AuthenticatedRoute allowedRoles={['CaptureAdmin']} />}>
        <Route path='' element={<CaptureAdminDashboard />} />
        <Route path='contests' element={<CaptureContests />} />
        <Route path='team-draw/:contestId' element={<CaptureTeamDraw />} />
        <Route path='team-draw-print/:contestId' element={<CaptureTeamDrawPrint />} />
        <Route path='game-spreads/:year' element={<CaptureGameSpreads showCaptureForceUpdate />} />
      </Route>

    </Route>

    <Route path='/capture' element={<CaptureFrontPage />} />
        
    <Route path='/cinderella' element={<AuthenticatedRoute allowedRoles={['CinderellaPlayer','CinderellaAdmin']} />}>
      <Route path='' element={<RecapView />} />
      <Route path='entries' element={<CinderellaEntries />} />
      <Route path='entry/new' element={<CinderellaEditEntry />} />
      <Route path='entry/edit/:entryId' element={<CinderellaEditEntry />} />
      <Route path='scoreboard' element={<ScoreboardRedirector />} />
      <Route path='scoreboard/:year/page/:page' element={<Scoreboard />} />
      <Route path='brackets/:year/:region' element={<Brackets />} />
      <Route path='brackets/:year' element={<Brackets />} />
      <Route path='past-champs' element={<PastChamps />} />

      <Route path='admin' element={<AuthenticatedRoute allowedRoles={['CinderellaAdmin']} />}>
        <Route path='' element={<CinderellaAdminDashboard />} />
        <Route path='utility/:dashboardSubTab' element={<CinderellaAdminDashboard />} />
        <Route path='recap/editor/new' element={<CinderellaRecaps />} />
        <Route path='recap/editor/:recapId/edit' element={<CinderellaRecaps />} />
        <Route path='recap/editor' element={<CinderellaRecaps />} />
        <Route path='tournament/start-dates' element={<BracketBuilderTournamentStartDates />} />
        <Route path='tournament-teams/:year' element={<CinderellaAdminBracketBuilder />} />
        <Route path='payments/:year' element={<CinderellaPayments />} />
        <Route path='tournament-games/:year' element={<CinderellaEditGameScores />} />
      </Route>

    </Route>
    
    <Route path='/eliminator' element={<AuthenticatedRoute allowedRoles={['EliminatorPlayer','EliminatorAdmin']} />}>
      <Route path='' element={<EliminatorIntro />} />
      <Route path='enter-pick' element={<EliminatorEnterPick />} />
      <Route path='standings/:seasonName' element={<EliminatorStandings />} />
      <Route path='nfl-scoreboard/:seasonName/week/:week' element={<EliminatorNflScoreboard />} />
      <Route path='winners' element={<EliminatorWinners />} />

      <Route path='admin' element={<AuthenticatedRoute allowedRoles={['EliminatorAdmin']} />}>
        <Route path='' element={<EliminatorAdminDashboard />} />
        <Route path='payments/:seasonName' element={<EliminatorPayments />} />
        <Route path='register-players/:seasonName' element={<EliminatorRegisterPlayers />} />
        <Route path='manual-entry/:seasonName/:seasonMode/week/:week' element={<EliminatorAdminManualPickEntry />} />
      </Route>

    </Route>
        
    <Route path='/football' element={<AuthenticatedRoute allowedRoles={['FootballPlayer','FootballAdmin']} />}>
      <Route path='' element={<FootballRedirector />} />
      <Route path='faq/:seasonName' element={<FAQ />} />
      <Route path='payouts/:seasonName' element={<Payouts />} />
      <Route path='preseason/:seasonName' element={<Preseason />} />
      <Route path='regular-season/:seasonName' element={<RegularSeason />} />
      <Route path='postseason/:seasonName' element={<Postseason />} />
      <Route path='postseason/:seasonName/playoff-week/:playoffWeek' element={<PostseasonMatchupDetail />} />
      <Route path='edit-team-profile/:teamId' element={<EditTeamProfile />} />
      <Route path='team-profile' element={<TeamProfileRedirector />} />
      <Route path='team-profile/:teamId' element={<TeamProfile />} />
      <Route path='regular-season/league-scoreboard/:seasonName/week/:week' element={<LeagueScoreboard />} />
      <Route path='regular-season/match-up/:seasonName/week/:week/game/:game/:teams' element={<MatchUp />} />
      <Route path='nfl-standings/:seasonName' element={<NFLStandings />} />
      <Route path='nfl-scoreboard/:seasonName/week/:week' element={<NFLScoreboard />} />
      <Route path='nfl-playoffs/:seasonName' element={<NFLPlayoffs />} />
      <Route path='nfl-team-analysis' element={<NFLTeamAnalysis />} />
      <Route path='nfl-team-analysis/:seasonName' element={<NFLTeamAnalysis />} />
      <Route path='nfl-team-analysis/:seasonName/:mascot' element={<NFLTeamAnalysis />} />
      <Route path='nfl-news' element={<NFLNews />} />
      <Route path='nfl-injury-report/:mascot' element={<NFLInjuryReport />} />
      <Route path='nfl-cover-trends/:seasonName' element={<NFLCoverTrends />} />
      <Route path='reports/pick-percentage/:seasonName' element={<PickPercentageReport />} />
      <Route path='reports/pick-trends/:seasonName/week/:week' element={<PickTrendsReport />} />
      <Route path='reports/top-score-odds/:seasonName/week/:week' element={<TopScoreOddsReport />} />
      <Route path='reports/playoff-race/:seasonName' element={<PlayoffRaceReport showHeader />} />
      <Route path='reports/pool-geography/:seasonName' element={<PoolGeographyReport />} />
      <Route path='reports/favorite-nfl-teams/:seasonName' element={<FavoriteNflTeamsReport />} />
      <Route path='reports/:reportId/:seasonName' element={<FootballLeagueReport />} />
      <Route path='league-standings/:seasonName' element={<DivisionStandingsView />} />
      <Route path='regular-season/:seasonName/enter-picks' element={<EnterRegularSeasonFootballPicks />} />
      <Route path='postseason/:seasonName/enter-picks' element={<EnterPostseasonFootballPicks />} />

      <Route path='admin' element={<AuthenticatedRoute allowedRoles={['FootballAdmin']} />}>
        <Route path='' element={<FootballAdminDashboard />} />
        <Route path='utility/:dashboardSubTab' element={<FootballAdminDashboard />} />
        <Route path='preseason/payment-log/:seasonName' element={<FootballPayments />} />
        <Route path='preseason/nfl-scheduler' element={<NflScheduler />} />
        <Route path='preseason/nfl-scheduler/:seasonName' element={<NflScheduler />} />
        <Route path='preseason/edit-nfl-teams' element={<EditNflTeams />} />
        <Route path='preseason/league-scheduler' element={<LeagueScheduler />} />
        <Route path='preseason/league-scheduler/:seasonName' element={<LeagueScheduler />} />
        <Route path='preseason/team-data/:seasonName' element={<TeamData />} />
        <Route path='preseason/division-organizer/:seasonName' element={<DivisionOrganizer />} />
        <Route path='regular-season/update-manager/:seasonName/week/:week' element={<UpdateManager />} />
        <Route path='regular-season/update-manager/:seasonName/week/:week/utility/:utility' element={<UpdateManager />} />
        <Route path='regular-season/update-nfl-lines/:seasonName/week/:week' element={<UpdateRegularSeasonLinesView />} />
        <Route path='postseason/update-manager/:seasonName/playoff-week/:week' element={<UpdateManager />} />
        <Route path='postseason/update-manager/:seasonName/playoff-week/:week/utility/:utility' element={<UpdateManager />} />
        <Route path='postseason/show-payouts/:seasonName' element={<ShowPayouts />} />
        <Route path='postseason/setup-playoffs/:seasonName' element={<SetupLeaguePlayoffs />} />
        <Route path='postseason/setup-playoffs/:seasonName/:subTab' element={<SetupLeaguePlayoffs />} />
        <Route path='postseason/update-nfl-playoffs/:seasonName' element={<EditNflPlayoffGamesContainer />} />
        <Route path='edit-seasons' element={<EditSeasons />} />
      </Route>

    </Route>

    <Route path='/oscars' element={<AuthenticatedRoute allowedRoles={['OscarsPlayer','OscarsAdmin']} />}>
      <Route path='' element={<OscarsFrontPage />} />
      <Route path='nominees/:year' element={<Nominees />} />
      <Route path='view-picks/:year' element={<OscarsViewPicks />} />
      <Route path='scoreboard/:year' element={<OscarsScoreboard />} />
      <Route path='standings' element={<OscarsStandings />} />
      <Route path='enter-picks/:category' element={<EnterOscarsPicks />} />

      <Route path='admin' element={<AuthenticatedRoute allowedRoles={['OscarsAdmin']} />}>
        <Route path='' element={<OscarsAdminDashboard />} />
        <Route path='categories' element={<OscarsAwardCategories />} />
        <Route path='nominees/:year' element={<OscarsNominees />} />
        <Route path='winners/:year' element={<OscarsWinners />} />
        <Route path='posters' element={<OscarsPosters />} />
      </Route>
    </Route>
    

    <Route path='*' element={<NotFound useLocation={useLocation} />}></Route>
  </Routes>
)

