import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Badge, Table, ListGroup, Card, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'
import * as s from './EspnScrapeTester.scss'
import { getEspnScoreboardWeeks, testEspnScoreboard } from '@severed-links/common.redherrings-reducers/footballAdmin'
import NflTeamListItem from '../NflTeamListItem'
import moment from 'moment-timezone'
import { startsWith, keysIn } from 'lodash'
import SeasonSelector from '../SeasonSelector'
import SeasonModeSelector from '../SeasonModeSelector'
import WeekSelector from '../WeekSelector'
import JSONFormatter from 'json-formatter-js'

const EspnScrapeTester = ({ defaultSeasonId = null, defaultSeasonMode = null, defaultWeek = 1, show = false, onClose }) => {

    const dispatch = useDispatch()    
    const seasons = useSelector(state => state.football.seasons)
    const seasonModes = useSelector(state => state.football.seasonModes)
    const [seasonId, setSeasonId] = useState(null)
    const [seasonMode, setSeasonMode] = useState('Preseason')
    const [week, setWeek] = useState(1)
    const [weeks, setWeeks] = useState([])
    const [games, setGames] = useState([])
    const [codeObject, setCodeObject] = useState(null)
    const [error, setError] = useState(null)
    const [gameCount, setGameCount] = useState(null)
    const [message, setMessage] = useState(null)
    const [messageType, setMessageType] = useState(null)

    useEffect(() => { setSeasonId(defaultSeasonId) }, [defaultSeasonId])
    useEffect(() => { setSeasonMode(defaultSeasonMode) }, [defaultSeasonMode])
    useEffect(() => { setWeek(defaultWeek) }, [defaultWeek])
    useEffect(() => { loadEspnWeeks() }, [seasonId])
    useEffect(() => { loadEspnScoreboardData() }, [seasonId, seasonMode, week])

    const loadEspnWeeks = () => {
        if (seasonId) {
            dispatch(getEspnScoreboardWeeks(seasonId))
            .then(action => setWeeks(action.payload.weeks || []))
        }
    }

    const loadEspnScoreboardData = () => {
        if (seasonId && seasonMode && week) {
            dispatch(testEspnScoreboard(seasonId, seasonMode, week))
            .then(action => {
                setError(action.payload.error)
                setGameCount(action.payload.gameCount)
                setGames(action.payload.games)
                setMessage(action.payload.message)
                setMessageType(action.payload.messageType)
            })
        }
    }

    const onSeasonChange = _seasonId => setSeasonId(_seasonId)
    const onSeasonModeChange = _seasonMode => setSeasonMode(_seasonMode)
    const onWeekChange = _week => setWeek(parseInt(_week.replace(/\D/gi, '')))

    const _season = { 
        ...(seasons.find(i => i.seasonId === seasonId) || {}),
        weeks: weeks.filter(i => i.isRegularSeasonWeek),
        preseasonWeeks: weeks.filter(i => i.isPreseasonWeek),
        playoffWeeks: weeks.filter(i => i.isPlayoffWeek),
    }
    const _jsonFormatter = new JSONFormatter(codeObject || {}, 2)
    const _jsonNode = _jsonFormatter.render()
    return (
        <div className={s.container}>
            <Modal heading={`ESPN Screen Scrape Tester`}
                show={show}
                onClose={onClose ? () => onClose() : null}>
                <div className={s.container}>
                    <ListGroup className={s.list}>
                        <ListGroup.Item className={s.item}>
                            <div className={s.title}>Season</div>
                            <div className={s.controls}>
                                <SeasonSelector seasons={seasons} season={seasons.find(i => i.seasonId === seasonId)} onSelect={_season => onSeasonChange(_season)} />
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item className={s.item}>
                            <div className={s.title}>Season mode</div>
                            <div className={s.controls}>
                                <SeasonModeSelector showPreseason seasonMode={seasonMode} onSelect={_seasonMode => onSeasonModeChange(_seasonMode)} />
                            </div>
                        </ListGroup.Item>
                        <ListGroup.Item className={s.item}>
                            <div className={s.title}>Week</div>
                            <div className={s.controls}>
                                <WeekSelector seasons={seasons} season={_season} 
                                    week={`${(seasonMode === 'Preseason' ? 'Pre' : seasonMode === 'Postseason' ? 'PW' : 'W')}${week}`}
                                    showPlayoffWeeks={seasonMode === 'Postseason'}
                                    showPreseasonWeeks={seasonMode === 'Preseason'}
                                    showRegularSeasonWeeks={seasonMode === 'Regular'}
                                    onSelect={e => onWeekChange(e)} />
                            </div>
                        </ListGroup.Item>
                    </ListGroup>
                    {!error && games && games.length ?
                    <div>
                    {games && games.map(g =>
                    <Card key={`game-card-${seasonId}-${seasonMode}-${week}-${g.gameId}`}
                        className={s.card}>
                        <Card.Header className={s.cardHeader}>
                            <div>{moment(g.gameTime).tz('America/New_York').format('M/D h:mm A')}</div>
                            {g.isComplete ? <div className={s.final}>Final</div>
                            : g.shortStatus ? <div>{g.shortStatus}</div>
                            : null}
                        </Card.Header>
                        <ListGroup variant='flush'>
                        {g.oTeams && g.oTeams.map(t => 
                        <NflTeamListItem {...t} 
                            right={<div className={s.rightContainer}>
                                {(t.isHome && g.homeFavorite === true) || (!t.isHome && g.homeFavorite === false) ? <div className={s.homeFavoriteIcon}><FontAwesomeIcon name='check' /></div> : null}
                                <div className={s.gameScore}>{!g.isNotStarted ? `${t.score}` : null}</div>
                            </div>}
                            showLine={t.isHome && g.line !== null}
                            line={g.line} showSignOnLine={false}
                            showOverUnder={!t.isHome && g.overUnder !== null}
                            overUnder={g.overUnder}
                            key={`scrape-team-${t.isHome ? 'home' : 'visitor'}-${g.gameId}-${t.teamId}`} />
                        )}
                        </ListGroup>
                        <Card.Footer className={s.cardFooter}>
                            {g.headline ? <div className={s.headline}>{g.headline}</div> : <div>&nbsp;</div>}
                            <Button className={s.button} variant='light' size='sm'
                                onClick={() => setCodeObject(g)}>
                                <FontAwesomeIcon name='code' />
                            </Button>
                        </Card.Footer>
                    </Card>
                    )}
                    </div>
                    : null}
                    {error ?
                    <div>
                        <Alert variant={messageType === 'error' ? 'danger' : messageType}>{message}</Alert>
                    </div>
                    : null}
                </div>
                <Modal show={!!codeObject}
                    heading={`Raw Game JSON: Game ID ${(codeObject || {}).gameId}`}
                    onClose={() => setCodeObject(null)}>
                    <div className={s.codeContainer} ref={_ref => _ref && _ref.appendChild ? _ref.appendChild(_jsonNode) : void(0)} />
                </Modal>
            </Modal>
        </div>
    )
}

export default EspnScrapeTester