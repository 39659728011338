import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getTourneyDays, getGamesForDay } from '@severed-links/common.redherrings-reducers/capture'
import moment from 'moment-timezone'
import { find, filter, first, last, orderBy } from 'lodash'
import * as s from './CaptureTourneyGames.scss'
import CaptureCalendarBar from './CaptureCalendarBar'
import CaptureTourneyGameList from './CaptureTourneyGameList'

const CaptureTourneyGames = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const contestId = params.contestId || null
    const tourneyDays = useSelector(state => state.capture.tourneyDays || [])
    const currentDate = params.currentDate || null
    const _currentDateUrlFormat = 'YYYY-MM-DD'
    const gamesForDay = useSelector(state => state.capture.gamesForDay)

    useEffect(() => { getData() }, [contestId])
    useEffect(() => { loadGames() }, [currentDate])
    useEffect(() => { autoSelectDate() }, [tourneyDays.length, currentDate])

    const getData = () => {
        if (contestId) {
            dispatch(getTourneyDays(contestId))
        }
    }

    const autoSelectDate = () => {
        if (tourneyDays.length && (!currentDate || !moment(currentDate, _currentDateUrlFormat).isValid())) {
            const _now = moment.tz('America/New_York')
            var _tourneyDay = null
            if (_now.isSameOrBefore(first(tourneyDays).date, 'day')) {
                _tourneyDay = moment.tz(first(tourneyDays).date, 'America/New_York').startOf('day')
            } else if (moment().isSameOrAfter(last(tourneyDays).date, 'day')) {
                _tourneyDay = moment.tz(last(tourneyDays).date, 'America/New_York').startOf('day')
            } else {
                var currentDates = filter(orderBy(tourneyDays, ['date'], ['asc']), d => moment().isSameOrBefore(moment(d.date), 'day'))
                if (currentDates && currentDates.length > 0) {
                    _tourneyDay = moment.tz(first(currentDates).date, 'America/New_York').startOf('day')
                } else {
                    _tourneyDay = moment.tz(first(tourneyDays).date, 'America/New_York').startOf('day')
                }
            }
            if (_tourneyDay) navigate(`/capture/${contestId}/tourney-games/${moment.tz(_tourneyDay, 'America/New_York').format(_currentDateUrlFormat)}`)
        }
    }

    const loadGames = () => {
        if (currentDate && moment(currentDate, _currentDateUrlFormat).isValid()) {
            dispatch(getGamesForDay(moment(currentDate, _currentDateUrlFormat).startOf('day').toISOString(true)))
        } else {

        }
    }

    return (
        <div className={s.container}>
            <h2>Tourney Games</h2>
            <CaptureCalendarBar />
            {currentDate ? 
                <CaptureTourneyGameList />
            : null}
        </div>
    )
}

export default CaptureTourneyGames