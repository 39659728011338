import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { ListGroup, FormGroup, FormControl, InputGroup, FormLabel, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './NflGameRescheduler.scss'
import NflTeam from '../NflTeam'
import moment from 'moment-timezone'
import Datetime from 'react-datetime'
import { rescheduleNflGame, rescheduleNflGameReset, getNflRegularSeasonGamesForWeek } from '@severed-links/common.redherrings-reducers/footballAdmin'
import Confirm from '@severed-links/common.confirm'

const NflGameRescheduler = ({ seasonId, seasonName, week, game }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const seasons = useSelector(state => state.football.seasons)
    const nflReschedulerResponse = useSelector(state => state.footballAdmin.nflReschedulerResponse)
    const [newWeek, setNewWeek] = useState(0)
    const [newGameTime, setNewGameTime] = useState(moment().toDate())
    const [isSaving, setSaving] = useState(false)

    useEffect(() => {
        rescheduleNflGameReset()
        if (game) {
            setNewWeek(game.week)
            if (game.date) {
                setNewGameTime(moment(game.date, 'M/D/YYYY h:mm A').toDate())
            }
        }
    }, [])

    const handleDateChange = dt => {
        const _newGameTime = moment(newGameTime)
        _newGameTime.setFullYear(dt.year())
        _newGameTime.setMonth(dt.month())
        _newGameTime.setDate(dt.date())
        setNewGameTime(_newGameTime)
    }

    const handleTimeChange = dt => {
        const _newGameTime = moment(newGameTime)
        _newGameTime.hour(dt.hour())
        _newGameTime.minute(dt.minute())
        _newGameTime.second(0)
        _newGameTime.millisecond(0)
        setNewGameTime(_newGameTime)
    }

    const doReschedule = () => {
        const _postData = { gameId: game._id, newWeek, newGameTime: moment(newGameTime).toISOString(true) }
        setSaving(true)
        dispatch(rescheduleNflGame(_postData))
        .then(action => {
            if (action.payload.messageType === 'success') {
                dispatch(getNflRegularSeasonGamesForWeek(seasonId, week))
            }
            setSaving(false)
        })
    }

    if (!game) return null
    const _teams = [game.visitor, game.home]
    return (
        <div className={s.container}>
            <div className={s.weekHeading}>{seasonName} Week #{week}</div>
            <div className={s.teamContainer}>
                <ListGroup className={s.teamList}>
                {_teams.map(i => <NflTeam teamId={i.teamId} teamName={i.teamName} mascot={i.mascot} key={`rescheduler-team-${i.teamId}`} />)}
                </ListGroup>
                <div className={s.gameTimeContainer}>
                    {game.date}
                </div>
            </div>
            <FormGroup>
                <InputGroup>
                    <InputGroup.Text className={s.newWeekInputGroupText}>New Week Number</InputGroup.Text>
                    <FormControl value={newWeek} type='number' className={s.newWeekTextBox} onChange={e => setNewWeek(parseInt(e.target.value))} />
                </InputGroup>                                        
            </FormGroup>
            <FormGroup>
                <FormLabel>New Game Time: {moment(newGameTime).format('M/D/YYYY h:mm A')}</FormLabel>
                <div className={s.calendarContainer}>
                    <div className={s.calendar}>
                        <Datetime value={newGameTime} open input={false}
                            title={'New Game Date'}
                            dateFormat='M/D/YYYY' timeFormat={false}
                            onChange={dt => handleDateChange(dt)} />
                    </div>
                    <div className={s.calendar}>
                        <Datetime value={newGameTime} open input={false}
                            title={'New Game Time'}
                            dateFormat={false} timeFormat={'h:mm A'}
                            onChange={dt => handleTimeChange(dt)} />
                    </div>
                </div>
            </FormGroup>
            {!nflReschedulerResponse ?
            <Confirm variant='warning' onConfirm={() => doReschedule()}
                title={'Confirm Game Reschedule'}
                confirmText='confirm game reschedule'
                body={'Are you sure you want to reschedule this game?'}
                disabled={isSaving || week === newWeek} 
                buttonIcon='calendar' buttonText='confirm game reschedule' />
            :
            <ListGroup className={s.responseList}>
            {[
                { title: nflReschedulerResponse.subject, subtitle: nflReschedulerResponse.message, variant: nflReschedulerResponse.messageType === 'error' ? 'danger' : 'success' },
                { title: 'Eliminator picks at issue', right: nflReschedulerResponse.eliminatorPicksCount, variant: nflReschedulerResponse.eliminatorPicksCount ? 'warning' : 'success' },
                { title: `Football picks updated`, right: nflReschedulerResponse.picksUpdatedCount },
                { title: `Game ${nflReschedulerResponse.gameNumber} football picks deleted`, right: nflReschedulerResponse.picksDeletedCount },
            ].map(i =>
                <ListGroup.Item variant={i.variant} className={s.responseItem}>
                    <div className={s.content}>
                        <div className={s.title}>{i.title}</div>
                        {i.subtitle ? <div className={s.subtitle}>{i.subtitle}</div> : null}
                    </div>
                    <div className={s.right}>{i.right}</div>
                </ListGroup.Item>
            )}
            </ListGroup>}
        </div>
    )
}

export default NflGameRescheduler