// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AvatarNeedsEditingAlert__container___bTfYQ{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center;padding:.25rem .75rem}.AvatarNeedsEditingAlert__container___bTfYQ .AvatarNeedsEditingAlert__avatar___vTDwj{flex:0 0 40px;height:40px;border-radius:8px;margin-right:1rem}.AvatarNeedsEditingAlert__container___bTfYQ .AvatarNeedsEditingAlert__message___RE4zM{flex:1 1 auto}.AvatarNeedsEditingAlert__container___bTfYQ .AvatarNeedsEditingAlert__controls___0WQaA{margin-left:1rem;white-space:nowrap;flex:0 0 0}`, "",{"version":3,"sources":["webpack://./src/routes/Football/TeamProfile/AvatarNeedsEditingAlert.scss"],"names":[],"mappings":"AAAA,4CACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CACA,qBAAA,CAEA,qFACI,aAAA,CACA,WAAA,CACA,iBAAA,CACA,iBAAA,CAGJ,sFACI,aAAA,CAGJ,uFACI,gBAAA,CACA,kBAAA,CACA,UAAA","sourcesContent":[".container {\n    display: flex;\n    flex-flow: row nowrap;\n    justify-content: space-between;\n    align-items: center;\n    padding: 0.25rem 0.75rem;\n\n    .avatar {\n        flex: 0 0 40px;\n        height: 40px;\n        border-radius: 8px;\n        margin-right: 1rem;\n    }\n\n    .message {\n        flex: 1 1 auto;\n    }\n\n    .controls {\n        margin-left: 1rem;\n        white-space: nowrap;\n        flex: 0 0 0;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `AvatarNeedsEditingAlert__container___bTfYQ`;
export var avatar = `AvatarNeedsEditingAlert__avatar___vTDwj`;
export var message = `AvatarNeedsEditingAlert__message___RE4zM`;
export var controls = `AvatarNeedsEditingAlert__controls___0WQaA`;
export default ___CSS_LOADER_EXPORT___;
