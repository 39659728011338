import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form, FormGroup, FormControl, FormLabel, Button, 
    ToggleButtonGroup, ToggleButton, Image } from 'react-bootstrap'
import * as s from './ManageUsers.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import FormCheck from 'react-bootstrap/FormCheck'
import CloudinaryImage from '../../Cloudinary/CloudinaryImage'
import CloudinaryUploader from '../../Cloudinary/CloudinaryUploader'
import Confirm from '@severed-links/common.confirm'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { checkEmail, checkUsername, setUserInfo } from '@severed-links/common.redherrings-reducers/siteRegistration'
import { getAllRoles } from '@severed-links/common.redherrings-reducers/account'
import { img, playerUrl } from '@severed-links/common.redherrings-constants'
import { some, trimEnd, find, startsWith, filter, forEach } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment-timezone'
import validator from 'validator'

const ManageUsersAddNewUser = () => {

    const dispatch = useDispatch()
    const uploadedImages = useSelector(state => state.images)
    const [_newUser, setNewUser] = useState({ _id: null, avatarUrl: '', emailAddress: '', cellPhone: '', fastLogin: false,
        firstName: '', _id: null, isLockedOut: false, lastName: '', numLogins: 0, playerId: null, roleCount: 0, userName: '',
        roles: [], userRoles: [], isUserNameOk: false, isEmailAddressOk: false, isCellPhoneOk: false, isUserValid: false, 
    })
    const { _id, firstName, lastName, emailAddress, cellPhone,
        password, avatarUrl, fastLogin, lastLogin,
        userRoles, roles, isEmailAddressOk, isUserNameOk, isCellPhoneOk,
        isUserValid, userName
    } = _newUser


    useEffect(() => {
        dispatch(getAllRoles())
        .then(action => setNewUser({
            ..._newUser, 
            avatarUrl: '', emailAddress: '', cellPhone: '', fastLogin: false,
            firstName: '', _id: null, isLockedOut: false, lastName: '', numLogins: 0,
            playerId: null, roleCount: 0, userName: '',
            roles: [...action.payload.map(role => ({ userName: '', playerId: null, roleName: role, displayName: role, userInRole: 0, userIsInRole: false }))],
            userRoles: [], isUserNameOk: false, isEmailAddressOk: false, isCellPhoneOk: false, isUserValid: false,
        }))
    }, [])

    useEffect(() => { newUserIsValid() }, [firstName, lastName, isUserNameOk, isEmailAddressOk, password])

    useEffect(() => {
        if (userName && userName.length >= 3) {
            dispatch(checkUsername(userName))
            .then(action => setNewUser({ ..._newUser, isUserNameOk: action.payload.isAvailable }))
        }
    }, [userName])

    useEffect(() => {
        if (emailAddress && validator.isEmail(emailAddress)) {
            dispatch(checkEmail(emailAddress))
            .then(action => setNewUser({ ..._newUser, isEmailAddressOk: action.payload.isAvailable }))
        } else {
            setNewUser({ ..._newUser, isEmailAddressOk: false })
        }
    }, [emailAddress])

    useEffect(() => { validateCellPhone() }, [cellPhone])

    const updatePlayerAvatar = _uploadResults => {
        const { public_id: avatarUrl } = _uploadResults
        if (avatarUrl) {
            setNewUser({ ..._newUser, avatarUrl })
        }
    }

    const newUserIsValid = () => {
        const { firstName, lastName, isUserNameOk, isEmailAddressOk, password } = _newUser
        const isUserValid = firstName && lastName && isUserNameOk && isEmailAddressOk && password
        setNewUser({ ..._newUser, isUserValid })
    }

    const handleChange = e => {
        var fieldName = e.target.name
        setNewUser({ ..._newUser, [fieldName]: e.target.value })
    }

    const handleBootstrapSwitch = (e, checkedState) => setNewUser({ ..._newUser, [e.target.name]: checkedState })

    const handleRoleChange = (_roles = []) => setNewUser({ ..._newUser, userRoles: [ ..._roles ] })

    const validateCellPhone = () => setNewUser({ ..._newUser, isCellPhoneOk: validator.isMobilePhone(_newUser.cellPhone || '', 'en-US') })

    const saveUser = () => {
        if (!_newUser.isUserValid) return
        var saveData = { ..._newUser }
        forEach(saveData.roles, r => {
            r.userName = saveData.userName
            r.userIsInRole = some(saveData.userRoles, r1 => r.roleName === r1)
        })
        delete saveData.userRoles
        delete saveData.isUserNameOk
        delete saveData.isEmailAddressOk
        dispatch(saveUser(saveData))
        .then(action => {
            dispatch(createNotification({
                type: action.payload.messageType, 
                headline: 'Save New Player', 
                message: action.payload.message, 
                timeout: 6000
            }))
            onClose()
        })
    }

    return (
        <div className={s.container}>
            <Form>
            <Row>
                <Col sm={6} xs={12}>
                    <Row>
                        <Col xs={6}>
                            <FormGroup>
                                <FormLabel>First name</FormLabel>
                                <FormControl name='firstName' autoCapitalize='words' value={firstName} 
                                    required isValid={!validator.isEmpty(firstName || '')}
                                    isInvalid={validator.isEmpty(firstName || '')}
                                    onChange={e => handleChange(e)} />
                                <FormControl.Feedback />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <FormLabel>Last name</FormLabel>
                                <FormControl name='lastName' autoCapitalize='words' value={lastName} 
                                    required isValid={!validator.isEmpty(lastName || '')}
                                    isInvalid={validator.isEmpty(lastName || '')}
                                    onChange={e => handleChange(e)} />
                                <FormControl.Feedback />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <FormLabel>Email address</FormLabel>
                                <FormControl name='emailAddress' autoCapitalize='none' value={emailAddress} 
                                    required isValid={validator.isEmail(emailAddress || '') && isEmailAddressOk}
                                    isInvalid={!emailAddress || !isEmailAddressOk}
                                    onChange={e => handleChange(e)} />
                                    <FormControl.Feedback />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <FormLabel>Cell Phone</FormLabel>
                                <FormControl name='cellPhone' autoCapitalize='none' 
                                    required isValid={isCellPhoneOk}
                                    isInvalid={!isCellPhoneOk}
                                    value={cellPhone} onChange={(e) => handleChange(e)} />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <FormLabel>Username</FormLabel>
                                <FormControl id='manageUsersNewUserUserName' autoCapitalize='none' name='userName' 
                                    value={userName} 
                                    required isValid={!validator.isEmpty(userName || '') && isUserNameOk}
                                    isInvalid={!userName || !isUserNameOk}
                                    onChange={e => handleChange(e)} />
                                <FormControl.Feedback />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup>
                                <FormLabel>Password</FormLabel>
                                <FormControl name='password' value={password} 
                                    required isValid={!validator.isEmpty(password || '') && validator.isLength(password || '', { min: 5 })}
                                    isInvalid={!password || !validator.isLength(password || '', { min: 5 })}
                                    onChange={e => handleChange(e)} />
                                    <FormControl.Feedback />
                            </FormGroup>
                        </Col>
                </Row>
                </Col>
                <Col sm={6} xs={12}>
                    <Row>
                        <Col xs={12}>
                            <FormGroup>
                                <FormLabel style={{ display: 'block' }}>Player avatar</FormLabel>
                                <div className={s.newUserAvatarContainer}>
                                    <Image src={playerUrl(avatarUrl)} className={s.avatar} />
                                    <div className={s.button}>
                                        <CloudinaryUploader originalId={avatarUrl} 
                                            showImage={false}
                                            showButtonIsBlock={false}
                                            showButton={true}
                                            showButtonSize={'sm'}
                                            folder={img.PlayerAvatarFolder}
                                            onUploadSuccess={updatePlayerAvatar} />
                                        </div>
                                </div>
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row> 
            <Row>
                <Col xs={12}>
                    <ToggleButtonGroup className={s.userRoleToggleButtonGroup} 
                        type='checkbox' value={userRoles}
                        onChange={handleRoleChange}>
                        {roles && roles.map(r =>
                        <ToggleButton id={`add-new-user-toggle-button-${r.roleName}`} className={s.button + ' ' + (some(userRoles, ur => ur === r.roleName) ? s.green : s.red)} key={r.roleName} value={r.roleName}>{r.displayName}</ToggleButton>
                        )}
                    </ToggleButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup>
                        <FormLabel>&nbsp;</FormLabel>
                        <Confirm onConfirm={() => saveUser()}
                                title={'Confirm Save New User'}
                                variant='primary' 
                                confirmText='confirm save user'
                                body={`Are you sure you want to save this new user '${firstName} ${lastName}'?`}
                                disabled={!isUserValid} buttonIcon='check' buttonText='save user' />
                    </FormGroup>
                </Col>
            </Row> 
        </Form>
        </div>
    )
}


export default ManageUsersAddNewUser
