import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { isEmpty } from 'validator'
import { resetForgotUsername, setUserInfo, requestUsername } from '@severed-links/common.redherrings-reducers/forgotUsername'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import * as s from '../ForgotPassword/ForgotPassword.scss'

const ForgotUsernameStep1 = forwardRef(({ jumpToStep }, _ref) => {

    const dispatch = useDispatch()
    const fu = useSelector(state => state.forgotUsername)
    const [sendBy, setSendBy] = useState('')
    const _isValid = fu.sendBy == 'Email' || fu.sendBy == 'Text'

    useEffect(() => {
        setSendBy('')
        dispatch(resetForgotUsername())
    }, [])

    useEffect(() => {
        if (fu.nextDisabled !== !_isValid) dispatch(setUserInfo({ nextDisabled: !_isValid }))
    }, [_isValid, fu.nextDisabled])

    const handleClick = _sendBy => {
        setSendBy(_sendBy)
        dispatch(setUserInfo({ sendBy: _sendBy }))
        jumpToStep(1)
    }


    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValid
    }))    


    return (
        <div className={s.step1}>
            <p className={s.instructions}>We can send your user name to your registered email address or cell phone 
                that is associated with your Redherrings account.</p>
            <div className={s.buttonGroup}>
                <div className={s.button}>
                    <Button size='lg' variant={fu.sendBy === 'Email' ? 'primary' : 'light'} 
                        onClick={() => handleClick('Email')}>
                        <FontAwesomeIcon name='envelope' />
                        {' '}
                        <span className='d-none d-md-inline'>send via</span> 
                        {' '}
                        <span>email</span>
                    </Button>
                </div>
                <div className={s.button}>
                    <Button size='lg' variant={fu.sendBy === 'Text' ? 'primary' : 'light'} 
                        onClick={() => handleClick('Text')}>
                        <FontAwesomeIcon name='mobile' />
                        {' '}
                        <span className='d-none d-md-inline'>send via</span>
                        {' '}
                        SMS
                    </Button>
                </div>
            </div>
        </div>
    )
})

export default ForgotUsernameStep1
