import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './ResetAllEliminatorReminders.scss'
import Modal from '@severed-links/common.modal'
import Picker from '@severed-links/common.picker'
import { resetAllReminders } from '@severed-links/common.redherrings-reducers/eliminatorAdmin'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { REMINDER_BUTTONS } from '@severed-links/common.redherrings-constants'
import FormCheck from 'react-bootstrap/FormCheck'

const ResetAllEliminatorReminders = ({ seasonId }) => {

    const dispatch = useDispatch()
    const seasons = useSelector(state => state.eliminator.seasons || [])
    const seasonName = (seasons.find(s => s.seasonId === seasonId) || {}).seasonName || ''
    const [show, setShow] = useState(false)
    const [reminderTypes, setReminderTypes] = useState({})

    useEffect(() => {
        if (seasonId) {
            setReminderTypes(REMINDER_BUTTONS.reduce(
                (obj, item) => ({
                  ...obj,
                  [item.fieldName]: false,
                }),
                {}
            ))
        }
    }, [seasonId])

    const handleSave = () => {
        dispatch(resetAllReminders(seasonId, reminderTypes))
        .then(action => {
            dispatch(createNotification(action.payload))
            if (action.payload.messageType === 'success') {
                setShow(close)
            }
        })
    }


    return (
        <div className={s.container}>
            <Button className={s.button} variant='light' onClick={() => setShow(true)}><FontAwesomeIcon name='clock' /> reset all reminders</Button>

            <Modal show={show}
                heading={`Reset All ${seasonName} Reminders`}
                onClose={() => setShow(false)}
                actionButtonText='save'
                actionButtonIcon='check'
                actionButtonDisabled={false}
                actionButtonOnClick={() => handleSave()}>
                {show ?
                <ListGroup className={s.reminderTypeList}>
                {REMINDER_BUTTONS.map(i => ({ ...i, value: reminderTypes[i.fieldName] })).map((item, index) =>
                <ListGroup.Item className={s.item} key={`reminder-type-${item.fieldName}`}>
                    <FontAwesomeIcon fixedWidth name={item.icon} className={s.icon} />
                    <div className={s.content}>{item.title}</div>
                    <div className={s.controls}>
                        <FormCheck type='switch' checked={item.value} onChange={e => setReminderTypes({ ...reminderTypes, [item.fieldName]: e.target.checked })} />
                    </div>
                </ListGroup.Item>
                )}
                </ListGroup>
                : null}
            </Modal>
        </div>
    )
}

export default ResetAllEliminatorReminders
