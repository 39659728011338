import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router'
import { ListGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getPlayoffDetail, getNflPlayoffScoreboard } from '@severed-links/common.redherrings-reducers/football'
import { find, uniq, filter } from 'lodash'
import * as s from './PostseasonMatchupDetail.scss'
import Line from '../Line'
import moment from 'moment-timezone'
import { img } from '@severed-links/common.redherrings-constants'
import PlayerTeamListItem from '../PlayerTeamListItem'
import pluralize from 'pluralize'

const NflPlayoffGameHeader = ({ g = null }) => {
    const _hasBegun = moment().isAfter(moment(g.date), 'second')
    return (
        <div className={s.nflGameItem}>
            <div className={s.header}>
                {g.isBeforeKickoff ?
                <>
                <div>{moment(g.date).format('MMM D')}</div>
                <div>{moment(g.date).format('h:mm A')}</div>
                </>
                : g.isComplete ?
                <>
                <div>Final</div>
                </>
                : <div>{g.status || `${moment(g.date).format('MMM D h:mm A')}`}</div>}
            </div>
            <div className={s.content}>
                <div className={s.item + ' ' + s.avatar} title={g.homeTeam} style={{ backgroundImage: 'url(' + img.BaseUrl + img.NflSmallTeamFolder + '/' + g.homeMascot + ')' }}>&nbsp;</div>
                <div className={s.item + ' ' + s.score}><div>{_hasBegun ? g.homeScore : '\u00a0'}</div></div>
                <div className={s.item + ' ' + s.score}><div>{_hasBegun ? g.visitorScore : '\u00a0'}</div></div>
                <div className={s.item + ' ' + s.avatar} title={g.visitorTeam} style={{ backgroundImage: 'url(' + img.BaseUrl + img.NflSmallTeamFolder + '/' + g.visitorMascot + ')' }}>&nbsp;</div>
            </div>
            <div className={s.footer}>
                <div className={s.line + (g.result === 'Favorite' ? ` ${s.correct}` : g.result === 'Underdog' || g.result === 'Push' ? ` ${s.incorrect}` : ``)}>{g.favoriteMascot} by <Line line={g.line} showSign={false} /></div>
                <div className={s.overUnder + (g.ouResult ? ` ${s.correct}` : '')}>{g.ouResult || 'O/U'}: <Line line={g.ou} showSign={false} /></div>
            </div>
        </div>
    )
}

const TiebreakerNotice = ({ playoffWeek = 0, tiebreaker = 0 }) => (
    playoffWeek === 4 && tiebreaker > 0 ?
    <div className={s.content + ' ' + s.tiebreaker}>
        Total {pluralize('point', tiebreaker, false)}: {tiebreaker}
    </div>
    : null
)

const PlayerPlayoffPicksBox = ({ p = null, playoffWeek = 0 }) => {
    const _hasBegun = moment().isAfter(moment(p.gameDate), 'second')
    const _hasBegunClass = _hasBegun ? ` ${s.hasBegun}` : ''
    const _isCorrectPickClass = p.result && p.isPickCorrect ? s.correct : s.incorrect
    const _isCorrectOuPickClass = p.ouResult && p.isOuCorrect ? s.correct : s.incorrect
    return (
        <div className={s.nflGameItem + ' ' + s.picks}>
            <div className={s.content}>
                <div className={`${s.item} ${s.avatar} ${s.pick} ${_hasBegunClass} ${_isCorrectPickClass}`} title={`${p.pick} ${p.pickTeam}`} style={{ backgroundImage: 'url(' + img.BaseUrl + img.NflSmallTeamFolder + '/' + (p.pickTeam || '').toLowerCase() + ')' }}>&nbsp;</div>
                <div className={`${s.item} ${s.score} ${s.pick} ${_hasBegunClass} ${_isCorrectPickClass}`} title={`${p.pick} ${p.pickTeam}`}><div>{p.rank || '\u00a0'}</div></div>
                <div className={`${s.item} ${s.score} ${s.pick} ${_hasBegunClass} ${_isCorrectOuPickClass}`} title={`${p.ouPick}`}><div>{p.ouRank || '\u00a0'}</div></div>
                <div className={`${s.item} ${s.avatar} ${s.pick} ${_hasBegunClass} ${s.overUnderIcon} ${_isCorrectOuPickClass}`} title={`${p.ouPick}`}>
                    <FontAwesomeIcon name={p.ouPick === 'Over' ? 'arrow-up' : p.ouPick === 'Under' ? 'arrow-down' : 'arrow-right'} />
                </div>
            </div>
            <TiebreakerNotice playoffWeek={playoffWeek} tiebreaker={p.tiebreaker} />
        </div>
    )
}

const PlayerScore = ({ score = 0 }) => <div className={s.playerScore}>{score}</div>

const PlayerSeed = ({ num = 0 }) => <div className={s.seedContainer}>#{num}</div>

const PostseasonMatchupDetail = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const seasonName = params.seasonName || null
    const playoffWeekParam = params.playoffWeek || null
    const playoffWeek = parseInt(playoffWeekParam || '0')
    const seasons = useSelector(state => state.football.seasons)
    const season = seasons.find(i => i.seasonName === seasonName)
    const seasonId = (season || {}).seasonId || null
    const playoffDetail = useSelector(state => (state.football.playoffDetails || []).find(i => i.seasonName === seasonName && i.week === playoffWeek) || {})
    const playerId = useSelector(state => state.account._id)
    const _nflScoreboardGames = ((((useSelector(state => state.football.nflPlayoffScoreboards)
        .find(x => x.seasonId === seasonId) || { weeks: [] }).weeks || [])
        .find(x => x.week === playoffWeek) || { games: [] }).games || [])
    const _nflPlayoffScoreboardKey = _nflScoreboardGames.map(x => `${x.visitorScore || 0}-${x.homeScore || 0} (${x.status})`).join(',')
 
    useEffect(() => { seasonName && playoffWeek && _nflScoreboardGames.length ? dispatch(getPlayoffDetail(seasonName, playoffWeek)) : null }, [seasonName, playoffWeek, _nflScoreboardGames.length, _nflPlayoffScoreboardKey])
    useEffect(() => { seasonId ? dispatch(getNflPlayoffScoreboard(seasonId)) : null }, [seasonId])

    if (!season || !playoffWeek || !playoffDetail) return null
    const { playoffTeams, afterDeadline } = playoffDetail
    const _gameNumbers = _.uniq((playoffTeams || []).map(i => i.game))
    return (
        <div className={s.container}>
            <div className={s.heading}>
                <div className={s.backIcon}>
                    <Button onClick={() => navigate(`/football/postseason/${seasonName}`)} variant='light'>
                        <FontAwesomeIcon name='chevron-left' />
                    </Button>
                </div>
                <h2 className={s.title}>{seasonName} Postseason Week {playoffWeek} Matchup Detail</h2>
            </div>
            <div className={s.nflGamesContainer}>
            {_nflScoreboardGames && _nflScoreboardGames.map(g => <NflPlayoffGameHeader g={g} key={`nfl-playoff-game-${season.seasonName}-${playoffWeek}-${g._id}`} />)}
            </div>
            <div className={s.matchupsContainer}>
                {_gameNumbers && _gameNumbers.map(g => 
                <ListGroup className={s.playoffDetailList} key={`league-playoff-game-${seasonName}-${playoffWeek}-game-${g}`}>
                {playoffTeams && playoffTeams.filter(i => i.game === g).map(t =>
                <PlayerTeamListItem key={`league-playoff-team-${seasonName}-${playoffWeek}-game-${g}-${t.teamId}`}
                    teamId={t.teamId} teamName={t.teamName} avatarUrl={t.avatarUrl}
                    division={t.division} showDivision
                    seasonId={t.seasonId} seasonName={seasonName}
                    playoffSeed={t.displaySeed} showPlayoffSeed
                    left={<PlayerSeed num={t.num} />}
                    middle={t.picks && t.picks.length ? 
                    <div className={s.picksContainer}>
                    {t.picks && t.picks.map(p => <PlayerPlayoffPicksBox p={p} playoffWeek={playoffWeek} key={`league-playoff-team-${seasonName}-${playoffWeek}-game-${g}-${t.teamId}-pick-${p.game}`} />)}
                    </div> : null}
                    right={<PlayerScore score={t.score} />} />
                )}
                </ListGroup>
                )}
            </div>
        </div>
    )
}

export default PostseasonMatchupDetail