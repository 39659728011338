import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Button, Table, Carousel, CarouselCaption, CarouselItem, 
    Popover, OverlayTrigger, Alert, ListGroup, Overlay } from 'react-bootstrap'
import CloudinaryImage from '../Cloudinary/CloudinaryImage'
import { filter, trim } from 'lodash'
import * as s from './OscarsFrontPage.scss'
import { getMovieDetails, comparePosterArrays, setOscarsStateValue } from '@severed-links/common.redherrings-reducers/oscars'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import moment from 'moment-timezone'
import pluralize from 'pluralize'
import OscarsDeadlineCalendar from './OscarsDeadlineCalendar'

const OscarsMoviePopoverContent = ({ year, bestPicture, bestActor, bestActress, bestSupportingActor, bestSupportingActress, bestDirector }) => (
    <ListGroup className={s.popoverList} variant='flush'>
    {[
        bestDirector ? { award: `Director`, winner: bestDirector } : null,
        bestActor ? { award: `Actor`, winner: bestActor } : null,
        bestActress ? { award: `Actress`, winner: bestActress } : null,
        bestSupportingActor ? { award: `Supporting Actor`, winner: bestSupportingActor } : null,
        bestSupportingActress ? { award: `Supporting Actress`, winner: bestSupportingActress } : null,
    ].filter(i => i).map(i => 
        <ListGroup.Item className={s.item} key={`oscars-other-awards-${year}-${i.award}`}>
            <div className={s.content}>
                <div className={s.award}>{i.award}</div>
                <div className={s.winner}>{i.winner}</div>
            </div>
        </ListGroup.Item>
    )}
    </ListGroup>
)

const OscarsMainPage = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const year = useSelector(state => state.oscars.year)
    const posters = useSelector(state => state.oscars.posters)
    const posterIndex = useSelector(state => state.oscars.posterIndex)
    const posterDirection = useSelector(state => state.oscars.posterDirection)
    const pickDeadline = useSelector(state => state.oscars.pickDeadline)
    const isFetching = useSelector(state => state.oscars.isFetching)
    const [showPopover, setShowPopover] = useState(false)
    const [currentTime, setCurrentTime] = useState(moment().toISOString())
    const _minutesLeftToDeadline = moment(pickDeadline).diff(moment(currentTime), 'minute')
    const _pickAlertVariant = _minutesLeftToDeadline >= 0 && _minutesLeftToDeadline <= 60 ? 'danger' : 'info'

    useEffect(() => {
        if (!isFetching && posters && posters.length > 0 && posterIndex > -1 && !posters[posterIndex].loaded) {
            dispatch(getMovieDetails(posters[posterIndex].posterYear))
        }
    }, [isFetching, posters.length, posterIndex, posterIndex > -1 && posterIndex <= posters.length - 1 ? !posters[posterIndex].loaded : true])

    useEffect(() => {
        let timerId = setInterval(() => setCurrentTime(moment().toISOString()), 1000)
        return () => clearInterval(timerId)
    }, [])

    const handleSelect = (selectedIndex, e) => {
        dispatch(setOscarsStateValue({
            posterIndex: selectedIndex,
            ...(e && e.direction ? { posterDirection: e.direction } : {})
        }))
    }

    const afterDeadline = moment.utc().isSameOrAfter(moment(pickDeadline), 'minute')
    const _cp = posters && posters.length && posterIndex > 0 ? posters[posterIndex] : { caption: {} }
    return (
        <div className={s.container}>
            <h2>Tom's {year} Oscar Contest</h2>
            <div className={s.contentContainer}>
                <div className={s.instructions}>
                    <p>
                        Welcome to Tom's Oscar Contest...where the prize money is nil, but 
                        the bragging rights are worth everything.
                    </p>
                    <p>
                        Use the navigation bar above to enter your picks and join in the fun 
                        on Oscar night!
                    </p>
                    <OscarsDeadlineCalendar />
                    {pickDeadline && !afterDeadline ? 
                        <Alert variant={_pickAlertVariant} className={s.enterPickAlert}>
                            <div className={s.alertText}>{_pickAlertVariant === 'danger' ? `You have ${pluralize('minute', _minutesLeftToDeadline, true)} to enter your picks!` : 'There is still time to enter your picks!'}</div>
                            <div className={s.button}>
                                <Button variant={_pickAlertVariant} onClick={() => navigate(`/oscars/enter-picks/start`)}><FontAwesomeIcon name='chevron-right' /></Button>
                            </div>
                        </Alert>
                    : null}
                </div>
                <div className={s.posters}>
                {posters && posters.length > 0 ?
                <Carousel activeIndex={posterIndex} direction={posterDirection} 
                    className={s.carousel} indicators={false} controls={true}
                    prevIcon={<FontAwesomeIcon name='chevron-left' />}
                    nextIcon={<FontAwesomeIcon name='chevron-right' />}
                    onSelect={handleSelect}
                    onSlide={() => setShowPopover(false)}>
                {posters.map((p, _index) =>
                    <Carousel.Item key={`poster-item-${p._id}`} className={s.carouselItem}>
                        <div className={s.posterContainer} style={p.loaded && p.imageUrl ? { backgroundImage: `url(${p.imageUrl})` } : null} />
                        <Carousel.Caption className={s.carouselCaption}>
                        {p.caption ?
                            <OverlayTrigger placement='top' overlay={<Popover className={s.moviePopoverContent}><Popover.Header className={s.popoverTitle}>{(p.caption || {}).year}</Popover.Header><Popover.Body className={s.popoverContent}><OscarsMoviePopoverContent {...(p.caption || {})} /></Popover.Body></Popover>}>
                            <div style={{ position: 'relative' }}>
                                <h4>{trim(`${(p.caption || {}).bestPicture || ''}${p.caption && p.caption.year ? ` (${p.caption.year})` : ''}`)}</h4>
                            </div>
                            </OverlayTrigger>
                        : null}
                        </Carousel.Caption>
                    </Carousel.Item> 
                )}
                </Carousel>
                : null}
                </div>
            </div>
        </div>
    )
}

export default OscarsMainPage
