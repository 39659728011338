import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { useAuthenticatedSocket } from './useAuthenticatedSocket'
import { upperCaseFirst } from 'upper-case-first'
import { first } from 'lodash'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { setOscarsOnlineUsers, setOscarsScoreboard } from '@severed-links/common.redherrings-reducers/oscars'
import { setNflScoreboard, setNflPlayoffScoreboard, setNflStandings, setLeagueScoreboard, setLeagueStandings, 
    setFootballDetails, getTeamProfile, getMatchup } from '@severed-links/common.redherrings-reducers/football'
import { setFootballOnlineUsers, updateManagerChecklistFromSocket, setFootballPayments, reloadFootballScores,
    setFootballAdminPlayoffGames } from '@severed-links/common.redherrings-reducers/footballAdmin'
import { setFrontPageData, setFrontPageRecap, getEntries as getCinderellaEntries, 
    setScoreboard as setCinderellaScoreboard, updateRecapComments, updateRecapLikes, updateOngoingGames as cinderellaUpdateOngoingGames } from '@severed-links/common.redherrings-reducers/cinderella'
import { setCinderellaOnlineUsers, newAuditTrailEntry, setPayments, setDuplicatePayments } from '@severed-links/common.redherrings-reducers/cinderellaAdmin'
import { setNflEliminatorScoreboard, setEliminatorStandings, setEliminatorSeasonDetails, setFrontPagePickReport, getMainPageData, getStandings as getEliminatorStandings } from '@severed-links/common.redherrings-reducers/eliminator'
import { setEliminatorOnlineUsers, setEliminatorPayments } from '@severed-links/common.redherrings-reducers/eliminatorAdmin'
import { updateOngoingGames as captureUpdateOngoingGames } from '@severed-links/common.redherrings-reducers/capture'
import { setCaptureOnlineUsers } from '@severed-links/common.redherrings-reducers/captureAdmin'
import { sendSocketCommand, clearSocketCommand } from '@severed-links/common.redherrings-reducers/socket'
import { updateHelpCenterTicket } from '@severed-links/common.redherrings-reducers/help'
import { closePaymentModal } from '@severed-links/common.redherrings-reducers/payment'

const SocketClient = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const isDevelopment = __DEV__
    const playerId = useSelector(state => state.account._id)
    const isAuthenticated = useSelector(state => state.account.isAuthenticated)
    const commandQueue = useSelector(state => state.socket.commandQueue || [])
    const teamProfiles = useSelector(state => state.football.teamProfiles || [])
    const matchUps = useSelector(state => state.football.matchUps || [])

    const { socket, connected, error } = useAuthenticatedSocket()
    
    const app = upperCaseFirst(`${location.pathname}`.split('/')[1].toLowerCase().replace('-admin',''))
    
    useEffect(() => { isDevelopment ? console.log(`socket is ${!connected ? 'not ' : ''}connected...`) : null }, [connected])
    useEffect(() => { onAppChange() }, [isAuthenticated, app, connected])
    useEffect(() => { onCommandQueueChange() }, [isAuthenticated, connected, commandQueue.length])

    useEffect(() => {
        socket
        // ACTION RESPONSES
        .on('showCaptureOnlineUsers', _response => dispatch(setCaptureOnlineUsers(_response)))
        .on('showCinderellaOnlineUsers', _response => dispatch(setCinderellaOnlineUsers(_response)))
        .on('showFootballOnlineUsers', _response => dispatch(setFootballOnlineUsers(_response)))
        .on('showEliminatorOnlineUsers', _response => dispatch(setEliminatorOnlineUsers(_response)))
        .on('showOscarsOnlineUsers', _response => dispatch(setOscarsOnlineUsers(_response)))
        .on('pushNotification', _response => { 
            dispatch(createNotification(_response))
            if (app === 'Cinderella' && _response.headline === 'Final Score Update') {
                dispatch(getCinderellaEntries())
            }
        })
        .on('helpCenterTicketUpdate', _response => dispatch(updateHelpCenterTicket({ ..._response, loggedInPlayerId: playerId, isMyTicket: playerId === _response.playerId })))
    
        // Capture
        .on('captureUpdateOngoingGames', _response => dispatch(captureUpdateOngoingGames(_response)))

        // Cinderella Events
        .on('pushCinderellaFrontPageUpdate', _response => dispatch(setFrontPageData(_response)))
        .on('pushCinderellaFrontPageRecap', _response => dispatch(setFrontPageRecap(_response)))
        .on('newCinderellaAuditTrailEntry', _response => dispatch(newAuditTrailEntry(_response)))
        .on('ncaaScoreboardUpdate', _response => dispatch(setCinderellaScoreboard(_response)))
        .on('cinderellaPaymentUpdate', _response => dispatch(setPayments(_response)))
        .on('cinderellaDuplicatePaymentUpdate', _response => dispatch(setDuplicatePayments(_response)))
        .on('updateRecapComments', _response => dispatch(updateRecapComments(_response)))
        .on('updateRecapLikes', _response => dispatch(updateRecapLikes(_response)))
        .on('cinderellaUpdateOngoingGames', _response => dispatch(cinderellaUpdateOngoingGames(_response)))
    
        // Football Events
        .on('pushFootballDetails', _response => dispatch(setFootballDetails(_response)))
        .on('updateFootballDivisionStandings', _response => isDevelopment ? console.log(_response) : null)
        .on('nflScoreboard', _response => dispatch(setNflScoreboard(_response)))
        .on('nflStandings', _response => dispatch(setNflStandings(_response)))
        .on('footballLeagueScoreboard', _response => {
            if (app === 'Football') {
                dispatch(setLeagueScoreboard(_response))
                if (_response.seasonId && _response.scoreboard) {
                    teamProfiles.filter(tp => tp.seasonId === _response.seasonId).forEach(i => {
                        dispatch(getTeamProfile(i._id))
                    })
                    matchUps.filter(m => m.seasonId === _response.seasonId).forEach(i => {
                        dispatch(getMatchup(i.seasonName, i.week, i.game))
                    })
                }
            }
        })
        .on('footballLeagueStandings', _response => dispatch(setLeagueStandings(_response)))
        .on('nflPlayoffs', _response => dispatch(setNflPlayoffScoreboard(_response)))
    
        //FootballAdmin Events
        .on('updateManagerChecklistFromSocket', _response => dispatch(updateManagerChecklistFromSocket(_response)))
        .on('footballPaymentUpdate', _response => dispatch(setFootballPayments(_response)))
        .on('footballAdminReloadScores', _response => dispatch(reloadFootballScores(_response)))
        .on('updateFootballAdminNflPlayoffGames', _response => dispatch(setFootballAdminPlayoffGames(_response)))
    
        // Eliminator Events
        .on('pushMainPageData', _response => isDevelopment ? console.log(_response) : null)
        .on('eliminatorSeasonDetails', _response => dispatch(setEliminatorSeasonDetails(_response)))
        .on('nflEliminatorScoreboard', _response => dispatch(setNflEliminatorScoreboard(_response)))
        .on('eliminatorStandings', _response => dispatch(setEliminatorStandings(_response)))
        .on('eliminatorFrontPagePickReport', _response => dispatch(setFrontPagePickReport(_response)))
        .on('eliminatorPaymentUpdate', _response => dispatch(setEliminatorPayments(_response)))
        .on('eliminatorPlayerPaymentUpdate', _response => {
            dispatch(getMainPageData())
            dispatch(getEliminatorStandings(seasonName))
        })
        
        // Oscar Events
        .on('pushOscarsScoreboardUpdate', _response => dispatch(setOscarsScoreboard(_response)))
    
        // Payment Events
        .on('closePaymentModal', _response => dispatch(closePaymentModal(_response)))
    
        // Development log tester
        .on('logEvent', _response => {
            if (isDevelopment) {
                console.log(`Logging received event...`, _response)
            }
        })
      
        return () => {
          socket.removeAllListeners()
        }
      }, [socket])
      
    const onCommandQueueChange = () => {
        if (isAuthenticated && connected && commandQueue.length) {
            try {
                const _command = first(commandQueue)
                const { _id, command, commandData } = _command
                socket.emit(command, commandData)
                dispatch(clearSocketCommand(_id))
            } catch (_ex) {
                if (isDevelopment) console.log('Error sending socket command: ', _ex)
            }
        }
    }

    const onAppChange = () => {
        if (isAuthenticated && connected && app && app !== 'Login') {
            if (isDevelopment) console.log(`App changed to ${app}`)

            dispatch(sendSocketCommand('set-user-current-app', { app, playerId }))
        }
    }

    const disconnect = () => {
        if (isDevelopment) console.log('disconnecting socket...')
        socket.emit('set-user-current-app', { playerId, app: null })
        socket.emit('manual-disconnect')
    }

    return null
}


export default SocketClient