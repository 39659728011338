import React, { useEffect } from 'react'
import { ListGroup, Image, Badge, FormCheck, 
    Tooltip, OverlayTrigger, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './CinderellaPayments.scss'
import moment from 'moment-timezone'
import numeral from 'numeral'
import { FRIENDLY_SHORT_DATE_FORMAT, FRIENDLY_SHORT_TIME_FORMAT, CURRENCY_FORMAT, playerUrl } from '@severed-links/common.redherrings-constants'
import Confirm from '@severed-links/common.confirm'

const CinderellaPaymentLogItem = ({ item, onClick, isDueItem, onSelect, checked, onDeleteLogEntry = null, deletingId = null }) => {

    const _isBeingDeleted = deletingId && item._id === deletingId

    return (
        <ListGroup.Item className={s.item + (_isBeingDeleted ? ` ${s.isDeleted}` : '')} 
            action={!!onClick && !_isBeingDeleted} 
            onClick={() => onClick ? onClick(item.playerId) : void(0)}>
            {_isBeingDeleted ? <div className={s.deleteOverlay}><FontAwesomeIcon name='trash' className={s.icon} /></div> : null}
            <div className={s.content}>
            {isDueItem ? 
            <div className={s.checkboxContainer} onClick={e => e.stopPropagation()}>
                <FormCheck type='checkbox' className={s.checkbox + (item.balanceDue <= 0.00 ? ' ' + s.disabled : '')} 
                    checked={checked} disabled={item.balanceDue <= 0.00}
                    onChange={e => onSelect(e, item.playerId)} />
            </div>
            : null}
            <div className={s.avatar}>
                {item.avatarUrl ? <Image src={playerUrl(item.avatarUrl)} /> : null}
            </div>
            <div className={s.player}>
                <div className={s.name}>{item.firstName} {item.lastName}</div>
                <div className={s.email + ' ' + s.hideSmall}>{item.payer_email || item.emailAddress}</div>
            </div>
            {!isDueItem ? 
            <>
            <div className={s.status}>
                {item.isRefunded ? <Badge bg='warning' text='dark'>Refunded</Badge> : 
                 item.isCompleted ? <Badge bg='success' text='light'>Completed</Badge>
                : null}
                <div className={s.transactionId + ' ' + s.hideSmall}>{item.txn_id}</div>
            </div>
            <div className={s.datetime}>
                <div className={s.date}>{moment(item.entryDate).local().format(FRIENDLY_SHORT_DATE_FORMAT)}</div>
                <div className={s.time}>{moment(item.entryDate).local().format(FRIENDLY_SHORT_TIME_FORMAT)}</div>
            </div>
            <div className={s.amount}>
                <OverlayTrigger placement={'top'} overlay={<Tooltip id={`payment-log-tooltip-${item._id}`}>Net payment: {numeral(item.net_amount).format('($0,0.00)')}</Tooltip>}>
                    <span>{numeral(item.totalPayment).format('($0,0.00)')}</span>
                </OverlayTrigger>
            </div>
            </>
            :
            <>
            <div className={s.stat + ' ' + s.hideSmall}>
                <div className={s.header}>Entries</div>
                <div className={s.value}>{item.entryCount}</div>
            </div>
            <div className={s.stat + ' ' + s.hideSmall}>
                <div className={s.header}>Total Due</div>
                <div className={s.value}>{numeral(item.totalDue).format('($0,0.00)')}</div>
            </div>
            <div className={s.stat + ' ' + s.hideSmall}>
                <div className={s.header}>Prior Payments</div>
                <div className={s.value}>{numeral(item.paymentTotal).format('($0,0.00)')}</div>
            </div>
            <div className={s.amount + ' ' + s.balanceDue + ' ' + (item.balanceDue <= 0.00 ? s.success : s.danger)}>{numeral(item.balanceDue).format('($0,0.00)')}</div>
            </>
            }
            {onDeleteLogEntry && item._id ?
                <Confirm variant='danger' onConfirm={() => onDeleteLogEntry(item._id)}
                    title={'Delete Player Entry'}
                    confirmText='confirm delete'
                    body={'Are you sure you want to delete this log entry?'}
                    disabled={_isBeingDeleted} size='sm' buttonClassName={s.deleteLogEntryButton}
                    buttonIcon='times' />
            : null}
            </div>
            {item.isRefunded && item.refundNoteToPayer ? <div className={s.refundNote}>{item.refundNoteToPayer} (Transaction ID {item.refundTransactionId})</div> : null}
        </ListGroup.Item>
    )
}

export default CinderellaPaymentLogItem
