// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EliminatorEditConfiguration__container___obME9{margin-bottom:.5rem}.EliminatorEditConfiguration__container___obME9 .EliminatorEditConfiguration__formGroup___vviFm{margin-bottom:1rem}`, "",{"version":3,"sources":["webpack://./src/routes/Eliminator/Admin/EliminatorEditConfiguration.scss"],"names":[],"mappings":"AAAA,gDACI,mBAAA,CAEA,gGACI,kBAAA","sourcesContent":[".container {\n    margin-bottom: 0.5rem;\n\n    .formGroup {\n        margin-bottom: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `EliminatorEditConfiguration__container___obME9`;
export var formGroup = `EliminatorEditConfiguration__formGroup___vviFm`;
export default ___CSS_LOADER_EXPORT___;
