import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './EliminatorWinnerInProgress.scss'

const EliminatorWinnerInProgress = ({ isActiveSeason, hasWinner, hasTie, ...props }) => {
    return (
        isActiveSeason && !hasWinner && !hasTie ?
        <Card.Body className={s.container}>
            <div className={s.icon}>
                <FontAwesomeIcon name={'hourglass-half'} className={s.icon} />
            </div>
            <div className={s.text}>In Progress...</div>
        </Card.Body>
        : null    
    )
}

export default EliminatorWinnerInProgress