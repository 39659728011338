import React, { useEffect } from 'react'
import { ListGroup } from 'react-bootstrap'
import * as s from './EliminatorEnterPickItem.scss'
import NflTeamListItem from '../Football/NflTeamListItem'

const EliminatorEnterPickItem = ({ pick = {}, index = 0, isCurrentPick = false }) => {

    return (
        <NflTeamListItem className={s.container + (isCurrentPick ? ` ${s.currentWeek}` : '')} 
            teamId={pick.teamId || `blank-team-_id-${pick.week}`} city={pick.city} mascot={pick.mascot}
            teamName={pick.nflTeam}
            left={<div className={s.week}>{pick.week}</div>} />
    )
}

export default EliminatorEnterPickItem