import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getConfig } from '@severed-links/common.redherrings-reducers/eliminatorAdmin'
import { find, some } from 'lodash'
import numeral from 'numeral'
import * as s from './EliminatorConfigSummary.scss'

const EliminatorConfigSummary = () => {

    const dispatch = useDispatch()
    const seasonId = useSelector(state => state.eliminator.seasonId)
    const seasonName = useSelector(state => state.eliminator.seasonName)
    const seasons = useSelector(state => state.eliminator.seasons)
    const config = useSelector(state => state.eliminatorAdmin.config)

    useEffect(() => { seasonId ? dispatch(getConfig(seasonId)) : null }, [seasonId])

    if (!config) return null
    const { seasonConfig, globalConfig } = config        
    const activeSeasonName = globalConfig && some(seasons, { seasonId: config.globalConfig.activeSeason }) ? find(seasons, { seasonId: globalConfig.activeSeason }).seasonName : ''
    const { week, seasonMode } = seasonConfig || {}
    const activeSeasonStatus = seasonConfig ? `${activeSeasonName} ${seasonMode}${seasonMode === 'Regular' ? ' Season' : ''}${seasonMode !== 'Preseason' ? ` Week ${week}` : ''}` : ''
    const configs = [
        { _id: 10, title: `Active season`, value: activeSeasonName },
        { _id: 11, title: `${activeSeasonName} Season status`, value: activeSeasonStatus },
        { _id: 20, title: `${activeSeasonName} Elimination mode`, value: (seasonConfig || {}).eliminationMode === 1 ? 'Single' : 'Double' },
        { _id: 21, title: `${activeSeasonName} Service running?`, value: (seasonConfig || {}).eliminatorServiceRunning },
        { _id: 30, title: `${activeSeasonName} Registration Code`, value: (seasonConfig || {}).registrationCode },
        { _id: 35, title: `${activeSeasonName} Entry Fee`, value: numeral((seasonConfig || {}).entryFee).format('($0,0.00)') },
        { _id: 40, title: `${activeSeasonName} PayPal Email`, value: (seasonConfig || {}).payPalEmailAccount },
        (seasonConfig || {}).frontPageMessage ? { _id: 45, title: `${activeSeasonName} Front Page Message`, value: (seasonConfig || {}).frontPageMessage || ''} : null,
    ].filter(i => i)
    return (
        <div className={s.container}>
            <h4>Configuration</h4>
            <ListGroup className={s.list}>
            {configs && configs.map(i =>
                <ListGroup.Item key={`config-${i._id}`} className={s.item}>
                    <div className={s.heading}>{i.title}</div>
                    <div className={s.value}>{i.value}</div>
                </ListGroup.Item>
            )}
            </ListGroup>
        </div>
    )
}

export default EliminatorConfigSummary
