import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button, Image, Badge } from 'react-bootstrap'
import { getPastChamps } from '@severed-links/common.redherrings-reducers/cinderella'
import * as s from './PastChamps.scss'
import NCAATeam from '../NCAATeam/NCAATeam'
import { img } from '@severed-links/common.redherrings-constants'
import { filter, startsWith, endsWith } from 'lodash'

const PastChamps = () => {

    const dispatch = useDispatch()
    const year = useSelector(state => state.cinderella.year)
    const pastChamps = useSelector(state => state.cinderella.pastChamps)

    useEffect(() => { dispatch(getPastChamps()) }, [])

    const getImageUrl = avatarUrl => img.BaseUrl + (!endsWith(img.BaseUrl, '/') && !startsWith(avatarUrl || img.NoImageAvailable, '/') ? '/' : '') + (avatarUrl || img.NoImageAvailable)

    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <h2>Past Champions</h2>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Row>
                    {pastChamps.map((c, index) => 
                        <Col lg={3} md={4} sm={6} xs={12} key={`cinderella-past-champs-${c.playerId}`}>
                            <div className={s.champContainer}>
                                <div className={s.year}>
                                    {filter(pastChamps, { year: c.year }).length > 1 ?
                                        <Badge bg='info' className={s.tieLabel}>Tie</Badge>
                                    : null}
                                    <span>{c.year}</span>
                                </div>
                                <div className={s.avatar}>
                                    <Image src={getImageUrl(c.avatarUrl)} 
                                        roundedCircle />
                                </div>
                                <div className={s.player}>{c.fullName}</div>
                                <div className={s.score}>{c.score} {c.score !== 1 ? 'points' : 'point'}</div>
                                {c.entryTeams && c.entryTeams.length > 0 ?
                                <div>
                                {c.entryTeams.map(t =>
                                    <NCAATeam key={`cinderella-past-champ-${c.playerId}-team-${t.teamId}`}
                                        teamId={t.teamId} 
                                        name={t.teamName} seed={t.seed} 
                                        points={t.points} showPoints
                                        avatarUrl={t.avatarUrl} showAvatar
                                        isAlive={t.isAlive} />
                                )}
                                </div>
                                :
                                <div className={s.emptyTeamsContainer}>&nbsp;</div>
                                }
                            </div>
                        </Col>
                    )}
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default PastChamps