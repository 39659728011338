import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { Card, Button, ButtonToolbar, ButtonGroup } from 'react-bootstrap'
import moment from 'moment-timezone'
import { filter, some } from 'lodash'
import * as s from './CinderellaEntries.scss'
import Confirm from '@severed-links/common.confirm'
import { getEntries, deleteEntry } from '@severed-links/common.redherrings-reducers/cinderella'
import NCAATeam from '../NCAATeam/NCAATeam'
import { VisibleAfterDate, VisibleBeforeDate } from '../../../components/VisibilityBeforeAfterDate'
import { ordinal } from '@severed-links/common.redherrings-constants'

const CinderellaPlayerEntry = ({ entry = {}, teams = [] }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const pickDeadline = useSelector(state => state.cinderella.pickDeadline)
    const [deletingId, setDeletingId] = useState(null)

    const doDeleteEntry = _entryId => {
        setDeletingId(_entryId)
        dispatch(deleteEntry(_entryId))
        .then(() => { 
            dispatch(getEntries())
            setDeletingId(null)
        })
    }

    if (!pickDeadline) return null
    const showPoints = moment().isAfter(pickDeadline)
    const isDeleting = deletingId === entry._id
    return (
        <Card className={s.cinderellaEntryContainer + (isDeleting ? ' ' + s.isDeleting : '')}>
            <Card.Header><h4 className={s.entryName}>{entry.entryName}</h4></Card.Header>
            <Card.Body className={s.entryBody}>
            {entry.entryTeams.map(team => 
            <div className={s.entryTeam} key={`entry-${entry._id}-team-${team.teamId}`}>
            <NCAATeam teamId={team.teamId} name={team.teamName} seed={team.seed} 
                points={team.points} isAlive={team.isAlive} size={'md'}
                avatarUrl={team.avatarUrl} showAvatar 
                showPoints={showPoints} />
            </div>
            )}
            </Card.Body>
            <Card.Footer>                
            {pickDeadline ?
            <VisibleBeforeDate deadline={pickDeadline}>
                <ButtonToolbar className='justify-content-between'>
                    <ButtonGroup>
                        <Button disabled={isDeleting} variant='outline-primary'  
                            onClick={() => navigate('/cinderella/entry/edit/' + entry._id)}>
                            <FontAwesomeIcon name='pencil-alt' />
                            {' '}
                            <span>edit entry</span>
                        </Button>
                    </ButtonGroup>
                    <ButtonGroup>
                        <Confirm variant='outline-danger' onConfirm={() => doDeleteEntry(entry._id)}
                            title={'Delete Entry'}  style={{ float: 'right' }}
                            confirmText='confirm delete entry'
                            body={'Are you sure you want to delete this entry?'}
                            buttonIcon='trash' disabled={isDeleting} />
                    </ButtonGroup>                                
                </ButtonToolbar>
            </VisibleBeforeDate>
            : null}
            <VisibleAfterDate deadline={pickDeadline}>
                <h5 className={s.entryScore}>
                    {entry.rank ? <div>{ordinal(entry.rank)} place</div> : null}
                    <div>{entry.score} {entry.score != 1 ? 'points' : 'point'}</div>
                </h5>
            </VisibleAfterDate>
            </Card.Footer>
        </Card>
    )
}

export default CinderellaPlayerEntry
