import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { Row, Col, Button } from 'react-bootstrap'
import { getEntries, getFrontPageData } from '@severed-links/common.redherrings-reducers/cinderella'
import moment from 'moment-timezone'
import { filter, some } from 'lodash'
import * as s from './CinderellaEntries.scss'

import CinderellaPlayerEntry from './CinderellaPlayerEntry'
import CinderellaEntryPaymentSummary from './CinderellaEntryPaymentSummary'
import { VisibleBeforeDate, VisibleAfterDate } from '../../../components/VisibilityBeforeAfterDate'

const CinderellaEntries = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const year = useSelector(state => state.cinderella.year)
    const pickDeadline = useSelector(state => state.cinderella.pickDeadline)
    const _entries = useSelector(state => state.cinderella.entries) || { teams: [], entries: [] }
    const isLoadingEntries = useSelector(state => state.cinderella.isLoadingEntries)
    const hasTeamsInBracket = useSelector(state => state.cinderella.frontPageData.hasTeamsInBracket)

    useEffect(() => {
        dispatch(getEntries())        
        if (!pickDeadline) dispatch(getFrontPageData())
    }, [])

    const { teams, entries } = _entries
    return (
        <div className={s.container}>
            <div className={s.titleContainer}>
                <h2 className={s.title}>
                    <div className={s.titleText}>Your {year} Entries</div>
                    <div className={s.spinner + (isLoadingEntries ? ' ' + s.isLoadingEntries : '')}>
                        <FontAwesomeIcon name='circle-notch' spin={isLoadingEntries} />
                    </div>
                </h2>
                {pickDeadline && hasTeamsInBracket ? 
                    <VisibleBeforeDate deadline={pickDeadline}>
                        <Button className={s.addEntryButton} 
                            variant='primary' size='lg'
                            onClick={() => navigate('/cinderella/entry/new')}>
                            <FontAwesomeIcon name='plus' />
                            {' '}
                            <span>add a new entry</span>
                        </Button>
                    </VisibleBeforeDate>
                : null}
            </div>
            <Row>
                {entries && entries.map(e => 
                <Col className={s.entryContainer} key={`cinderella-entry-${e._id}`} xs={12} sm={6} md={6} lg={4}>
                    <CinderellaPlayerEntry entry={e} teams={teams} />
                </Col>
                )}
                <Col xs={12} sm={6} md={6} lg={4}>
                    <CinderellaEntryPaymentSummary pickDeadline={pickDeadline} />
                </Col>
            </Row>
        </div>
    )
}

export default CinderellaEntries