import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ButtonGroup, Button, DropdownButton, Dropdown } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './ScoreboardButtonToolbar.scss'

const ScoreboardButtonToolbar = ({ year, availableScoreboards = [], showPopularPicks = false, showBestPossiblePick = false, onClickPopularPicks = () => void(0), onClickBestPossiblePick = () => void(0), onClickYearItem = () => void(0) }) => {

    return (
        <ButtonGroup className={s.buttonGroup}>
            <Button className={s.button} variant={showPopularPicks ? 'primary' : 'light'} 
                onClick={() => onClickPopularPicks()}>
                <FontAwesomeIcon name='users' /> 
                {' '}
                <span className={'d-inline d-md-none'}>popular</span>
                <span className={'d-none d-md-inline'}>most popular</span>
            </Button>
            <Button className={s.button} variant={showBestPossiblePick ? 'primary' : 'light'} 
                onClick={() => onClickBestPossiblePick()}>
                <FontAwesomeIcon name='trophy' />
                {' '}
                <span className={'d-inline d-md-none'}>best</span>
                <span className={'d-none d-md-inline'}>best possible</span>
            </Button>
            {availableScoreboards && availableScoreboards.length > 0 ? 
            <DropdownButton className={s.button} variant={'light'} 
                title={year} key={year} align='end'
                id={`cinderella-scoreboard-year-dropdown${year}`} 
                onClick={e => e.stopPropagation()}>
                {availableScoreboards.map(sb => 
                    <Dropdown.Item eventKey={`sb-year-${sb}`} key={sb}
                        onClick={() => onClickYearItem(sb)}>
                        <span>{sb}</span>
                        {sb === year ? <FontAwesomeIcon className={s.icon} name='check' /> : null}
                    </Dropdown.Item>
                )}
            </DropdownButton>
            : null}
        </ButtonGroup>
    )
}

export default ScoreboardButtonToolbar
