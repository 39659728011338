import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import NFLScoreboardGame from './NFLScoreboardGame'
import * as s from './NFLScoreboard.scss'
import moment from 'moment-timezone'

const NFLScoreboardGameDay = ({ gameDay = {}, seasonId, seasonName, seasonMode, week, showLines = true }) => {

    const time_zone = useSelector(state => state.account.time_zone)

    const d = gameDay
    if (!d || !d.gameCount) return null
    return (
        <div className={s.dayContainer}>
            <h4>{moment(d.gameDate).format('dddd, MMMM Do')}</h4>
            <Row>
            {d.games && d.games.map((g, gameIndex) =>
                <Col lg={4} md={6} xs={12} key={`nfl-scoreboard-${seasonId}-${seasonMode}-${week}-${d.gameDate}-${gameIndex}`}>
                    <NFLScoreboardGame game={g} seasonId={seasonId} seasonName={seasonName} 
                        seasonMode={seasonMode} week={week} showLine={showLines} />
                </Col>
            )}
            </Row>
        </div>
    )
}

export default NFLScoreboardGameDay