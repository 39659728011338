// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CinderellaEditGameScores__container___ig5N2{margin-bottom:1rem}.CinderellaEditGameScores__container___ig5N2 .CinderellaEditGameScores__heading___W94mE{display:flex;flex-flow:row wrap;justify-content:space-between;align-items:center}.CinderellaEditGameScores__container___ig5N2 .CinderellaEditGameScores__heading___W94mE .CinderellaEditGameScores__title___aY2ZA{flex:1 1 auto}.CinderellaEditGameScores__container___ig5N2 .CinderellaEditGameScores__heading___W94mE .CinderellaEditGameScores__controls___VxLUs{margin-left:1rem;white-space:nowrap}.CinderellaEditGameScores__container___ig5N2 .CinderellaEditGameScores__roundNav___st5tb{margin-bottom:1rem}.CinderellaEditGameScores__container___ig5N2 .CinderellaEditGameScores__regionName___yRvXn{margin:1rem 0px}`, "",{"version":3,"sources":["webpack://./src/routes/Cinderella/Admin/CinderellaEditGameScores.scss"],"names":[],"mappings":"AAAA,6CACI,kBAAA,CAEA,wFACI,YAAA,CACA,kBAAA,CACA,6BAAA,CACA,kBAAA,CAEA,iIACI,aAAA,CAGJ,oIACI,gBAAA,CACA,kBAAA,CAIR,yFACI,kBAAA,CAGJ,2FACI,eAAA","sourcesContent":[".container {\n    margin-bottom: 1rem;\n\n    .heading {\n        display: flex;\n        flex-flow: row wrap;\n        justify-content: space-between;\n        align-items: center;\n\n        .title {\n            flex: 1 1 auto;\n        }\n\n        .controls {\n            margin-left: 1rem;\n            white-space: nowrap;\n        }\n    }\n\n    .roundNav {\n        margin-bottom: 1rem;\n    }\n\n    .regionName {\n        margin: 1rem 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `CinderellaEditGameScores__container___ig5N2`;
export var heading = `CinderellaEditGameScores__heading___W94mE`;
export var title = `CinderellaEditGameScores__title___aY2ZA`;
export var controls = `CinderellaEditGameScores__controls___VxLUs`;
export var roundNav = `CinderellaEditGameScores__roundNav___st5tb`;
export var regionName = `CinderellaEditGameScores__regionName___yRvXn`;
export default ___CSS_LOADER_EXPORT___;
