// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.UnpaidAlert__container___X1BfJ{margin-bottom:1.5rem;display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center}.UnpaidAlert__container___X1BfJ .UnpaidAlert__message___C7Yr7{flex:1}.UnpaidAlert__container___X1BfJ .UnpaidAlert__button___SJO2D{flex:0 0 0;margin-left:1.5rem}`, "",{"version":3,"sources":["webpack://./src/routes/Football/UnpaidAlert.scss"],"names":[],"mappings":"AAAA,gCACI,oBAAA,CACA,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CAEA,8DACI,MAAA,CAGJ,6DACI,UAAA,CACA,kBAAA","sourcesContent":[".container {\n    margin-bottom: 1.5rem;\n    display: flex;\n    flex-flow: row nowrap;\n    justify-content: space-between;\n    align-items: center;\n\n    .message {\n        flex: 1;\n    }\n\n    .button {\n        flex: 0 0 0;\n        margin-left: 1.5rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `UnpaidAlert__container___X1BfJ`;
export var message = `UnpaidAlert__message___C7Yr7`;
export var button = `UnpaidAlert__button___SJO2D`;
export default ___CSS_LOADER_EXPORT___;
