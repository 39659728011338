import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup } from 'react-bootstrap'
import * as s from './OscarsConfigSummary.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { find, some } from 'lodash'
import numeral from 'numeral'
import moment from 'moment-timezone'

const OscarsConfigSummary = () => {

    const year = useSelector(state => state.oscars.year)
    const pickDeadline = useSelector(state => state.oscars.pickDeadline)
    const entryDeadlineDate = useSelector(state => state.oscars.entryDeadlineDate)
    const passphrase = useSelector(state => state.oscars.passphrase)

    const _fields = [
        { label: `Year`, value: `${year}` },
        { label: `Pick Deadline`, value: `${moment(entryDeadlineDate).local().format('M/D/YYYY h:mm A')}` },
        { label: `Registration Code`, value: `${passphrase}` },
    ]
    return (
        <ListGroup className={s.list}>
        {_fields && _fields.map(i =>
        <ListGroup.Item className={s.item} key={`eliminator-config-summary-${i.label}`}>
            <div className={s.content}>
                <div className={s.label}>{i.label}</div>
                <div className={s.value}>{i.value}</div>
            </div>
        </ListGroup.Item>
        )}
        </ListGroup>
    )
}

export default OscarsConfigSummary
