import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ListGroup, Image, Alert } from 'react-bootstrap'
import * as s from './EliminatorStandings.scss'
import { getStandings } from '@severed-links/common.redherrings-reducers/eliminator'
import { playerUrl } from '@severed-links/common.redherrings-constants'
import pluralize from 'pluralize'
import SeasonSelector from '../Football/SeasonSelector'
import EliminatorStandingsPickItem from './EliminatorStandingsPickItem'

const EliminatorStandings = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const isFetchingStandings = useSelector(state => state.eliminator.isFetchingStandings)
    const seasons = useSelector(state => state.eliminator.seasons)
    const seasonName = params.seasonName
    const _season = seasons.find(i => i.seasonName === seasonName)
    const _seasonId = (_season || {}).seasonId
    const _allStandings = useSelector(state => state.eliminator.standings) || {}
    const standings = _allStandings[`${_seasonId}`] || {}
    const scoreboardPlayers = (standings || {}).scoreboardPlayers || []
    const totalPlayers = scoreboardPlayers.length
    const remainingPlayers = scoreboardPlayers.filter(i => !i.isEliminated).length
    
    useEffect(() => {
        if (seasonName) {
            dispatch(getStandings(seasonName))
        }
    }, [dispatch, seasonName])

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <div className={s.titleContainer}>
                    <h2 className={s.title}>{seasonName} Standings</h2>
                    <div>
                        <SeasonSelector seasons={seasons} season={_season} />
                    </div>
                </div>
                <Alert variant='info' className={s.detail}>{remainingPlayers} of {pluralize('player', totalPlayers, true)} remaining</Alert>
            </div>
            <ListGroup className={s.list}>
            {scoreboardPlayers && scoreboardPlayers.map(i =>
            <ListGroup.Item className={s.item} 
                key={`eliminator-standings-${seasonName}-${i.playerId}`}>
                <div className={s.content}>
                    <div className={s.avatar}>
                        <Image src={playerUrl(i.avatarUrl)} />
                    </div>
                    <div className={s.player}>
                        <div className={s.firstName}>{i.firstName}</div>
                        <div className={s.lastName}>{i.lastName}</div>
                    </div>
                    <div className={s.picks}>
                    {i.picks && i.picks.filter(i => i.showPick).map(p => 
                        <EliminatorStandingsPickItem pick={p} 
                            key={`eliminator-pick-item-${i.playerId}-${p.weekDisplay}`} />
                    )}
                    </div>
                </div>
            </ListGroup.Item>
            )}

            {!isFetchingStandings && (!scoreboardPlayers || scoreboardPlayers.length === 0) ? 
            <ListGroup.Item>No players registered for this season.</ListGroup.Item>
            : null}
            </ListGroup>

        </div>
    )
}

export default EliminatorStandings