import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router-dom'
import { Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import RedHerringsPayPalButton from '../Payment/RedHerringsPayPalButton'
import * as s from './UnpaidAlert.scss'
import { loadPreseason, getMyTeams, markFirstMyTeamAsPaid } from '@severed-links/common.redherrings-reducers/football'

const UnpaidAlert = ({ myTeam = {} }) => {

    const dispatch = useDispatch()
    const userName = useSelector(state => state.account.userName)
    const seasonId = useSelector(state => state.football.seasonId)
    const seasonName = useSelector(state => state.football.seasonName)
    const playerId = useSelector(state => state.account._id)
    const entryFee = (useSelector(state => state.football.preseasons)[`${seasonId}`] || {}).entryFee || 89.99
    const paymentEmail = useSelector(state => state.football.paymentEmail)
    const myTeams = useSelector(state => state.football.myTeams)

    useEffect(() => {
        if (myTeam && !myTeam.isPaid) {
            dispatch(loadPreseason(seasonId))
        }
    }, [seasonName, myTeam])

    const onCancelledFootballPayment = () => {
    }

    const onAuthorizedFootballPayment = _event => dispatch(markFirstMyTeamAsPaid())

    if (!myTeam || myTeam.isPaid) return null
    return (
        <Alert className={s.container} variant='danger'>
            <div className={s.message}>
                {myTeam.teamName} is unpaid! Pay ${entryFee} here:
            </div>
            <div className={s.button}>
                <RedHerringsPayPalButton
                    contest={'Football'}
                    itemNumber={playerId}
                    receiverEmail={paymentEmail}
                    description={`RedHerrings Football ${seasonName}`}
                    amount={entryFee}
                    seasonId={seasonId}
                    playerId={playerId}
                    teamId={myTeam._id}
                    onAuthorizedPayment={onAuthorizedFootballPayment}
                    onCancelledPayment={onCancelledFootballPayment} />
            </div>
        </Alert>
    )
}

export default UnpaidAlert