import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { getNflScoreboard } from '@severed-links/common.redherrings-reducers/football'
import { find, startsWith, forEach, isEqual } from 'lodash'
import NFLScoreboardGame from './NFLScoreboardGame'
import NFLScoreboardGameDay from './NFLScoreboardGameDay'
import SeasonSelector from '../../SeasonSelector'
import WeekSelector from '../../WeekSelector'
import * as s from './NFLScoreboard.scss'
import { getFootballUrlParams } from '@severed-links/common.redherrings-constants'

const NFLScoreboard = ({ }) => {

    const dispatch = useDispatch()
    const params = useParams()
    const seasons = useSelector(state => state.football.seasons)
    const nflScoreboards = useSelector(state => state.football.nflScoreboards)
    const c = getFootballUrlParams(params, seasons)
    const sb = find(nflScoreboards, x => c.season && x.seasonId === c.season.seasonId && x.weekNumber === c.week)

    useEffect(() => { updateScoreboard() }, [c.seasonId, c.week])

    const updateScoreboard = () => {
        if (c.season && c.season.seasonId && c.week) {
            dispatch(getNflScoreboard(c.season.seasonId, `${c.isPlayoffWeek ? 'PW' : 'W'}${c.week}`))
        }
    }

    if (!sb) return null
    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}>{sb.seasonName} NFL Scoreboard {sb.weekDisplay} </h2>
                <div className={s.controls}>
                    <div className={s.seasonSelector}><SeasonSelector seasons={seasons} season={c.season} /></div>                            
                    <div className={s.weekSelector}><WeekSelector seasons={seasons} season={c.season} week={'W' + c.week.toString()} /></div>
                </div>
            </div>
            <div className={s.gamesContainer}>
            {sb.gameDays && sb.gameDays.map((d, index) => 
                <NFLScoreboardGameDay gameDay={d} seasonId={sb.seasonId} seasonName={sb.seasonName} seasonMode={sb.seasonMode} week={sb.weekNumber} showLines
                    key={`nfl-scoreboard-${sb.seasonId}-week-${sb.weekNumber}-day-${d.gameDate}`} />
            )}
            </div>
        </div>
    )
}

export default NFLScoreboard