// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Login__container___n_5Jr{margin-bottom:1.5rem}.Login__container___n_5Jr .Login__header___dMV_p{text-align:left;margin-bottom:2rem}.Login__container___n_5Jr .Login__form___p5iO9,.Login__container___n_5Jr .Login__errorMsgContainer___Ltqxf{width:100%;padding:1rem;margin:0px auto}.Login__container___n_5Jr .Login__form___p5iO9{position:relative}.Login__container___n_5Jr .Login__form___p5iO9 .Login__formGroup___q0ogN{margin-bottom:1rem}.Login__container___n_5Jr .Login__form___p5iO9 .Login__submitFormGroup___p3nR_{text-align:right}`, "",{"version":3,"sources":["webpack://./src/routes/Account/Login.scss"],"names":[],"mappings":"AAAA,0BACI,oBAAA,CACA,iDACI,eAAA,CACA,kBAAA,CAGJ,2GACI,UAAA,CACA,YAAA,CACA,eAAA,CAGJ,+CACI,iBAAA,CAEA,yEACI,kBAAA,CAEJ,+EACI,gBAAA","sourcesContent":[".container {\n    margin-bottom: 1.5rem;\n    .header {\n        text-align: left;\n        margin-bottom: 2rem;\n    }\n\n    .form, .errorMsgContainer {\n        width: 100%;\n        padding: 1rem;\n        margin: 0px auto;\n    }\n\n    .form {\n        position: relative;\n\n        .formGroup {\n            margin-bottom: 1rem;\n        }\n        .submitFormGroup {\n            text-align: right;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `Login__container___n_5Jr`;
export var header = `Login__header___dMV_p`;
export var form = `Login__form___p5iO9`;
export var errorMsgContainer = `Login__errorMsgContainer___Ltqxf`;
export var formGroup = `Login__formGroup___q0ogN`;
export var submitFormGroup = `Login__submitFormGroup___p3nR_`;
export default ___CSS_LOADER_EXPORT___;
