import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import * as s from './TeamProfileHeader.scss'
import { Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import CloudinaryImage from '../../Cloudinary/CloudinaryImage'
import PlayerBackground from './PlayerBackground'

const TeamProfileHeader = ({ playerId = null, profile = null, isMe = false }) => {

    const navigate = useNavigate()

    if (!profile) return null
    const { teamName, division, teamAvatarUrl, playerAvatarUrl } = profile || {}
    return (
        <div className={s.teamProfileHeader}>
            <div className={s.titleBar}>
                <div className={s.avatar}>
                    <CloudinaryImage publicId={teamAvatarUrl} 
                        className={s.avatarImage}
                        transformationName='large_avatar_200' />
                </div>
                <div className={s.infoContainer}>
                    <h2 className={s.teamName}>{teamName}</h2>
                    <h4 className={s.division}>{division} Division</h4>
                </div>
                {isMe ? <Button className={s.editButton} variant='outline-primary' onClick={() => navigate('/football/edit-team-profile/' + profile._id)}><FontAwesomeIcon name='pencil-alt' /> <span className={s.buttonText}>Edit my profile</span></Button> : null}
            </div>
            <PlayerBackground profile={profile} />
        </div>
    )
}

export default TeamProfileHeader