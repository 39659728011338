import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button, Badge, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './CaptureContests.scss'
import Modal from '@severed-links/common.modal'
import moment from 'moment-timezone'
import CaptureContestEditor from './CaptureContestEditor'
import { getContests, updateContest, deleteContest, restoreContest } from '@severed-links/common.redherrings-reducers/captureAdmin'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import FormCheck from 'react-bootstrap/FormCheck'

const CaptureContests = () => {

    const dispatch = useDispatch()
    const contests = useSelector(state => state.captureAdmin.contests)
    const [showEdit, setShowEdit] = useState(false)
    const [editContest, setEditContest] = useState(null)
    const [editTitle, setEditTitle] = useState('')
    const [showDeletedContests, setShowDeletedContests] = useState(false)

    useEffect(() => { dispatch(getContests()) }, [])

    const add = () => {
        setShowEdit(true)
        setEditContest({ _id: null, contestName: '', year: moment().year(), entryFee: 2.00, isDeleted: false })
        setEditTitle('Add new contest')
    }

    const edit = c => {
        setShowEdit(true)
        setEditContest({ _id: c._id, contestName: c.contestName, year: c.year, entryFee: c.entryFee, isDeleted: c.isDeleted, isActive: c.isActive })
        setEditTitle('Edit contest')
    }

    const save = () => {
        dispatch(updateContest(editContest))
        .then(response => {
            dispatch(createNotification({ message: `Saved: ${editContest.contestName}.`, type: 'success', headline: 'Update Contest', timeout: 6000 }))
            closeEditor()
            dispatch(getContests())
        })
    }

    const onDelete = () => {
        dispatch(deleteContest(editContest))
        .then(response => {
            dispatch(createNotification({ message: response.payload.message, type: response.payload.messageType, headline: response.payload.subject, timeout: 6000 }))
            closeEditor()
            dispatch(getContests())
        })
    }

    const restore = () => {
        dispatch(restoreContest(editContest))
        .then(response => {
            dispatch(createNotification({ message: response.payload.message, type: response.payload.messageType, headline: response.payload.subject, timeout: 6000 }))
            closeEditor()
            dispatch(getContests())
        })
    }

    const updateEditedContest = c => setEditContest({ ...c, entryFee: parseFloat(c.entryFee) }) 

    const closeEditor = () => {
        setShowEdit(false)
        setEditContest(null)
    }

    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <div className={s.heading}>
                        <h2 className={s.title}>Capture Contests</h2>
                        <div className={s.showDeletedContestsContainer}>
                            <FormCheck type='switch' checked={showDeletedContests} 
                                label={<FontAwesomeIcon className={s.trashIcon} name='trash' />}
                                onChange={e => setShowDeletedContests(e.target.checked)} />
                        </div>
                        <div className={s.addNewContestContainer}>
                            <Button onClick={() => add()}><FontAwesomeIcon name='plus' /> add new contest</Button>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                <ListGroup className={s.contestList}>
                {contests && contests.filter(c => !showDeletedContests ? !c.isDeleted : true).map(c =>
                    <ListGroup.Item key={c._id} className={s.contestItem} onClick={() => edit(c)}>
                        <div className={s.content}>
                            <div className={s.details}>
                                <div>{c.contestName}</div>
                                <div className={s.contestDetails}>Year: {c.year} Entry fee: ${c.entryFee.toFixed(2)}</div>
                            </div>
                            <div className={s.icons}>
                            {c.isDeleted ? <Badge bg='danger'>Deleted</Badge> : null}
                            {c.isActive ? <Badge bg='info'>Active!</Badge> : null}
                            </div>
                        </div>
                    </ListGroup.Item>
                )}
                </ListGroup>
                </Col>
            </Row>
            <Modal heading={editTitle + (editContest && editContest._id ? ' ' + editContest.contestName : '')} size={null}
                show={showEdit}
                onClose={() => closeEditor()}
                actionButtonOnClick={() => save()}
                actionButtonText={'save'}>
                <div>
                    {editContest ?
                        <CaptureContestEditor 
                            {...editContest}
                            closeEditor={closeEditor}
                            updateEditedContest={updateEditedContest} 
                            onDelete={onDelete} restore={restore} />
                    : null}
                </div>
            </Modal>
        </div>
    )
}

export default CaptureContests