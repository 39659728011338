import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import moment from 'moment-timezone'
import { FRIENDLY_DATE_FORMAT } from '@severed-links/common.redherrings-constants'
import * as s from './CinderellaConfigSummary.scss'
import CinderellaEntryTally from './CinderellaEntryTally'
import ConfigSummaryServerTime from './ConfigSummaryServerTime'

const CinderellaConfigSummary = () => {

    const dispatch = useDispatch()
    const year = useSelector(state => state.cinderellaAdmin.year)
    const numPicks = useSelector(state => state.cinderellaAdmin.numPicks)
    const entryCode = useSelector(state => state.cinderellaAdmin.entryCode)
    const pickDeadline = useSelector(state => state.cinderellaAdmin.pickDeadline)
    const entryFee = useSelector(state => state.cinderellaAdmin.entryFee)
    const eventTitle = useSelector(state => state.cinderellaAdmin.eventTitle)
    const payPalAccount = useSelector(state => state.cinderellaAdmin.payPalAccount)
    const timeZone = useSelector(state => state.account.time_zone || 'America/New_York')

    const _summary = [
        { title: 'Year', value: `${year}` },
        { title: 'Picks', value: `${numPicks}` },
        { title: `Pick Deadline`, subtitle: `${timeZone}`, value: moment(pickDeadline).isValid() ? moment(pickDeadline).tz(timeZone).format(FRIENDLY_DATE_FORMAT) : '' },
        { title: 'Entry Fee', value: `$${(entryFee || 0).toFixed(2)}` },
        { title: 'Event Title', value: `${eventTitle}` },
        { title: 'Entry Code', value: `${entryCode}` },
        { title: 'Server time', subtitle: `${timeZone}`, value: <ConfigSummaryServerTime /> },
    ].filter(i => i.value)
    return (
        <div className={s.container}>
            <h4>Configuration</h4>
            <ListGroup className={s.list}>
                <CinderellaEntryTally />
                {_summary && _summary.map(i =>
                <ListGroup.Item className={s.item} key={`cinderella-config-summary-${i.title}`}>
                    <div className={s.titleContainer}>
                        <div className={s.title}>{i.title}</div>
                        <div className={s.subtitle}>{i.subtitle}</div>
                    </div>
                    <div className={s.value}>{i.value}</div>
                </ListGroup.Item>
                )}
            </ListGroup>
        </div>
    )
}

export default CinderellaConfigSummary