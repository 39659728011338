import React, { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { Table, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import moment from 'moment-timezone'
import NCAATeam from '../Cinderella/NCAATeam/NCAATeam'
import Line from '../Football/Line'
import * as s from './CaptureTourneyGameList.scss'
import CaptureStatusLabel from './CaptureStatusLabel'
import CaptureGameTime from './CaptureGameTime'

const CaptureTourneyGameListItem = ({ g = [] }) => {
    if (!g || g.length !== 2) return null

    var t1 = g[0]
    var t2 = g[1]

    if (t2.favorite || (!t1.favorite && !t2.favorite && t1.seed < t2.seed)) {
        var t2 = g[0]
        var t1 = g[1]                
    }

    const _favorite = t1.favorite ? t1 : t2.favorite ? t2 : null
    const _teams = [t1,t2]

    return (
        <ListGroup.Item className={s.item}>
            <div className={s.header}>
                <div className={s.date}>
                    <CaptureGameTime gameTime={t1.gameTime} timeOnly />
                </div>
                <div className={s.lineContainer}>
                {!isNaN(t1.line) && _favorite ? 
                <>
                <span>{_favorite.teamName}</span>
                <span className={s.separator}>by</span>
                <Line line={t1.line} showSign={false} />
                </>
                : null}
                </div>
                <div className={s.captureStatus}>
                    <CaptureStatusLabel status={t1.captureStatus} />
                </div>
            </div>
            <div className={s.teams}>
            {_teams && _teams.map(t =>
            <NCAATeam teamId={t.teamId} name={t.teamName} 
                showAvatar avatarUrl={t.avatarUrl}
                seed={t.seed} gameScore={t.score} showGameScore={true}
                key={`capture-scoreboard-item-${t.teamId}`} />        
            )}
            </div>

        </ListGroup.Item>
    )

}

const CaptureTourneyGameList = () => {

    const gamesForDay = useSelector(state => state.capture.gamesForDay || [])
    const totalGames = gamesForDay.length

    return (
        <div className={s.container}>
            <ListGroup className={s.list + ([2,3].includes(totalGames) ? ` ${s.moreThan2}` : '') + (totalGames >= 4 ? ` ${s.moreThan4}` : '')}>
            {gamesForDay && gamesForDay.map((game, index) => 
            <CaptureTourneyGameListItem g={game} index={index}
                key={`capture-tourney-game-item-${index}`} />
            )}
            {!gamesForDay || !gamesForDay.length ?
            <ListGroup.Item className={s.item}>No games located.</ListGroup.Item>
            : null}
            </ListGroup>
        </div>
    )
}

export default CaptureTourneyGameList