import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Alert, Button, Badge, Form, FormGroup, Image, 
    FormLabel, FormControl, FormControlFeedback, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Player from '../Player/Player'
import moment from 'moment-timezone'
import { startsWith, startCase, filter } from 'lodash'
import { getMyTickets, getOthersTickets, toggleTicketModal, setActiveTicket, 
    updateActiveTicket, saveTicket, loadActiveTicket } from '@severed-links/common.redherrings-reducers/help'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import * as s from './HelpCenter.scss'
import Modal from '@severed-links/common.modal'
import { img } from '@severed-links/common.redherrings-constants'
import LastTicketUpdate from './LastTicketUpdate'
import { camelCase } from 'lodash'

const TicketListItem = ({ t = {} }) => {

    const dispatch = useDispatch()

    const openTicket = ticket => {
        dispatch(setActiveTicket(ticket))
        dispatch(toggleTicketModal(true))
        dispatch(loadActiveTicket(ticket._id))
    }

    const createImageUrl = app => {
        const appRoot = `${(app || '').replace('Player', '')}HeaderMobile`
        return `${img.CloudinaryBaseUrl}${(img[appRoot] ? img[appRoot] : img.RedHerringsBlockLogo)}`
    }

    if (!t) return null
    const _imageUrl = createImageUrl(t.app)
    return (
        <ListGroup.Item key={t._id} className={s.ticketListItem} 
            onClick={() => openTicket(t)} action>
            <div className={s.details}>
                <div className={s.ticketTypeContainer}>
                    <div className={s.ticketTypeIcon + (t.ticketType ? ` ${s[camelCase(t.ticketType.replace(/\s+/gi, ''))]}` : ``)}>
                        {t.ticketType === 'Bug' ? <FontAwesomeIcon name='bug' size='2x' /> : null}
                        {t.ticketType === 'Feature Request' ? <FontAwesomeIcon name='star' size='2x' /> : null}
                        {t.ticketType === 'Comment' ? <FontAwesomeIcon name='comment' size='2x' /> : null}
                    </div>
                    <div className={s.appIcon}>
                        {t.app && _imageUrl ? <Image src={_imageUrl} /> : null}
                    </div>
                </div>
                <div className={s.playerContainer}>
                    <div className={s.avatar}><Player onlyAvatar isLarge playerId={t.player._id} fullName={t.player.fullName} avatarUrl={t.player.avatarUrl} /></div>
                    <div className={s.name}><Player onlyName playerId={t.player._id} fullName={t.player.fullName} avatarUrl={t.player.avatarUrl} /></div>
                </div>
                <div className={s.detailsContainer}>
                    <div className={s.subject}>{`Ticket #${t.ticketNumber}${t.subject ? `:  ${t.subject}` : ``}`}</div>
                    <div className={s.description}>{t.description}</div>
                </div>
                {t.comments && t.comments.length ?
                <div className={s.commentCount}>
                    <FontAwesomeIcon name='comment' className={s.icon} />
                    <div className={s.count}>{t.comments.length}</div>
                </div>
                : null}
                <div className={s.ticketIndicators}>
                    {!t.isCompletedTicket && !t.isDeletedTicket ? 
                        <Badge className={s.badge} bg='info' size='sm'>
                            {t.createDate === t.updateDate ? 'Created' : 'Updated'}
                        </Badge>
                    : null}
                    {t.isCompletedTicket ? <Badge className={s.badge} size='sm' bg='success'>Completed</Badge> : null}
                    {t.isDeletedTicket ? <Badge className={s.badge} size='sm' bg='danger'>Deleted</Badge> : null}
                    <div className={s.date}><LastTicketUpdate dateUpdated={moment(t.updateDate).toISOString()} /></div>
                </div>
                <div className={s.chevron}>
                    <FontAwesomeIcon name='chevron-right' />
                </div>
            </div>
        </ListGroup.Item>
    )
}

export default TicketListItem