import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Image, Transformation } from 'cloudinary-react'
import { getCloudinaryConfig } from '@severed-links/common.redherrings-reducers/cloudinary'
import { img } from '@severed-links/common.redherrings-constants'

const CloudinaryImage = ({ publicId, transformationName, ...otherProps }) => {

    const cloudName = useSelector(state => state.cloudinary.cloudName)
    const noImageAvailable = useSelector(state => state.cloudinary.noImageAvailable)

    return (
        cloudName && (publicId || noImageAvailable) ?
        <Image {...otherProps} 
            cloudName={cloudName} 
            transformation={transformationName || null}
            publicId={publicId || noImageAvailable} 
            secure /> 
        : null
    )
}

export default CloudinaryImage
