import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import Alert from '@severed-links/common.alert'
import Modal from '@severed-links/common.modal'
import numeral from 'numeral'
import moment from 'moment-timezone'
import * as s from './RedHerringsPayPalButton.scss'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { createPayPalOrder, setPaymentState, updatePayPalOrder, getPayPalClientId } from '@severed-links/common.redherrings-reducers/payment'
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js'

const RedHerringsPayPalButton = ({
    contest, itemNumber, receiverEmail, description = '', amount = 0.00,
    year = moment().year(), seasonId = null, playerId = null, teamId = null,
    onAuthorizedPayment = () => void(0),
    onCancelledPayment = () => void(0),
    disabled = false,
    variant = 'primary', size = 'sm',
}) => {

    const dispatch = useDispatch()
    const { userName, _id: _playerId } = useSelector(state => state.account)
    const { payPalClientId, showPayPalModal, referenceId, transactionStatus } = useSelector(state => state.payment)    
    playerId = playerId || _playerId
    const [errorMessage, setErrorMessage] = useState(null)

    useEffect(() => { 
        dispatch(getPayPalClientId())
    }, [])

    useEffect(() => {
        if (showPayPalModal) {
            setErrorMessage(null)
        }
    }, [showPayPalModal])

    if (!payPalClientId) return null
    const _payPalOptions = { 
        clientId: !__DEV__ ? payPalClientId : 'sb',
        currency: 'USD',
        intent: 'capture',
        commit: true,
        disableFunding: 'credit',
    }
    const amountDisplay = numeral(parseFloat(amount || '0.01')).format('$0.00')
    return (
        <PayPalScriptProvider options={_payPalOptions}>
            <Button className={s.paymentButton} variant={variant} disabled={disabled} 
                onClick={() => dispatch(setPaymentState({ showPayPalModal: true, referenceId: null }))}>
                Pay {amountDisplay}
            </Button>
            <Modal heading={`Pay: ${amountDisplay}`} size={'sm'}
                show={showPayPalModal}
                onClose={() => dispatch(setPaymentState({ showPayPalModal: false, referenceId: null }))}>
                <div className={s.container}>
                    
                    {errorMessage ? 
                    <Alert small 
                        message={errorMessage} 
                        variant='danger'
                        icon='exclamation-triangle' />
                    : null}
                    
                    <PayPalButtons style={{ layout: 'vertical' }}
                        disabled={!payPalClientId || !showPayPalModal}
                        fundingSource={undefined}
                        createOrder={async () => {
                            try {
                                setErrorMessage(null)
                                var _order = { 
                                    contest, itemNumber, receiverEmail, description, amount, 
                                    year, seasonId, playerId, teamId,
                                    data: {
                                        body: {
                                            intent: 'CAPTURE',
                                            purchaseUnits: [
                                                {
                                                    description,
                                                    referenceId: _referenceId,
                                                    amount: {
                                                        currencyCode: 'USD',
                                                        value: `${amount}`,
                                                        breakdown: {
                                                            itemTotal: {
                                                                currencyCode: 'USD',
                                                                value: `${amount}`,
                                                            },
                                                        },
                                                    },
                                                },
                                            ],
                                        },
                                        prefer: 'return=representation',
                                
                                    }
                                }
                                const _createOrderResponse = await dispatch(createPayPalOrder(_order))
                                const _referenceId = ((_createOrderResponse || {}).payload || {})._id || null
                                if (__DEV__ || userName === 'gtw') {
                                    console.log({ _referenceId })
                                }
                                dispatch(setPaymentState({ referenceId: _referenceId }))
                                if (_createOrderResponse?.payload?.orderId) {
                                    return _createOrderResponse.payload.orderId
                                } else {
                                    throw new Error('Cannot initiate PayPal transaction...')
                                }
                            } catch (_ex) {
                                console.log(_ex.message)
                            }
                        }}             
                        onApprove={async (data, actions) => {
                            try {
                                setErrorMessage(null)
                                if (__DEV__ || userName === 'gtw') console.log({ referenceId, data })
                                if (referenceId) {
                                    const _updateOrderResponse = await dispatch(updatePayPalOrder(referenceId, { payPalOrderTransactionId: data.orderID }))
                                    const orderData = _updateOrderResponse?.payload?.orderData
                                    // Three cases to handle:
                                    //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                    //   (2) Other non-recoverable errors -> Show a failure message
                                    //   (3) Successful transaction -> Show confirmation or thank you message
        
                                    const errorDetail = orderData?.details?.[0]
        
                                    if (errorDetail?.issue === 'INSTRUMENT_DECLINED') {
                                        // (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                                        // recoverable state, per https://developer.paypal.com/docs/checkout/standard/customize/handle-funding-failures/
                                        return actions.restart()
                                    } else if (errorDetail) {
                                        // (2) Other non-recoverable errors -> Show a failure message
                                        setErrorMessage(`${errorDetail.description} (${orderData.debug_id})`)
                                    } else {
                                        // (3) Successful transaction -> Show confirmation or thank you message
                                        // Or go to another URL:  actions.redirect('thank_you.html')
                                        const transaction = orderData.purchase_units[0].payments.captures[0]

                                        setTimeout(() => { dispatch(setPaymentState({ showPayPalModal: false, referenceId: null })) }, 1000)
                                        if (onAuthorizedPayment) {
                                            onAuthorizedPayment({ 
                                                data, contest, itemNumber, receiverEmail, 
                                                description, amount, seasonId, playerId,
                                                referenceId,
                                            })
                                        }
    
                                    }
                                }
    
                            } catch (error) {
                                setErrorMessage(`Sorry, your transaction could not be processed...${error}`)
                            }    
                        }}  
                        onError={async err => {
                            console.log(err)
                        }}  
                        onCancel={async (data, actions) => {
                            setErrorMessage(null)
                            if (__DEV__ || userName === 'gtw') console.log(data, actions)
                            dispatch(setPaymentState({ showPayPalModal: false, referenceId: null }))
                            if (onCancelledPayment) {
                                onCancelledPayment()
                            }    
                        }} />
                </div>
            </Modal>
        </PayPalScriptProvider>
    )
}
   
export default RedHerringsPayPalButton
