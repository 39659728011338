import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Button, ButtonGroup } from 'react-bootstrap'
import moment from 'moment-timezone'
import * as s from './FootballAdmin.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Logins from '../../Main/Admin/Logins'
import FootballConfigSummary from './FootballConfigSummary'
import FootballSetActiveSeason from './FootballSetActiveSeason'
import FootballEditConfiguration from './FootballEditConfiguration'
import BroadcastMessage from './BroadcastMessage'
import FootballAdminSetSiteRegistration from './FootballAdminSetSiteRegistration'
import Modal from '@severed-links/common.modal'
import { resetEditConfig, saveConfig, getConfig } from '@severed-links/common.redherrings-reducers/footballAdmin'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import OnlineUsers from '../../../components/OnlineUsers'
import SendPushNotification from '../../Notifications/SendPushNotification'

const FootballAdminDashboard = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const seasonId = useSelector(state => state.football.seasonId)
    const seasonName = useSelector(state => state.football.seasonName)
    const seasonMode = useSelector(state => state.football.seasonMode)
    const week = useSelector(state => state.football.week)
    const playoffWeek = useSelector(state => state.football.playoffWeek)
    const loginInfo = useSelector(state => state.footballAdmin.loginInfo)
    const editConfig = useSelector(state => state.footballAdmin.editConfig)
    const onlineUsers = useSelector(state => state.footballAdmin.onlineUsers)
    const dashboardSubTab = (params || {}).dashboardSubTab || null

    const saveConfiguration = () => {
        const stateToSave = { ...editConfig, teamRegistration: editConfig.teamRegistration ? 'Open' : 'Closed' }
        dispatch(saveConfig(stateToSave))
        .then(resp => {
            dispatch(createNotification({ 
                type: resp.payload.messageType, 
                headline: 'Save Configuration', 
                message: resp.payload.message, 
                timeout: 6000 
            }))
            dispatch(getConfig(resp.payload.data))
        })
        dispatch(resetEditConfig())
        navigate('/football/admin')
    }

    var buttonContainers = [
        { icon: 'envelope', text: 'send email', onClick: () => navigate(`/football/admin/utility/broadcast-message`) },
        { icon: 'leaf', text: 'set active season', onClick: () => navigate(`/football/admin/utility/set-active-season`) },
        { icon: 'cog', text: 'edit configuration', onClick: () => navigate(`/football/admin/utility/edit-season-config`) },
        ...(seasonMode === 'Preseason' ? [
            { icon: 'list-alt', text: 'team data', onClick: () => navigate(`/football/admin/preseason/team-data/${seasonName}`) },
            { icon: 'list', text: 'NFL scheduler', onClick: () => navigate(`/football/admin/preseason/nfl-scheduler/${seasonName}`) },
            { icon: 'th-list', text: 'league scheduler', onClick: () => navigate(`/football/admin/preseason/league-scheduler/${seasonName}`) },
            { icon: 'money-bill-alt', text: 'payment log', onClick: () => navigate(`/football/admin/preseason/payment-log/${seasonName}`) },
        ] : []),
        ...((seasonMode === 'Regular' || seasonMode === 'Preseason') ? [
            { icon: 'rotate', text: 'update manager', onClick: () => navigate(`/football/admin/regular-season/update-manager/${seasonName}/week/${week}`) },
        ] : []),
    ]
    return (
        <div className={s.container}>
            <h2>Administrator Dashboard</h2>
            <Row>
                <Col sm={6} xs={12}>
                    <FootballConfigSummary />
                    <ButtonGroup className={s.dashboardButtonContainer}>
                        <FootballAdminSetSiteRegistration />
                        {buttonContainers.map(b =>
                            <Button variant='light' key={`football-admin-dashboard-${b.text}`} onClick={() => b.onClick()}><FontAwesomeIcon name={b.icon} /> {b.text}</Button>
                        )}
                    </ButtonGroup>
                </Col>
                <Col sm={6} xs={12}>
                    <h4>
                        Online Users
                        {' '}
                        <SendPushNotification />
                    </h4>
                    <OnlineUsers users={onlineUsers} />
                    <Logins isDashboard />                        
                </Col>
            </Row>

            <Modal heading='Broadcast Message'
                show={dashboardSubTab === 'broadcast-message'}
                onClose={() => navigate(`/football/admin`)}
                actionButtonText={null}>
                <BroadcastMessage />
            </Modal>

            <Modal heading='Set active season' size={'sm'}
                show={dashboardSubTab === 'set-active-season'}
                onClose={() => navigate(`/football/admin`)}
                actionButtonText={null}>
                <FootballSetActiveSeason />
            </Modal>

            <Modal heading={'Edit ' + seasonName + ' Season configuration'} size={null}
                show={dashboardSubTab === 'edit-season-config'}
                onClose={() => { navigate(`/football/admin`); resetEditConfig(); }}
                actionButtonOnClick={() => saveConfiguration()}
                actionButtonText={'save configuration'}>
                <div className={s.editConfigurationModalContainer}><FootballEditConfiguration /></div>                    
            </Modal>
        </div>
    )
}

export default FootballAdminDashboard