import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Row, Col, Button, Alert } from 'react-bootstrap'
import * as s from './LeagueScheduler.scss'
import { getLeagueScheduler, setLeagueSchedulerState, getUnscheduledSeasons, updateManagerRegularSeasonPrepareAutoPicks } from '@severed-links/common.redherrings-reducers/footballAdmin'
import { take, find, findIndex, forEach, random, sample, filter, 
    some, intersection, roun, sumBy, sum, orderBy, update } from 'lodash'
import moment from 'moment-timezone'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import SeasonSelector from '../SeasonSelector'
import Player from '../../Player/Player'
import PlayerTeam from '../PlayerTeam'
import Confirm from '@severed-links/common.confirm'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'

import LeagueSchedulerGrid from './LeagueSchedulerGrid'
import LeagueSchedulerSummary from './LeagueSchedulerSummary'
import LeagueSchedulerControls from './LeagueSchedulerControls'
import LeagueSchedulerOddTeamWarning from './LeagueSchedulerOddTeamWarning'
import LeagueSchedulerSettings from './LeagueSchedulerSettings'

const LeagueScheduler = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const _seasonName = params.seasonName || null
    const _leagueSchedulerData = useSelector(state => state.footballAdmin.leagueSchedulers.find(i => i.seasonName === _seasonName) || {})
    const seasons = useSelector(state => state.football.seasons)
    const unscheduledSeasons = useSelector(state => state.footballAdmin.unscheduledSeasons)
    const season = seasons.find(i => i.seasonName === _seasonName) || {}
    const [currentTime, setCurrentTime] = useState(moment().toISOString())
    var { seasonId, WAIT_MS, createAutoPicks, scheduleToSave, progress, passes, resets, grid, gridUpdated, startTime, isLoaded, isRunning, isDone, seasonName, teams, weeks, divisionHeadings, divisionIndices, divisionNames, divisions, gamesToSchedule } = _leagueSchedulerData
    const _ms = moment(currentTime).diff(moment(gridUpdated), 'ms')
    const fireUpdate = _ms > WAIT_MS

    useEffect(() => {
        dispatch(getUnscheduledSeasons())
        if (season.seasonId) {
            let timerId = setInterval(() => setCurrentTime(moment().toISOString()), 1)
            return () => {
                dispatch(setLeagueSchedulerState(season.seasonId, { isRunning: false }))
                clearInterval(timerId)
            }
        }
    }, [season.seasonId])
    useEffect(() => { rerouteOnNoSeasonName() }, [_seasonName, unscheduledSeasons.nextUnscheduledLeagueSeasonName])
    useEffect(() => { getData() }, [season.seasonId])
    useEffect(() => { isRunning && !isDone && fireUpdate ? placeNextGame() : null }, [isRunning, isDone, fireUpdate])
    useEffect(() => { isLoaded ? dispatch(setLeagueSchedulerState(season.seasonId, { grid: getResetGrid() })) : null }, [isLoaded])

    const getData = () => {
        if (season && season.seasonId) {
            dispatch(getLeagueScheduler(season.seasonId))
            .then(action => { 
                dispatch(setLeagueSchedulerState(season.seasonId, { 
                    season, 
                    seasonName, 
                    passes: 0,
                    resets: 0,
                    startTime: null,
                    ...action.payload,
                    grid: [],
                    progress: 0.00,
                    isLoaded: true, 
                    isRunning: false,
                    isDone: false,
                    WAIT_MS: 10,
                }))
            })
        }
    }

    const rerouteOnNoSeasonName = () => {
        if (!_seasonName && unscheduledSeasons.nextUnscheduledLeagueSeasonName) {
            navigate(`/football/admin/preseason/league-scheduler/${unscheduledSeasons.nextUnscheduledLeagueSeasonName}`)
        }
    }

    const divisionNameFromCode = divCode => divisionNames[findIndex(divisions, d => d === divCode)]

    const getResetGrid = () => (teams || []).map(t => ({ ...t, divisionName: divisionNameFromCode(t.division), games: [...weeks.map(w => ({ week: w, opp: null }))], numGames: 0 }))

    const resetData = () => dispatch(setLeagueSchedulerState(season.seasonId, { 
        grid: getResetGrid(),
        passes: 0,
        progress: 0.00,
        resets: (resets || 0) + 1,
        isDone: false,
    }))

    const resetScheduler = () => resetData()

    const createDivisionalSchedule = () => {
        var _grid = [...getResetGrid()]
        var index = 0
        while (index <= _grid.length - 1) {
            while (getRemainingDivisionalOpponents(_grid[index], _grid).length > 0) {
                const nextGame = getDivisionalGame(index, _grid)
                if (nextGame.isValid) {
                    var team1Index = findIndex(_grid, t => t._id === nextGame.team._id)
                    var team2Index = findIndex(_grid, t => t._id === nextGame.opponent._id)
                    _grid[team1Index].games[nextGame.week - 1].opp = { ...nextGame.opponent }
                    _grid[team1Index].numGames = filter(grid[team1Index].games, g => g.opp && g.opp.teamName).length
                    _grid[team2Index].games[nextGame.week - 1].opp = { ...nextGame.team }
                    _grid[team2Index].numGames = filter(grid[team2Index].games, g => g.opp && g.opp.teamName).length
                }
            }
            index++
        }
        return _grid
    }

    const placeNextGame = () => {
        // do divisional games
        if (some(grid, g => getRemainingDivisionalOpponents(g, grid).length > 0)) {
            // divisional games needed
            dispatch(setLeagueSchedulerState(season.seasonId, { grid: createDivisionalSchedule() }))
        } else {
            // do non-divisional games
            //console.log('time for non-divisional games...?')
            setNonDivisionalGames()
        }
    }

    const getDivisionalGame = (index, _grid = []) => {
        var team = _grid[index]
        var teamsPlayed = getTeamsPlayed(team)
        //console.log('teamsPlayed', teamsPlayed)
        var remainingDivisionalOpponents = getRemainingDivisionalOpponents(team, _grid)
        //console.log('  - remaining divisional opponents:', remainingDivisionalOpponents)
        var opponent = sample(remainingDivisionalOpponents)
        //console.log('  - opponent:', opponent)
        var possibleWeeks = intersection(getAvailableWeeks(team), getAvailableWeeks(opponent))
        //console.log('  - possible weeks:', possibleWeeks)
        return { isValid: possibleWeeks.length > 0, team, opponent, week: sample(possibleWeeks) }
    }

    const setDivisionalGame = (index) => {
        const game = getDivisionalGame(index)
        if (game.isValid) {
            placeGame(team, opponent, sample(possibleWeeks))
        }
        return game.isValid ? 1 : -1
    }

    const placeGame = (team1, team2, week) => {
        var team1Index = findIndex(grid, t => t._id === team1._id)
        var team2Index = findIndex(grid, t => t._id === team2._id)
        grid[team1Index].games[week - 1].opp = { ...team2 }
        grid[team1Index].numGames = filter(grid[team1Index].games, g => g.opp && g.opp.teamName).length
        grid[team2Index].games[week - 1].opp = { ...team1 }
        grid[team2Index].numGames = filter(grid[team2Index].games, g => g.opp && g.opp.teamName).length
        passes++
        const totalScheduledGames = sumBy(grid, 'numGames')
        progress = Math.round(gamesToSchedule !== 0 ? 100.0 * parseFloat(totalScheduledGames) / parseFloat(gamesToSchedule) : 0, 0)
        dispatch(setLeagueSchedulerState(season.seasonId, { grid: [...grid], passes, progress }))
    }

    const getAvailableWeeks = teamRow => teamRow && teamRow.games ? filter(teamRow.games, g => !g.opp).map(g => g.week) : []

    const getTeamsPlayed = teamRow => teamRow && teamRow.games ? filter(teamRow.games, g => g.opp && g.opp.teamName).map(g => g.opp) : []

    const getDivisionalOpponents = (teamRow, _grid = []) => teamRow && teamRow._id ? filter(_grid, t => t._id !== teamRow._id && t.division === teamRow.division) : []

    const getRemainingDivisionalOpponents = (teamRow, _grid = []) => filter(getDivisionalOpponents(teamRow, _grid), item => !some(getTeamsPlayed(teamRow), t1 => t1._id === item._id))

    const getNonDivisionalOpponents = (teamRow, _grid = []) => filter(_grid, t => t._id !== teamRow._id && t.division !== teamRow.division)

    const getRemainingNonDivisionalOpponents = (teamRow, _grid = []) => filter(getNonDivisionalOpponents(teamRow, _grid), item => !some(getTeamsPlayed(teamRow), t1 => t1._id === item._id))

    const getOpponentsAvailableInWeek = (teamRow, week, _grid = []) => filter(getRemainingNonDivisionalOpponents(teamRow, _grid), o => !o.games[week - 1].opp).map(o => ({ opponent: o, availableWeeks: getAvailableWeeks(o) }))

    const doneWithSchedule = () => {
        console.log('* * * * * SUCCESS! * * * * *')
        var scheduleToSave = grid.map(g => { return { teamId: g._id, teamName: g.teamName, opponents: g.games.map(g => { return g.opp._id }) }})
        dispatch(setLeagueSchedulerState(season.seasonId, { scheduleToSave, isDone: true, isRunning: false, scheduleToSave }))
    }

    const setNonDivisionalGames = () => {
        if (!isRunning) return
        var index = 0
        var indices = []
        while (index <= grid.length - 1) {
            if (getAvailableWeeks(grid[index]).length > 0) indices.push(index)
            index++
        }

        if (indices.length > 0) {
            const minGamesOnSchedule = Math.min(...grid.map(x => x.numGames))
            //console.log(minGamesOnSchedule, indices, filter(indices, i => grid[i].numGames === minGamesOnSchedule))
            index = sample(filter(indices, i => grid[i].numGames === minGamesOnSchedule))
            var team = grid[index]
            //console.log('Found ', index, team)
            var availableWeeks = getAvailableWeeks(team)
            //console.log(' - available weeks left:', availableWeeks)
            if (availableWeeks.length > 0) {
                var week = sample(availableWeeks)  //use random available week
                var opponents = getOpponentsAvailableInWeek(team, week, grid)
                //console.log(' - available opponents in week', week, opponents)
                if (opponents.length > 0) {
                    var selectedOpponent = sample(opponents)
                    if (some(opponents, o => o.availableWeeks.length === 1)) {
                        //console.log('only 1 available week for ', filter(opponents, o => o.availableWeeks.length === 1), some(opponents, o => o.availableWeeks.length === 1))
                        selectedOpponent = sample(filter(opponents, o => o.availableWeeks.length === 1))
                    }
                    placeGame(team, selectedOpponent.opponent, week)
                } else {
                    //console.log('We died placing non-divisional games for ', team)
                    resetScheduler()
                }
            } else {
                resetScheduler()
            }
        } else {
            doneWithSchedule()
        }
    }

    const prepareWeekOneAutoPicks = () => {
        dispatch(updateManagerRegularSeasonPrepareAutoPicks(seasonId, 1, true))
        .then(action => {
            dispatch(createNotification(action.payload))
        })

    }

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}>{seasonName} League Scheduler</h2>
                <div className={s.controls}>
                    <Confirm variant={'secondary'} 
                        onConfirm={() => prepareWeekOneAutoPicks()}
                        title={`Prepare Week 1 AutoPicks`}
                        confirmText={`prepare week 1 picks`}
                        body={`Are you sure you want to prepare week 1 autopicks and overwrite existing picks?`}
                        buttonIcon={'list-alt'} buttonText='week 1 autopicks' />
                    <Button variant='info' onClick={() => navigate('/football/admin/edit-seasons')}><FontAwesomeIcon name='pencil-alt' /> edit seasons</Button>
                </div>
                <div className={s.controls}>
                    <SeasonSelector seasons={seasons} season={season} />
                </div>
            </div>
            {teams && teams.length > 0 ?
            <Row>
                <Col sm={3} xs={12}>
                    <LeagueSchedulerSummary startTime={startTime} seasonName={seasonName} weeks={weeks}
                        teams={teams} divisions={divisions} gamesToSchedule={gamesToSchedule} progress={progress} 
                        resets={resets} />
                    <LeagueSchedulerSettings seasonId={seasonId} isRunning={isRunning}
                        createAutoPicks={createAutoPicks} WAIT_MS={WAIT_MS} />
                    <LeagueSchedulerControls seasonId={seasonId} seasonName={seasonName}
                        isRunning={isRunning} isDone={isDone} WAIT_MS={WAIT_MS}
                        scheduleToSave={scheduleToSave} createAutoPicks={createAutoPicks} />
                    <LeagueSchedulerOddTeamWarning teamCount={teams.length} />
                </Col>
                <Col sm={9} xs={12}>
                    <LeagueSchedulerGrid grid={grid} weeks={weeks} isRunning={isRunning} />
                </Col>
            </Row>
            : 
            <Row>
                <Col xs={12}>
                    <Alert variant='danger'>No teams loaded!</Alert>
                </Col>    
            </Row>}
        </div>
    )
}

export default LeagueScheduler
