import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import StepZilla from 'react-stepzilla'
import { resetForgotPassword } from '@severed-links/common.redherrings-reducers/forgotPassword'
import { checkEmail } from '@severed-links/common.redherrings-reducers/siteRegistration'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'

import '../SiteRegistration/ProgressTracker.scss'
import * as s from './ForgotPassword.scss'

import ForgotPasswordStep1 from './ForgotPasswordStep1'
import ForgotPasswordStep2 from './ForgotPasswordStep2'
import ForgotPasswordEnterCode from './ForgotPasswordEnterCode'
import ForgotPasswordEnterNew from './ForgotPasswordEnterNew'
import ForgotPasswordConfirmation from './ForgotPasswordConfirmation'

const ForgotPassword = () => {

    const dispatch = useDispatch()
    const fp = useSelector(state => state.forgotPassword)

    useEffect(() => { dispatch(resetForgotPassword()) }, [])

    const steps = [
        { name: 'Send Method', component: <ForgotPasswordStep1 /> },
        { name: fp.sendBy || 'Number', component: <ForgotPasswordStep2 /> },
        { name: 'Enter Code', component: <ForgotPasswordEnterCode /> },
        { name: 'Enter Password', component: <ForgotPasswordEnterNew />},
        { name: 'Confirmation', component: <ForgotPasswordConfirmation />}
    ]
    return (
        <Row>
            <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                <h2>Reset your password</h2>
                <div className={s.forgotPasswordContainer}>
                    <div className='step-progress'>
                        <StepZilla
                            steps={steps}
                            preventEnterSubmission={false}
                            nextTextOnFinalActionStep={'Reset'}
                            // backButtonText={<span><FontAwesomeIcon name='chevron-left' /> Back</span>}
                            backButtonText={'Back'}
                            backButtonCls={`btn btn-prev btn-light btn-lg`}
                            // nextButtonText={<span>Next <FontAwesomeIcon name='chevron-right' /></span>}
                            nextButtonText={'Next'}
                            nextButtonCls={`btn btn-next btn-light btn-lg${(fp.nextDisabled ? ' ' + s.nextDisabled : ``)}`}
                        />
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default ForgotPassword
