import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation, useMatch } from 'react-router-dom'
import { FormGroup, FormControl, InputGroup } from 'react-bootstrap'
import * as s from './ManageUsersRoleFilter.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { useDispatch } from 'react-redux'

const ManageUsersRoleFilter = ({ roleFilters = [] }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const appName = params.appName || ''
    const letter = params.letter || 'A'
    const roleFilter = params.roleFilter || 'AllRoles' 
    const _match = useMatch(`/:appName/admin/manage-users/filter/:letter/:roleFilter`)

    const handleSelect = e => {
        if (_match.pathname && e.target.value) {
            navigate(_match.pathname.replace(`/${_match.params.roleFilter}`, `/${e.target.value}`))
        }
    }

    return (
        <FormGroup className={s.container}>
            <InputGroup className={s.inputGroup}>
                <InputGroup.Text className={s.icon}><FontAwesomeIcon name='filter' /></InputGroup.Text>
                <FormControl as='select' placeholder='[Select a filter...]' 
                    value={roleFilter}
                    onChange={e => handleSelect(e)}>
                {roleFilters && roleFilters.map(o => 
                    <option value={o.value} key={o.value}>{o.text}</option>
                )}
                </FormControl>
            </InputGroup>
        </FormGroup>
    )
}

export default ManageUsersRoleFilter
