import React, { useState, useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Alert, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'

const EnterOscarsPicksStart = ({ afterDeadline = false }) => {

    const navigate = useNavigate()

    return (
        <div>
            {afterDeadline ? 
            <Alert variant='danger'>
                The time for entering your picks has passed.
            </Alert>
            :
            <Alert variant='info'>
                <p>You still have time to enter your picks!</p>
                <p>
                    <Button variant='info' onClick={() => navigate('/oscars/enter-picks/1')}>
                        Let's get started!
                    </Button>
                </p>
            </Alert>
            }
        </div>
    )
}
    
export default EnterOscarsPicksStart