import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Row, Col, Table, Button, ListGroup } from 'react-bootstrap'
import * as s from './ManageUsers.scss'
import { getManageUsers } from '@severed-links/common.redherrings-reducers/manageUsers'
import { take, find } from 'lodash'
import Player from '../../Player/Player'
import PlayerListItem from '../../Player/PlayerListItem'
import moment from 'moment-timezone'
import pluralize from 'pluralize'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import ManageUsersLetterBar from './ManageUsersLetterBar'
import ManageUsersRoleFilter from './ManageUsersRoleFilter'
import ManageUsersEditUser from './ManageUsersEditUser'
import ManageUsersAddNewUser from './ManageUsersAddNewUser'
import Modal from '@severed-links/common.modal'
import { img, manageUsersPath } from '@severed-links/common.redherrings-constants'
import { upperCaseFirst } from 'upper-case-first'
import editorsReducer from '@severed-links/common.redherrings-reducers/editors'

const ManageUsers = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const isAuthenticated = useSelector(state => state.account.isAuthenticated)
    const users = useSelector(state => state.manageUsers.users) || []
    const letters = useSelector(state => state.manageUsers.letters)
    const roleFilters = useSelector(state => state.manageUsers.roleFilters)
    const [showNewUserModal, setShowNewUserModal] = useState(false)
    const app = params.appName || null
    const letter = params.letter || null
    const roleFilter = params.roleFilter || null
    const editPlayerId = params.playerId || null
    const showEditUserModal = !!editPlayerId
    const editUser = users.find(i => i._id === editPlayerId) || {}

    useEffect(() => { reRouteIfBlankParams() }, [app, letter, roleFilter])
    useEffect(() => { getData() }, [letter, roleFilter])

    const reRouteIfBlankParams = () => {
        const _path = `/${app}/admin/manage-users/filter/${letter || 'A'}/${app ? `${upperCaseFirst(app)}Player` : 'AllRoles'}`
    }

    const getData = (force = false) => {
        if (letter && roleFilter) {
            dispatch(getManageUsers(letter, roleFilter))
        }
    }

    const getImageUrl = lastLoginRole => {
        if (!lastLoginRole) return null
        const appRoot = `${(lastLoginRole || '').replace('Player', '').replace('Admin', '')}HeaderMobile`
        return `${img.CloudinaryBaseUrl}${(img[appRoot] ? img[appRoot] : img.RedHerringsBlockLogo)}`
    }

    const handleUserClick = (lastName, playerId) => navigate(manageUsersPath(app, lastName, playerId))

    const onModalClose = () => {
        setShowNewUserModal(false)
        navigate(manageUsersPath(app, letter))
        getData()
    }

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}>Manage Redherrings User Accounts</h2>
                <div className={s.controls}>

                    <Button onClick={() => setShowNewUserModal(true)}><FontAwesomeIcon name='plus' /></Button>

                    <ManageUsersRoleFilter 
                        roleFilter={roleFilter} 
                        letter={letter}
                        roleFilters={roleFilters} />

                </div>
            </div>
            <ManageUsersLetterBar letters={letters} roleFilters={roleFilters} />
            <ListGroup className={s.userList}>
            {users && users.map(p =>
            <PlayerListItem player={p}
                rightDetail={<div className={s.right}>
                    <div className={s.loginContainer}>
                        <div>{pluralize('login', p.numLogins, true)}</div>
                        <div>{moment(p.lastLogin).local().fromNow()}</div>
                    </div>
                    <div className={s.lastLoginContainer} style={{ backgroundImage: `url(${getImageUrl(p.lastLoginRole)})` }} />
                    <div className={s.lockedOutContainer + (p.isLockedOut ? ` ${s.isLockedOut}` : '')}>
                        <FontAwesomeIcon name={p.isLockedOut ? 'lock' : 'unlock'} />
                    </div>
                </div>}
                action onClick={() => handleUserClick(p.lastName, p._id || p.playerId)} 
                key={`manage-users-${p._id || p.playerId}`} />
            )}
            </ListGroup>

            <Modal heading={editUser && editUser._id ? `Edit User: ${(editUser || {}).fullName || ''}${(editUser || {}).userName ? ` (${(editUser || {}).userName})` : ''}` : ''}
                show={showEditUserModal}
                size={'lg'} showFooter={false}
                onClose={() => onModalClose()}
                actionButtonText={null}>
                <div>
                {showEditUserModal ?
                <ManageUsersEditUser playerId={editPlayerId} 
                    onClose={() => onModalClose()} />
                : null}
                </div>
            </Modal>

            <Modal heading={'New User'}
                show={showNewUserModal}
                size={'lg'}
                onClose={() => onModalClose()}
                actionButtonText={null}>
                <ManageUsersAddNewUser />
            </Modal>

        </div>
    )
}

export default ManageUsers