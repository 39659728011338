import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, FormGroup, FormControl, FormLabel, Button, Card } from 'react-bootstrap'
import * as s from './OscarsAdmin.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { uploadPoster, deletePoster } from '@severed-links/common.redherrings-reducers/oscarsAdmin'
import { getOscarsDetails } from '@severed-links/common.redherrings-reducers/oscars'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { trim, endsWith } from 'lodash'
import { img, getBase64 } from '@severed-links/common.redherrings-constants'
import Confirm from '@severed-links/common.confirm'
import Modal from '@severed-links/common.modal'
import moment from 'moment-timezone'
import { first } from 'lodash'

const OscarsPosters = () => {

    const dispatch = useDispatch()
    const _year = useSelector(state => state.oscars.year)
    const posters = useSelector(state => state.oscars.posters)
    const [showUploadBox, setShowUploadBox] = useState(false)
    const [year, setYear] = useState(moment().year() + 1)
    const [file, setFile] = useState(null)
    const [isSaving, setSaving] = useState(false)

    useEffect(() => { dispatch(getOscarsDetails()) }, [])

    const doDelete = posterId => {
        dispatch(deletePoster(posterId))
        .then(action => {
            dispatch(createNotification({ type: action.payload.messageType, headline: 'Delete Poster', message: action.payload.message, timeout: 6000 }))
            dispatch(getOscarsDetails())
        })
        
    }

    const handleImageChange = e => setFile(e.target.files && e.target.files.length > 0 ? e.target.files[0] : null)

    const doUpload = () => {
        if (file && year > 0) {
            setSaving(true)
            getBase64(file, _base64File => {
                dispatch(uploadPoster(year, _base64File))
                .then(action => {
                    dispatch(createNotification({ type: action.payload.messageType, headline: 'Save New Poster', message: action.payload.message, timeout: 6000 }))
                    dispatch(getOscarsDetails())
                    setShowUploadBox(false)
                    setFile(null)
                    setSaving(false)
                })
            })
        }
    }

    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <Button variant='light' style={{ float: 'right' }} 
                        onClick={() => setShowUploadBox(true)}>
                        <FontAwesomeIcon name='plus-circle' />
                        {' '}
                        <span className='d-none d-md-inline'>add poster</span>
                    </Button>
                    <h2 style={{ marginBottom: '1.5rem' }}>
                        Oscars Posters
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <div className={s.posterDeck}>
                    {posters && posters.map(p =>
                        <Card className={s.posterContainer} key={p._id}>
                            <Card.Header>
                                <Confirm variant='danger' onConfirm={() => doDelete(p._id)}
                                    title={'Delete Poster'}
                                    confirmText='delete poster'
                                    body={'Are you sure you want to delete this poster?'}
                                    buttonIcon='times' size='sm' buttonClassName={s.deleteButton} />
                                {p.posterYear}
                            </Card.Header>
                            <Card.Img className={s.img} src={`${img.BaseUrl}${img.OscarsPosterFolder}/${p.publicId}`} alt={p.posterYear} />
                        </Card>
                    )}
                    </div>
                </Col>
            </Row>
            <Modal heading='Upload New Poster'
                show={showUploadBox} showFooter={false}
                onClose={() => setShowUploadBox(false)}
                actionButtonText={null}>
                <div>
                    <Row>
                        <Col sm={6} xs={12}>
                            <FormGroup>
                                <FormLabel>Poster year</FormLabel>
                                <FormControl type='number' name='year' value={year} onChange={e => setYear(parseInt(e.target.value)) } />
                            </FormGroup>
                        </Col>
                        <Col sm={6} xs={12}>
                            <FormGroup>
                                <FormLabel>File</FormLabel>
                                <FormControl type='file' name='file'
                                    onChange={e => handleImageChange(e)} />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup>
                                <Button variant='primary' 
                                    disabled={!file || !year || isSaving} 
                                    onClick={() => doUpload()}>
                                    <FontAwesomeIcon name={isSaving ? 'circle-notch' : 'upload'} spin={isSaving} />
                                    {' '}
                                    {isSaving ? 'uploading...' : 'upload poster'}
                                </Button>
                            </FormGroup>
                        </Col>
                    </Row>
                </div>
            </Modal>
        </div>
    )
}

export default OscarsPosters