import React, { useEffect } from 'react'
import { ListGroup, Badge } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PlayerTeamListItem from '../PlayerTeamListItem'
import * as s from './YouMayRememberMeAs.scss'

import PlayerTeam from '../PlayerTeam'

const PlayoffTeamInfo = ({ playoffStatus = null, isLeagueChampion = false, isLeagueRunnerUp = false, isPlayoffTeam = false, maxPlayoffWeek = 0 }) => (
    playoffStatus ?
    <span className={s.playoffStatus + (isLeagueChampion ? ` ${s.leagueChampion}` : '') + (isLeagueRunnerUp ? ` ${s.leagueRunnerUp}` : '')}>{playoffStatus}</span>
    : null
)

const YouMayRememberMeAs = ({ youMayRememberMeAs = [] }) => {

    const ymrma = youMayRememberMeAs
    return (
        <div className={s.container}>
            <h4>You may also rememember me as...</h4>
            <ListGroup>
            {ymrma && ymrma.map(i => 
                <PlayerTeamListItem key={`ymrma-${i.teamId}`}
                    size='sm'
                    teamId={i.teamId} 
                    teamName={i.teamName} teamNameRaw={i.teamName}
                    avatarUrl={i.avatarUrl}
                    miniHeader={<div>{i.seasonName}</div>}
                    right={<div>{i.record}</div>}
                    detail={<PlayoffTeamInfo {...i} />} />
            )}
            </ListGroup>
        </div>
    )
}

export default YouMayRememberMeAs