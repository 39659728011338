import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import StepZilla from 'react-stepzilla'
import { resetSiteRegistration, siteRegistrationCheck } from '@severed-links/common.redherrings-reducers/siteRegistration'
    
import './ProgressTracker.scss'
import * as s from './SignUp.scss'

import PassphraseStep1 from './PassphraseStep1'
import UsernameStep2 from './UsernameStep2'
import PasswordStep3 from './PasswordStep3'
import NameAndEmailStep4 from './NameAndEmailStep4'
import InfoConfirmStep5 from './InfoConfirmStep5'
import RegistrationDoneStep6 from './RegistrationDoneStep6'

const SignUp = () => {

    const dispatch = useDispatch()
    const siteRegistration = useSelector(state => state.siteRegistration)

    useEffect(() => {
        dispatch(resetSiteRegistration())
        dispatch(siteRegistrationCheck())
    }, [])

    if (!siteRegistration) return null
    const steps = [
        { name: 'Passphrase', component: <PassphraseStep1 /> },
        { name: 'Username', component: <UsernameStep2 /> },
        { name: 'Password', component: <PasswordStep3 /> },
        { name: 'Name/Email', component: <NameAndEmailStep4 /> },
        { name: 'Confirm', component: <InfoConfirmStep5 /> },
        { name: 'Done!', component: <RegistrationDoneStep6 /> }
    ]
    const { isChecking, isOpen } = siteRegistration
    return (
        <Row>
            <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                <h2>Sign Up for an Account</h2>
                <div className={s.signupContainer}>
                {!isChecking && isOpen ? 
                    <div>
                        <h4>Welcome to Redherrings Registration!</h4>
                        <div className='step-progress'>
                            <StepZilla
                                steps={steps}
                                // backButtonText={<span><FontAwesomeIcon name='chevron-left' /> Back</span>}
                                backButtonText={'Back'}
                                backButtonCls={`btn btn-prev btn-light btn-lg ${s.prevButton}`}
                                // nextButtonText={<span>Next <FontAwesomeIcon name='chevron-right' /></span>}
                                nextButtonText={'Next'}
                                nextButtonCls={`btn btn-next btn-light btn-lg ${s.nextButton}${(siteRegistration.nextDisabled ? ' ' + s.nextDisabled : ``)}`}
                                preventEnterSubmission={false}
                                nextTextOnFinalActionStep={'Register'}
                            />
                        </div>
                    </div>
                : null}

                {!isChecking && !isOpen ?
                <Alert style={{ marginTop: '100px' }}>
                    This site is not accepting registrations for 
                    new users at this point.  Please check back 
                    later.
                </Alert>
                : null}

                </div>
            </Col>
        </Row>
    )
}

export default SignUp
