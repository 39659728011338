import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, FormGroup, FormLabel, FormControl, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { isEmpty } from 'validator'
import * as s from './ForgotPassword.scss'
import { resetForgotPassword, setUserInfo } from '@severed-links/common.redherrings-reducers/forgotPassword'

const ForgotPasswordStep1 = forwardRef(({ jumpToStep }, _ref) => {

    const dispatch = useDispatch()
    const fp = useSelector(state => state.forgotPassword)
    const [sendBy, setSendBy] = useState('')
    const _isValid = fp.sendBy == 'Email' || fp.sendBy == 'Text'

    useEffect(() => {
        setSendBy('')
        dispatch(resetForgotPassword())
    }, [])

    useEffect(() => {
        if (fp.nextDisabled !== !_isValid) dispatch(setUserInfo({ nextDisabled: !_isValid }))
    }, [fp.nextDisabled, _isValid])

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValid
    }))    

    const handleClick = _sendBy => {
        setSendBy(_sendBy)
        dispatch(setUserInfo({ sendBy: _sendBy }))
        jumpToStep(1)
    }

    return (
        <div className={s.step1}>
            <p className={s.instructions}>We can send you a code to your email address or your cell phone number
                that is associated with your Redherrings account.</p>
            <div className={s.buttonGroup}>
                <div className={s.button}>
                    <Button size='lg' variant={fp.sendBy === 'Email' ? 'primary' : 'light'} 
                        onClick={() => handleClick('Email')}>
                        <FontAwesomeIcon name='envelope' />
                        {' '}
                        <span className='d-none d-md-inline'>send via</span> 
                        {' '}
                        <span>email</span>
                    </Button>
                </div>
                <div className={s.button}>
                    <Button size='lg' variant={fp.sendBy === 'Text' ? 'primary' : 'light'} 
                        onClick={() => handleClick('Text')}>
                        <FontAwesomeIcon name='mobile' />
                        {' '}
                        <span className='d-none d-md-inline'>send via</span>
                        {' '}
                        SMS
                    </Button>
                </div>
            </div>
        </div>
    )
})

export default ForgotPasswordStep1
