// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CinderellaSendMail__container___unINv{margin-bottom:1rem}.CinderellaSendMail__container___unINv .CinderellaSendMail__formGroup___SC83I{margin-bottom:1rem}.CinderellaSendMail__container___unINv .CinderellaSendMail__formGroup___SC83I.CinderellaSendMail__submit___LI3jw{justify-content:flex-end;text-align:right}`, "",{"version":3,"sources":["webpack://./src/routes/Cinderella/Admin/CinderellaSendMail.scss"],"names":[],"mappings":"AAAA,uCACI,kBAAA,CAEA,8EACI,kBAAA,CAEA,iHACI,wBAAA,CACA,gBAAA","sourcesContent":[".container {\n    margin-bottom: 1rem;\n\n    .formGroup {\n        margin-bottom: 1rem;\n\n        &.submit {\n            justify-content: flex-end;\n            text-align: right;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `CinderellaSendMail__container___unINv`;
export var formGroup = `CinderellaSendMail__formGroup___SC83I`;
export var submit = `CinderellaSendMail__submit___LI3jw`;
export default ___CSS_LOADER_EXPORT___;
