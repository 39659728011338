// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PasswordValidationRules__container___zfhub .PasswordValidationRules__ruleList___FtC3i{margin:1rem 0px}.PasswordValidationRules__container___zfhub .PasswordValidationRules__ruleList___FtC3i .PasswordValidationRules__ruleItem___WgH8N{border:none;background-color:rgba(0,0,0,0);color:inherit;display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:flex-start}.PasswordValidationRules__container___zfhub .PasswordValidationRules__ruleList___FtC3i .PasswordValidationRules__ruleItem___WgH8N .PasswordValidationRules__icon___NMbxg{flex:0 0 0;white-space:nowrap;margin-right:.75rem}.PasswordValidationRules__container___zfhub .PasswordValidationRules__ruleList___FtC3i .PasswordValidationRules__ruleItem___WgH8N .PasswordValidationRules__text___z05B6{flex:1 1 auto}`, "",{"version":3,"sources":["webpack://./src/routes/ForgotPassword/PasswordValidationRules.scss"],"names":[],"mappings":"AACI,uFACI,eAAA,CAEA,kIACI,WAAA,CACA,8BAAA,CACA,aAAA,CACA,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,sBAAA,CAEA,yKACI,UAAA,CACA,kBAAA,CACA,mBAAA,CAGJ,yKACI,aAAA","sourcesContent":[".container {\n    .ruleList {\n        margin: 1rem 0px;\n\n        .ruleItem {\n            border: none;\n            background-color: transparent;\n            color: inherit;\n            display: flex;\n            flex-flow: row nowrap;\n            justify-content: space-between;\n            align-items: flex-start;\n\n            .icon {\n                flex: 0 0 0;\n                white-space: nowrap;\n                margin-right: 0.75rem;\n            }\n\n            .text {\n                flex: 1 1 auto;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `PasswordValidationRules__container___zfhub`;
export var ruleList = `PasswordValidationRules__ruleList___FtC3i`;
export var ruleItem = `PasswordValidationRules__ruleItem___WgH8N`;
export var icon = `PasswordValidationRules__icon___NMbxg`;
export var text = `PasswordValidationRules__text___z05B6`;
export default ___CSS_LOADER_EXPORT___;
