import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './CinderellaBadGameDataAlert.scss'
import { flatten, sumBy } from 'lodash'
import moment from 'moment-timezone'
import pluralize, { plural } from 'pluralize'

const CinderellaBadGameDataAlert = ({ games = [], round = 0, showBadGameTimes = true, showBadLines = false }) => {

    const _games = flatten(games.map(i => i.games)).map(i => ({
        ...i,
        isLineValid: i.line !== null && !isNaN(i.line),
        isGameTimeValid: i.gameTime && moment(i.gameTime).isValid(),
        isFavoriteTeamValid: i.favoriteTeamId && ((i || {}).teams || []).some(t => t.teamId === i.favoriteTeamId),
    }))
    const gameTimeErrors = _games.filter(i => !i.isGameTimeValid).length
    const lineErrors = _games.filter(i => !i.isLineValid).length
    const favoriteTeamErrors = _games.filter(i => !i.isFavoriteTeamValid).length
    const _errors = [
        showBadGameTimes ? { label: 'game time', count: gameTimeErrors } : null,
        showBadLines ? { label: 'line', count: lineErrors } : null,
        showBadLines ? { label: 'favorite', count: favoriteTeamErrors } : null,
    ].filter(i => i && i.count)
    const _totalErrors = sumBy(_errors, 'count')
    return (
        _totalErrors ?
        <Alert className={s.container} variant='danger'>
            <div className={s.heading}>Round {round} Errors:</div>
            {_errors && _errors.map(i => 
            <span className={s.error} key={`game-alert-${i.label}`}>{pluralize(i.label, i.count, true)} {pluralize('is', i.count, false)} invalid.</span>
            )}
        </Alert>
        : null
    )
}

export default CinderellaBadGameDataAlert
