import React, { useEffect, useState } from 'react'
import { FormGroup, InputGroup, Button, FormControl } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { img } from '@severed-links/common.redherrings-constants'
import CloudinaryImage from '../../Cloudinary/CloudinaryImage'
import * as s from './ScoreboardKeywordFilter.scss'

const ScoreboardKeywordFilter = ({ 
    show = false, filter = '', showMooseHunter = false, 
    minimumTeamsLeft = 0, teamsLeft = [],
    onSetMinimumTeamsLeft = () => void(0),
    toggleMooseHunter = () => void(0), updateTextFilter = () => void(0), clearTextFilter = () => void(0)
}) => {

    return (
        show ?
        <FormGroup className={s.container}>
            <InputGroup>
                <Button title='toggle MooseHunter' variant={showMooseHunter ? 'success' : 'info'} 
                    onClick={() => toggleMooseHunter()}>
                    {showMooseHunter ? 
                    <CloudinaryImage publicId={img.CinderellaMooseHunter} width={11} height={11} />
                    :
                    <FontAwesomeIcon name='filter' /> 
                    }                                        
                </Button>
                {!showMooseHunter ?
                <FormControl type='text' value={filter} 
                    placeholder={'Filter by player or entry names...'}
                    onChange={(e) => updateTextFilter(e)} 
                    disabled={showMooseHunter} />
                :
                <div className={s.mooseHunterCountsContainer}>
                {teamsLeft && teamsLeft.map(i =>
                    <div className={s.countItem + ' ' + s[`teamsLeft${i.teamsLeft}`]}
                        key={`teams-left-indicator-${i.teamsLeft}`}
                        onClick={() => onSetMinimumTeamsLeft(i.teamsLeft)}>
                        <div className={s.teamsLeft}>{i.teamsLeft}</div>
                        <div className={s.count + (!i.count ? ` ${s.zero}` : '')}>{i.count}</div>
                    </div>
                )}
                </div>}
                {filter !== '' ? 
                    <InputGroup.Text style={{ cursor: 'pointer' }} onClick={() => clearTextFilter()}><FontAwesomeIcon name='times' /></InputGroup.Text>
                : null}
            </InputGroup>
        </FormGroup>
        : null
    )
}

export default ScoreboardKeywordFilter
