import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, ButtonGroup } from 'react-bootstrap'
import * as s from '../RegularSeason/EnterPicksGameItem.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Line from '../Line'
import { img, nflTeamImage } from '@severed-links/common.redherrings-constants'
import { filter } from 'lodash'
import FormCheck from 'react-bootstrap/FormCheck'
import RankButtonsContainer from '../RegularSeason/RankButtonsContainer'

import { useDrag } from 'react-dnd'
import { DRAG_AND_DROP_ITEM_TYPES } from '@severed-links/common.redherrings-constants'

const EnterPostseasonPicksOUItem = ({ game = {}, pickGameData, seasonName, addCommand, ranks = [] }) => {

    const dispatch = useDispatch()
    const [showGameData, setShowGameData] = useState(false)
    const [showRankOptions, setShowRankOptions] = useState(false)

    const [{ isDragging }, _dragRef, _previewRef] = useDrag(() => ({
        type: DRAG_AND_DROP_ITEM_TYPES.POSTSEASON_OVER_UNDER_PICK,
        collect: (monitor, props) => ({ isDragging: monitor.isDragging() }),
        item: () => ({ game: game.game }),
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            if (dropResult && addCommand) {
                addCommand({ type: 'changeOURank', ...dropResult, game: game.game, oldRank: game.ouRank })
            }    
        }
    }), [game.game, game.ouRank])
    
    const handleSwitchChange = e => addCommand({ type: 'makeOUPick', game: game.game, pick: e.target.checked ? 'Over' : 'Under' })

    const getGameData = () => {
        setShowGameData(true)
        if (!pickGameData) {
            dispatch(getEnterPicksNFLMatchUp(seasonName, game.week, game.game))
        }
    }

    const toggleMiniRankMenu = (e, _showRankOptions) => {
        setShowRankOptions(_showRankOptions)
        e.stopPropagation()
    }

    const handleMiniRankClick = (e, newRank) => {
        toggleMiniRankMenu(e, false)
        addCommand({ type: 'changeOURank', ...{ newRank }, game: game.game, oldRank: game.ouRank })
    }

    return (
        <div ref={_previewRef} className={s.container + (isDragging ? ' ' + s.isDragging : '')}>

            <div ref={_dragRef} className={s.sortHandleContainer} onClick={e => toggleMiniRankMenu(e, !showRankOptions)}>
                <FontAwesomeIcon name='sort' />
            </div>

            <RankButtonsContainer ranks={ranks} 
                game={game} isOuRanks
                show={showRankOptions} 
                onClick={handleMiniRankClick} 
                onClose={toggleMiniRankMenu} />

            <div className={s.ouContainer}>
                <div className={s.ouTeam} style={{ backgroundImage: `url(${nflTeamImage(game.visitorMascot)})` }} />
                <div className={s.ouTeam} style={{ backgroundImage: `url(${nflTeamImage(game.homeMascot)})` }} />
                <div className={s.ouSwitch}>
                    <FormCheck type='switch' 
                        label={<span>{game.ouPick === 'Over' ? 'O' : 'U'}<Line showSign={false} line={game.ou} /></span>} 
                        checked={game.ouPick === 'Over'} 
                        onChange={handleSwitchChange} />                    
                </div>
            </div>
        </div>
    )
}
    
export default EnterPostseasonPicksOUItem
    