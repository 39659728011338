// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.RedHerringsPagination__container___Jv1wf .RedHerringsPagination__pageItem___ksdWy .page-link{transition:all ease-in-out .5s}`, "",{"version":3,"sources":["webpack://./src/components/RedHerringsPagination.scss"],"names":[],"mappings":"AAEQ,8FACI,8BAAA","sourcesContent":[".container {\n    .pageItem {\n        :global(.page-link) {\n            transition: all ease-in-out 0.5s;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `RedHerringsPagination__container___Jv1wf`;
export var pageItem = `RedHerringsPagination__pageItem___ksdWy`;
export default ___CSS_LOADER_EXPORT___;
