import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, FormGroup, FormLabel, InputGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getRegistrationPassphrase, saveRegistrationPassphrase } from '@severed-links/common.redherrings-reducers/captureAdmin'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import * as s from './CaptureUpdatePassphrase.scss'

const CaptureUpdatePassphrase = () => {

    const dispatch = useDispatch()
    const _passphrase = useSelector(state => state.captureAdmin.passphrase)
    const [passphrase, setPassphrase] = useState('')

    useEffect(() => { dispatch(getRegistrationPassphrase()) }, [])
    useEffect(() => { setPassphrase(_passphrase) }, [_passphrase])

    const handleChange = e => setPassphrase(e.target.value)

    const save = () => {
        if (passphrase) {
            dispatch(saveRegistrationPassphrase(passphrase))
            .then(action => dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: action.payload.subject, timeout: 6000 })))
        }
    }

    return (
        <div className={s.container}>
            <h4>Contest Registraton Passphrase</h4>
            <FormGroup className={s.formGroup}>
                <InputGroup>
                    <FormControl type='text' value={passphrase || ''}
                        onChange={e => handleChange(e)} />
                    <Button variant='light' onClick={() => save()}><FontAwesomeIcon name='check' /></Button>
                </InputGroup>
            </FormGroup>
        </div>
    )
}

export default CaptureUpdatePassphrase
