import { combineReducers } from 'redux'

import account from '@severed-links/common.redherrings-reducers/account'
import accountDeletion from '@severed-links/common.redherrings-reducers/accountDeletion'
import capture from '@severed-links/common.redherrings-reducers/capture'
import captureAdmin from '@severed-links/common.redherrings-reducers/captureAdmin'
import cinderella from '@severed-links/common.redherrings-reducers/cinderella'
import cinderellaAdmin from '@severed-links/common.redherrings-reducers/cinderellaAdmin'
import cloudinary from '@severed-links/common.redherrings-reducers/cloudinary'
import editors from '@severed-links/common.redherrings-reducers/editors'
import eliminator from '@severed-links/common.redherrings-reducers/eliminator'
import eliminatorAdmin from '@severed-links/common.redherrings-reducers/eliminatorAdmin'
import football from '@severed-links/common.redherrings-reducers/football'
import footballAdmin from '@severed-links/common.redherrings-reducers/footballAdmin'
import forgotPassword from '@severed-links/common.redherrings-reducers/forgotPassword'
import forgotUsername from '@severed-links/common.redherrings-reducers/forgotUsername'
import help from '@severed-links/common.redherrings-reducers/help'
import main from '@severed-links/common.redherrings-reducers/main'
import manageUsers from '@severed-links/common.redherrings-reducers/manageUsers'
import mobileApps from '@severed-links/common.redherrings-reducers/mobileApps'
import notifications from '@severed-links/common.redherrings-reducers/notifications'
import oscars from '@severed-links/common.redherrings-reducers/oscars'
import oscarsAdmin from '@severed-links/common.redherrings-reducers/oscarsAdmin'
import payment from '@severed-links/common.redherrings-reducers/payment'
import siteRegistration from '@severed-links/common.redherrings-reducers/siteRegistration'
import socket from '@severed-links/common.redherrings-reducers/socket'

export const createRootReducer = () => combineReducers({
  account,
  accountDeletion,
  capture,
  captureAdmin,
  cinderella,
  cinderellaAdmin,
  cloudinary,
  editors,
  eliminator,
  eliminatorAdmin,
  forgotPassword,
  forgotUsername,
  football,
  footballAdmin,
  help,
  main,
  manageUsers,
  mobileApps,
  notifications,
  oscars,
  oscarsAdmin,
  payment,
  siteRegistration,
  socket,
})

export default createRootReducer
