import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Overlay, Nav, Tooltip } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './EnterOscarsPicksNavBarItem.scss'

const EnterOscarsPicksNavBarItem = ({ isCurrent = false, step = {}, isDone = false, title = '', category = '', onSelect }) => {

    const dispatch = useDispatch()
    const [showTooltip, setShowTooltip] = useState(false)
    const itemRef = useRef(null)

    useEffect(() => {
        if (isCurrent) {
            scrollToElement()
        }
    }, [isCurrent])

    const setTooltipVisibility = _showTooltip => setShowTooltip(_showTooltip)

    const scrollToElement = () => itemRef.current?.scrollIntoView({ block: 'nearest', inline: 'start', behavior: 'smooth' })

    const className = `${s.container} ${(isCurrent ? s.active : isDone ? s.done : step > 0 && step < 25 ? s.notDone : '')}`

    return (
        <Nav.Item className={className}
            onMouseEnter={() => setTooltipVisibility(true)}
            onMouseLeave={() => setTooltipVisibility(false)}>
            <Nav.Link eventKey={step.toString()} 
                ref={itemRef} 
                disabled={isCurrent}
                onClick={() => !isCurrent ? onSelect(step) : void(0)}>
                    <span>{title}</span>
            </Nav.Link>
            <Overlay target={itemRef.current}
                show={showTooltip} 
                placement='top'>
                {props => (
                <Tooltip id={`tooltip-item-${step}`} {...props}>
                    {category}
                </Tooltip>
                )}
            </Overlay>
        </Nav.Item>
    )
}

export default EnterOscarsPicksNavBarItem