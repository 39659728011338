import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form, FormGroup, FormControl, FormLabel, InputGroup, Button } from 'react-bootstrap'
import * as s from './EliminatorEditConfiguration.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import FormCheck from 'react-bootstrap/FormCheck'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { setActiveSeason, getConfig, setEditConfig } from '@severed-links/common.redherrings-reducers/eliminatorAdmin'
import { getEliminatorDetails } from '@severed-links/common.redherrings-reducers/eliminator'
import { forOwn, isArray } from 'lodash'

const EliminatorEditConfiguration = () => {

    const dispatch = useDispatch()
    const seasonId = useSelector(state => state.eliminator.seasonId)
    const seasonName = useSelector(state => state.eliminator.seasonName)
    const seasons = useSelector(state => state.eliminator.seasons)
    const config = useSelector(state => state.eliminatorAdmin.config) || { seasonConfig: {} }
    const [_config, setConfig] = useState({})
    const { 
        eliminationMode, seasonMode, week, playoffWeek, eliminatorServiceRunning, 
        payPalEventName, payPalEmailAccount, registrationCode, entryFee,
        frontPageMessage, 
    } = _config

    useEffect(() => { setInitState() }, [config])
    useEffect(() => { dispatch(setEditConfig(removeArrays({ ..._config }))) }, [_config])

    const handleChange = e => setConfig({ ..._config, [e.target.name]: !isNaN(e.target.value) ? parseFloat(e.target.value) : e.target.value })

    const handleBootstrapSwitch = (e, checkedState) => setConfig({ ..._config, [e.target.name]: checkedState })

    const removeArrays = obj => {
        forOwn(obj, (value, key) => { if (isArray(value)) delete obj[key] } )
        return obj
    }

    const setInitState = () => setConfig({ ..._config, ...config.seasonConfig, eliminatorServiceRunning: config.seasonConfig.eliminatorServiceRunning.toString().toLowerCase() === 'yes' })

    return (
        config && config.seasonConfig ?
        <Form className={s.container}>
        <Row>
        <Col md={6} xs={12}>
            <FormGroup className={s.formGroup}>
                <FormLabel>Elimination Mode</FormLabel>
                <FormControl as='select' placeholder='[Select format...]'
                    onChange={e => handleChange(e)}
                    name='eliminationMode'
                    value={eliminationMode}>
                {config.seasonConfig.eliminationFormatDropDown.map(s => 
                    <option key={s.value} value={s.value}>{s.text}</option>
                )}
                </FormControl>
            </FormGroup>
        </Col>
        <Col md={6} xs={12}>
            <FormGroup className={s.formGroup}>
                <FormLabel>Season Mode</FormLabel>
                <FormControl as='select' placeholder='[Select season mode...]'
                    onChange={e => handleChange(e)}
                    name='seasonMode'
                    value={seasonMode}>
                {config.seasonConfig.seasonModeDropDown.map(s => 
                    <option key={s.value} value={s.value}>{s.text}</option>
                )}
                </FormControl>
            </FormGroup>
        </Col>
        <Col md={6} xs={12}>
            <FormGroup className={s.formGroup}>
                <FormLabel>Active Regular Season Week</FormLabel>
                <FormControl placeholder='[Week]' type='number'
                    onChange={e => handleChange(e)}
                    name='week'
                    value={week} />
            </FormGroup>
        </Col>
        <Col md={6} xs={12}>
            <FormGroup className={s.formGroup}>
                <FormLabel>Active Postseason Week</FormLabel>
                <FormControl placeholder='[Playoff Week]' type='number'
                    onChange={e => handleChange(e)}
                    name='playoffWeek'
                    value={playoffWeek} />
            </FormGroup>
        </Col>
        <Col md={6} xs={12}>
            <FormGroup className={s.formGroup}>
                <FormLabel style={{ display: 'block' }}>Service running?</FormLabel>
                <FormCheck type='switch' onChange={e => handleBootstrapSwitch(e, e.target.checked)} 
                    name='eliminatorServiceRunning' 
                    checked={eliminatorServiceRunning} />
            </FormGroup>
        </Col>
        <Col md={6} xs={12}>
            <FormGroup className={s.formGroup}>
                <FormLabel>PayPal Event Name</FormLabel>
                <FormControl placeholder='[PayPal Event Name...]'
                    onChange={e => handleChange(e)}
                    name='payPalEventName'
                    value={payPalEventName} />
            </FormGroup>
        </Col>
        <Col md={6} xs={12}>
            <FormGroup className={s.formGroup}>
                <FormLabel>PayPal Email Account</FormLabel>
                <FormControl placeholder='[PayPal Payment Email...]' type='email'
                    onChange={e => handleChange(e)}
                    name='payPalEmailAccount'
                    value={payPalEmailAccount} />
            </FormGroup>
        </Col>
        <Col md={6} xs={12}>
            <FormGroup className={s.formGroup}>
                <FormLabel>Registration Code</FormLabel>
                <FormControl placeholder='[Registration Code...]'
                    onChange={e => handleChange(e)}
                    name='registrationCode'
                    value={registrationCode} />
            </FormGroup>
        </Col>
        <Col md={6} xs={12}>
            <FormGroup className={s.formGroup}>
                <FormLabel>Entry Fee</FormLabel>
                <FormControl placeholder='[Entry Fee]' type='number'
                    onChange={e => handleChange(e)}
                    name='entryFee'
                    value={entryFee} />
            </FormGroup>
        </Col>
        <Col xs={12}>
            <FormGroup className={s.formGroup}>
                <FormLabel>Front Page Message</FormLabel>
                <FormControl placeholder='[Front Page Message...]' type='text'
                    onChange={e => handleChange(e)}
                    name='frontPageMessage'
                    value={frontPageMessage || ''} />
            </FormGroup>
        </Col>
        </Row>
        </Form>
        : null
    )
}

export default EliminatorEditConfiguration