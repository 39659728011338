import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import CloudinaryImage from '../Cloudinary/CloudinaryImage'
import { Badge } from 'react-bootstrap'
import * as s from './NflTeam.scss'

const Line = ({ line = 0.0, showSign = true }) => {

    return (
        <span>
            {showSign && line > 0 ? '+' : null}
            {showSign && line < 0 ? '-' : null}
            {line === 0 ? 'PK' : null}
            {line !== 0 ?
                !isNaN(line) && line !== parseInt(line, 10) ? (Math.floor(Math.abs(line)) >= 1 ? parseInt(Math.abs(line),10).toString() : '') + '\u00BD' : Math.abs(line).toString()
            : null}
        </span>
    )
}

export default Line