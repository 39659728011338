import React, { useEffect, useState } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, FormControl, InputGroup, ButtonGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import CinderellaAdminRound1Dates from './CinderellaAdminRound1Dates'
import NCAATeam from '../NCAATeam/NCAATeam'
import { getBracketBuilderTeams, getRegions, setBracketBuilderRegions, getTeamsFromEspnWebsite } from '@severed-links/common.redherrings-reducers/cinderellaAdmin'
import { filter, some, forEach } from 'lodash'
import * as s from './CinderellaAdminBracketBuilder.scss'
import CinderellaTeamNameTypeahead from './CinderellaTeamNameTypeahead'

const CinderellaAdminBracketBuilder = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const espnTeams = useSelector(state => state.cinderellaAdmin.espnTeams)
    const regions = useSelector(state => state.cinderellaAdmin.bracketBuilderRegions)
    const brackets = useSelector(state => state.cinderellaAdmin.bracketBuilderTeams)
    const bracketBuilderTournamentDates = useSelector(state => state.cinderellaAdmin.bracketBuilderTournamentDates)
    const bracketBuilderTournamentDatesLoaded = useSelector(state => state.cinderellaAdmin.bracketBuilderTournamentDatesLoaded)
    const [isLoadingBrackets, setIsLoadingBrackets] = useState(false)
    const year = parseInt(params.year || '0')

    useEffect(() => {
        if (year) {
            getTourneyRegions()
            getBracketBuilder()
        }
    }, [year])

    const getTourneyRegions = () => {
        dispatch(getRegions(year))
        .then(action => dispatch(setBracketBuilderRegions(action.payload)))
    }

    const getBracketBuilder = () => {
        setIsLoadingBrackets(true)
        dispatch(getBracketBuilderTeams(year))
        .then(action => setIsLoadingBrackets(false))
    }

    const refresh = () => {
        getBracketBuilder()
        dispatch(getTeamsFromEspnWebsite(year))
    }

    const filteredEspnTeams = filter(espnTeams, t => !some(brackets, b => b.teamName === t.teamName && b.seed === t.seed))
    const _isStartDateNeeded = bracketBuilderTournamentDatesLoaded && !bracketBuilderTournamentDates.some(i => i.year === year)
    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}>Bracket Builder {year}</h2>
                <div className={s.controls}>
                    <ButtonGroup>
                    <Button variant={'light'} onClick={() => refresh()}><FontAwesomeIcon name='rotate' /></Button>
                    <Button variant={_isStartDateNeeded ? 'danger' : 'light'} onClick={() => navigate('/cinderella/admin/tournament/start-dates')}><FontAwesomeIcon name='calendar' /> update start dates</Button>
                    </ButtonGroup>
                </div>
            </div>
            <CinderellaAdminRound1Dates year={year} />
            <Row>
            {!isLoadingBrackets && brackets && brackets.length > 0 && regions && regions.length > 0 ?
                regions && regions.map((r, index) => 
                    <Col lg={2} md={2} sm={3} xs={6} key={index}>
                        <h4 className={s.bracketBuilderRegionHeader} title={`${r.regionName} Region`}>{r.regionName} Region</h4>
                        <div className={s.bracketBuilderRegionContainer}>
                        {filter(brackets, b => b.region === r.region && b.seed > 0).map((t, i2) =>
                            <CinderellaTeamNameTypeahead key={'region-' + r.region + '-' + i2} 
                                index={((r.region - 1) * 16) + i2} 
                                teamName={t.teamName} avatarUrl={t.avatarUrl}
                                seed={t.seed} teamId={t.teamId} region={t.region} year={year} />
                        )}
                        </div>
                    </Col>
                )
            :
            <Col md={8} sm={12} xs={6}>
                <div style={{ textAlign: 'center', padding: '50px', display: 'table-cell', verticalAlign: 'middle' }}>
                    <FontAwesomeIcon name='circle-notch' spin size='3x' className='text-muted' />
                </div>
            </Col>
            }
            {espnTeams ? 
                <Col lg={4} md={4} sm={12} xs={12}>
                    <h4 className='text-muted'>Unmatched ESPN Teams ({filteredEspnTeams.length})</h4>
                    <Row>
                    {filteredEspnTeams.map((t, index) =>
                        <Col key={index} xs={6}>
                            <NCAATeam teamId={index} seed={t.seed} name={t.teamName} 
                                avatarUrl={t.avatarUrl} showAvatar 
                                size='sm' isAlive={false} />
                        </Col>
                    )}
                    </Row>
                </Col> : null}
            </Row> 
        </div>
    )
}

export default CinderellaAdminBracketBuilder