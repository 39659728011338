import React, { useEffect } from 'react'
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import * as s from './EliminatorStandings.scss'
import { nflTeamImage, img } from '@severed-links/common.redherrings-constants'

const EliminatorStandingsPickItem = ({ pick }) => (
    <div className={s.pick + (pick.isAfterDeadline && pick.isFinalScore && pick.isCorrectPick ? ' ' + s.isCorrectPick : pick.isAfterDeadline && pick.isFinalScore && !pick.isCorrectPick ? ' ' + s.isIncorrectPick : '')}>
        <OverlayTrigger placement={'top'} overlay={<Tooltip id={`tooltip-user-week-pick-${pick.teamId}`}>{pick.weekDisplay}: {pick.nflTeam ? <strong>{pick.nflTeam}</strong> : <span>None</span>}</Tooltip>}>
            <div className={s.pickContent}>
                <Image src={(pick.mascot ? nflTeamImage(pick.mascot, true) : `${img.BaseUrl}/${img.CloudinaryNoImage}`)} className={s.image} />
                <div className={s.badge}>
                    {pick.weekDisplay}
                </div>
            </div>
        </OverlayTrigger>
    </div>
)

export default EliminatorStandingsPickItem