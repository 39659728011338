import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import BaseAuthenticatedMenu from './BaseAuthenticatedMenu'
import { Nav } from 'react-bootstrap'
import { img } from '@severed-links/common.redherrings-constants'
import CloudinaryImage from '../routes/Cloudinary/CloudinaryImage'
import { VisibleOnlyToCaptureAdmin } from '../components/Authorization'
import * as s from './Menu.scss'
import { getActiveContest, getWinners, getTourneyDays } from '@severed-links/common.redherrings-reducers/capture'
import moment from 'moment-timezone'
import { find, filter, first, last, orderBy, some } from 'lodash'

const CaptureMenu = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const contestId = params.contestId || null
    const isAuthenticated = useSelector(state => state.account.isAuthenticated)
    const activeContestId = useSelector(state => state.capture.activeContestId)
    const isHome = location.pathname.toLowerCase() === '/capture'

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getActiveContest())
        }
    }, [isAuthenticated])

    useEffect(() => {
        if (contestId && contestId.toLowerCase() === 'user-profile') return 

        if (contestId && isAuthenticated) {
            dispatch(getWinners(contestId))
            dispatch(getTourneyDays(contestId))
        } else if (activeContestId && isAuthenticated) {
            navigate(`/capture/${activeContestId}`, { replace: true })
        }
    }, [isAuthenticated, activeContestId, contestId])

    const navbarBrand = (       
        <Link to={{ pathname: `/capture${contestId ? `/${contestId}` : ''}` }}>
            <CloudinaryImage publicId={img.CaptureHeader} />
        </Link>
    )

    const AdminOnlyNavItem = () => <VisibleOnlyToCaptureAdmin><Nav.Item className={s.item} key={5}><Nav.Link eventKey={5} onClick={() => navigate('/capture/admin')}>Admin</Nav.Link></Nav.Item></VisibleOnlyToCaptureAdmin>

    const menuItems = contestId ? [
        <Nav.Item className={s.item} key={1}><Nav.Link eventKey={1} onClick={() => navigate(`/capture/${contestId}/tourney-games`)}>Tourney Games</Nav.Link></Nav.Item>,
        <Nav.Item className={s.item} key={2}><Nav.Link eventKey={2} onClick={() => navigate(`/capture/${contestId}/scoreboard`)}>Scoreboard</Nav.Link></Nav.Item>,
        <AdminOnlyNavItem key={5} />
    ] : []
    return (
        <BaseAuthenticatedMenu backgroundImageUrl={img.CloudinaryBaseUrl + img.CaptureHeaderBackground}
            navbarBrand={navbarBrand} 
            menuItems={menuItems} />
    )
}

export default CaptureMenu
