import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getCaptureLog } from '@severed-links/common.redherrings-reducers/capture'
import CaptureLog from './CaptureLog'
import * as s from './CaptureGameLog.scss'

const CaptureGameLog = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const contest = useSelector(state => state.capture.contest)
    const captureLog = useSelector(state => state.capture.captureLog)
    const contestId = params.contestId || null

    useEffect(() => { getLog() }, [contestId])

    const getLog = () => {
        if (contestId) {
            dispatch(getCaptureLog(contestId))
        }
    }

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <div className={s.backIcon}>
                    <Button variant='light' onClick={() => navigate(`/capture/${contestId}/scoreboard`)}><FontAwesomeIcon name='chevron-left' /></Button>
                </div>
                <h2 className={s.title}>Game Log</h2>
            </div>
            <CaptureLog captureLog={captureLog} />
        </div>
    )
}

export default CaptureGameLog