import React from 'react'
import { useLocation, useParams, Navigate, Outlet, useMatch, useOutletContext } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { capitalize } from 'lodash'

const AuthenticatedRoute = ({ allowedRoles = [], children = null, doLogging = false, ...otherProps }) => {

    let location = useLocation()
    let params = useParams()
    let _context = useOutletContext() || {}
    let { isAuthenticated, roles: userRoles, hasCheckedToken, isMyProfileLoaded } = useSelector(state => state.account)
    let _noRolesNeeded = !allowedRoles.length
    let _hasAllowedRoles = userRoles.some(r => allowedRoles.includes(r))
    let _userIsPermitted = isAuthenticated && (_noRolesNeeded || _hasAllowedRoles)

    if (doLogging) {
        console.log({ isAuthenticated, hasCheckedToken, allowedRoles, userRoles, _noRolesNeeded, _hasAllowedRoles, _userIsPermitted, otherProps })
    }

    if (!isAuthenticated && !isMyProfileLoaded) {
        if (doLogging) {
            console.log('Routing back to login...')
        }
        return <Navigate to='/login' state={{ from: location }} />
    } else if (isAuthenticated && isMyProfileLoaded && !_noRolesNeeded && !_hasAllowedRoles) {
        if (doLogging) {
            console.log('Routing to main...')
        }
        return <Navigate to='/main' state={{ from: location }} />
    }
    if (doLogging) console.log('Got to outlet!')
    return (
        <Outlet context={_context} />
    )
  }

  export default AuthenticatedRoute
  