// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EliminatorIntro__instructions___HnUDf{font-size:1.2em}.EliminatorIntro__rulesList___DlOYo li{margin-bottom:1em}.EliminatorIntro__bottomMargin___RvOHQ{margin-bottom:1.5rem}.EliminatorIntro__bottomMarginCenter___EguT8{margin-bottom:1.5rem;text-align:center}.EliminatorIntro__alertLineItem___pGleJ{margin-bottom:1.5rem;text-align:center}.EliminatorIntro__sidePanel___IXttH{margin-top:30px}.EliminatorIntro__remindersContainer___jzeNS{margin:1.5rem 0px}`, "",{"version":3,"sources":["webpack://./src/routes/Eliminator/EliminatorIntro.scss"],"names":[],"mappings":"AAMA,uCACI,eAAA,CAIA,uCACI,iBAAA,CAIR,uCACI,oBAAA,CAGJ,6CACI,oBAAA,CACA,iBAAA,CAGJ,wCACI,oBAAA,CACA,iBAAA,CAGJ,oCACI,eAAA,CAGJ,6CACI,iBAAA","sourcesContent":["@use '../../styles/variables/colors' as colors;\n\n.container {\n    \n}\n\n.instructions {\n    font-size: 1.2em;\n}\n\n.rulesList {\n    li {\n        margin-bottom:1em;\n    }\n}\n\n.bottomMargin {\n    margin-bottom: 1.5rem;\n}\n\n.bottomMarginCenter {\n    margin-bottom: 1.5rem;\n    text-align: center;\n}\n\n.alertLineItem {\n    margin-bottom: 1.5rem;\n    text-align: center;\n}\n\n.sidePanel {\n    margin-top: 30px;\n}\n\n.remindersContainer {\n    margin: 1.5rem 0px;\n}"],"sourceRoot":""}]);
// Exports
export var instructions = `EliminatorIntro__instructions___HnUDf`;
export var rulesList = `EliminatorIntro__rulesList___DlOYo`;
export var bottomMargin = `EliminatorIntro__bottomMargin___RvOHQ`;
export var bottomMarginCenter = `EliminatorIntro__bottomMarginCenter___EguT8`;
export var alertLineItem = `EliminatorIntro__alertLineItem___pGleJ`;
export var sidePanel = `EliminatorIntro__sidePanel___IXttH`;
export var remindersContainer = `EliminatorIntro__remindersContainer___jzeNS`;
export default ___CSS_LOADER_EXPORT___;
