import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormControl, FormGroup, FormLabel } from 'react-bootstrap'
import * as s from './FootballSetSiteRegistration.scss'
import FormCheck from 'react-bootstrap/FormCheck'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { setSiteRegistration, getConfig } from '@severed-links/common.redherrings-reducers/footballAdmin'
import { getFootballDetails } from '@severed-links/common.redherrings-reducers/football'
import { isEqual } from 'lodash'

const FootballSetSiteRegistration = () => {

    const dispatch = useDispatch()
    const seasonName = useSelector(state => state.football.seasonName)
    const config = useSelector(state => state.footballAdmin.config)

    const [isOpen, setOpen] = useState(false)

    useEffect(() => { setInitialStateOfSwitch() }, [((config || {}).globalConfig || {}).playerRegistration])

    const setInitialStateOfSwitch = () => {
        var isOpen = ((config || {}).globalConfig || {}).playerRegistration === 'Open' // 'Open' || 'CLosed'
        setOpen(isOpen)
    }

    const doSetSiteRegistration = (e, checkedState) => {
        setOpen(checkedState)
        dispatch(setSiteRegistration(checkedState ? 'Open' : 'Closed'))
        .then(resp => {
            dispatch(createNotification({ 
                type: resp.payload.messageType, 
                headline: 'Set Site Registration',
                message: resp.payload.message, 
                timeout: 6000 
            }))
            dispatch(getFootballDetails(true))
            dispatch(getConfig(seasonName))
        })
    }

    return (
        <FormGroup key={`football-admin-set-site-registration`} className={s.container}>
            <FormCheck type='switch' onChange={e => doSetSiteRegistration(e, e.target.checked)}
                label='Site reg?'
                name='isOpen' checked={isOpen} />
        </FormGroup>
    )
}

export default FootballSetSiteRegistration