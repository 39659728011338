import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, ListGroup, ListGroupItem } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './PickReminderSelector.scss'
import Modal from '@severed-links/common.modal'
import { checkForMobileDevices } from '@severed-links/common.redherrings-reducers/mobileApps'
import { REMINDER_BUTTONS } from '@severed-links/common.redherrings-constants'

const PickReminderSelector = ({ buttonVariant = 'info', sendEmailReminder = false, sendTextReminder = false, sendPushReminder = false, onChange = null }) => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const userHasMobileDevices = useSelector(state => state.mobileApps.userHasMobileDevices)
    const [show, setShow] = useState(false)
    const [selectedIcons, setSelectedIcons] = useState([])
    const _icons = REMINDER_BUTTONS.map(i => ({
        ...i, 
        showIcon: i.fieldName === 'sendEmailReminder' ? sendEmailReminder
            : i.fieldName === 'sendTextReminder' ? sendTextReminder 
            : i.fieldName === 'sendPushReminder' ? sendPushReminder
            : false,
        isSelected: selectedIcons.some(x => x.fieldName === i.fieldName),
    })).filter(i => userHasMobileDevices || i.fieldName !== 'sendPushReminder')
    
    const _saveData = {
        sendEmailReminder: selectedIcons.some(i => i.isEmail),
        sendTextReminder: selectedIcons.some(i => i.isText),
        sendPushReminder: selectedIcons.some(i => i.isPush),
    }

    useEffect(() => {
        resetToDefaults()
    }, [sendEmailReminder, sendTextReminder, sendPushReminder])

    useEffect(() => {
        dispatch(checkForMobileDevices())
    }, [])

    const _variant = _icons.filter(i => i.showIcon).length ? buttonVariant : 'light'

    const resetToDefaults = () => {
        setSelectedIcons(_icons.filter(i => (i.isEmail && sendEmailReminder) || (i.isText && sendTextReminder) || (i.isPush && sendPushReminder)))
    }

    const showModal = () => {
        resetToDefaults()
        setShow(true)
    }

    const hideModal = () => {
        setShow(false)
        resetToDefaults()
    }

    const handleSelectionChange = _icon => {
        var _selectedIcons = [...selectedIcons]
        const _isSelected = _selectedIcons.some(i => i.icon === _icon)
        if (_isSelected) {
            _selectedIcons = _selectedIcons.filter(i => i.icon !== _icon)
        } else {
            _selectedIcons.push(_icons.find(i => i.icon === _icon))
        }
        setSelectedIcons(_selectedIcons)
    }

    const handleSave = () => {
        if (onChange) {
            onChange(_saveData)
            setShow(false)
        }
    }

    return (
        <div className={s.container}>
            <Button variant={_variant} className={s.button} onClick={() => showModal()}>
                <div className={s.title}>Set pick reminders</div>
                <div className={s.iconContainer}>
                {_icons && _icons.filter(i => i.showIcon).map(i =>
                <div className={s.icon} key={`reminder-button-icon-${i.icon}`}>
                    <FontAwesomeIcon name={i.icon} />
                </div>
                )}
                </div>
            </Button>
            <Modal show={show} heading='Select Reminder Types'
                onClose={() => hideModal()}
                actionButtonText='save reminder types'
                actionButtonIcon='check'
                actionButtonStyle='primary'
                actionButtonOnClick={() => handleSave()}>
            <ListGroup className={s.reminderTypeList}>
            {_icons && _icons.map(i => 
            <ListGroup.Item className={s.item} action
                onClick={() => handleSelectionChange(i.icon)}
                key={`reminder-type-${i.icon}`}>
                <div className={s.leftIcon}><FontAwesomeIcon name={i.isSelected ? 'check-circle' : 'circle'} isRegular={!i.isSelected} /></div>
                <div className={s.title}>{i.title}</div>
                <div className={s.rightIcon}><FontAwesomeIcon name={i.icon} /></div>                
            </ListGroup.Item>
            )}
            </ListGroup>
            </Modal>
        </div>
    )
}

export default PickReminderSelector
