import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { IoProvider } from 'socket.io-react-hook'

import 'normalize.css/normalize.css'
import 'bootstrap/scss/bootstrap.scss'
import * as s from './CoreLayout.scss'
import '../../styles/core.scss'
import 'react-datetime/css/react-datetime.css'
import 'react-bootstrap-typeahead/css/Typeahead.css'
import './Typeahead-bs4.scss'


import NotificationsContainer from '../../routes/Notifications/NotificationsContainer'

import { getCloudinaryConfig } from '@severed-links/common.redherrings-reducers/cloudinary'

import { setPlayerId as setHelpPlayerId } from '@severed-links/common.redherrings-reducers/help'

import ContentPanel from '../ContentPanel/ContentPanel'
import MenuPanel from '../MenuPanel/MenuPanel'
import SocketClient from '../../routes/Socket.io/SocketClient'
import ScrollToTop from '@severed-links/common.scroll-to-top'
import Container from 'react-bootstrap/Container'
import TokenWatcher from '../../routes/Account/TokenWatcher'

const CoreLayout = ({ menu = null, content = null, children = null }) => {

  const dispatch = useDispatch()
  const location = useLocation()
  const { _id: playerId } = useSelector(state => state.account)

  useEffect(() => { dispatch(getCloudinaryConfig()) }, [])
  useEffect(() => { dispatch(setHelpPlayerId(playerId || null)) }, [playerId])

  return (
    <IoProvider>
      <TokenWatcher />
      <SocketClient />
      <Container className={s.appLayout} fluid>
        <ScrollToTop pathname={location.pathname} />
        <MenuPanel />
        <NotificationsContainer />
        <ContentPanel />
      </Container>
    </IoProvider>
  )
}

export default CoreLayout
