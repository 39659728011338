import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import StepZilla from 'react-stepzilla'
import { resetForgotUsername, setUserInfo, requestUsername } from '@severed-links/common.redherrings-reducers/forgotUsername'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'

import '../SiteRegistration/ProgressTracker.scss'
import * as s from '../ForgotPassword/ForgotPassword.scss'

import ForgotUsernameStep1 from './ForgotUsernameStep1'
import ForgotUsernameStep2 from './ForgotUsernameStep2'
import ForgotUsernameConfirmation from './ForgotUsernameConfirmation'

const ForgotUsername = () => {

    const dispatch = useDispatch()
    const fu = useSelector(state => state.forgotUsername)
    
    useEffect(() => { dispatch(resetForgotUsername()) }, [])

    const steps = [
        { name: 'Send Method', component: <ForgotUsernameStep1 /> },
        { name: fu.sendBy || 'Number', component: <ForgotUsernameStep2  /> },
        { name: 'Confirmation', component: <ForgotUsernameConfirmation />}
    ]
    return (
        <Row>
            <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 10, offset: 1 }} lg={{ span: 8, offset: 2 }}>
                <h2>Forgot your user name?</h2>
                <div className={s.forgotPasswordContainer}>
                    <div className='step-progress'>
                        <StepZilla
                            steps={steps}
                            preventEnterSubmission={false}
                            nextTextOnFinalActionStep={'Send'}
                            // backButtonText={<span><FontAwesomeIcon name='chevron-left' /> Back</span>}
                            backButtonText={'Back'}
                            backButtonCls={`btn btn-prev btn-light btn-lg`}
                            // nextButtonText={<span>Next <FontAwesomeIcon name='chevron-right' /></span>}
                            nextButtonText={'Next'}
                            nextButtonCls={`btn btn-next btn-light btn-lg${(fu.nextDisabled ? ' ' + s.nextDisabled : ``)}`}
                        />
                    </div>
                </div>
            </Col>
        </Row>
    )
}

export default ForgotUsername
