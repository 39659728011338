import React, { useEffect, useState } from 'react'
import { ListGroup, Image } from 'react-bootstrap'
import {  } from '@severed-links/common.redherrings-reducers/cinderella'
import { find } from 'lodash'
import * as s from './RecapEntriesByYear.scss'
import { img } from '@severed-links/common.redherrings-constants'

const RecapEntriesByYear = ({ yearlyEntries = [] }) => {

    const [isChanged, setChanged] = useState(false)
    const currentYearItem = find(yearlyEntries, { isCurrentYear: true }) || {}
    const _currentYearCount = currentYearItem.countOfEntryId || 0

    useEffect(() => {
        setChanged(true)
        setTimeout(() => setChanged(false), 2000)
    }, [_currentYearCount])

    return (
        <div className={s.container}>
            {yearlyEntries.length ?
            <div>
                <h4>Entries By Year</h4>
                <ListGroup className={s.list}>
                    {yearlyEntries && yearlyEntries.length > 5 ?<div className={s.background} style={{ backgroundImage: `url(${img.CloudinaryBaseUrl + img.CinderellaWithBall})` }} /> : null}
                    {yearlyEntries.map((entry, index) =>
                        <ListGroup.Item key={`yearly-count-${entry.groupByOfYear}`} className={s.item + ' ' + (entry.isCurrentYear ? s.isCurrentYear : '') + ' ' + (isChanged ? s.isChanged : '')}>
                            <div className={s.year}>{entry.groupByOfYear}{entry.isCovid ? <Image src={`${img.CloudinaryBaseUrl}${img.CovidIcon}`} className={s.covidIcon} /> : null}</div>
                            <div className={s.count}>{entry.countOfEntryId}</div>
                        </ListGroup.Item>
                    )}
                </ListGroup>
            </div>
            : null}
        </div>
    )
}

export default RecapEntriesByYear
