import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col, Table, Badge, ListGroup, Image } from 'react-bootstrap'
import { getMatchup, getLeagueScoreboard, getNflScoreboard } from '@severed-links/common.redherrings-reducers/football'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Line from '../Line'
import PlayerTeam from '../PlayerTeam'
import NflTeam from '../NflTeam'
import * as s from './MatchUp.scss'
import { forEach, find, flatten, startsWith, endsWith } from 'lodash'
import { getFootballUrlParams, teamUrl, playerUrl } from '@severed-links/common.redherrings-constants'
import LeagueScoreboardGame from './LeagueScoreboardGame'
import moment from 'moment-timezone'
import CloudinaryImage from '../../Cloudinary/CloudinaryImage'
import { trim, last } from 'lodash'

const Score = ({ score }) => (
    score !== null ? <div className={s.scoreContainer}>{score}</div> : null
)

const GameDate = ({ date, scoreType, gameClock, gameQuarter, isOT, isInProgress, isFinal }) => {
    const _showGameClock = !!gameClock && !!gameQuarter && isInProgress && !isFinal
    const _showFinal = isFinal
    const _showGameTime = !_showGameClock && !_showFinal
    return (
        <div className={s.gameTimeContainer}>
            {_showGameTime ?
            <>
            <div className={s.value}>{moment(date).local().format('h:mm')}</div>
            <div className={s.heading}>{moment(date).local().format('A')}</div>
            </>
            : null}
            {_showGameClock ?
            <>
            <div className={s.value}>{gameClock}</div>
            {isOT ? <div className={s.heading}>{gameQuarter}</div> : null}
            </>
            : null}
            {_showFinal ?
            <>
            <div className={s.value}>Final</div>
            {isOT ? <div className={s.heading}>OT</div> : null}
            </>
            : null}
        </div>
    )
}

const NflAvatar = ({ mascot, isRight }) => (
    <div className={s.nflAvatarContainer + (isRight ? ` ${s.right}` : '')}>
        <CloudinaryImage publicId={`/football/nfl-team-small-avatars/${(mascot || '').toLowerCase()}`} />
    </div>
)

const NflTeamItem = ({ city, mascot, teamName, abbreviation, record, isRight, isHome }) => (
    <div className={s.teamNameContainer + (isRight ? ` ${s.right}` : '')}>
        <div className={s.abbreviation}>{(abbreviation || '').toUpperCase()}</div>
        <div className={s.city}>{trim(city || (teamName || '').replace(mascot, ''))}</div>
        <div className={s.mascotContainer}>
            {isRight && isHome ?
            <div className={s.homeIconContainer}>
                <FontAwesomeIcon name='home' />
            </div>
            : null}
            {record && isRight ? <span className={s.record}>{record}</span> : null}
            <div className={s.mascot}>{mascot}</div>
            {record && !isRight ? <span className={s.record}>{record}</span> : null}
            {!isRight && isHome ?
            <div className={s.homeIconContainer}>
                <FontAwesomeIcon name='home' />
            </div>
            : null}
        </div>
    </div>
)

const PlayerPicks = ({ picks = [], isRight, result = null, isComplete = false, isInProgress = false, isBeforeKickoff = false }) => (
    <div className={s.picksContainer + (isRight ? ` ${s.right}` : '')}>
    {picks && picks.map(i =>
        <div className={s.pickItem} key={`matchup-player-pick-${i._id}`} style={{ backgroundImage: `url(${teamUrl(i.teamAvatarUrl)})` }}>
            <Badge className={s.rank + (isComplete ? ` ${s.complete}` : '') + (result ? (i.isPickCorrect ? ` ${s.correct}` : ` ${s.incorrect}`) : '')}>{i.rank}</Badge>
        </div>
    )}
    </div>
)

const LineContainer = ({ line }) => (
    <div className={s.lineContainer}>
        <div className={s.heading}>Line</div>
        <div className={s.value}><Line line={line} showSign={false} /></div>
    </div>
)

const MatchUp = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const playerId = useSelector(state => state.account._id)
    const seasons = useSelector(state => state.football.seasons)
    const _seasonCount = (seasons || []).length
    const matchUps = useSelector(state => state.football.matchUps)
    const leagueScoreboards = useSelector(state => state.football.leagueScoreboards)
    const nflScoreboards = useSelector(state => state.football.nflScoreboards)
    const c = getFootballUrlParams(params, seasons)
    const matchup = find(matchUps, x => x.seasonId === c.seasonId && x.week === c.week && x.game === c.game)
    const nflSb = nflScoreboards.find(x => x.seasonId === c.seasonId && x.weekNumber === c.week)
    const sb = (leagueScoreboards.find(x => x.seasonId === c.seasonId && x.week === c.week) || {}).scoreboard || []
    const scoreboardItem = sb.find(x => x.game === c.game) || {}
    const score1 = scoreboardItem.score1 || 0
    const score2 = scoreboardItem.score2 || 0
    const nflScores = flatten(((nflSb || {}).gameDays || []).map(x => x.games || [])).map(x => `${x.visitorScore || 0}-${x.homeScore || 0}`).join(',')

    useEffect(() => { fetchMatchup() }, [c.seasonName, c.week, c.game, score1, score2, nflScores])
    useEffect(() => { (!nflSb || !nflSb.week) && c.seasonId && c.week ? dispatch(getNflScoreboard(c.seasonId, c.week)) : null }, [nflSb, c.seasonId, c.week])
    useEffect(() => { (!sb || !sb.length) && c.seasonName && c.week ? dispatch(getLeagueScoreboard(c.seasonName, c.week)) : null }, [sb, c.seasonName, c.week])

    const fetchMatchup = () => c.seasonName && c.week && c.game ? dispatch(getMatchup(c.seasonName, c.week, c.game)) : null

    const getField = (picks, field, game) => {
        const _pick = picks.find(p => p.game === game)
        return (_pick || {})[field] || ''
    }

    if (!matchup) return null
    const { nflGames, team1, team2, picks1, picks2, seasonName, seasonId, week, game } = matchup
    var scoreboardOrder = ['2','1']
    if (team1 && team2 && scoreboardItem && scoreboardItem._id1 === matchup.team1._id) {
        scoreboardOrder = ['1', '2']
    }
    const cow1 = scoreboardItem ? scoreboardItem[`cow${scoreboardOrder[0]}`] : 0.00
    const cow2 = scoreboardItem ? scoreboardItem[`cow${scoreboardOrder[1]}`] : 0.00
    const cot = scoreboardItem ? scoreboardItem.cot : 0.00
    return (
        <div className={s.container}>
            {matchup && matchup.team1 && matchup.team2 ?
            <div className={s.matchupContainer}>
                
                <h2>{c.seasonName} Week {c.week} Matchup: {matchup.team1.teamName} vs. {matchup.team2.teamName}</h2>
                
                <div className={s.largeScoreboard}>
                    <LeagueScoreboardGame seasonName={seasonName} 
                        game={scoreboardItem} isLarge
                        showHeader={false} showFooter />
                </div>
                <div className={s.smallScoreboard}>
                    <LeagueScoreboardGame seasonName={seasonName} 
                        game={scoreboardItem} isLarge={false}
                        showHeader={false} showFooter />
                </div>

                <ListGroup className={s.nflGamesList}>
                <ListGroup.Item className={s.nflFavoriteUnderdogHeaderItem}>
                    <div className={s.blankGameTime} />
                    <div className={s.icon}><FontAwesomeIcon name='arrow-left' /></div>
                    <div className={s.title}>Favorites</div>
                    <div className={s.title + ' ' + s.right}>Underdogs</div>
                    <div className={s.icon + ' ' + s.right}><FontAwesomeIcon name='arrow-right' /></div>
                </ListGroup.Item>
                {nflGames && nflGames.map((i, _index) => [
                _index === 0 || !moment(i.date).local().isSame(moment(nflGames[_index - 1].date).local(), 'day') ? 
                <ListGroup.Item className={s.miniHeaderItem} key={`matchup-${seasonId}-week-${week}-game-${i.game}-mini-date-header`}>{moment(i.date).local().format('dddd, MMMM D, YYYY')}</ListGroup.Item>
                : null,
                <ListGroup.Item className={s.nflGamesListItem} key={`matchup-${seasonId}-week-${week}-game-${i.game}`}>
                    <div className={s.content}>
                        <GameDate date={i.date} scoreType={i.scoreType} 
                            gameClock={i.gameClock} gameQuarter={i.gameQuarter} 
                            isInProgress={i.isInProgress} 
                            isFinal={i.isComplete} isOT={i.isOT} />
                        <NflAvatar mascot={i.favoriteMascot} />
                        <NflTeamItem abbreviation={i.favoriteAbbrev} city={i.favoriteCity}
                            mascot={i.favoriteMascot} teamName={i.favoriteTeam} record={i.favoriteRecord}
                            isHome={i.isHomeFavorite} />
                        <PlayerPicks picks={i.favoritePicks} result={i.result} isComplete={i.isComplete} isInProgress={i.isInProgress} isBeforeKickoff={i.isBeforeKickoff} />
                        <Score score={i.favoriteScore} />
                        <LineContainer line={i.line} />
                        <Score score={i.underdogScore} />
                        <PlayerPicks picks={i.underdogPicks} result={i.result} isComplete={i.isComplete} isInProgress={i.isInProgress} isBeforeKickoff={i.isBeforeKickoff} />
                        <NflTeamItem abbreviation={i.underdogAbbrev} city={i.underdogCity} isRight
                            mascot={i.underdogMascot} teamName={i.underdogTeam} record={i.underdogRecord}
                            isHome={!i.isHomeFavorite} />
                        <NflAvatar mascot={i.underdogMascot} isRight />
                    </div>
                </ListGroup.Item>
                ])}
                </ListGroup>

            </div>
            : null}
        </div>
    )
}

export default MatchUp