import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { Row, Col, Button, FormGroup, FormControl, FormLabel, Form } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from '../RegularSeason/EnterPicks.scss'
import moment from 'moment-timezone'
import { getEnterPlayoffPicks, enterPostseasonPicksCommand, 
    savePostseasonPicks, getPlayoffs, setPickTeamId,
    setPostseasonPickDirtyState } from '@severed-links/common.redherrings-reducers/football'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { orderBy, some, isEqual, first } from 'lodash'
import EnterPostseasonPicksGameContainer from './EnterPostseasonPicksGameContainer'
import EnterPostseasonPicksGameItem from './EnterPostseasonPicksGameItem'
import EnterPostseasonPicksOUContainer from './EnterPostseasonPicksOUContainer'
import EnterPostseasonPicksOUItem from './EnterPostseasonPicksOUItem'
import EnterPostseasonPicksAutoGenerationAlert from './EnterPostseasonPicksAutoGenerationAlert'

const EnterPostseasonFootballPicks = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const playerId = useSelector(state => state.account._id)
    const seasons = useSelector(state => state.football.seasons)
    const seasonName = params.seasonName || null
    const myTeams = useSelector(state => state.football.myTeams)
    const playoffPickDeadline = useSelector(state => state.football.playoffPickDeadline)
    const pickTeamId = useSelector(state => state.football.pickTeamId)
    const postseasonPicks = useSelector(state => state.football.postseasonPicks) || {}
    const postseasonPickCommands = useSelector(state => state.football.postseasonPickCommands)
    const postseasonPickGameData = useSelector(state => state.football.postseasonPickGameData)
    const postseasonPicksAreDirty = useSelector(state => state.football.postseasonPicksAreDirty)
    const postseason = useSelector(state => state.football.playoffs || []).find(i => i.seasonName === seasonName)
    const playoffWeek = (postseason || {}).activeWeek || 0
    const [isSaving, setSaving] = useState(false)
    const [isSavedOnce, setSavedOnce] = useState(false)
    const [tiebreaker, setTiebreaker] = useState('0')
    const [originalTiebreaker, setOriginalTiebreaker] = useState('0')
    const afterDeadline = moment().isSameOrAfter(playoffPickDeadline)
    const isDevelopment = __DEV__

    const _myTeam = myTeams.find(i => pickTeamId && i._id === pickTeamId)
    const { teamName } = _myTeam || {}

    useEffect(() => { dispatch(getPlayoffs(seasonName)) }, [seasonName])
    useEffect(() => { !pickTeamId && myTeams.length ? dispatch(setPickTeamId((first(myTeams) || {})._id || 0)) : null }, [pickTeamId, myTeams.length])
    useEffect(() => { !isDevelopment && (afterDeadline || (postseason || {}).isPlayoffTeam === false) ? navigate(`/football/postseason/${seasonName}`) : null }, [afterDeadline, (postseason || {}).isPlayoffTeam])
    useEffect(() => {
        if (!afterDeadline && seasonName && playoffWeek && (_myTeam || {})._id) {
            dispatch(getEnterPlayoffPicks(seasonName, playoffWeek, (_myTeam || {})._id))
            .then(action => {
                const _tiebreaker = action.payload.tiebreaker || '0'
                setOriginalTiebreaker(_tiebreaker)
                setTiebreaker(_tiebreaker)
            })
        }
    }, [afterDeadline, seasonName, playoffWeek, (_myTeam || {})._id])
    useEffect(() => { tiebreaker !== '0' && tiebreaker !== originalTiebreaker ? dispatch(setPostseasonPickDirtyState(true)) : null }, [tiebreaker, originalTiebreaker])

    const doSavePostseasonPicks = () => {
        const picks = postseasonPicks
        if (!afterDeadline && pickTeamId) {
            setSaving(true)
            dispatch(savePostseasonPicks({
                teamId: pickTeamId,
                picks: picks && picks.items ? orderBy(picks.items.map(p => ({ _id: p._id, game: p.game, pick: p.pick, rank: p.rank, ouPick: p.ouPick, ouRank: p.ouRank })), ['game'], ['asc']) : [],
                tiebreaker: parseInt(tiebreaker)
            })).then(resp => {
                dispatch(createNotification({
                    type: resp.payload.messageType, 
                    headline: `Save Postseason Picks -- Week ${playoffWeek}`, 
                    message: resp.payload.message, 
                    timeout: 6000
                }))
                setSaving(false)
                setSavedOnce(true)
            })
        }
    }

    const items = (postseasonPicks || {}).items || []
    const gamePicks = orderBy(items, ['rank'], ['desc'])
    const rankPicks = orderBy(items, ['ouRank'], ['desc'])
    const _ranks = (gamePicks.length ? gamePicks.map(i => i.rank) : (postseasonPicks.validRanks || [])).sort((a, b) => b - a)
    const _ouRanks = (gamePicks.length ? gamePicks.map(i => i.ouRank) : (postseasonPicks.validOuRanks || [])).sort((a, b) => b - a)
    const showTiebreaker = playoffWeek === 4
    return (
        <div className={s.container}>
            <div className={s.titleBar}>
                <h2>Enter {seasonName} Postseason Week {playoffWeek} Picks</h2>
                {postseasonPicks.isAutoGenerated || postseasonPicksAreDirty ? 
                    <Button size='lg'
                        variant='primary' 
                        disabled={isSaving}
                        onClick={!isSaving ? () => doSavePostseasonPicks() : null}>
                        <span><FontAwesomeIcon name={!isSaving ? 'check' : 'circle-notch'} spin={isSaving} /> {!isSaving ? 'save' : 'saving...'}</span>
                    </Button>
                : null}
            </div>
            <Row>
                <Col lg={8}>
                    <h4>Game picks</h4>
                    <ul className={s.picksContainer}>
                    {gamePicks && gamePicks.map((i, index) =>
                        <EnterPostseasonPicksGameContainer key={`postseason-pick-${i._id}`} rank={i.rank}>
                            <EnterPostseasonPicksGameItem 
                                game={i} 
                                ranks={_ranks}
                                seasonName={seasonName}
                                addCommand={command => dispatch(enterPostseasonPicksCommand(command))} />
                        </EnterPostseasonPicksGameContainer>
                    )}
                    </ul>
                    {showTiebreaker ? 
                        <div className={s.tiebreakerContainer}>
                            <div className={s.tiebreakerLabel}>Tiebreaker (total points for both teams)</div>
                            <div className={s.tiebreakerTextboxContainer}>
                                <FormControl value={tiebreaker} pattern='^\d+$' 
                                    size='lg'
                                    className={s.tiebreakerTextbox}
                                    onChange={e => setTiebreaker(e.target.value)} 
                                    onBlur={e => setTiebreaker(!isNaN(e.target.value) ? parseInt(e.target.value || '0').toString() : e.target.value)} />
                            </div>
                        </div>
                    : null}
                </Col>
                <Col lg={4}>
                    <h4>Over/Under picks</h4>
                    <ul className={s.picksContainer}>
                    {rankPicks && rankPicks.map((i, index) =>
                        <EnterPostseasonPicksOUContainer key={`postseason-ou-rank-pick-${i._id}`} rank={i.ouRank}>
                            <EnterPostseasonPicksOUItem game={i}
                                ranks={_ouRanks}
                                seasonName={seasonName} 
                                addCommand={command => dispatch(enterPostseasonPicksCommand(command))} />
                        </EnterPostseasonPicksOUContainer>
                    )}
                    </ul>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <EnterPostseasonPicksAutoGenerationAlert isAutoGenerated={postseasonPicks.isAutoGenerated} />
                </Col>
            </Row>
        </div>
    )
}

export default EnterPostseasonFootballPicks