import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useLocation, useParams } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Nav, Navbar, NavDropdown, Dropdown } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './Menu.scss'
import { playerUrl, HEROKU_VERSION } from '@severed-links/common.redherrings-constants'
import { camelCase } from 'change-case'
import { getProfile, checkProfile, getTimeZone, getMe } from '@severed-links/common.redherrings-reducers/account'
import FootballMenuStatusBar from './FootballMenuStatusBar'
import EliminatorMenuStatusBar from './EliminatorMenuStatusBar'

import UserMenu from '@severed-links/common.user-menu'
import AuthTokenExpirationChecker from '@severed-links/common.auth-token-expiration-checker'

const BaseAuthenticatedMenu = ({ navbarBrand = null, backgroundImageUrl = '', menuItems = [], expand = 'lg' }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const urlParts = location.pathname.split('/')
    const currentAppPath = (urlParts && urlParts.length > 1 ? urlParts[1] : 'main').toLowerCase()
    const currentAppClass = camelCase(currentAppPath)
    const _user = useSelector(state => state.account)
    const { _id, isAuthenticated, firstName, lastName, avatarUrl: imageUrl, expirationDate,
        hasCellPhone, hasEmail, hasAvatar, isMyProfileLoaded, isMyExtendedProfileLoaded } = _user
    const _settingsMenuVisible = !!_id && !!isAuthenticated
    const profileBadgeCount = isMyProfileLoaded && isMyExtendedProfileLoaded ? (hasCellPhone ? 0 : 1) + (hasEmail ? 0 : 1) + (hasAvatar ? 0 : 1) : 0
    const badgeCount = profileBadgeCount + 0

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getProfile())
            dispatch(checkProfile())
            dispatch(getTimeZone())
        }
    }, [isAuthenticated])

    const _settingsMenuItems = [
        { icon: 'pencil-alt', text: 'Edit my profile', path: `/${currentAppPath}/user-profile/edit-profile`, options: { replace: true }, badgeCount: profileBadgeCount },
        { icon: 'lock', text: 'Change my password', path: `/${currentAppPath}/user-profile/change-password`, addDivider: true },
        { icon: 'question', text: 'Help center', path: `/${currentAppPath}/help-center`, addDivider: true },
        { icon: 'arrows-alt-h', text: 'Switch contests', path: `/main`, addDivider: true },
        { icon: 'sign-out-alt', text: 'Logout', path: '/logout', options: { replace: true } },
    ]

    const handleSettingsMenuSelect = _item => {
        navigate(_item.path, _item.options || {})
    }

    const _settingsMenuData = { 
        firstName, lastName, imageUrl, show: _settingsMenuVisible,
        badgeCount, menuItems: _settingsMenuItems, containerClassName: `${s.settingsMenu} ${s[currentAppClass]}`,
        onSelect: handleSettingsMenuSelect, imageUrlConverter: playerUrl,
        buildVersion: HEROKU_VERSION, 
        autoShowSize: 'md',
    }

    return (
        <div className={s.container}>
            <Navbar collapseOnSelect expand={expand}
                className={s.baseMenu + ' ' + s[currentAppClass]}
                style={{ backgroundImage: backgroundImageUrl ? 'url(' + backgroundImageUrl + ')' : null }}>
                <Navbar.Brand className={s.navbarBrand}>
                    {navbarBrand}
                </Navbar.Brand>
                <Navbar.Toggle className={s.navbarToggle}>
                    <FontAwesomeIcon name='bars' />
                </Navbar.Toggle>
                <Navbar.Collapse className={s.navbarCollapse}>
                    <Nav className={s.nav} onClick={e => e.stopPropagation()}>
                        {menuItems}
                    </Nav>
                    <UserMenu {..._settingsMenuData} />
                </Navbar.Collapse>
                <AuthTokenExpirationChecker expirationDate={expirationDate}
                    onExpired={() => navigate('/logout', { state: { from: location }, replace: true })} />
            </Navbar>
            {currentAppClass === 'football' ? <FootballMenuStatusBar /> : null}
            {currentAppClass === 'eliminator' ? <EliminatorMenuStatusBar /> : null}
        </div>
    )
}

export default BaseAuthenticatedMenu
