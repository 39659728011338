import React, { useState, useEffect } from 'react'
import { Badge, Modal, Button } from 'react-bootstrap'
import { find, forEach } from 'lodash'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import NCAATeam from '../NCAATeam/NCAATeam'
import * as s from './ScoreboardPlayerTeams.scss'
import { ordinal } from '@severed-links/common.redherrings-constants'

const ScoreboardPlayerTeams = ({ entry = {}, afterDeadline = false, teams = [], addTeamToFilter = () => void(0) }) => {

    const [show, setShow] = useState(false)

    const { entryTeams } = entry
    const buttonVariant = entry.teamsRemaining >= 4 ? 'success' : entry.teamsRemaining >= 2 ? 'warning' : entry.teamsRemaining === 1 ? 'danger' : 'light'
    return (
        <div className={s.scoreboardTeamsContainer}>
            <div className={'d-none d-lg-flex ' + s.teamsRow}>
                {entryTeams.map(t => 
                <div key={`teams-${entry.entryId}-${t.teamId}`} 
                    className={s.teamContainer} 
                    onClick={() => addTeamToFilter(t)}>
                    <NCAATeam name={t.teamName} seed={t.seed} 
                        size='sm' points={t.points} isAlive={t.isAlive} 
                        showAvatar avatarUrl={t.avatarUrl}
                        showPoints={true} />                    
                </div>
                )}
            </div>
            <div className='d-inline-block d-lg-none'>
                <Button className={s.showTeamsModalButton}
                    variant={buttonVariant}
                    onClick={() => setShow(true)}>
                    {entry.teamsRemaining}
                </Button>
                <Modal show={show} onHide={() => setShow(false)}>
                    <Modal.Header closeButton>
                        <Modal.Title as='h4'>{entry.fullName}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <div>
                        <h4>{entry.entryName}</h4>
                        {entryTeams.map(t => 
                            <div key={`mobile-teams-${entry.entryId}-${t.teamId}`} 
                                className={s.teamContainer} 
                                onClick={() => addTeamToFilter(t)}>
                                <NCAATeam name={t.teamName} seed={t.seed} 
                                    size='sm' points={t.points} isAlive={t.isAlive} 
                                    showAvatar avatarUrl={t.avatarUrl}
                                    showPoints={true} />                    
                            </div>
                        )}
                        </div>
                        <h4 className={s.entryScore}>
                            <div>{ordinal(entry.rank)} place</div>
                            <div>{entry.score} {entry.score !== 1 ? 'points' : 'point' }</div>
                        </h4>
                    </Modal.Body>
                </Modal>
            </div>
        </div>
    )
}

export default ScoreboardPlayerTeams