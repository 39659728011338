import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { FormGroup, FormControl, Badge, InputGroup, FormLabel, Button, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './PreseasonRegistrationStep2.scss'
import { teamRegistrationDivisionSelection } from '@severed-links/common.redherrings-reducers/football'

const PreseasonRegistrationStep3 = forwardRef(({ jumpToStep }, _ref) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const seasonName = params.seasonName || null
    const seasons = useSelector(state => state.football.seasons)
    const playerId = useSelector(state => state.account._id)
    const userName = useSelector(state => state.account.userName)
    const season = seasons.find(i => i.seasonName === seasonName)
    const seasonId = (season || {}).seasonId || null
    const teamRegistration = useSelector(state => state.football.teamRegistration)
    const preseason = useSelector(state => state.football.preseasons)[`${seasonId}`] || {}
    const { nflKickoff, spotsLeft, divisions, entryFee } = preseason
    const [division, setDivision] = useState('')
    const _isValid = !!division

    useEffect(() => { setDivision('') }, [])
    useEffect(() => { division ? dispatch(teamRegistrationDivisionSelection(division)) : null }, [division])

    const handleSelect = e => setDivision(e.target.value)

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValid
    }))

    return (
        <Alert variant='primary' className={s.container}>
            <FormGroup className={s.formGroup}>
                <InputGroup>
                    <Button variant='light' onClick={() => jumpToStep(0)}><FontAwesomeIcon name='chevron-left' /> Cancel</Button>
                    <FormControl autoFocus autoComplete='off' as='select' 
                        name='division' value={division} 
                        className={s.input}
                        required isValid={_isValid}
                        onChange={e => handleSelect(e)}>
                    {divisions && divisions.map(d =>
                        <option key={'division-registration-option-' + d.value} value={d.value}>{d.text}</option>
                    )}
                    </FormControl>
                    <Button variant='primary' 
                        disabled={!_isValid}
                        onClick={() => _isValid ? jumpToStep(3) : void(0)}><FontAwesomeIcon name='chevron-right' /> Next</Button>
                </InputGroup>
            </FormGroup>
        </Alert>
    )
})

export default PreseasonRegistrationStep3