import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col, Alert, Button } from 'react-bootstrap'
import * as s from './Postseason.scss'
import { getNflPlayoffScoreboard, getPlayoffs, setPickTeamId } from '@severed-links/common.redherrings-reducers/football'
import { filter, range, find, first, uniq, uniqBy } from 'lodash'
import moment from 'moment-timezone'
import NFLScoreboardGame from '../RegularSeason/NFL/NFLScoreboardGame'
import * as _nflScoreboardStyle from '../RegularSeason/NFL/NFLScoreboard.scss'
import PostseasonPastResult from './PostseasonPastResult'
import SeasonSelector from '../SeasonSelector'
import PostseasonThisWeek from './PostseasonThisWeek'
import DeadlineAlertContainer from '../DeadlineAlertContainer'
import RedherringsChampion from './RedherringsChampion'

const Postseason = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const seasonName = params.seasonName || null
    const { _id: playerId } = useSelector(state => state.account)
    const { seasons, playoffs: _allPlayoffs, nflPlayoffScoreboards } = useSelector(state => state.football)
    const season = seasons.find(i => i.seasonName === seasonName)
    const seasonId = (season || {}).seasonId || null
    const playoffs = (_allPlayoffs || []).find(i => i.seasonName === seasonName) || {}
    const nflPlayoffScoreboard = (nflPlayoffScoreboards || []).find(i => i.seasonName === seasonName) || {}
    const myTeams = useSelector(state => state.football.myTeams) || []
    const { rounds, activeWeek, isPlayoffTeam, pickDeadline, afterDeadline } = playoffs
    const currentWeek = find(rounds || [], { round: activeWeek })
    const myTeam = first(myTeams) || { teamName: '', _id: null }
    const thisWeekNflPlayoffScoreboard = (nflPlayoffScoreboard.weeks || []).find(w => w.week === activeWeek && w.games?.length)
    const nflGameDates = uniqBy(thisWeekNflPlayoffScoreboard?.games || [], 'shortDayName')

    useEffect(() => { seasonId ? dispatch(getNflPlayoffScoreboard(seasonId)) : null }, [seasonId])
    useEffect(() => { seasonName ? dispatch(getPlayoffs(seasonName)) : null }, [seasonName])
    
    if (!playoffs) return null
    return (
        <div>
            <Row>
                <Col xs={12}>
                    <h2>
                        <span className={s.seasonSelector}><SeasonSelector seasons={seasons} season={season} /></span>                            
                        {seasonName} Playoffs {activeWeek > 0 ? "Week " + activeWeek : ""}
                    </h2>

                    {myTeam._id && isPlayoffTeam ? 
                    <DeadlineAlertContainer deadline={pickDeadline} 
                        teamId={myTeam._id}
                        teamName={myTeam.teamName} postseason
                        setPickTeamId={setPickTeamId}
                        showPickButton={true} />
                    : null}

                </Col>
            </Row>
            <Row>
                <Col lg={8} xs={12}>

                    <RedherringsChampion nflPlayoffScoreboard={nflPlayoffScoreboard} 
                        seasonName={seasonName}
                        playoffs={playoffs} />

                    {currentWeek ?
                        <PostseasonThisWeek week={activeWeek} 
                            round={currentWeek} season={season} 
                            afterDeadline={afterDeadline} />
                    : <Alert>Playoffs not loaded for this season yet.</Alert>}

                    {activeWeek > 1 ?
                    <div>
                        <h3>Past Results</h3>
                        {range(activeWeek - 1, 0, -1).map(week => 
                            <PostseasonPastResult week={week} key={`postseason-past-result-week-${week}`}
                                season={season}
                                round={find(rounds, { round: week })} />
                        )}
                    </div>
                    : null}
                </Col>
                <Col lg={4} xs={12}>
                <div className={_nflScoreboardStyle.container}>
                    {thisWeekNflPlayoffScoreboard?.heading ? <h2>{thisWeekNflPlayoffScoreboard.heading}</h2> : null}
                    <div className={_nflScoreboardStyle.gamesContainer}>
                    {nflGameDates && nflGameDates.map(_date => 
                        <div className={_nflScoreboardStyle.dayContainer} key={`postseason-nfl-week-${activeWeek}-day-${_date.game}`}>
                            <h3>{moment(_date.date).format('dddd, MMMM D, YYYY')}</h3>
                            {thisWeekNflPlayoffScoreboard?.games && thisWeekNflPlayoffScoreboard.games.filter(g => moment(_date.date).isSame(moment(g.date), 'day')).map(g => 
                            <Col xs={12} key={`playoff-game-${g.game}`}>
                                <NFLScoreboardGame game={g} seasonName={seasonName} seasonMode={'Postseason'} showLine />
                            </Col>
                        )}
                        </div>
                    )}
                    </div>
                </div>
                {nflPlayoffScoreboard && nflPlayoffScoreboard.weeks && filter(nflPlayoffScoreboard && nflPlayoffScoreboard.weeks, w => w.week === activeWeek && w.games && w.games.length > 0).length === 0 ? 
                <Alert variant='info'>No {seasonName} playoff games located.</Alert>
                : null}
                </Col>
            </Row>
        </div>
    )
}

export default Postseason