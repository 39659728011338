import React, { useState, useEffect, useDebugValue } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import RedHerringsPayPalButton from '../Payment/RedHerringsPayPalButton'
import * as s from './EliminatorPaymentAlert.scss'
import { getMainPageData, getStandings } from '@severed-links/common.redherrings-reducers/eliminator'

const EliminatorPaymentAlert = () => {

    const dispatch = useDispatch()
    const seasonId = useSelector(state => state.eliminator.seasonId)
    const seasonName = useSelector(state => state.eliminator.seasonName)
    const seasonMode = useSelector(state => state.eliminator.seasonMode)
    const eliminationMode = useSelector(state => state.eliminator.eliminationMode)
    const mainPage = useSelector(state => state.eliminator.mainPage)
    const entryFee = useSelector(state => state.eliminator.entryFee)
    const week = useSelector(state => state.eliminator.week)
    const playoffWeek = useSelector(state => state.eliminator.playoffWeek)
    const afterDeadline = useSelector(state => state.eliminator.afterDeadline)
    const paymentEmail = useSelector(state => state.eliminator.paymentEmail)
    const playerId = useSelector(state => state.account._id)
    const userName = useSelector(state => state.account.userName)

    const tryMainDataReload = () => {
        dispatch(getMainPageData())
        dispatch(getStandings(seasonName))
    }

    const onAuthorizedEliminatorPayment = () => tryMainDataReload()
    const onCancelledEliminatorPayment = () => tryMainDataReload()

    if (!seasonName || !mainPage || !mainPage.isSignedUpAlready || (mainPage.isSignedUpAlready && mainPage.isPaid)) return null
    const { isPaid, isSignedUpAlready } = mainPage
    return (
        <Alert className={s.container} variant={'warning'}>
            <Alert.Heading>Payment is due</Alert.Heading>
            <p>
                Our records show that the ${entryFee} payment is due.  Click the button below to pay.
            </p>
            <p className={s.paymentContainer}>
            <RedHerringsPayPalButton
                contest={'Eliminator'}
                itemNumber={playerId}
                receiverEmail={paymentEmail}
                description={`RedHerrings Eliminator ${seasonName}`}
                amount={entryFee}
                seasonId={seasonId}
                playerId={playerId}
                onAuthorizedPayment={onAuthorizedEliminatorPayment}
                onCancelledPayment={onCancelledEliminatorPayment} />
            </p>
        </Alert>
    )
}

export default EliminatorPaymentAlert