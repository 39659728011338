import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, Tabs, Tab, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import SeasonSelector from '../SeasonSelector'
import Modal from '@severed-links/common.modal'
import moment from 'moment-timezone'
import * as s from './DivisionOrganizer.scss'
import { getTeamData, setTeamDivision } from '@severed-links/common.redherrings-reducers/footballAdmin'
import { countBy, first, orderBy, forOwn } from 'lodash'
import PlayerTeamListItem from '../PlayerTeamListItem'
import Player from '../../Player/Player'
import pluralize from 'pluralize'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'

const DivisionOrganizer = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const seasons = useSelector(state => state.football.seasons)
    const seasonName = (params || {}).seasonName || null
    const seasonId = (seasons.find(i => i.seasonName === seasonName) || {}).seasonId || null
    const teamData = useSelector(state => (state.footballAdmin.teamData || []).find(i => i.seasonName === seasonName) || {})
    const [activeTab, setActiveTab] = useState(null)
    const [showDivisionModal, setShowDivisionModal] = useState(false)
    const [editTeam, setEditTeam] = useState({})

    useEffect(() => { fetchTeamData() }, [seasonId])
    useEffect(() => {
        if (!activeTab && (teamData.divisions || []).length) {
            setActiveTab((first((teamData.divisions || []).filter(i => i.value)) || {}).value || null)
        }
    }, [activeTab, (teamData.divisions || []).length])

    const fetchTeamData = () => seasonId ? dispatch(getTeamData(seasonId)) : null

    const doSetEditTeam = _team => {
        setEditTeam(_team)
        setShowDivisionModal(true)
    }

    const closeModal = () => setShowDivisionModal(false)

    const saveNewDivision = _division => {
        dispatch(setTeamDivision(editTeam._id, _division.value))
        .then(action => {
            fetchTeamData()
            dispatch(createNotification(action.payload))
            closeModal()
        })
    }

    const { teams, missingPlayers, divisions, players, divisionCounts, slotsLeft, teamCount, teamCap } = teamData || {}
    const divisionItems = (divisions || []).map(d => ({
        ...d,
        divisionName: d.text,
        count: (divisionCounts || {})[`${d.text}`] || 0,
    }))
    return (
        <div className={s.container}>
            <div className={s.headingContainer}>
                <Button variant='light' onClick={() => navigate(-1)}><FontAwesomeIcon name='chevron-left' /></Button>
                <h2>Division Organizer {seasonName} {teamCap ? ` (${teamCount}/${pluralize('team', teamCap, true)})` : ``}</h2>
            </div>

            <Tabs activeKey={activeTab} id={`division-organizer-tab-container`} onSelect={e => setActiveTab(e)}>
            {divisionItems && divisionItems.filter(i => i.value).map(i =>
                <Tab eventKey={i.value} key={`div-org-${i.value}`} title={`${i.text} (${i.count})`}>
                    <ListGroup>
                    {teams && teams.filter(t => t.division === i.value).map(t => 
                        <PlayerTeamListItem teamId={t._id} teamName={t.teamName} avatarUrl={t.avatarUrl}
                            teamNameRaw={t.teamName}
                            right={<span>{t.divisionName}</span>}
                            detail={<Player playerId={t.playerId} firstName={t.firstName} lastName={t.lastName} fullName={t.fullName} avatarUrl={t.playerAvatarUrl} />}
                            name={<span><FontAwesomeIcon name={t.paid ? 'check' : 'times'} /></span>}
                            onClick={() => doSetEditTeam(t)}
                            key={`div-org-team-${t._id}`} />
                    )}
                    </ListGroup>
                </Tab>
            )}
            </Tabs>

            <Modal heading={`Set Team Division: ${editTeam.teamName}`}
                show={showDivisionModal}
                onClose={() => closeModal()}
                actionButtonText={null}>
                <div>
                    <h4>Current division: {(divisionItems.find(i => i.value === ((editTeam || {}).division || null)) || {}).text || 'None'}</h4>
                    <ListGroup>
                    {divisionItems && divisionItems.map(i =>
                    <ListGroup.Item key={`div-org-edit-div-${i.value}`} action onClick={() => saveNewDivision(i)}>
                        {i.text}
                    </ListGroup.Item>
                    )}
                    </ListGroup>
                </div>
            </Modal>

        </div>
    )
}

export default DivisionOrganizer