import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { setAccountDeletionState } from '@severed-links/common.redherrings-reducers/accountDeletion'
import pluralize from 'pluralize'
import * as s from './AccountDeletionStep.scss'

const LogoutStep = forwardRef(({ jumpToStep, ...props }, _ref) => {

    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const playerId = useSelector(state => state.account._id)
    const _accountDeletion = useSelector(state => state.accountDeletion)
    const { 
        isFetching, playerInfoSelections, playerInfoSelection, firstName, lastName, address, 
        city, state, zip, emailAddress, cellPhone, imageUrl, accountDeletionConfirmed, 
    } = _accountDeletion
    const _isValidated = !!playerInfoSelection

    useEffect(() => {
        setTimeout(() => navigate('/logout'), 3500)
    }, [])

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValidated,
    }))

    return (
        <div className={s.container}>
            <h4>Logging Off</h4>
            <p>
                You will now be logged off.
            </p>
        </div>
    )
})

export default LogoutStep
