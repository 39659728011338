import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import * as s from './CaptureFrontPage.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'

import CaptureInstructions from './CaptureInstructions'
import CaptureWinners from './CaptureWinners'

const CaptureFrontPage = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const contestId = params.contestId || null
    const contest = useSelector(state => state.capture.contest) || {}
    const winners = useSelector(state => state.capture.winners)

    return (
        contestId && contest.contestName ? 
        <div>
            <Row>
                <Col xs={12}>
                    <h2>{contest.contestName}</h2>
                </Col>
            </Row>
            <Row>
                <Col sm={9} xs={12}>
                    <CaptureInstructions />
                </Col>
                <Col sm={3} xs={12}>
                    <CaptureWinners winners={winners} />
                </Col>
            </Row>
        </div>
        : null
    )
}

export default CaptureFrontPage