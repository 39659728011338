import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import BaseAuthenticatedMenu from './BaseAuthenticatedMenu'
import { Nav } from 'react-bootstrap'
import { img } from '@severed-links/common.redherrings-constants'
import CloudinaryImage from '../routes/Cloudinary/CloudinaryImage'
import * as s from './Menu.scss'
import { getOscarsDetails } from '@severed-links/common.redherrings-reducers/oscars'

const OscarsAdminMenu = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const isAuthenticated = useSelector(state => state.account.isAuthenticated)
    const year = useSelector(state => state.oscars.year)
    const isFetching = useSelector(state => state.oscars.isFetching || state.oscarsAdmin.isFetching)
    const isHome = location.pathname.toLowerCase() === '/oscars/admin'

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getOscarsDetails())
        }
    }, [isAuthenticated])

    const navbarBrand = (       
        <Link to={{ pathname: '/oscars/admin' }}>
            <CloudinaryImage publicId={img.OscarsHeader} />
        </Link>
    )

    const menuItems = [
        <Nav.Item key={1}><Nav.Link eventKey={1} title='Back to Oscars' onClick={() => navigate('/oscars')}>
            <FontAwesomeIcon name='chevron-left' />
        </Nav.Link></Nav.Item>,
        !isHome ? <Nav.Item className={s.item} key={2}><Nav.Link eventKey={2} title='Back to Oscars Admin Dashboard' onClick={() => navigate('/oscars/admin')}>
            <FontAwesomeIcon name='home' />
        </Nav.Link></Nav.Item> : null,
        <Nav.Item className={s.item} key={4}><Nav.Link eventKey={4} onClick={() => navigate(`/oscars/admin/nominees/${year}`)}>Nominees</Nav.Link></Nav.Item>,
        <Nav.Item className={s.item} key={5}><Nav.Link eventKey={5} onClick={() => navigate(`/oscars/admin/winners/${year}`)}>Winners</Nav.Link></Nav.Item>,
        <Nav.Item className={s.item} key={6}><Nav.Link eventKey={6} onClick={() => navigate(`/oscars/admin/posters`)}>Posters</Nav.Link></Nav.Item>,
    ]
    return (
        <BaseAuthenticatedMenu backgroundImageUrl={img.CloudinaryBaseUrl + img.OscarsHeaderBackground}
            navbarBrand={navbarBrand} 
            expand='md'
            menuItems={menuItems} />
    )
}

export default OscarsAdminMenu
