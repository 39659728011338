import React, { useState, useEffect, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, FormGroup, FormControl, FormLabel, Button } from 'react-bootstrap'
import * as s from './TeamDataEditTeam.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import FormCheck from 'react-bootstrap/FormCheck'
import moment from 'moment-timezone'
import { v4 as uuidv4 } from 'uuid'
import { some, trimEnd, find, startsWith } from 'lodash'
import CloudinaryImage from '../../Cloudinary/CloudinaryImage'
import Confirm from '@severed-links/common.confirm'
import CloudinaryUploader from '../../Cloudinary/CloudinaryUploader'
import Modal from '@severed-links/common.modal'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { img, playerUrl } from '@severed-links/common.redherrings-constants'
import { saveTeamData, setTeamDataReload, setTeamDataCloseOnSave, 
    deleteTeam, transferTeamToAnotherPlayer } from '@severed-links/common.redherrings-reducers/footballAdmin'
import { updateTeamAvatar } from '@severed-links/common.redherrings-reducers/football'
import { updatePlayerAvatar } from '@severed-links/common.redherrings-reducers/account'

const TeamDataEditTeam = ({ editTeam = {}, divisions = [], players = [] }) => {

    const dispatch = useDispatch()
    const [showTransferTeamModal, setShowTransferTeamModal] = useState(false)
    const [transferPlayerId, setTransferPlayerId] = useState('0')
    const [_editTeam, setEditTeam] = useState({})

    useEffect(() => { 
        setEditTeam({
            ...editTeam,
            avatarUrl: editTeam.avatarUrl || null,
            playerAvatarUrl: editTeam.playerAvatarUrl || null, 
        })
    }, [])

    const handleChange = e => setEditTeam({ ..._editTeam, [e.target.name]: e.target.value })

    const handleBootstrapSwitch = (e, checkedState) => {
        setEditTeam({ ..._editTeam, [e.target.name]: checkedState })
    }

    const updatePlayerAvatar = _uploadResults => {
        const { public_id: avatarUrl } = _uploadResults
        const { playerId } = _editProfile
        if (playerId && avatarUrl) {
            dispatch(updatePlayerAvatar(playerId, avatarUrl))
            .then(action => setEditTeam({  ..._editTeam, playerAvatarUrl: action.payload.avatarUrl }))
        }
    }

    const updateTeamAvatar = _uploadResults => {
        const { public_id: avatarUrl } = _uploadResults
        const { _id: teamId } = _editProfile
        if (teamId && avatarUrl) {
            dispatch(updateTeamAvatar(teamId, avatarUrl))
            .then(action => setEditTeam({  ..._editTeam, avatarUrl: action.payload.avatarUrl }))
        }
    }

    const saveTeam = () => {
        var teamToSave = { ..._editTeam }
        delete teamToSave.divisions
        dispatch(saveTeamData(teamToSave))
        .then(action => {
            dispatch(createNotification({ type: action.payload.messageType, 
                headline: 'Save Team Data', 
                message: action.payload.message, 
                timeout: 6000 
            }))
            dispatch(setTeamDataReload(true))
            dispatch(setTeamDataCloseOnSave(true))
        })
    }

    const doDeleteTeam = () => {
        dispatch(deleteTeam(editTeam._id))
        .then(action => {
            dispatch(createNotification({ type: action.payload.messageType, 
                headline: 'Deleted Team', 
                message: action.payload.message, 
                timeout: 6000 
            }))
            dispatch(setTeamDataReload(true))
            dispatch(setTeamDataCloseOnSave(true))
        })
    }

    const transferPlayer = () => {
        const { _id } = _editTeam
        dispatch(transferTeamToAnotherPlayer(_id, transferPlayerId))
        .then(action => {
            dispatch(createNotification(action.payload))
            setShowTransferTeamModal(false)
            dispatch(setTeamDataReload(true))
            dispatch(setTeamDataCloseOnSave(true))
        })
    }

    return (
        <Row className={s.container}>
            <Col sm={6} xs={12}>
                <h4>Team Data</h4>

                <FormGroup className={s.formGroup}>
                    <FormLabel>Team name</FormLabel>
                    <FormControl name='teamName' autoCapitalize='words' 
                        value={_editTeam.teamName || ''} onChange={e => handleChange(e)} />
                </FormGroup>

                <FormGroup className={s.formGroup}>
                    <FormLabel>Division</FormLabel>
                    <FormControl as='select' name='division' value={_editTeam.division || ''} 
                        onChange={e => handleChange(e)}>
                    {divisions.map(d =>
                        <option key={d.value} value={d.value}>{d.text}</option>
                    )}
                    </FormControl>
                </FormGroup>

                <Row>
                    <Col sm={6} xs={12}>
                        <FormGroup className={s.formGroup}>
                        <FormLabel style={{ width: '100%' }}>Team avatar</FormLabel>
                            <div className={s.teamDataAvatarContainer}>
                                <div className={s.avatarContainer} style={{ backgroundImage: `url(${playerUrl(_editTeam.avatarUrl)}` }} />
                                <CloudinaryUploader originalId={_editTeam.avatarUrl} 
                                    showImage={false}
                                    showButtonIsBlock={true}
                                    showButton={true}
                                    showButtonSize={'xs'}
                                    className={s.teamDataAvatarButton}
                                    folder={img.FootballTeamFolder}
                                    onUploadSuccess={updateTeamAvatar} />
                            </div>
                        </FormGroup>
                    </Col>
                    <Col sm={6} xs={12}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel style={{ width: '100%' }}>Player avatar</FormLabel>
                            <div className={s.teamDataAvatarContainer}>
                            <div className={s.avatarContainer} style={{ backgroundImage: `url(${playerUrl(_editTeam.playerAvatarUrl)}` }} />
                                <CloudinaryUploader originalId={_editTeam.playerAvatarUrl || ''} 
                                    showImage={false}
                                    showButtonIsBlock={true}
                                    showButton={true}
                                    showButtonSize={'xs'}
                                    className={s.teamDataAvatarButton}
                                    folder={img.PlayerAvatarFolder}
                                    onUploadSuccess={updatePlayerAvatar} />
                                </div>
                        </FormGroup>
                    </Col>
                </Row>



            </Col>
            <Col sm={6} xs={12}>
                <div>
                    <h4>Player Data</h4>
                    <Button variant='warning' size='sm' onClick={() => setShowTransferTeamModal(true)}><FontAwesomeIcon name='rotate' /> transfer</Button>
                </div>

                <FormGroup className={s.formGroup}>
                    <FormLabel>First name</FormLabel>
                    <FormControl name='firstName' autoCapitalize='words' value={_editTeam.firstName || ''} 
                        onChange={e => handleChange(e)} />
                </FormGroup>

                <FormGroup className={s.formGroup}>
                    <FormLabel>Last name</FormLabel>
                    <FormControl name='lastName' autoCapitalize='words' value={_editTeam.lastName || ''}
                        onChange={e => handleChange(e)} />
                </FormGroup>

                <FormGroup className={s.formGroup}>
                    <FormLabel>Full name</FormLabel>
                    <FormControl name='fullName' autoCapitalize='words' value={_editTeam.fullName || ''} 
                        onChange={e => handleChange(e)} />
                </FormGroup>

                <FormGroup className={s.formGroup}>
                    <FormLabel>Email address</FormLabel>
                    <FormControl type='email' autoCapitalize='none' name='emailAddress' value={_editTeam.emailAddress || ''}
                        onChange={e => handleChange(e)} />
                </FormGroup>

            </Col>

            <Col xs={12}>

                <div>
                <FormGroup className={s.formGroup}>
                        <FormLabel style={{ display: 'block' }}>Paid?</FormLabel>
                        <FormCheck type='switch' onChange={e => handleBootstrapSwitch(e, e.target.checked)} name='paid' checked={_editTeam.paid || false} />
                    </FormGroup>                    
                    <FormGroup className={s.formGroup}>
                        <FormLabel style={{ display: 'block' }}>Pick Reminder Email?</FormLabel>
                        <FormCheck type='switch' onChange={e => handleBootstrapSwitch(e, e.target.checked)} name='sendEmailReminder' checked={_editTeam.sendEmailReminder || false} />
                    </FormGroup>                    
                    <FormGroup className={s.formGroup}>
                        <FormLabel style={{ display: 'block' }}>Key Game Email?</FormLabel>
                        <FormCheck type='switch' onChange={e => handleBootstrapSwitch(e, e.target.checked)} name='sendEmailKeyGameList' checked={_editTeam.sendEmailKeyGameList || false} />
                    </FormGroup>                    
                </div>

            </Col>
            
            <Col xs={12}>
                <Row>
                    <Col xs={6}>                    
                        <FormGroup className={s.formGroup}>
                            <Confirm onConfirm={() => doDeleteTeam()}
                                title={'Delete Team'}
                                confirmText='confirm delete'
                                body={`Are you sure you want to delete "${_editTeam.teamName}"?`}
                                buttonIcon='trash' buttonText='delete team' />
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup className={s.formGroup + ' text-end'}>
                            <Button variant='primary' onClick={() => saveTeam()}><FontAwesomeIcon name='check' /> save team and player data</Button>
                        </FormGroup>
                    </Col>

                </Row>
            </Col>

            <Modal heading='Transfer Team to Another Player' size={'sm'}
                show={showTransferTeamModal} enforceFocus={false}
                onClose={() => setShowTransferTeamModal(false)}
                actionButtonDisabled={transferPlayerId === '0'}
                actionButtonIcon='rotate'
                actionButtonText={'transfer'}
                actionButtonOnClick={transferPlayer}>
                <div>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>Player</FormLabel>
                        <FormControl as='select' name='playerId' value={transferPlayerId} 
                            onChange={e => setTransferPlayerId(e.target.value)}>
                        {players && players.map(d =>
                            <option key={d.value} value={d.value}>{d.text}</option>
                        )}
                        </FormControl>
                    </FormGroup>
                </div>
            </Modal>

        </Row> 
    )
}

export default TeamDataEditTeam
