import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { setAccountDeletionState } from '@severed-links/common.redherrings-reducers/accountDeletion'
import pluralize from 'pluralize'
import * as s from './AccountDeletionStep.scss'
import PlayerListItem from '../Player/PlayerListItem'

const ConfirmationStep = forwardRef(({ jumpToStep, ...props }, _ref) => {

    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const playerId = useSelector(state => state.account._id)
    const _accountDeletion = useSelector(state => state.accountDeletion)
    const { 
        isFetching, playerInfoSelections, playerInfoSelection, firstName, lastName, address, 
        city, state, zip, emailAddress, cellPhone, imageUrl, accountDeletionConfirmed, 
    } = _accountDeletion
    const _isValidated = !!playerInfoSelection && playerInfoSelections.length

    useEffect(() => {
    }, [])

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValidated,
    }))

    const _items = [
        { title: (playerInfoSelections.find(i => i.value === playerInfoSelection) || {}).summary || `Personal information not selected.` },
        { title: `Log you out of Redherrings for the last time.` },
    ].filter(i => i && i.title)

    return (
        <div className={s.container}>
            <h4>Confirm Account Deletion</h4>
            <p>
                Pressing the "Delete Account" button will take the following actions:
            </p>
            <ListGroup className={s.list}>
            {_items && _items.map((i, _index) => 
                <ListGroup.Item className={s.item} key={`account-deletion-confirm-item-${_index}`}>
                    <div className={s.num}>{_index + 1}</div>
                    <div className={s.title}>{i.title}</div>
                </ListGroup.Item>
            )}
            </ListGroup>

        </div>
    )
})

export default ConfirmationStep
