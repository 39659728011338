import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate, useLocation, useMatch } from 'react-router-dom'
import CloudinaryImage from '../Cloudinary/CloudinaryImage'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import NflTeam from './NflTeam'
import { find, orderBy, findIndex } from 'lodash'
import * as s from './NFLTeamSelector.scss'

const NFLTeamSelector = ({ mascot = '', nflTeam = '', teamId = null, selectedTeam = {}, onSelect = () => void(0) }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const _mascotParam = (params.mascot || '').toLowerCase() || null
    const nflTeams = useSelector(state => state.football.nflTeams)

    const onTeamSelect = e => {
        const selectedTeam = nflTeams.find(i => i._id === e)
        if (selectedTeam) {
            const _newPath = location.pathname.toLowerCase().replace(_mascotParam, selectedTeam.mascot.toLowerCase())
            navigate(_newPath)
        }
    }

    return (
        <DropdownButton className={s.nflTeamSelector} 
            variant='light' 
            title={selectedTeam && selectedTeam._id ? <NflTeam teamId={selectedTeam._id} teamName={selectedTeam.nflTeam} mascot={selectedTeam.mascot} /> : <span>[Select a team...]</span>} 
            id={`NFLTeamSelector-dropdown`} align='end'
            onSelect={e => onTeamSelect(e)}>
        {nflTeams && orderBy(nflTeams, ['nflTeam'], ['asc']).map(t =>
            <Dropdown.Item key={`nfl-team-selector-${t._id}`} eventKey={t._id}>
                <NflTeam teamId={t._id || ''} teamName={t.nflTeam || ''} 
                    mascot={t.mascot || ''} city={t.city || ''} />
            </Dropdown.Item>
        )}
        </DropdownButton>
    )
}

export default NFLTeamSelector
