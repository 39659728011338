import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './EliminatorWinners.scss'
import EliminatorWinnerInProgress from './EliminatorWinnerInProgress'
import EliminatorWinnerCardHeader from './EliminatorWinnerCardHeader'
import EliminatorWinnerCardFooter from './EliminatorWinnerCardFooter'
import EliminatorWinnerAvatars from './EliminatorWinnerAvatars'
import EliminatorWinnerNames from './EliminatorWinnerNames'
import EliminatorWinnerRunnerUpNames from './EliminatorWinnerRunnerUpNames'

const EliminatorWinnerCard = ({ winner = {}, onClick, ...props }) => {
    return (
        <Card className={s.winnerCard} onClick={() => onClick(winner.seasonName)}>
            <EliminatorWinnerCardHeader seasonName={winner.seasonName} />
            <EliminatorWinnerInProgress {...winner} />
            <EliminatorWinnerAvatars {...winner} />
            <EliminatorWinnerNames {...winner} />
            <EliminatorWinnerRunnerUpNames {...winner} />
            <EliminatorWinnerCardFooter {...winner} />
        </Card>
    )
}

export default EliminatorWinnerCard