import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col, FormCheck, Button, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import YearSelector from '../../Oscars/YearSelector'
import * as s from './CinderellaPayments.scss'
import { getPayments, sendNonPaymentEmails, getPaymentHistory, 
    deleteEntry, addManualPayment, getConfig,
    getMainPageData, deletePaymentLogEntry } from '@severed-links/common.redherrings-reducers/cinderellaAdmin'
import CinderellaPaymentHistory from './CinderellaPaymentHistory'
import numeral from 'numeral'
import moment from 'moment-timezone'
import { some, reject, sumBy, filter } from 'lodash'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import Modal from '@severed-links/common.modal'
import CinderellaPaymentLogItem from './CinderellaPaymentLogItem'
import Confirm from '@severed-links/common.confirm'
import CinderellaDuplicatePayments from './CinderellaDuplicatePayments'
import pluralize from 'pluralize'
import { deletePaymentLogItem } from '@severed-links/common.redherrings-reducers/footballAdmin'

const CinderellaPayments = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const year = parseInt(params.year || '0')
    const _payments = useSelector(state => state.cinderellaAdmin.payments[`${year}`] || {})

    const { entryFee, entryCount, entryPlayerCount, estimatedPaidEntries, estimatedProcessingFees,
        netPayments, payPalPerPayment, payPalPerTransactionFees, payPalPercentage, 
        payPalPercentageFees, paymentCount, payments, paymentsDue, processingFees, 
        totalPayments } = _payments

    const [isLoading, setLoading] = useState(false)
    const [playerIds, setPlayerIds] = useState([])
    const [paymentHistory, setPaymentHistory] = useState({})
    const [showPaymentHistory, setShowPaymentHistory] = useState(false)
    const [showDuplicatesModal, setShowDuplicatesModal] = useState(false)
    const [deletingId, setDeletingId] = useState(null)

    useEffect(() => { loadPayments() }, [year])

    const loadPayments = (forceReload = false) => {
        if (year) {
            setLoading(true)
            dispatch(getMainPageData())
            dispatch(getConfig())
            dispatch(getPayments(year, forceReload))
            .then(action => {
                setLoading(false)
                if (forceReload) reloadPaymentHistory()
            })
        }
    }

    const selectAllChange = e => setPlayerIds(e.target.checked ? paymentsDue.map(i => i.playerId) : [])

    const selectFormCheckChange = (e, playerId) => {
        e.stopPropagation()
        var _playerIds = reject([...playerIds], _pid => _pid === playerId)
        if (e.target.checked) {
            _playerIds.push(playerId)
        }
        setPlayerIds(_playerIds)
    }
    
    const doSendEmails = () => {
        if (playerIds.length === 0) {
            dispatch(createNotification({ headline: 'Send Payment Emails', message: 'No users are selected.  Check at least one checkbox in the left-hand column.', type: 'warning', timeout: 3000 }))
        } else {
            dispatch(sendNonPaymentEmails(year, playerIds))
            .then(action => {
                dispatch(createNotification({ headline: action.payload.subject, message: action.payload.message, type: action.payload.messageType, timeout: 3000 }))
                if (action.payload.messageType === 'success') {
                    setPlayerIds([])
                }
            })
        }
    }

    const doShowPaymentHistory = playerId => {
        if (year && playerId) {
            dispatch(getPaymentHistory(year, playerId))
            .then(action => {
                setPaymentHistory(action.payload)
                setShowPaymentHistory(true)
                setDeletingId(null)
            })
        }
    }

    const clearPaymentHistory = () => {
        setShowPaymentHistory(false)
        setTimeout(() => setPaymentHistory({}), 750)
    }

    const doDeleteEntry = (entryId, playerId) => {
        dispatch(deleteEntry(entryId, playerId))
        .then(action => {
            loadPayments(true)
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: action.payload.subject, timeout: 5000 }))
            if (year && playerId) {
                dispatch(getPaymentHistory(year, playerId))
            }
            setPaymentHistory(action.payload)
            setShowPaymentHistory(false)
        })
    }

    const doDeleteLogEntry = _id => {
        setDeletingId(_id)
        dispatch(deletePaymentLogItem(_id))
        .then(action => {
            if (action.payload.messageType !== 'success') {
                dispatch(createNotification(action.payload))
                setDeletingId(null)
            } else {
                loadPayments(true)
            }
        })
    }


    const doAddManualPayment = postData => {
        dispatch(addManualPayment(postData))
        .then(() => loadPayments(true))
    }

    const reloadPaymentHistory = () => {
        if (paymentHistory && paymentHistory.playerId) {
            doShowPaymentHistory(paymentHistory.playerId)
        }
    }

    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <div className={s.titleContainer}>
                        <h2 className={s.title}>
                            {year} Payments {isLoading ? <FontAwesomeIcon name='circle-notch' spin /> : null}
                        </h2>
                        <div className={s.refreshButton} title='reload payments'>
                            <Button onClick={() => loadPayments(true)}><FontAwesomeIcon name='rotate' spin={isLoading} /> reload payments</Button>
                        </div>
                        <div className={s.paymentsYearSelector} title='change year'>
                            <YearSelector defaultYear={year} 
                                buttonTitle={null} buttonIcon={'calendar'} />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h4>Entries with Balances Due</h4>
                    <ListGroup className={s.paymentLog}>
                        <ListGroup.Item className={s.item} variant='light'>
                            <div className={s.content}>
                                <div className={s.checkbox}>
                                <FormCheck type='checkbox'
                                    checked={(playerIds && paymentsDue && paymentsDue.length > 0 && playerIds.length === paymentsDue.length) || false}
                                    disabled={(paymentsDue || []).length === 0}
                                    onChange={e => selectAllChange(e)} />
                                </div>
                                <div className={s.player + ' ' + s.sendUnpaidEmails}>
                                <Confirm variant={(playerIds.length === 0 ? 'outline-primary' : 'primary')} 
                                    onConfirm={() => doSendEmails()}
                                    title={'Send Unpaid Emails'}
                                    confirmText='send unpaid emails'
                                    body={`Are you sure you want to send ${pluralize('email', (playerIds || []).length, true)}?`}
                                    size='sm' disabled={playerIds.length === 0} buttonIcon='envelope' buttonText='send unpaid emails' />
                                </div>
                                <div className={s.amount + ' ' + s.total + ' ' + s.hideSmall}>{sumBy(paymentsDue, 'entryCount')}</div>
                                <div className={s.amount + ' ' + s.total + ' ' + s.hideSmall}>{numeral(sumBy(paymentsDue, 'totalDue')).format('($0,0.00)')}</div>
                                <div className={s.amount + ' ' + s.total + ' ' + s.hideSmall}>{numeral(sumBy(paymentsDue, 'payments')).format('($0,0.00)')}</div>
                                <div className={s.amount + ' ' + s.total}>{numeral(sumBy(paymentsDue, 'balanceDue')).format('($0,0.00)')}</div>
                            </div>
                        </ListGroup.Item>
                        {paymentsDue && paymentsDue.map((x, index) =>
                            <CinderellaPaymentLogItem key={`payment-due-item-${x.playerId}-${index}`} item={x}
                                isDueItem onSelect={e => selectFormCheckChange(e, x.playerId)}
                                checked={some(playerIds, _pid => _pid === x.playerId)}
                                onClick={() => doShowPaymentHistory(x.playerId)}
                                deletingId={deletingId} />
                        )}
                        {!paymentsDue || paymentsDue.length === 0 ? 
                        <ListGroup.Item className={s.item} variant='info'>
                            <div className={s.content}>
                                No unpaid players located for {year}
                            </div>
                        </ListGroup.Item>
                        : null}
                    </ListGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <h4>Payments</h4>
                    <ListGroup className={s.paymentLog}>
                    <ListGroup.Item className={s.item} variant='light'>
                        <div className={s.content}>
                            <div className={s.amount + ' ' + s.total + ' ' + s.header + ' ' + s.left}>
                                {pluralize('item', paymentCount || 0, true)},
                                {' '}
                                {pluralize('estimated paid entry', estimatedPaidEntries || 0, true)}
                            </div>
                            <div className={s.content}>
                            <div className={s.amount + ' ' + s.total + ' ' + s.header}>
                                    Total:
                                    {' '}
                                    {numeral(totalPayments || 0).format('($0,0.00)')},
                                    {' '}
                                    less fees: {numeral(netPayments || 0).format('($0,0.00)')}
                                </div>
                            </div>
                        </div>
                    </ListGroup.Item>
                    {payments && payments.map((x, index) =>
                        <CinderellaPaymentLogItem key={`payment-item-${x.txn_id}--${index}`} item={x} 
                            onClick={() => doShowPaymentHistory(x.playerId)}
                            onDeleteLogEntry={null} deletingId={deletingId} />
                    )}
                    </ListGroup>
                    {!payments || payments.length === 0 ? 
                    <ListGroup.Item className={s.item} variant='light'>
                        <div className={s.content}>
                            No payments located for {year}
                        </div>
                    </ListGroup.Item>
                    : null}
                </Col>
            </Row>

            {paymentHistory && paymentHistory.user ?
            <Modal heading={year + ' Payment History'} size={'lg'}
                show={!!showPaymentHistory}
                onClose={() => clearPaymentHistory()}
                showFooter={false}>
                <CinderellaPaymentHistory paymentHistory={paymentHistory} 
                    addManualPayment={doAddManualPayment}
                    onDelete={doDeleteEntry}
                    onDeleteLogEntry={_transactionId => doDeleteLogEntry(_transactionId)}
                    deletingId={deletingId} />
            </Modal>
            : null}

            <Modal heading={year + ' Duplicate Payment Checker'} size={'lg'}
                show={showDuplicatesModal}
                onClose={() => setShowDuplicatesModal(false)}
                showFooter={false}>
                <CinderellaDuplicatePayments year={year} />
            </Modal>
        </div>
    )
}

export default CinderellaPayments