import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useMatch } from 'react-router-dom'
import { Row, Col, Table, Badge, ListGroup } from 'react-bootstrap'
import { getPlayoffRaceReport } from '@severed-links/common.redherrings-reducers/football'
import { find, startsWith } from 'lodash'
import SeasonSelector from '../SeasonSelector'
import * as s from './FootballLeagueReport.scss'
import PlayoffRaceReportHeaderRow from './PlayoffRaceReportHeaderRow'
import PlayoffRaceReportRow from './PlayoffRaceReportRow'
import DivisionStandingsRow from '../DivisionStandingsRow'

const PlayoffRaceReport = ({ fullWidth = false, showHeader = false, showTeamsIn = true, showTeamsOut = true }) => {

    const dispatch = useDispatch()
    const params = useParams()
    const _match = useMatch(`/football/reports/:reportId/*`)
    const seasonName = params.seasonName || null
    const reportId = ((_match || {}).params || {}).reportId || 'playoff-race'
    const seasons = useSelector(state => state.football.seasons)
    const playerId = useSelector(state => state.account._id)
    const reports = useSelector(state => state.football.reports)
    const season = seasons.find(i => i.seasonName === seasonName) || {}
    const report = reports.find(i => i.seasonId === season.seasonId && i.reportId === reportId)

    useEffect(() => { fetchReport() }, [seasonName])

    const fetchReport = () => seasonName ? dispatch(getPlayoffRaceReport(seasonName)) : null

    if (!report) return null
    const { teamsIn, teamsOut } = report
    return (
            season && season.seasonName && report && (report.teamsIn || report.teamsOut) ? 
            <div className={s.container}>
                {showHeader ?
                <Row>
                    <Col xs={12}>
                        <span style={{ float: 'right' }}><SeasonSelector seasons={seasons} season={season} /></span>
                        <h2>If The {season.seasonName} Season Ended Today...</h2>
                    </Col>
                </Row>
                : null}
                <Row>
                    {showTeamsIn ?
                    <Col sm={!fullWidth ? 6 : 12} xs={12}>
                        <ListGroup>
                        {teamsIn && teamsIn.map((i, index) => [
                        i.playoffSeed === 'D1' ? <ListGroup.Item key={'playoff-race-d1'}><strong>Division Winners</strong></ListGroup.Item> : null,
                        i.playoffSeed === 'W1' ? <ListGroup.Item key={'playoff-race-d1'}><strong>Wildcards</strong></ListGroup.Item> : null,
                        <DivisionStandingsRow key={i.teamId} 
                            divisionItem={{ ...i, avatarUrl: i.teamAvatarUrl, feagles: i.feagles }} 
                            divisionCode={i.divisionCode} showPlayoffSeed
                            playerId={playerId} 
                            shortForm={false} />
                        ])}
                        </ListGroup>
                    </Col>
                    : null}
                    {showTeamsOut ?
                    <Col sm={!fullWidth ? 6 : 12} xs={12}>
                        <ListGroup>
                            <ListGroup.Item><strong>On the outside looking in...</strong></ListGroup.Item>
                        {teamsOut && teamsOut.map((i, index) =>
                        <DivisionStandingsRow key={i.teamId} 
                            divisionItem={{ ...i, avatarUrl: i.teamAvatarUrl, feagles: i.feagles }} 
                            divisionCode={i.divisionCode} showPlayoffSeed
                            playerId={playerId} 
                            shortForm={false} />
                        )}
                        </ListGroup>
                    </Col>
                    : null}
                </Row>
            </div>
            : null
    )
}

export default PlayoffRaceReport