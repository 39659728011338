import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { ListGroup, Image, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getNflNews } from '@severed-links/common.redherrings-reducers/football'
import { find, forEach, orderBy } from 'lodash'
import moment from 'moment-timezone'
import * as s from './NFLNews.scss'

const NFLNews = () => {

    const dispatch = useDispatch()
    const nflNews = useSelector(state => state.football.nflNews)

    useEffect(() => { dispatch(getNflNews()) }, [])

    if (!nflNews) return null
    const { category, description, image, items, link, title } = nflNews
    return (
        <div className={s.container}>
            <div className={s.heading}>
                {image ? <div className={s.image}><Image src={image} /></div> : null}
                <h2 className={s.title}>{description || `NFL News`}</h2>
                <div className={s.controls}>
                    <Button onClick={() => window.open(link, '_blank')}><FontAwesomeIcon name='external-link-alt' /></Button>
                </div>
            </div>
            <ListGroup className={s.list}>
            {items && items.map(i =>
            <ListGroup.Item key={`nfl-news-item-${i.link}`} 
                className={s.item}
                action={!!i.link} 
                onClick={i.link ? () => window.open(i.link, '_blank') : null}>
                {i.title ? <h4>{i.title}</h4> : null}
                {i.description ?
                <div>
                    <p>{i.description} | <span className={s.published}>published {moment(i.published).fromNow()}</span></p>
                </div>
                : null}
            </ListGroup.Item>
            )}
            </ListGroup>
        </div>
    )
}

export default NFLNews