// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EnterOscarsPicksNavBar__container___VsW2k{max-width:90vw;margin-bottom:30px}.EnterOscarsPicksNavBar__container___VsW2k .EnterOscarsPicksNavBar__nav___CunlJ{flex-flow:row nowrap;overflow:hidden;overflow-x:scroll}`, "",{"version":3,"sources":["webpack://./src/routes/Oscars/EnterOscarsPicksNavBar.scss"],"names":[],"mappings":"AAAA,2CACI,cAAA,CACA,kBAAA,CAEA,gFACI,oBAAA,CACA,eAAA,CACA,iBAAA","sourcesContent":[".container {\n    max-width: 90vw;\n    margin-bottom: 30px;\n\n    .nav {\n        flex-flow: row nowrap;\n        overflow: hidden;\n        overflow-x: scroll;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `EnterOscarsPicksNavBar__container___VsW2k`;
export var nav = `EnterOscarsPicksNavBar__nav___CunlJ`;
export default ___CSS_LOADER_EXPORT___;
