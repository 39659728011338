import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import { getRecap, getFrontPageData } from '@severed-links/common.redherrings-reducers/cinderella'
import RecapPossibilities from './RecapPossibilities'
import RecapEntriesByYear from './RecapEntriesByYear'
import RecapPlayerEntries from './RecapPlayerEntries'
import DeadlineCountdown from '../DeadlineCountdown'
import * as s from './RecapView.scss'
import RecapArticle from './RecapArticle'
import OngoingGames from './OngoingGames'

const RecapView = () => {

    const dispatch = useDispatch()
    const recap = useSelector(state => state.cinderella.recap)
    const frontPageData = useSelector(state => state.cinderella.frontPageData)

    useEffect(() => {
        dispatch(getRecap())
        dispatch(getFrontPageData())
    }, [])

    return (
        <div className={s.container}>
            <OngoingGames />
            <Row>
                <Col sm={8} xs={12}>
                    <RecapArticle recapId={recap._id} title={recap.title} recapHtml={recap.recapHtml} author={recap.author} lastEdited={recap.lastEdited}  />
                </Col>
                <Col sm={4} xs={12}>

                    <DeadlineCountdown />

                    <h3 className={s.someNumbersHeading}>Some Numbers</h3>

                    <RecapPossibilities {...(frontPageData.possibilities || {})} />

                    <RecapEntriesByYear yearlyEntries={frontPageData.yearlyEntries} />
                </Col>
            </Row>
        </div>
    )
}

export default RecapView