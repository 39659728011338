import React, { useEffect } from 'react'
import { Nav, Navbar, OverlayTrigger, Tooltip } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './EnterOscarsPicksNavBar.scss'
import { Link, Element, Events, animateScroll, scrollSpy, scroller } from 'react-scroll'
import EnterOscarsPicksNavBarItem from './EnterOscarsPicksNavBarItem'

const EnterOscarsPicksNavBar = ({ menuItems = [], activeKey = '', onSelect, navgiation, route }) => {

    return (
        <div className={s.container}>
        <Nav className={s.nav} variant='pills' activeKey={activeKey} onClick={e => e.stopPropagation()}>
        {menuItems && menuItems.map(i => 
            <EnterOscarsPicksNavBarItem key={`oscar-picks-navbar-item-overlay-${i.step}`}
                step={i.step} isDone={i.done} title={i.stepTitle}
                category={i.category} isCurrent={i.isCurrent}
                onSelect={onSelect} />
        )}
        </Nav>
        </div>
    )
}
    
export default EnterOscarsPicksNavBar