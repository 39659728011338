import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, FormGroup, FormControl, FormLabel, Button, 
    ToggleButtonGroup, ToggleButton, Image } from 'react-bootstrap'
import * as s from './ManageUsers.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import FormCheck from 'react-bootstrap/FormCheck'
import CloudinaryImage from '../../Cloudinary/CloudinaryImage'
import CloudinaryUploader from '../../Cloudinary/CloudinaryUploader'
import Confirm from '@severed-links/common.confirm'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { updatePlayerAvatar } from '@severed-links/common.redherrings-reducers/account'
import { editUser, emailUserCredentials, saveUser, deleteUser } from '@severed-links/common.redherrings-reducers/manageUsers'
import { checkCellPhone, checkEmail } from '@severed-links/common.redherrings-reducers/siteRegistration'
import { img, playerUrl } from '@severed-links/common.redherrings-constants'
import { some, trimEnd, find, startsWith, filter, forEach } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import moment from 'moment-timezone'
import validator from 'validator'

const ManageUsersEditUser = ({ playerId = null, onClose = null }) => {

    const dispatch = useDispatch()
    const uploadedImages = useSelector(state => state.images)
    const [_editUser, setEditUser] = useState({ _id: null, isEmailAddressOk: false, isCellPhoneOk: false })
    const [_userUpdate, setUserUpdate] = useState({})
    const { _id, firstName, lastName, emailAddress, cellPhone,
        password, avatarUrl, fastLogin, lastLogin,
        userRoles, roles, userName, isEmailAddressOk, isCellPhoneOk } = _editUser

    useEffect(() => {
        if (playerId) {
            dispatch(editUser(playerId))
            .then(action => setEditUser({ 
                ...action.payload, 
                playerId,
                userRoles: [...filter(action.payload.roles, r => r.userIsInRole).map(r => r.roleName)],
            }))
        }
    }, [playerId])

    useEffect(() => { validateCellPhone() }, [cellPhone])
    useEffect(() => { validateEmail() }, [emailAddress])
    useEffect(() => {
        if (_userUpdate) {
            setEditUser({ ..._editUser, ..._userUpdate })
        }
    }, [_userUpdate])

    const handleChange = e => {
        const fieldName = e.target.name
        const value = e.target.value
        setUserUpdate({ [fieldName]: value })
    }

    const doUpdatePlayerAvatar = _uploadResults => {
        const { public_id } = _uploadResults
        if (public_id) {
            setUserUpdate({ avatarUrl: public_id })
            dispatch(updatePlayerAvatar(playerId, public_id))
        }

    }

    const validateEmail = () => {
        if (!validator.isEmpty(emailAddress || '') && validator.isEmail(emailAddress || '')) {
            dispatch(checkEmail(emailAddress, playerId))
            .then(action => setUserUpdate({ isEmailAddressOk: action.payload.isAvailable }))
        } else {
            setUserUpdate({ isEmailAddressOk: false })
        }
    }

    const validateCellPhone = () => {
        const _isMobilePhone = !validator.isEmpty(cellPhone || '') && validator.isMobilePhone(`+1${cellPhone}` || '', `en-US`)
        if (_isMobilePhone) {
            dispatch(checkCellPhone(cellPhone, playerId))
            .then(action => setUserUpdate({ isCellPhoneOk: action.payload.isAvailable }))
        } else {
            setUserUpdate({ isCellPhoneOk: false })
        }
    }

    const handleBootstrapSwitch = (e, checkedState) => {
        setUserUpdate({ [e.target.name]: checkedState })
    }

    const handleRoleChange = (roles = []) => {
        setUserUpdate({ userRoles: [...roles] })
    }

    const doSaveUser = () => {
        var saveData = { ..._editUser }
        forEach(saveData.roles, r => {
            r.userIsInRole = some(saveData.userRoles, r1 => r.roleName === r1)
        })
        delete saveData.userRoles
        dispatch(saveUser(saveData))
        .then(action => {
            dispatch(createNotification({
                type: action.payload.messageType, 
                headline: 'Save Player Profile', 
                message: action.payload.message, 
                timeout: 6000
            }))
            if (action.payload.messageType === 'success') {
                if (onClose) onClose()
            }
        })
    }

    const sendEmail = () => {
        dispatch(emailUserCredentials(playerId))
        .then(action => {
            dispatch(createNotification({
                type: action.payload.messageType, 
                headline: 'Email Login Credentials', 
                message: action.payload.message, 
                timeout: 6000
            }))
            if (onClose) onClose()
        })
    }

    const doDeleteUser = () => {
        dispatch(deleteUser(playerId))
        .then((action) => {
            dispatch(createNotification({
                type: action.payload.messageType, 
                headline: 'Delete User', 
                message: action.payload.message, 
                timeout: 6000
            }))
            if (onClose) onClose()
        })
    }

    if (!_id) return null
    return (
        <div className={s.container}>
            <Row>
                <Col sm={6} xs={12}>
                    <Row>
                        <Col xs={6}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>First name</FormLabel>
                                <FormControl name='firstName' autoCapitalize='words' 
                                    required isValid={!validator.isEmpty(firstName || '')}
                                    isInvalid={validator.isEmpty(firstName || '')}
                                    value={firstName} onChange={(e) => handleChange(e)} />
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>Last name</FormLabel>
                                <FormControl name='lastName' autoCapitalize='words' 
                                    required isValid={!validator.isEmpty(lastName || '')}
                                    isInvalid={validator.isEmpty(lastName || '')}
                                    value={lastName} onChange={(e) => handleChange(e)} />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>Email address</FormLabel>
                                <FormControl name='emailAddress' autoCapitalize='none' 
                                    required isValid={isEmailAddressOk}
                                    isInvalid={!isEmailAddressOk}
                                    value={emailAddress || ''} onChange={(e) => handleChange(e)} />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>Cell Phone</FormLabel>
                                <FormControl name='cellPhone' autoCapitalize='none' 
                                    required isValid={isCellPhoneOk}
                                    isInvalid={!isCellPhoneOk}
                                    value={cellPhone || ''} onChange={(e) => handleChange(e)} />
                            </FormGroup>
                        </Col>
                        <Col xs={12}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>New password?</FormLabel>
                                <FormControl name='password' autoCapitalize='none' 
                                    isValid={!validator.isEmpty(password || '') && validator.isLength(password || '', { min: 5 })}
                                    value={password} onChange={(e) => handleChange(e)} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} xs={12}>
                    <Row>
                        <Col xs={12}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel style={{ display: 'block' }}>Player avatar</FormLabel>
                                <div className={s.newUserAvatarContainer}>
                                    <Image src={playerUrl(avatarUrl)} className={s.avatar} />
                                    <div className={s.button}>
                                        <CloudinaryUploader originalId={avatarUrl} 
                                            showImage={false}
                                            showButtonIsBlock={false}
                                            showButton={true}
                                            showButtonSize={'sm'}
                                            folder={img.PlayerAvatarFolder}
                                            onUploadSuccess={doUpdatePlayerAvatar} />
                                        </div>
                                </div>
                            </FormGroup>
                        </Col>
                        <Col xs={6}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>Fast login?</FormLabel>
                                <div>
                                    <FormCheck type='switch' onChange={e => handleBootstrapSwitch(e, e.target.checked)} 
                                        name='fastLogin' 
                                        checked={fastLogin} />
                                </div>
                            </FormGroup>                    
                        </Col>
                        <Col xs={6}>
                            <FormGroup className={s.formGroup}>
                                <FormLabel>Last Login</FormLabel>
                                <FormControl plaintext readOnly style={{ whiteSpace: 'nowrap', overflow: 'hidden' }} value={moment(lastLogin).format('M/D/YYYY h:mm:ss A')} />
                            </FormGroup>
                        </Col>
                    </Row>
                </Col>
            </Row> 
            <Row>
                <Col xs={12}>
                    <ToggleButtonGroup className={s.userRoleToggleButtonGroup} type='checkbox' value={userRoles}
                    onChange={(roles) => handleRoleChange(roles)}>
                        {roles && roles.map(r =>
                        <ToggleButton id={`edit-user-role-toggle-button-${r.roleName}`} className={s.button + ' ' + (some(userRoles, ur => ur === r.roleName) ? s.green : s.red)} key={r.roleName} value={r.roleName}>{r.displayName}</ToggleButton>
                        )}
                    </ToggleButtonGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={4}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>&nbsp;</FormLabel>
                        <Confirm onConfirm={() => sendEmail()}
                                title={'Email Login Credentials'}
                                variant='info' 
                                confirmText='send login credentials'
                                body={`Are you sure you want to send login credentials to ${firstName} ${lastName} at email address ${emailAddress}?`}
                                className={s.blockButton} buttonIcon='envelope' buttonText='email login' />
                    </FormGroup>
                </Col>
                <Col xs={4}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>&nbsp;</FormLabel>
                        <Confirm onConfirm={() => doDeleteUser()}
                                title={'Delete User'}
                                confirmText='confirm delete'
                                body={`Are you sure you want to delete ${firstName} ${lastName} (${userName})`}
                                className={s.blockButton} variant='danger' buttonIcon='trash' buttonText='delete user' />
                    </FormGroup>
                </Col>
                <Col xs={4}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>&nbsp;</FormLabel>
                        <Button className={s.blockButton} variant='primary' 
                            onClick={() => doSaveUser()}>
                            <FontAwesomeIcon name='check' /> 
                            {' '}
                            <span className='d-none d-sm-inline'>save user</span>
                        </Button>
                    </FormGroup>                    
                </Col>
            </Row> 
        </div>
    )
}


export default ManageUsersEditUser
