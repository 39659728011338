import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, Button, FormControl, FormLabel, FormGroup, InputGroup } from 'react-bootstrap'
import { addSeason } from '@severed-links/common.redherrings-reducers/footballAdmin'
import { getSeasons } from '@severed-links/common.redherrings-reducers/football'
import moment from 'moment-timezone'
import { first, last } from 'lodash'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import * as s from './EditSeasons.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Modal from '@severed-links/common.modal'

const EditSeasons = ({ navigation, route }) => {

    const dispatch = useDispatch()
    const seasons = useSelector(state => state.football.seasons)
    const [showAddSeasonModal, setShowAddSeasonModal] = useState(false)
    const [seasonName, setSeasonName] = useState(`${moment().year()}-${moment().year()+1}`)
    
    useEffect(() => {
        dispatch(getSeasons(true))
        .then(action => {
            if (action.payload && action.payload.length) {
                const _seasonName = first(action.payload).seasonName
                if (_seasonName.indexOf('-') > -1) {
                    const _parts = _seasonName.split('-').map(i => parseInt(i))
                    const _firstPart = first(_parts)
                    if (!isNaN(_firstPart)) {
                        const _nextSeasonName = `${_firstPart + 1}-${_firstPart + 2}`
                        setSeasonName(_nextSeasonName)
                    }
                }
            }
        })
    }, [])

    const save = () => {
        dispatch(addSeason(seasonName))
        .then(action => {
            dispatch(createNotification(action.payload))
            if (action.payload.messageType === 'success') {
                dispatch(getSeasons())
                setShowAddSeasonModal(false)
            }
        })
    }

    const isValid = () => {
        const _testMatch = /^\d{4}\-\d{4}$/.test(seasonName)
        if (_testMatch) {
            const _parts = seasonName.split('-').map(i => parseInt(i))
            return _parts.length === 2 && 
                !isNaN(first(_parts)) &&
                !isNaN(last(_parts)) &&
                last(_parts) === first(_parts) + 1 &&
                !seasons.some(i => i.seasonName === seasonName)
        }
        return false
    }

    const _isValid = isValid()
    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}>Edit Seasons</h2>
                <div className={s.controls}>
                    <Button variant='light' onClick={() => setShowAddSeasonModal(true)}><FontAwesomeIcon name='plus' /></Button>
                </div>
            </div>
            <ListGroup className={s.list}>
                {seasons && seasons.map((i, _index) =>
                <ListGroup.Item className={s.item} key={`season-${i.seasonId}`}>
                    <div className={s.icon}>
                        <FontAwesomeIcon name='leaf' fixedWidth />
                    </div>
                    <div className={s.content}>
                        <div className={s.seasonName}>{i.seasonName}</div>
                        <div className={s.seasonDetails}>{i.weeks?.length ? <div className={s.detailItem}><FontAwesomeIcon name='calendar' className={s.detailIcon} /><span>{i.weeks.length}</span></div> : null}{i.playoffWeeks?.length ? <div className={s.detailItem}><FontAwesomeIcon name='trophy' className={s.detailIcon} /><span>{i.playoffWeeks.length}</span></div> : null}</div>
                    </div>
                </ListGroup.Item>
                )}
            </ListGroup>
            <Modal show={showAddSeasonModal}
                heading='Add a season...'
                actionButtonIcon='leaf'
                actionButtonStyle='primary'
                actionButtonText={'save new season'}
                actionButtonDisabled={!_isValid}
                actionButtonOnClick={() => save()}
                onClose={() => setShowAddSeasonModal(false)}>
                <div className={s.addSeasonModalContainer}>
                    <FormGroup>
                        <FormLabel>New Season Name</FormLabel>
                        <InputGroup>
                            <FormControl value={seasonName || ''}
                                onChange={e => setSeasonName(e.target.value)} />
                            <InputGroup.Text><FontAwesomeIcon name={_isValid ? 'check' : 'times'} className={s.icon + ' ' + s[`${_isValid ? 'success' : 'danger'}`]} /></InputGroup.Text>
                        </InputGroup>
                    </FormGroup>
                </div>
            </Modal>
        </div>
    )
}

export default EditSeasons
