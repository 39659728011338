import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { find, orderBy, findIndex, filter } from 'lodash'
import { INITIAL_STATE } from '@severed-links/common.redherrings-constants'

const SeasonModeSelector = ({ seasonMode = 'Preseason', showPreseason = true, onSelect = null }) => {

    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()

    const onItemSelect = e => {
        if (onSelect) {
            onSelect(e)
        } else {
            const _replaceText = e === 'Regular' ? 'regular-season' : e.toLowerCase().replace(' ', '-')
            navigate(location.pathname.replace(params.seasonMode, _replaceText))
        }
    }

    const modes = [...INITIAL_STATE.football.seasonModes]
        .filter(m => m.value !== (!showPreseason ? 'Preseason' : null))
    return (
        <DropdownButton variant='light' 
            title={seasonMode ? <span>{seasonMode}</span> : <span>[Select a season mode...]</span>} 
            id='SeasonModeSelector-dropdown' 
            onSelect={e => onItemSelect(e)}>
        {modes.map(m =>
            <Dropdown.Item key={m.value} eventKey={m.value}>{m.text}</Dropdown.Item>
        )}
        </DropdownButton>
    )
}

export default SeasonModeSelector
