import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Button, ButtonGroup, 
    DropdownButton, FormControl, FormGroup, 
    FormLabel, Alert, Dropdown, ListGroup } from 'react-bootstrap'
import { v4 as uuidv4 } from 'uuid'

import FormCheck from 'react-bootstrap/FormCheck'
import PickReminderSelector from '../PickReminderSelector'

import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './EditTeamProfile.scss'
import { find, some, reject, omit } from 'lodash'

import CloudinaryUploader from '../../Cloudinary/CloudinaryUploader'

import RedHerringsPayPalButton from '../../Payment/RedHerringsPayPalButton'

import { getTeamsByPlayer, getEditTeamProfile, saveEditTeamProfile, updateTeamAvatar } from '@severed-links/common.redherrings-reducers/football'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'

import HtmlEditor from '../../Editors/HtmlEditor'

import EditTeamProfileTeamList from './EditTeamProfileTeamList'

const EditTeamProfile = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const teamId = params.teamId || null
    const playerId = useSelector(state => state.account._id)
    const paymentEmail = useSelector(state => state.football.paymentEmail)
    const editedBackground = useSelector(state => state.editors.teamProfile)
    const teams = useSelector(state => state.football.editProfileTeams)
        .map(i => ({ ...i, isActiveTeam: i.teamId === teamId }))
    const uploadedImages = useSelector(state => state.images)
    const [activeSeason, setActiveSeason] = useState('')
    const [profile, setProfile] = useState({})
    const [isFetching, setFetching] = useState(false)
    const [areRemindersUnsaved, setRemindersUnsaved] = useState(false)

    useEffect(() => { loadProfile() }, [teamId])

    const loadProfile = () => {
        dispatch(getEditTeamProfile(teamId))
        .then(action => setProfile(action.payload))
    }

    const copyProfileFrom = team => {
        dispatch(getEditTeamProfile(team.teamId))
        .then(response => { 
            var copiedProfile = omit({ ...response.payload }, ['_id','playerId', 'seasonId','seasonName','teamName','division'])
            var updatedProfile = { ...profile, ...copiedProfile }
            setProfile(updatedProfile)
            dispatch(createNotification({ type: 'warning', 
                headline: 'Profile copied from ' + team.seasonName + ' ' + team.teamName, 
                message: 'You still need to press the save button.', 
                timeout: 7500 
            }))
        })
    }

    const handleSwitchChange = (e, state) => {
        setProfile({ ...profile, [e.target.name]: state })
    }
    const handleReminderChange = (_data = {}) => {
        setProfile({ ...profile, ..._data })
        setRemindersUnsaved(true)
    }
    const onPickRankStringSelect = e => setProfile({ ...profile, [e.target.name]: e.target.value })

    const doUpdateTeamAvatar = _uploadResults => {
        const { public_id: avatarUrl } = _uploadResults
        const { _id: teamId } = profile
        if (teamId && avatarUrl) {
            dispatch(updateTeamAvatar(teamId, avatarUrl))
            .then(action => {
                setProfile({ ...profile, avatarUrl: action.payload.avatarUrl })
                loadProfile()
                dispatch(getTeamsByPlayer(playerId))
            })
        }
    }

    const saveProfile = () => {
        var postData = {
            playerId,
            teamId: profile._id,
            backgroundInfo: editedBackground,
            pickString: profile.pickString,
            rankString: profile.rankString,
            sendEmailReminder: profile.sendEmailReminder || false,
            sendTextReminder: profile.sendTextReminder || false,
            sendPushReminder: profile.sendPushReminder || false,
            sendEmailKeyGameList: profile.sendEmailKeyGameList,
        }
        dispatch(saveEditTeamProfile(postData))
        .then(() => {
            dispatch(createNotification({ type: 'success', 
                headline: 'Save Profile', 
                message: 'Your profile is saved.', 
                timeout: 5000 
            }))
            dispatch(getTeamsByPlayer(playerId))
            setRemindersUnsaved(false)
        })
        
    }

    const onAuthorizedFootballPayment = () => setProfile({ ...profile, paid: true })

    const onCancelledFootballPayment = () => setProfile({ ...profile, paid: false })

    return (
        <div>
            <Row>
                <Col xs={12}>
                    <h2>Edit my team profile</h2>
                </Col>
            </Row>
            <Row>
                <Col md={3} sm={4} xs={12}>
                    <EditTeamProfileTeamList />
                </Col>
                <Col md={9} sm={8} xs={12}>
                {profile.teamName ? 
                    <div>
                        {profile && !profile.paid ? 
                        <Row>
                            <Col xs={12}>
                                <Alert variant='danger'>
                                    <strong>Entry Fee Payment Needed!</strong>
                                    Please use the button below to pay your entry fee.
                                    <RedHerringsPayPalButton
                                        contest={'Football'}
                                        description={'RedHerrings Football ' + activeSeason}
                                        itemNumber={playerId}
                                        receiverEmail={paymentEmail}
                                        amount={profile.entryFee}
                                        seasonId={profile.seasonId}
                                        playerId={profile.playerId}
                                        data={profile.playerId.toString() + '|' + profile.seasonId.toString()}
                                        onAuthorizedPayment={onAuthorizedFootballPayment}
                                        onCancelledPayment={onCancelledFootballPayment} />

                                </Alert>
                            </Col>
                        </Row>
                        : null}
                        <Row>
                            <Col xs={12}>
                                <div className={s.profileHeading}>
                                    <div className={s.title}><h4>Edit: {activeSeason} {profile.teamName}</h4></div>
                                    <div className={s.controls}>
                                        <ButtonGroup>
                                            {teams && teams.length > 1 ?
                                            <DropdownButton variant='light'
                                                title={<span><span><FontAwesomeIcon name='copy' /> copy profile...</span></span>} 
                                                id='rh-football-edit-profile-copy-profile-from-another-team'>
                                            {reject(teams, t => t.isActiveTeam).map(i => 
                                                <Dropdown.Item key={i.teamId} eventKey={i.teamId} onClick={() => copyProfileFrom(i)}>{i.seasonName} {i.teamName}</Dropdown.Item>
                                            )}
                                            </DropdownButton>
                                            : null}
                                            <Button variant='light' onClick={() => navigate(`/football/team-profile/${teamId}`)}><FontAwesomeIcon name='times' /> cancel</Button>
                                            <Button variant='primary' onClick={() => saveProfile()}><FontAwesomeIcon name='check' /> save</Button>
                                        </ButtonGroup>
                                    </div>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col xs={3}>
                                <div className={s.details}>
                                    <FormLabel>Team Avatar</FormLabel>
                                    <CloudinaryUploader originalId={profile.avatarUrl} 
                                        folder={'/football/team-avatars'}
                                        transformationName='large_avatar_200'
                                        onUploadSuccess={doUpdateTeamAvatar} />
                                </div>
                            </Col>
                            <Col xs={9}>
                                <div className={s.details}>
                                    <FormLabel>Background Information</FormLabel>
                                    <div>
                                        <HtmlEditor content={profile.backgroundInfo} 
                                            name={'teamProfile'}
                                            showToolbar
                                            placeholder={'Enter some information about you...'} />
                                    </div>
                                </div>
                                <div className={s.details}>
                                    <Row>
                                        <Col xs={6}>
                                            <FormGroup className={s.formGroup}>
                                                <FormLabel>Auto-Picks</FormLabel>
                                                <FormControl as='select' name='pickString' 
                                                    value={profile.pickString} 
                                                    onChange={(e) => onPickRankStringSelect(e)}>
                                                    <option value='Favorites'>All favorites</option>
                                                    <option value='Underdogs'>All underdogs</option>
                                                    <option value='Random'>Random</option>
                                                </FormControl>
                                            </FormGroup>
                                        </Col>
                                        <Col xs={6}>
                                            <FormGroup className={s.formGroup}>
                                                <FormLabel>Auto-Ranks</FormLabel>
                                                <FormControl as='select' name='rankString' 
                                                    value={profile.rankString} 
                                                    onChange={(e) => onPickRankStringSelect(e)}>
                                                    <option value='Ascending'>Ascending 1-16</option>
                                                    <option value='Descending'>Descending 16-1</option>
                                                    <option value='Random'>Random</option>
                                                </FormControl>
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                    <Row>
                                        <Col xs={6}>
                                            <FormGroup className={s.formGroup}>
                                                <FormLabel>Send pick reminder?</FormLabel>
                                                <PickReminderSelector sendEmailReminder={profile.sendEmailReminder}
                                                    sendTextReminder={profile.sendTextReminder}
                                                    sendPushReminder={profile.sendPushReminder}
                                                    onChange={_data => handleReminderChange(_data)} />
                                                {areRemindersUnsaved ?
                                                <Alert variant='warning' className={s.remindersUnsavedAlert}>Make sure you save your profile with these updated settings.</Alert>
                                                : null}
                                            </FormGroup>
                                        </Col>
                                        <Col xs={6}>
                                            <FormGroup className={s.formGroup}>
                                                <FormLabel>Send email game listing</FormLabel>
                                                <FormCheck type='switch' name='sendEmailKeyGameList' 
                                                    onChange={e => handleSwitchChange(e, e.target.checked)}
                                                    checked={profile.sendEmailKeyGameList || false} />
                                            </FormGroup>
                                        </Col>
                                    </Row>
                                </div>
                            </Col>
                        </Row>
                        <Row style={{ marginTop: '2rem' }}>
                            <Col sm={{ span: 9, offset: 3 }} xs={12}>
                                <Alert variant='info'>
                                    <Alert.Heading>Edit Your Personal Information</Alert.Heading>
                                    <p>
                                    <b>Reminder</b>: You can also update your personal avatar 
                                    and other information about you on your personal profile editor page.
                                    </p>
                                    <hr />
                                    <div className='d-flex justify-content-end'>
                                        <Button variant='info' onClick={() => navigate(`/main/edit-profile`)}>My profile{' '}<FontAwesomeIcon name='chevron-right' /></Button>
                                    </div>
                                </Alert>
                            </Col>
                        </Row>
                    </div>
                : null}
                </Col>
            </Row>
        </div>
    )
}

export default EditTeamProfile