import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Alert, Image, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './EliminatorCurrentPick.scss'
import { nflTeamImage, img } from '@severed-links/common.redherrings-constants'
import moment from 'moment-timezone'
import { getPlayerPick } from '@severed-links/common.redherrings-reducers/eliminator'
import CloudinaryImage from '../Cloudinary/CloudinaryImage'
import { startsWith } from 'lodash'

const EliminatorCurrentPick = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const playerId = useSelector(state => state.account._id)
    const { seasons, seasonId, seasonName, seasonMode, pickDeadline, week, playoffWeek, myPick, standings } = useSelector(state => state.eliminator)
    const _myPick = myPick || {}
    const _scoreboardPlayers = ((standings || {})[`${seasonId || null}`] || {}).scoreboardPlayers || []
    const _scoreboardPlayer = (_scoreboardPlayers || []).find(i => i.playerId === playerId) || {}
    const isEliminatorPlayerThisSeason = !!playerId && _scoreboardPlayer.playerId === playerId
    const isEliminated = isEliminatorPlayerThisSeason && !!_scoreboardPlayer.isEliminated

    const { pick, opponent, label, isHome, 
        gameDate, hasPick, hasOpponent, nflGame } = _myPick
    const { homeScore, visitorScore, isInProgress, isComplete, status } = nflGame || {}
    const _afterDeadline = moment().isAfter(moment(pickDeadline))
    const _currentWeek = seasonMode === 'Postseason' ? playoffWeek : week
    const [lastUpdate, setLastUpdate] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [isMounted, setMounted] = useState(false)
    const [currentTime, setCurrentTime] = useState(moment().toISOString())
    const _kickoff = nflGame?.date && moment(nflGame.date).isValid() ? moment(nflGame?.date).local() : null
    const _isAfterKickoff = nflGame?.date && moment(nflGame.date).isValid() && moment(currentTime).isSameOrAfter(moment(nflGame.date))
    const _pregameDetail = opponent?.mascot && nflGame?.date ? `${isHome ? `At home` : `On the road`} against the ${opponent?.mascot || ''} on ${moment(nflGame.date).local().format('dddd, MMMM D')} at ${moment(nflGame.date).local().format('h:mm a')}` : ''
    const _scoreDetail = homeScore !== null && visitorScore !== null && !isNaN(homeScore) && !isNaN(visitorScore) ? `${homeScore > visitorScore ? homeScore : visitorScore}-${homeScore > visitorScore ? visitorScore : homeScore}` : ``
    const _resultDetail = _scoreDetail ?
    (
        isHome && isInProgress && homeScore > visitorScore ? 'Winning' :
        isHome && isInProgress && homeScore < visitorScore ? 'Losing' :
        isHome && isInProgress && homeScore === visitorScore ? 'Tied' :
        isHome && isComplete && homeScore > visitorScore ? 'Won' :
        isHome && isComplete && homeScore < visitorScore ? 'Lost' :
        isHome && isComplete && homeScore === visitorScore ? 'Tie' :
        !isHome && isInProgress && visitorScore > homeScore ? 'Winning' :
        !isHome && isInProgress && visitorScore < homeScore ? 'Losing' :
        !isHome && isInProgress && visitorScore === homeScore ? 'Tied' :
        !isHome && isComplete && visitorScore > homeScore ? 'Won' :
        !isHome && isComplete && visitorScore < homeScore ? 'Lost' :
        !isHome && isComplete && visitorScore === homeScore ? 'Tie' :
        ''
    )
    : ``
    const _gameDetail = `${_resultDetail ? `${_resultDetail}, ` : ``}${_scoreDetail || ''}${status ? ` (${status})` : ``}`

    useEffect(() => {
        let timerId = setInterval(() => setCurrentTime(moment().toISOString()), 2 * 60 * 1000)
        return () => clearInterval(timerId)
    }, [])

    useLayoutEffect(() => {
        if (playerId && seasonName && seasonMode && (week || playoffWeek)) {
            getPick()
            setLastUpdate(moment().toISOString())
        }
    }, [seasonName, seasonMode, week, playoffWeek, currentTime])
    
    const getPick = () => {
        setLoading(true)
        dispatch(getPlayerPick(seasonName, seasonMode, seasonMode === 'Postseason' ? playoffWeek : week))
        .then(() => {
            setLoading(false)
            setMounted(true)
        })
    }

    if (!isMounted || isLoading || !_myPick.seasonId || seasonMode === 'Preseason' || !_scoreboardPlayers.length || isEliminated) return null
    return (
        <div className={s.container}>
            <Alert variant={hasPick ? 'success' : 'danger'} className={s.alertContainer}>
                <div className={s.mascot}>
                {hasPick ?
                    <Image src={nflTeamImage(pick.mascot)} />
                :
                    <CloudinaryImage publicId={img.FootballBlankHelmet} />
                }
                </div>
                <div className={s.message}>
                    <div className={s.pickLabel}>{label}</div>
                    <div className={s.pickDetail}>{hasPick ? `Your pick this week: ${pick.mascot}` : `You have no pick recorded this week`}</div>
                    {!_isAfterKickoff && _pregameDetail ? <div className={s.gameDetail}>{_pregameDetail}</div> : null}
                    {_isAfterKickoff && _gameDetail ? <div className={s.gameDetail}>{_gameDetail}</div> : null}
                </div>
                {!_afterDeadline ?
                <div className={s.controls}>
                    <Button variant={hasPick ? 'success' : 'danger'} onClick={() => navigate(`/eliminator/enter-pick`)}><FontAwesomeIcon name='chevron-right' /></Button>
                </div>
                : null}
            </Alert>
        </div>
    )
}

export default EliminatorCurrentPick
