import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getConfig } from '@severed-links/common.redherrings-reducers/footballAdmin'
import { find, some } from 'lodash'
import numeral from 'numeral'
import * as s from './FootballConfigSummary.scss'

const FootballConfigSummary = () => {

    const dispatch = useDispatch()
    const seasonId = useSelector(state => state.football.seasonId)
    const seasonName = useSelector(state => state.football.seasonName)
    const seasons = useSelector(state => state.football.seasons)
    const config = useSelector(state => state.footballAdmin.config)

    useEffect(() => { loadConfig() }, [seasonName])

    const loadConfig = () => seasonName ? dispatch(getConfig(seasonName)) : null

    if (!config) return null
    const { seasonConfig, globalConfig } = config || {}
    const activeSeasonName = config && config.globalConfig && some(seasons, { seasonId: config.globalConfig.activeSeason }) ? find(seasons, { seasonId: config.globalConfig.activeSeason }).seasonName : ''
    const activeSeasonStatus = config && config.seasonConfig ? 
        `${activeSeasonName} ` + 
        (seasonConfig.seasonMode === 'Postseason' ? ` Postseason Week ${seasonConfig.activePlayoffWeek}` 
            : seasonConfig.seasonMode === 'Regular' ? ` Regular Season Week ${seasonConfig.activeWeek}` : `Preseason`)
        : ''
    const configs = [
        { _id: 10, title: `Active season`, value: activeSeasonName },
        { _id: 12, title: `${activeSeasonName} Season status`, value: activeSeasonStatus },
        { _id: 20, title: `${activeSeasonName} Team Registration`, value: (seasonConfig || {}).teamRegistration },
        { _id: 30, title: `${activeSeasonName} Registration Code`, value: (seasonConfig || {}).entryCode },
        { _id: 40, title: `${activeSeasonName} Entry Fee`, value: numeral((seasonConfig || {}).entryFee).format('($0,0.00)') },
        { _id: 50, title: `${activeSeasonName} PayPal Email`, value: (seasonConfig || {}).payPalAccount },
    ].filter(i => i.value)
    return (
        <div className={s.container}>
            <h4>Configuration</h4>
            <ListGroup className={s.list}>
            {configs && configs.map(i =>
                <ListGroup.Item key={`config-${i._id}`} className={s.item}>
                    <div className={s.heading}>{i.title}</div>
                    <div className={s.value}>{i.value}</div>
                </ListGroup.Item>
            )}
            </ListGroup>
        </div>
    )
}

export default FootballConfigSummary
