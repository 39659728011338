import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, FormGroup, FormControl, FormLabel, InputGroup, Button, ListGroup } from 'react-bootstrap'
import * as s from './OscarsBroadcastMessage.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import HtmlEditor from '../../Editors/HtmlEditor'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { sendOscarsBroadcastMessage } from '@severed-links/common.redherrings-reducers/oscarsAdmin'
import { trim, endsWith } from 'lodash'
import Picker from '@severed-links/common.picker'

const OscarsBroadcastMessage = () => {

    const dispatch = useDispatch()
    const year = useSelector(state => state.oscars.year)
    const pickDeadline = useSelector(state => state.oscars.pickDeadline)
    const broadcastMessage = useSelector(state => state.oscarsAdmin.broadcastMessage)
    const editorContent = useSelector(state => state.editors.oscarsAdminEmailMessageBody)
    const [messageType, setMessageType] = useState('')
    const [subject, setSubject] = useState('')
    const [body, setBody] = useState('')
    const [oscarsAdminEmailMessageBody, setOscarsAdminEmailMessageBody] = useState('')
    const [showMessageTypePicker, setShowMessageTypePicker] = useState(false)

    useEffect(() => { getData() }, [year])

    const getData = () => {
        setMessageType('')
        setBody('')
        setSubject('')
        setOscarsAdminEmailMessageBody('')
    }

    const doBroadcastMessage = () => {
        var emailMessage = { messageType, subject, body: editorContent }
        dispatch(sendOscarsBroadcastMessage(emailMessage))
        .then(action => dispatch(createNotification({
            type: action.payload.messageType, 
            headline: 'Broadcast Message', 
            message: action.payload.message, 
            timeout: 6000 
        })))
    }

    const insertFieldName = text => {
        var _editorContent = editorContent
        _editorContent = trim(_editorContent)
        if (endsWith(_editorContent, '</p>'))
        _editorContent = _editorContent.replace(/\<\/p\>$/g, '')
        _editorContent += '[' + text + ']</p>'
        setBody(_editorContent)
    }

    const _messageTypes = [
        { title: '[Select recipients...]', value: '', icon: 'envelope' },
        { title: 'Players With Entries This Year', value: 'Current', icon: 'envelope' },
        { title: 'All Registered Oscars Players', value: 'All', icon: 'envelope' },
        { title: 'Administrators Only (for testing)', value: 'Admin', icon: 'envelope' },
    ]

    return (
        <div className={s.container}>
            <FormGroup className={s.formGroup}>
                <FormLabel>Recipients</FormLabel>
                <FormControl placeholder='Message type...' 
                    tabIndex={-1} readOnly className={s.readOnlyInput}
                    name='messageType'
                    value={_messageTypes.find(i => i.value === messageType).title}
                    onClick={() => setShowMessageTypePicker(true)} />
            </FormGroup>

            <FormGroup className={s.formGroup}>
                <FormLabel>Subject</FormLabel>
                <FormControl placeholder='Subject' autoCapitalize='words' name='subject'
                    onChange={e => setSubject(e.target.value)}
                    value={subject} />
            </FormGroup>

            <FormGroup className={s.formGroup}>
                <FormLabel style={{ width: '100%' }}>
                    Message body
                </FormLabel>
                <div>
                    <HtmlEditor content={body} showToolbar
                        name={'oscarsAdminEmailMessageBody'}
                        placeholder={'Email message body...'} />
                </div>
            </FormGroup>

            <FormGroup className={s.formGroup}>
                <Button variant='primary' onClick={() => doBroadcastMessage()}><FontAwesomeIcon name='envelope' /> send</Button>
            </FormGroup>

            <Picker show={showMessageTypePicker} onClose={() => setShowMessageTypePicker(false)}
                heading={`Select a message type...`}
                items={_messageTypes}
                onSelect={_item => setMessageType(_item.value)}
                defaultValue={messageType} />
        </div>
    )
}

export default OscarsBroadcastMessage