import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useMatch } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'
import { getReport } from '@severed-links/common.redherrings-reducers/football'
import { find, startsWith, isEqual } from 'lodash'
import SeasonSelector from '../SeasonSelector'
import * as s from './FootballLeagueReport.scss'
import PlayerTeam from '../PlayerTeam'
import PlayerTeamListItem from '../PlayerTeamListItem'
import numeral from 'numeral'

const FootballLeagueReport = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const _match = useMatch(`/football/reports/:reportId/*`)
    const seasonName = params.seasonName || null
    const reportId = ((_match || {}).params || {}).reportId || null
    const seasons = useSelector(state => state.football.seasons)
    const playerId = useSelector(state => state.account._id)
    const reports = useSelector(state => state.football.reports)
    const season = seasons.find(i => i.seasonName === seasonName) || {}
    const seasonId = season.seasonId || null
    const report = reports.find(i => i.seasonId === seasonId && i.reportId === reportId)
    const isToughDivisionReport = reportId === 'toughest-division'

    useEffect(() => { fetchReport() }, [seasonId, reportId])

    const fetchReport = () => {
        if (seasonId && seasonName && reportId && reportId !== 'pick-percentage' && reportId !== 'top-score-odds' && 
            reportId !== 'playoff-race' && reportId !== 'pool-geography' && reportId !== 'favorite-nfl-teams') {
            dispatch(getReport(seasonId, reportId))
        }
    }

    if (!report) return null
    return (
        season.seasonName && report && reportId != '' 
        && (reportId !== 'pick-percentage' && reportId !== 'top-score-odds' && 
        reportId != 'playoff-race' && reportId !== 'pool-geography' && reportId !== 'favorite-nfl-teams') ? 
        <div className={s.container}>
            <div className={s.titleBar}>
                <h2 className={s.title}>{season.seasonName} {report.title}</h2>
                <div className={s.seasonSelector}><SeasonSelector season={season} seasons={seasons} /></div>
            </div>
            <ListGroup className={s.reportList}>
            {report.items && report.items.map((i, index) => 
                !isToughDivisionReport ?
                <PlayerTeamListItem key={`report-${reportId}-${index}`}
                    teamId={i.teamId} teamName={i.teamName} teamNameRaw={i.teamName} 
                    avatarUrl={i.avatarUrl} 
                    division={i.division} showDivision
                    week={i.week} showWeek
                    rank={i.rank} showRank
                    right={numeral(i.score).format(report.numberFormat)}
                    detail={false}
                    variant={i.playerId === playerId ? 'warning' : null} />
                : 
                <ListGroup.Item className={s.item} key={`report-${reportId}-${index}`}>
                    <div className={s.division}>{i.division}</div>
                    <div className={s.value}>{numeral(i.score).format(report.numberFormat)}</div>
                </ListGroup.Item>
            )}
            {!report.items || report.items.length === 0 ?  
                <ListGroup.Item className={s.item}>
                    No records located.
                </ListGroup.Item>
            : null}
            </ListGroup>
        </div>
        : null
    )
}

export default FootballLeagueReport