// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TeamDataTeamList__container___VFb8H{margin-bottom:1.5rem}.TeamDataTeamList__container___VFb8H .TeamDataTeamList__divisionName___QsgyC{min-width:0;white-space:nowrap;overflow:hidden;text-overflow:ellipsis;z-index:2}@media screen and (max-width: 991px){.TeamDataTeamList__container___VFb8H .TeamDataTeamList__divisionName___QsgyC{font-size:.75rem}}`, "",{"version":3,"sources":["webpack://./src/routes/Football/Admin/TeamDataTeamList.scss"],"names":[],"mappings":"AAEA,qCACI,oBAAA,CAEA,6EACI,WAAA,CACA,kBAAA,CACA,eAAA,CACA,sBAAA,CACA,SAAA,CACA,qCANJ,6EAOQ,gBAAA,CAAA","sourcesContent":["@use '../../../styles/variables/breakpoints' as breakpoints;\n\n.container {\n    margin-bottom: 1.5rem;\n\n    .divisionName {\n        min-width: 0;\n        white-space: nowrap;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        z-index: 2;\n        @media screen and (max-width: breakpoints.$screen-md-max) {\n            font-size: 0.75rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `TeamDataTeamList__container___VFb8H`;
export var divisionName = `TeamDataTeamList__divisionName___QsgyC`;
export default ___CSS_LOADER_EXPORT___;
