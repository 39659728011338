// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CaptureGameTime__container___E4LRy{display:flex;flex-flow:row nowrap;justify-content:flex-start;align-items:center;font-size:inherit;color:inherit}.CaptureGameTime__container___E4LRy .CaptureGameTime__icon___x73wU{margin-right:.25rem}`, "",{"version":3,"sources":["webpack://./src/routes/Capture/CaptureGameTime.scss"],"names":[],"mappings":"AAEA,oCACI,YAAA,CACA,oBAAA,CACA,0BAAA,CACA,kBAAA,CACA,iBAAA,CACA,aAAA,CAEA,mEACI,mBAAA","sourcesContent":["@use '../../styles/variables/colors' as colors;\n\n.container {\n    display: flex;\n    flex-flow: row nowrap;\n    justify-content: flex-start;\n    align-items: center;\n    font-size: inherit;\n    color: inherit;\n\n    .icon {\n        margin-right: 0.25rem;\n    }\n\n    .gameTime {\n\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `CaptureGameTime__container___E4LRy`;
export var icon = `CaptureGameTime__icon___x73wU`;
export default ___CSS_LOADER_EXPORT___;
