import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router'
import {  } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import moment from 'moment-timezone'
import * as s from './CaptureCalendarBar.scss'
import { last } from 'lodash'

const CaptureCalendarBar = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const navigate = useNavigate()
    const tourneyDays = useSelector(state => state.capture.tourneyDays)
    const contestId = params.contestId || null
    const currentDate = params.currentDate || null
    const _currentDateUrlFormat = 'YYYY-MM-DD'
    const _currentDateRef = useRef(null)
    const _containerRef = useRef(null)

    useEffect(() => { scrollToCalendarItem() }, [currentDate])

    const setActiveDate = d => navigate(`/capture/${contestId}/tourney-games/${moment(d.date).format(_currentDateUrlFormat)}`)

    const isSameAsCurrent = calendarDate => {
        if (!calendarDate) return false
        const d1 = moment(calendarDate)
        const d2 = moment(currentDate, _currentDateUrlFormat)
        return d1.isSame(d2, 'day')
    }

    const scrollToCalendarItem = () => {
        if (currentDate) {
            setTimeout(() => {
                window.scrollTo(0, 0)
                _currentDateRef.current?.scrollIntoView({ behavior: 'smooth', inline: 'center' })
            }, 50)
        }
    }

    return (
        <div className={s.calendarBarListContainer}>
            <ul className={s.calendarBarList}>
            {tourneyDays && tourneyDays.map((d, index) =>
                <li className={s.calendarBarListItem + (isSameAsCurrent(d.date) ? ' ' + s.activeItem : '')} 
                    key={index} onClick={() => setActiveDate(d)} ref={isSameAsCurrent(d.date) ? _currentDateRef : null}>
                    <div className={s.month + (isSameAsCurrent(d.date) ? ' ' + s.activeItem : '')}>{moment(d.date).format('MMM')}</div>
                    <div className={s.date + (isSameAsCurrent(d.date) ? ' ' + s.activeItem : '')}>{moment(d.date).format('D')}</div>
                    <div className={s.gameCount + (isSameAsCurrent(d.date) ? ' ' + s.activeItem : '')}>{d.count} {d.count !== 1 ? 'games' : 'game'}</div>
                </li>
            )}        
            </ul>
            {!tourneyDays || !tourneyDays.length ?
            <p>No games entered yet.</p>
            : null}
        </div>
    )
}

export default CaptureCalendarBar