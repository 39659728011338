import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import moment from 'moment-timezone'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { setOscarsOnlineUsers } from '@severed-links/common.redherrings-reducers/oscars'

const FootballRedirector = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const seasonMode = useSelector(state => state.football.seasonMode)
    const seasonName = useSelector(state => state.football.seasonName)
    const [isRouted, setRouted] = useState(false)

    useEffect(() => { setRouted(false) }, [seasonMode, seasonName])
    useEffect(() => { routeToSeason() }, [isRouted])

    const routeToSeason = () => {
        if (isRouted) return 
        
        if (!seasonName || !seasonMode || location.pathname !== '/football') {
            setRouted(true)
            return
        }        
        
        var seasonModePath = 'regular-season'
        if (seasonMode === 'Postseason') seasonModePath = 'postseason'
        else if (seasonMode === 'Preseason') seasonModePath = 'preseason'
        const path = `/football/${seasonModePath}/${seasonName}`
        setRouted(true)
        navigate(path, { replace: true })
    }

    return (
        <div>
            {!isRouted ?
                <div>
                    <FontAwesomeIcon name='circle-notch' spin size='3x' /> 
                </div>
            : null}
        </div>
    )
}

export default FootballRedirector