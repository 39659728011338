// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CinderellaBadGameDataAlert__container___dReo1{margin:1rem 0px;display:flex;flex-flow:row wrap;justify-content:flex-start;align-items:center}.CinderellaBadGameDataAlert__container___dReo1 .CinderellaBadGameDataAlert__heading___ekJHN{font-weight:700;margin-right:.25rem}.CinderellaBadGameDataAlert__container___dReo1 .CinderellaBadGameDataAlert__error___sJ3_P{font-weight:normal;display:inline;margin-right:.25rem}`, "",{"version":3,"sources":["webpack://./src/routes/Cinderella/Admin/CinderellaBadGameDataAlert.scss"],"names":[],"mappings":"AAEA,+CACI,eAAA,CAEA,YAAA,CACA,kBAAA,CACA,0BAAA,CACA,kBAAA,CAEA,4FACI,eAAA,CACA,mBAAA,CAGJ,0FACI,kBAAA,CACA,cAAA,CACA,mBAAA","sourcesContent":["@use '../../../styles/variables/colors' as colors;\n\n.container {\n    margin: 1rem 0px;\n\n    display: flex;\n    flex-flow: row wrap;\n    justify-content: flex-start;\n    align-items: center;\n\n    .heading {\n        font-weight: 700;\n        margin-right: 0.25rem;\n    }\n\n    .error {\n        font-weight: normal;\n        display: inline;\n        margin-right: 0.25rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `CinderellaBadGameDataAlert__container___dReo1`;
export var heading = `CinderellaBadGameDataAlert__heading___ekJHN`;
export var error = `CinderellaBadGameDataAlert__error___sJ3_P`;
export default ___CSS_LOADER_EXPORT___;
