// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DivisionOrganizer__container___qkoxM .DivisionOrganizer__headingContainer___ercDY{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center}.DivisionOrganizer__container___qkoxM .DivisionOrganizer__headingContainer___ercDY h2{flex:1;margin-right:1.5rem}`, "",{"version":3,"sources":["webpack://./src/routes/Football/Admin/DivisionOrganizer.scss"],"names":[],"mappings":"AAGI,mFACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CAEA,sFACI,MAAA,CACA,mBAAA","sourcesContent":["\n.container {\n\n    .headingContainer {\n        display: flex;\n        flex-flow: row nowrap;\n        justify-content: space-between;\n        align-items: center;\n\n        h2 {\n            flex: 1;\n            margin-right: 1.5rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `DivisionOrganizer__container___qkoxM`;
export var headingContainer = `DivisionOrganizer__headingContainer___ercDY`;
export default ___CSS_LOADER_EXPORT___;
