// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SeasonSchedule__container___RF8Io .SeasonSchedule__list___UFioV{margin-bottom:1.5rem}.SeasonSchedule__container___RF8Io .SeasonSchedule__list___UFioV .SeasonSchedule__result___SyLsy{cursor:pointer}`, "",{"version":3,"sources":["webpack://./src/routes/Football/TeamProfile/SeasonSchedule.scss"],"names":[],"mappings":"AAII,iEACI,oBAAA,CAEA,iGACI,cAAA","sourcesContent":["@use '../../../styles/variables/colors' as colors;\n@use '../../../styles/variables/breakpoints' as breakpoints;\n\n.container {\n    .list {\n        margin-bottom: 1.5rem;\n\n        .result {\n            cursor: pointer;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `SeasonSchedule__container___RF8Io`;
export var list = `SeasonSchedule__list___UFioV`;
export var result = `SeasonSchedule__result___SyLsy`;
export default ___CSS_LOADER_EXPORT___;
