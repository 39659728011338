// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CaptureGameSpreads__container___CpCfs{margin-bottom:1rem}.CaptureGameSpreads__container___CpCfs .CaptureGameSpreads__regionName___TuM1X{margin:1rem 0px}`, "",{"version":3,"sources":["webpack://./src/routes/Capture/Admin/CaptureGameSpreads.scss"],"names":[],"mappings":"AAAA,uCACI,kBAAA,CAEA,+EACI,eAAA","sourcesContent":[".container {\n    margin-bottom: 1rem;\n\n    .regionName {\n        margin: 1rem 0px;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `CaptureGameSpreads__container___CpCfs`;
export var regionName = `CaptureGameSpreads__regionName___TuM1X`;
export default ___CSS_LOADER_EXPORT___;
