import React, { useEffect } from 'react'
import { Row, Col } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import NCAATeam from '../NCAATeam/NCAATeam'
import * as s from './Brackets.scss'

const BracketFinal4 = ({ bracket = {} }) => {

    if (!bracket) return null
    const { regionName1, regionName2, regionName3, regionName4,
        scoreFF1, scoreFF2, scoreFF3, scoreFF4, scoreSF1, scoreSF2, 
        seedCHP, seedFF1, seedFF2, seedFF3, seedFF4, seedSF1, seedSF2, 
        teamNameCHP, avatarUrlCHP,
        teamNameFF1, teamNameFF2, teamNameFF3, teamNameFF4, 
        avatarUrlFF1, avatarUrlFF2, avatarUrlFF3, avatarUrlFF4, 
        teamNameSF1, teamNameSF2, avatarUrlSF1, avatarUrlSF2
    } = bracket
    return (
        <div className={s.final4container}>
            <Row>
                <Col sm={4} xs={12}>
                    <div className={s.final4}>
                        <h4>{regionName1} Region</h4>
                        <NCAATeam teamId={0} name={teamNameFF1} showAvatar avatarUrl={avatarUrlFF1} seed={seedFF1} showGameScore gameScore={scoreFF1} />
                        <div className={s.bracketConnector + ' ' + s.top + ' ' + s.left}></div>
                    </div>
                    <div className={s.semiFinalist}>
                        <h4>Semi-Finalist</h4>
                        <NCAATeam teamId={0} name={teamNameSF1} showAvatar avatarUrl={avatarUrlSF1} seed={seedSF1} showGameScore gameScore={scoreSF1} />
                        <div className={s.bracketConnector + ' ' + s.left}></div>
                    </div>
                    <div className={s.final4}>
                        <h4>{regionName2} Region</h4>
                        <NCAATeam teamId={0} name={teamNameFF2} showAvatar avatarUrl={avatarUrlFF2} seed={seedFF2} showGameScore gameScore={scoreFF2} />
                        <div className={s.bracketConnector + ' ' + s.bottom + ' ' + s.left}></div>
                    </div>
                </Col>
                <Col sm={4} xs={12}>
                    <div className={s.nationalChampion}>
                        <h4>National Champion</h4>
                        <NCAATeam teamId={0} name={teamNameCHP} showAvatar avatarUrl={avatarUrlCHP} seed={seedCHP} showGameScore={false} />
                    </div>
                </Col>
                <Col sm={4} xs={12}>
                    <div className={s.final4 + ' ' + s.rightHandSide}>
                        <h4>{regionName3} Region</h4>
                        <NCAATeam teamId={0} name={teamNameFF3} showAvatar avatarUrl={avatarUrlFF3} seed={seedFF3} showGameScore gameScore={scoreFF3} />
                        <div className={s.bracketConnector + ' ' + s.top + ' ' + s.right}></div>
                    </div>
                    <div className={s.semiFinalist + ' ' + s.rightHandSide}>
                        <h4>Semi-Finalist</h4>
                        <NCAATeam teamId={0} name={teamNameSF2} showAvatar avatarUrl={avatarUrlSF2} seed={seedSF2} showGameScore gameScore={scoreSF2} />
                        <div className={s.bracketConnector + ' ' + s.right}></div>
                    </div>
                    <div className={s.final4 + ' ' + s.rightHandSide}>
                        <h4>{regionName4} Region</h4>
                        <NCAATeam teamId={0} name={teamNameFF4} showAvatar avatarUrl={avatarUrlFF4} seed={seedFF4} showGameScore gameScore={scoreFF4} />
                        <div className={s.bracketConnector + ' ' + s.bottom + ' ' + s.right}></div>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default BracketFinal4