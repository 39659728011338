import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Table, Button, ListGroup } from 'react-bootstrap'
import * as s from './EliminatorRegisterPlayers.scss'
import { getRegistrations, updateRegistrationStatus, updateRegistrationPayment } from '@severed-links/common.redherrings-reducers/eliminatorAdmin'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { find, findIndex } from 'lodash'
import Player from '../../Player/Player'
import PlayerListItem from '../../Player/PlayerListItem'
import moment from 'moment-timezone'
import numeral from 'numeral'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import SeasonSelector from '../../Football/SeasonSelector'

const RegisterPlayerButton = ({ title = '', isChecked = false, disabled = false, onClick }) => (
    <Button size='sm' className={s.registrationButton} disabled={disabled}
        onClick={() => onClick(!isChecked)} variant={disabled ? 'light' : isChecked ? 'success' : 'danger'}>
        <FontAwesomeIcon className={s.buttonIcon} name={isChecked ? 'check' : 'times'} />
        <div className={s.buttonTitle}>{!isChecked ? 'not ' : ''}{title}</div>
    </Button>
)

const EliminatorRegisterPlayers = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const seasons = useSelector(state => state.eliminator.seasons || [])
    const seasonName = params.seasonName || null
    const season = seasons.find(i => i.seasonName === seasonName)
    const seasonId = (season || {}).seasonId || null
    const [registrations, setRegistrations] = useState({})

    useEffect(() => { doGetRegistrations() }, [seasonName])

    const updateRegistration = (playerId, isRegistered) => {
        var _registrations = { ...registrations }
        var index = _registrations.players.findIndex(p => p.playerId === playerId)
        _registrations.players[index].isRegistered = isRegistered
        _registrations.players[index].isRegisteredNotPaid = isRegistered && !_registrations.players[index].isPaid
        setRegistrations(_registrations)
        dispatch(updateRegistrationStatus(seasonId, playerId, isRegistered))
        .then(resp => dispatch(createNotification({ 
            type: resp.payload.messageType || 'error', 
            headline: 'Update Player Registration Status', 
            message: resp.payload.message || resp.message, 
            timeout: 6000 
        })))
    }

    const updatePayment = (playerId, isPaid) => {
        var _registrations = { ...registrations }
        var index = _registrations.players.findIndex(p => p.playerId === playerId)
        _registrations.players[index].isPaid = isPaid
        _registrations.players[index].isRegisteredNotPaid = _registrations.players[index].isRegistered && !_registrations.players[index].isPaid
        setRegistrations(_registrations)
        dispatch(updateRegistrationPayment(seasonId, playerId, isPaid))
        .then(resp => dispatch(createNotification({ 
            type: resp.payload.messageType || 'error', 
            headline: 'Update Player Registration Payment', 
            message: resp.payload.message || resp.message, 
            timeout: 6000 
        })))
    }

    const doGetRegistrations = () => {
        if (seasonId) {
            setRegistrations([])
            dispatch(getRegistrations(seasonId))
            .then(action => setRegistrations(action.payload))
        }
    }

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}>Register Players for Eliminator {seasonName}</h2>
                <div className={s.seasonSelector}>
                    <SeasonSelector seasons={seasons} season={season} />
                </div>
            </div>
            <ListGroup className={s.registrationList}>
            {registrations && registrations.players && registrations.players.map((p,index) => 
            <PlayerListItem player={p} 
                rightDetail={<div className={s.rightDetail}>
                    <RegisterPlayerButton title='registered' isChecked={p.isRegistered}
                        onClick={_bool => updateRegistration(p.playerId, _bool)} />
                    <RegisterPlayerButton title='paid' isChecked={p.isPaid} disabled={!p.isRegistered}
                        onClick={_bool => updatePayment(p.playerId, _bool)} />
                </div>}
                key={`registration-${seasonName}-player-${p.playerId}`} />
            )}
            </ListGroup>
        </div>
    )
}

export default EliminatorRegisterPlayers