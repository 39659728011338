import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './EliminatorIntro.scss'
import { getMainPageData, getStandings, doSignUp, cancelSignUp } from '@severed-links/common.redherrings-reducers/eliminator'
import moment from 'moment-timezone'

const EliminatorPreseasonSignUp = () => {
    
    const dispatch = useDispatch()
    const seasonId = useSelector(state => state.eliminator.seasonId)
    const seasonName = useSelector(state => state.eliminator.seasonName)
    const seasonMode = useSelector(state => state.eliminator.seasonMode)
    const eliminationMode = useSelector(state => state.eliminator.eliminationMode)
    const mainPage = useSelector(state => state.eliminator.mainPage)
    const entryFee = useSelector(state => state.eliminator.entryFee)    
    const week = useSelector(state => state.eliminator.week)
    const playoffWeek = useSelector(state => state.eliminator.playoffWeek)
    const pickDeadline = useSelector(state => state.eliminator.pickDeadline)
    const afterDeadline = moment(pickDeadline).isValid() ? moment().isAfter(moment(pickDeadline), 'second') : true
    const playoffPickDeadline = useSelector(state => state.eliminator.playoffPickDeadline)
    const afterPlayoffDeadline = moment(playoffPickDeadline).isValid() ? moment().isAfter(moment(playoffPickDeadline), 'second') : true
    const playerId = useSelector(state => state.account._id)
    const userName = useSelector(state => state.account.userName)
    const paymentEmail = useSelector(state => state.eliminator.paymentEmail)

    useEffect(() => { dispatch(getMainPageData()) }, [])

    const doEliminatorSignUp = () => {
        dispatch(doSignUp())
        .then(() => { 
            dispatch(getStandings(seasonName))
            dispatch(getMainPageData())
        })
    }

    const cancelEliminatorSignUp = () => {
        dispatch(cancelSignUp())
        .then(() => { 
            dispatch(getStandings(seasonName))
            dispatch(getMainPageData())
        })
    }

    if (!mainPage || !seasonName) return null
    const { isPaid, isSignedUpAlready } = mainPage
    const _isSignUpAllowed = (seasonMode === 'Preseason' || (seasonMode === 'Regular' && week === 1 && !afterDeadline))
    return (
        _isSignUpAllowed ?
        <div className={s.sidePanel}>
            {isSignedUpAlready ?
            <Alert variant='success'>
                <Alert.Heading>Registration: You're in!</Alert.Heading>
                {!isPaid ? 
                <p className='text-end'>
                    <Button style={{ color: 'inherit' }} 
                        variant='link' 
                        size='sm' 
                        onClick={() => cancelEliminatorSignUp()}>
                        <FontAwesomeIcon name='times' />
                        {' '}
                        cancel my registration
                    </Button>
                </p>
                : <p>Our records show you have paid already - thank you!</p>}
            </Alert>
            :
            <Alert variant='primary'>
                <Alert.Heading>Registration</Alert.Heading>
                <p className='text-center'>I'm in for the ${entryFee}.</p>
                <p className='text-center'>
                    <Button variant='primary' size='lg' onClick={() => doEliminatorSignUp()}>
                        <FontAwesomeIcon name='check' />
                        {' '}
                        Sign me up!
                    </Button>
                </p>
            </Alert>
            }
        </div>
        : null
    )
}
    
export default EliminatorPreseasonSignUp