import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './RecapArticle.scss'
import moment from 'moment-timezone'
import RecapByLine from './RecapByLine'
import RecapReactions from './RecapReactions'
import RecapComments from './RecapComments'

const RecapArticle = ({ recapId = null, title = '', recapHtml = '', author = {}, lastEdited = null }) => {

    const dispatch = useDispatch()
    const pickDeadline = useSelector(state => state.cinderella.pickDeadline)
    const timeZone = useSelector(state => state.account.time_zone)

    var _localPickDeadline = pickDeadline && moment(pickDeadline).isValid() ? moment(pickDeadline).tz(timeZone).format('M/D/YYYY h:mm A') : ''
    var __html = (recapHtml || '').replace(/\[pickDeadline\]/gi, _localPickDeadline)
    return (
        <div className={s.container}>
            <h2 className={s.recapTitle}>{title}</h2>
            <div className={s.detailsContainer}>
                <RecapByLine author={author} lastEdited={lastEdited} />
                <RecapReactions recapId={recapId} />
            </div>
            <div className={s.recapContent} dangerouslySetInnerHTML={{ __html }} />
            <RecapComments recapId={recapId} />
        </div>
    )
}

export default RecapArticle
