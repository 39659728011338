// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ContentPanel__contentPanel___lRZ_X{padding-top:6rem}.ContentPanel__contentPanel___lRZ_X.ContentPanel__extraPaddingForStatusBar___mz6ud{padding-top:8rem}`, "",{"version":3,"sources":["webpack://./src/layouts/ContentPanel/ContentPanel.scss"],"names":[],"mappings":"AAAA,oCACI,gBAAA,CAEA,mFACI,gBAAA","sourcesContent":[".contentPanel {\n    padding-top: 6rem;\n\n    &.extraPaddingForStatusBar {\n        padding-top: 8rem;\n    }\n }\n"],"sourceRoot":""}]);
// Exports
export var contentPanel = `ContentPanel__contentPanel___lRZ_X`;
export var extraPaddingForStatusBar = `ContentPanel__extraPaddingForStatusBar___mz6ud`;
export default ___CSS_LOADER_EXPORT___;
