import React, { useState } from 'react'
import { Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { filter, find, first, startsWith } from 'lodash'
import * as s from './RedherringsChampion.scss'
import { teamUrl } from '@severed-links/common.redherrings-constants'

const RedherringsChampion = ({ playoffs = {}, nflPlayoffScoreboard = {}, seasonName = null }) => {

    const [isLoading, setLoading] = useState(false)

    if (!playoffs || playoffs.activeWeek < 4 || isLoading) return null
    const finals = (first((find(playoffs.rounds, { round: 4 }) || {}).matchups || []) || {}).teams || []
    const nfl = first((find(nflPlayoffScoreboard.weeks, { week: 4 }) || {}).games || [])
    if (!finals || finals.length !== 2 || !nfl) return null
    const isFinal = nfl && startsWith(((nfl || {}).scoreType || '').toLowerCase(), 'final')
    if (!isFinal) return null
    const totalScore = nfl.homeScore + nfl.visitorScore
    const isTiebreaker = finals[0].score === finals[1].score
    var winningTeam = null
    var isTie = false
    if (isFinal) {
        if (finals[1].score > finals[0].score ||
            (finals[1].score === finals[0].score && Math.abs(totalScore - finals[1].tiebreaker) < Math.abs(totalScore - finals[0].tiebreaker))) {
            winningTeam = finals[1]
        } else if (finals[0].score > finals[1].score ||
            (finals[0].score === finals[1].score && Math.abs(totalScore - finals[0].tiebreaker) < Math.abs(totalScore - finals[1].tiebreaker))) {
            winningTeam = finals[0]
        } else {
            isTie = true
        }
    }
    return (
        <div className={s.container} key={`redherrings-chamption-${playoffs.seasonName}`}>
            {!isTie ?
            <div>
                <h4>Congratulations to</h4>
                {winningTeam && winningTeam.avatarUrl ? <Image src={teamUrl(winningTeam.avatarUrl)} /> : null}
                {winningTeam && winningTeam.name ? <h2>{winningTeam.name}</h2> : null}
                <h4>as our {playoffs.seasonName} Redherrings Football Champion!</h4>
            </div>
            :
            <div>
                <h3>We have a tie between {finals[0].name} and {finals[1].name} for the Redherrings {playoffs.seasonName} Championship!</h3>
            </div>}
        </div>
    )
}

export default RedherringsChampion