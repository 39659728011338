import React, { useEffect } from 'react'
import { Image, OverlayTrigger, Tooltip } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './NCAATeamIcon.scss'

const NCAATeamIcon = ({
    teamId = null, name = '', teamName = '', seed = null, 
    isAlive = true, avatarUrl = null, size = 'md', 
    showPoints = false, points = 0, style = {}, region = 0, 
    regionName = '', picked = 0, className = null,
    showAvatar = false, gameScore = 0, showGameScore = false, 
}) => {

    const _name = name || teamName
    return (
        <OverlayTrigger placement='top' overlay={<Tooltip id={`ncaa-icon-${teamId}`}>{seed ? `#${seed} `: ''}{_name}</Tooltip>}>
        <div className={s.container + (className ? ` ${className}` : ``) + ' ' + s[size] + ' ' + (isAlive ? s.isAlive : s.isNotAlive)}
            style={style}>
            {avatarUrl ? <Image src={avatarUrl} className={s.avatar} /> 
            : 
            <div className={s.blankIconContainer}>
                <FontAwesomeIcon name='basketball' className={s.icon} />
            </div>}
        </div>
        </OverlayTrigger>
    )
}

export default NCAATeamIcon