// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.Player__playerContainer___P0lYb{margin:0px;padding:0px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.Player__playerContainer___P0lYb .Player__playerAvatar___DG9Bx{padding:0px;margin:0px;border-radius:50%;height:32px;width:32px}.Player__playerContainer___P0lYb .Player__playerName___iLRoH{margin:0px 10px;padding:0px;overflow:hidden;text-overflow:ellipsis;white-space:nowrap}.Player__playerContainer___P0lYb.Player__isLarge___t3Rjr .Player__playerAvatar___DG9Bx{height:30px;vertical-align:top}.Player__playerContainer___P0lYb.Player__isLarge___t3Rjr .Player__playerName___iLRoH{font-size:30px;line-height:30px}`, "",{"version":3,"sources":["webpack://./src/routes/Player/Player.scss"],"names":[],"mappings":"AAAA,iCACI,UAAA,CACA,WAAA,CACA,eAAA,CACA,sBAAA,CACA,kBAAA,CACA,+DACI,WAAA,CACA,UAAA,CACA,iBAAA,CACA,WAAA,CACA,UAAA,CAEJ,6DACI,eAAA,CACA,WAAA,CACA,eAAA,CACA,sBAAA,CACA,kBAAA,CAIA,uFACI,WAAA,CACA,kBAAA,CAEJ,qFACI,cAAA,CACA,gBAAA","sourcesContent":[".playerContainer {\n    margin: 0px;\n    padding: 0px;\n    overflow: hidden;\n    text-overflow: ellipsis;\n    white-space: nowrap;\n    .playerAvatar {\n        padding: 0px;\n        margin: 0px;\n        border-radius: 50%;\n        height: 32px;\n        width: 32px;\n    }\n    .playerName {\n        margin: 0px 10px;\n        padding: 0px;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        white-space: nowrap;\n    }\n\n    &.isLarge {\n        .playerAvatar {\n            height: 30px;\n            vertical-align: top;\n        }\n        .playerName {\n            font-size: 30px;\n            line-height: 30px;\n        }\n            \n    }\n}"],"sourceRoot":""}]);
// Exports
export var playerContainer = `Player__playerContainer___P0lYb`;
export var playerAvatar = `Player__playerAvatar___DG9Bx`;
export var playerName = `Player__playerName___iLRoH`;
export var isLarge = `Player__isLarge___t3Rjr`;
export default ___CSS_LOADER_EXPORT___;
