import React, { useEffect } from 'react'
import { Row, Col, Table, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import NCAATeam from '../Cinderella/NCAATeam/NCAATeam'
import Line from '../Football/Line'
import * as s from './CaptureNcaaGameList.scss'
import moment from 'moment-timezone'
import CaptureGameTime from './CaptureGameTime'

const CaptureNcaaGameList = ({ title = '', games = [] }) => {
    
    return (
        <div className={s.container}>
            {games && games.length > 0 ?
                <div className={s.gamesContainer}>
                    <h4>{title}</h4>
                    {games.map((g, gameIndex) => {
                        const t1 = g[0].score >= g[1].score ? g[0] : g[1]
                        const t2 = g[0].score >= g[1].score ? g[1] : g[0]
                        return (
                        <div className={s.gameContainer} key={gameIndex}>
                            <div className={s.gameDetails}>
                                <CaptureGameTime gameTime={t1.gameTime} />
                                <div>
                                    {t1.favorite ? t1.teamName : t2.teamName} by <Line line={t1.line} showSign={false} />
                                </div>
                            </div>
                            <NCAATeam teamId={t1._id} name={t1.teamName} seed={t1.seed} 
                                showAvatar avatarUrl={t1.avatarUrl}
                                gameScore={t1.score} showGameScore={true} />
                            <NCAATeam teamId={t2._id} name={t2.teamName} seed={t2.seed} 
                                showAvatar avatarUrl={t2.avatarUrl}
                                gameScore={t2.score} showGameScore={true} />
                        </div>
                        )
                    })}
                </div>
            : null}
        </div>
    )
}
    
export default CaptureNcaaGameList