import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, FormGroup, FormLabel, FormControl, InputGroup, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { trim } from 'lodash'
import * as s from './ForgotPassword.scss'
import validator from 'validator'
import { PatternFormat } from 'react-number-format'
import { setUserInfo, checkCellPhone } from '@severed-links/common.redherrings-reducers/forgotPassword'
import { checkEmail } from '@severed-links/common.redherrings-reducers/siteRegistration'

const ForgotPasswordStep2 = forwardRef(({ jumpToStep }, _ref) => {

    const dispatch = useDispatch()
    const fp = useSelector(state => state.forgotPassword)
    const [email, setEmail] = useState('')
    const [cellPhone, setCellPhone] = useState('')
    const _isValid = (fp.sendBy === 'Email' && fp.emailIsValid) ||
        (fp.sendBy === 'Text' && fp.cellPhoneIsValid)

    useEffect(() => {
        setEmail(fp.email)
        setCellPhone(fp.cellPhone)
    }, [])

    useEffect(() => {
        if (fp.nextDisabled !== !_isValid) dispatch(setUserInfo({ nextDisabled: !_isValid }))
    }, [fp.nextDisabled, _isValid])

    useEffect(() => {
        if (validator.isEmail(email || '')) {
            dispatch(checkEmail(email || ''))
        } else {
            dispatch(setUserInfo({ emailIsValid: false }))
        }
    }, [email])

    useEffect(() => {
        if (validator.isMobilePhone(cellPhone || '', 'en-US')) {
            dispatch(checkCellPhone(cellPhone || ''))
        } else {
            dispatch(setUserInfo({ cellPhoneIsValid: false }))
        }
    }, [cellPhone])

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValid
    }))

    const handleEmailChange = e => setEmail(trim(e.target.value))

    const handleCellPhoneChange = _values => {
        var data = { cellPhone: trim(_values.formattedValue || '') }
        setCellPhone(data.cellPhone)
    }

    return (
        <div>
            {fp.sendBy == 'Email' ? 
            <FormGroup>
                <Col sm={{ span: 10, offset: 1 }}>
                    <FormLabel>Email address (you must have updated your Redherrings profile with this email)</FormLabel>
                    <InputGroup size='lg'>
                        <InputGroup.Text><FontAwesomeIcon name='envelope' /></InputGroup.Text>
                        <FormControl autoFocus autoComplete='off' 
                            type='text' name='email' 
                            value={email} 
                            required isValid={_isValid}
                            isInvalid={!_isValid}
                            onChange={e => handleEmailChange(e)} />
                        <FormControl.Feedback />
                    </InputGroup>
                    <div className={s.alertContainer}>
                        {_isValid ?
                        <Alert variant='success'>Press 'Next' to send your code to your selected location.</Alert>
                        : null}
                    </div>
                </Col>
            </FormGroup>
            : null}
            {fp.sendBy == 'Text' ? 
            <FormGroup>
                <Col sm={{ span: 8, offset: 2 }}>
                    <FormLabel>Cell phone (you must have updated your Redherrings profile with this cell phone number)</FormLabel>
                    <InputGroup size='lg'>
                        <InputGroup.Text><FontAwesomeIcon name='mobile' /> +1</InputGroup.Text>
                        <PatternFormat customInput={FormControl} autoFocus autoComplete='off' 
                            name='cellPhone' type='tel' format='(###) ###-####' mask='_' 
                            value={cellPhone}                                         
                            required isValid={_isValid}
                            isInvalid={!_isValid}
                            onValueChange={(values, sourceInfo) => handleCellPhoneChange(values)} />
                        <FormControl.Feedback />
                    </InputGroup>
                    <div className={s.alertContainer}>
                        {_isValid ?
                        <Alert variant='success'>Press 'Next' to send your code to your selected location.</Alert>
                        : null}
                    </div>
                </Col>
            </FormGroup>
            : null}
        </div>
    )
})

export default ForgotPasswordStep2
