import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './OscarsDeadlineCalendar.scss'
import moment from 'moment-timezone'

const OscarsDeadlineCalendar = () => {

    const pickDeadline = useSelector(state => state.oscars.pickDeadline)
    const timezoneId = useSelector(state => state.account.time_zone || state.oscars.timezoneId)

    if (!pickDeadline) return null
    const _deadline = moment(pickDeadline).tz(timezoneId)
    const month = _deadline.format('MMMM')
    const day = _deadline.date()
    const time = _deadline.format('h:mm A')
    return (
        <div className={s.container}>
            <div className={s.header}><h4>Pick entry deadline</h4></div>
            <div className={s.calendarContainer}>
                <div className={s.calendar}>
                    <div className={s.month}>{month}</div>
                    <div className={s.day}>{day}</div>
                </div>
                <div className={s.time}>
                    <FontAwesomeIcon name='clock' />
                    {' '}
                    {time}      
                </div>
            </div>
        </div>
    )
}

export default OscarsDeadlineCalendar