import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Card, ListGroup, Button, FormGroup, InputGroup, FormControl } from 'react-bootstrap'
import * as s from './OscarsWinners.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { getWinners, getNomineesByCategory, setWinner } from '@severed-links/common.redherrings-reducers/oscarsAdmin'
import { trim, endsWith, some, orderBy } from 'lodash'
import YearSelector from '../YearSelector'
import Modal from '@severed-links/common.modal'

const CategoryFilter = ({ filterText, onChange }) => (
    <FormGroup>
        <InputGroup>
            <InputGroup.Text>
                <FontAwesomeIcon name='search' />
            </InputGroup.Text>
            <FormControl value={filterText} onChange={e => onChange(e)} />
        </InputGroup>
    </FormGroup>
)

const WinnerCountStatus = ({ categories = [] }) => (
    <span>{categories.filter(i => i.hasWinner).length}/{categories.length}</span>
)

const OscarsWinners = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const _year = useSelector(state => state.oscars.year)
    const year = parseInt(params.year || '0')
    const [categories, setCategories] = useState([])
    const [nominees, setNominees] = useState([])
    const [selectedCategory, setSelectedCategory] = useState(null)
    const [isLoading, setLoading] = useState(false)
    const [filterText, setFilterText] = useState('')

    useEffect(() => { getData() }, [year])

    const getData = () => {
        if (year) {
            setSelectedCategory(null)
            setNominees([])
            setLoading(true)
            dispatch(getWinners(year))
            .then(action => {
                setCategories(action.payload.categories || [])
                setLoading(false)
            })
        }
    }

    const selectCategory = _selectedCategory => {
        setSelectedCategory(_selectedCategory)
        setNominees([])
        setLoading(true)
        dispatch(getNomineesByCategory(year, _selectedCategory.num))
        .then(action => {
            setNominees(action.payload)
            setLoading(false)
        })
    }

    const doSetWinner = _nominee => {
        setSelectedCategory(null)
        dispatch(setWinner({ ..._nominee, submit: 'OscarWinner' }))
        .then(() => getData())
    }

    const filteredCategories = orderBy((filterText ? categories.filter(i => i.category.toString().toLowerCase().indexOf(filterText.toString().toLowerCase()) > -1) : categories)
        .map(i => ({ ...i, sortOrder: i.hasWinner ? 2 : 1 })), ['sortOrder','num'], ['asc','asc'])
    
    return (
        <div className={s.container}>
            <div className={s.header}>
                <h2 className={s.title}>{year} Winners (<WinnerCountStatus categories={categories} /> awarded)</h2>
                <div className={s.controls}>
                    <CategoryFilter filterText={filterText} onChange={e => setFilterText(e.target.value)} />
                </div>
                <div className={s.controls}>
                    <YearSelector defaultYear={year} />
                </div>
            </div>
            <Row>
            {filteredCategories && filteredCategories.map(c =>
                <Col md={4} sm={6} xs={12} key={`winner-${year}-category-${c.num}`}>
                    <Card className={s.winnerCard + ' ' + (c.hasWinner ? s.hasWinner : null)} title={c.category}>
                        <Card.Header className={s.winnerHeader} as='h6'>
                            <div className={s.category}>{c.category}</div>
                            <div className={s.control}><Button onClick={() => selectCategory(c)}><FontAwesomeIcon name='check' /></Button></div>
                        </Card.Header>
                        <Card.Body className={s.winnerBody}>{c.winnerText}</Card.Body>
                    </Card>
                </Col>
            )}
            </Row>
            <Modal heading={'Set Winner: ' + (selectedCategory ? selectedCategory.category : '')}
                show={!!selectedCategory} showFooter={false}
                onClose={() => setSelectedCategory(null)}
                actionButtonText={null}>
                {!isLoading ?
                <div>
                    <ListGroup className={s.winnerNomineeList} variant='flush'>
                        {nominees && nominees.map((n, index) =>
                            <ListGroup.Item key={`winner-list-${year}-${n.choice}`} 
                                className={s.winnerNomineeListItem + ' ' + (n.isWinner ? s.isWinner : s.isNotWinner)}
                                disabled={n.isWinner} action={!n.isWinner} 
                                onClick={() => doSetWinner(n)}>
                                <div className={s.choiceText}>{n.choiceText}</div>
                                    {n.isWinner ? <div className={s.winnerIcon}><FontAwesomeIcon name='check' /></div> : null}
                            </ListGroup.Item>
                        )}
                        <ListGroup.Item className={s.winnerNomineeListItem + ' ' + (!some(nominees, i => i.isWinner) ? s.isWinner : s.isNotWinner)}
                            action={some(nominees, i => i.isWinner)}
                            disabled={!some(nominees, i => i.isWinner)}
                            onClick={() => doSetWinner({ year: year, num: selectedCategory.num, choice: '', choiceText: '' })}>
                            No winner
                        </ListGroup.Item>
                    </ListGroup>
                </div>
                : null}
            </Modal>
    </div>
    )
}

export default OscarsWinners