// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EliminatorPaymentAlert__container___PETs8{margin:10px 0px}.EliminatorPaymentAlert__container___PETs8 .EliminatorPaymentAlert__paymentContainer___jvlb_{text-align:center}`, "",{"version":3,"sources":["webpack://./src/routes/Eliminator/EliminatorPaymentAlert.scss"],"names":[],"mappings":"AAAA,2CACI,eAAA,CACA,6FACI,iBAAA","sourcesContent":[".container {\n    margin: 10px 0px;\n    .paymentContainer {\n        text-align: center;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `EliminatorPaymentAlert__container___PETs8`;
export var paymentContainer = `EliminatorPaymentAlert__paymentContainer___jvlb_`;
export default ___CSS_LOADER_EXPORT___;
