// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.OscarsEditConfiguration__container___oqiuG .OscarsEditConfiguration__formGroup____oeoy{margin-bottom:1rem}`, "",{"version":3,"sources":["webpack://./src/routes/Oscars/Admin/OscarsEditConfiguration.scss"],"names":[],"mappings":"AACI,wFACI,kBAAA","sourcesContent":[".container {\n    .formGroup {\n        margin-bottom: 1rem;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `OscarsEditConfiguration__container___oqiuG`;
export var formGroup = `OscarsEditConfiguration__formGroup____oeoy`;
export default ___CSS_LOADER_EXPORT___;
