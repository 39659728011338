import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getPayouts } from '@severed-links/common.redherrings-reducers/football'
import numeral from 'numeral'
import pluralize from 'pluralize'
import * as s from './Payouts.scss'
import { teamUrl, playerUrl } from '@severed-links/common.redherrings-constants'

const Payouts = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const seasonName = params.seasonName || null
    const seasons = useSelector(state => state.football.seasons)
    const season = seasons.find(i => i.seasonName === seasonName)
    const seasonId = (season || {}).seasonId || null
    const payouts = useSelector(state => state.football.payouts.find(i => i.seasonName === seasonName))

    useEffect(() => { seasonId ? dispatch(getPayouts(seasonId)) : null }, [seasonId])

    if (!payouts) return null
    const { items } = payouts
    return (
        <div className={s.container}>
            <h2>{seasonName} Payouts</h2>
            <ListGroup className={s.list}>
            {items && items.map(i =>
            <ListGroup.Item className={s.item + ' ' + s[`${i.type}`]} key={`payout-${seasonName}-${i.type}`}>
                <div className={s.content}>
                    <div className={s.descriptionContainer}>
                        <div className={s.description}>{i.item} {i.amount ? ` (${numeral(Math.abs(i.amount)).format('($0,0.00')}${i.num > 1 ? `/each` : ``})` : ``}</div>
                        {i.num ?
                        <div className={s.detail}>
                            <div className={s.numberOfItems}>{pluralize('item', i.num, true)}</div>
                            <div className={s.winners}>
                            {i.winners && i.winners.map((w, _winnerIndex) => 
                                <div className={s.winner} key={`payouts-${seasonName}-${i.type}-winner-${_winnerIndex}`}>
                                    {i.type === 'weekly' ? <div className={s.weekContainer}><div className={s.weekHeading}>Week</div><div className={s.weekNumber}>{w.week}</div></div> : null}
                                    <div className={s.avatar} style={{ backgroundImage: `url(${teamUrl(w.avatarUrl)})` }} />
                                    <div className={s.avatar} style={{ backgroundImage: `url(${playerUrl(w.playerAvatarUrl)})` }} />
                                    <div className={s.names}>
                                        <div className={s.teamName}>{w.teamName}</div>
                                        <div className={s.playerName}>{w.firstName} {w.lastName}</div>
                                    </div>
                                </div>
                            )}
                            </div>
                        </div>
                        : null}
                    </div>
                    <div className={s.amountContainer + (!i.balance ? ` ${s.zero}` : '')}>
                        {numeral(i.balance).format('($0,0.00')}
                    </div>
                </div>
            </ListGroup.Item>
            )}
            {!items || !items.length ? <ListGroup.Item className={s.item}>No payouts located for {seasonName}.</ListGroup.Item> : null}
            </ListGroup>
        </div>
    )
}

export default Payouts