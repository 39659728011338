import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, FormGroup, FormControl, InputGroup, Button } from 'react-bootstrap'
import * as s from './FootballAdmin.scss'
import Modal from '@severed-links/common.modal'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { setActiveSeason, getConfig } from '@severed-links/common.redherrings-reducers/footballAdmin'
import { getFootballDetails } from '@severed-links/common.redherrings-reducers/football'

const FootballSetActiveSeason = () => {

    const dispatch = useDispatch()
    const seasonId = useSelector(state => state.football.seasonId)
    const seasonName = useSelector(state => state.football.seasonName)
    const seasons = useSelector(state => state.football.seasons)
    const [activeSeason, setActiveSeasonState] = useState(null)

    useEffect(() => { setActiveSeasonState(seasonId) }, [seasonId])
    useEffect(() => {
        if (seasonName) {
            dispatch(getFootballDetails())
            dispatch(getConfig(seasonName))
        }
    }, [seasonName])

    const doSetActiveSeason = () => {
        dispatch(setActiveSeason(activeSeason))
        .then(resp => {
            dispatch(createNotification({ 
                type: resp.payload.messageType, 
                headline: 'Set Active Season', 
                message: resp.payload.message, 
                timeout: 6000 
            }))
        })
    }

    const handleChange = e => setActiveSeasonState(e.target.value)

    return (
        <Row>
            <Col xs={12}>
                <FormGroup>
                    <InputGroup>
                        <FormControl as='select' placeholder='select' 
                            onChange={e => handleChange(e)}
                            value={activeSeason || ''}>
                        {seasons && seasons.map(s => 
                            <option key={s.seasonId} value={s.seasonId}>{s.seasonName}</option>
                        )}
                        </FormControl>
                        <Button variant='primary' onClick={() => doSetActiveSeason()}><FontAwesomeIcon name='check' /> set</Button>
                    </InputGroup>
            </FormGroup>
            </Col>
        </Row>
    )
}

export default FootballSetActiveSeason