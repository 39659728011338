import React, { useState, useEffect } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { find, orderBy, findIndex } from 'lodash'

const SeasonSelector = ({ season = {}, seasons = [], onSelect = null }) => {

    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()

    const onItemSelect = e => {
        if (onSelect) {
            onSelect(e)
        } else {
            navigate(location.pathname.replace(params.seasonName, e))
        }
    }

    return (
        <DropdownButton variant='light' 
            title={season && season.seasonName ? <span>{season.seasonName}</span> : <span>[Select a season...]</span>} 
            id='SeasonSelector-dropdown' onSelect={e => onItemSelect(e)} 
            onClick={e => e.stopPropagation()}>
        {seasons && orderBy(seasons, ['seasonName'], ['desc']).map(s =>
            <Dropdown.Item key={s.seasonId} eventKey={s.seasonName}>{s.seasonName}</Dropdown.Item>
        )}
        </DropdownButton>
    )
}

export default SeasonSelector
