// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EditNflPlayoffGames__container___vTTDl{margin-bottom:1.5rem}.EditNflPlayoffGames__container___vTTDl.EditNflPlayoffGames__isModal___bmeTa{max-height:65vh;overflow-y:scroll}.EditNflPlayoffGames__container___vTTDl .EditNflPlayoffGames__weekContainer___YiW4O{margin:12px 0px}.EditNflPlayoffGames__container___vTTDl .EditNflPlayoffGames__weekContainer___YiW4O .EditNflPlayoffGames__weekHeading___OtSbK{margin-bottom:10px}`, "",{"version":3,"sources":["webpack://./src/routes/Football/Admin/EditNflPlayoffGames.scss"],"names":[],"mappings":"AAAA,wCACI,oBAAA,CAEA,6EACI,eAAA,CACA,iBAAA,CAGJ,oFACI,eAAA,CACA,8HACI,kBAAA","sourcesContent":[".container {\n    margin-bottom: 1.5rem;\n\n    &.isModal {\n        max-height: 65vh;\n        overflow-y: scroll;\n    }\n\n    .weekContainer {\n        margin: 12px 0px;\n        .weekHeading { \n            margin-bottom: 10px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `EditNflPlayoffGames__container___vTTDl`;
export var isModal = `EditNflPlayoffGames__isModal___bmeTa`;
export var weekContainer = `EditNflPlayoffGames__weekContainer___YiW4O`;
export var weekHeading = `EditNflPlayoffGames__weekHeading___OtSbK`;
export default ___CSS_LOADER_EXPORT___;
