import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormCheck, Alert } from 'react-bootstrap'
import * as s from './EliminatorIntro.scss'
import { setReminderPreference } from '@severed-links/common.redherrings-reducers/eliminator'
import PickReminderSelector from '../Football/PickReminderSelector'

const PickReminders = () => {

    const dispatch = useDispatch()
    const mainPage = useSelector(state => state.eliminator.mainPage)
    const seasonName = useSelector(state => state.eliminator.seasonName)

    const handleChange = (_entryId, _data = { sendEmailReminder: false, sendTextReminder: false, sendPushReminder: false }) => {
        dispatch(setReminderPreference(_entryId, _data))
    }

    if (!mainPage) return null
    const { entries } = mainPage
    const hasEntries = entries && entries.length > 0
    return (
        <div className={s.remindersContainer}>
            {hasEntries ? 
            <Alert variant='info'>
                <Alert.Heading>Pick Reminders</Alert.Heading>
                <p>
                    Indicate below whether we should send you a reminder about your pick in the hour 
                    or so before your picks are due.
                </p>
                {entries && entries.map(entry => 
                    <div key={entry._id}>
                        <PickReminderSelector sendEmailReminder={entry.sendEmailReminder}
                            sendTextReminder={entry.sendTextReminder}
                            sendPushReminder={entry.sendPushReminder}
                            onChange={_data => handleChange(entry._id, _data)} />
                    </div>
                )}
            </Alert>
            : null}
        </div>
    )
}

export default PickReminders