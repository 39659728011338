import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button, FormGroup, FormControl, FormLabel, InputGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import moment from 'moment-timezone'
import Confirm from '@severed-links/common.confirm'
import * as s from './CaptureContestEditor.scss'

const CaptureContestEditor = ({ 
    _id = null, contestName = '', year = moment().year(), entryFee = 2.0, isDeleted = false, isActive = false,  
    closeEditor = () => void(0), updateEditedContest = () => void(0), 
    restore = () => void(0), onDelete = () => void(0), 
}) => {

    const _contest = { _id, contestName, year, entryFee, isDeleted, isActive }

    const handleChange = e => {
        if (e.target.name === 'contestName' || e.target.name === 'entryFee') {
            updateEditedContest({ ..._contest, [e.target.name]: e.target.value })
        } else if (e.target.name === 'year') {
            updateEditedContest({ ..._contest, year: parseFloat(e.target.value, 10) })
        }
    } 

    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>Contest name</FormLabel>
                        <FormControl type='text' name='contestName' 
                            value={contestName} 
                            required isValid={!!contestName}
                            onChange={e => handleChange(e)} />
                        <FormControl.Feedback />
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col xs={4}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>Year</FormLabel>
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon name='calendar' /></InputGroup.Text>
                            <FormControl type='text' name='year' value={year} 
                                required isValid={parseInt(year) > 1970}
                                onChange={e => handleChange(e)} />
                            <FormControl.Feedback />
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col xs={4}>
                    <FormGroup className={s.formGroup}>
                        <FormLabel>Entry fee</FormLabel>
                        <InputGroup>
                            <InputGroup.Text><FontAwesomeIcon name='money-bill' /></InputGroup.Text>
                            <FormControl type='text' name='entryFee' 
                                value={entryFee} 
                                required isValid={!isNaN(entryFee)}
                                onChange={e => handleChange(e)} />
                            <FormControl.Feedback />
                        </InputGroup>
                    </FormGroup>
                </Col>
                <Col xs={4}>
                    {_id ?
                    <FormGroup className={s.formGroup}>
                        <FormLabel style={{ width: '100%' }}>&nbsp;</FormLabel>
                        <Confirm variant={isDeleted ? 'info' : 'danger'} 
                            onConfirm={() => isDeleted ? restore() : onDelete()}
                            title={`${isDeleted ? 'Restore' : 'Delete'} Contest`}
                            confirmText={`${isDeleted ? 'restore' : 'delete'} contest`}
                            body={`Are you sure you want to ${isDeleted ? 'restore' : 'delete'} this contest?`}
                            buttonIcon={isDeleted ? 'rotate' : 'trash'} />
                    </FormGroup>
                    : null}
                </Col>
            </Row>
            {isActive ?
            <Row>
                <Col xs={12}>
                    <p className={s.isActiveText}>This contest is the active contest and cannot be deleted.</p>
                </Col>
            </Row>
            : null}
        </div>
    )
}

export default CaptureContestEditor
