import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, FormGroup, FormControl, FormLabel, InputGroup, Button, Form, ListGroup } from 'react-bootstrap'
import * as s from './OscarsAwardCategories.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { getCategories, updateCategory, deleteCategory } from '@severed-links/common.redherrings-reducers/oscarsAdmin'
import { trim, endsWith, maxBy } from 'lodash'
import Confirm from '@severed-links/common.confirm'
import Modal from '@severed-links/common.modal'

const OscarsAwardCategories = () => {

    const dispatch = useDispatch()
    const year = useSelector(state => state.oscars.year)
    const categories = useSelector(state => state.oscarsAdmin.categories)
    const [showEditCategory, setShowEditCategory] = useState(false)
    const [num, setNum] = useState(0)
    const [category, setCategory] = useState('')

    useEffect(() => { dispatch(getCategories()) }, [])

    const editCategory = _category => {
        const { num, category } = _category
        setNum(num)
        setCategory(category)
        setShowEditCategory(true)
    }

    const doDeleteCategory = _category => {
        dispatch(deleteCategory(_category.num))
        .then(action => {
            dispatch(createNotification({ headline: 'Delete Category', type: action.payload.messageType, message: action.payload.message, timeout: 6000 }))
        })
    }

    const addCategory = () => {
        var max = maxBy(categories, i => i.num).num + 1
        setNum(max)
        setCategory('')
        setShowEditCategory(true)
    }

    const processUpdate = () => {
        dispatch(updateCategory({ num, category }))
        .then(action => {
            dispatch(createNotification({ headline: 'Update Category', type: action.payload.messageType, message: action.payload.message, timeout: 6000 }))
            setNum(0)
            setCategory('')
            setShowEditCategory(false)
        })
    }

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}>Award Categories</h2>
                <div className={s.controls}>
                    <Button onClick={() => addCategory()}><FontAwesomeIcon name='plus-circle' /></Button>
                </div>
            </div>
            <ListGroup className={s.list}>
            {categories && categories.map(c =>
            <ListGroup.Item className={s.item} key={`oscars-award-category-${c.num}`}>
                <div className={s.num}>{c.num}</div>
                <div className={s.category}>{c.category}</div>
                <div className={s.edit}><Button variant='info' size='sm' onClick={() => {
                        setNum(c.num)
                        setCategory(c.category)
                        setShowEditCategory(true)
                    }}><FontAwesomeIcon name='pencil-alt' /></Button></div>
                <div className={s.delete}>
                    <Confirm variant='danger' onConfirm={() => doDeleteCategory(c)}
                        title={'Delete Category'}
                        confirmText='delete category'
                        body={'Are you sure you want to delete this category?'}
                        buttonIcon='times' size='sm' />
                </div>
            </ListGroup.Item>
            )}
            </ListGroup>
            <Modal heading='Update Category'
            show={showEditCategory} showFooter={false}
            onClose={() => setShowEditCategory(false)}
            actionButtonText={null}>
            <Form className={s.editCategoryContainer}>
                <FormGroup className={s.formGroup}>
                    <FormLabel>Num</FormLabel>
                    <FormControl type='number' name='Num' value={`${num}`} 
                        onChange={e => setNum(parseInt(e.target.value)) } />
                </FormGroup>
                <FormGroup className={s.formGroup}>
                    <FormLabel>Category</FormLabel>
                    <FormControl type='text' name='Category' value={category} 
                        onChange={e => setCategory(e.target.value)} />
                </FormGroup>
                <FormGroup className={s.formGroup}>
                    <Button variant='primary' onClick={() => processUpdate()}><FontAwesomeIcon name='check' /> update</Button>
                </FormGroup>
            </Form>
        </Modal>

        </div>
    )
}

export default OscarsAwardCategories