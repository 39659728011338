import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Row, Col, Form, FormGroup, FormControl, InputGroup, 
    FormLabel, Button, ButtonGroup, Dropdown, DropdownButton,
    ListGroup } from 'react-bootstrap'
import * as s from './UpdateManager.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import FormCheck from 'react-bootstrap/FormCheck'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { getFootballDetails } from '@severed-links/common.redherrings-reducers/football'
import { updateManagerGetWeeklyWinners, updateManagerRegularSeasonUpdateScoresAndStandings,
    updateManagerRegularSeasonPrepareAutoPicks,
    updateManagerRegularSeasonSendWeeklyEmail, updateManagerRegularSeasonSetToNextWeek,
    updateManagerPostseasonUpdateScoresAndStandings,
    updateManagerPostseasonPrepareAutoPicks,
    updateManagerPostseasonSendWeeklyEmail, updateManagerPostseasonSetToNextWeek,
    runManualServiceUpdate, getUpdateManagerChecklist, updateUpdateManagerChecklist } from '@severed-links/common.redherrings-reducers/footballAdmin'
import SeasonSelector from '../SeasonSelector'
import WeekSelector from '../WeekSelector'
import { find, orderBy, findIndex, startsWith, isEqual, endsWith } from 'lodash'
import UpdateNflRegularSeasonLines from './UpdateNflRegularSeasonLines'
import Modal from '@severed-links/common.modal'
import UpdateManagerPayPalFrame from './UpdateManagerPayPalFrame'
import EditNflPlayoffGames from './EditNflPlayoffGames'
import CheckPlayerPickStatus from './CheckPlayerPickStatus'
import EspnScrapeTester from './EspnScrapeTester'
import moment from 'moment-timezone'

const UpdateManagerFormListItem = ({ show, label, disabled, variant, value, onClick, controls = [], onControlChange = null }) => (
    show ?
    <ListGroup.Item className={s.updateManagerListItem} variant={variant} disabled={disabled}>
        {label ? <div>{label}</div> : null}
        <div className={s.content}>
            <h4 className={s.weekNumber}>Week {value}</h4>
            {onClick ? 
            <div className={s.button}>
                <Button variant={variant} disabled={disabled} onClick={() => onClick()}>go</Button>
            </div>
             : null}
        </div>
        {controls.length && onControlChange ?
        <div className={s.controls}>
        {controls.map(i =>
        <div className={s.controlContainer} key={`um-control-${name}-${i.name}`}>
        {i.type === 'switch' ? 
        <FormCheck type='switch' name={i.name}
            label={i.labelText} 
            checked={i.value} 
            onChange={onControlChange} />
        : null}
        </div>
        )}
        </div>
        : null}
    </ListGroup.Item>
    : null
)

const UpdateManager = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const seasonName = params.seasonName || null
    const seasons = useSelector(state => state.football.seasons || [])
    const entryFee = useSelector(state => state.football.entryFee) || 0.00
    const season = seasons.find(i => i.seasonName === seasonName) || {}
    const seasonId = season.seasonId || null
    const weekParam = params.week || '0'
    const week = parseInt(weekParam)
    const seasonMode = location.pathname.toLowerCase().indexOf('postseason') > -1 ? 'Postseason' : 'Regular'
    const isPlayoffWeek = seasonMode === 'Postseason'
    const _utility = params.utility || null
    const _checklist = useSelector(state => (state.footballAdmin.updateChecklists || [])
        .find(i => i.seasonId === seasonId && i.isPlayoffWeek === isPlayoffWeek && i.week === week)) || {}
    const { doingUpdateScoresStandings, weekForUpdateScoresStandings, doneWithUpdateScoresStandings, 
        doingProcessPayment, weekForProcessPayment, doneWithProcessPayment,
        doingUpdateLines, weekForUpdateLines, doneWithUpdateLines,
        doingAutoPicks, weekForAutoPicks, doneWithAutoPicks, overwriteAutoPicks,
        doingEmailUpdate, doneWithEmailUpdate, weekForEmailUpdate, emailWithWinner, emailWithAutoPicks, emailWithSiteUpdated,  
        doingSetToNextWeek, doneWithSetToNextWeek, weekForSetToNextWeek,
        showUpdateLinesModal, showUpdatePlayoffGamesModal, updatedAt } = _checklist

    const [games, setGames] = useState([])
    const [winners, setWinners] = useState([])
    const [showPlayerPickStatusModal, setShowPlayerPickStatusModal] = useState(false)
    const title = `${seasonName} ${seasonMode} ${seasonMode === 'Regular' ? 'Season ' : ''}Update Manager Week ${week}`
    const showEspnScrapeTester = _utility === 'espn-scrape-tester'

    useEffect(() => { getChecklist() }, [seasonId, seasonMode, week])
    useEffect(() => { getWinners() }, [seasonId, weekForProcessPayment])

    const getChecklist = () => {
        if (seasonId && seasonMode && week && weekParam) {
            dispatch(getUpdateManagerChecklist(seasonId, seasonMode, week))
        }
    }

    const getWinners = () => {
        if (seasonId && weekForProcessPayment) {
            dispatch(updateManagerGetWeeklyWinners(seasonId, weekForProcessPayment))
            .then(action => setWinners(action.payload))
        }
    }

    const createNotificationMessage = (type, message, headline, timeout) => dispatch(createNotification({ type, message, headline, timeout }))

    const handleBootstrapSwitch = (e, checkedState) => {
        dispatch(updateUpdateManagerChecklist(seasonId, seasonMode, week, { [e.target.name]: checkedState }))
    }

    const doUpdateScoresStandings = async () => {
        await dispatch(updateUpdateManagerChecklist(seasonId, seasonMode, week, { doingUpdateScoresStandings: true, doneWithUpdateScoresStandings: false }))
        const action = await dispatch((!isPlayoffWeek ? updateManagerRegularSeasonUpdateScoresAndStandings : updateManagerPostseasonUpdateScoresAndStandings)(seasonId, weekForUpdateScoresStandings))
        createNotificationMessage(action.payload.messageType, action.payload.message, 'Update Scores and Standings', 5000)
        await dispatch(updateUpdateManagerChecklist(seasonId, seasonMode, week, { doingUpdateScoresStandings: false, doneWithUpdateScoresStandings: action.payload.messageType === 'success' }))
    }

    const doProcessPayment = async () => {
        const _winnerResponse = await dispatch(updateManagerGetWeeklyWinners(seasonId, weekForProcessPayment))
        setWinners(_winnerResponse.payload)
        await dispatch(updateUpdateManagerChecklist(seasonId, seasonMode, week, { doneWithProcessPayment: false, doingProcessPayment: true }))
    }

    const doUpdateLines = async () => {
        await dispatch(updateUpdateManagerChecklist(seasonId, seasonMode, week, { [`${!isPlayoffWeek ? 'showUpdateLinesModal' : 'showUpdatePlayoffGamesModal'}`]: true }))
    }

    const doAutoPicks = async () => {
        await dispatch(updateUpdateManagerChecklist(seasonId, seasonMode, week, { doingAutoPicks: true, doneWithAutoPicks: false }))
        const action = await dispatch((!isPlayoffWeek ? updateManagerRegularSeasonPrepareAutoPicks : updateManagerPostseasonPrepareAutoPicks)(seasonId, weekForAutoPicks, overwriteAutoPicks))
        createNotificationMessage(action.payload.messageType, action.payload.message, 'Generate AutoPicks', 5000)
        await dispatch(updateUpdateManagerChecklist(seasonId, seasonMode, week, { doingAutoPicks: false, doneWithAutoPicks: true }))
    }

    const doEmailUpdate = async () => {
        await dispatch(updateUpdateManagerChecklist(seasonId, seasonMode, week, { doingEmailUpdate: true, doneWithEmailUpdate: false }))
        const action = await dispatch((!isPlayoffWeek ? updateManagerRegularSeasonSendWeeklyEmail : updateManagerPostseasonSendWeeklyEmail)(seasonId, weekForEmailUpdate, emailWithWinner, emailWithAutoPicks, emailWithSiteUpdated))
        createNotificationMessage(action.payload.messageType, action.payload.message, 'Send Email Update', 5000)
        await dispatch(updateUpdateManagerChecklist(seasonId, seasonMode, week, { doingEmailUpdate: false, doneWithEmailUpdate: true }))
    }

    const doSetToNextWeek = async () => {
        await dispatch(updateUpdateManagerChecklist(seasonId, seasonMode, week, { doingSetToNextWeek: true, doneWithSetToNextWeek: false }))
        const action = await dispatch((!isPlayoffWeek ? updateManagerRegularSeasonSetToNextWeek : updateManagerPostseasonSetToNextWeek)(seasonId, weekForSetToNextWeek))
        createNotificationMessage(action.payload.messageType, action.payload.message, 'Set Active Week', 5000)
        await dispatch(getFootballDetails())
        await dispatch(updateUpdateManagerChecklist(seasonId, seasonMode, week, { doingSetToNextWeek: false, doneWithSetToNextWeek: true }))
    }

    const doRunManualServiceUpdate = () => {
        dispatch(runManualServiceUpdate(seasonId, seasonMode, week))
        .then(action => dispatch(createNotification(action.payload)))        
    }

    const routeToReminderLog = () => navigate(`/football/admin/reminder-log/season/${seasonName}/${isPlayoffWeek ? 'postseason' : 'regular'}/week/${week}`)

    const onSelectSettingsDropdown = e => {
        switch (e) {
            case 'pickStatus':
                setShowPlayerPickStatusModal(true)
                break
            case 'reminderLog':
                routeToReminderLog()
                break
            case 'manualScoreUpdate':
                doRunManualServiceUpdate()
                break
            case 'scrapeTester':
                navigate(`${location.pathname}/utility/espn-scrape-tester`)
                break
            default:
                break
        }
    }

    const _updateManagerItems = [
        { show: true, label: `Update scores and standings for current ${(isPlayoffWeek ? 'playoff week' : 'week')}`, disabled: doingUpdateScoresStandings, name: 'weekForUpdateScoresStandings', value: weekForUpdateScoresStandings, variant: doneWithUpdateScoresStandings ? 'success' : 'danger', onClick: doUpdateScoresStandings },
        { show: !isPlayoffWeek, label: `Process payment to weekly winner(s)`, disabled: doingProcessPayment, name: 'weekForProcessPayment', value: weekForProcessPayment, variant: doneWithProcessPayment ? 'success' : 'danger', onClick: doProcessPayment },
        { show: true, label: `Update lines for next week's ${(isPlayoffWeek ? 'playoff games' : 'games')}`, disabled: doingUpdateLines, name: 'weekForUpdateLines', value: weekForUpdateLines, variant: doneWithUpdateLines ? 'success' : 'danger', onClick: doUpdateLines },
        { 
            show: true, label: `Prepare AutoPicks for next week's ${(isPlayoffWeek ? 'playoff games' : 'games')}`, disabled: doingAutoPicks, 
            name: 'weekForAutoPicks', value: weekForAutoPicks, variant: doneWithAutoPicks ? 'success' : 'danger', onClick: doAutoPicks,
            controls: [
                { type: 'switch', name: 'overwriteAutoPicks', value: overwriteAutoPicks, labelText: 'overwrite?', isDone: doneWithAutoPicks },
            ],
        },
        { 
            show: true, label: `Send email out to teams with this information`, disabled: doingEmailUpdate, 
            name: 'weekForEmailUpdate', value: weekForEmailUpdate, variant: doneWithEmailUpdate ? 'success' : 'danger', onClick: doEmailUpdate,
            controls: [
                { type: 'switch', name: 'emailWithWinner', value: emailWithWinner, labelText: 'winner?', isDone: doneWithEmailUpdate },
                { type: 'switch', name: 'emailWithAutoPicks', value: emailWithAutoPicks, labelText: 'auto picks?', isDone: doneWithEmailUpdate },
                { type: 'switch', name: 'emailWithSiteUpdated', value: emailWithSiteUpdated, labelText: 'site updated?', isDone: doneWithEmailUpdate },
            ],
        },
        { show: true, label: `Set week to next ${(isPlayoffWeek ? 'playoff week' : 'week')}`, disabled: doingSetToNextWeek, name: 'weekForSetToNextWeek', value: weekForSetToNextWeek, variant: doneWithSetToNextWeek ? 'success' : 'danger', onClick: doSetToNextWeek },
    ]
    .filter(i => i.label && i.value)
    .map((i, _index) => ({ 
        ...i, 
        onControlChange: e => handleBootstrapSwitch(e, e.target.checked),
        key: `update-manager-item-${_index + 1}`
    }))

    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <div className={s.heading + ' ' + s.column}>                            
                        <h2 className={s.title}>{title}</h2>
                        <ButtonGroup className={s.controls}>
                        <SeasonSelector seasons={seasons} season={season} />
                        <WeekSelector showRegularSeasonWeeks={!isPlayoffWeek} 
                            showPlayoffWeeks={isPlayoffWeek} 
                            seasons={seasons} season={season} 
                            week={`${!isPlayoffWeek ? 'W' : 'PW'}${week}`} />
                        <DropdownButton title={<FontAwesomeIcon name='cog' />} 
                            variant='light' align='end' onSelect={onSelectSettingsDropdown}>
                            <Dropdown.Item eventKey='pickStatus'><FontAwesomeIcon name='list-alt' /> Pick status</Dropdown.Item>
                            <Dropdown.Item eventKey='reminderLog'><FontAwesomeIcon name='list-alt' /> Reminder log</Dropdown.Item>
                            <Dropdown.Item eventKey='manualScoreUpdate'><FontAwesomeIcon name='rotate' /> Manual score update</Dropdown.Item>
                            <Dropdown.Item eventKey='scrapeTester'><FontAwesomeIcon name='download' /> ESPN scrape tester</Dropdown.Item>
                        </DropdownButton>
                        </ButtonGroup>
                        <CheckPlayerPickStatus show={showPlayerPickStatusModal} onClose={() => setShowPlayerPickStatusModal(false)} />
                        <EspnScrapeTester show={showEspnScrapeTester} onClose={() => navigate(`${location.pathname.replace(`/utility/${_utility}`, '')}`)}
                            defaultSeasonId={seasonId} defaultSeasonMode={seasonMode} defaultWeek={week} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col lg={{ span: 6, offset: 3 }} md={{ span: 8, offset: 2 }} sm={{ span: 10, offset: 1 }} xs={12}>

                    
                    <ListGroup className={s.updateManagerList}>
                    {_updateManagerItems && _updateManagerItems.map(i =>
                    <UpdateManagerFormListItem {...i} />
                    )}
                    </ListGroup>

                    <div>Last updated {moment(updatedAt).fromNow()}</div>
                </Col>
            </Row>

            {season && weekForUpdateLines ?
            <Modal size='lg'
                heading={'Update ' + seasonName + ' Week ' + weekForUpdateLines + ' Lines'}
                onClose={() => dispatch(updateUpdateManagerChecklist(seasonId, seasonMode, week, { doingUpdateLines: false, doneWithUpdateLines: true, showUpdateLinesModal: false }))}
                show={showUpdateLinesModal}>
                <UpdateNflRegularSeasonLines seasonId={seasonId} seasonMode={seasonMode}
                    seasonName={seasonName} week={weekForUpdateLines} />
            </Modal>
            : null}
            
            {season && weekForUpdateLines ?
            <Modal size='lg'
                heading={'Update ' + seasonName + ' Week ' + weekForUpdateLines + ' Playoff Games'}
                onClose={() => dispatch(updateUpdateManagerChecklist(seasonId, seasonMode, week, { doingUpdateLines: false, doneWithUpdateLines: true, showUpdatePlayoffGamesModal: false }))}
                show={showUpdatePlayoffGamesModal}>
                <EditNflPlayoffGames seasonName={seasonName} isModal defaultWeek={weekForUpdateLines} />
            </Modal>
            : null}
            

            <Modal size='small'
                heading={'Issue Payment to Winners'}
                onClose={() => dispatch(updateUpdateManagerChecklist(seasonId, seasonMode, week, { doingProcessPayment: false, doneWithProcessPayment: true }))}
                show={doingProcessPayment}>
                <UpdateManagerPayPalFrame week={weekForProcessPayment} 
                    winners={winners} entryFee={entryFee} />
            </Modal>

        </div>
    )
}

export default UpdateManager

