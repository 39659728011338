import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import BaseAuthenticatedMenu from './BaseAuthenticatedMenu'
import { Nav, Navbar, NavDropdown } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './Menu.scss'
import { img } from '@severed-links/common.redherrings-constants'
import CloudinaryImage from '../routes/Cloudinary/CloudinaryImage'
import { getDetails, getFrontPageData, getRecapLikeTypes } from '@severed-links/common.redherrings-reducers/cinderella'
import { getMainPageData, getConfig, getPayments, getBracketBuilderTournamentDates } from '@severed-links/common.redherrings-reducers/cinderellaAdmin'
import { getTimeZone } from '@severed-links/common.redherrings-reducers/account'

const CinderellaAdminMenu = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const isAuthenticated = useSelector(state => state.account.isAuthenticated)
    const year = useSelector(state => state.cinderella.year)
    const isHome = location.pathname.toLowerCase() === '/cinderella/admin'

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getTimeZone())
            dispatch(getDetails())
            dispatch(getMainPageData())
            dispatch(getFrontPageData())
            dispatch(getConfig())
            dispatch(getPayments(year))
            dispatch(getBracketBuilderTournamentDates())
            dispatch(getRecapLikeTypes())
        }
    }, [isAuthenticated])

    const navbarBrand = (       
        <Link to={{ pathname: '/cinderella/admin' }}>
            <CloudinaryImage publicId={img.CinderellaHeader} />
        </Link>
    )

    const menuItems = [
        <Nav.Item className={s.item} key={1}><Nav.Link eventKey={1} title='Back to Cinderella' onClick={() => navigate('/cinderella')}>
            <FontAwesomeIcon name='chevron-left' />
        </Nav.Link></Nav.Item>,
        !isHome ? <Nav.Item className={s.item} key={2}><Nav.Link eventKey={2} title='Back to Cinderella Dashboard' onClick={() => navigate('/cinderella/admin')}>
            <FontAwesomeIcon name='home' />
        </Nav.Link></Nav.Item> : null,
        <Nav.Item className={s.item} key={5}><Nav.Link eventKey={5} onClick={() => navigate(`/cinderella/admin/payments/${year}`)}>Payments</Nav.Link></Nav.Item>,
        <Nav.Item className={s.item} key={7}><Nav.Link eventKey={7} onClick={() => navigate(`/cinderella/admin/tournament-games/${year}`)}>Games</Nav.Link></Nav.Item>,
        <Nav.Item className={s.item} key={8}><Nav.Link eventKey={8} onClick={() => navigate(`/cinderella/admin/recap/editor`)}>Recap</Nav.Link></Nav.Item>
    ]
    return (
        <BaseAuthenticatedMenu navbarBrand={navbarBrand} 
            menuItems={menuItems} />
    )
}

export default CinderellaAdminMenu
