import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { getEliminatorWinners } from '@severed-links/common.redherrings-reducers/eliminator'
import * as s from './EliminatorWinners.scss'
import EliminatorWinnerCard from './EliminatorWinnerCard'

const EliminatorWinners = () => {
    
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const winners = useSelector(state => state.eliminator.winners)
    const isFetching = useSelector(state => state.eliminator.isFetching)
    
    useEffect(() => { dispatch(getEliminatorWinners()) }, [dispatch])

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}>Eliminator Winners</h2>
            </div>
            {!isFetching && (!winners || !winners.length) ?
            <div className={s.noWinners}>
                No winners located.
            </div>
            : null}
            <Row className={s.cardContainer}>
            {winners && winners.map(i =>
            <Col xs={12} sm={12} md={6} lg={4} xl={3} 
                key={`winner-card-${i.seasonName}`}
                className={s.winnerCol}>
                <EliminatorWinnerCard winner={i} onClick={_seasonName => navigate(`/eliminator/standings/${_seasonName}`)} />
            </Col>
            )}
            </Row>
        </div>
    )
}

export default EliminatorWinners