// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.EnterOscarsPicksNavBarItem__container___NCtB3 a{padding-left:8px;padding-right:8px;min-width:64px;text-align:center;transition:background-color .5s ease-in-out;background-color:rgba(0,0,0,0) !important;color:inherit !important;font-size:1.5rem;white-space:nowrap}.EnterOscarsPicksNavBarItem__container___NCtB3.EnterOscarsPicksNavBarItem__active___IlSMF a{color:#fff !important;background-color:#337ab7 !important}.EnterOscarsPicksNavBarItem__container___NCtB3.EnterOscarsPicksNavBarItem__done___xT0BH a{color:#3c763d !important;background-color:#dff0d8 !important}.EnterOscarsPicksNavBarItem__container___NCtB3.EnterOscarsPicksNavBarItem__notDone___p8EiC a{color:#8a6d3b !important;background-color:#fcf8e3 !important}`, "",{"version":3,"sources":["webpack://./src/routes/Oscars/EnterOscarsPicksNavBarItem.scss"],"names":[],"mappings":"AACI,iDACI,gBAAA,CACA,iBAAA,CACA,cAAA,CACA,iBAAA,CACA,2CAAA,CACA,yCAAA,CACA,wBAAA,CACA,gBAAA,CACA,kBAAA,CAGA,4FACI,qBAAA,CACA,mCAAA,CAIJ,0FACI,wBAAA,CACA,mCAAA,CAIJ,6FACI,wBAAA,CACA,mCAAA","sourcesContent":[".container {\n    a {\n        padding-left: 8px;\n        padding-right: 8px;\n        min-width: 64px;\n        text-align: center;\n        transition: background-color 0.5s ease-in-out;\n        background-color: transparent !important;\n        color: inherit !important;\n        font-size: 1.5rem;\n        white-space: nowrap;\n    }\n    &.active {\n        a {\n            color: white !important;\n            background-color: #337ab7 !important;\n        }\n    }\n    &.done {\n        a {\n            color: #3c763d !important;\n            background-color: #dff0d8 !important;            \n        }\n    }\n    &.notDone {\n        a {\n            color: #8a6d3b !important;\n            background-color: #fcf8e3 !important;\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
export var container = `EnterOscarsPicksNavBarItem__container___NCtB3`;
export var active = `EnterOscarsPicksNavBarItem__active___IlSMF`;
export var done = `EnterOscarsPicksNavBarItem__done___xT0BH`;
export var notDone = `EnterOscarsPicksNavBarItem__notDone___p8EiC`;
export default ___CSS_LOADER_EXPORT___;
