import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Row, Col, FormControl, FormGroup, Button, FormLabel, Alert, ProgressBar, ListGroup } from 'react-bootstrap'
import { getProfile, changeMyPassword } from '@severed-links/common.redherrings-reducers/account'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './ChangePassword.scss'
import { PASSWORD_VALIDATOR, PASSWORD_SCORE, MINIMUM_PASSWORD_SCORE } from '@severed-links/common.redherrings-constants'
import PasswordValidationRules from '../ForgotPassword/PasswordValidationRules'
import zxcvbn from 'zxcvbn'

const ChangeMyPassword = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const user = useSelector(state => state.account)
    const [password, setPassword] = useState('')
    const [progressBar, setProgressBar] = useState({ length: 0, style: 'danger' })
    const [passwordStrength, setPasswordStrength] = useState({})
    const [failedRules, setFailedRules] = useState([])

    useEffect(() => { validatePassword() }, [password])

    const validatePassword = () => {
        setFailedRules(password ? PASSWORD_VALIDATOR.validate(password, { list: true }) : [])
        const _passwordStrength = zxcvbn(password)
        const _passwordLength = PASSWORD_SCORE(password.length, _passwordStrength.score)
        setPasswordStrength(_passwordStrength)
        var style = 'danger'
        if (_passwordLength >= MINIMUM_PASSWORD_SCORE) style = 'success'
        else if (_passwordLength >= MINIMUM_PASSWORD_SCORE / 2.0) style = 'warning'
        setProgressBar({ length: _passwordLength, style })
    }
    
    const doChangeMyPassword = () => {
        dispatch(changeMyPassword(password))
        .then(action => {
            if (action.payload.messageType === 'success') {
                setPassword('')
                setFailedRules([])
            }
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, headline: action.payload.subject, timeout: 4000 }))
        })
    }

    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <h2>Change my Redherrings password</h2>
                </Col>
            </Row>
            <Row>
                <Col xs={12} sm={{ span: 6, offset: 3 }} md={{ span: 6, offset: 3 }}>
                    <FormGroup className={s.formGroup} controlId='txtChangePasswordPassword'>
                        <FormLabel>New password</FormLabel>
                        <FormControl placeholder='Enter a new password...' 
                            name='password' value={password} size='lg'
                            onChange={e => setPassword(e.target.value)} />
                        <ProgressBar now={progressBar.length} 
                            variant={progressBar.style} className={s.progressBar} />
                    </FormGroup>
                    <FormGroup className={s.formGroup} size='lg' controlId='Submit'>
                        <Button size='lg' variant='light' onClick={() => navigate(-1)}>
                            <FontAwesomeIcon name='times' /> cancel
                        </Button>
                        <Button disabled={failedRules.length > 0} style={{ marginLeft: '30px' }} 
                            size='lg' variant='primary' onClick={() => doChangeMyPassword()}>
                            <FontAwesomeIcon name='lock' /> change password
                        </Button>
                    </FormGroup>
                    <FormGroup className={s.formGroup}>
                        <PasswordValidationRules show={!!password} 
                            failedRules={failedRules}
                            score={progressBar.length} />
                    </FormGroup>
                </Col>
            </Row>
            </div>
    )
}

export default ChangeMyPassword
