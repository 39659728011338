import React, { useEffect } from 'react'
import CloudinaryImage from '../Cloudinary/CloudinaryImage'
import * as s from './NflTeam.scss'

const NflTeam = ({ teamId, teamName, mascot, showMascot = true, showTeamName = true, onClick, roundedLogo = false, className = null }) => {

    return (
        <div key={teamId} className={s.teamContainer + ' ' + (onClick ? s.hasClick : null) + (className ? ` ${className}` : null)} title={teamName} onClick={onClick ? () => onClick() : void(0)}>
            {showMascot ? <CloudinaryImage className={s.teamAvatar + (roundedLogo ? ` ${s.rounded}` : ``)} publicId={`/football/nfl-team-small-avatars/${(mascot || '').toLowerCase()}`} /> : null}
            {showTeamName ? <div className={s.teamName}><span className={s.name}>{teamName}</span><span className={s.mascot}>{mascot}</span></div> : null}
        </div>
    )
}

export default NflTeam