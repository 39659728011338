import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import { Row, Col, Table, Alert, ListGroup, Card } from 'react-bootstrap'
import { filter, forEach } from 'lodash'
import * as s from './Nominees.scss'
import moment from 'moment-timezone'
import { getNominees } from '@severed-links/common.redherrings-reducers/oscars'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'

const Nominees = () => {

    const dispatch = useDispatch()
    const location = useLocation()
    const navigate = useNavigate()
    const params = useParams()
    const nominees = useSelector(state => state.oscars.nominees)
    const year = params.year || null

    useEffect(() => { dispatch(getNominees(year)) }, [year])

    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <h2>{year} Nominees</h2>
                </Col>
            </Row>
            <Row>
            {nominees && nominees.categories && nominees.categories.map(i => 
                <Col md={4} sm={6} xs={12} key={`nominee-card-${i.num}`}>
                    <Card className={s.nomineeCard}>
                        <Card.Header>{i.category}</Card.Header>
                        <ListGroup variant='flush' className={s.nominees}>
                        {nominees && nominees.items && filter(nominees.items, j => j.num === i.num).map(x => 
                            <ListGroup.Item className={s.nominee + (x.pickIsCorrect ? ' ' + s.isCorrect : '')} 
                                key={`nominee-${i.num}-item-${x.choice}`}>
                                <div className={s.content}>
                                    <div className={s.name}>{x.choiceText}</div>
                                    {x.pickIsCorrect ? <div className={s.correct}><FontAwesomeIcon name='check' /></div> : null}
                                </div>
                            </ListGroup.Item>
                        )}
                        </ListGroup>
                    </Card>
                </Col>
            )}
            </Row>
            {!nominees || !nominees.categories || nominees.categories.length === 0 ?
            <Alert>No {year} nominees located.</Alert>
            : null}
        </div>
    )
}

export default Nominees