import React, { useEffect } from 'react'
import { Card, ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import PreseasonDivisionRow from './PreseasonDivisionRow'
import * as s from './Preseason.scss'

const PreseasonDivision = ({ division = {}, playerId = null }) => {

    const d = division
    return (
        <Card className={s.divisionCard} key={d.divisionCode}>
            <Card.Header as='h4' className={s.divisionName}>{d.divisionName} Division</Card.Header>
            <ListGroup className={s.preseasonDivisionList} variant='flush'>
            {d.standings && d.standings.map(s => 
            <PreseasonDivisionRow key={s.teamId} 
                divisionItem={s} 
                divisionCode={d.divisionCode} 
                playerId={playerId} />
            )}
            {!d.standings || !d.standings.length ? 
            <ListGroup.Item>No teams located.</ListGroup.Item>
            : null}
            </ListGroup>
        </Card>
    )
}

export default PreseasonDivision