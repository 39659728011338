import React, { useE } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Table } from 'react-bootstrap'
import { getNflStandings } from '@severed-links/common.redherrings-reducers/football'
import { find } from 'lodash'
import * as s from './NFLStandings.scss'
import NflTeam from '../../NflTeam'

const NFLStandingsDivision = ({ division = null, seasonName = '' }) => {

    const navigate = useNavigate()

    if (!division || !division.conference || !division.standings || !seasonName) return null
    return (
        <div>
            <h4>{division.conference} {division.division}</h4>
            <Table striped responsive='sm'>
                <thead>
                    <tr>
                        <th>Team</th>
                        <th className='text-end'>W</th>
                        <th className='text-end'>L</th>
                        <th className='text-end'>T</th>
                        <th className='text-end'>.pct</th>
                        <th className='text-end'>PF</th>
                        <th className='text-end'>PA</th>
                    </tr>
                </thead>
                <tbody>
                {division.standings && division.standings.map(x => 
                    <tr key={`nfl-division-row-${seasonName}-${division.conference}-${division.division}-${x.teamId}`}>
                        <td><NflTeam teamId={x.teamId} teamName={x.nflTeam} mascot={x.mascot} onClick={() => seasonName && mascot ? navigate(`/football/nfl-team-analysis/${seasonName}/${x.mascot.toLowerCase()}`) : null} /></td>
                        <td className='text-end'>{x.w}</td>
                        <td className='text-end'>{x.l}</td>
                        <td className='text-end'>{x.t}</td>
                        <td className='text-end'>{x.winPctStr}</td>
                        <td className='text-end'>{x.pf}</td>
                        <td className='text-end'>{x.pa}</td>
                    </tr>
                )}
                </tbody>
            </Table>
        </div>
    )
}

export default NFLStandingsDivision