import React, { useEffect } from 'react'
import { Button, ButtonGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './RankButtonsContainer.scss'
import { filter } from 'lodash'

const RankButtonsContainer = ({ ranks = [], game, show = false, onClick, onClose, isOuRanks = false }) => {

    if (!show) return null
    return (
        <div className={s.container}>
            <div className={s.close}>
                <Button variant='light' size='sm' onClick={e => onClose(e, false)}>
                    <FontAwesomeIcon name='times' />
                </Button>
            </div>
            <ButtonGroup className={s.rankButtons}>
                {ranks && filter(ranks, r => r !== (!isOuRanks ? game.rank : game.ouRank)).map(i => <Button variant='light' onClick={e => onClick(e, i)} key={`rank-button-${game.game}-${i}`}>{i}</Button>)}
            </ButtonGroup>
        </div>
    )
}

export default RankButtonsContainer
