// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FAQ__container___sGWHn{margin-bottom:3rem}.FAQ__container___sGWHn .FAQ__title___F3qna{margin-bottom:1rem}.FAQ__container___sGWHn .FAQ__questionList___sxGQN{margin-bottom:1rem}.FAQ__container___sGWHn .FAQ__questionList___sxGQN .FAQ__questionItem___VrSCY .FAQ__question___lPXYM{font-weight:700}.FAQ__container___sGWHn .FAQ__questionList___sxGQN .FAQ__questionItem___VrSCY .FAQ__answer___VtbAK{font-weight:normal}`, "",{"version":3,"sources":["webpack://./src/routes/Football/RegularSeason/FAQ.scss"],"names":[],"mappings":"AAAA,wBACI,kBAAA,CAEA,4CACI,kBAAA,CAEJ,mDACI,kBAAA,CAEI,qGACI,eAAA,CAGJ,mGACI,kBAAA","sourcesContent":[".container {\n    margin-bottom: 3rem;\n\n    .title {\n        margin-bottom: 1rem;\n    }\n    .questionList {\n        margin-bottom: 1rem;\n        .questionItem {\n            .question {\n                font-weight: 700;\n            }\n            \n            .answer {\n                font-weight: normal;\n            }\n        }\n    }\n}\n\n"],"sourceRoot":""}]);
// Exports
export var container = `FAQ__container___sGWHn`;
export var title = `FAQ__title___F3qna`;
export var questionList = `FAQ__questionList___sxGQN`;
export var questionItem = `FAQ__questionItem___VrSCY`;
export var question = `FAQ__question___lPXYM`;
export var answer = `FAQ__answer___VtbAK`;
export default ___CSS_LOADER_EXPORT___;
