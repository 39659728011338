import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useMatch } from 'react-router-dom'
import { ProgressBar, Tooltip, OverlayTrigger, ListGroup } from 'react-bootstrap'
import { getReport } from '@severed-links/common.redherrings-reducers/football'
import { find, startsWith, isEqual } from 'lodash'
import SeasonSelector from '../SeasonSelector'
import * as s from './FootballLeagueReport.scss'
import PlayerTeam from '../PlayerTeam'
import PlayerTeamListItem from '../PlayerTeamListItem'
import numeral from 'numeral'

const PickPercentageReport = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const _match = useMatch(`/football/reports/:reportId/*`)
    const seasonName = params.seasonName || null
    const reportId = ((_match || {}).params || {}).reportId || null
    const seasons = useSelector(state => state.football.seasons)
    const playerId = useSelector(state => state.account._id)
    const reports = useSelector(state => state.football.reports)
    const season = seasons.find(i => i.seasonName === seasonName) || {}
    const seasonId = season.seasonId || null
    const report = reports.find(i => i.seasonId === seasonId && i.reportId === reportId)

    useEffect(() => { fetchReport() }, [seasonId, reportId])

    const fetchReport = () => seasonId && reportId ? dispatch(getReport(seasonId, reportId)) : null

    if (!report) return null

    return (
        season && season.seasonName && report ? 
        <div className={s.container}>
            <div className={s.titleBar}>
                <h2 className={s.title}>{season.seasonName} Pick Percentage (How's Your Ranking Ability?)</h2>
                <div className={s.seasonSelector}><SeasonSelector season={season} seasons={seasons} /></div>
            </div>
            <ListGroup>
            {report.items && report.items.map((i, index) =>
                <PlayerTeamListItem key={`${reportId}-${i.teamId}`} 
                    right={<div title={`PF: ${i.pf} Correct picks: ${i.correctPicks}`}>
                    {i.pickLeader === 1 ?
                    <span>
                        <Tooltip placement='left' className={s.toolTip + ' in'} id='pick-percentage-leader'>Leader</Tooltip>
                        <span>{numeral(i.correctPct).format('0.0%')}</span>
                    </span>
                    :
                    <span>{numeral(i.correctPct).format('0.0%')}</span>}
                    </div>}
                    detail={<div>
                        <ProgressBar style={{ marginBottom: "0px" }}>
                            <ProgressBar variant='success' now={i.normalizedHighPct * 100.0} label={numeral(i.correctHighPct).format('0.0%') + (i.highPickLeader ? ' (Leader)' : '')} key={1} />
                            <ProgressBar variant='warning' now={i.normalizedMedPct * 100.0} label={numeral(i.correctMedPct).format('0.0%') + (i.medPickLeader ? ' (Leader)' : '')} key={2} />
                            <ProgressBar variant='danger' now={i.normalizedLowPct * 100.0} label={numeral(i.correctLowPct).format('0.0%') + (i.lowPickLeader ? ' (Leader)' : '')} key={3} />
                        </ProgressBar>
                    </div>}
                    teamId={i.teamId} teamName={i.teamName} teamNameRaw={i.teamName} avatarUrl={i.avatarUrl}
                    rank={i.rank} />
            )}
            </ListGroup>
        </div>
        : null
    )
}

export default PickPercentageReport