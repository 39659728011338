import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col, Alert } from 'react-bootstrap'
import { divisionStandings } from '@severed-links/common.redherrings-reducers/football'
import { find, forEach, update } from 'lodash'
import DivisionStandings from './DivisionStandings'
import SeasonSelector from './SeasonSelector'
import * as s from './DivisionStandingsView.scss'
import { getFootballUrlParams } from '@severed-links/common.redherrings-constants'
import { isEqual } from 'lodash'

const DivisionStandingsView = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const playerId = useSelector(state => state.account._id)
    const seasons = useSelector(state => state.football.seasons)
    const leagueStandings = useSelector(state => state.football.leagueStandings)
    const c = getFootballUrlParams(params, seasons)

    useEffect(() => { updateStandings() }, [seasons.length, c.season])

    const updateStandings = () => {
        if (c.season) {
            dispatch(divisionStandings(c.season.seasonId))
        }
    }

    const oStandings = find(leagueStandings, x => c.season && x.seasonId === c.season.seasonId)
    var standings = []
    if (oStandings && oStandings.leagueStandings) {
        forEach(oStandings.leagueStandings, (d, index) => { 
            standings.push(<Col key={'sm-' + d.divisionName}  sm={6}><DivisionStandings playerId={playerId} division={d} seasonName={c.season.seasonName} key={d.division} shortForm={false} /></Col>)
        })
    }
    return (
            <div className={s.container}>
            {c.season && standings ? 
            <div>
                <Row>
                    <Col xs={12}>
                        <span className={s.seasonSelector}><SeasonSelector seasons={seasons} season={c.season} /></span>
                        <h2>{c.season.seasonName} League Standings</h2>
                    </Col>
                </Row>
                <Row className='d-none d-sm-flex'>
                    {standings}
                </Row>
                <Row className='d-inline-block d-sm-none'>
                        {oStandings && oStandings.leagueStandings && oStandings.leagueStandings.map(d => 
                        <Col key={'xs-' + d.divisionName} xs={12}>
                            <DivisionStandings playerId={playerId} division={d} seasonName={c.season.seasonName} key={d.division} shortForm={true} />
                        </Col>
                        )}

                </Row>
            </div>
            : null}
            {!standings || standings.length === 0 ?
                <Alert>No teams loaded for this season yet.</Alert>
            : null}
            </div>

    )
}

export default DivisionStandingsView