import React, { useEffect, useState } from 'react'
import { Row, Col } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import moment from 'moment-timezone'

const ScoreboardLastUpdate = ({ lastUpdate }) => {

    const [currentTime, setCurrentTime] = useState(moment().toDate())

    useEffect(() => {
        let timerId = setInterval(() => tick(), 1000)
        return () => clearInterval(timerId)
    }, [])

    const tick = () => setCurrentTime(moment().toDate())

    return (
        lastUpdate && currentTime ? <span>{moment(lastUpdate).fromNow()}</span> : null
    )
}

export default ScoreboardLastUpdate