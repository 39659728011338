import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormGroup, FormLabel, FormControl, Alert } from 'react-bootstrap'
import { trim, toUpper } from 'lodash'
import * as s from './SignUp.scss'
import { checkUsername, setUsername, setUserInfo } from '@severed-links/common.redherrings-reducers/siteRegistration'
import validator from 'validator'

const UsernameStep2 = forwardRef(({ jumpToStep }, _ref) => {

    const dispatch = useDispatch()
    const siteRegistration = useSelector(state => state.siteRegistration)
    const [userName, doSetUsername] = useState('')
    const _isValid = !validator.isEmpty(userName) && siteRegistration.userNameIsAvailable

    useEffect(() => { setUsername(siteRegistration.userName || '') }, [])

    useEffect(() => {
        if (siteRegistration.nextDisabled !== !_isValid) {
            dispatch(setUserInfo({ nextDisabled: !_isValid }))
        }
    }, [siteRegistration.nextDisabled, _isValid])

    useEffect(() => { dispatch(checkUsername(userName)) }, [userName])

    const handleChange = e => {
        const _username = toUpper(trim(e.target.value.replace(/[^A-Za-z0-9]/g, '')))
        doSetUsername(_username)
        dispatch(setUsername(_username))
    }

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValid
    }))

    if (!siteRegistration) return null
    return (
        <div className={s.step2 + ' ' + s.step}>
            <FormGroup className={s.usernameContainer}>
                <FormLabel>Enter your user name</FormLabel>
                <FormControl size='lg' autoFocus autoComplete='off' type='text' 
                    name='userName' value={userName} 
                    required isValid={siteRegistration.userNameIsAvailable}
                    isInvalid={!siteRegistration.userNameIsAvailable}
                    onChange={e => handleChange(e)} />
                <FormControl.Feedback />
                <div className={s.alertContainer}>
                    {_isValid ?
                    <Alert variant='success'>This user name is available!</Alert>
                    : null}
                </div>
            </FormGroup>
        </div>
    )
})

export default UsernameStep2
