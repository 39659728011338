import React, { useEffect, useState, memo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { withRouter } from 'react-router'
import { ListGroup, Badge, Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './LeagueSchedulerGrid.scss'
import Player from '../../Player/Player'
import PlayerTeam from '../PlayerTeam'
import { forEach, filter, range } from 'lodash'
import { teamUrl, playerUrl } from '@severed-links/common.redherrings-constants'
import pluralize from 'pluralize'

const LeagueSchedulerDivisionHeading = ({ divisionName = '', teamCount = 0, weeks = [] }) => (
    <ListGroup.Item className={s.divisionHeadingItem}>
        <div className={s.divisionName}>{divisionName}{teamCount ? ` (${teamCount})` : ''}</div>
        {weeks && weeks.map(_week => <div className={s.weekItem} key={`week-${_week}`}>{_week}</div>)}
        <div className={s.weekItem}>&nbsp;</div>
    </ListGroup.Item>
)

const LeagueSchedulerGridGameItem = memo(({ oppTeamName = null, oppAvatarUrl = null }) => (
    <div className={s.gameItem + ' ' + (oppTeamName ? s.success : s.danger)} title={oppTeamName || '-Empty-'}>
    {oppTeamName ?
    <Image src={teamUrl(oppAvatarUrl)} className={s.oppAvatar} />
    : 
    <FontAwesomeIcon name='times' className={s.noOppIcon} />}
    </div>
))

const LeagueSchedulerGridTeamItem = ({ team = {} }) => (
    <ListGroup.Item className={s.teamItem}>
        <div className={s.team}>
            <div className={s.avatar}><Image src={teamUrl(team.avatarUrl)} /></div>
            <div className={s.nameContainer}>
            <div className={s.teamName}>{team.teamName}</div>
            </div>
        </div>
        <div className={s.player}>
            <div className={s.avatar}><Image src={playerUrl(team.playerAvatarUrl)} /></div>
            <div className={s.nameContainer}>
                <div className={s.firstName}>{team.firstName}</div>
                <div className={s.lastName}>{team.lastName}</div>
            </div>
        </div>
        {team.games && team.games.map(g =>
        <LeagueSchedulerGridGameItem 
            oppTeamName={(g.opp || {}).teamName || null}
            oppAvatarUrl={(g.opp || {}).avatarUrl || null}
            key={`game-${g.week}`} />
        )}
        <div className={s.gameItem + ' ' + s.numGames + ' ' + (team.numGames === team.games.length ? s.success : s.danger)}>{team.numGames || 0}</div>
    </ListGroup.Item>
)

const LeagueSchedulerGrid = ({ grid = [], isRunning = false, weeks = [] }) => {
    
    return (
        <div className={s.container}>
            <ListGroup className={s.list}>
            {grid && grid.map((g, index) => [
                index == 0 || g.division !== grid[index - 1].division ?
                <LeagueSchedulerDivisionHeading divisionName={g.divisionName} teamCount={grid.filter(row => row.division === g.division).length} weeks={weeks} key={`league-scheduler-grid-division-heading-${index}`} />
                : null,
                <LeagueSchedulerGridTeamItem team={g} key={`league-scheduler-grid-item-${g.teamId}`} />
            ])}
            </ListGroup>
        </div>
    )
}

export default LeagueSchedulerGrid