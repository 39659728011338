import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import BaseAuthenticatedMenu from './BaseAuthenticatedMenu'
import { Nav } from 'react-bootstrap'
import { img } from '@severed-links/common.redherrings-constants'
import CloudinaryImage from '../routes/Cloudinary/CloudinaryImage'
import * as s from './Menu.scss'
import { getAdmins, getContests, getRegistrationPassphrase } from '@severed-links/common.redherrings-reducers/captureAdmin'
import { getActiveContest } from '@severed-links/common.redherrings-reducers/capture'

const CaptureAdminMenu = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const isAuthenticated = useSelector(state => state.account.isAuthenticated)
    const contest = useSelector(state => state.capture.contest)
    const isHome = location.pathname.toLowerCase() === '/capture/admin'

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getActiveContest())
            dispatch(getAdmins())
            dispatch(getContests())
            dispatch(getRegistrationPassphrase())
        }
    }, [isAuthenticated])

    const navbarBrand = (       
        <Link to={{ pathname: '/capture/admin' }}>
            <CloudinaryImage publicId={img.CaptureHeader} />
        </Link>
    )

    const menuItems = [
        <Nav.Item className={s.item} key={1}><Nav.Link eventKey={1} title='Back to Capture' onClick={() => navigate('/capture')}>
            <FontAwesomeIcon name='chevron-left' />
        </Nav.Link></Nav.Item>,
        !isHome ? <Nav.Item className={s.item} key={2}><Nav.Link eventKey={2} title='Back to Capture Dashboard' onClick={() => navigate('/capture/admin')}>
            <FontAwesomeIcon name='home' />
        </Nav.Link></Nav.Item> : null,
        <Nav.Item className={s.item} key={3}><Nav.Link eventKey={3} onClick={() => navigate(`/capture/admin/contests`)}>Contests</Nav.Link></Nav.Item>,
        <Nav.Item className={s.item} key={4}><Nav.Link eventKey={4} onClick={() => navigate(`/capture/admin/team-draw/${contest._id}`)}>Team Draw</Nav.Link></Nav.Item>,
        <Nav.Item className={s.item} key={5}><Nav.Link eventKey={5} onClick={() => navigate(`/capture/admin/team-draw-print/${contest._id}`)}>Team Draw (Print)</Nav.Link></Nav.Item>,
        <Nav.Item className={s.item} key={6}><Nav.Link eventKey={6} onClick={() => navigate(`/capture/admin/game-spreads/${contest.year}`)}>Game Spreads</Nav.Link></Nav.Item>
    ]
    return (
        <BaseAuthenticatedMenu backgroundImageUrl={img.CloudinaryBaseUrl + img.CaptureHeaderBackground}
            navbarBrand={navbarBrand} 
            menuItems={menuItems} />
    )
}

export default CaptureAdminMenu
