import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Row, Col, Button } from 'react-bootstrap'
import * as s from './OscarsAdmin.scss'
import Logins from '../../Main/Admin/Logins'
import OscarsBroadcastMessage from './OscarsBroadcastMessage'
import OscarsConfigSummary from './OscarsConfigSummary'
import OscarsEditConfiguration from './OscarsEditConfiguration'
import Modal from '@severed-links/common.modal'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import OnlineUsers from '../../../components/OnlineUsers'
import SendPushNotification from '../../Notifications/SendPushNotification'

const OscarsAdminDashboard = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const year = useSelector(state => state.oscars.year)
    const pickDeadline = useSelector(state => state.oscars.pickDeadline)
    const dateFormat = useSelector(state => state.oscars.dateFormat)
    const passphrase = useSelector(state => state.oscars.passphrase)
    const loginInfo = useSelector(state => state.oscarsAdmin.loginInfo)
    const onlineUsers = useSelector(state => state.oscars.onlineUsers)
    const [showSetYearModal, setShowSetYearModal] = useState(false)
    const [showBroadcastMessage, setShowBroadcastMessage] = useState(false)
    const [showEditConfiguration, setShowEditConfiguration] = useState(false)

    return (
        <div className={s.container}>
            <h2>Oscars Dashboard</h2>
            <Row>
                <Col sm={6} xs={12}>
                    <Row>
                        <Col xs={12}>
                            <OscarsConfigSummary />
                        </Col>
                    </Row>
                    <Row className={s.dashboardButtonContainer}>
                        <Col xs={6}>
                            <Button variant='light' onClick={() => setShowBroadcastMessage(true)}><FontAwesomeIcon name='envelope' /> send email</Button>
                        </Col>
                        <Col xs={6}>
                            <Button variant='light' onClick={() => setShowEditConfiguration(true)}><FontAwesomeIcon name='cog' /> edit configuration</Button>
                        </Col>
                        <Col xs={6}>
                            <Button variant='light' onClick={() => dispatch(createNotification({ type: 'info', headline: 'test message', message: 'test notification', timeout: 0 }))}><FontAwesomeIcon name='rotate' /> test notification</Button>
                        </Col>
                        <Col xs={6}>
                            <Button variant='light' onClick={() => navigate('/oscars/admin/categories')}><FontAwesomeIcon name='list' /> categories</Button>
                        </Col>
                    </Row>
                </Col>
                <Col sm={6} xs={12}>
                    <Row>
                        <Col xs={12}>
                            <h4>
                                Online Users
                                {' '}
                                <SendPushNotification />
                            </h4>
                            <OnlineUsers users={onlineUsers} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <Logins isDashboard />
                        </Col>
                    </Row>
                </Col>
            </Row>

            <Modal heading='Broadcast Message'
                show={showBroadcastMessage} showFooter={false}
                onClose={() => setShowBroadcastMessage(false)}
                actionButtonText={null}>
                <OscarsBroadcastMessage />
            </Modal>

            <Modal heading='Edit Configuration'
                show={showEditConfiguration} showFooter={false}
                onClose={() => setShowEditConfiguration(false)}
                actionButtonText={null}>
                <OscarsEditConfiguration />
            </Modal>

        </div>
    )
}

export default OscarsAdminDashboard