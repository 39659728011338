import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams } from 'react-router-dom'
import CloudinaryImage from '../Cloudinary/CloudinaryImage'
import { FormGroup, FormControl, InputGroup, Button, InputGroupButton } from 'react-bootstrap'
import { find, orderBy, findIndex, range, isEqual } from 'lodash'
import moment from 'moment-timezone'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'

const YearSelector = ({ defaultYear = moment().year(), buttonTitle = 'change year', buttonIcon = 'calendar', onChangeYear = null }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const [year, setYear] = useState(moment().year())

    useEffect(() => { setYear(defaultYear) }, [defaultYear])

    const changeUrlParam = () => navigate(location.pathname.replace(params.year, `${year}`))

    const changeYearOnClick = () => {
        if (typeof onChangeYear === 'function') {
            onChangeYear(year)
        } else {
            changeUrlParam()
        }
    }

    return (
        <FormGroup>
            <InputGroup>
                <FormControl type='number' value={`${year}`}
                    onChange={e => setYear(parseInt(e.target.value)) } />
                <Button variant='light' onClick={() => changeYearOnClick()}>
                    {buttonIcon ? <FontAwesomeIcon name={buttonIcon} /> : null}
                    {' '}
                    {buttonTitle ? <span>{buttonTitle}</span> : null}
                </Button>
            </InputGroup>
        </FormGroup> 
    )
}

export default YearSelector
