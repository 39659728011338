import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router'
import { Row, Col, FormControl, FormLabel, FormGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import HtmlEditor from '../../Editors/HtmlEditor'
import { sendMail } from '@severed-links/common.redherrings-reducers/cinderellaAdmin'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import * as s from './CinderellaSendMail.scss'

const CinderellaSendMail = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const cinderellaSendMailMessage = useSelector(state => state.editors.cinderellaSendMailMessage)
    const isFetching = useSelector(state => state.cinderellaAdmin.isFetching)
    const [to, setTo] = useState('Admin')
    const [subject, setSubject] = useState('')
    const [message, setMessage] = useState('')

    const doSendMail = () => {
        if (subject && cinderellaSendMailMessage) {
            dispatch(sendMail({ messageType: to, subject: subject, body: cinderellaSendMailMessage }))
            .then(action => {
                dispatch(createNotification({ headline: action.payload.subject, type: action.payload.messageType, message: action.payload.message, timeout: 5000 }))
                navigate('/cinderella/admin')
            })
        }
    }

    return (
        <div className={s.container}>
            <FormGroup className={s.formGroup}>
                <FormLabel>Recipients</FormLabel>
                <FormControl as='select' 
                    onChange={e => setTo(e.target.value)}
                    value={to}>
                    <option value='Admin'>Administrators only (for testing)</option>
                    <option value='Current'>Players with Entries this Year</option>
                    <option value='All'>All Cinderella Players</option>
                </FormControl>
            </FormGroup>
            <FormGroup className={s.formGroup}>
                <FormLabel>Subject</FormLabel>
                <FormControl placeholder='Subject...' 
                    onChange={e => setSubject(e.target.value)}
                    value={subject} />
            </FormGroup>
            <FormGroup className={s.formGroup}>
                <HtmlEditor content={message} 
                    name={'cinderellaSendMailMessage'}
                    showToolbar
                    placeholder={'Message content...'} />
            </FormGroup>
            <FormGroup>
                <p><small>Field inserts: <pre style={{ whiteSpace: 'normal' }}>%recipient.firstName% %recipient.lastName% %recipient.userName%</pre></small></p>
            </FormGroup>
            <FormGroup className={s.formGroup + ' ' + s.submit}>
                <Button variant='primary' 
                    disabled={isFetching}
                    onClick={() => doSendMail()}>
                    <FontAwesomeIcon name={!isFetching ? 'envelope' : 'circle-notch'} spin={isFetching} /> {!isFetching ? 'send' : 'sending'} email
                </Button>
            </FormGroup>
        </div>
    )
}

export default CinderellaSendMail