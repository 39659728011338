import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Row, Col, Alert, FormGroup, FormLabel, FormControl, 
    Button, InputGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { setupLeaguePlayoffs, setupLeaguePlayoffsLoadPlayoffTeams, 
    setupLeaguePlayoffsSetPlayoffWeek, setupLeaguePlayoffsSetSeasonMode,
    getConfig } from '@severed-links/common.redherrings-reducers/footballAdmin'
import { find, sumBy } from 'lodash'
import SeasonSelector from '../SeasonSelector'
import * as s from './FootballAdmin.scss'
import PlayoffRaceReport from '../Reports/PlayoffRaceReport'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import Modal from '@severed-links/common.modal'
import EditNflPlayoffGames from './EditNflPlayoffGames'
import pluralize from 'pluralize'

const SetupLeaguePlayoffs = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const seasonName = params.seasonName || null
    const showNflPlayoffModal = params.subTab === 'edit-nfl-playoffs'
    const { seasons, seasonModes } = useSelector(state => state.football)
    const season = seasons.find(i => i.seasonName === seasonName)
    const seasonId = (season || {}).seasonId || null
    const playoffSetup = useSelector(state => state.footballAdmin.playoffSetup)
    const _playoffWeek = useSelector(state => state.football.playoffWeek)
    const _seasonMode = useSelector(state => state.football.seasonMode)
    const [seasonMode, setSeasonMode] = useState('')
    const [playoffWeek, setPlayoffWeek] = useState('')

    useEffect(() => { getSetupData() }, [seasonId])

    useEffect(() => {
        dispatch(getConfig(seasonName))
        .then(action => {
            if (action.payload.seasonConfig) {
                setPlayoffWeek(`${action.payload.seasonConfig.activePlayoffWeek}`)
                setSeasonMode(`${action.payload.seasonConfig.seasonMode}`)
            }
        })
    }, [seasonName])

    const getSetupData = () => {
        if (seasonId) {
            dispatch(setupLeaguePlayoffs(seasonId))
        }
    }

    const doSetSeasonMode = () => {
        dispatch(setupLeaguePlayoffsSetSeasonMode(seasonId, seasonMode))
        .then(action => dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, timeout: 3000, headline: 'Setup League Playoffs' })))
    }

    const doSetPlayoffWeek = () => {
        dispatch(setupLeaguePlayoffsSetPlayoffWeek(seasonId, playoffWeek))
        .then(action => dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, timeout: 3000, headline: 'Setup League Playoffs' })))
    }

    const loadPlayoffTeams = () => {
        dispatch(setupLeaguePlayoffsLoadPlayoffTeams(seasonId))
        .then(action => {
            dispatch(createNotification({ message: action.payload.message, type: action.payload.messageType, timeout: 3000, headline: 'Setup League Playoffs' }))
            dispatch(setupLeaguePlayoffs(seasonId))
        })
    }

    if (!season || !playoffSetup) return null
    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}>Setup {seasonName} League Playoffs</h2>
                <div className={s.seasonSelector}><SeasonSelector seasons={seasons} season={season} /></div>
            </div>
            {!playoffSetup.hasProperWeekTotals ?
            <Alert variant='danger'>Teams have records which do not total {pluralize('game', playoffSetup.numRegularSeasonWeeks, true)}.  It is likely that you need to run the regular season update manager one last time.</Alert>
            : null}
            <Row>
                <Col md={3} sm={6} xs={12}>
                    <div className={s.stepContainer}>
                        <div className={s.background}>1</div>
                        <div className={s.content}>
                            <FormGroup>
                                <FormLabel>Set to postseason</FormLabel>
                                <InputGroup>
                                    <FormControl as={'select'} value={seasonMode} onChange={e => setSeasonMode(e.target.value)}>
                                    {seasonModes && seasonModes.map(i =>
                                        <option key={i.value} value={i.value}>{i.text}</option>
                                    )}
                                    </FormControl>
                                    <Button variant='light' onClick={() => doSetSeasonMode()}><FontAwesomeIcon name='check' /></Button>
                                </InputGroup>
                            </FormGroup>
                        </div>
                    </div>
                </Col>
                <Col md={3} sm={6} xs={12}>
                    <div className={s.stepContainer}>
                        <div className={s.background}>2</div>
                        <div className={s.content}>
                            <FormGroup>
                                <FormLabel>Set playoff week to 1</FormLabel>
                                <InputGroup>
                                    <FormControl value={playoffWeek} style={{ textAlign: 'right' }}
                                        onChange={e => setPlayoffWeek(e.target.value)} />
                                    <Button variant='light' onClick={() => doSetPlayoffWeek()}><FontAwesomeIcon name='check' /></Button>
                                </InputGroup>
                            </FormGroup>
                        </div>
                    </div>
                </Col>
                <Col md={3} sm={6} xs={12}>
                    <div className={s.stepContainer}>
                        <div className={s.background}>3</div>
                        <div className={s.content}>
                            <FormGroup>
                                <FormLabel>Load NFL playoff games</FormLabel>
                                <Button onClick={() => navigate(`/football/admin/postseason/setup-playoffs/${seasonName}/edit-nfl-playoffs`)}><FontAwesomeIcon name='pencil-alt' /> edit NFL playoff games</Button>
                            </FormGroup>
                        </div>
                    </div>
                </Col>
                <Col md={3} sm={6} xs={12}>
                    <div className={s.stepContainer}>
                        <div className={s.background}>4</div>
                        <div className={s.content}>
                            <FormGroup>
                                <FormLabel>Load playoff teams</FormLabel>
                                <Button disabled={!playoffSetup.hasProperWeekTotals || seasonMode !== 'Postseason'}
                                    onClick={() => loadPlayoffTeams()}><FontAwesomeIcon name='check' /> load playoff teams</Button>
                            </FormGroup>
                        </div>
                    </div>
                </Col>
                <Col lg={6} md={12} sm={12} xs={12}>
                    <PlayoffRaceReport fullWidth showHeader={false} showTeamsIn showTeamsOut={false} />
                </Col>
            </Row>

            <Modal heading={'Edit ' + season.seasonName + ' NFL Playoff Games'}
                show={showNflPlayoffModal} showFooter={false}
                onClose={() => navigate(`/football/admin/postseason/setup-playoffs/${seasonName}`)}>
                <EditNflPlayoffGames seasonName={season.seasonName} isModal defaultWeek={1} />
            </Modal>
        </div>
    )
}

export default SetupLeaguePlayoffs