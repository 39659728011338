// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.SendPushNotification__modalContainer___lcNRI{margin-bottom:1rem}.SendPushNotification__modalContainer___lcNRI .SendPushNotification__formGroup___tpokG{margin-bottom:1rem}.SendPushNotification__pushPreviewContainer___BncKT{margin:1rem 0px}`, "",{"version":3,"sources":["webpack://./src/routes/Notifications/SendPushNotification.scss"],"names":[],"mappings":"AAAA,8CACI,kBAAA,CAEA,uFACI,kBAAA,CAIR,oDACI,eAAA","sourcesContent":[".modalContainer {\n    margin-bottom: 1rem;\n\n    .formGroup {\n        margin-bottom: 1rem;\n    }\n}\n\n.pushPreviewContainer {\n    margin: 1rem 0px;\n}"],"sourceRoot":""}]);
// Exports
export var modalContainer = `SendPushNotification__modalContainer___lcNRI`;
export var formGroup = `SendPushNotification__formGroup___tpokG`;
export var pushPreviewContainer = `SendPushNotification__pushPreviewContainer___BncKT`;
export default ___CSS_LOADER_EXPORT___;
