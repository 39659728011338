import React, { useEffect } from 'react'
import { Badge } from 'react-bootstrap'

const CaptureStatusLabel = ({ status = '' }) =>  {

    var statusStyle = null
    switch (status)
    {
        case 'Favorite': statusStyle = 'primary'; break;
        case 'Underdog': statusStyle = 'info'; break;
        case 'Capture': statusStyle = 'danger'; break;
    }
    return (
        status ? 
            <Badge bg={statusStyle} text={statusStyle === 'light' || statusStyle === 'warning' ? 'dark' : 'light'}>{status}</Badge>
        : null
    )
}

export default CaptureStatusLabel