import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { FormGroup, FormControl, Badge, FormLabel, Button, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './PreseasonRegistrationStep2.scss'
import { loadPreseason, divisionStandings } from '@severed-links/common.redherrings-reducers/football'

const PreseasonRegistrationStep5 = forwardRef(({ jumpToStep }, _ref) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const seasonName = params.seasonName || null
    const seasons = useSelector(state => state.football.seasons)
    const playerId = useSelector(state => state.account._id)
    const userName = useSelector(state => state.account.userName)
    const season = seasons.find(i => i.seasonName === seasonName)
    const seasonId = (season || {}).seasonId || null
    const teamRegistration = useSelector(state => state.football.teamRegistration)
    const preseason = useSelector(state => state.football.preseasons)[`${seasonId}`] || {}
    const { nflKickoff, spotsLeft, divisions, entryFee } = preseason
    const _isValid = true

    useEffect(() => { 
        if (seasonId) {
            dispatch(loadPreseason(seasonId))
            dispatch(divisionStandings(seasonId))
        }
    }, [seasonId])

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValid
    }))

    return (
        <Alert variant='success' className={s.container}>
            <FormGroup className={s.formGroup}>
                <div>Congratulations!  You are registered for the season!</div>
            </FormGroup>
        </Alert>
    )
})

export default PreseasonRegistrationStep5