import React, { useState, useEffect, useLayoutEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { img } from '@severed-links/common.redherrings-constants'
import BaseAuthenticatedMenu from './BaseAuthenticatedMenu'
import { Nav, NavDropdown } from 'react-bootstrap'
import CloudinaryImage from '../routes/Cloudinary/CloudinaryImage'
import { VisibleOnlyToFootballAdmin } from '../components/Authorization'
import { VisibleBeforeDate } from '../components/VisibilityBeforeAfterDate'
import { getFootballDetails, getSeasons, getNflTeams, getMyFavoriteTeam, 
    getMyTeams, getLeagueScoreboard, getNflScoreboard } from '@severed-links/common.redherrings-reducers/football'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import * as s from './Menu.scss'
import moment from 'moment-timezone'
import { forEach } from 'lodash'
import RedHerringsPayPalButton from '../routes/Payment/RedHerringsPayPalButton'

const FootballMenu = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const isAuthenticated = useSelector(state => state.account.isAuthenticated)
    const seasonId = useSelector(state => state.football.seasonId)
    const seasonName = useSelector(state => state.football.seasonName)
    const seasonMode = useSelector(state => state.football.seasonMode)
    const week = useSelector(state => state.football.week)
    const playoffWeek = useSelector(state => state.football.playoffWeek)
    const pickDeadline = useSelector(state => state.football.pickDeadline)
    const afterDeadline = useSelector(state => state.football.afterDeadline)
    const playoffPickDeadline = useSelector(state => state.football.playoffPickDeadline)
    const afterPlayoffDeadline = useSelector(state => state.football.afterPlayoffDeadline)
    const favoriteTeam = useSelector(state => state.football.favoriteTeam)
    const postseason = useSelector(state => state.football.postseason)
    const isHome = location.pathname.toLowerCase() === '/football'

    useLayoutEffect(() => { fetchData() }, [isAuthenticated, seasonName, seasonMode, week, playoffWeek, pickDeadline, playoffPickDeadline])

    const fetchData = () => {
        if (isAuthenticated){
            dispatch(getSeasons())
            dispatch(getNflTeams())
            dispatch(getMyFavoriteTeam())
            dispatch(getFootballDetails())
            .then(action => {
                if (action.payload.seasonName) {
                    dispatch(getMyTeams(action.payload.seasonName))
                    .then(action2 => {
                        forEach(action2.payload, t => {
                            if (!t.isPaid) {
                                console.log(`*** Unpaid team ${t.teamName}`)
                            }
                        })
                    })
                    if (action.payload.week) {
                        dispatch(getLeagueScoreboard(action.payload.seasonName, action.payload.week))
                        dispatch(getNflScoreboard(action.payload.seasonId, action.payload.week))
                    }
                }
            })
        }    
    }

    const onAuthorizedFootballPayment = () => dispatch(getMyTeams(seasonName))

    const onCancelledFootballPayment = () => {
    }

    var indexPath = '/football'
    switch (seasonMode) {
        case 'Preseason': indexPath = `/football/preseason/${seasonName}`; break;
        case 'Postseason': indexPath = `/football/postseason/${seasonName}`; break;
        case 'Regular': indexPath = `/football/regular-season/${seasonName}`; break;
    }
    const navbarBrand = (       
        <Link to={{ pathname: indexPath }}>
            <CloudinaryImage publicId={img.FootballHeader} width={200} height={50} />
        </Link>
    )

    const AdminOnlyNavItem = () => <VisibleOnlyToFootballAdmin><Nav.Item className={s.item} key={5}><Nav.Link eventKey={5} onClick={() => navigate('/football/admin')}>Admin</Nav.Link></Nav.Item></VisibleOnlyToFootballAdmin>
    
    const RegularSeasonPickMenuItem = seasonMode === "Regular" && !afterDeadline ? <VisibleBeforeDate deadline={pickDeadline}><NavDropdown.Item className={s.item} eventKey={25.21} onClick={() => navigate(`/football/regular-season/${seasonName}/enter-picks`)}>Enter picks</NavDropdown.Item></VisibleBeforeDate> : null
    const PostseasonPickMenuItem = seasonMode === "Postseason" && postseason && postseason.isPlayoffTeam && !afterPlayoffDeadline ? <VisibleBeforeDate deadline={playoffPickDeadline}><NavDropdown.Item className={s.item} eventKey={25.22} onClick={() => navigate(`/football/postseason/${seasonName}/enter-picks`)}>Enter picks</NavDropdown.Item></VisibleBeforeDate> : null

    const menuItems = [
        <NavDropdown key={25} id="rh-nfl-nav-dropdown-football-league" title={"League"} className={s.dropdown}>
            {seasonMode === 'Preseason' ? <NavDropdown.Item eventKey={25.12} onClick={() => navigate(`/football/preseason/${seasonName}`)}>Preseason</NavDropdown.Item> : null}
            {RegularSeasonPickMenuItem}
            {PostseasonPickMenuItem}
            <NavDropdown.Item eventKey={25.2} onClick={() => navigate(`/football/regular-season/league-scoreboard/${seasonName}/week/${week}`)}><FontAwesomeIcon name="list" /> Scoreboard</NavDropdown.Item>
            <NavDropdown.Item eventKey={25.3} onClick={() => navigate(`/football/league-standings/${seasonName}`)}>Division standings</NavDropdown.Item>
            {seasonMode !== 'Preseason' ? <NavDropdown.Item eventKey={25.19} onClick={() => navigate(`/football/postseason/${seasonName}`)}>Playoffs</NavDropdown.Item> : null}
            <NavDropdown.Item eventKey={25.4} onClick={() => navigate(`/football/team-profile`)}>Profiles and schedules</NavDropdown.Item>
            <NavDropdown.Item eventKey={25.5} onClick={() => navigate(`/football/faq/${seasonName}`)}>FAQs</NavDropdown.Item>
        </NavDropdown>,
        <NavDropdown key={30} id="rh-nfl-nav-dropdown-football-nfl" title={"NFL"} className={s.dropdown}>
            <NavDropdown.Item eventKey={30.1} onClick={() => navigate(`/football/nfl-scoreboard/${seasonName}/week/${week}`)}><FontAwesomeIcon name="list" /> Scoreboard</NavDropdown.Item>
            <NavDropdown.Item eventKey={30.2} onClick={() => navigate(`/football/nfl-playoffs/${seasonName}`)}>Playoffs</NavDropdown.Item>
            <NavDropdown.Item eventKey={30.3} onClick={() => navigate(`/football/nfl-standings/${seasonName}`)}>Standings</NavDropdown.Item>
            <NavDropdown.Item eventKey={30.4} onClick={() => navigate(`/football/nfl-team-analysis/${seasonName}/${favoriteTeam.mascot.toString().toLowerCase()}`)}>Team analysis</NavDropdown.Item>
            <NavDropdown.Item eventKey={30.5} onClick={() => navigate(`/football/nfl-news/`)}>News</NavDropdown.Item>
            <NavDropdown.Item eventKey={30.6} onClick={() => navigate(`/football/nfl-injury-report/${favoriteTeam.mascot.toString().toLowerCase()}`)}>Injury report</NavDropdown.Item>
            <NavDropdown.Item eventKey={30.7} onClick={() => navigate(`/football/nfl-cover-trends/${seasonName}`)}>Cover trends</NavDropdown.Item>
        </NavDropdown>,
        <NavDropdown key={40} id="rh-reports-nav-dropdown-football-reports" title={"Reports"} className={s.dropdown}>
            <NavDropdown.Item eventKey={40.1} onClick={() => navigate(`/football/reports/scoring-per-game/${seasonName}`)}>Scoring per game</NavDropdown.Item>
            <NavDropdown.Item eventKey={40.2} onClick={() => navigate(`/football/reports/win-margin-per-game/${seasonName}`)}>Win margin</NavDropdown.Item>
            <NavDropdown.Item eventKey={40.3} onClick={() => navigate(`/football/reports/hardest-schedule/${seasonName}`)}>Hardest schedule</NavDropdown.Item>
            <NavDropdown.Item eventKey={40.4} onClick={() => navigate(`/football/reports/easiest-schedule/${seasonName}`)}>Easiest schedule</NavDropdown.Item>
            <NavDropdown.Item eventKey={40.5} onClick={() => navigate(`/football/reports/toughest-division/${seasonName}`)}>Toughest division</NavDropdown.Item>
            <NavDropdown.Item eventKey={40.6} onClick={() => navigate(`/football/reports/pick-percentage/${seasonName}`)}>Pick percentage</NavDropdown.Item>
            <NavDropdown.Item eventKey={40.7} onClick={() => navigate(`/football/reports/highest-weekly-scores/${seasonName}`)}>Highest weekly scores</NavDropdown.Item>
            <NavDropdown.Item eventKey={40.41} onClick={() => navigate(`/football/reports/lowest-weekly-scores/${seasonName}`)}>Lowest weekly scores</NavDropdown.Item>
            <NavDropdown.Item eventKey={40.8} onClick={() => navigate(`/football/reports/weekly-winners/${seasonName}`)}>Weekly winners</NavDropdown.Item>
            <NavDropdown.Item eventKey={40.9} onClick={() => navigate(`/football/reports/pick-trends/${seasonName}/week/${week}`)}>Pick trends</NavDropdown.Item>
            <NavDropdown.Item eventKey={40.101} onClick={() => navigate(`/football/reports/top-score-odds/${seasonName}/week/${week}`)}>Top score odds</NavDropdown.Item>
            <NavDropdown.Item eventKey={40.11} onClick={() => navigate(`/football/reports/playoff-race/${seasonName}`)}>Playoff race</NavDropdown.Item>
            <NavDropdown.Item eventKey={40.12} onClick={() => navigate(`/football/reports/popular-profiles/${seasonName}`)}>Popular profiles</NavDropdown.Item>
            <NavDropdown.Item eventKey={40.13} onClick={() => navigate(`/football/reports/pool-geography/${seasonName}`)}>Pool geography</NavDropdown.Item>
            <NavDropdown.Item eventKey={40.14} onClick={() => navigate(`/football/reports/favorite-nfl-teams/${seasonName}`)}>Favorite NFL teams</NavDropdown.Item>
        </NavDropdown>,
        <AdminOnlyNavItem key={5} />
    ]
    return (
        <BaseAuthenticatedMenu backgroundImageUrl={img.CloudinaryBaseUrl + img.FootballHeaderBackground}
            navbarBrand={navbarBrand} 
            menuItems={menuItems} />
    )
}

export default FootballMenu
