import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormLabel, FormControl, FormGroup, Button, ListGroup } from 'react-bootstrap'
import * as adminCss from './OscarsAdmin.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { find, some } from 'lodash'
import numeral from 'numeral'
import moment from 'moment-timezone'
import Datetime from 'react-datetime'
import { saveConfiguration } from '@severed-links/common.redherrings-reducers/oscarsAdmin'
import { getOscarsDetails } from '@severed-links/common.redherrings-reducers/oscars'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import * as s from './OscarsEditConfiguration.scss'

const OscarsEditConfiguration = () => {

    const dispatch = useDispatch()
    const _year = useSelector(state => state.oscars.year)
    const _pickDeadline = useSelector(state => state.oscars.pickDeadline)
    const _timezoneId = useSelector(state => state.oscars.timezoneId)
    const _passphrase = useSelector(state => state.oscars.passphrase)
    const [year, setYear] = useState(0)
    const [pickDeadline, setPickDeadline] = useState(null)
    const [passphrase, setPassphrase] = useState('')

    useEffect(() => { setInitialStateValues() }, [_year, _pickDeadline, _passphrase])

    const setInitialStateValues = () => {
        setYear(_year)
        setPassphrase(_passphrase)
        setPickDeadline(moment.tz(_pickDeadline, _timezoneId).toDate())
    }

    const handleYearChange = e => setYear(parseInt(e.target.value))
    const handlePassphraseChange = e => setPassphrase(e.target.value)
    const handleDateChange = dt => setPickDeadline(moment.tz(dt, _timezoneId).toDate())

    const saveConfig = () => {
        var saveConfig = { year, registrationPassphrase: passphrase, entryDeadline: moment.tz(pickDeadline, _timezoneId).toISOString(true) }
        dispatch(saveConfiguration(saveConfig))
        .then(action => {
            dispatch(createNotification({ type: action.payload.messageType, headline: 'Save Configuration', message: action.payload.message, timeout: 6000 }))
            dispatch(getOscarsDetails())
        })
    }

    const _fields = [
        { label: `Year`, fieldName: `year`, value: `${year}`, type: 'text' },
        { label: `Pick deadline`, fieldName: `pickDeadline`, value: moment.tz(pickDeadline, _timezoneId).toDate(), type: 'date' },
        { label: `Registration passphrase`, fieldName: `passphrase`, value: `${passphrase}`, type: 'text' },
    ]
    return (
        <div className={s.container}>
        <ListGroup>
        {_fields && _fields.map(i =>
        <ListGroup.Item key={`oscars-config-edit-${i.fieldName}`}>
            <FormGroup className={s.formGroup}>
                <FormLabel>{i.label}</FormLabel>
                {i.type === 'text' ?
                <FormControl type={i.fieldName === 'year' ? 'number' : 'text'} 
                    name={i.fieldName} value={i.value} 
                    onChange={i.fieldName === 'year' ? handleYearChange : i.fieldName === 'passphrase' ? handlePassphraseChange : void(0)} />
                : i.type === 'date' ? 
                <Datetime value={i.value} 
                    dateFormat={'M/D/YYYY'}
                    timeFormat={'h:mm A'}
                    onChange={dt => handleDateChange(dt)} />
                : null}
            </FormGroup>
        </ListGroup.Item>
        )}
        </ListGroup>
        <FormGroup>
            <Button variant='primary' style={{ alignSelf: 'flex-end' }}
                onClick={() => saveConfig()}>
                <FontAwesomeIcon name='check' />
                {' '}
                save
            </Button>
        </FormGroup>
        </div>
    )
}

export default OscarsEditConfiguration