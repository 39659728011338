import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Row, Col, ListGroup, Card, FormControl, 
    Button, Badge, Alert, ButtonToolbar, ButtonGroup } from 'react-bootstrap'
import * as s from './UpdateNflRegularSeasonLines.scss'
import { getNflRegularSeasonGamesForWeek, saveNflRegularSeasonLinesForWeek } from '@severed-links/common.redherrings-reducers/footballAdmin'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import FormCheck from 'react-bootstrap/FormCheck'
import { find, orderBy, findIndex, startsWith, forEach, 
    filter, first, isEqual, padStart } from 'lodash'
import NflTeam from '../NflTeam'
import NflTeamListItem from '../NflTeamListItem'
import moment from 'moment-timezone'
import Datetime from 'react-datetime'
import { FRIENDLY_DATE_FORMAT, FRIENDLY_SHORT_DATE_FORMAT, FRIENDLY_LONG_DATE_FORMAT } from '@severed-links/common.redherrings-constants'
import NflGameRescheduler from './NflGameRescheduler'
import Modal from '@severed-links/common.modal'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'

const FavoriteBadge = ({ isProposed }) => (
    <Badge className={s.favoriteBadge + (isProposed ? ' ' + s.isProposed : '')}><FontAwesomeIcon name='check' /></Badge>
)

const UpdateNflRegularSeasonLines = ({ seasonName, seasonMode, week }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const seasons = useSelector(state => state.football.seasons || [])
    const season = seasons.find(i => i.seasonName === seasonName) || {}
    const seasonId = season.seasonId || null
    const isPlayoffWeek = seasonMode === 'Postseason'
    const tz = useSelector(state => state.account.time_zone)
    const reloadScores = useSelector(state => state.footballAdmin.reloadScores)
    const [games, setGames] = useState([])
    const [showRescheduler, setShowRescheduler] = useState(false)
    const [gameToReschedule, setGameToReschedule] = useState(null)
    const _updateMap = [
        { name: 'HomeFavorite', proposed: 'proposedHomeFavorite', base: 'homeFavorite' },
        { name: 'Line', proposed: 'proposedLine', base: 'line' },
        { name: 'OU', proposed: 'proposedOU', base: 'ou' },
    ]

    useEffect(() => { loadGames() }, [seasonId, seasonMode, week])
    useEffect(() => { reloadScores ? loadGames() : null }, [reloadScores])

    const loadGames = () => {
        dispatch(getNflRegularSeasonGamesForWeek(seasonId, week))
        .then(action => {
            setGames(orderBy((action.payload || []).map(g => ({ 
                ...g, 
                date: moment(g.date).local().format(FRIENDLY_DATE_FORMAT),
                sortOrder: `${moment(g.date).format('YYYYMMDDHHmmss')}-${padStart(g.game.toString(), 2, '0')}`
            })), ['sortOrder','date','game'], ['asc','asc','asc']))
        })
    }

    const setHomeFavorite = (_gameId, homeFavorite) => {
        let _games = [...games]
        const _index = _games.findIndex(g => g._id === _gameId)
        _games[_index].homeFavorite = homeFavorite
        setGames(_games)
    }

    const handleBootstrapSwitch = (e, checkedState, _index) => {
        let _games = [...games]
        _games[_index].homeFavorite = checkedState
        setGames(_games)
    }

    const handleChange = (e, _index) => {
        let _games = [...games]
        _games[_index][e.target.name] = e.target.value
        setGames(_games)
    }

    const handleDateChange = (dt, _gameId) => {
        let _games = [...games]
        let _index = _games.findIndex(g => g._id === _gameId)
        _games[_index] = {
            ..._games[_index],
            date: moment(dt, FRIENDLY_DATE_FORMAT).format(FRIENDLY_DATE_FORMAT),
            sortOrder: `${moment(dt).format('YYYYMMDDHHmmss')}-${padStart(_games[_index].game.toString(), 2, '0')}`,
        }
        setGames(_games)
    }

    const handleDateChangeDone = () => {
        let _games = [...games]
        setGames(orderBy(_games, ['sortOrder','date','game'], ['asc','asc','asc']))
    }

    const acceptAllOfType = fieldType => {
        const map = find(_updateMap, { name: fieldType })
        if (map && map.name) {
            let _games = [...games]
            var filteredGames = filter(_games, x => x[map.proposed] !== null)
            if (map.name === 'Line' || map.name === 'OU') {
                filteredGames = filter(filteredGames, x => parseFloat(x[map.proposed]) >= 0.0)
            }
            forEach(filteredGames, g => { 
                const _index = findIndex(games, i => i.game === g.game)
                if (_index !== -1) {
                    _games[_index][map.base] = _games[_index][map.proposed] 
                }
            })
            setGames(_games)
        }
    }

    const acceptProposed = (_index, fieldName, value) => {
        let _games = [...games]
        _games[_index][fieldName] = value
        setGames(_games)
    }

    const areDatesEqual = (dt1, dt2) => moment(dt1, FRIENDLY_DATE_FORMAT).isSame(moment(dt2, FRIENDLY_DATE_FORMAT), 'day')

    const save = () => {
        const postData = games.map(g => { return { 
                _id: g._id, game: g.game,
                gameDate: moment(g.date, FRIENDLY_DATE_FORMAT).toISOString(),
                visitorId: g.visitor.teamId, 
                homeId: g.home.teamId, 
                line: g.line, 
                ou: g.ou, 
                homeFavorite: g.homeFavorite 
            }
        })
        dispatch(saveNflRegularSeasonLinesForWeek(seasonId, week, postData))
        .then(action => {
            dispatch(createNotification({ ...action.payload, headline: 'Save Lines for ' + seasonName + ' Week ' + week, timeout: 6000}))
            setGames(orderBy(games, ['sortOrder'], ['asc']))
        })
    }

    const onShowRescheduler = (_game = {}) => {
        setGameToReschedule(_game)
        setShowRescheduler(true)
    }

    const onCloseRescheduler = () => setShowRescheduler(false)

    if (!seasonId || !week || !games || games.length === 0) return null
    const isAfterKickoff = moment().isSameOrAfter(moment(first(games).date, FRIENDLY_DATE_FORMAT), 'hour')
    return (
        <div className={s.container}>
            <div className={s.heading}>
                <ButtonToolbar className={s.topButtonToolbar}>
                    <ButtonGroup className={s.topButtonGroup}>
                        {filter(games, g => `${g.homeFavorite}` !== `${g.proposedHomeFavorite}`).length > 0 ?
                        <Button variant='warning'
                            onClick={() => acceptAllOfType('HomeFavorite')}>
                            <FontAwesomeIcon name='clone' /> 
                            {' '}
                            Favorite
                        </Button>
                        : null}
                        {filter(games, g => `${g.line}` !== `${g.proposedLine}`).length > 0 ?
                        <Button variant='warning'
                            onClick={() => acceptAllOfType('Line')}>
                            <FontAwesomeIcon name='clone' /> 
                            {' '}
                            Line
                        </Button>
                        : null}
                        {filter(games, g => `${g.ou}` !== `${g.proposedOU}`).length > 0 ?
                        <Button variant='warning'
                            onClick={() => acceptAllOfType('OU')}>
                            <FontAwesomeIcon name='clone' /> 
                            {' '}
                            OU
                        </Button>
                        : null}
                        </ButtonGroup>
                        <ButtonGroup className={s.topButtonGroup}>
                        <Button variant='primary'
                            onClick={() => save()}>
                            <FontAwesomeIcon name='check' /> 
                            {' '}
                            save
                        </Button>
                    </ButtonGroup>
                </ButtonToolbar>

                {isAfterKickoff ?
                    <Alert className={s.alert} variant='danger'>
                        <div>After kickoff - do you mean to update next week?</div>
                        <Button variant='danger' style={{ float: 'right' }} size='sm' 
                            onClick={() => navigate(location.pathname.replace(`/week/${week}`, `/week/${week + 1}`))}>next week <FontAwesomeIcon name='chevron-right' /></Button>
                        </Alert>
                : null}
            </div>
            <Row className={s.gamesContainer}>
            {games && games.map((g, index) => [
                index === 0 || !areDatesEqual(g.date, games[index - 1].date) ? 
                <Col xs={12} key={`game-date-card-header-item-${g._id}`}>
                    <h4>{moment(g.date, FRIENDLY_SHORT_DATE_FORMAT).format(FRIENDLY_LONG_DATE_FORMAT)}</h4>
                </Col>
                : null,
                <Col md={12} lg={6} key={`game-date-card-item-${g._id}`}>
                    <Card className={s.gameCard}>
                        <Card.Header className={s.cardHeader}>
                            <div className={s.gameTimeOverallContainer}>
                                <div><FontAwesomeIcon name='calendar' /></div>
                                <Datetime value={g.date} dateFormat='M/D/YYYY' timeFormat='h:mm A'
                                    className={s.datepickerContainer}
                                    inputProps={{ className: s.datepickerInput }}
                                    onChange={dt => handleDateChange(dt, g._id)}
                                    onClose={handleDateChangeDone}
                                    initialViewMode='time' />
                                <div className={s.rescheduleContainer}><Button variant='light' size='sm' className={s.rescheduleButton} onClick={() => onShowRescheduler(g)}><FontAwesomeIcon name='chevron-right' /></Button></div>
                            </div>
                        </Card.Header>
                        <ListGroup variant='flush'>
                        <NflTeamListItem {...g.visitor} tabIndex={-1}
                            onClick={() => setHomeFavorite(g._id, false)}
                            right={<div className={s.teamControlsContainer}>
                                {!g.homeFavorite ? <FavoriteBadge isProposed={`${g.homeFavorite}` === `${g.proposedHomeFavorite}`} /> : null}
                                {g.isStarted ? <div>{g.visitorScore}</div> : null}
                                <div className={s.controlContainer + (`${g.line}` === `${g.proposedLine}` ? ` ${s.isProposed}` : ``)}
                                    onClick={e => e.stopPropagation()}>
                                    <div className={s.groupLabel}>
                                        {g.line !== g.proposedLine ? 
                                        <Badge bg='warning' text='dark' className={s.proposedLabel} onClick={() => acceptProposed(index, 'line', g.proposedLine)}>{g.proposedLine}</Badge>
                                        :
                                        <span>Line</span>}
                                    </div>
                                    <FormControl name={'line'} value={g.line || ''} className={s.updateLinesTextBox}
                                        onChange={e => handleChange(e, index)} />
                                </div>
                            </div>} />
                        <NflTeamListItem {...g.home} tabIndex={-1}
                            onClick={() => setHomeFavorite(g._id, true)}
                            right={<div className={s.teamControlsContainer}>
                                {g.homeFavorite ? <FavoriteBadge isProposed={`${g.homeFavorite}` === `${g.proposedHomeFavorite}`} /> : null}
                                {g.isStarted ? <div>{g.homeScore}</div> : null}
                                <div className={s.controlContainer + (`${g.ou}` === `${g.proposedOU}` ? ` ${s.isProposed}` : ``)}
                                    onClick={e => e.stopPropagation()}>
                                <div className={s.groupLabel}>
                                    {g.ou !== g.proposedOU ?
                                    <Badge bg='warning' text='dark' className={s.proposedLabel} onClick={() => acceptProposed(index, 'ou', g.proposedOU)}>{g.proposedOU}</Badge>
                                    :
                                    <span>O/U</span>}
                                </div>
                                    <FormControl name={'ou'} value={g.ou || ''} className={s.updateLinesTextBox}
                                        onChange={e => handleChange(e, index)} />                                    
                                </div>
                            </div>} />
                        </ListGroup>
                        <Card.Footer className={s.cardFooter}>
                            <div className={s.headlineContainer}>
                                {g.headline ? `${g.scoreType || 'Headline'}: ${g.headline}` : ''}
                            </div>
                            <div>{g.proposedLineUpdate && moment(g.proposedLineUpdate).isValid() ? moment(g.proposedLineUpdate).format('MMM D YYYY h:mm A') : 'No update'}</div>
                        </Card.Footer>
                    </Card>
                </Col>
            ])}
            </Row>
            {seasonId && week && gameToReschedule ?
            <Modal heading={'NFL Game Rescheduler'}
                onClose={() => onCloseRescheduler()}
                show={showRescheduler}>
                <NflGameRescheduler seasonId={seasonId} seasonName={seasonName} 
                    week={week} game={gameToReschedule} />
            </Modal>
            : null}
        </div>
    )
}

export default UpdateNflRegularSeasonLines
