import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Row, Col, ListGroup } from 'react-bootstrap'
import * as s from './EliminatorEnterPick.scss'
import { getEnterPicks, savePick } from '@severed-links/common.redherrings-reducers/eliminator'
import EliminatorEnterPickItem from './EliminatorEnterPickItem'
import EliminatorEnterPickGame from './EliminatorEnterPickGame'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import moment from 'moment-timezone'
import { update } from 'lodash'

const EliminatorEnterPick = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const seasonId = useSelector(state => state.eliminator.seasonId)
    const seasonName = useSelector(state => state.eliminator.seasonName)
    const seasonMode = useSelector(state => state.eliminator.seasonMode)
    const week = useSelector(state => state.eliminator.week)
    const playoffWeek = useSelector(state => state.eliminator.playoffWeek)
    const pickDeadline = useSelector(state => state.eliminator.pickDeadline)
    const afterDeadline = moment(pickDeadline).isValid() ? moment().isAfter(moment(pickDeadline), 'second') : true
    const playoffPickDeadline = useSelector(state => state.eliminator.playoffPickDeadline)
    const afterPlayoffDeadline = moment(playoffPickDeadline).isValid() ? moment().isAfter(moment(playoffPickDeadline), 'second') : true
    const enterPicks = useSelector(state => state.eliminator.enterPicks)
    const isSavingPick = useSelector(state => state.eliminator.isSavingPick)
    const [currentTime, setCurrentTime] = useState(null)
    const [message, setMessage] = useState(null)
    const [firstLoad, setFirstLoad] = useState(false)
    const [updatedPickRefs, setUpdatedPickRefs] = useState(null)
    var _pickRefs = {}

    useEffect(() => {
        checkDeadline()
        let timerId = setInterval(() => setCurrentTime(moment().toISOString()), 1000)
        return () => clearInterval(timerId)
    }, [])

    useEffect(() => { checkDeadline() }, [currentTime])

    useEffect(() => { updatePickData() }, [seasonMode, seasonId, week, playoffWeek])

    useEffect(() => {
        if (enterPicks && enterPicks.message) {
            dispatch(createNotification({ type: enterPicks.messageType, headline: 'Eliminator Pick', message: enterPicks.message, timeout: 6000 }))
            updatePickData()
        }
    }, [enterPicks.message])

    useEffect(() => {
        if (firstLoad) {
            const currentPick = (enterPicks.picks || []).find(p => p.isCurrentPick)
            if (currentPick) {
                const _currentPickRefKey = generatePickRef(currentPick.seasonMode, currentPick.week)
                if (_pickRefs[_currentPickRefKey] && currentPick.week >= 5) {
                    _pickRefs[_currentPickRefKey].current?.scrollIntoView()
                }
            }
        }

    }, [firstLoad])

    const checkDeadline = () => {
        if ((seasonMode === 'Regular' && moment(pickDeadline).isValid() && afterDeadline) || (seasonMode === 'Postseason' && moment(playoffPickDeadline).isValid() && afterPlayoffDeadline)) {
            setMessage('Deadline passed. Rerouting.')
            console.log('Deadline passed. Rerouting.')
            navigate('/eliminator')
        }
    }

    const updatePickData = () => {
        dispatch(getEnterPicks())
        .then(action => {
            var _updatedPickRefs = false
            const picks = ((action.payload || {}).picks || []).forEach(_pick => {
                const _pickRefKey = generatePickRef(_pick.seasonMode, _pick.week)
                if (!_pickRefs[_pickRefKey]) {
                    _pickRefs[_pickRefKey] = React.createRef()
                    _updatedPickRefs = true
                }
            })
            const _stateUpdate = { firstLoad: true, ...(_updatedPickRefs ? { updatedPickRefs: moment().toISOString(true) } : {}) }
            setFirstLoad(true)
            if (_updatedPickRefs) {
                setUpdatedPickRefs(true)
            }
        })
    }

    const generatePickRef = (seasonMode = 'Regular', week = 1) => `${seasonMode}-${week}`

    if (!enterPicks || !enterPicks.picks) return null
    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <h2>Enter Your Pick</h2>
                </Col>
            </Row>
            <Row>
                <Col lg={4} xs={12}>
                    <h4>Your Picks</h4>
                    {enterPicks ?
                    <ListGroup className={s.pickList}>
                    {enterPicks.picks && enterPicks.picks.map((p, index) =>
                        isSavingPick && p.isCurrentPick ?
                        <ListGroup.Item className={s.isSavingItem} key={`eliminator-pick-is-loading-item-${index}`}>
                            <div className={s.week}>{p.week}</div>
                            <div className={s.spinnerContainer}><FontAwesomeIcon name='circle-notch' spin size='2x' /></div>                            
                        </ListGroup.Item>
                        :
                        <div ref={_pickRefs[generatePickRef(p.seasonMode, p.week)]}
                            key={`eliminator-pick-item-${index}`}>
                            <EliminatorEnterPickItem pick={p} index={index} 
                                isCurrentPick={p.isCurrentPick || false} />
                        </div>
                    )}
                    </ListGroup>
                    : null}
                </Col>
                <Col lg={8} xs={12}>
                    <h4>This Week's Games</h4>
                    {enterPicks && enterPicks.games && enterPicks.games.map((g, index) => [
                        index === 0 || !moment(g.date).isSame(moment(enterPicks.games[index - 1].date), 'day') ? 
                        <div key={`eliminator-pick-row-${index}`} className={s.gameDate}>{moment(g.date).format('dddd, MMMM D, YYYY')}</div>
                        : null,
                        <EliminatorEnterPickGame game={g} index={index} 
                            key={`eliminator-game-item-${index}`} 
                            savePick={_teamId => dispatch(savePick(_teamId))} />,
                    ])}
                </Col>
            </Row>
        </div>
    )
}

export default EliminatorEnterPick