import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Row, Col, Button, Nav } from 'react-bootstrap'
import { getBrackets, getBracketRegions } from '@severed-links/common.redherrings-reducers/cinderella'
import YearSelector from '../../Oscars/YearSelector'
import * as s from './Brackets.scss'
import { filter, uniqBy, findIndex, find, first } from 'lodash'
import NCAATeam from '../NCAATeam/NCAATeam'
import BracketRounds14 from './BracketRounds14'
import BracketFinal4 from './BracketFinal4'

const Brackets = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const brackets = useSelector(state => state.cinderella.brackets)
    const year = parseInt(params.year || '0')
    const regionName = (params.region || '').toLowerCase().replace('-', ' ')
    const oBracket = find(brackets, b => b.year === year && b.regionName.toLowerCase() === regionName) || {}
    const { regions, bracket, rounds, final4 } = oBracket
    const selectedRegion = find(regions || [], r => r.regionName.toLowerCase() === regionName)

    useEffect(() => {
        if (year) {
            dispatch(getBracketRegions(year))
            .then(action => {
                const _regions = action.payload || []
                if (_regions.length) {
                    const newRegion = first(_regions).regionName || ''
                    if (newRegion) {
                        navigate(`/cinderella/brackets/${year}/${newRegion.toLowerCase().replace(' ', '-')}`)
                    }
                }
            })    
        }
    }, [year])

    useEffect(() => {
        loadBracket()
    }, [year, regionName])

    const loadBracket = () => {
        if (year && regionName) {
            dispatch(getBrackets(year, regionName))
        }
    }

    const handleRegionChange = e => navigate(`/cinderella/brackets/${year}/${e.toLowerCase().replace(' ', '-')}`)

    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <h2>
                        <span className={s.yearSelector}>
                            <YearSelector defaultYear={year}
                                buttonTitle={null} buttonIcon={'calendar'} />
                        </span>
                        {year} Brackets
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <Nav variant='pills' activeKey={(selectedRegion || {}).regionName} onSelect={e => handleRegionChange(e)} onClick={e => e.stopPropagation()}>
                    {regions && regions.map(r =>
                        <Nav.Item key={`year-${year}-region-pill-${r._id}`}><Nav.Link eventKey={r.regionName}>{r.regionName}</Nav.Link></Nav.Item>
                    )}
                    </Nav>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                {(selectedRegion || {}).region <= 4 ? <BracketRounds14 bracket={bracket} year={year} region={selectedRegion.region || {}} rounds={rounds || []} /> : null}
                {(selectedRegion || {}).region > 4 ? <BracketFinal4 bracket={final4 || {}} /> : null}
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                </Col>
            </Row>
        </div>
    )
}

export default Brackets