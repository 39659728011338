import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, Button, FormControl, InputGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './PaymentTester.scss'
import PlayerListItem from '../Player/PlayerListItem'
import Modal from '@severed-links/common.modal'
import RedHerringsPayPalButton from './RedHerringsPayPalButton'
import { sample, range, padStart, first } from 'lodash'
import { getSeasons, getFootballDetails } from '@severed-links/common.redherrings-reducers/football'
import { getEliminatorDetails } from '@severed-links/common.redherrings-reducers/eliminator'
import { getDetails as getCinderellaDetails } from '@severed-links/common.redherrings-reducers/cinderella'
import { prettyPrintJson } from 'pretty-print-json'

const PAYMENT_APPS = ['Cinderella', 'Football', 'Eliminator']

const PaymentTester = ({ defaultApp = null, businessEmail = 'gtw@mcgarrybair.com' }) => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const firstName = useSelector(state => state.account.firstName)
    const lastName = useSelector(state => state.account.lastName)
    const avatarUrl = useSelector(state => state.account.avatarUrl)
    const cinderellaYear = useSelector(state => state.cinderella.year)
    const footballSeasonName = useSelector(state => state.football.seasonName)
    const footballSeasonId = useSelector(state => state.football.seasonId)
    const eliminatorSeasonName = useSelector(state => state.eliminator.seasonName)
    const eliminatorSeasonId = useSelector(state => state.eliminator.seasonId)
    const [show, setShow] = useState(false)
    const [showAppSelector, setShowAppSelector] = useState(false)
    const [app, setApp] = useState(null)
    const [amount, setAmount] = useState('0.00')
    const [response, setResponse] = useState(null)
    const _player = { _id: playerId, playerId, firstName, lastName, avatarUrl }

    useEffect(() => {
        if (show) {
            dispatch(getFootballDetails())
            dispatch(getEliminatorDetails())
            dispatch(getCinderellaDetails())
            setApp(PAYMENT_APPS.some(i => defaultApp && i === defaultApp) ? defaultApp : first(PAYMENT_APPS))
            setAmount(`0.${padStart(`${sample(range(1, 51))}`, 2, '0')}`)
            setShowAppSelector(false)
            setResponse(null)
        }
    }, [show])

    useEffect(() => { setResponse(null) }, [app])

    const onAuthorizedPayment = (_response = {}) => setResponse({ ..._response, error: false })
    const onCancelledPayment = (_response = {}) => setResponse({ ..._response, error: true })

    const _amountIsValid = amount && !isNaN(amount)
    const _playerIsValid = !!playerId
    const _description = app === 'Cinderella' ? `Cinderella ${cinderellaYear}` : app === 'Football' ? `RedHerrings Football ${footballSeasonName}` : app === 'Eliminator' ? `RedHerrings Eliminator ${eliminatorSeasonName}` : 'None'
    if (show) {
        console.log({ cinderellaYear, footballSeasonName, eliminatorSeasonName, _description })
    }
    const _responseHtml = prettyPrintJson.toHtml(response)
    return (
        <div className={s.container}>
            <Button variant='light' onClick={() => setShow(true)}>
                <FontAwesomeIcon name='money-bill-alt' />
            </Button>
            <Modal show={show} onClose={() => setShow(false)}
                heading={`Payment Tester`} showFooter={false}>
            <div className={s.modalContainer}>
            <ListGroup className={s.list}>
                <PlayerListItem player={_player} />
                {!showAppSelector ?
                <ListGroup.Item className={s.item}>
                    <div className={s.description}>Application</div>
                    <div>
                        <Button onClick={() => setShowAppSelector(true)}>{app}</Button>
                    </div>
                </ListGroup.Item>
                :
                <>
                {PAYMENT_APPS && PAYMENT_APPS.map(i =>
                <ListGroup.Item className={s.item + (i === app ? ` ${s.isSelected}` : '')} 
                    action onClick={() => { setApp(i); setShowAppSelector(false) }}
                    key={`payment-app-${i}`}>
                        <FontAwesomeIcon name={app === i ? 'check-circle' : 'circle'} className={s.icon} />
                        <div className={s.description}>{i}</div>
                        <FontAwesomeIcon name='chevron-right' className={s.chevron} />
                    </ListGroup.Item>
                )}                    
                </>}
                <ListGroup.Item className={s.item}>
                    <div className={s.description}>Payment Description</div>
                    <div>{_description}</div>
                </ListGroup.Item>
                <ListGroup.Item className={s.item}>
                    <div className={s.description}>Amount</div>
                    <InputGroup className={s.inputGroup}>
                        <InputGroup.Text>$</InputGroup.Text>
                        <FormControl className={s.input} value={amount} onChange={e => setAmount(e.target.value)} />
                        <InputGroup.Text><FontAwesomeIcon name={_amountIsValid ? 'check' : 'times'} className={s.icon + ' ' + s.right + ' ' + (_amountIsValid ? s.isValid : s.isInvalid)} /></InputGroup.Text>
                    </InputGroup>
                </ListGroup.Item>
                {!response && _amountIsValid && _playerIsValid ?
                <ListGroup.Item className={s.item + ' ' + s.paymentButtonContainer}>
                    <RedHerringsPayPalButton
                        contest={app}
                        itemNumber={playerId}
                        receiverEmail={businessEmail}
                        description={_description}
                        amount={_amountIsValid ? parseFloat(amount) : 0.00}
                        year={app === 'Cinderella' ? cinderellaYear : null}
                        seasonId={app === 'Eliminator' ? eliminatorSeasonId : app === 'Football' ? footballSeasonId : null}
                        playerId={playerId}
                        onAuthorizedPayment={onAuthorizedPayment}
                        onCancelledPayment={onCancelledPayment} />
                </ListGroup.Item>
                : response && _responseHtml ?
                <ListGroup.Item className={s.item}>
                    <pre dangerouslySetInnerHTML={{ __html: _responseHtml }} />
                </ListGroup.Item>
                : null}
            </ListGroup>
            </div>
            </Modal>
        </div>
    )
}

export default PaymentTester
