// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.DeadlineAlertContainer__container___zihPc{padding:4px 8px 4px 4px}.DeadlineAlertContainer__container___zihPc .DeadlineAlertContainer__content___SifoG{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center}.DeadlineAlertContainer__container___zihPc .DeadlineAlertContainer__content___SifoG .DeadlineAlertContainer__avatar___h2zyW{flex:0 0 50px;margin-right:10px}.DeadlineAlertContainer__container___zihPc .DeadlineAlertContainer__content___SifoG .DeadlineAlertContainer__avatar___h2zyW img{width:50px;height:50px;border-radius:8px}.DeadlineAlertContainer__container___zihPc .DeadlineAlertContainer__content___SifoG .DeadlineAlertContainer__teamName___jBe3g{flex:1 1 auto;white-space:normal}.DeadlineAlertContainer__container___zihPc .DeadlineAlertContainer__content___SifoG .DeadlineAlertContainer__control___hAxYA{flex:0 0 0;margin-left:10px;white-space:nowrap}`, "",{"version":3,"sources":["webpack://./src/routes/Football/DeadlineAlertContainer.scss"],"names":[],"mappings":"AAEA,2CACI,uBAAA,CACA,oFACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CAEA,4HACI,aAAA,CACA,iBAAA,CACA,gIACI,UAAA,CACA,WAAA,CACA,iBAAA,CAIR,8HACI,aAAA,CACA,kBAAA,CAGJ,6HACI,UAAA,CACA,gBAAA,CACA,kBAAA","sourcesContent":["@use '../../styles/variables/colors' as colors;\n\n.container {\n    padding: 4px 8px 4px 4px;\n    .content {\n        display: flex; \n        flex-flow: row nowrap;\n        justify-content: space-between;\n        align-items: center;\n\n        .avatar {\n            flex: 0 0 50px;\n            margin-right: 10px;\n            img {\n                width: 50px;\n                height: 50px;\n                border-radius: 8px;\n            }\n        }\n\n        .teamName {\n            flex: 1 1 auto;\n            white-space: normal;\n        }\n\n        .control {\n            flex: 0 0 0;\n            margin-left: 10px;\n            white-space: nowrap;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `DeadlineAlertContainer__container___zihPc`;
export var content = `DeadlineAlertContainer__content___SifoG`;
export var avatar = `DeadlineAlertContainer__avatar___h2zyW`;
export var teamName = `DeadlineAlertContainer__teamName___jBe3g`;
export var control = `DeadlineAlertContainer__control___hAxYA`;
export default ___CSS_LOADER_EXPORT___;
