import React, { useEffect, useState, useRef, forwardRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, Image, Button, Popover, FormGroup, 
    InputGroup, OverlayTrigger, Overlay } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './CinderellaRecapImages.scss'
import CloudinaryUploader from '../../Cloudinary/CloudinaryUploader'
import { img } from '@severed-links/common.redherrings-constants'
import { getRecapImages, deleteRecapImage, addNewRecapImage } from '@severed-links/common.redherrings-reducers/cinderellaAdmin'
import Confirm from '@severed-links/common.confirm'
import { filesize } from 'filesize'
import { CopyToClipboard } from 'react-copy-to-clipboard'

const ImageThumbnail = ({ thumbnail_url, _ref = null, onClick }) => (
    <div ref={_ref} className={s.thumbnailContainer} onClick={onClick ? () => onClick() : null}>
        <Image src={thumbnail_url} className={s.thumbnail} rounded />
    </div>
)

const ImagePopover = forwardRef(({ popper, children, recapId, ...props }, _ref) => {

    useEffect(() => { popper.scheduleUpdate() }, [recapId])

    return (
        <Popover ref={_ref} {...props} id={`recap-images-popover`}>
        {children}
        </Popover>
    )
})
  

const CinderellaRecapImages = ({ recapId = null }) => {

    const dispatch = useDispatch()
    const { _id: playerId } = useSelector(state => state.account)
    const images = useSelector(state => state.cinderellaAdmin.recapImages[`${recapId}`]) || []
    const [_selectedThumbnail, setSelectedThumbnail] = useState(null)
    const _currentThumb = useRef(null)

    useEffect(() => { 
        if (recapId) { 
            dispatch(getRecapImages(recapId))
        }
    }, [recapId])

    const onUploadNewImage = _results => {
        dispatch(addNewRecapImage({ ..._results, recapId }))
        .then(() => dispatch(getRecapImages(recapId)))
    }

    const onDeleteRecapImage = _recapImageId => {
        setSelectedThumbnail(null)
        dispatch(deleteRecapImage(_recapImageId))
        .then(() => dispatch(getRecapImages(recapId)))
    }

    const onCopyButtonClick = () => setSelectedThumbnail(null)

    const t = _selectedThumbnail || {}
    const _showPopover = !!t._id
    if (!recapId) return null
    return (
        <div className={s.container}>
            <FormGroup className={s.formGroup}>
                <InputGroup className={s.inputGroup}>
                    <InputGroup.Text className={s.imageIcon}><FontAwesomeIcon name='image' size='3x' /></InputGroup.Text>
                    <div className={s.imagesContainer + ' form-control'} ref={!_selectedThumbnail ? _currentThumb : null}>
                    {!images || !images.length ? <div className={s.imagePlaceholder}>[Upload images here...]</div> : null}
                    {images && images.map(i =>
                    <ImageThumbnail {...i} 
                        onClick={() => setSelectedThumbnail(!t._id || i._id !== t._id ? i : null)}
                        onDeleteRecapImage={onDeleteRecapImage}
                        key={`recap-image-thumb-${i._id}`} />
                    )}
                    </div>
                    <div className={s.uploaderContainer}>
                        <CloudinaryUploader originalId={null}
                            showImage={false}
                            showButtonIsBlock={false}
                            showButton={true}
                            showButtonSize={'sm'}
                            buttonClassName={s.uploaderButton}
                            folder={img.CinderellaRecapImageFolder}
                            onUploadSuccess={onUploadNewImage} />
                    </div>
                </InputGroup>
            </FormGroup>
            <Overlay show={_showPopover} target={_currentThumb.current} placement='top' trigger='click'>
                {props => (
                    <ImagePopover className={s.recapImagePopover} {...props}
                        recapId={t._id || null}>
                        <Popover.Header className={s.header}>
                            <div className={s.title}>{t.original_filename || ''}</div>
                            <div className={s.closeButtonContainer}>
                                <Button className={s.closeButton} size='sm' onClick={() => setSelectedThumbnail(null)}><FontAwesomeIcon name='times' /></Button>
                            </div>
                        </Popover.Header>
                        <Popover.Body className={s.body}>
                            <div className={s.imageContainer} style={{ backgroundImage: t.secure_url ? `url(${t.secure_url})` : null }} />
                            <ListGroup className={s.imageInfoList} variant='flush'>
                                <ListGroup.Item className={s.item}>
                                    <div>Dimensions</div>
                                    <div>{t.width || 0}x{t.height || 0}</div>
                                </ListGroup.Item>
                                <ListGroup.Item className={s.item}>
                                    <div>File size</div>
                                    <div>{filesize(t.bytes || 0)}</div>
                                </ListGroup.Item>
                                <ListGroup.Item className={s.item}>
                                    <div className={s.deleteButtonContainer}>
                                        <Confirm variant={'danger'} 
                                            className={s.confirmDeleteRecapImageModal}
                                            dialogClassName={s.confirmDeleteRecapImageDiaglogModal}
                                            onConfirm={e => onDeleteRecapImage(t._id)}
                                            title={`Delete Recap Image`}
                                            confirmText='delete image'
                                            body={<div className={s.deleteRecapImage}>
                                                <p>Are you sure you want to delete this recap image?</p>
                                                <p><small>If you fail to remove a reference to it in the recap body above, users will see an error in their browser.</small></p>
                                                <p className={s.imgPara}><Image src={t.secure_url} /></p>
                                            </div>}
                                            buttonIcon='trash' buttonClassName={s.deleteButton} />
                                    </div>
                                    <div className={s.copyButtonContainer}>
                                        <CopyToClipboard text={t.secure_url} onCopy={() => onCopyButtonClick()}>
                                            <Button className={s.copyRecapImageUrl}><FontAwesomeIcon name='copy' /></Button>
                                        </CopyToClipboard>
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                        </Popover.Body>
                    </ImagePopover>
                )}
            </Overlay>
        </div>
    )
}

export default CinderellaRecapImages
