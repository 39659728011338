import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Button, ListGroup, Image } from 'react-bootstrap'
import * as s from './FootballPayments.scss'
import { getPayments, deletePaymentLogItem } from '@severed-links/common.redherrings-reducers/footballAdmin'
import { find, isEqual, sumBy, reject } from 'lodash'
import PlayerListItem from '../../Player/PlayerListItem'
import Modal from '@severed-links/common.modal'
import moment from 'moment-timezone'
import numeral from 'numeral'
import pluralize from 'pluralize'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import SeasonSelector from '../../Football/SeasonSelector'
import RedHerringsPayPalButton from '../../Payment/RedHerringsPayPalButton'
import Confirm from '@severed-links/common.confirm'
import { CURRENCY_FORMAT, playerUrl } from '@severed-links/common.redherrings-constants'
import FootballPaymentBalances from './FootballPaymentBalances'

const FootballPayments = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const seasons = useSelector(state => state.football.seasons)
    const seasonName = params.seasonName || null
    const season = seasons.find(i => i.seasonName === seasonName)
    const seasonId = (season || {}).seasonId || null
    const payments = useSelector(state => (state.footballAdmin.paymentLogs || []).find(i => i.seasonName === seasonName) || { items: [] })
    const userName = useSelector(state => state.account.userName)
    const paymentEmail = useSelector(state => state.football.paymentEmail)
    const playerId = useSelector(state => state.account._id || null)
    const [isLoading, setLoading] = useState(false)
    const [txn, setTxn] = useState({})
    const [showDetail, setShowDetail] = useState(false)
    const [testEntryFee, setTestEntryFee] = useState(0.00)

    useEffect(() => { getPaymentList() }, [seasonId])

    const getPaymentList = () => {
        if (seasonId) {
            const { playerFilter } = payments
            setLoading(true)
            dispatch(getPayments(seasonId, playerFilter || '[All]'))
            .then(() => {
                setLoading(false)
                setTestEntryFee(randomDollarAmountForTesting())
            })
        }
    }

    const onAuthorizedTestPayment = () => getPaymentList()

    const onCancelledTestPayment = () => getPaymentList()

    const deleteLogItem = _id => {
        setShowDetail(false)
        dispatch(deletePaymentLogItem(_id))
        .then(() => getPaymentList())
    }

    const randomDollarAmountForTesting = () => {
        const min = 10
        const max = 49
        return parseFloat(Math.floor(Math.random() * (max - min + 1) + min))/100.00;
    }
      
    const showTransactionDetail = _txn => {
        setTxn(_txn)
        setShowDetail(true)
    }

    if (!payments || !seasons) return null
    const { items, balances, teamCount, totalEntryFeesDue } = payments
    const _detailItems = txn && showDetail ? [
        { label: 'Player', value: `${txn.firstName} ${txn.lastName}` },
        { label: 'Email', value: `${txn.payer_email}` },
        { label: 'Transaction ID', value: `${txn.txn_id}` },
        { label: 'Amount', value: numeral(txn.payment_amount).format('($0,0.00)') },
        { label: 'Payment Status', value: txn.payment_status },
        { label: 'Timestamp', value: moment(txn.entryDate).format('M/D/YYYY h:mm:ss A') },
    ] : []
    const totalPayments = sumBy((payments || {}).items || [], 'payment_amount')
    const _isEntryFeeMismatch = totalPayments !== totalEntryFeesDue
    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}>{seasonName} Payment Log</h2>
                <div className={s.controls}>
                    <div className={s.refreshContainer}>
                        <Button variant='light' size='sm'
                            onClick={() => getPaymentList()}>
                            <FontAwesomeIcon name='rotate' spin={isLoading} />
                        </Button>
                    </div>
                    <div className={s.paymentTest} title={`Test entry fee: ${numeral(testEntryFee).format('($0,0.00)')}`}>
                        {season ?
                        <RedHerringsPayPalButton
                            contest={'Football'}
                            itemNumber={playerId}
                            receiverEmail={paymentEmail}
                            description={`RedHerrings Football ${seasonName}`}
                            amount={testEntryFee}
                            seasonId={season.seasonId}
                            onAuthorizedPayment={onAuthorizedTestPayment}
                            onCancelledPayment={onCancelledTestPayment} />
                        : null}
                    </div>
                    <div className={s.seasonSelector}><SeasonSelector seasons={seasons} season={season} /></div>
                </div>
            </div>
            <FootballPaymentBalances balances={balances} />
            <h4>Payment Log</h4>
            <ListGroup className={s.paymentList}>
            <ListGroup.Item className={s.totalItem}>
                <div>Total ({pluralize('entry', (items || []).length, true)})</div>
                <div>{numeral(totalPayments).format('($0,0.00)')}</div>
            </ListGroup.Item>
            {_isEntryFeeMismatch ?
            <ListGroup.Item className={s.totalItem}>
                <div>Entry Fees Needed ({pluralize('team', teamCount, true)})</div>
                <div>{numeral(totalEntryFeesDue).format('($0,0.00)')}</div>
            </ListGroup.Item>
            : null}
            {items && items.map(i => 
            <PlayerListItem key={`payment-log-item-${i.txn_id}`}
                player={i} detail={moment(i.entryDate).format('M/D/YYYY h:mm:ss A')}
                rightDetail={numeral(i.payment_amount).format('($0,0.00)')}
                onClick={() => showTransactionDetail(i)} />
            )}
            </ListGroup>
            <Modal heading='Transaction Detail'
                show={showDetail}
                onClose={() => setShowDetail(false)}>
                <div className={s.detailContainer}>
                <ListGroup className={s.detailList}>
                {_detailItems.map((i, _index) => 
                <ListGroup.Item className={s.detailItem} key={`transaction-detail-${_index}`}>
                    <div className={s.content}>
                        <div className={s.label}>{i.label}</div>
                        <div className={s.value}>{i.value}</div>
                    </div>
                </ListGroup.Item>
                )}
                </ListGroup>
                <div className={s.deleteContainer}>
                    <Confirm variant='danger' onConfirm={() => deleteLogItem(txn._id)}
                        title={'Delete Payment Log Item'}
                        confirmText='delete log item'
                        body={'Are you sure you want to delete this payment log item?'}
                        buttonClassName={s.deleteButton} size='sm'
                        buttonIcon='times' buttonText='Delete log item' />
                </div>
                </div>
            </Modal>
        </div>
    )
}

export default FootballPayments