// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ScoreboardButtonToolbar__buttonGroup___fTssC{margin-top:0px}.ScoreboardButtonToolbar__buttonGroup___fTssC .ScoreboardButtonToolbar__button___o5nRl{white-space:nowrap}.ScoreboardButtonToolbar__buttonGroup___fTssC .ScoreboardButtonToolbar__button___o5nRl .ScoreboardButtonToolbar__icon___Z_p94{margin-left:10px}`, "",{"version":3,"sources":["webpack://./src/routes/Cinderella/Scoreboard/ScoreboardButtonToolbar.scss"],"names":[],"mappings":"AAAA,8CACI,cAAA,CACA,uFACI,kBAAA,CACA,8HACI,gBAAA","sourcesContent":[".buttonGroup {\n    margin-top: 0px;\n    .button {\n        white-space: nowrap;\n        .icon {\n            margin-left: 10px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var buttonGroup = `ScoreboardButtonToolbar__buttonGroup___fTssC`;
export var button = `ScoreboardButtonToolbar__button___o5nRl`;
export var icon = `ScoreboardButtonToolbar__icon___Z_p94`;
export default ___CSS_LOADER_EXPORT___;
