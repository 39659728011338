import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { Button, Alert } from 'react-bootstrap'
import { getEnterPicks, enterPicksCommand, saveRegularSeasonPicks, getEnterPicksNFLMatchUp,
    findMyFootballMatchups, setPickTeamId } from '@severed-links/common.redherrings-reducers/football'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { find, orderBy, first, after } from 'lodash'
import * as s from './EnterPicks.scss'
import EnterPicksGameItem from './EnterPicksGameItem'
import EnterPicksGameContainer from './EnterPicksGameContainer'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import moment from 'moment-timezone'

const EnterRegularSeasonPicksGameContainer = ({ seasonName = '', gameDataItem = null, gameItem = {}, ranks = [], rank = 0, onAddCommand, onRequestNflMatchup }) => {
    if (!rank) return null
    return (
        <EnterPicksGameContainer rank={rank}>
            <EnterPicksGameItem 
                game={gameItem} 
                ranks={ranks}
                pickGameData={gameDataItem}
                seasonName={seasonName}
                addCommand={command => onAddCommand(command)} 
                getEnterPicksNFLMatchUp={(seasonName, week, game) => onRequestNflMatchup(seasonName, week, game)} />
        </EnterPicksGameContainer>
    )
}

const EnterRegularSeasonFootballPicks = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const playerId = useSelector(state => state.account._id)
    const seasons = useSelector(state => state.football.seasons)
    const seasonName = useSelector(state => state.football.seasonName)
    const week = useSelector(state => state.football.week)
    const myTeams = useSelector(state => state.football.myTeams)
    const pickTeamId = useSelector(state => state.football.pickTeamId)
    const pickDeadline = useSelector(state => state.football.pickDeadline)
    const picks = useSelector(state => state.football.picks)
    const pickCommands = useSelector(state => state.football.pickCommands)
    const pickGameData = useSelector(state => state.football.pickGameData)
    const picksAreDirty = useSelector(state => state.football.picksAreDirty)
    const leagueScoreboards = useSelector(state => state.football.leagueScoreboards)
    const [isSaving, setSaving] = useState(false)
    const [isSavedOnce, setSavedOnce] = useState(false)
    const afterDeadline = moment().isSameOrAfter(pickDeadline)
    const _myTeam = myTeams.find(i => pickTeamId && i._id === pickTeamId)
    const { teamName } = _myTeam || {}
    const myGame = (findMyFootballMatchups({ leagueScoreboards, seasons, seasonName, week, playerId }) || []).find(i => i._id1 === pickTeamId || i._id2 === pickTeamId)

    useEffect(() => { !pickTeamId && myTeams.length ? dispatch(setPickTeamId((first(myTeams) || {})._id || 0)) : null }, [pickTeamId, myTeams.length])
    useEffect(() => { afterDeadline ? navigate(`/football/regular-season/${seasonName}`) : null }, [afterDeadline])
    useEffect(() => { !afterDeadline && seasonName && week && (_myTeam || {})._id ? dispatch(getEnterPicks(seasonName, week, (_myTeam || {})._id)) : null }, [afterDeadline, seasonName, week, (_myTeam || {})._id])

    const savePicks = () => {
        if (!afterDeadline && pickTeamId) {
            setSaving(true)
            dispatch(saveRegularSeasonPicks({
                teamId: pickTeamId,
                picks: picks && picks.items ? orderBy(picks.items.map(p => ({ _id: p._id, game: p.game, pick: p.pick, rank: p.rank })), ['game'], ['asc']) : []
            })).then(resp => {
                dispatch(createNotification({
                    type: resp.payload.messageType, 
                    headline: `Save Regular Season Picks -- Week ${week}`, 
                    message: resp.payload.message, 
                    timeout: 6000
                }))
                setSaving(false)
                setSavedOnce(true)
            })
        }
    }

    const switchPickTeam = _id => dispatch(setPickTeamId(_id))
    const _pickSummary = orderBy(((picks || {}).items || []).map(i => ({ rank: i.rank, game: i.game })), ['rank'], ['desc'])

    return (
        myTeams && myTeams.length > 0 ?
        <div className={s.container}>
            <div className={s.titleBar}>
                <h2>Enter {seasonName} Regular Season Week {week} Picks{teamName ? `: ${teamName}` : ``}</h2>
                {picksAreDirty ? 
                    <Button size='lg' variant='primary' disabled={isSaving}
                        onClick={!isSaving ? () => savePicks() : null}>
                        <FontAwesomeIcon spin={isSaving} name={!isSaving ? 'check' : 'circle-notch'} />
                        {' '}
                        {!isSaving ? 'save' : 'saving...'}
                    </Button>
                : null}
                {myTeams && myTeams.filter(i => i._id !== pickTeamId).map(i => 
                    <Button size='lg' key={`pick-team-button-${i._id}`} variant='warning' onClick={() => switchPickTeam(i._id)}>
                        {i.teamName}
                        {' '}
                        <FontAwesomeIcon name='chevron-right' />
                    </Button>
                )}
            </div>
            {isSavedOnce && !isSaving ? 
                <Alert className={s.savedAlert} variant='info'>Picks are saved.  <Button style={{ float: 'right' }} onClick={() => navigate(`/football/regular-season/match-up/${myGame.seasonName}/week/${myGame.week}/game/${myGame.game}/${myGame.name1.toLowerCase().replace(' ', '-')}-vs-${myGame.name2.toLowerCase().replace(' ', '-')}`)} variant='info' size='sm'>View matchup</Button></Alert>
            : null}
            <ul className={s.picksContainer}>
                <li className={s.nflFavoriteUnderdogHeaderItem}>
                    <div />
                    <div className={s.icon}><FontAwesomeIcon name='arrow-left' /></div>
                    <div className={s.title}>Favorites</div>
                    <div className={s.title + ' ' + s.right}>Underdogs</div>
                    <div className={s.icon + ' ' + s.right}><FontAwesomeIcon name='arrow-right' /></div>
                </li>
            {picks && picks.items && picks.items.map((i, index) => {
                const _rank = picks.items.length - index
                return (
                    <EnterRegularSeasonPicksGameContainer rank={_rank} seasonName={seasonName}
                        gameDataItem={find(pickGameData, { seasonName, week, game: (find(picks.items, { rank: _rank }) || {}).game })}
                        gameItem={find(picks.items, { rank: _rank })}
                        ranks={(orderBy(picks.items || [], ['rank'], ['desc'])).map(r => r.rank)}
                        onAddCommand={command => dispatch(enterPicksCommand(command))}
                        onRequestNflMatchup={(seasonName, week, game) => dispatch(getEnterPicksNFLMatchUp(seasonName, week, game))}
                        key={`regular-season-pick-game-container-${picks.items.length - index}`} />
                )
            })}
            </ul>
        </div>
        : null
    )
}

export default EnterRegularSeasonFootballPicks
