import React, { useEffect } from 'react'
import { ListGroup } from 'react-bootstrap'
import { getNflStandings } from '@severed-links/common.redherrings-reducers/football'
import { find } from 'lodash'
import * as s from './NFLStandings.scss'
import NflTeamListItem from '../../NflTeamListItem'

const NFLTeamAnalysisAgainstOtherTeams = ({ seasonName = '', records = [] }) => {

    return (
            <div>
                <h4>Records Against Other Teams</h4>
                <ListGroup>
                {records && records.map(x => 
                <NflTeamListItem key={`record-against-opp-${x.teamId}-${x.oppId}`}
                    teamId={x.oppId} teamName={x.oppName} city={x.oppCity} mascot={x.oppMascot} seasonName={seasonName}
                    right={x.record} />
                )} 
                </ListGroup>
            </div>
    )
}

export default NFLTeamAnalysisAgainstOtherTeams