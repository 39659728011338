import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FormGroup, FormLabel, FormControl, Button, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { isEmpty } from 'validator'
import * as s from './ForgotPassword.scss'
import { requestPasswordChange } from '@severed-links/common.redherrings-reducers/forgotPassword'

const ForgotPasswordConfirmation = forwardRef(({ jumpToStep }, _ref) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const fp = useSelector(state => state.forgotPassword)
    const _isValid = fp.passwordIsValid && fp.passwordIsReset

    useEffect(() => {
        if (fp.passwordIsValid && !fp.passwordIsReset) {
            dispatch(requestPasswordChange({ verificationCode: fp.verificationCode, newPassword: fp.password }))
        }
    }, [fp.passwordIsValid, fp.passwordIsReset])

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValid
    }))

    return (
        <div>
            {fp.passwordIsReset ?
            <Alert variant='success'>
                <div>
                    <h4>Your password has been reset.</h4>
                    <p>Click <a style={{ cursor: 'pointer' }} onClick={() => navigate('/login')}>here</a> to log in.</p>
                </div>
            </Alert>
            :null}
            {!fp.passwordIsReset && fp.passwordError ?
            <Alert variant='danger'>
                <div>
                    <h4>There was an error resetting your password.</h4>
                    <p>{fp.passwordErrorMessage}</p>
                </div>
            </Alert>
            :null}
        </div>
    )
})

export default ForgotPasswordConfirmation
