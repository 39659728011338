import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './ScoreboardListGroup.scss'
import ScoreboardPlayerTeams from './ScoreboardPlayerTeams'
import { img } from '@severed-links/common.redherrings-constants'
import { endsWith, startsWith } from 'lodash'

const ScoreboardListItem = ({ item = {}, teams = [], showRank = true, afterDeadline = false, isYourEntry = false, addTeamToFilter = () => void(0) }) => {

    if (!item) return null
    const i = item
    const _avatarUrl = img.BaseUrl + (!endsWith(img.BaseUrl, '/') && !startsWith(i.avatarUrl || img.NoImageAvailable, '/') ? '/' : '') + (i.avatarUrl || img.NoImageAvailable)
    return (
        <ListGroup.Item className={s.item + (isYourEntry ? ' ' + s.yourEntry : '')}>
            <div className={s.rank}>{showRank ? i.rank : <span>&nbsp;</span>}</div>
            <div className={s.avatar}>
                <Image src={_avatarUrl} />
            </div>
            <div className={s.entry}>
                <div className={s.name}>{i.name}</div>
                <div className={s.entryName}>{i.entryName}</div>
            </div>
            {afterDeadline ?
            <div className={s.teams}>
                <ScoreboardPlayerTeams entry={i}
                    afterDeadline={afterDeadline}
                    teams={teams} 
                    addTeamToFilter={addTeamToFilter} />
            </div>
            : null}
            <div className={s.score + (i.teamsRemaining > 0 ? ' ' + s.active : '')}>{i.score}</div>
            <div className={s.teamsLeft + ' ' + s[`teamsLeft${i.teamsRemaining}`] + (i.teamsRemaining === 0 ? ' ' + s.zero : '')}>{i.teamsRemaining === 0 ? '--' : `${i.teamsRemaining}`}</div>
        </ListGroup.Item>
    )
}

export default ScoreboardListItem
