import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import { Row, Col, FormGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './Login.scss'
import { login, getProfile, getTimeZone, getMe } from '@severed-links/common.redherrings-reducers/account'
import Alert from '@severed-links/common.alert'
import Button from '@severed-links/common.button'
import TextInput from '@severed-links/common.text-input'
import SpinnerOverlay from '@severed-links/common.spinner-overlay'

const Login = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const account = useSelector(state => state.account)
    const [userName, setUsername] = useState('')
    const [password, setPassword] = useState('')
    const [usernameValid, setUsernameValid] = useState(false)
    const [passwordValid, setPasswordValid] = useState(false)
    const [isLoggingIn, setLoggingIn] = useState(false)
    const { isAuthenticated, access_token, roles, isMyProfileLoaded } = account
    const error = account.error || null
    const _isValid = usernameValid && passwordValid
    const _redirectPath = location?.state?.from?.pathname || '/main'

    useEffect(() => { loginCallback() }, [isLoggingIn, isAuthenticated, access_token])
    useEffect(() => { checkForRedirect() }, [isLoggingIn, isAuthenticated, access_token, isMyProfileLoaded, roles.length, _redirectPath])
    useEffect(() => { updateValidations() }, [userName, password])

    const loginCallback = () => {
        if (!isLoggingIn && isAuthenticated && access_token) {
            dispatch(getTimeZone())
            dispatch(getMe())
            dispatch(getProfile())
        }
    }

const checkForRedirect = () => {
        if (!isLoggingIn && isAuthenticated && access_token && isMyProfileLoaded && roles.length) {
            navigate(_redirectPath, { replace: true })
        }
    }
    
    const handleChange = (_fieldName, _value = '') => {
        if (_fieldName === 'userName') setUsername(_value)
        else if (_fieldName === 'password') setPassword(_value)
    }

    const handleKeyPress = e => {
        if (e.key === 'Enter') {
            handleSubmit()
        }
    }

    const handleSubmit = () => {
        if (_isValid) {
            setLoggingIn(true)
            dispatch(login(userName, password))
            .then(action => {
                setLoggingIn(false)
            })
        }
    }

    const updateValidations = () => {
        setUsernameValid(userName != null && userName != '' && userName.length >= 2)
        setPasswordValid(password != null && password != '' && password.length >= 3)
    }

    var errorMsg = null
    if (error) {
        if (error.name && error.response && error.response.error_description)
            errorMsg = error.response.error_description
        else if (error.response && error.response.message)
            errorMsg = error.response.message
        else if (error.error_description)
            errorMsg = error.error_description
        else 
            errorMsg = 'Error'
    }
    const showSpinner = isLoggingIn || isAuthenticated
    return (
        <div className={s.container}>
            <Row>
                <Col xs={12} sm={{ span: 10, offset: 1 }} md={{ span: 6, offset: 3 }} lg={{ span: 4, offset: 4 }}>
                    <div className={s.form}>

                        <h2 className={s.header}>Login</h2>
                        
                        <SpinnerOverlay show={showSpinner} message={'Logging in...'} rounded />
                        
                        <FormGroup className={s.formGroup}>
                            <TextInput label='Username' value={userName} autoFocus
                                icon='envelope' isValid={usernameValid} required
                                onChange={_text => handleChange('userName', _text)}
                                autoCapitalize='none' showValidState size='lg'
                                onKeyDown={e => handleKeyPress(e)} />
                            </FormGroup>
                        <FormGroup className={s.formGroup}>
                            <TextInput label='Password' value={password}
                                icon='lock' isValid={passwordValid} required
                                onChange={_text => handleChange('password', _text)}
                                type='password' showValidState size='lg'
                                onKeyDown={e => handleKeyPress(e)} />
                        </FormGroup>
                        <FormGroup className={s.submitFormGroup}>
                            <Button brandColor={'redherringsMain'}
                                block size='lg'
                                isOutline={!_isValid || isLoggingIn}
                                type='submit' variant='primary' 
                                disabled={!_isValid || isLoggingIn}
                                icon='sign-in-alt' title='login'
                                onClick={() => handleSubmit()} />
                        </FormGroup>

                    </div>

                    <div className={s.errorMsgContainer}>
                    {errorMsg ? 
                    <Alert variant='danger'
                        icon='exclamation-triangle' 
                        message={errorMsg} />
                    : null}
                    </div>

                        
                </Col>
            </Row>
        </div>
    )
}

export default Login