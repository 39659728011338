import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { img } from '@severed-links/common.redherrings-constants'
import BaseAuthenticatedMenu from './BaseAuthenticatedMenu'
import { Nav, NavDropdown } from 'react-bootstrap'

import CloudinaryImage from '../routes/Cloudinary/CloudinaryImage'
import { getFootballDetails, getSeasons, getNflTeams, getMyFavoriteTeam, getMyTeams } from '@severed-links/common.redherrings-reducers/football'
import * as s from './Menu.scss'

const FootballAdminMenu = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const isAuthenticated = useSelector(state => state.account.isAuthenticated)
    const seasonId = useSelector(state => state.football.seasonId)
    const seasonMode = useSelector(state => state.football.seasonMode)
    const seasonName = useSelector(state => state.football.seasonName)
    const week = useSelector(state => state.football.week)
    const playoffWeek = useSelector(state => state.football.playoffWeek)
    const isHome = location.pathname.toLowerCase() === '/football/admin'

    useEffect(() => { fetchData() }, [isAuthenticated, seasonMode, seasonName, week, playoffWeek])

    const fetchData = () => {
        if (isAuthenticated) {
            dispatch(getSeasons())
            dispatch(getNflTeams())
            dispatch(getFootballDetails())
            .then(() => seasonName ? dispatch(getMyTeams(seasonName)) : void(0))
            dispatch(getMyFavoriteTeam())
        }
    }

    const navbarBrand = (       
        <Link to={{ pathname: '/football/admin' }}>
            <CloudinaryImage publicId={img.FootballHeader} width={200} height={50} />
        </Link>
    )

    const menuItems = [
        <Nav.Item className={s.item} key={1}><Nav.Link eventKey={1} title='Back to Redherrings Football' onClick={() => navigate('/football')}>
            <FontAwesomeIcon name='chevron-left' />
        </Nav.Link></Nav.Item>,
        !isHome ? <Nav.Item className={s.item} key={2}><Nav.Link eventKey={2} title='Back to Football Admin Dashboard' onClick={() => navigate('/football/admin')}>
            <FontAwesomeIcon name='home' />
        </Nav.Link></Nav.Item> : null,
        <NavDropdown key={30} id='rh-admin-preseason-nav-dropdown' title={'Preseason'} className={s.dropdown}>
            <NavDropdown.Item eventKey={30.1} onClick={() => navigate('/football/admin/preseason/nfl-scheduler')}>NFL scheduler</NavDropdown.Item>
            <NavDropdown.Item eventKey={30.2} onClick={() => navigate('/football/admin/preseason/team-data/' + seasonName)}>Team data</NavDropdown.Item>
            <NavDropdown.Item eventKey={30.3} onClick={() => navigate('/football/admin/preseason/league-scheduler')}>League scheduler</NavDropdown.Item>
            <NavDropdown.Item eventKey={30.4} onClick={() => navigate('/football/admin/preseason/payment-log/' + seasonName)}>Payment log</NavDropdown.Item>
        </NavDropdown>,
        <NavDropdown key={40} id='rh-admin-regular-nav-dropdown' title={'Regular season'} className={s.dropdown}>
            <NavDropdown.Item eventKey={40.1} onClick={() => navigate(`/football/admin/regular-season/update-manager/${seasonName}/week/${week}`)}>Update manager</NavDropdown.Item>
            <NavDropdown.Item eventKey={40.2} onClick={() => navigate(`/football/admin/regular-season/update-nfl-lines/${seasonName}/week/${week}`)}>Update NFL lines</NavDropdown.Item>
        </NavDropdown>,
        <NavDropdown key={50} id='rh-admin-postseason-nav-dropdown' title={'Postseason'} className={s.dropdown}>
            <NavDropdown.Item eventKey={50.1} onClick={() => navigate(`/football/admin/postseason/setup-playoffs/${seasonName}`)}>Setup league playoffs</NavDropdown.Item>
            <NavDropdown.Item eventKey={50.2} onClick={() => navigate(`/football/admin/postseason/update-manager/${seasonName}/playoff-week/${playoffWeek}`)}>Update manager</NavDropdown.Item>
            <NavDropdown.Item eventKey={50.3} onClick={() => navigate(`/football/admin/postseason/update-nfl-playoffs/${seasonName}`)}>Update NFL playoff games</NavDropdown.Item>
            <NavDropdown.Item eventKey={50.4} onClick={() => navigate(`/football/admin/postseason/show-payouts/${seasonName}`)}>Show payouts</NavDropdown.Item>
        </NavDropdown>
    ]
    return (
        <BaseAuthenticatedMenu backgroundImageUrl={img.CloudinaryBaseUrl + img.FootballHeaderBackground}
            navbarBrand={navbarBrand} 
            menuItems={menuItems} />
    )
}

export default FootballAdminMenu
