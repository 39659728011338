import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { ListGroup, Button, FormGroup, FormLabel, FormControl, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './CinderellaRecapList.scss'
import striptags from 'striptags'
import moment from 'moment-timezone'

const CinderellaRecapList = ({ showEditor = false, activeRecapId = null, onSelectRecap = null }) => {

    const params = useParams()
    const recaps = useSelector(state => state.cinderellaAdmin.recaps)
    const year = useSelector(state => state.cinderellaAdmin.year)
    const _blankArticle = { _id: null, articleText: '', title: '', lastEdited: null, active: false, isNew: true }

    return (
            <ListGroup className={s.container}>
            {recaps.map(r =>
                <ListGroup.Item className={s.articleListItem + (r._id === activeRecapId ? ` ${s.isActive}` : '')} key={`recap-list-item-${r._id}`}
                    onClick={() => onSelectRecap(r._id)}>
                    <div className={s.titleContainer}>
                        <div className={s.title}>{r.title}</div>
                        <div className={s.labels}>
                            {r.active ? <Badge bg={'primary'}>Active</Badge> : null}
                            {' '}
                            <Badge bg={r.year === year ? 'success' : 'light'} text={r.year === year ? 'light' : 'dark'}>{r.year}</Badge>
                        </div>
                    </div>
                    <div className={s.teaserContainer}>
                        <div className={s.teaser}>{striptags(r.articleText || '').replace(/\&nbsp;/g,' ').replace(/\&amp;/g,'&')}</div>
                        <div className={s.date}>last edited {moment(r.lastEdited).local().fromNow()}</div>
                    </div>
                </ListGroup.Item>
            )}
            </ListGroup>
    )
}

export default CinderellaRecapList