// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ResetAllEliminatorReminders__container___vLPld{margin-left:1rem}.ResetAllEliminatorReminders__container___vLPld .ResetAllEliminatorReminders__button___YpWqh{white-space:nowrap}.ResetAllEliminatorReminders__reminderTypeList___VlNpi{margin-bottom:1rem}.ResetAllEliminatorReminders__reminderTypeList___VlNpi .ResetAllEliminatorReminders__item___rRYeK{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center}.ResetAllEliminatorReminders__reminderTypeList___VlNpi .ResetAllEliminatorReminders__item___rRYeK .ResetAllEliminatorReminders__icon___N8MGx{flex:0 0 2rem;margin-right:1rem}.ResetAllEliminatorReminders__reminderTypeList___VlNpi .ResetAllEliminatorReminders__item___rRYeK .ResetAllEliminatorReminders__content___T0F_b{flex:1 1 auto}.ResetAllEliminatorReminders__reminderTypeList___VlNpi .ResetAllEliminatorReminders__item___rRYeK .ResetAllEliminatorReminders__controls___YzcT6{flex:0 0 0;margin-left:1rem}`, "",{"version":3,"sources":["webpack://./src/routes/Eliminator/Admin/ResetAllEliminatorReminders.scss"],"names":[],"mappings":"AAEA,gDAEI,gBAAA,CAEA,6FACI,kBAAA,CAKR,uDACI,kBAAA,CAEA,kGACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CAEA,6IACI,aAAA,CACA,iBAAA,CAGJ,gJACI,aAAA,CAGJ,iJACI,UAAA,CACA,gBAAA","sourcesContent":["@use '../../../styles/variables/colors' as colors;\n\n.container {\n\n    margin-left: 1rem;\n\n    .button {\n        white-space: nowrap;\n    }\n\n}\n\n.reminderTypeList {\n    margin-bottom: 1rem;\n\n    .item {\n        display: flex;\n        flex-flow: row nowrap;\n        justify-content: space-between;\n        align-items: center;\n\n        .icon {\n            flex: 0 0 2rem;\n            margin-right: 1rem;\n        }\n\n        .content {\n            flex: 1 1 auto;\n        }\n\n        .controls {\n            flex: 0 0 0;\n            margin-left: 1rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `ResetAllEliminatorReminders__container___vLPld`;
export var button = `ResetAllEliminatorReminders__button___YpWqh`;
export var reminderTypeList = `ResetAllEliminatorReminders__reminderTypeList___VlNpi`;
export var item = `ResetAllEliminatorReminders__item___rRYeK`;
export var icon = `ResetAllEliminatorReminders__icon___N8MGx`;
export var content = `ResetAllEliminatorReminders__content___T0F_b`;
export var controls = `ResetAllEliminatorReminders__controls___YzcT6`;
export default ___CSS_LOADER_EXPORT___;
