import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import * as s from './EliminatorIntro.scss'

const EliminatorInstructions = ({ eliminationMode }) => {

    return (
        <div className={s.instructions}>
            <p>Here's the rules:</p>

            <p>Each week you pick one game and you pick the winner of that game. No point spreads. 
            The team you pick simply needs to win its game. So you examine the weekly schedule 
            and find the game that looks like the easiest win for a team and then select that 
            team. Sounds pretty simple, huh. </p>

            <p>As long as you make the right pick, you stay in the pool the following week. NOTE 
            THAT THIS SEASON WE'RE USING A {eliminationMode} ELIMINATION 
            FORMAT. The last person standing wins. So you only need to be better than the person in 
            the office next to you to win. The pool pays out first and second place on a 70-30 split 
            of the entries.</p>

            <p>One caveat ... you can't select the same team twice. This adds at least a certain level 
            of strategy to your picks. For example, if you pick New England to win its game in the 
            first week, you can't pick New England again for the rest of the season. Thus, you may 
            want to at least look ahead at the schedule to see if you want to use up your "good" teams early.</p>

            <ul className={s.rulesList}>
                <li>The last person who hasn't been eliminated will be declared the winner and the penultimate 
                person eliminated is the runner-up.</li>
                <li>Any person who forgets to provide a pick before the first game of the week will automatically 
                receive a wrong pick for the week.</li>
                <li>If the winners have not been decided by the time the regular season ends, the pool will 
                continue into the NFL playoffs where the remaining contestants will continue picking the playoff 
                games. In the playoffs, all remaining contestants will start with a clean pick slate and their 
                previous selections will be disregarded. This will allow contestants to pick a team during the 
                NFL playoffs that they had already selected during the regular season. However, once a team is 
                selected in the playoffs, it cannot be selected again.</li>
                <li>At any point in the pool, if there are only 2 contestants left and both contestants lose 
                their picks in the same week, the pool continues on until 1 of the contestants makes a correct pick 
                and the other contestant makes a wrong pick.</li>
                <li>If there are multiple contestants remaining after the Super Bowl, all remaining contestants 
                split the total proceeds equally according to their place of finish. For example, if there are 
                still 4 teams remaining all without a single loss, those 4 teams will equally split the combined 
                proceeds for 1st and 2nd place.</li>
                <li>During the week when the winner is declared, the contestant who loses their pick will be declared 
                the runner-up. If multiple contestants lose their picks during that same week, those losing contestants 
                will be declared co-runners-up and will split the runner-up proceeds equally. For example, if during 
                the 2nd week of the playoffs there are 4 contestants left and 3 out of the 4 contestants lose their 
                pick that week, the 3 losing contestants will split 2nd place while the contestant with the winning pick 
                takes first.</li>
            </ul>

        </div>
    )
}

export default EliminatorInstructions