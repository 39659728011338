import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { ListGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './AccountDeletionPortal.scss'
import { getPlayerInfoSummary, setAccountDeletionState } from '@severed-links/common.redherrings-reducers/accountDeletion'
import StepZilla from 'react-stepzilla'
import InstructionsStep from './InstructionsStep'
import PersonalInfoStep from './PersonalInfoStep'
import ConfirmationStep from './ConfirmationStep'
import EndStep from './EndStep'
import LogoutStep from './LogoutStep'

const AccountDeletionPortal = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const playerId = useSelector(state => state.account._id)
    const _accountDeletion = useSelector(state => state.accountDeletion)

    useEffect(() => {
        dispatch(getPlayerInfoSummary())
        const _initializeState = {
            playerInfoSelection: '',
            accountDeletionConfirmed: false,
        }
        dispatch(setAccountDeletionState(_initializeState))
    }, [])

    useEffect(() => {
    }, [])

    const handleStepChange = _currentStep => {
    }

    const _steps = [
        { name: 'Instructions', component: <InstructionsStep /> },
        { name: 'Personal Information', component: <PersonalInfoStep /> },
        { name: 'Confirmation', component: <ConfirmationStep /> },
        { name: 'Complete', component: <EndStep /> },
        { name: 'Logout', component: <LogoutStep /> },
    ]

    return (
        <div className={s.container}>

            <div className={s.heading}>
                <div className={s.icon}>
                    <FontAwesomeIcon name='exclamation-triangle' size='2x' />
                </div>
                <h2 className={s.title}>Account Deletion Portal</h2>
            </div>
            <div className={s.stepContainer}>
                <StepZilla steps={_steps}
                    preventEnterSubmission
                    nextTextOnFinalActionStep={'Delete Account'}
                    onStepChange={handleStepChange}
                    nextButtonText={<FontAwesomeIcon name='chevron-right' />}
                    backButtonText={<FontAwesomeIcon name='chevron-left' />}
                    nextButtonCls={'btn btn-next btn-outline-danger btn-lg ' + s.navButton + ' ' + s.nextNavButton}
                    backButtonCls={'btn btn-prev btn-outline-secondary btn-lg ' + s.navButton + ' ' + s.prevNavButton}
                />
            </div>
        </div>
    )
}

export default AccountDeletionPortal
