import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, FormGroup, FormLabel, FormControl, InputGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import NCAATeam from '../../Cinderella/NCAATeam/NCAATeam'
import Line from '../../Football/Line'
import * as s from './CaptureAdminGameEditor.scss'
import moment from 'moment-timezone'
import Modal from '@severed-links/common.modal'
import { FRIENDLY_DATE_FORMAT } from '@severed-links/common.redherrings-constants'
import Datetime from 'react-datetime'
import { find, isEqual } from 'lodash'
import { CAPTUREADMIN_CONTESTS_COMPLETE } from '@severed-links/common.redherrings-reducers/captureAdmin'

const CaptureAdminGameEditor = ({ game = {}, showLine = false, showGameTime = false, saveGameTime, saveLineAndFavorite, saveScores }) => {

    const dispatch = useDispatch()
    const timeZone = useSelector(state => state.account.time_zone)
    const [editGameTime, setEditGameTime] = useState(null)
    const [originalGameTime, setOriginalGameTime] = useState(null)
    const [gameTime, setGameTime] = useState(null)
    const [isGameTimeChanged, setGameTimeChanged] = useState(false)
    const [editLine, setEditLine] = useState(false)
    const [line, setLine] = useState(null)
    const [originalLine, setOriginalLine] = useState(null)
    const [favoriteTeam, setFavoriteTeam] = useState(null)
    const [originalFavoriteTeam, setOriginalFavoriteTeam] = useState(null)
    const [isLineChanged, setLineChanged] = useState(false)
    const [isFavoriteChanged, setFavoriteChanged] = useState(false)
    const [editScores, setEditScores] = useState(false)
    const [scores, setScores] = useState([])

    const _isLineValid = line !== null && !isNaN(line)
    const _isGameTimeValid = game.gameTime && moment(game.gameTime).isValid()
    const _isFavoriteTeamValid = favoriteTeam && ((game || {}).teams || []).some(t => t.teamName === (favoriteTeam || {}).teamName)

    useEffect(() => { updateComponentState() }, [game, showLine, showGameTime])

    const initializeGameTimeState = () => {
        const gameTime = moment(game.gameTime).local().format(FRIENDLY_DATE_FORMAT)
        setEditGameTime(null)
        setGameTime(gameTime)
        setOriginalGameTime(gameTime)
        setGameTimeChanged(false)
    }

    const initializeLineState = () => {
        const _favoriteTeam = find(game.teams, { teamId: game.favoriteTeamId })
        const _line = game.line
        setEditLine(false)
        setLine(_line || 0.0)
        setFavoriteTeam(_favoriteTeam)
        setOriginalLine(_line || 0.0)
        setOriginalFavoriteTeam(_favoriteTeam)
        setLineChanged(false)
        setFavoriteChanged(false)
    }

    const initializeGameScoreState = () => {
        setEditScores(false)
        setScores(game.teams ? game.teams.map(t => ({ ...t, score: t.score ? t.score.toString() : '' })) : [])
    }

    const updateComponentState = () => {
        initializeGameTimeState()
        initializeLineState()
        initializeGameScoreState()
    }

    const handleDateChange = dt => {
        const _editGameTime = moment(dt, FRIENDLY_DATE_FORMAT).format(FRIENDLY_DATE_FORMAT)
        setEditGameTime(_editGameTime)
        setGameTimeChanged(_editGameTime !== originalGameTime)
        setGameTime(_editGameTime)
    }

    const handleFavoriteChange = teamId => {
        const _favoriteTeam = find(game.teams, { teamId: teamId })
        setFavoriteChanged((_favoriteTeam || {}).teamId !== (originalFavoriteTeam || {}).teamId)
        setFavoriteTeam(_favoriteTeam)
    }

    const handleLineChange = e => {
        const _newLine = e.target.value
        setLine(_newLine)
        setLineChanged(_newLine !== `${originalLine}`)
    }

    const handleScoreChange = (e, index) => {
        var _scores = [...scores]
        _scores[index].score = e.target.value
        setScores(_scores)
    }

    const handleLineChangeButton = offset => {
        const newLine = parseFloat(line) + offset
        setLine(`${newLine}`)
        setLineChanged(`${newLine}` !== `${originalLine}`)
    }

    const handleSaveGameTime = () => {
        if (isGameTimeChanged) {
            saveGameTime(game, moment(editGameTime, FRIENDLY_DATE_FORMAT).format(FRIENDLY_DATE_FORMAT))
        }
        setEditGameTime(null)
    }

    const handleSaveGameScore = () => {
        saveScores(game, scores)
        setEditScores(false)
    }

    const handleSaveLineAndFavorite = () => {
        if (favoriteTeam && !isNaN(line)) {
            saveLineAndFavorite(game, line, favoriteTeam)
            setEditLine(false)
        }
    }

    return (
        <div className={s.container}>

            {showGameTime ?
            <div className={s.gameTimeContainer + (!_isGameTimeValid ? ' ' + s.invalidGameTime : '') + (isGameTimeChanged ? ' ' + s.isChanged : '')}
                onClick={() => setEditGameTime(moment(game.gameTime).format(FRIENDLY_DATE_FORMAT))}>
                <span style={{ float: 'left' }}>
                    <FontAwesomeIcon name='calendar' />
                    {' '}
                    <span>{moment(gameTime, FRIENDLY_DATE_FORMAT).format("MMM D YYYY")}</span>
                </span>
                <span style={{ float: 'right' }}>
                    <FontAwesomeIcon name='clock' />
                    {' '}
                    <span>{moment(gameTime, FRIENDLY_DATE_FORMAT).format("h:mm A")}</span>
                </span>
                <br style={{ clear: "both" }} />
            </div> 
            : null}

            <div className={s.teamsContainer} onClick={() => setEditScores(true)}>
            {game.teams && game.teams.map(t =>
                <NCAATeam key={t.teamId} teamId={t.teamId} name={t.teamName} 
                    showAvatar avatarUrl={t.avatarUrl}
                    seed={t.seed} gameScore={t.score} showGameScore={true} />
            )}
            </div>

            {showLine ?
            <div className={s.lineContainer + (isLineChanged || isFavoriteChanged || !_isFavoriteTeamValid || !_isLineValid ? ' ' + s.invalidLine : '')}
                onClick={() => setEditLine(true)}>
                <span>{(favoriteTeam || {}).teamName || '[None]'}</span>
                {' '}
                <span>by</span>
                {' '}
                <Line line={parseFloat(line)} showSign={false} />
            </div>
            : null}

            <Modal heading='Edit game time' size={'sm'}
                show={!!editGameTime}
                onClose={() => setEditGameTime(null)}
                actionButtonOnClick={() => handleSaveGameTime()}
                actionButtonText='save game time'>
                <div className={s.gameTimeEditContainer}>
                    <h4 className='text-center'>{moment(editGameTime, FRIENDLY_DATE_FORMAT).format(FRIENDLY_DATE_FORMAT)}</h4>
                    <Datetime value={editGameTime} 
                        dateFormat="M/D/YYYY" 
                        timeFormat="h:mm A"
                        open={true}
                        onChange={(dt) => handleDateChange(dt)} />
                </div>
            </Modal>

            <Modal heading='Edit game score' size={'sm'}
                show={editScores}
                onClose={() => setEditScores(false)}
                actionButtonOnClick={() => handleSaveGameScore()}
                actionButtonText='save scores'>
                <div>
                    <Row>
                    {scores && scores.map((t, index) =>
                        <Col xs={6} key={t.teamId}>
                            <NCAATeam key={t.teamId} teamId={t.teamId} name={t.teamName} 
                                showAvatar avatarUrl={t.avatarUrl}
                                seed={t.seed} />
                            <FormGroup size='lg'>
                                <FormControl size='lg' className='text-center' type="text" value={scores[index].score} onChange={(e) => handleScoreChange(e, index)} />
                            </FormGroup>
                        </Col>
                    )}
                    </Row>
                </div>
            </Modal>

            <Modal heading='Edit line and favorite' size={'sm'}
                show={editLine}
                onClose={() => setEditLine(false)}
                actionButtonOnClick={() => handleSaveLineAndFavorite()}
                actionButtonText='save line and favorite'>
                <div className={s.lineAndFavoriteEditContainer}>
                    <h4>Game favorite</h4>
                    <div>
                    {game.teams && game.teams.map(t =>
                        <div key={t.teamId} className={s.editLineTeamContainer + (favoriteTeam && favoriteTeam.teamId === t.teamId ? ' ' + s.gameFavorite : '')}
                            onClick={() => handleFavoriteChange(t.teamId)}>
                            <NCAATeam teamId={t.teamId} name={t.teamName} seed={t.seed} 
                                showAvatar avatarUrl={t.avatarUrl}
                                showGameScore={false} />
                        </div>
                    )}
                    </div>
                    <h4>Line</h4>
                    <div>
                        <FormGroup size='lg'>
                            <InputGroup>
                                <Button size='lg' onClick={() => handleLineChangeButton(-0.5)}><FontAwesomeIcon name='arrow-down' /></Button>
                                <FormControl size='lg' className='text-center' type="text" value={line} onChange={(e) => handleLineChange(e)} />
                                <Button size='lg' onClick={() => handleLineChangeButton(0.5)}><FontAwesomeIcon name='arrow-up' /></Button>
                            </InputGroup>
                        </FormGroup>
                    </div>
                </div>
            </Modal>

        </div>
    )
}

export default CaptureAdminGameEditor