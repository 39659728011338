import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ToastContainer } from 'react-bootstrap'
import * as s from './NotificationsContainer.scss'
import Notification from './Notification'

const NotificationsContainer = () => {

  const dispatch = useDispatch()
  const notifications = useSelector(state => state.notifications || [])

  return (
    <ToastContainer position='top-end' className={s.container}>
    {notifications && notifications.map((i, _index) =>
    <Notification notification={i} key={`notification-${i._id || `${_index}`}`} />
    )}
    </ToastContainer>
  )
}

export default NotificationsContainer
