import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './CaptureContestPicker.scss'
import { findIndex, find, isEqual } from 'lodash'

const CaptureContestPicker = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const contests = useSelector(state => state.captureAdmin.contests || [])
    const contestId = params.contestId || null
    const selectedContest = find(contests, { _id: contestId }) || {}

    const handleChange = e => navigate(location.pathname.replace(contestId, e))

    return (
        <div className={s.container + ' hidden-print'} onClick={e => e.stopPropagation()}>
            {contests && contests.length > 0 ?
            <DropdownButton autoClose onSelect={e => handleChange(e)}
                title={<span>{selectedContest.contestName || '[Select a contest...]'}</span>}
                id='ContestSelector-dropdown'>
            {contests && contests.map(c =>
                <Dropdown.Item key={c._id} eventKey={c._id}>{c.contestName}</Dropdown.Item>
            )}
            </DropdownButton>
            : null}
        </div>
    )
}

export default CaptureContestPicker