import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import NCAATeam from '../../Cinderella/NCAATeam/NCAATeam'
import { map, filter, indexOf } from 'lodash'
import * as s from './CaptureTeamDraw.scss'

const TeamDrawAvailableNCAATeams = ({ pickTeam = () => void(0) }) => {

    const dispatch = useDispatch()
    const teamDraw = useSelector(state => state.captureAdmin.teamDraw)

    if (!teamDraw) return null
    const { picks, teams } = teamDraw
    const pickedIds = map(picks, p => p.teamId)
    const availableTeams = filter(teams, t => indexOf(pickedIds, t.teamId) === -1)
    return (
        <div className={s.availableTeamContainer}>
        {availableTeams && availableTeams.map(t =>
            <div className={s.availableTeam} key={t.teamId} onClick={() => pickTeam(t)}>
                <NCAATeam teamId={t.teamId} name={t.teamName} 
                    showAvatar avatarUrl={t.avatarUrl}
                    seed={t.seed} size='sm' />
            </div>
        )}
        {!availableTeams || availableTeams.length === 0 ?
            <Alert variant='success'>No teams left!</Alert>
        : null}
        </div>
    )
}

export default TeamDrawAvailableNCAATeams