import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getScoreboard, getCaptureLog, getNcaaGames } from '@severed-links/common.redherrings-reducers/capture'
import * as s from './CaptureScoreboard.scss'
import CaptureScoreboardList from './CaptureScoreboardList'
import CaptureNcaaGameLists from './CaptureNcaaGameLists'

const GameLogButton = ({ onClick }) => (
    <Button className={s.logButton} variant='light' onClick={() => onClick()}><FontAwesomeIcon name='list' />{' '}game log</Button>
)

const NcaaGamesButton = ({ onClick }) => (
    <Button className={s.logButton + ' ' + s.ncaaGamesButton} variant='light' onClick={() => onClick()}><FontAwesomeIcon name='calendar' />{' '}NCAA games</Button>
)

const CaptureScoreboard = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const contestId = params.contestId || null
    const contest = useSelector(state => state.capture.contest) || {}
    const year = parseInt(contest.year || '0')
    const scoreboard = useSelector(state => state.capture.scoreboard)

    useEffect(() => {
        if (contestId) {
            dispatch(getScoreboard(contestId))
        }
    }, [contestId])

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}>Scoreboard</h2>
                <div className={s.controls}>
                    <GameLogButton onClick={() => navigate(`/capture/${contestId}/game-log`)} />
                </div>
            </div>
            <div className={s.contentContainer}>
                <div className={s.scoreboardContainer}>
                    <CaptureScoreboardList year={year} scoreboard={scoreboard} />
                    <div className={s.buttonsContainer}>
                        <GameLogButton onClick={() => navigate(`/capture/${contestId}/game-log`)} />
                        <NcaaGamesButton onClick={() => navigate(`/capture/${contestId}/ncaa-games/${year}`)} />

                    </div>
                </div>
                <div className={s.upcomingGamesContainer}>
                    <CaptureNcaaGameLists />
                </div>
            </div>
        </div>
    )
}

export default CaptureScoreboard