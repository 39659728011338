import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { Table, Button } from 'react-bootstrap'
import * as s from './FootballAdmin.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Player from '../../Player/Player'
import moment from 'moment-timezone'
import Confirm from '@severed-links/common.confirm'
import { convertPendingRequest, emailRePendingRequest, deletePendingRequest, setTeamDataReload } from '@severed-links/common.redherrings-reducers/footballAdmin'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'

const TeamDataPendingRequests = ({ requests = [] }) => {

    const dispatch = useDispatch()

    const doDeletePendingRequest = _id => {
        dispatch(deletePendingRequest(_id))
        .then(action => {
            dispatch(createNotification({ type: action.payload.messageType, 
                headline: 'Delete Pending Request', 
                message: action.payload.message, 
                timeout: 6000 
            }))
            dispatch(setTeamDataReload(true))
        })
    }

    const doEmailRePendingRequest = _id => {
        dispatch(emailRePendingRequest(_id))
        .then(action => {
            dispatch(createNotification({ type: action.payload.messageType, 
                headline: 'Email Regarding Pending Request', 
                message: action.payload.message, 
                timeout: 6000 
            }))
            dispatch(setTeamDataReload(true))
        })
    }

    const doConvertPendingRequest = _id => {
        dispatch(convertPendingRequest(_id))
        .then(action => {
            dispatch(createNotification({ type: action.payload.messageType, 
                headline: 'Convert Pending Request', 
                message: action.payload.message, 
                timeout: 6000 
            }))
            dispatch(setTeamDataReload(true))
        })
    }

    return (
        <Table striped hover>
            <thead>
                <tr>
                <th>Player</th>
                <th>Team</th>
                <th>Division</th>
                <th>Timestamp</th>
                <th></th>
                <th></th>
                <th></th>
                </tr>
            </thead>
            <tbody>
            {requests.map(t => 
                <tr key={t._id}>
                    <td><Player playerId={t.playerId} firstName={t.firstName} lastName={t.lastName} fullName={t.fullName} avatarUrl={t.playerAvatarUrl} /></td>
                    <td>{t.teamName}</td>
                    <td>{t.division}</td>
                    <td>{moment(t.timestamp).format('M/D/YYYY h:mm:ss A')}</td>
                    <td>
                        <Confirm variant='primary' onConfirm={() => doConvertPendingRequest(t._id)}
                            title={'Email Player -- Incomplete Request'}
                            confirmText='convert entry'
                            body={'Are you sure you want to convert this request for \'' + t.teamName + '\' to a team entry?'}
                            size='sm' buttonIcon='play' />
                    </td>
                    <td>
                        <Confirm variant='info' onConfirm={() => doEmailRePendingRequest(t._id)}
                            title={'Email Player -- Incomplete Request'}
                            confirmText='send email'
                            body={'Are you sure you want to email ' + t.fullName + ' about this request?'}
                            size='sm' buttonIcon='envelope' />
                    </td>
                    <td>
                        <Confirm variant='danger' onConfirm={() => doDeletePendingRequest(t._id)}
                            title={'Delete Team'}
                            confirmText='confirm delete'
                            body={'Are you sure you want to delete \'' + t.teamName + '\''}
                            size='sm' buttonIcon='trash' />
                    </td>
                </tr>
            )}
            </tbody>
        </Table>
    )
}

export default TeamDataPendingRequests
