import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import EditNflPlayoffGames from './EditNflPlayoffGames'
import SeasonSelector from '../SeasonSelector'
import * as s from './EditNflPlayoffGamesContainer.scss'
import { find } from 'lodash'

const EditNflPlayoffGamesContainer = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const seasons = useSelector(state => state.football.seasons)
    const seasonName = params.seasonName || null
    const season = seasons.find(i => i.seasonName === seasonName) || {}

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h2>Edit {season.seasonName} NFL Playoff Games</h2>
                <div className={s.seasonSelector}><SeasonSelector seasons={seasons} season={season} /></div>
            </div>
            {seasonName ?
            <EditNflPlayoffGames seasonName={season.seasonName} />
            : null}
        </div>
    )
}

export default EditNflPlayoffGamesContainer