import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Alert } from 'react-bootstrap'
import { filter, find } from 'lodash'
import { getLeagueScoreboard } from '@severed-links/common.redherrings-reducers/football'
import * as s from './LeagueScoreboard.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import LeagueScoreboardGame from './LeagueScoreboardGame'
import SeasonSelector from '../SeasonSelector'
import WeekSelector from '../WeekSelector'
import { getFootballUrlParams } from '@severed-links/common.redherrings-constants'

const LeagueScoreboard = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const playerId = useSelector(state => state.account._id)
    const leagueScoreboards = useSelector(state => state.football.leagueScoreboards)
    const seasonName = useSelector(state => state.football.seasonName)
    const seasons = useSelector(state => state.football.seasons)
    const week = useSelector(state => state.football.week)
    const c = getFootballUrlParams(params, seasons)
    const sb = find(leagueScoreboards, x => x.seasonId === c.seasonId && x.week === c.week)
    const [isLoading, setLoading] = useState(false)

    useEffect(() => { fetchScoreboard() }, [c.seasonName, c.week])

    const fetchScoreboard = () => {
        if (c.seasonName && c.week) {
            setLoading(true)
            dispatch(getLeagueScoreboard(c.seasonName, c.week))
            .then(() => setLoading(false))
        }
    }

    if (!c.seasonId || !c.seasonName || !c.week) return null
    const myGames = sb && sb.scoreboard && sb.scoreboard.length > 0 ? filter(sb.scoreboard, g => g.playerId1 === playerId || g.playerId2 === playerId) : []
    const games = sb && sb.scoreboard && sb.scoreboard.length > 0 ? filter(sb.scoreboard, g => g.playerId1 !== playerId && g.playerId2 !== playerId) : []
    const hasGames = (games.length > 0 || myGames.length > 0)
    return (
        <div className={s.container}>
            <div className={s.heading}>
                {c.week ? <h2 className={s.title}>{c.seasonName} Week {c.week} Scoreboard</h2> : null}
                <div className={s.controls}>
                    <div className={s.weekSelector}><WeekSelector seasons={seasons} season={c.season} week={'W' + c.week.toString()} /></div>
                    <div className={s.seasonSelector}><SeasonSelector seasons={seasons} season={c.season} /></div>
                </div>
            </div>
            <div className={s.gamesContainer}>
            {myGames && myGames.map(g => 
                <LeagueScoreboardGame seasonName={c.season.seasonName} game={g} isLarge key={g.seasonName + '-week-' + g.week + '-game-' + g.game} />
            )}
            {games && games.map(g => 
                <LeagueScoreboardGame seasonName={c.season.seasonName} game={g} isLarge={false} key={g.seasonName + '-week-' + g.week + '-game-' + g.game} />
            )}
            {!isLoading && !hasGames ?
                <Alert className={s.alert}>No league games scheduled for this week.</Alert>
            : null}
            </div>
        </div>
    )
}

export default LeagueScoreboard
