import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { ListGroup, Button, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './FootballPaymentBalances.scss'
import Modal from '@severed-links/common.modal'
import PlayerListItem from '../../Player/PlayerListItem'
import moment from 'moment-timezone'
import numeral from 'numeral'
import pluralize from 'pluralize'
import { CURRENCY_FORMAT, playerUrl } from '@severed-links/common.redherrings-constants'

const FootballPaymentBalances = ({ balances = [] }) => {

    const [balancePlayerId, setBalancePlayerId] = useState(null)

    if (!balances || !balances.length) return null
    const _showPlayerBalanceModal = !!balancePlayerId
    const _playerBalanceItem = balances.find(i => i.playerId === balancePlayerId) || {}
    return (
        <div className={s.container}>
            <h4>Players with Balances</h4>
            <ListGroup className={s.balancesList}>
            {balances && balances.map(i =>
            <PlayerListItem player={i}
                rightDetail={<div className={s.rightDetail}>
                    <div className={s.item}>
                        <div className={s.heading}>Balance</div>
                        <div className={s.value}>{numeral(i.balance).format('($0,0.00)')}</div>
                    </div>
                </div>}
                onClick={() => setBalancePlayerId(i.playerId)}
                key={`balance-${i.playerId}`} />
            )}
            </ListGroup>
            <Modal heading='Player Balance Detail'
                show={_showPlayerBalanceModal}
                onClose={() => setBalancePlayerId(null)}>
                <div className={s.balanceContainer}>
                <ListGroup className={s.balanceList}>
                    <PlayerListItem player={_playerBalanceItem} variant='info'
                        rightDetail={<span>{numeral(_playerBalanceItem.balance).format(CURRENCY_FORMAT)}</span>} />
                    <ListGroup.Item className={s.balanceItem} variant='secondary'>
                        <div className={s.description}>Teams</div>
                        <div className={s.value}>{_playerBalanceItem.teamCount}</div>
                    </ListGroup.Item>
                    <ListGroup.Item className={s.balanceItem} variant='secondary'>
                        <div className={s.description}>Total Due</div>
                        <div className={s.value}>{numeral(_playerBalanceItem.totalDue).format(CURRENCY_FORMAT)}</div>
                    </ListGroup.Item>
                    {_playerBalanceItem.payments && _playerBalanceItem.payments.map(i => 
                    <PlayerListItem key={`payment-balance-item-${i.txn_id}`}
                        player={i} detail={moment(i.entryDate).format('M/D/YYYY h:mm:ss A')}
                        rightDetail={numeral(i.payment_amount).format('($0,0.00)')} />
                    )}
                    <ListGroup.Item className={s.balanceItem} variant='secondary'>
                        <div className={s.description}>Total Payments</div>
                        <div className={s.value}>{numeral(_playerBalanceItem.totalPayments).format(CURRENCY_FORMAT)}</div>
                    </ListGroup.Item>
                    <ListGroup.Item className={s.balanceItem} variant='secondary'>
                        <div className={s.description}>Player Id</div>
                        <div className={s.value}>{_playerBalanceItem.playerId}</div>
                    </ListGroup.Item>
                </ListGroup>
                </div>
            </Modal>
        </div>
    )
}

export default FootballPaymentBalances