import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import CaptureAdmins from './CaptureAdmins'
import CaptureUpdatePassphrase from './CaptureUpdatePassphrase'
import Logins from '../../Main/Admin/Logins'
import Picker from '@severed-links/common.picker'
import { getActiveContest } from '@severed-links/common.redherrings-reducers/capture'
import { setActiveContest } from '@severed-links/common.redherrings-reducers/captureAdmin'
import * as s from './CaptureAdminDashboard.scss'
import { is } from 'immutable'


const CaptureAdminDashboard = () => {

    const dispatch = useDispatch()
    const contest = useSelector(state => state.capture.contest)
    const contests = useSelector(state => state.captureAdmin.contests || [])
    const activeContestId = useSelector(state => state.capture.activeContestId)
    const [showActiveContestPicker, setShowActiveContestPicker] = useState(false)

    const handleActiveContestSelect = _contestId => {
        setShowActiveContestPicker(false)
        dispatch(setActiveContest(_contestId))
        .then(() => dispatch(getActiveContest()))
    }

    return (
        <div className={s.container}>
            <Row>
                <Col md={6} sm={12} xs={12}>
                    <h2>Capture Contest Administration</h2>
                    {contest && contest._id ? 
                    <div className={s.activeContestContainer}>
                        <h4 className={s.title}>Active contest: {contest.contestName} ({contest.year})</h4>
                        <div className={s.controls}>
                            <Button size='sm' onClick={() => setShowActiveContestPicker(true)}><FontAwesomeIcon name='pencil-alt' /></Button>
                        </div>
                    </div>
                    : null}
                    <CaptureUpdatePassphrase />
                    <CaptureAdmins />
                </Col>
                <Col md={6} sm={12} xs={12}>
                    <Logins isDashboard limit={0} />
                </Col>
            </Row>
            <Picker show={showActiveContestPicker} onClose={() => setShowActiveContestPicker(false)}
                heading={`Set Active Contest`}
                items={contests.filter(i => !i.isDeleted).map(i => ({ ...i, title: i.contestName, value: i._id, icon: 'trophy' }))}
                defaultValue={activeContestId}
                onSelect={_item => handleActiveContestSelect(_item.value)} />
        </div>
    )
}

export default CaptureAdminDashboard