// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CinderellaRegionRoundConfig__container___pcLwz .CinderellaRegionRoundConfig__regionConfigEditYearSelectorContainer___p8_o0{margin-right:50px;margin-top:-60px;width:210px;float:right}`, "",{"version":3,"sources":["webpack://./src/routes/Cinderella/Admin/CinderellaRegionRoundConfig.scss"],"names":[],"mappings":"AACI,4HACI,iBAAA,CACA,gBAAA,CACA,WAAA,CACA,WAAA","sourcesContent":[".container {\n    .regionConfigEditYearSelectorContainer {\n        margin-right: 50px;\n        margin-top: -60px;\n        width: 210px;\n        float: right;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `CinderellaRegionRoundConfig__container___pcLwz`;
export var regionConfigEditYearSelectorContainer = `CinderellaRegionRoundConfig__regionConfigEditYearSelectorContainer___p8_o0`;
export default ___CSS_LOADER_EXPORT___;
