import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './CinderellaRecapPreview.scss'
import Modal from '@severed-links/common.modal'
import RecapArticle from '../Recap/RecapArticle'

const CinderellaRecapPreview = ({ recapId = null, title = '', recapHtml = '', author = {}, lastEdited = null }) => {

    const [show, setShow] = useState(false)

    return (
        <>
        <Button className={s.previewButton} variant='light' onClick={() => setShow(true)}>
            <FontAwesomeIcon name='eye' />
        </Button>
        <Modal show={show} onClose={() => setShow(false)}
            heading={`Recap Preview`} showFooter={false}
            size={'lg'}>
            <div className={s.recapPreviewContainer}>
                <RecapArticle recapId={recapId} title={title} recapHtml={recapHtml} author={author} lastEdited={lastEdited} />
            </div>
        </Modal>
        </>
    )
}

export default CinderellaRecapPreview
