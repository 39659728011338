import React, { useEffect } from 'react'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'

const Instructions = () => {

    return (
        <div>
        <p>We're going to mix things up and change how we do our 
        NCAA bracket this year.  Instead of drawings teams and each 
        player the same teams through the entire tournament, we 
        are going to have a "capture-style" bracket.  Here is 
        how it will work:</p>

        <ol>
            <li><b>Entry</b>.  Each player puts in the entry fee of $2 per team 
            and takes a turn drawing a name.  The number of teams 
            per player will vary.  The total pool will be $128.</li>

            <li><b>Drawing</b>.  The drawn teams will be entered into the standard 
            NCAA bracket format and associated with each player’s name.</li>

            <li><b>Lines</b>.  Each game will have a spread assigned from that day’s 
            ESPN <a href="http://espn.go.com/mens-college-basketball/scoreboard" target="_blank">scoreboard</a></li>

            <li><b>Captures!</b>  Then, for each game, the spread will be taken into 
            account as follows:
                <ol>
                <li>If the favorite wins by more than the spread, the 
                    player who has the favorite moves on with the 
                    favorite into the next round.</li>
                <li>If the underdog wins, the player who has the 
                    underdog moves on with the underdog into the next 
                    round.</li>
                <li><b>Capture!</b>  If the favorite wins, but by the 
                    spread points or less, then the player with the 
                    underdog "captures" the favorite and moves on to 
                    the next round with the favorite team.</li>
                </ol>
            </li>

            <li><b>Prizes</b>.  
                <ol>
                <li>Champion - $50</li>
                <li>Runner-up - $26</li>
                <li>The other two final four teams -$13/ea.</li>
                <li>First capture - $13</li>
                <li>Person with the most captures - $13</li>
                </ol>
            </li>
        </ol>

        <p>Here is an example:</p>

        <p>Charlotte is the favorite and LA Clippers is the 
        underdog.  The point spread is 10.</p>

        <ul>
            <li>If Charlotte (favorite) wins by more than 10, the 
            player who has Charlotte moves on with that team to the 
            next round.</li>

            <li>If LA Clippers (underdog) wins, the player who has 
            the LA Clippers moves on with that team to the next 
            round.</li>

            <li><b>Capture!</b>  If Charlotte (favorite) wins by 10 
            or less, the player who had LA Clippers (underdog) moves 
            on with Charlotte (favorite) to the next round.  The 
            player who originally had Charlotte (favorite) is out.</li>
        </ul>

        <p>If the home team is the favorite, they show the spread 
        as negative points against them (-10 in our example), if 
        the road team is the favorite the home team would show plus 
        points (i.e., add the spread to their score or take it away)</p>
    </div>
    )
}

export default Instructions