import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import ListGroup from 'react-bootstrap/ListGroup'
import Image from 'react-bootstrap/Image'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './OngoingGames.scss'
import moment from 'moment-timezone'
import { ordinal } from '@severed-links/common.redherrings-constants'
import { loadOngoingGames as loadCinderellaOngoingGames } from '@severed-links/common.redherrings-reducers/cinderella'
import { loadOngoingGames as loadCaptureOngoingGames } from '@severed-links/common.redherrings-reducers/capture'
import { pick, orderBy } from 'lodash'

const OngoingGame = ({ status, state, date, isNotStarted, isInProgress, isFinal, clock, displayClock, period, shortDetail, detail, ...props }) => {

    const { time_zone } = useSelector(state => state.account)
    const _teams = orderBy(['home','visitor'].map(i => ({ homeVisitor: i, abbreviation: props[`${i}Abbreviation`], team: props[`${i}`], seed: props[`${i}Seed`], id: props[`${i}Id`], avatarUrl: props[`${i}AvatarUrl`], score: props[`${i}Score`] })), ['score','seed'], ['desc','asc'])
    const periodDisplay = period <= 2 ? `${ordinal(period)}` : `${period > 3 ? `${period - 2}` : ``}OT`
    return (
        <div className={s.gameContainer}>
            {_teams && _teams.map(i => 
                <div className={s.teamContainer + (i.homeVisitor === 'visitor' ? ` ${s.last}` : ``)}
                    key={`team-container-${i.homeVisitor}`}>
                    <div className={s.seed}>{i.seed}</div>
                    <div className={s.avatar}><Image src={i.avatarUrl} /></div>
                    <div className={s.team}>{i.abbreviation}</div>
                    {!isNotStarted ? <div className={s.score}>{i.score}</div> : null}
                </div>
            )}
            <div className={s.status}>
                {isNotStarted ? moment.tz(date, time_zone).format('h:mm A')
                : isFinal || shortDetail === 'Halftime' ? shortDetail
                : `${periodDisplay}${displayClock !== '0:00' ? ` ${displayClock}` : ''}` }
            </div>
        </div>
    )
}

const OngoingGames = ({ isCapture = false }) => {

    const dispatch = useDispatch()
    const { userName } = useSelector(state => state.account)
    const { ongoingGames } = useSelector(state => state[isCapture ? 'capture' : 'cinderella'])
    const _today = moment().tz('America/New_York').startOf('day')
    const _dateKey = _today.format('YYYY-MM-DD')
    const _games = orderBy((ongoingGames || {})[_dateKey] || [], ['isInProgress', 'date'], ['desc', 'asc'])

    useEffect(() => {
        _loadGames()
        const _timerId = setInterval(() => _loadGames(), 120 * 1000)
        return () => clearInterval(_timerId)
    }, [])

    const _loadGames = () => {
        dispatch((isCapture ? loadCaptureOngoingGames : loadCinderellaOngoingGames)(_today))
    }

    if (!_games.length) return null
    return (
        <div className={s.container}>
        {_games && _games.map((i, _index) => <OngoingGame {...i} key={`ongoing-game-${_dateKey}-${i.homeAbbreviation}-${i.visitorAbbreviation}`} />)}
        </div>
    )
}

export default OngoingGames
