import React, { useEffect } from 'react'
import { Card, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './ScoreboardPopularPicks.scss'
import NCAATeam from '../NCAATeam/NCAATeam'
import { take, takeRight } from 'lodash'

const ScoreboardPopularPicks = ({ popular = [], theField = '' }) => {

    return (
        <div className={s.container}>
        {popular.length >= 5 ?
            <Card variant='info' className={s.card}>
                <Card.Header>The Field {theField ? ` (${theField})` : null}</Card.Header>
                <ListGroup variant='flush'>
                {popular && take(popular, 5).map(t =>
                    <ListGroup.Item key={`popular-pick-field-${t.teamId}`} variant='info'
                        className={s.teamContainer}>
                        <div className={s.team}>
                            <NCAATeam key={t.teamId} name={t.teamName} seed={t.seed} 
                                size='lg' points={t.points} isAlive={t.isAlive} 
                                showAvatar avatarUrl={t.avatarUrl}
                                showPoints={true} />
                        </div>
                        <div className={s.picked}><h4>{t.picked}</h4></div>
                    </ListGroup.Item>
                )}
                </ListGroup>
            </Card>
            : null}
            {popular.length > 5 ?
            <Card className={s.card}>
                <Card.Header>The Rest</Card.Header>
                <ListGroup variant='flush'>
                {popular && takeRight(popular, popular.length - 5).map(t =>
                    <ListGroup.Item key={`popular-pick-field-${t.teamId}`} variant='light'
                        className={s.teamContainer}>
                        <div className={s.team}>
                            <NCAATeam key={t.teamId} name={t.teamName} seed={t.seed} 
                                size='lg' points={t.points} isAlive={t.isAlive} 
                                showAvatar avatarUrl={t.avatarUrl}
                                showPoints={true} />
                        </div>
                        <div className={s.picked}><h4>{t.picked}</h4></div>
                    </ListGroup.Item>
                )}
                </ListGroup>
            </Card>
            : null}

        </div>
    )
}

export default ScoreboardPopularPicks