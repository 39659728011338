import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import BaseAuthenticatedMenu from './BaseAuthenticatedMenu'
import { Nav } from 'react-bootstrap'
import { img } from '@severed-links/common.redherrings-constants'
import CloudinaryImage from '../routes/Cloudinary/CloudinaryImage'
import * as s from './Menu.scss'

const MainMenu = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const redHerringsSmallLogo = useSelector(state => state.cloudinary.redHerringsSmallLogo)

    const navbarBrand = (       
        <Link to={{ pathname: '/main' }}>
            <CloudinaryImage publicId={redHerringsSmallLogo} />
        </Link>
    )

    const menuItems = [
        <Nav.Item className={s.item} key={1}><Nav.Link eventKey={1} onClick={() => navigate('/main')}>Pick a Contest</Nav.Link></Nav.Item>,
        <Nav.Item className={s.item} key={2}><Nav.Link eventKey={2} onClick={() => navigate('/main/register-contest')}>Register for a Contest</Nav.Link></Nav.Item>
    ]

    return (
        <BaseAuthenticatedMenu navbarBrand={navbarBrand} 
            menuItems={menuItems} />
    )
}

export default MainMenu
