import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './RecapReactions.scss'
import { getRecapLikes, getRecapLikeTypes, updateRecapLike } from '@severed-links/common.redherrings-reducers/cinderella'
import Modal from '@severed-links/common.modal'
import { first, size, sumBy } from 'lodash'
import PlayerListItem from '../../Player/PlayerListItem'

const ReactionButton = ({ type, icon, variant, title, likeType, count = 0, onClick, isMySelecton = false }) => (
    <Button className={s.reactionButton + (variant ? ` ${s[`${variant}`]}` : ``) + (!count ? ` ${s.isZero}` : '')}
        title={title} onClick={() => onClick(likeType)}>
        <FontAwesomeIcon name={icon} isRegular={!isMySelecton} />
        <div className={s.count}>{count}</div>
    </Button>
)

const RecapReactions = ({ recapId = null }) => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const isAuthenticated = useSelector(state => state.account.isAuthenticated)
    const likeTypes = useSelector(state => state.cinderella.recapLikeTypes)
    const _likes = useSelector(state => state.cinderella.recapLikes[`${recapId}`])
    const [show, setShow] = useState(false)
    const [activeTab, setActiveTab] = useState(false)
    const likes = (_likes || {}).likes || []
    const _reactionTabs = likes.filter(i => i.count)
    const _selectedReactions = activeTab && likes.length ? likes.find(i => i.likeType === activeTab) : {}
    const _selectedPlayers = (_selectedReactions || {}).players || []
    const _totalLikes = sumBy(likes, 'count')
    const _countKey = _reactionTabs.map(i => `${i.type}-${i.count}`).join(',')

    useEffect(() => { isAuthenticated ? dispatch(getRecapLikeTypes()) : null }, [isAuthenticated])
    useEffect(() => { isAuthenticated && likes.length ? setActiveTab((first(likes.filter(i => i.count)) || {}).type || null) : null }, [_countKey])

    useEffect(() => {
        if (recapId) {
            dispatch(getRecapLikes(recapId))
        }
    }, [recapId])

    const handleReaction = _likeType => dispatch(updateRecapLike(recapId, playerId, _likeType))

    if (!_likes) return null
    return (
        <div className={s.container}>
        {likes && likes.map(i =>
        <ReactionButton {...i} onClick={_likeType => handleReaction(_likeType)} key={`recap-reaction-${recapId}-${i.likeType}`}
            isMySelecton={(i.players || []).some(x => x.playerId === playerId)} />
        )}
        {_totalLikes ?
        <Button className={s.reactionButton + ' ' + s.info}
            onClick={() => setShow(true)}><FontAwesomeIcon name='users' />
        </Button>
        : null}

        <Modal show={show} onClose={() => setShow(false)}
            heading={`Reactions`} showFooter={false}>
        <div className={s.reactionUserContainer}>
            <ListGroup className={s.list}>
                <ListGroup.Item className={s.item}>
                {_reactionTabs && _reactionTabs.map(i =>
                <Button className={s.reactionButton + ' ' + s.reactionTab + ' ' + s[`${i.variant}`]}
                    onClick={() => setActiveTab(i.type)}
                    key={`reaction-tab-${i.type}`}>
                    <FontAwesomeIcon name={i.icon} isRegular={i.type !== activeTab} />
                </Button>
                )}
                </ListGroup.Item>
                {_selectedPlayers && _selectedPlayers.map(i => 
                <PlayerListItem player={i} key={`reaction-player-${i.playerId}`} />
                )}
                {!_selectedPlayers || !_selectedPlayers.length ?
                <ListGroup.Item className={s.item}>No reactions located.</ListGroup.Item>
                : null}
            </ListGroup>
        </div>
        </Modal>
        </div>
    )
}

export default RecapReactions
