import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { ListGroup, Button, FormControl, FormLabel, FormGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './BracketBuilderTournamentStartDates.scss'
import { getBracketBuilderTournamentDates, saveBracketBuilderTournamentDates } from '@severed-links/common.redherrings-reducers/cinderellaAdmin'
import Modal from '@severed-links/common.modal'
import Datetime from 'react-datetime'
import { FRIENDLY_SHORT_DATE_FORMAT } from '@severed-links/common.redherrings-constants'
import moment from 'moment-timezone'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'

const BracketBuilderTournamentStartDates = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const bracketBuilderTournamentDates = useSelector(state => state.cinderellaAdmin.bracketBuilderTournamentDates)
    const bracketBuilderTournamentDatesLoaded = useSelector(state => state.cinderellaAdmin.bracketBuilderTournamentDatesLoaded)
    const [showEditor, setShowEditor] = useState(false)
    const [yearToEdit, setYearToEdit] = useState({})

    useEffect(() => { dispatch(getBracketBuilderTournamentDates()) }, [])

    const handleAdd = () => {
        const _year = ((_.maxBy(bracketBuilderTournamentDates, 'year') || {}).year || moment().year()) + 1
        setShowEditor(true)
        handleYearChange(`${_year}`)
    }

    const handleEdit = _item => {
        const { year, startDateDisplay, endDateDisplay, finalDateDisplay } = _item
        setShowEditor(true)
        setYearToEdit({ year: `${_item.year}`, startDate: startDateDisplay, endDate: endDateDisplay, finalDate: finalDateDisplay })
    }

    const handleYearChange = year => {
        const _yearNum = parseInt(year)
        var date = new Date(_yearNum, 2, 1)
        var month_week = 3
        var week_day = 4
        var first_week = moment(date).startOf('month').day(week_day)
        var startDate = moment(first_week).add(7 * (month_week - 1), 'days')
        var endDate = moment(startDate).add(1, 'day')
        var finalDate = moment(startDate).add(18, 'days')
        setYearToEdit({ ...yearToEdit, year, startDate: startDate.format(FRIENDLY_SHORT_DATE_FORMAT), endDate: endDate.format(FRIENDLY_SHORT_DATE_FORMAT), finalDate: finalDate.format(FRIENDLY_SHORT_DATE_FORMAT) })
    }

    const handleDateChange = (dt, fieldName) => {
        var _dateUpdate = { [fieldName]: moment(dt).format(FRIENDLY_SHORT_DATE_FORMAT) }
        if (fieldName === 'startDate') {
            _dateUpdate.endDate = moment(dt).add(1, 'day').format(FRIENDLY_SHORT_DATE_FORMAT)
            _dateUpdate.finalDate = moment(dt).add(18, 'days').format(FRIENDLY_SHORT_DATE_FORMAT)
        }
        setYearToEdit({ ...yearToEdit, ..._dateUpdate })
    }

    const handleSave = () => {
        const _postData = {
            year: parseInt(yearToEdit.year),
            startDate: moment(yearToEdit.startDate, FRIENDLY_SHORT_DATE_FORMAT).format(FRIENDLY_SHORT_DATE_FORMAT),
            endDate: moment(yearToEdit.endDate, FRIENDLY_SHORT_DATE_FORMAT).format(FRIENDLY_SHORT_DATE_FORMAT),
            finalDate: moment(yearToEdit.finalDate, FRIENDLY_SHORT_DATE_FORMAT).format(FRIENDLY_SHORT_DATE_FORMAT),
        }
        dispatch(saveBracketBuilderTournamentDates(_postData))
        .then(action => {
            dispatch(createNotification(action.payload))
            setShowEditor(false)
            dispatch(getBracketBuilderTournamentDates())
        })
    }

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <div className={s.goBack}>
                    <Button variant='light' onClick={() => navigate(-1)}><FontAwesomeIcon name='chevron-left' /></Button>
                </div>
                <h2 className={s.title}>Edit Tournament Round 1 and Finals Dates</h2>
                <div className={s.controls}>
                    <Button variant='primary' onClick={() => handleAdd()}><FontAwesomeIcon name='plus' /></Button>
                </div>
            </div>
            <div>
                <p>The round 1 start and end dates are needed to ping the ESPN scoreboard to get the list of teams entered in a given year's tournament. The final date is needed so the server knows when to stop checking scores.</p>
            </div>
            <div className={s.content}>
            <ListGroup className={s.list}>
            {bracketBuilderTournamentDates && bracketBuilderTournamentDates.map(i =>
            <ListGroup.Item className={s.yearItem} action  key={`tournament-dates-item-${i._id}`}
                onClick={() => handleEdit(i)}>
                <div className={s.yearContent}>
                    <div className={s.year}>{i.year}</div>
                    <div className={s.dates}>
                        <div>Round 1: {i.round1Display}</div>
                        <div>Finals: {moment(i.finalDate).format('MMM D')}</div>
                    </div>
                </div>
            </ListGroup.Item>
            )}
            {!bracketBuilderTournamentDates || !bracketBuilderTournamentDates.length ? <ListGroup.Item>No year items located.</ListGroup.Item> : null}
            </ListGroup>
            </div>

            <Modal heading='Edit year start dates' size={'sm'}
                show={showEditor}
                onClose={() => setShowEditor(false)}
                actionButtonOnClick={() => handleSave()}
                actionButtonText='save dates for year'>
                <div>
                    <FormGroup>
                        <FormLabel>Year</FormLabel>
                        <FormControl value={yearToEdit.year || ''} onChange={e => handleYearChange(e.target.value)} />
                    </FormGroup>
                    {[
                        { title: 'Round 1 start date', fieldName: 'startDate', value: yearToEdit.startDate },
                        { title: 'Round 1 end date', fieldName: 'endDate', value: yearToEdit.endDate },
                        { title: 'Final game date', fieldName: 'finalDate', value: yearToEdit.finalDate },
                    ].map(x => 
                    <FormGroup key={`tournament-dates-editor-${x.fieldName}`}>
                        <FormLabel>{x.title}</FormLabel>
                        <Datetime value={x.value} 
                            dateFormat={FRIENDLY_SHORT_DATE_FORMAT}
                            timeFormat={false} closeOnSelect
                            onChange={dt => handleDateChange(dt, x.fieldName)} />
                    </FormGroup>
                    )}
                </div>
            </Modal>
        </div>
    )
}

export default BracketBuilderTournamentStartDates