import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Button, ListGroup } from 'react-bootstrap'
import * as s from './EliminatorAdminManualPickEntry.scss'
import { getPicks, getManualPlayerEntries, setManualPlayerEntry } from '@severed-links/common.redherrings-reducers/eliminatorAdmin'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { find, findIndex, startsWith, isEqual } from 'lodash'
import NflTeamListItem from '../../Football/NflTeamListItem'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import SeasonSelector from '../../Football/SeasonSelector'
import SeasonModeSelector from '../../Football/SeasonModeSelector'
import WeekSelector from '../../Football/WeekSelector'
import Modal from '@severed-links/common.modal'
import PlayerListItem from '../../Player/PlayerListItem'
import EliminatorAdminManualEntryPickButton from './EliminatorAdminManualEntryPickButton'

const EliminatorAdminManualPickEntry = () => {

    const navigate = useNavigate()
    const params = useParams()
    const dispatch = useDispatch()

    const seasons = useSelector(state => state.eliminator.seasons)
    const seasonName = params.seasonName
    const seasonMode = params.seasonMode
    const week = parseInt(params.week || '0')
    const isPlayoffWeek = (params.seasonMode || '').toLowerCase() === 'postseason'
    const season = (seasons || []).find(i => i.seasonName === seasonName) || {}
    const seasonId = season.seasonId || null

    const [items,setItems] = useState([])
    const [editPlayerEntry, setEditPlayerEntry] = useState(null)

    useEffect(() => { getEliminatorPickItems() }, [seasonId, week, isPlayoffWeek])

    const getEliminatorPickItems = () => {
        if (seasonId && seasonMode && week) {
            dispatch(getPicks(seasonId, seasonMode, week, isPlayoffWeek))
            .then(action => setItems((action.payload || {}).items || []))
        }
    }

    const getEliminatorManualPlayerEntries = _player => {
        dispatch(getManualPlayerEntries(seasonId, seasonMode, week, _player.playerId))
        .then(_action => setEditPlayerEntry({ ..._action.payload, playerId: _player.playerId, pick: _player.pick }))
    }

    const setManualEntryPick = teamId => {
        dispatch(setManualPlayerEntry(seasonId, seasonName, seasonMode, week, editPlayerEntry.playerId, teamId))
        .then(action => {
            dispatch(createNotification({
                type: action.payload.messageType, 
                headline: 'Save Player Pick', 
                message: action.payload.message, 
                timeout: 5000
            }))
            getEliminatorPickItems()
        })
        closePlayerEntryModal()
    }

     const closePlayerEntryModal = () => {
        setEditPlayerEntry(null)
    }

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}>{seasonName} {seasonMode} Week {week} Manual Pick Entry</h2>
                <div className={s.controls}>
                    <div className={s.controlContent}>
                        <div className={s.seasonSelector}><SeasonSelector seasons={seasons} season={season} /></div>
                        <div className={s.seasonModeSelector}><SeasonModeSelector showPreseason={false} seasonMode={seasonMode} /></div>
                        <div className={s.weekSelector}><WeekSelector seasons={seasons} season={season} week={`${(isPlayoffWeek ? 'PW' : 'W')}${week}`} showPlayoffWeeks={true} /></div>
                    </div>
                </div>
            </div>
            <ListGroup className={s.list}>
            {items && items.map(p => 
                <PlayerListItem player={p} key={`manual-entry-${p.playerId}`} 
                    rightDetail={<div className={s.right}>
                        <EliminatorAdminManualEntryPickButton p={p} 
                            variant={!p || !p.teamId ? 'light' : 'info'} 
                            onPlayerSelect={_player => getEliminatorManualPlayerEntries(_player)} />
                        </div>}
                    detail={<div className={s.detail}>
                        <div className={s.detailItem}>{p.emailAddress}</div>
                    </div>} />
            )}
            {!items || !items.length ? <ListGroup.Item>No players located.</ListGroup.Item> : null}
            </ListGroup>

            <Modal heading={`Edit ${seasonName} ${seasonMode} ${(isPlayoffWeek ? 'Playoff ' : '')}Week Pick for ${(editPlayerEntry ? editPlayerEntry.fullName : '')}`}
                size={'lg'}
                show={editPlayerEntry !== null && editPlayerEntry.playerId !== null}
                onClose={() => closePlayerEntryModal()}>
                <div className={s.modalContainer}>
                    <ListGroup className={s.manualPickModalListGroup}>
                        <ListGroup.Item className={s.blankPickItem} variant='danger'
                            action onClick={() => setManualEntryPick(null)}>
                            <FontAwesomeIcon name='trash' className={s.icon} />
                            <div className={s.content}>Delete pick...</div>
                        </ListGroup.Item>
                        {editPlayerEntry && (editPlayerEntry.nflTeamItems || []).map(t =>
                        <NflTeamListItem mascot={t.mascot} city={t.city} key={`manual-eliminator-pick-modal-${t.teamId}`} 
                            onClick={() => setManualEntryPick(t.teamId)}
                            variant={editPlayerEntry.pickedNflTeamIds.indexOf(t.teamId) > -1 ? 'light' : 'info'}
                            disabled={editPlayerEntry.pickedNflTeamIds.indexOf(t.teamId) > -1} />
                        )}
                    </ListGroup>
                </div>
            </Modal>

        </div>
    )
}

export default EliminatorAdminManualPickEntry