import React, { useEffect } from 'react'
import { Button, Table } from 'react-bootstrap'
import * as s from './RecapPossibilities.scss'

const RecapPossibilities = ({ teams = 0, picks = 0, totalDisplay = '' }) => {

    const _items = [
        { title: 'Teams', value: teams },
        { title: 'Picks', value: picks },
        { title: 'Possibilities', value: totalDisplay },
    ].filter(i => teams && picks && i.value)
    return (
        _items.length ?
        <div className={s.container}>
            <h4>Possibilities</h4>
            <div className={s.itemsContainer}>
            {_items.map(i => 
                <div className={s.item} key={`poss-item-${i.title}`}>
                    <div className={s.heading}>{i.title}</div>
                    <div className={s.value}>{i.value}</div>
                </div>
            )}
            </div>
        </div>
        : null
    )
}

export default RecapPossibilities
