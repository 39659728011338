import React, { useEffect } from 'react'
import { ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './ScoreboardListGroup.scss'
import ScoreboardListItem from './ScoreboardListItem'
import ScoreboardListHeader from './ScoreboardListHeader'
import { some } from 'lodash'

const ScoreboardListGroup = ({ rows = [], yourItems = [], afterDeadline = false, teams = [], addTeamToFilter = () => void(0), mooseHunter = false }) => {

    return (
        <div className={s.container}>
            <ListGroup className={s.list}>
                <ScoreboardListHeader className={s.header} afterDeadline={afterDeadline} />
                {rows && rows.map((r, index) =>
                <ScoreboardListItem item={r} 
                    showRank={index === 0 || rows[index].rank !== rows[index - 1].rank}
                    isYourEntry={some(yourItems, i => i._id === r.entryId)}
                    afterDeadline={afterDeadline} 
                    teams={teams}
                    addTeamToFilter={addTeamToFilter}
                    key={`scoreboard-list-item-${r.order}-${r.playerId}-${r.entryId}-${index}`} />
                )}
                {!rows || !rows.length ? 
                <ListGroup.Item>No entries located.</ListGroup.Item>
                : null}
            </ListGroup>
        </div>
    )
}

export default ScoreboardListGroup
