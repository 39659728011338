// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 575px){.RecapView__container___YFphn .RecapView__someNumbersHeading___HT0hU{margin-top:2rem 0px 1rem 0px}}`, "",{"version":3,"sources":["webpack://./src/routes/Cinderella/Recap/RecapView.scss"],"names":[],"mappings":"AAKI,qCACI,qEACI,4BAAA,CAAA","sourcesContent":["@use '../../../styles/variables/colors' as colors;\n@use '../../../styles/variables/breakpoints' as breakpoints;\n\n.container {\n\n    @media screen and (max-width: breakpoints.$screen-xs-max) {\n        .someNumbersHeading {\n            margin-top: 2rem 0px 1rem 0px;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `RecapView__container___YFphn`;
export var someNumbersHeading = `RecapView__someNumbersHeading___HT0hU`;
export default ___CSS_LOADER_EXPORT___;
