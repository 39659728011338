import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import { startsWith } from 'lodash'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as styles from './SeasonSchedule.scss'

import PlayerTeamListItem from '../PlayerTeamListItem'

const SeasonSchedule = ({ seasonName = '', schedule = [], week = 0 }) => {

    const navigate = useNavigate()
    const getMatchupLink = s => `/football/regular-season/match-up/${s.seasonName}/week/${s.week}/game/${s.game}/${(teamName || '').toLowerCase()}-vs-${(s.oppTeamName || '').toLowerCase()}`

    const sched = schedule
    return (
        <div>
            <h4>Season Schedule</h4>
            <ListGroup className={styles.list}>
            {sched && sched.map(s => 
                <PlayerTeamListItem key={`season-schedule-${seasonName}-${s.week}-${s.oppId}`} 
                    variant={startsWith(s.displayResult, 'Won') ? 'success' : startsWith(s.displayResult, 'Lost') ? 'danger' : null}
                    size='sm'
                    miniHeader={<div>Week {s.week}</div>}
                    teamName={s.oppTeamName} teamNameRaw={s.oppTeamName}
                    teamId={s.oppId}
                    right={<div className={styles.result} onClick={e => e.stopPropagation()}>
                        <div onClick={() => navigate(getMatchupLink(s))}>
                        {s.displayResult}
                        {' '}
                        {startsWith(s.displayResult, 'Won') ? <FontAwesomeIcon name='check' fixedWidth /> : null}
                        {startsWith(s.displayResult, 'Lost') ? <FontAwesomeIcon name='times' fixedWidth /> : null}
                        </div>
                    </div>}
                    avatarUrl={s.oppAvatarUrl} />
            )}
            {!sched || sched.length === 0 ? <ListGroup.Item>No matchups located.</ListGroup.Item> : null}
            </ListGroup>
        </div>
    )
}

export default SeasonSchedule