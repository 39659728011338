import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col, Nav, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getGameSpreads, getGameSpreadsForRound, saveTournamentUpdates, forceLineGameTimeUpdate } from '@severed-links/common.redherrings-reducers/captureAdmin'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { filter, find } from 'lodash'
import CaptureAdminGameEditor from './CaptureAdminGameEditor'
import moment from 'moment-timezone'
import { setDashboardTeam } from '@severed-links/common.redherrings-reducers/football'
import * as s from './CaptureGameSpreads.scss'
import CinderellaBadGameDataAlert from '../../Cinderella/Admin/CinderellaBadGameDataAlert'

const CaptureGameSpreads = ({ showCaptureForceUpdate = false }) => {

    const dispatch = useDispatch()
    const params = useParams()
    const gameSpreads = useSelector(state => state.captureAdmin.gameSpreads)
    const [round, setRound] = useState(1)
    const [updates, setUpdates] = useState([])
    const [isSaving, setSaving] = useState(false)
    const [isForcingUpdate, setForcingUpdate] = useState(false)
    const year = parseInt(params.year || '0')

    useEffect(() => { loadData() }, [year])
    useEffect(() => { dispatch(getGameSpreadsForRound(year, round)) }, [year, round])

    const saveUpdates = () => {
        setSaving(true)
        dispatch(saveTournamentUpdates(updates))
        .then(action => { 
            dispatch(createNotification({ message: action.payload.message, headline: action.payload.subject, type: action.payload.messageType, timeout: 2000 }))
            setUpdates([])
            setSaving(false)
            loadGames()
        })
    }

    const loadData = () => {
        dispatch(getGameSpreads(year))
        .then(() => loadGames())
    }

    const loadGames = () => dispatch(getGameSpreadsForRound(year, round))

    const handleRoundChange = _round => setRound(_round)

    const saveGameTime = (game, editGameTime) => {
        const data = { year: game.year, game: game.game, data: 'gameTime', values: [{ teamId: null, value: moment(editGameTime).utc().toISOString() } ] }
        var _updates = [...updates]
        _updates.push(data)
        setUpdates(_updates)
    }

    const handleForceUpdate = () => {
        setForcingUpdate(true)
        dispatch(forceLineGameTimeUpdate(year, round))
        .then(() => {
            setForcingUpdate(false)
            setTimeout(() => {
                loadData()
                loadGames()
            }, 5000)
        })
    }

    const saveScores = (game, scores) => {
        if (scores && scores.length === 2) {
            const data = { 
                year: game.year, 
                game: game.game, 
                data: 'scores', 
                values: [
                    { teamId: scores[0].teamId, value: scores[0].score },
                    { teamId: scores[1].teamId, value: scores[1].score }
                ] 
            }
    
            var _updates = [...updates]
            _updates.push(data)
            setUpdates(_updates)
        }
    }

    const saveLineAndFavorite = (game, line, favoriteTeam) => {
        const data1 = { year: game.year, game: game.game, data: 'line', values: [{ teamId: null, value: line.toString() } ] }
        const data2 = { year: game.year, game: game.game, data: 'favoriteTeamId', values: [{ teamId: favoriteTeam.teamId, value: favoriteTeam.teamId.toString() } ] }

        var _updates = [...updates]
        _updates.push(data1)
        _updates.push(data2)
        setUpdates(_updates)        
    }

    if (!gameSpreads) return null
    const { regions, rounds, games } = gameSpreads
    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <h2>     
                        {showCaptureForceUpdate ? 
                            <Button variant='light' style={{ float: 'right' }} onClick={() => handleForceUpdate()}>
                                <FontAwesomeIcon name='rotate' spin={isForcingUpdate} /> {!isForcingUpdate ? 'force gametime/line update' : 'updating gametimes/lines...'}
                            </Button> 
                        : null}
                        Edit Game Times, Scores, Lines
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {updates.length > 0 ? 
                        <Button variant='primary' style={{ float: 'right' }} disabled={isSaving} onClick={() => saveUpdates()}>
                        {isSaving ? 
                            <span><FontAwesomeIcon name='rotate' spin={isSaving} /> saving...</span> 
                        :
                            <span><FontAwesomeIcon name='check' /> save updates</span> 
                        }
                        </Button>
                    : null}
                    <Nav variant="pills" activeKey={round} onSelect={e => handleRoundChange(e)} onClick={e => e.stopPropagation()}>
                    {rounds && filter(rounds, r => r.round <= 6).map(r =>
                        <Nav.Item key={`round-${r._id}`}><Nav.Link eventKey={r.round}>{r.roundName}</Nav.Link></Nav.Item>
                    )}
                    </Nav>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                <CinderellaBadGameDataAlert showBadGameTimes showBadLines games={games} round={round} />
                {round <= 4 ? 
                    <div>
                    {regions && regions.map(region => 
                        <div key={`region-${region._id}`}>
                            <Row>
                                <Col xs={12}>
                                <h4 className={s.regionName}>{region.regionName} Region</h4>
                                <Row>
                                {games && filter(games, { region: region.region }).map(regionGames =>
                                    regionGames.games && regionGames.games.map(g =>
                                        <Col xs={12} sm={6} md={3} key={`region-game-item-${g.region}-${g.game}`}>
                                            <CaptureAdminGameEditor game={g} 
                                                saveGameTime={saveGameTime} 
                                                showGameTime showLine
                                                saveScores={saveScores}
                                                saveLineAndFavorite={saveLineAndFavorite} />
                                        </Col>
                                    )
                                )}
                                </Row>
                            </Col>
                        </Row>
                        </div>
                    )}
                    </div>
                :
                    <Row key={'finals' + round}>
                        <Col xs={12} sm={6} md={3}>
                        {games && games.map(regionGames =>
                            <div key={`region-div-item-${regionGames._id}`}>
                            {regionGames.games && regionGames.games.map(g =>
                                <div key={g._id}>
                                    <CaptureAdminGameEditor game={g} 
                                        saveGameTime={saveGameTime} 
                                        showGameTime showLine
                                        saveScores={saveScores}
                                        saveLineAndFavorite={saveLineAndFavorite} />
                                </div>
                            )}
                            </div>
                        )}
                        </Col>
                    </Row>
                }
                </Col>
            </Row>
        </div>
    )
}

export default CaptureGameSpreads