import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './EliminatorWinners.scss'
import { playerUrl } from '@severed-links/common.redherrings-constants'

const EliminatorWinnerAvatars = ({ isActiveSeason, hasWinner, hasTie, seasonName, winners, ...props }) => (
    (hasWinner || hasTie) && winners && winners.length ?
    <Card.Body className={s.winnerAvatarContainer}>
    {winners.map(i =>
    <Image className={s.avatar + ' ' + s[`avatar${winners.length <= 2 ? `${winners.length}` : '3'}`]} 
        src={playerUrl(i.avatarUrl)}
        key={`winner-avatar-${seasonName}-${i.playerId}`} />
    )}
    </Card.Body>
    : null 
)

export default EliminatorWinnerAvatars