import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, FormGroup, FormControl, FormLabel, Button, FormCheck } from 'react-bootstrap'
import * as s from './TeamDataAddNewTeam.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import moment from 'moment-timezone'
import { some, trimEnd, find, startsWith, isEqual } from 'lodash'
import Confirm from '@severed-links/common.confirm'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { img } from '@severed-links/common.redherrings-constants'
import { saveNewTeam, setTeamDataReload, setNewTeamDataCloseOnSave } from '@severed-links/common.redherrings-reducers/footballAdmin'

const TeamDataAddNewTeam = ({ players = [], divisions = [], seasonId = null, seasonName = '' }) => {

    const dispatch = useDispatch()
    const [teamName, setTeamName] = useState('')
    const [division, setDivision] = useState('')
    const [playerId, setPlayerId] = useState(null)

    useEffect(() => { initializeState() }, [seasonId])

    const initializeState = () => {
        setTeamName('')
        setDivision('')
        setPlayerId(null)
    }

    const saveTeam = () => {
        var teamToSave = { 
            newTeamSeasonId: seasonId,
            newTeamPlayerId: playerId,
            newTeamDivision: division,
            newTeamName: teamName
        }
        dispatch(saveNewTeam(teamToSave))
        .then(action => {
            dispatch(createNotification({ type: action.payload.messageType, 
                headline: 'Save Team Data', 
                message: action.payload.message, 
                timeout: 6000 
            }))
            dispatch(setTeamDataReload(true))
            dispatch(setNewTeamDataCloseOnSave(true))
        })
    }

    return (
    <div className={s.container}>
            <FormGroup className={s.formGroup}>
                <FormLabel>Team name</FormLabel>
                <FormControl name='teamName' autoCapitalize='words' 
                    value={teamName} onChange={e => setTeamName(e.target.value)} />
            </FormGroup>

            <FormGroup className={s.formGroup}>
                <FormLabel>Division</FormLabel>
                <FormControl as='select' name='division' 
                    value={division} onChange={e => setDivision(e.target.value)}>
                {divisions && divisions.map(d =>
                    <option key={d.value} value={d.value}>{d.text}</option>
                )}
                </FormControl>
            </FormGroup>

            <FormGroup className={s.formGroup}>
                <FormLabel>Player</FormLabel>
                <FormControl as='select' name='playerId' value={playerId} onChange={e => setPlayerId(e.target.value)}>
                {players && players.map(d =>
                    <option key={d.value} value={d.value}>{d.text}</option>
                )}
                </FormControl>
            </FormGroup>

            <FormGroup className={s.formGroup + ' ' + s.saveButton}>
                <Confirm variant='primary' onConfirm={() => saveTeam()}
                    title={`Save New ${seasonName} Team`}
                    confirmText='confirm save'
                    body={`Are you sure you want to add "${teamName}"?`}
                    style={{ float: 'right' }} disabled={!seasonId || !division || !playerId || !teamName} 
                    buttonIcon='check' buttonText='create new team' />
            </FormGroup>
        </div>
    )
}

export default TeamDataAddNewTeam
