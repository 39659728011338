import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useMatch } from 'react-router'
import { ListGroup, Table, Button, Badge } from 'react-bootstrap'
import * as s from './Logins.scss'
import { getLoginInfo as getCaptureLoginInfo, getLoginInfo } from '@severed-links/common.redherrings-reducers/captureAdmin'
import { getLoginInfo as getCinderellaLoginInfo } from '@severed-links/common.redherrings-reducers/cinderellaAdmin'
import { getLoginInfo as getEliminatorLoginInfo } from '@severed-links/common.redherrings-reducers/eliminatorAdmin'
import { getLoginInfo as getFootballLoginInfo } from '@severed-links/common.redherrings-reducers/footballAdmin'
import { getLoginInfo as getOscarsLoginInfo } from '@severed-links/common.redherrings-reducers/oscarsAdmin'
import { take, find } from 'lodash'
import Player from '../../Player/Player'
import PlayerListItem from '../../Player/PlayerListItem'
import moment from 'moment-timezone'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { manageUsersPath } from '@severed-links/common.redherrings-constants'

const LoginDetail = ({ lastLogin, numLogins }) => (
    <div title={`${numLogins} ${(numLogins !== 1 ? 'logins' : 'login')}, last login: ${moment(lastLogin).format('M/D/YYYY h:mm A')}`}>
        <FontAwesomeIcon name='calendar' />
        {' '}
        {lastLogin && numLogins ? moment(lastLogin).fromNow() : 'Never logged in'}
    </div>
)

const CinderellaNewItemBadge = ({ lastItem, app }) => {
    if (app !== 'cinderella' || !lastItem || moment().diff(moment(lastItem + '+00:00'), 'minutes') > 5) return null
    return (
        <Badge style={{ margin: '0px 10px' }} bg='info'>New entry!</Badge>
    )
}

const ManageUsersButton = ({ navigate, app }) => (
    <Button variant='light' size='sm' className={s.manageUsersButton}
        onClick={() => navigate(manageUsersPath(app))}>
        <FontAwesomeIcon name='user' />
        {' '}
        <span className='d-none d-md-inline'>manage user accounts</span>
    </Button>
)

const Logins = ({ isDashboard = false, limit = 10 }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const isAuthenticated = useSelector(state => state.account.isAuthenticated)
    const captureLoginInfo = useSelector(state => state.captureAdmin.loginInfo)
    const cinderellaLoginInfo = useSelector(state => state.cinderellaAdmin.loginInfo)
    const eliminatorLoginInfo = useSelector(state => state.eliminatorAdmin.loginInfo)
    const footballLoginInfo = useSelector(state => state.footballAdmin.loginInfo)
    const oscarsLoginInfo = useSelector(state => state.oscarsAdmin.loginInfo)
    const _match = useMatch(`/:appName/admin/*`)
    const app = _match ? (_match.params.appName || '').toLowerCase() : ''

    useEffect(() => { 
        if (app && isAuthenticated) {
            getLoginInfo()
        }
    }, [app, isAuthenticated])

    const getLoginInfo = () => {
        switch (app) {
            case 'capture': 
                dispatch(getCaptureLoginInfo())
                break
            case 'cinderella': 
                dispatch(getCinderellaLoginInfo())
                break
            case 'eliminator': 
                dispatch(getEliminatorLoginInfo())
                break
            case 'football': 
                dispatch(getFootballLoginInfo())
                break
            case 'oscars': 
                dispatch(getOscarsLoginInfo())
                break
            default: 
                break
        }
    }

    const doEditUser = player => navigate(manageUsersPath(app, player.lastName, player.playerId))

    var loginInfo = []
    switch (app) {
        case 'capture': 
            loginInfo = captureLoginInfo
            break
        case 'cinderella': 
            loginInfo = cinderellaLoginInfo
            break
        case 'eliminator': 
            loginInfo = eliminatorLoginInfo
            break
        case 'football': 
            loginInfo = footballLoginInfo
            break
        case 'oscars': 
            loginInfo = oscarsLoginInfo
            break
        default: 
            loginInfo = []
            break
    }
    if (loginInfo && isDashboard && limit && loginInfo.length > limit) {
        loginInfo = take(loginInfo, limit)
    }
    return (
        <div className={s.container}>
            {!isDashboard ? 
                <div>
                    <ManageUsersButton app={app} navigate={navigate} />
                    <h2>Logins</h2> 
                </div>
            : null}
            {isDashboard ? 
            <>
            <div className={s.headingContainer}>
                <h4 className={s.title}>Recent Logins</h4>
                <div className={s.controls}>
                    <ManageUsersButton app={app} navigate={navigate} />
                </div>
            </div>
            <ListGroup>
            {loginInfo && loginInfo.map((p, index) => (
                <PlayerListItem player={p} action
                    onClick={() => doEditUser(p)}
                    rightDetail={<>
                        <CinderellaNewItemBadge app={app} lastItem={p.lastItem} />
                        <LoginDetail numLogins={p.numLogins} lastLogin={p.lastLogin} />
                    </>}
                    key={`${app}-login-small-${p._id}-${index}`} />
            ))}
            </ListGroup>
            </>
            :
            <Table striped hover>
                <thead>
                    <tr>
                        <th>Player</th>
                        <th>Username</th>
                        <th>Email</th>
                        <th>
                            Last Login
                        </th>
                        <th className='text-end'>Logins</th>
                        <th>Last Contest Login</th>
                    </tr>
                </thead>
                <tbody>
                    {loginInfo && loginInfo.map((p,index) => 
                    <tr key={`${app}-login-small-${p._id}-${index}`} onClick={() => doEditUser(p)}>
                        <td><Player playerId={p.playerId} firstName={p.firstName} lastName={p.lastName} 
                                fullName={p.fullName} avatarUrl={p.avatarUrl} />
                            {' '}
                            <CinderellaNewItemBadge app={app} lastItem={p.lastItem} />
                        </td>
                        <td>{p.userName}</td>
                        <td>{p.emailAddress}</td>
                        <td>
                            {p.lastLogin ? moment(p.lastLogin).format('M/D/YYYY h:mm A') : ''}
                        </td>
                        <td className='text-end'>{p.numLogins}</td>
                        <td>
                            {p.lastLoginRole}
                        </td>
                    </tr>
                    )}
                </tbody>
            </Table>
            }
        {isDashboard ? 
        <Button variant='light' size='sm' style={{ float: 'right' }}
            onClick={() => navigate(`/${app}/admin/logins`)}>
            see more
            {' '}
            <FontAwesomeIcon name='chevron-right' />                                                 
        </Button> 
    : null}
    </div>
    )
}

export default Logins