import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Row, Col, Button, ButtonGroup, DropdownButton, Dropdown, 
    ListGroup, Table, Card } from 'react-bootstrap'
import KeyHandler, { KEYDOWN } from 'react-key-handler'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import moment from 'moment-timezone'
import { filter as lodashFilter, reject, find, findIndex, 
    some, take, drop, keys, intersection, keysIn, countBy,
    range, last } from 'lodash'
import { getScoreboard, setScoreboardConfig } from '@severed-links/common.redherrings-reducers/cinderella'
import ScoreboardKeywordFilter from './ScoreboardKeywordFilter'
import ScoreboardTitleEntryInfo from './ScoreboardTitleEntryInfo'
import ScoreboardTeamFilter from './ScoreboardTeamFilter'
import * as s from './Scoreboard.scss'
import RedHerringsPagination from '../../../components/RedHerringsPagination'
import Modal from '@severed-links/common.modal'
import ScoreboardPopularPicks from './ScoreboardPopularPicks'
import ScoreboardBestPossiblePick from './ScoreboardBestPossiblePick'
import ScoreboardButtonToolbar from './ScoreboardButtonToolbar'
import ScoreboardListGroup from './ScoreboardListGroup'
import ScoreboardLastUpdate from './ScoreboardLastUpdate'
import pluralize from 'pluralize'
import queryString from 'query-string'
import { useWindowWidth } from '@react-hook/window-size'

const Scoreboard = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const year = parseInt(params.year || '0')
    const page = parseInt(params.page || '1')
    const { _id: playerId } = useSelector(state => state.account)
    const { scoreboardParams, pageLength } = useSelector(state => state.cinderella)
    const _scoreboard = useSelector(state => state.cinderella.scoreboards[`${year}`]) || {}
    const { isFetching, scoreboard, bestPossible, popular, popularScore, popularRank, 
        popularOrdinal, availableScoreboards, teams, numPicks, lastUpdate, pickDeadline } = _scoreboard
    const yourItems = (scoreboard || []).filter(i => `${i.playerId}` === `${playerId}`)
    const afterDeadline = moment().isAfter(moment(pickDeadline), 'second') || false
    const [showPopularPicks, setShowPopularPicks] = useState(false)
    const [showBestPossiblePick, setShowBestPossiblePick] = useState(false)
    const [minimumTeamsLeft, setMinimumTeamsLeft] = useState(0)
    const [teamsLeft, setTeamsLeft] = useState([])

    useEffect(() => {
        const _teamsLeftMap = countBy(scoreboard, 'teamsLeft')
        const _teamsLeft = range(numPicks, -1, -1).map(i => ({ key: `teams-left-${i}`, teamsLeft: i, count: _teamsLeftMap[`${i}`] || 0 }))
        const _minimumTeamsLeft = (last(_teamsLeft.filter(i => i.count)) || {}).teamsLeft || 0
        setTeamsLeft(_teamsLeft)
        setMinimumTeamsLeft(_minimumTeamsLeft)
    }, [lastUpdate, numPicks])

    const getFilteredScoreboard = () => {
        var filteredScoreboard = (scoreboard || [])
            .filter(i => 
                (!mooseHunter && filterText ? ((i.fullName || '').toLowerCase().indexOf((filterText || '').toLowerCase()) > -1 || (i.entryName || '').toLowerCase().indexOf((filterText || '').toLowerCase()) > -1) : true)
                &&
                (!mooseHunter && filterTeams.length ? (intersection(i.entryTeams.map(x => x.teamId), filterTeams.map(x => x._id))).length === filterTeams.length : true)
                && 
                (mooseHunter ? (i.teamsRemaining === minimumTeamsLeft) : true)
            )
        const totalPages = Math.ceil(filteredScoreboard.length / pageLength)
        const items = take(drop(filteredScoreboard, (page - 1) * pageLength), pageLength)
        return { scoreboard, filteredScoreboard, items, year, page, pageLength, totalPages }
    }

    const parseQueryString = () => {
        const _parsed = queryString.parse(location.search) || {}
        const _response = {
            filterText: _parsed.q || '',
            filterTeams: (teams || []).filter(i => (_parsed.t || '').toLowerCase().split(',').includes((i.teamName || '').toLowerCase())),
            mooseHunter: (_parsed.m || '') === '1'
        }
        return _response
    }

    const { filterText, filterTeams, mooseHunter } = parseQueryString()
    const { items, totalPages, filteredScoreboard } = getFilteredScoreboard()
    const showYourItemRankings = !filterText && filterTeams.length === 0 && !mooseHunter

    const _width = useWindowWidth()
    const _siblingPagesRange = _width <= 400 ? 0 : 1
    const _boundaryPagesRange = _width <= 400 ? 0 : _width <= 575 ? 1 : 2

    useEffect(() => { dispatch(getScoreboard(year)) }, [year])
    useEffect(() => {
        if (totalPages) {
            if (mooseHunter) {
                changePageNumber(totalPages)
            } else {
                changePageNumber(1)
            }
        }
    }, [totalPages, mooseHunter, minimumTeamsLeft])

    const changePageNumber = (_pageNumber, _request = {}) => {
        const { search } = location
        _request = { filterText, filterTeams, mooseHunter, ..._request }
        const _newSearchResponse = {
            ...(_request.filterText ? { q: _request.filterText } : {}),
            ...(_request.filterTeams && _request.filterTeams.length ? { t: _request.filterTeams.map(i => (i.teamName || '').toLowerCase()).join(',') } : {}),
            ...(_request.mooseHunter ? { m: 1 } : {}),
        }
        const _newSearch = `${keys(_newSearchResponse).length ? `?${queryString.stringify(_newSearchResponse)}` : ''}`
        const _newUrl = `/cinderella/scoreboard/${year}/page/${_pageNumber}`
        navigate(`${_newUrl}${_newSearch}`)
    }

    const goToEntry = entryId => {
        const _index = filteredScoreboard.findIndex(i => i._id === entryId)
        if (_index > -1) {
            const _newPageNumber = Math.ceil(_index / pageLength)
            changePageNumber(_newPageNumber)
        }
    }

    const handleKeyPress = (e, offset) => {
        if (totalPages && page + offset >= 1 &&
            page + offset <= totalPages) {
                changePageNumber(page + offset)
        }
        e.preventDefault()
    }

    const updateTextFilter = e => {
        if (e.target.value) {
            changePageNumber(1, { filterText: e.target.value })
        } else {
            clearTextFilter()
        }
    }

    const clearTextFilter = e => changePageNumber(1, { filterText: '' })

    const toggleMooseHunter = () => changePageNumber(1, { filterText: '', filterTeams: [], mooseHunter: !mooseHunter })

    const clearTeamFilter = () => changePageNumber(1, { filterTeams: [] })

    const removeTeamFromFilter = team => {
        var _filterTeams = reject(filterTeams, i => i.teamId === team.teamId)
        changePageNumber(1, { filterTeams: _filterTeams })
    }

    const addTeamToFilter = team => {
        var _filterTeams = [...reject(filterTeams, i => i.teamId === team.teamId), team]
        changePageNumber(1, { filterTeams: _filterTeams })
    }

    const theFieldData = () => {
        return popularScore >= 0 ? 
            '' + pluralize('point', popularScore, true) + 
            ', ' + popularOrdinal + ' place' : ''
    }

    return (
        <div className={s.scoreboardContainer}>

            <Row className={s.titleRowContainer}>
                <Col className={s.titleContainer}>
                    <h2 className={s.title}>{year} Scoreboard</h2>
                    {isFetching ? 
                    <FontAwesomeIcon name='circle-notch' size='2x'
                        spin={isFetching} className={s.spinnerIcon} />
                    : null}
                </Col>
                <Col className={s.buttonGroupContainer}>
                    <ScoreboardButtonToolbar year={year}
                        availableScoreboards={availableScoreboards} 
                        showBestPossiblePick={showBestPossiblePick}
                        showPopularPicks={showPopularPicks}
                        onClickBestPossiblePick={() => setShowBestPossiblePick(true)} 
                        onClickPopularPicks={() => setShowPopularPicks(true)}
                        onClickYearItem={selectedYear => navigate(`/cinderella/scoreboard/${selectedYear}/page/1`)}/>
                </Col>
            </Row>
            <Row>
                <Col>
                    <div className={s.paginatorContainer}>
                        {totalPages ? 
                        <RedHerringsPagination currentPage={Math.min(page,totalPages)}
                            totalPages={totalPages} 
                            boundaryPagesRange={_boundaryPagesRange}
                            siblingPagesRange={_siblingPagesRange}
                            onChange={changePageNumber} />
                        : null}
                    </div>                        
                </Col>
            </Row>
            <Row className={s.filtersContainer}>
                <Col>
                    {!isFetching ?
                    <ScoreboardTitleEntryInfo totalEntries={(scoreboard || []).length} 
                        yourItems={yourItems} show={showYourItemRankings}
                        goToEntry={goToEntry} />
                    : null}
                </Col>

                {!isFetching && afterDeadline ?
                <Col>
                    <ScoreboardTeamFilter filterTeams={filterTeams}
                        ncaaTeams={teams} 
                        clearTeamFilter={clearTeamFilter}
                        removeTeamFromFilter={removeTeamFromFilter} />
                </Col>
                : null}

                <Col>
                    {!isFetching ?
                    <ScoreboardKeywordFilter 
                        show={(scoreboard || []).length > 0}
                        filter={filterText} 
                        showMooseHunter={mooseHunter}
                        teamsLeft={teamsLeft}
                        minimumTeamsLeft={minimumTeamsLeft}
                        onSetMinimumTeamsLeft={_count => setMinimumTeamsLeft(_count)}
                        toggleMooseHunter={toggleMooseHunter}
                        clearTextFilter={clearTextFilter}
                        updateTextFilter={updateTextFilter} />
                    : null}
                </Col>

            </Row>
            <Row>
                <Col xs={12}>
                    <ScoreboardListGroup rows={items} 
                        yourItems={yourItems}
                        afterDeadline={afterDeadline} 
                        teams={teams} mooseHunter={mooseHunter}
                        addTeamToFilter={addTeamToFilter} />
                </Col>
            </Row>

            <Modal heading='Best Possible Pick'
                show={showBestPossiblePick} showFooter={false}
                onClose={() => setShowBestPossiblePick(false)}>
                <ScoreboardBestPossiblePick bestPossible={bestPossible} />
            </Modal>

            <Modal heading='Most Popular Picks'
                show={showPopularPicks} showFooter={false}
                onClose={() => setShowPopularPicks(false)}>
                <ScoreboardPopularPicks theField={theFieldData()}
                    popular={popular} />
            </Modal>

            <KeyHandler keyEventName={KEYDOWN} keyValue='ArrowRight' onKeyHandle={(e, offset) => handleKeyPress(e, 1)} />
            <KeyHandler keyEventName={KEYDOWN} keyValue='ArrowLeft' onKeyHandle={(e) => handleKeyPress(e, -1)} />
            <KeyHandler keyEventName={KEYDOWN} keyValue='PageDown' onKeyHandle={(e, offset) => handleKeyPress(e, 1)} />
            <KeyHandler keyEventName={KEYDOWN} keyValue='PageUp' onKeyHandle={(e) => handleKeyPress(e, -1)} />

            <div style={{ marginTop: '1.5rem', textAlign: 'right', fontSize: '12px' }}>
                Last updated: <ScoreboardLastUpdate lastUpdate={lastUpdate} />
            </div>
        </div>
    )
}

export default Scoreboard