import React, { useEffect, useLayoutEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import BaseAuthenticatedMenu from './BaseAuthenticatedMenu'
import { Nav } from 'react-bootstrap'
import { img } from '@severed-links/common.redherrings-constants'
import CloudinaryImage from '../routes/Cloudinary/CloudinaryImage'
import { VisibleOnlyToEliminatorAdmin } from '../components/Authorization'
import { VisibleBeforeDate } from '../components/VisibilityBeforeAfterDate'
import { getEliminatorDetails, getSeasons, getNflTeams, getMainPageData, getStandings } from '@severed-links/common.redherrings-reducers/eliminator'
import moment from 'moment-timezone'
import * as s from './Menu.scss'
import { isEqual } from 'lodash'

const EliminatorMenu = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const isAuthenticated = useSelector(state => state.account.isAuthenticated)
    const seasons = useSelector(state => state.eliminator.seasons)
    const seasonId = useSelector(state => state.eliminator.seasonId)
    const seasonName = useSelector(state => state.eliminator.seasonName)
    const seasonMode = useSelector(state => state.eliminator.seasonMode)
    const week = useSelector(state => state.eliminator.week)
    const playoffWeek = useSelector(state => state.eliminator.playoffWeek)
    const pickDeadline = useSelector(state => state.eliminator.pickDeadline)
    const playoffPickDeadline = useSelector(state => state.eliminator.playoffPickDeadline)
    const playerId = useSelector(state => state.account._id)
    const isEliminatorPlayerThisSeason = useSelector(state => ((state.eliminator.standings[`${((seasons || []).find(i => i.seasonName === seasonName) || {}).seasonId || null}`] || {}).scoreboardPlayers || []).some(i => i.playerId === playerId))
    const isHome = location.pathname.toLowerCase() === '/eliminator/admin'
    const [currentTime, setCurrentTime] = useState(moment().toDate())

    useLayoutEffect(() => { fetchData() }, [isAuthenticated, seasonMode, seasonName, week, playoffWeek])

    useLayoutEffect(() => {
        tick()
        let timerId = setInterval(() => tick(), 60 * 1000)
        return () => clearInterval(timerId)
    }, [])

    const fetchData = () => {
        if (isAuthenticated) {
            dispatch(getSeasons())
            dispatch(getNflTeams())
            dispatch(getEliminatorDetails())
            dispatch(getMainPageData())
            if (seasonName) {
                dispatch(getStandings(seasonName))
            }
        }
    }

    const tick = () => {
        setCurrentTime(moment().toDate())
    }

    const navbarBrand = (       
        <Link to={{ pathname: '/eliminator' }}>
            <CloudinaryImage publicId={img.EliminatorHeader} />
        </Link>
    )

    const AdminOnlyNavItem = () => <VisibleOnlyToEliminatorAdmin><Nav.Item className={s.item} key={5}><Nav.Link eventKey={5} onClick={() => navigate('/eliminator/admin')}>Admin</Nav.Link></Nav.Item></VisibleOnlyToEliminatorAdmin>
    const RegularSeasonPickMenuItem = () => <VisibleBeforeDate deadline={pickDeadline}>{isEliminatorPlayerThisSeason && <Nav.Item className={s.item} key={253}><Nav.Link eventKey={253} onClick={() => navigate(`/eliminator/enter-pick`)}>Enter pick</Nav.Link></Nav.Item>}</VisibleBeforeDate>
    const PostseasonPickMenuItem = () => <VisibleBeforeDate deadline={playoffPickDeadline}>{isEliminatorPlayerThisSeason && <Nav.Item className={s.item} key={253}><Nav.Link eventKey={253} onClick={() => navigate(`/eliminator/enter-pick`)}>Enter pick</Nav.Link></Nav.Item>}</VisibleBeforeDate>
    
    const menuItems = [
        seasonMode === 'Postseason' ? <PostseasonPickMenuItem key={252} /> : null,
        seasonMode === 'Regular' ? <RegularSeasonPickMenuItem key={251} /> : null,
        <Nav.Item className={s.item} key={2}><Nav.Link eventKey={2} onClick={() => navigate(`/eliminator/standings/${seasonName}`)}>Standings</Nav.Link></Nav.Item>,
        <Nav.Item className={s.item} key={3}><Nav.Link eventKey={3} onClick={() => navigate(`/eliminator/nfl-scoreboard/${seasonName}/week/${week}`)}>NFL Scoreboard</Nav.Link></Nav.Item>,
        <Nav.Item className={s.item} key={4}><Nav.Link eventKey={4} onClick={() => navigate(`/eliminator/winners`)}>Winners</Nav.Link></Nav.Item>,
        <AdminOnlyNavItem key={5} />
    ].filter(i => i)
    return (
        <BaseAuthenticatedMenu backgroundImageUrl={img.CloudinaryBaseUrl + img.EliminatorHeaderBackground}
            navbarBrand={navbarBrand} 
            menuItems={menuItems} />
    )
}

export default EliminatorMenu
