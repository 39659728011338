import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button, Table, FormGroup, InputGroup, FormControl, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './CinderellaPayments.scss'
import CinderellaPaymentLogItem from './CinderellaPaymentLogItem'
import PlayerListItem from '../../Player/PlayerListItem'
import NCAATeam from '../NCAATeam/NCAATeam'
import Confirm from '@severed-links/common.confirm'
import FormCheck from 'react-bootstrap/FormCheck'
import moment from 'moment-timezone'
import numeral from 'numeral'
import { filter, some } from 'lodash'

const CinderellaPaymentHistory = ({ paymentHistory = {}, deletingId = null, onDelete = () => void(0), onDeleteLogEntry = null, addManualPayment = null }) => {

    const dispatch = useDispatch()
    const [allowDeletes, setAllowDeletes] = useState(false)
    const [amount, setAmount] = useState('0')
    const [isMounted, setMounted] = useState(false)

    useEffect(() => { setMounted(true) }, [])

    const deleteEntry = (entryId, playerId) => {
        onDelete(entryId, playerId)
    }

    const handleSwitch = (el, checkedState) => setAllowDeletes(checkedState)

    const doAddManualPayment = () => {
        const { year, playerId } = paymentHistory
        if (addManualPayment && year && playerId && !isNaN(amount)) {
            addManualPayment({ year, playerId, amount: parseFloat(amount) })
            setAmount('0')    
        }
    }

    const manualPaymentIsValid = () => !isNaN(amount)

    if (!paymentHistory) return null
    const ph = paymentHistory
    const filteredPaymentLog = filter(ph.paymentLog, p => !some(ph.payments, pay => pay.txn_id === p.txn_id))
    return (
        <div className={s.container}>
            <PlayerListItem player={ph?.user}
                rightDetail={<div className={s.playerInformationContainer + ' ' + s.rightDetail}>
                    {isMounted ?
                    <div className={s.stat}>
                        <div className={s.header}>Allow deletes?</div>
                        <div className={s.value}>
                            <FormCheck type='switch' onChange={e => handleSwitch(e, e.target.checked)}
                                name='active' checked={allowDeletes} />
                        </div>
                    </div>
                    : null}
                    <div className={s.stat}>
                        <div className={s.header}>Last Logged In</div>
                        <div className={s.value}>{moment(ph.user.lastLogin).local().fromNow()}</div>
                    </div>
                    {ph.user.lastEntryView ?
                    <div className={s.stat}>
                        <div className={s.header}>Last entries view</div>
                        <div className={s.value}>{moment(ph.user.lastEntryView).local().fromNow()}</div>
                    </div>
                    : null}
                </div>} />
            <div className={s.playerInformationContainer}>
                <div className={s.player}>&nbsp;</div>
                <div className={s.stat}>
                    <div className={s.header}>Entries</div>
                    <div className={s.value}>{ph.paymentStatus.entries}</div>
                </div>
                <div className={s.stat}>
                    <div className={s.header}>Entry fee</div>
                    <div className={s.value}>{numeral(ph.paymentStatus.entryFee).format('($0,0.00)')}</div>
                </div>
                <div className={s.stat}>
                    <div className={s.header}>Total due</div>
                    <div className={s.value}>{numeral(ph.paymentStatus.totalDue).format('($0,0.00)')}</div>
                </div>
                <div className={s.stat}>
                    <div className={s.header}>Balance due</div>
                    <div className={s.value + ' ' + (ph.paymentStatus.balanceDue > 0 ? s.danger : ph.paymentStatus.balanceDue < 0 ? s.success : '')}>{numeral(ph.paymentStatus.balanceDue).format('($0,0.00)')}</div>
                </div>
            </div>
            <div className={s.entriesContainer}>
            {ph.entries && ph.entries.map(entry =>
                <div className={s.entry} key={`entry-${entry.entryId}`}>
                    <div className={s.titleContainer}>
                        <h5 className={s.title} title={`Entry: ${entry.entryName}`}>{entry.entryName}</h5>
                        {allowDeletes ?
                            <Confirm variant='danger' onConfirm={() => deleteEntry(entry.entryId, ph.user.playerId)}
                                title={'Delete Player Entry'}
                                confirmText='confirm delete entry'
                                body={'Are you sure you want to delete this entry?'}
                                buttonIcon='trash' size='sm' buttonClassName={s.deleteButton}  />
                        : null}
                    </div>
                    {entry.teams && entry.teams.map(t =>
                        <NCAATeam key={t.teamId} teamId={t.teamId} name={t.teamName} 
                            showAvatar avatarUrl={t.avatarUrl}
                            seed={t.seed} isAlive={t.isAlive} showPoints points={t.points} />
                    )}  
                </div>
            )}
            </div>
            <div className={s.completedPaymentsRow}>
                <div className={s.title}>
                    <h4>Completed Payments</h4>
                </div>
                <div className={s.manualPayment}>
                    <FormGroup className={s.manualPayment}>
                        <InputGroup>
                            <InputGroup.Text>Manual Payment{' '}<FontAwesomeIcon name='money-bill-alt' className={s.icon} /></InputGroup.Text>
                            <FormControl value={amount} onChange={e => setAmount(e.target.value)} style={{ textAlign: 'right' }} />
                            <Button variant='primary' onClick={() => doAddManualPayment()}><FontAwesomeIcon name='check' /></Button>
                        </InputGroup>
                    </FormGroup>
                </div>
            </div>
            <ListGroup className={s.paymentLog}>
            {ph.payments && ph.payments.map((x, index) =>
                <CinderellaPaymentLogItem key={`payment-history-payment-item-${index}-${x.txn_id}`} item={x}
                    onDeleteLogEntry={onDeleteLogEntry} deletingId={deletingId} />
            )}
            {!ph.payments || ph.payments.length === 0 ? 
                <ListGroup.Item className={s.item + ' ' + s.noRecords} variant='danger'>No completed payments located for {ph.year}.</ListGroup.Item>
            : null}
            </ListGroup>
            <h4>Other Transactions</h4>
            <ListGroup className={s.paymentLog}>
            {filteredPaymentLog && filteredPaymentLog.map((x, index) =>
                <CinderellaPaymentLogItem key={`payment-history-filtered-payment-log-${index}-${x._id}`} item={x} deletingId={deletingId} />
            )}
            {!filteredPaymentLog || filteredPaymentLog.length === 0 ? 
                <ListGroup.Item className={s.item + ' ' + s.noRecords} variant='info'>No other transactions located for {ph.year}.</ListGroup.Item>
            : null}
            </ListGroup>
            <h4>User Email Address</h4>
            <div>{ph?.user?.emailAddress ? <i>{ph.user.emailAddress}</i> : null}</div>
        </div>
    )
}

export default CinderellaPaymentHistory
