import React, { useState, useEffect, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, FormControl, FormGroup, InputGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './CinderellaAdminBracketBuilder.scss'
import NCAATeam from '../NCAATeam/NCAATeam'
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { tournamentTeamsLookup, setBracketBuilderTeamName, 
    saveBracketBuilderTeamName } from '@severed-links/common.redherrings-reducers/cinderellaAdmin'
import { find } from 'lodash'
import moment from 'moment-timezone'

const SAVE_SECONDS = 2

const CinderellaTeamNameTypeahead = ({ index = 0, teamName = '', region = 0, seed = 0, avatarUrl = null, year = null, _id = null }) => {

    const dispatch = useDispatch()
    const espnTeams = useSelector(state => state.cinderellaAdmin.espnTeams)
    const [options, setOptions] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [isSaving, setSaving] = useState(false)
    const [saveTimeoutId, setSaveTimeoutId] = useState(0)
    const [currentTime, setCurrentTime] = useState(moment().toISOString())
    const [lastUpdate, setLastUpdate] = useState(null)
    const _typeaheadRef = useRef(null)

    useEffect(() => {
        let _timerId = setInterval(() => setCurrentTime(moment().toISOString()), 500)
        return () => clearInterval(_timerId)
    })

    useEffect(() => {
        if (lastUpdate && moment(lastUpdate).isValid() && moment(currentTime).diff(moment(lastUpdate), 'seconds') > SAVE_SECONDS) {
            saveTeamNameToDb()
        }
    }, [teamName, currentTime, lastUpdate])

    const getEspnAvatarUrl = _teamName => (find(espnTeams, { teamName: _teamName }) || {}).avatarUrl || null

    const handleSaveButtonClick = () => {
        const _teamName = _typeaheadRef.current?.getInput().value || ''
        handleTypeaheadSelect([{ teamName: _teamName }])
    }

    const handleTypeaheadSelect = e => {
        const _teamName = e && e.length > 0 ? (e[0].teamName || '') : ''
        dispatch(setBracketBuilderTeamName(_teamName, _id, getEspnAvatarUrl(_teamName), index))
        setLastUpdate(moment().subtract(SAVE_SECONDS - 1.5, 'seconds').toISOString())
    }

    const handleSearch = _query => { 
        setLoading(true)
        dispatch(tournamentTeamsLookup(_query))
        .then(action => {
            setOptions(action.payload || [])
            setLoading(false)
        })
    }

    const saveTeamNameToDb = () => {
        setLastUpdate(null)
        setSaving(true)
        dispatch(saveBracketBuilderTeamName(teamName, _id, getEspnAvatarUrl(teamName), index, region, year))
        .then(() => setSaving(false))
    }

    return (
        <div className={s.bracketBuilderTeamContainer} key={index}>
            <NCAATeam teamId={_id} seed={seed} name={""} size='sm' 
                avatarUrl={avatarUrl} showAvatar />
            <div className={s.typeaheadContainer}>
                <div className={s.bracketBuilderTeamNameEditor}>
                    <AsyncTypeahead id={`cinderella-team-name-typeahead-${index}`}
                        multiple={false}
                        ref={_typeaheadRef}
                        defaultInputValue={teamName || ''}
                        size='sm'
                        isLoading={isLoading}
                        options={options}
                        onSearch={_query => handleSearch(_query)}
                        onChange={handleTypeaheadSelect}
                        labelKey='teamName'
                        renderMenuItemChildren={(option, props) => (
                            <div className={s.bracketBuilderTeamNameLookupItem}>
                                {option.avatarUrl ? <div className={s.avatar}><img src={option.avatarUrl} /></div> : null}
                                <div className={s.teamName}>{option.teamName}</div>
                            </div>
                        )} />
                </div>
                <div className={s.typeaheadControls}>
                    <Button variant='primary' onClick={() => handleSaveButtonClick()}><FontAwesomeIcon name={isSaving ? 'circle-notch' : 'check'} spin={isSaving} /></Button>
                </div>
            </div>
        </div>
    )
}

export default CinderellaTeamNameTypeahead