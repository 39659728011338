import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'

const HomeView = () => {

    return (
        <Row>
            <Col xs={{ span: 10, offset: 1 }} sm={{ span: 6, offset: 3 }}>
                <h2>Welcome to Redherrings!</h2>
                <p>Click <Link to='/login'>here</Link> to log in...</p>
            </Col>
        </Row>
    )
}

export default HomeView