import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation, useParams, Link } from 'react-router-dom'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { Row, Col, Button, FormGroup, FormControl, FormLabel } from 'react-bootstrap'
import { getEntries, saveEntry } from '@severed-links/common.redherrings-reducers/cinderella'
import moment from 'moment-timezone'
import { filter, some, startsWith, find, 
    reject, range, isEqual, update, first } from 'lodash'
import * as s from './CinderellaEntries.scss'

import NCAATeam from '../NCAATeam/NCAATeam'
import Confirm from '@severed-links/common.confirm'
import validator from 'validator'

const CinderellaEditEntry = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const playerId = useSelector(state => state.account._id)
    const isAuthenticated = useSelector(state => state.account.isAuthenticated)
    const year = useSelector(state => state.cinderella.year)
    const pickDeadline = useSelector(state => moment(state.cinderella.pickDeadline).toDate())
    const entries = useSelector(state => state.cinderella.entries) || {}
    const entryPicks = useSelector(state => state.cinderella.entryPicks)
    const regions = useSelector(state => reject(state.cinderella.regions || [], r => r.regionName == 'Final Four'))
    const hasTeamsInBracket = useSelector(state => state.cinderella.frontPageData.hasTeamsInBracket)
    const [entryName, setEntryName] = useState('')
    const [entryTeams, setEntryTeams] = useState([])
    const [teams, setTeams] = useState(entries.teams || [])
    const [entryLoaded, setEntryLoaded] = useState(false)
    const [entryTeamLoader, setEntryTeamLoader] = useState([])
    const [isSaving, setSaving] = useState(false)
    const [currentTime, setCurrentTime] = useState(moment().toISOString())
    const picksNeeded = entryPicks - entryTeams.length
    const isNewEntry = location.pathname === '/cinderella/entry/new'
    const entryId = !isNewEntry && params.entryId ? params.entryId : null
    const readOnly = !isNewEntry && entries && entries?.entries &&
        !some(entries?.entries || [], e => e._id === entryId)
    
    useEffect(() => {
        updateStateSecurity()
        setSaving(false)
        let timerId = setInterval(() => setCurrentTime(moment().toISOString()), 30 * 1000)
        return () => clearInterval(timerId)
    }, [])

    useEffect(() => {
        if (isAuthenticated && pickDeadline && year) { 
            updateStateSecurity()
        }
    }, [isAuthenticated, year, pickDeadline, location.pathname, currentTime])

    useEffect(() => {
        if (entryTeamLoader.length) {
            var _entryTeamLoader = [...entryTeamLoader]
            selectTeam(first(_entryTeamLoader))
            _entryTeamLoader.splice(0, 1)
            setEntryTeamLoader(_entryTeamLoader)
        }
    }, [entryTeamLoader.length])

    const updateStateSecurity = () => {

        /* redirect means -- your time is up :) */
        var redirect = moment().isAfter(pickDeadline)
        if (redirect || readOnly) {
            navigate('/cinderella/entries')
        } else if (!entryLoaded) {
            loadEntry()
        }
    }

    const loadEntry = () => {
        setTeams(teams.map(t => ({ ...t, isAlive: true })))
        /* this should only run once */
        setEntryName('')
        setEntryTeams([])
        if (!isNewEntry) {
            var userEntry = (entries?.entries || []).find(e => e._id === entryId)
            if (userEntry) {
                setEntryName(userEntry.entryName)
                setEntryTeamLoader((userEntry.entryTeams || []).map(i => i.teamId))
            }
        }
        setEntryLoaded(true)
    }

    const selectTeam = teamId => {
        if (picksNeeded > 0) {
            var _entryTeams = [...entryTeams]
            var _teams = [...teams]
            var pickedTeam = _teams.find(t => t.teamId === teamId)
            if (pickedTeam && pickedTeam.isAlive && !_entryTeams.some(t => t.teamId === teamId)) {
                pickedTeam.isAlive = false
                _entryTeams.push(pickedTeam)
            }
            setEntryTeams(_entryTeams)
            setTeams(_teams)
        }
    }

    const deselectTeam = teamId => {
        var _entryTeams = [...entryTeams]
        var _teams = [...teams]
        var pickedTeam = _teams.find(t => t.teamId === teamId)
        _entryTeams = reject(_entryTeams, t => t.teamId == teamId)
        var pickedTeam = find(_teams, t => t.teamId === teamId)
        if (pickedTeam) pickedTeam.isAlive = true
        setEntryTeams(_entryTeams)
        setTeams(_teams)
    }

    const handleChange = e => {
        setEntryName(e.target.value)
    }

    const onCancelSave = () => {
        setSaving(false)
        navigate('/cinderella/entries')
    }

    const onConfirmSave = () => {
        setSaving(true)
        dispatch(saveEntry({ entryId, year, entryName, entryTeams }))
        .then(() => {
            onCancelSave()
        })
    }

    const _isEntryNameValid = entryName && validator.isLength(entryName, { min: 3 })
    const _isValid = _isEntryNameValid && !picksNeeded
    return (
        <div className={s.editEntryContainer}>
            {entryLoaded ? 
            <div>
                <Row>
                    <Col xs={12}>
                    {isNewEntry ? 
                        <h2>Add a New Cinderella Entry</h2>
                    :
                        <h2>Edit Your Cinderella Entry: {entryName} </h2>
                    }
                    </Col>
                </Row>
                <Row>
                    <Col xs={12} sm={4} md={4} lg={3}>
                        <h4>Your Entry</h4>
                        <FormGroup>
                            <FormLabel>Entry name</FormLabel>
                            <FormControl type='text' name='EntryName' 
                                value={entryName} size='lg'
                                required isValid={_isEntryNameValid}
                                isInvalid={!_isEntryNameValid}
                                onChange={e => handleChange(e)} />
                            <FormControl.Feedback />
                        </FormGroup>
                        <h4 className={picksNeeded == 0 ? 'text-white' : 'text-white'}>
                            <span>Your Teams</span>
                        </h4>
                        {entryTeams.map(entryTeam => 
                            <div key={entryTeam.teamId} style={{ marginBottom: '10px', cursor: 'pointer' }} onClick={() => deselectTeam(entryTeam.teamId)}>
                                <NCAATeam size={'lg'} name={entryTeam.teamName} 
                                    showAvatar avatarUrl={entryTeam.avatarUrl}
                                    seed={entryTeam.seed} isAlive={true} showPoints={false} 
                                    showGameScore={false} />
                            </div>
                        )}
                        {picksNeeded ? Array(picksNeeded).fill().map((_, index) =>
                            <div key={index + '-empty-slot'} style={{ marginBottom: '10px' }}>
                                <NCAATeam size={'lg'} name={null} seed={null} 
                                    showAvatar={false}
                                    alive={false} showPoints={false} showGameScore={false} />
                            </div>
                        ) : null}
                        <FormGroup size='lg' controlId='formEntrySubmit'>
                            <Confirm variant={_isValid ? 'success' : 'danger'} title={<span>Confirm Entry Save</span>} 
                                body={<div>Are you sure you are ready to save this entry?</div>} 
                                onConfirm={() => onConfirmSave()} onClose={() => onCancelSave()}
                                buttonIcon={_isValid ? (!isSaving ? 'check' : 'circle-notch') : 'times'} 
                                size='lg' buttonText={!isSaving ? 'save entry' : 'saving...'} 
                                disabled={isSaving || !_isValid}
                                buttonClassName={s.saveButton} />
                        </FormGroup>
                        {!isSaving ? 
                        <div style={{ marginTop: '30px', textAlign: 'center', fontSize: '90%' }}>
                            <Link to={'/cinderella/entries'}>cancel and return to entries page</Link>
                        </div>
                        : null}
                    </Col>
                    <Col xs={12} sm={8} md={8} lg={9}>
                    <div>
                        <Row>
                        {regions.map(r =>
                            <Col key={r.regionName} xs={6} sm={3}>
                                <h4 style={{ textAlign: 'center' }}>{r.regionName}</h4>
                                {filter(teams, (t) => t.region == r.region).map(team =>
                                    <div key={team.teamId} onClick={() => selectTeam(team.teamId)} style={{ cursor: 'pointer' }}>
                                        <NCAATeam name={team.teamName} seed={team.seed} isAlive={team.isAlive} 
                                            showAvatar avatarUrl={team.avatarUrl}
                                            showPoints={false} showGameScore={false} />
                                    </div>
                                )}
                            </Col>
                        )}
                        </Row>
                    </div>
                    </Col>
                </Row>
            </div>
            : null}
        </div>
    )
}

export default CinderellaEditEntry