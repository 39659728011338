import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form, FormGroup, FormControl, FormLabel, 
    InputGroup, Button, ListGroup } from 'react-bootstrap'
import * as s from './FootballEditConfiguration.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import FormCheck from 'react-bootstrap/FormCheck'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { setActiveSeason, getConfig, setEditConfig } from '@severed-links/common.redherrings-reducers/footballAdmin'
import { getFootballDetails } from '@severed-links/common.redherrings-reducers/football'
import { forOwn, isArray, has } from 'lodash'

const FootballEditConfiguration = () => {

    const dispatch = useDispatch()
    const seasonId = useSelector(state => state.football.seasonId)
    const seasonName = useSelector(state => state.football.seasonName)
    const seasons = useSelector(state => state.football.seasons)
    const config = useSelector(state => state.footballAdmin.config)
    const [_editConfig, setConfig] = useState({})

    useEffect(() => { 
        if (config) {
            setInitState()
        }
    }, [config])

    useEffect(() => { dispatch(setEditConfig(removeArrays(_editConfig))) }, [_editConfig])

    const handleChange = e => setConfig({ ..._editConfig, [e.target.name]: !isNaN(e.target.value) ? parseFloat(e.target.value) : e.target.value })

    const handleBootstrapSwitch = e => {
        if (e.target.name && has(_editConfig, e.target.name)) {
            setConfig({ ..._editConfig, [e.target.name]: e.target.checked })
        }
    }

    const removeArrays = (obj = {}) => {
        forOwn(obj, (value, key) => { if (isArray(value)) delete obj[key] })
        return obj
    }

    const setInitState = () => {
        const _newConfig = { ...((config || {}).seasonConfig || {}), teamRegistration: ((config || {}).seasonConfig || {}).teamRegistration === 'Open' }
        setConfig(_newConfig)
    }

    const { seasonConfig } = config || {}
    if (!config || !seasonConfig) return null
    const { seasonModeDropDown } = seasonConfig || {}
    const _configFields = [
        { label: 'Season Mode', fieldType: 'select', fieldName: 'seasonMode', value: `${_editConfig.seasonMode || ''}`, placeholder: '[Select season mode...]' },
        { label: 'Active Regular Season Week', fieldType: 'number', fieldName: 'activeWeek', value: `${_editConfig.activeWeek || ''}`, placeholder: '[Week]' },
        { label: 'Active Postseason Week', fieldType: 'number', fieldName: 'activePlayoffWeek', value: `${_editConfig.activePlayoffWeek || ''}`, placeholder: '[Playoff Week]' },
        { label: 'PayPal Event Name', fieldType: 'text', fieldName: 'eventTitle', value: `${_editConfig.eventTitle || ''}`, placeholder: '[PayPal Event Name...]' },
        { label: 'PayPal Email Account', fieldType: 'email', fieldName: 'payPalAccount', value: `${_editConfig.payPalAccount || ''}`, placeholder: '[PayPal Payment Email...]' },
        { label: 'Team Registration', fieldType: 'switch', fieldName: 'teamRegistration', value: _editConfig.teamRegistration || false, placeholder: '[]' },
        { label: 'Registration Code', fieldType: 'text', fieldName: 'entryCode', value: `${_editConfig.entryCode || ''}`, placeholder: '[Registration Code...]' },
        { label: 'Entry Fee', fieldType: 'number', fieldName: 'entryFee', value: `${_editConfig.entryFee || ''}`, placeholder: '[Entry Fee]' },
        { label: 'Team Entry Cap', fieldType: 'number', fieldName: 'teamCap', value: `${_editConfig.teamCap || ''}`, placeholder: '[Max Teams]' },
        { label: 'Max Teams/Player', fieldType: 'number', fieldName: 'maxTeamsPerPlayer', value: `${_editConfig.maxTeamsPerPlayer || '1'}`, placeholder: '[Max Teams/Player]' },
        { heading: 'Payouts', label: 'Feagles!', fieldType: 'number', fieldName: 'feagles', value: `${_editConfig.feagles || ''}`, placeholder: '[Feagles]' },
        { label: 'Round 1', fieldType: 'number', fieldName: 'round1', value: `${_editConfig.round1 || ''}`, placeholder: '[Round 1]' },
        { label: 'Round 2', fieldType: 'number', fieldName: 'round2', value: `${_editConfig.round2 || ''}`, placeholder: '[Round 2]' },
        { label: 'Final 4', fieldType: 'number', fieldName: 'final4', value: `${_editConfig.final4 || ''}`, placeholder: '[Final 4]' },
        { label: 'Runner-up', fieldType: 'number', fieldName: 'runnerup', value: `${_editConfig.runnerup || ''}`, placeholder: '[Runner-up]' },
        { label: 'Champion', fieldType: 'number', fieldName: 'champion', value: `${_editConfig.champion || ''}`, placeholder: '[Champion]' },
    ]

    return (
        <div className={s.container}>
            <ListGroup className={s.list}>
            {_configFields && _configFields.map(i => [
            i.heading ? <h4 key={`edit-football-config-heading-${i.fieldName}`}>{i.heading}</h4> : null,
            <ListGroup.Item className={s.item} key={`edit-football-config-${i.fieldName}`}>
                <div className={s.content}>
                    <div className={s.label}>{i.label}</div>
                    <div>
                    {i.fieldName === 'seasonMode' ? 
                    <FormControl as='select' placeholder={i.placeholder}
                        onChange={e => handleChange(e)}
                        name={i.fieldName}
                        value={i.value}>
                    {seasonModeDropDown && seasonModeDropDown.map(o => 
                        <option key={o.value} value={o.value}>{o.text}</option>
                    )}
                    </FormControl>
                    : i.fieldName === 'teamRegistration' ? 
                    <FormCheck type='switch' onChange={e => handleBootstrapSwitch(e, e.target.checked)} 
                        name='teamRegistration'  
                        checked={_editConfig.teamRegistration} />
                    :
                    <FormControl placeholder={i.placeholder} type={i.fieldType}
                        onChange={e => handleChange(e)}
                        name={i.fieldName}
                        value={i.value} />
                    }
                    </div>
                </div>
            </ListGroup.Item>
            ])}
            </ListGroup>
        </div>
    )
}

export default FootballEditConfiguration