import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Form, FormControl, FormLabel, FormGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getConfig, saveConfig } from '@severed-links/common.redherrings-reducers/cinderellaAdmin'
import { getDetails } from '@severed-links/common.redherrings-reducers/cinderella'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { FRIENDLY_DATE_FORMAT } from '@severed-links/common.redherrings-constants'
import Datetime from 'react-datetime'
import * as s from './CinderellaEditConfig.scss'
import moment from 'moment-timezone'

const CinderellaEditConfig = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const _entryCode = useSelector(state => state.cinderellaAdmin.entryCode)
    const _entryFee = useSelector(state => state.cinderellaAdmin.entryFee)
    const _eventTitle = useSelector(state => state.cinderellaAdmin.eventTitle)
    const _numPicks = useSelector(state => state.cinderellaAdmin.numPicks)
    const _payPalAccount = useSelector(state => state.cinderellaAdmin.payPalAccount)
    const _pickDeadline = useSelector(state => state.cinderellaAdmin.pickDeadline)
    const _year = useSelector(state => state.cinderellaAdmin.year)
    const timeZone = useSelector(state => state.account.timeZone)
    const [_config, setConfig] = useState({ year: _year, activeYear: _year, numPicks: '5', pickDeadline: '', payPalAccount: '', entryFee: '20', eventTitle: '', entryCode: '' })
    const [isSaving, setSaving] = useState(false)
    const { activeYear, numPicks, pickDeadline, payPalAccount, eventTitle, entryCode, entryFee } = _config

    useEffect(() => { loadConfig() }, [_year, _entryCode, _entryFee, _eventTitle, _numPicks, _payPalAccount, _pickDeadline])

    const loadConfig = () => {
        dispatch(getConfig())
        .then(action => setConfigState(action.payload))
    }

    const setConfigState = (_config = {}) => {
        setConfig({ 
            activeYear: `${_config.activeYear}`,
            numPicks: `${_config.numPicks}`,
            pickDeadline: moment(_config.pickDeadlineDate).tz('America/New_York').format(FRIENDLY_DATE_FORMAT),
            payPalAccount: `${_config.payPalAccount}`,
            eventTitle: `${_config.eventTitle}`,
            entryCode: `${_config.entryCode}`,
            entryFee: `${_config.entryFee}`,
        })
    }

    const handleDateChange = dt => setConfig({ ..._config, pickDeadline: moment(dt, FRIENDLY_DATE_FORMAT).format(FRIENDLY_DATE_FORMAT) })

    const doSaveConfig = () => {
        var config = {
            ..._config, 
            activeYear: parseInt(activeYear), 
            entryFee: parseFloat(entryFee), 
            numPicks: parseInt(numPicks),
            pickDeadline: moment(pickDeadline, FRIENDLY_DATE_FORMAT).utc().toISOString(),
        }
        setSaving(true)
        dispatch(saveConfig(config))
        .then(action => {
            dispatch(createNotification({ headline: action.payload.subject, message: action.payload.message, type: action.payload.messageType, timeout: 6000 }))
            dispatch(getConfig())
            dispatch(getDetails())
            setSaving(false)
            navigate('/cinderella/admin')
        })
    }

    return (
        <div className={s.container}>
            {activeYear ?
            <Form>
                <FormGroup className={s.formGroup}>
                    <FormLabel>Year</FormLabel>
                    <FormControl placeholder='Year' type='number'
                        onChange={e => setConfig({ ..._config, activeYear: e.target.value })}
                        value={`${activeYear}`} />
                </FormGroup>

                <FormGroup className={s.formGroup}>
                    <FormLabel>Pick Deadline (ET)</FormLabel>
                    <Datetime value={pickDeadline}
                        dateFormat='M/D/YYYY' 
                        timeFormat='h:mm A'
                        onChange={dt => handleDateChange(dt)} />
                </FormGroup>
                    
                <FormGroup className={s.formGroup}>
                    <FormLabel>Entry Teams</FormLabel>
                    <FormControl placeholder='#' type='number'
                        onChange={e => setConfig({ ..._config, numPicks: e.target.value })}
                        value={numPicks} />
                </FormGroup>

                <FormGroup className={s.formGroup}>
                    <FormLabel>Entry Fee</FormLabel>
                    <FormControl placeholder='$0.00'  type='number'
                        onChange={e => setConfig({ ..._config, entryFee: e.target.value })}
                        value={entryFee} />
                </FormGroup>

                <FormGroup className={s.formGroup}>
                    <FormLabel>Entry Code</FormLabel>
                    <FormControl placeholder='Entry Code' 
                        onChange={e => setConfig({ ..._config, entryCode: e.target.value })}
                        value={entryCode} />
                </FormGroup>

                <FormGroup className={s.formGroup}>
                    <FormLabel>Event Title</FormLabel>
                    <FormControl placeholder='Event Title' 
                        onChange={e => setConfig({ ..._config, eventTitle: e.target.value })}
                        value={eventTitle} />
                </FormGroup>

                <FormGroup className={s.formGroup}>
                    <FormLabel>PayPal Account</FormLabel>
                    <FormControl placeholder='PayPal Account Email' type='email'
                        onChange={e => setConfig({ ..._config, payPalAccount: e.target.value })}
                        value={payPalAccount} />
                </FormGroup>

                <FormGroup className={s.formGroup}>
                    <Button disabled={isSaving} variant='primary' onClick={() => doSaveConfig()} style={{ alignSelf: 'flex-end' }}>
                        <FontAwesomeIcon name={!isSaving ? 'check' : 'circle-notch'} spin={isSaving} /> save configuration
                    </Button>
                </FormGroup>
            </Form> : null}
        </div>
    )
}

export default CinderellaEditConfig