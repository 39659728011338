import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { setAccountDeletionState } from '@severed-links/common.redherrings-reducers/accountDeletion'
import pluralize from 'pluralize'
import * as s from './AccountDeletionStep.scss'
import PlayerListItem from '../Player/PlayerListItem'

const PersonalInfoStep = forwardRef(({ jumpToStep, ...props }, _ref) => {

    const dispatch = useDispatch()
    const playerId = useSelector(state => state.account._id)
    const _accountDeletion = useSelector(state => state.accountDeletion)
    const { 
        isFetching, playerInfoSelections, playerInfoSelection, firstName, lastName, address, 
        city, state, zip, emailAddress, cellPhone, imageUrl, accountDeletionConfirmed, 
    } = _accountDeletion

    const _isValidated = playerInfoSelections.length && (playerInfoSelections.map(i => i.value).includes(playerInfoSelection))

    useEffect(() => {
    }, [])

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValidated,
    }))

    const handlePersonalInfoSelect = _playerInfoSelection => {
        dispatch(setAccountDeletionState({ playerInfoSelection: _playerInfoSelection !== playerInfoSelection ? _playerInfoSelection : '' }))
    }

    const _contestCounts = ['Football', 'Eliminator', 'Cinderella', 'Oscars', 'Capture']
        .map(i => ({
            name: i,
            count: _accountDeletion[`${i.toLowerCase()}Count`] || 0,
        })).map(i => ({
            ...i,
            display: `${pluralize(`${['Football', 'Eliminator'].includes(i.name) ? 'season' : 'year'}`, i.count, true)}`,
        })).filter(i => i && i.count)

    return (
        <div className={s.container}>
            <h4>Consent to Remaining Personal Information</h4>
            <p>
                Your player information may be linked to other data in our database. 
                After deletion of your account, may we continue to link your first name, 
                last name, and avatar image to your account ID? Your remaining personal 
                information will be removed (cell phone, email, address, etc.).
            </p>
            {_contestCounts.length ?
            <>
            <p>You have played the following {pluralize('contest', _contestCounts.length, false)}:</p>
            <ListGroup className={s.list}>
            {_contestCounts.map(i =>
            <ListGroup.Item className={s.item} key={`contest-account-delete-${i.name}`}>
                <div className={s.title}>{i.name}</div>
                <div className={s.num + ' ' + s.normal}>{i.display}</div>
            </ListGroup.Item>
            )}
            </ListGroup>
            </>
            : null}
            <p>Please select one of the options for your information (a representation is provided about what future references to your user account will look like):</p>
            {playerInfoSelections && playerInfoSelections.map(i =>
            <Card className={s.card + (playerInfoSelection && playerInfoSelection === i.value ? ` ${s.isSelected}` : '')} key={`account-deletion-personal-info-card-${i.value}`}
                onClick={() => handlePersonalInfoSelect(i.value)}>
                <Card.Header className={s.cardHeader}>
                    <div className={s.icon}><FontAwesomeIcon name={playerInfoSelection && playerInfoSelection === i.value ? 'check-circle' : 'circle'} isRegular={!(playerInfoSelection && playerInfoSelection === i.value)} /></div>
                    <div className={s.title}>{i.title}</div>
                </Card.Header>
                <ListGroup variant='flush' className={s.list}>
                    <PlayerListItem player={i.model} clickDisabled />
                </ListGroup>
            </Card>
            )}
        </div>
    )
})

export default PersonalInfoStep
