// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.LeagueSchedulerSettings__container___xgK4H .LeagueSchedulerSettings__list___Iug7E{margin:.25rem 0px 1rem 0px}.LeagueSchedulerSettings__container___xgK4H .LeagueSchedulerSettings__list___Iug7E .LeagueSchedulerSettings__item___mQLBc{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center;padding-top:.25rem;padding-bottom:.25rem}.LeagueSchedulerSettings__container___xgK4H .LeagueSchedulerSettings__list___Iug7E .LeagueSchedulerSettings__item___mQLBc .LeagueSchedulerSettings__title___xINzl{flex:1 1 auto}.LeagueSchedulerSettings__container___xgK4H .LeagueSchedulerSettings__list___Iug7E .LeagueSchedulerSettings__item___mQLBc .LeagueSchedulerSettings__switch___PkO9m{margin-left:1rem;flex:0 0 0;white-space:nowrap}`, "",{"version":3,"sources":["webpack://./src/routes/Football/Admin/LeagueSchedulerSettings.scss"],"names":[],"mappings":"AACI,mFACI,0BAAA,CAEA,0HACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CACA,kBAAA,CACA,qBAAA,CAEA,kKACI,aAAA,CAIJ,mKACI,gBAAA,CACA,UAAA,CACA,kBAAA","sourcesContent":[".container {\n    .list {\n        margin: 0.25rem 0px 1.00rem 0px;\n\n        .item {\n            display: flex;\n            flex-flow: row nowrap;\n            justify-content: space-between;\n            align-items: center;\n            padding-top: 0.25rem;\n            padding-bottom: 0.25rem;\n\n            .title {\n                flex: 1 1 auto;\n                \n            }\n\n            .switch {\n                margin-left: 1rem;\n                flex: 0 0 0;\n                white-space: nowrap;\n            }\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `LeagueSchedulerSettings__container___xgK4H`;
export var list = `LeagueSchedulerSettings__list___Iug7E`;
export var item = `LeagueSchedulerSettings__item___mQLBc`;
export var title = `LeagueSchedulerSettings__title___xINzl`;
var _1 = `LeagueSchedulerSettings__switch___PkO9m`;
export { _1 as "switch" };
export default ___CSS_LOADER_EXPORT___;
