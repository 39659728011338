import React, { useEffect } from 'react'
import CloudinaryImage from '../Cloudinary/CloudinaryImage'
import { Badge } from 'react-bootstrap'
import * as s from './PlayerTeam.scss'

const PlayerTeam = ({
    teamId, teamName, avatarUrl, showAvatar = true, showTeamName = true,
    smallAvatar = true, largeFont = false, setWidth = 'auto', setHeight = 'auto', 
    onClick = () => void(0), 
}) => {

    return (
        <div key={teamId} className={s.teamContainer + ' ' + (onClick ? s.hasClick : null)} title={teamName} onClick={onClick ? () => onClick() : void(0)}>
            {showAvatar ?
            <CloudinaryImage className={s.teamAvatar} 
                publicId={avatarUrl} 
                transformationName={smallAvatar ? "small_team_avatar" : null}
                alt={teamName} 
                style={{ width: setWidth, height: setHeight }} />
            : null}
            {showTeamName ?
            <div className={s.teamName + ' ' + (largeFont ? s.largeTeamName : null)}>{teamName}</div>
            : null}
        </div>
    )
}

export default PlayerTeam