import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useMatch } from 'react-router-dom'
import { ListGroup, Row, Col, Table } from 'react-bootstrap'
import { getPickTrendsReport } from '@severed-links/common.redherrings-reducers/football'
import { find, startsWith } from 'lodash'
import SeasonSelector from '../SeasonSelector'
import WeekSelector from '../WeekSelector'
import * as s from './FootballLeagueReport.scss'
import NflTeam from '../NflTeam'
import Line from '../Line'

const PickTrendsReport = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const _match = useMatch(`/football/reports/:reportId/*`)
    const seasonName = params.seasonName || null
    const reportId = ((_match || {}).params || {}).reportId || null
    const week = parseInt(params.week || '0')
    const seasons = useSelector(state => state.football.seasons)
    const playerId = useSelector(state => state.account._id)
    const reports = useSelector(state => state.football.reports)
    const season = seasons.find(i => i.seasonName === seasonName) || {}
    const report = reports.find(i => i.seasonId === season.seasonId && 
        i.reportId === `${reportId}-week-${week}`)

    useEffect(() => { fetchReport() }, [seasonName, week])

    const fetchReport = () => seasonName && week ? dispatch(getPickTrendsReport(seasonName, week)) : null

    if (!report) return null
    const { games } = report
    return (
            season && season.seasonName && report ? 
            <div className={s.container}>
                <div className={s.titleBar}>
                    <h2 className={s.title}>{season.seasonName} Week {week} Pick Trends</h2>
                    <div className={s.seasonSelector}><SeasonSelector season={season} seasons={seasons} /></div>
                    <div className={s.weekSelector}><WeekSelector seasons={seasons} season={season} week={'W' + week.toString()} /></div>
                </div>
                {games && games.length ?
                <ListGroup className={s.pickTrendsList}>
                {games.map(g => 
                    <ListGroup.Item className={s.pickTrendsItem} key={`pick-trends-report-item-${g.seasonId}-${g.week}-${g.game}`}>
                        <div className={s.content}>
                            <div className={s.count}>{g.favoriteCount}</div>
                            <div className={s.team}>
                                <div className={s.graph + ' ' + s.left} style={{ width: `${g.favoritePct * 100.0}%` }}></div>
                                <div className={s.teamItem}>
                                    <NflTeam teamId={g.favorite} teamName={g.favoriteTeam} mascot={g.favoriteMascot} />
                                </div>
                            </div>
                            <div className={s.line}><Line line={g.line} showSign={false} /></div>
                            <div className={s.team}>
                                <div className={s.graph + ' ' + s.right} style={{ width: `${g.underdogPct * 100.0}%` }}></div>
                                <div className={s.teamItem}>
                                    <NflTeam teamId={g.underdog} teamName={g.underdogTeam} mascot={g.underdogMascot} />
                                </div>
                            </div>
                            <div className={s.count}>{g.underdogCount}</div>
                        </div>
                    </ListGroup.Item>
                )}

                </ListGroup>
                :
                <div>No games located for this week.</div>}
            </div>
            : null
    )
}

export default PickTrendsReport