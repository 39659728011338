import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { Row, Col, Form, FormGroup, FormControl, InputGroup, FormLabel, Button, ButtonGroup } from 'react-bootstrap'
import * as s from './FootballAdmin.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import FormCheck from 'react-bootstrap/FormCheck'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { runScoreUpdater, runSpreadUpdater } from '@severed-links/common.redherrings-reducers/footballAdmin'
import SeasonSelector from '../SeasonSelector'
import WeekSelector from '../WeekSelector'
import { find, orderBy, findIndex, startsWith } from 'lodash'
import UpdateNflRegularSeasonLines from './UpdateNflRegularSeasonLines'

const UpdateRegularSeasonLinesView = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const seasons = useSelector(state => state.football.seasons || [])
    const seasonName = params.seasonName || null
    const season = seasons.find(i => i.seasonName === seasonName) || {}
    const seasonId = season.seasonId || null
    const weekParam = params.week || ''
    const seasonMode = location.pathname.toLowerCase().indexOf('postseason') > -1 ? 'Postseason' : 'Regular'
    const week = parseInt(weekParam.toString().replace('playoff-week', '').replace('week', ''))
    const isPlayoffWeek = startsWith(weekParam, 'playoff-')
    const [games, setGames] = useState([])
    const [isUpdatingScores, setUpdatingScores] = useState(false)
    const [isUpdatingSpreads, setUpdatingSpreads] = useState(false)

    const doRunScoreUpdater = () => {
        if (!isPlayoffWeek) {
            setUpdatingScores(true)
            dispatch(runScoreUpdater(seasonName, 'Regular', week))
            .then(action => {
                dispatch(createNotification(action.payload))
                setUpdatingScores(false)
            })
        }
    }

    const doRunSpreadUpdater = () => {
        if (!isPlayoffWeek) {
            setUpdatingSpreads(true)
            dispatch(runSpreadUpdater(seasonName, 'Regular', week))
            .then(action => {
                dispatch(createNotification(action.payload))
                setUpdatingSpreads(false)
            })
        }
    }

    return (
        season.seasonId && week ?
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}>Update {season.seasonName} Week {week} Lines</h2>
                <div className={s.controls + ' ' + s.grow}>
                {isPlayoffWeek ? 
                    <span className={s.weekSelector}><WeekSelector showRegularSeasonWeeks={false} showPlayoffWeeks={true} seasons={seasons} season={season} week={(!isPlayoffWeek ? 'W' : 'PW') + week.toString()} /></span>
                    :
                    <span className={s.weekSelector}><WeekSelector showRegularSeasonWeeks={true} showPlayoffWeeks={false} seasons={seasons} season={season} week={(!isPlayoffWeek ? 'W' : 'PW') + week.toString()} /></span>
                }
                    <span className={s.seasonSelector}><SeasonSelector seasons={seasons} season={season} /></span>
                {!isPlayoffWeek ?
                    <ButtonGroup style={{ float: 'right', marginTop: '3px' }}>
                        <Button disabled={isUpdatingScores} onClick={() => doRunScoreUpdater()}><FontAwesomeIcon name={!isUpdatingScores ? 'star' : 'circle-notch'} spin={isUpdatingScores} /> scores</Button>
                        <Button disabled={isUpdatingSpreads} onClick={() => doRunSpreadUpdater()}><FontAwesomeIcon name={!isUpdatingSpreads ? 'money-bill-alt' : 'circle-notch'} spin={isUpdatingSpreads} /> spreads</Button>
                    </ButtonGroup>
                : null}
                </div>
            </div>
            <Row>
                <Col xs={12}>
                    {season && season.seasonName ? <UpdateNflRegularSeasonLines seasonId={season.seasonId} 
                        seasonName={season.seasonName} week={week} seasonMode={seasonMode} />
                    : null}
                </Col>
            </Row>
        </div> : null
    )
}

export default UpdateRegularSeasonLinesView

