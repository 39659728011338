import React from 'react'
import * as s from './ContentPanel.scss'
import { ContentRoutes } from '../../routes'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router-dom'
import { camelCase } from 'change-case'
import AvatarNeedsEditingAlert from '../../routes/Football/TeamProfile/AvatarNeedsEditingAlert'

const ContentPanel = () => {

  const location = useLocation()
  const urlParts = location.pathname.split('/')
  const currentAppPath = (urlParts && urlParts.length > 1 ? urlParts[1] : 'main').toLowerCase()
  const currentAppClass = camelCase(currentAppPath)
  const { _id: playerId, firstName, lastName, avatarUrl } = useSelector(state => state.account)

  return (
    <div className={s.contentPanel + (['football','footballAdmin','eliminator','eliminatorAdmin'].includes(currentAppClass) ? ` ${s.extraPaddingForStatusBar}` : '')}>

      <AvatarNeedsEditingAlert _id={playerId} 
        name={`${firstName} ${lastName}`} isTeam={false} 
        avatarUrl={avatarUrl} />

      <ContentRoutes />
    </div>
  )
}

export default ContentPanel
