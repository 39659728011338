import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, FormGroup, FormControl, FormLabel, InputGroup, Button, Popover, OverlayTrigger } from 'react-bootstrap'
import * as s from './BroadcastMessage.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import HtmlEditor from '../../Editors/HtmlEditor'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { getBroadcastMessageData, sendBroadcastMessage } from '@severed-links/common.redherrings-reducers/footballAdmin'
import { trim, endsWith, first } from 'lodash'

const BroadcastMessage = () => {

    const dispatch = useDispatch()
    const _seasonId = useSelector(state => state.football.seasonId)
    const _seasonName = useSelector(state => state.football.seasonName)
    const seasons = useSelector(state => state.football.seasons)
    const broadcastMessage = useSelector(state => state.footballAdmin.broadcastMessage)
    const editorContent = useSelector(state => state.editors.footballAdminEmailMessageBody)
    const [seasonId, setSeasonId] = useState('')
    const [seasonName, setSeasonName] = useState('')
    const [isSending, setSending] = useState(false)
    const [messageTypes, setMessageTypes] = useState([])
    const [messageType, setMessageType] = useState('')
    const [teams, setTeams] = useState([])
    const [columns, setColumns] = useState([])
    const [teamId, setTeamId] = useState('')
    const [body, setBody] = useState('')
    const [subject, setSubject] = useState('')

    useEffect(() => {
        if (messageType === '-3') setTeamId('')
    }, [messageType])

    useEffect(() => { setSeasonId(_seasonId) }, [_seasonId])
    useEffect(() => { setSeasonName(_seasonName) }, [_seasonName])
    useEffect(() => { getData() }, [seasonId])

    const getData = () => {
        if (seasonId) {
            dispatch(getBroadcastMessageData(seasonId))
            .then(action => {
                setSubject('')
                setBody('')
                setColumns(action.payload.columns || [])
                setMessageTypes(action.payload.messageTypes || [])
                setMessageType(action.payload.messageType)
                setTeamId(action.payload.teamId || '')
                setTeams(action.payload.teams || [])
            })       
        }
    }

    const doBroadcastMessage = () => {
        setSending(true)
        var emailMessage = { messageType, seasonId, teamId, subject, body: editorContent }
        dispatch(sendBroadcastMessage(emailMessage))
        .then(action => {
            dispatch(createNotification({
                type: action.payload.messageType, 
                headline: 'Broadcast Message', 
                message: action.payload.message, 
                timeout: 6000 
            }))
            setSending(false)
        })
    }

    const insertFieldName = _column => {
        var _editorContent = editorContent
        _editorContent = trim(_editorContent)
        if (endsWith(_editorContent, '</p>'))
            _editorContent = _editorContent.replace(/\<\/p\>$/g, '')
        _editorContent += `${_column.columnNameInsert}</p>`
        setBody(_editorContent)
    }

    const popoverClick = (
        <Popover id='message-fields-popover' title='Insert a field name'>
            <div>
            {columns && columns.map((c, index) =>
            <Button key={`field-name-popover-${c.columnName}`} size='sm' title={c.columnNameDisplay} 
                tabIndex={-1} variant='light' 
                onClick={() => insertFieldName(c)}>
                {c.columnNameDisplay}
            </Button>
            )}
            </div>
        </Popover>
    )
    return (
        messageTypes ? 
        <div className={s.container}>
            <FormGroup className={s.formGroup}>
                <FormLabel>Recipients</FormLabel>
                <FormControl as='select' placeholder='Message type...' tabIndex={-1} 
                    onChange={e => setMessageType(e.target.value)} name='messageType'
                    value={messageType}>
                {messageTypes && messageTypes.map(m => 
                    <option key={m.value} value={m.value}>{m.text}</option>
                )}
                </FormControl>
            </FormGroup>

            {messageType === '-3' ?
            <FormGroup className={s.formGroup}>
                <FormLabel>Team</FormLabel>
                <FormControl as='select' placeholder='Select a team...' 
                        onChange={e => setTeamId(e.target.value)} 
                        name='teamId'
                        value={teamId}>
                    {teams && teams.map(m => 
                        <option key={m.value} value={m.value || ''}>{m.text}</option>
                    )}
                </FormControl>
            </FormGroup>
            : null}

            <FormGroup className={s.formGroup}>
                <FormLabel>Subject</FormLabel>
                <FormControl placeholder='Subject' autoCapitalize='words' name='subject'
                    onChange={e => setSubject(e.target.value)} autoFocus
                    value={subject} />
            </FormGroup>

            <FormGroup className={s.formGroup}>
                <FormLabel className={s.formLabel}>
                    <div>Message body</div>
                    <div>
                        <OverlayTrigger trigger='click' placement='top' overlay={popoverClick}>
                            <Button style={{ float: 'right' }} size='sm' tabIndex={-1}><FontAwesomeIcon name='i-cursor' /><span className='d-none d-sm-inline'>{' '}insert field</span></Button>
                        </OverlayTrigger>
                    </div>                    
                </FormLabel>
                <div>
                    <HtmlEditor content={body} 
                        name={'footballAdminEmailMessageBody'}
                        showToolbar
                        placeholder={'Email message body...'} />
                </div>
            </FormGroup>

            <FormGroup className={s.formGroup}>
                <Button disabled={isSending} variant='primary' onClick={() => doBroadcastMessage()}>
                    <FontAwesomeIcon spin={isSending} name={!isSending ? 'envelope' : 'circle-notch'} /> {!isSending ? 'send' : 'sending...'}
                </Button>
            </FormGroup>
        </div>
        : null
    )
}

export default BroadcastMessage