import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Tabs, Tab } from 'react-bootstrap'
import { getNflTeamAnalysis } from '@severed-links/common.redherrings-reducers/football'
import { first } from 'lodash'
import NFLTeamAnalysisAgainstOtherTeams from './NFLTeamAnalysisAgainstOtherTeams'
import NFLTeamAnalysisSeasonSummaries from './NFLTeamAnalysisSeasonSummaries'
import NFLTeamAnalysisGameResults from './NFLTeamAnalysisGameResults'
import SeasonSelector from '../../SeasonSelector'
import NFLTeamSelector from '../../NFLTeamSelector'
import * as s from './NFLTeamAnalysis.scss'
import StickyHeader from '@severed-links/common.sticky-header'

const NFLTeamAnalysis = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const seasonName = params.seasonName || null
    const mascot = (params.mascot || '').toLowerCase()
    const seasons = useSelector(state => state.football.seasons)
    const season = seasons.find(i => i.seasonName === seasonName) || {}
    const nflTeams = useSelector(state => state.football.nflTeams)
    const seasonId = (seasons.find(i => i.seasonName === seasonName) || {}).seasonId || null
    const nflTeam = nflTeams.find(i => i.mascot.toLowerCase() === mascot) || {}
    const analysis = (useSelector(state => state.football.nflTeamAnalysis)[`${seasonId}`] || {})[`${nflTeam._id}`] || {}
    const favoriteTeam = useSelector(state => state.football.favoriteTeam)
    const defaultSeasonName = useSelector(state => state.football.seasonName)
    const _tabs = [
        { title: 'Season Results' },
        { title: 'Records Against Opponents' },
        { title: 'Season Summaries' },
    ]
    const [_activeTab, setActiveTab] = useState(first(_tabs).title)

    useEffect(() => {
        if (seasonId && nflTeam._id) {
             dispatch(getNflTeamAnalysis(seasonId, nflTeam._id))
             setActiveTab(first(_tabs).title)
        }
    }, [seasonId, nflTeam._id])
    useEffect(() => {
        if (!seasonId && defaultSeasonName) {
            navigate(`/football/nfl-team-analysis/${defaultSeasonName}`)
        } else if (seasonName && !mascot && favoriteTeam && favoriteTeam.mascot) {
            navigate(`/football/nfl-team-analysis/${defaultSeasonName}/${favoriteTeam.mascot.toLowerCase()}`)
        } else if (seasonName && !mascot && nflTeams.length) {
            navigate(`/football/nfl-team-analysis/${defaultSeasonName}/${(first(nflTeams)).mascot.toLowerCase()}`)
        }
    }, [seasons.length, seasonId, mascot, defaultSeasonName, favoriteTeam, nflTeams.length])

    const seasonSummaries = analysis.seasonSummaries || []
    const oppRecords = analysis.oppRecords || []
    const seasonResults = analysis.seasonResults || []
    const postseasonResults = analysis.postseasonResults || []
    const team = analysis.team || {}
    return (
            analysis ? 
            <div className={s.container}>
                <StickyHeader title={`${seasonName} ${nflTeam.nflTeam}`}
                    controls={<>
                        <div><SeasonSelector seasons={seasons} season={season} /></div>
                        <div><NFLTeamSelector selectedTeam={nflTeam} /></div>
                    </>} />
                <div className={s.tabsContainer}>
                    <Tabs activeKey={_activeTab} onSelect={e => setActiveTab(e)}>
                    {_tabs.map((_tab, _index) =>
                    <Tab className={s.tabPane} title={_tab.title} eventKey={_tab.title}>
                        {_index === 0 ? <NFLTeamAnalysisGameResults seasonName={seasonName} games={seasonResults} playoffGames={postseasonResults} /> : null}
                        {_index === 1 ? <NFLTeamAnalysisAgainstOtherTeams seasonName={seasonName} records={oppRecords} /> : null}
                        {_index === 2 ? <NFLTeamAnalysisSeasonSummaries seasonName={seasonName} summaries={seasonSummaries} /> : null}
                    </Tab>
                    )}
                    </Tabs>
                </div>
            </div>
            : null
    )
}

export default NFLTeamAnalysis