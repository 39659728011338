import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Alert, Button, Tabs, Tab } from 'react-bootstrap'
import * as s from './RegularSeason.scss'
import { setDashboardTeam, getLeagueScoreboard, setPickTeamId,
    getTeamProfile, divisionStandings, findMyFootballMatchups } from '@severed-links/common.redherrings-reducers/football'
import { find, some, forEach, first, uniq } from 'lodash'
import DivisionStandings from '../DivisionStandings'
import DeadlineAlertContainer from '../DeadlineAlertContainer'
import LeagueScoreboardGame from './LeagueScoreboardGame'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import moment from 'moment-timezone'
import durationFormat from 'moment-duration-format'
import { getFootballUrlParams } from '@severed-links/common.redherrings-constants'
import SeasonSchedule from '../TeamProfile/SeasonSchedule'
import SeasonScheduleLarge from '../TeamProfile/SeasonScheduleLarge'
import AvatarNeedsEditingAlert from '../TeamProfile/AvatarNeedsEditingAlert'
import pluralize from 'pluralize'
import UnpaidAlert from '../UnpaidAlert'

const RegularSeason = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const playerId = useSelector(state => state.account._id)
    const seasonName = params.seasonName || null
    const seasons = useSelector(state => state.football.seasons)
    const season = seasons.find(i => i.seasonName === seasonName)
    const seasonId = (season || {}).seasonId || null
    const week = useSelector(state => state.football.week)
    const pickDeadline = useSelector(state => state.football.pickDeadline)
    const dashboard = useSelector(state => state.football.dashboard) || {}
    const myTeams = useSelector(state => state.football.myTeams) || []
    /* TODO: convert to existing data */
    const teamProfiles = useSelector(state => state.football.teamProfiles)
    const leagueStandings = useSelector(state => state.football.leagueStandings)
    const leagueScoreboards = useSelector(state => state.football.leagueScoreboards)
    const [activeTab, setActiveTab] = useState(0)

    useEffect(() => { !activeTab && myTeams.length ? setActiveTab((first(myTeams) || {})._id) : null }, [myTeams.length, activeTab])
    useEffect(() => { updateDashboardData() }, [seasonId, myTeams.length, dashboard._id])
    useEffect(() => { updateDashboardTeam((first(myTeams) || {})._id || null) }, [myTeams.length, dashboard._id])

    const updateDashboardTeam = teamId => {
        if (some(myTeams, { _id: teamId })) {
            dispatch(setDashboardTeam(find(myTeams, { _id: teamId })))
        }
    }
    
    const updateDashboardData = () => {
        if (seasonId && dashboard && dashboard._id && myTeams && myTeams.length) {
            dispatch(divisionStandings(seasonId))
            dispatch(getLeagueScoreboard(seasonName, week))

            const _loadedTeamProfiles = (teamProfiles || []).map(i => i._id)
            const _teamIds = (myTeams || []).map(i => i._id)
            const _unloadedTeamIds = _teamIds.filter(i => _loadedTeamProfiles.indexOf(i) === -1)
            Promise.all(_unloadedTeamIds.map(i => dispatch(getTeamProfile(i))))
        }
    }

    const findMyTeamProfiles = teamIds => (teamProfiles || []).filter(tp => teamIds.indexOf(tp._id) > -1)

    const findMyStandings = teamIds => {
        const leagueStanding = find(leagueStandings, { seasonName: seasonName })
        if (leagueStanding && leagueStanding.leagueStandings) {
            const _standings = leagueStanding.leagueStandings || []
            const _myDivisions = uniq(_standings.filter(i => (i.standings || []).some(x => teamIds.indexOf(x.teamId) > -1)).map(i => i.divisionCode))
            return _standings.filter(i => _myDivisions.indexOf(i.divisionCode) > -1)
        } else {
            return null
        }
    }

    const myGames = findMyFootballMatchups({ leagueScoreboards, seasons, seasonName, week, playerId })
    const myIds = myTeams.map(i => i._id)
    const _teamProfiles = findMyTeamProfiles(myIds) || []
    const _standings = findMyStandings(myIds)
    return (
        seasonName && week > 0 ?
        <div>
            <Row>
                <Col xs={12}>
                    <h2>Redherrings {seasonName} Regular Season Week {week}</h2>
                </Col>
            </Row>
            <Row>
                <Col sm={6} xs={12}>
                    {_teamProfiles && _teamProfiles.map(teamProfile => 
                    <div key={`reg-season-team-profile-${teamProfile._id}`}>
                        <AvatarNeedsEditingAlert _id={teamProfile._id} 
                            key={`team-avatar-alert-${teamProfile._id}`}
                            name={teamProfile.teamName} isTeam 
                            avatarUrl={teamProfile.teamAvatarUrl} />
                    </div>
                    )}
                    {dashboard && dashboard._id && myGames && myGames.length ? 
                        <div>
                            <h4>This Week's {pluralize('Match-up', myGames.length, false)}</h4>
                            {myTeams && myTeams.map(t => 
                            <UnpaidAlert myTeam={t} key={`dashboard-unpaid-team-${t._id}`} />
                            )}
                            {myGames && myGames.map(myGame => 
                            <div key={`dashboard-matchup-${dashboard._id}-${myGame.id1}-${myGame.id2}`}>
                                <LeagueScoreboardGame seasonName={seasonName} game={myGame} isLarge={true} />
                                <DeadlineAlertContainer deadline={pickDeadline} 
                                    teamId={myGame[`_id${myGame.myTeam}`]} 
                                    teamName={myGame[`name${myGame.myTeam}`]} 
                                    avatarUrl={myGame[`avatarUrl${myGame.myTeam}`]}
                                    setPickTeamId={setPickTeamId}
                                    showPickButton={true} />
                            </div>
                            )}
                        </div>
                    : null}
                </Col>
                <Col sm={6} xs={12}>
                {_standings && _standings.map(i =>
                <div key={`reg-season-standings-${i.divisionCode}`}>
                    <DivisionStandings 
                        division={{ divisionName: i.divisionName, divisionCode: i.division, standings: i.standings }} 
                        playerId={playerId} shortForm={false} />
                </div>
                )}
                </Col>
                <Col xs={12}>
                {_teamProfiles && _teamProfiles.length === 1 ? 
                <>
                {_teamProfiles && _teamProfiles.filter(i => i.seasonSchedule).map(teamProfile => 
                <div key={`reg-season-season-schedule-${teamProfile._id}`}>
                    <SeasonScheduleLarge week={week} teamName={teamProfile.teamName} seasonName={seasonName} schedule={teamProfile.seasonSchedule} />
                </div>
                )}
                </>
                :
                <>
                <Tabs activeKey={activeTab} onSelect={e => setActiveTab(parseInt(e))}>
                {_teamProfiles && _teamProfiles.filter(i => i.seasonSchedule).map(teamProfile => 
                <Tab eventKey={teamProfile._id} title={`${teamProfile.teamName}`}
                    key={`reg-season-mult-team-tab-${teamProfile._id}`}>
                    <SeasonScheduleLarge week={week} teamName={teamProfile.teamName} seasonName={seasonName} schedule={teamProfile.seasonSchedule} />
                </Tab>
                )}
                </Tabs>
                </>
                }
                </Col>
            </Row>
        </div>
        : null
    )
}

export default RegularSeason