import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Row, Col, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import moment from 'moment-timezone'
import { filter, some, find } from 'lodash'
import * as s from './CinderellaAdmin.scss'
import Logins from '../../Main/Admin/Logins'
import CinderellaAdmins from './CinderellaAdminsList'
import CinderellaConfigSummary from './CinderellaConfigSummary'
import CinderellaSendMail from './CinderellaSendMail'
import CinderellaEditConfig from './CinderellaEditConfig'
import CinderellaSetupButtonList from './CinderellaSetupButtonList'
import CinderellaRegionRoundConfig from './CinderellaRegionRoundConfig'
import Modal from '@severed-links/common.modal'
import { getMainPageData } from '@severed-links/common.redherrings-reducers/cinderellaAdmin'
import DeadlineCountdown from '../DeadlineCountdown'
import OnlineUsers from '../../../components/OnlineUsers'
import SendPushNotification from '../../Notifications/SendPushNotification'
import PaymentTester from '../../Payment/PaymentTester'

const CinderellaAdminDashboard = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const onlineUsers = useSelector(state => state.cinderellaAdmin.onlineUsers)
    const year = useSelector(state => state.cinderellaAdmin.year)
    const showEditConfig = useSelector(state => state.cinderellaAdmin.showEditConfig)
    const showSendMail = useSelector(state => state.cinderellaAdmin.showSendMail)
    const showRegionConfig = useSelector(state => state.cinderellaAdmin.showRegionConfig)
    const dashboardSubTab = params.dashboardSubTab || null

    useEffect(() => { dispatch(getMainPageData()) }, [])

    return (
        <div className={s.container}>
            <div className={s.heading}>
                <h2 className={s.title}>Cinderella Admin Dashboard</h2>
                <div className={s.onlineUserContainer}>
                    <OnlineUsers users={onlineUsers} right noBottomMargin />
                </div>
                <div className={s.button}>
                    <SendPushNotification />
                </div>
                <div className={s.button}>
                    <PaymentTester defaultApp={'Cinderella'} />
                </div>
            </div>
            <Row>
                <Col md={6} sm={12} xs={12}>
                    <CinderellaConfigSummary />
                    <DeadlineCountdown threshholdHours={24} />
                    <CinderellaSetupButtonList />
                </Col>
                <Col md={6} sm={12} xs={12}>
                    <CinderellaAdmins />
                    <Logins isDashboard />
                </Col>
            </Row>

            <Modal heading={'Send Mail'} size={null}
                show={dashboardSubTab === 'send-mail'}
                onClose={() => navigate('/cinderella/admin')}
                showFooter={false}>
                <CinderellaSendMail />
            </Modal>

            <Modal heading={'Edit Configuration'} size={null}
                show={dashboardSubTab === 'edit-configuration'}
                onClose={() => navigate('/cinderella/admin')}
                showFooter={false}>
                <CinderellaEditConfig />
            </Modal>

            <Modal heading={'Edit Region/Round Configuration'} size={'lg'}
                show={dashboardSubTab === 'edit-rounds-and-regions'}
                onClose={() => navigate('/cinderella/admin')}
                showFooter={false}>
                <CinderellaRegionRoundConfig />
            </Modal>
        </div>
    )
}

export default CinderellaAdminDashboard