import React, { useEffect } from 'react'
import { Badge } from 'react-bootstrap'
import * as s from './NCAATeam.scss'

const NCAATeam = ({
    teamId = null, name = '', isAlive = true, size = 'md', 
    seed = null, points = 0, gameScore = 0, showAvatar = false, 
    avatarUrl = null, picked = 0, region = 0, regionName = '', 
    showPoints = false, showGameScore = false, 
}) => {


    var showGameScore = !showPoints && showGameScore
    const style = showAvatar && avatarUrl ? { style: { backgroundImage: `url(${avatarUrl})` } } : undefined
    return (
        <div className={s.teamContainer +
            (size == 'lg' ? ' ' + s.lg : size == 'md' ? ' ' + s.md : ' ' + s.sm) +
            (isAlive ? ' ' + s.isAlive : '') + ' ' +
            (showAvatar && avatarUrl ? s.hasAvatar : s.noAvatar)}
            title={`#${seed} ${name}`}>
            <div className={s.seed}>
                <div>{seed && !isNaN(seed) ? seed : '\u00a0'}</div>
            </div>
            <div className={s.name}{...style}>
                {name && name !== '&nbsp;' ? name : '\u00a0' }
            </div>
            {showPoints && points > 0 ?
                <div className={s.points}>
                    <Badge className={s.pointsBadge} bg={isAlive ? 'info' : 'light'} text={isAlive ? 'light' : 'dark'}>{points}</Badge>
                </div>
            : null}
            {showGameScore && gameScore > 0 ?
            <div className={s.gameScore}>
                {gameScore}
            </div>
            : null}
        </div>
    )
}

export default NCAATeam