import React, { useEffect, useLayoutEffect, useState } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment-timezone'

const VisibilityBeforeAfterDate = ({ 
  before = false, deadline = moment().add(30, 'seconds'), children = null, 
}) => {

  const [visible, setVisible] = useState(false)

  useLayoutEffect(() => {
    tick()
    let _timerId = setInterval(() => tick(), 1000)
    return () => clearInterval(_timerId)
  }, [])

  const tick = () => {
    var _visible = false
    if (before) {
      _visible = moment().isBefore(moment(deadline))
    } else {
      _visible = moment().isAfter(moment(deadline))
    }
    setVisible(_visible)
  }

  return (
    visible && children && typeof children === 'object' ? children : null
  )
}

export const VisibleBeforeDate = (props = {}) => {

  return (
    <VisibilityBeforeAfterDate {...props} before={true} />
  )
}

export const VisibleAfterDate = (props = {}) => {
  return (
    <VisibilityBeforeAfterDate {...props} before={false} />
  )
}
