import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col } from 'react-bootstrap'
import EliminatorInstructions from './EliminatorInstructions'
import PickReminders from './PickReminders'
import PickReport from './PickReport'
import EliminatorPreseasonSignUp from './EliminatorPreseasonSignUp'
import EliminatorPaymentAlert from './EliminatorPaymentAlert'
import EliminatorCurrentPick from './EliminatorCurrentPick'
import EliminatorFrontPageMessage from './EliminatorFrontPageMessage'
import * as s from './EliminatorIntro.scss'

const EliminatorIntro = () => {

    const seasonName = useSelector(state => state.eliminator.seasonName)
    const eliminationMode = useSelector(state => state.eliminator.eliminationMode)
    const mainPage = useSelector(state => state.eliminator.mainPage)

    if (!mainPage) return null

    const { afterDeadline, isPreseason } = mainPage

    return (
        <div>
            <Row>
                <Col lg={7} md={6} sm={12}>
                    <h2>Welcome to the {seasonName} Eliminator!</h2>
                    <EliminatorFrontPageMessage />
                    <EliminatorCurrentPick />
                    <EliminatorInstructions eliminationMode={eliminationMode} />
                </Col>
                <Col lg={5} md={6} sm={12}>

                    <EliminatorPreseasonSignUp />

                    <EliminatorPaymentAlert />

                    <PickReminders />

                    {!isPreseason && afterDeadline ? 
                        <PickReport />
                    : null}

                </Col>
            </Row>
        </div>
    )
}

export default EliminatorIntro