import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useLocation } from 'react-router-dom'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './FootballMenuStatusBar.scss'
import moment from 'moment-timezone'
import { endsWith, isEqual } from 'lodash'

const FootballMenuStatusBar = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const seasonId = useSelector(state => state.football.seasonId)
    const seasonName = useSelector(state => state.football.seasonName)
    const seasonMode = useSelector(state => state.football.seasonMode)
    const week = useSelector(state => state.football.week)
    const playoffWeek = useSelector(state => state.football.playoffWeek)
    const pickDeadline = useSelector(state => state.football.pickDeadline)
    const playoffPickDeadline = useSelector(state => state.football.playoffPickDeadline)
    const isFetching = useSelector(state => state.football.isFetching || state.footballAdmin.isFetching)
    const timeZone = useSelector(state => state.account.time_zone)
    const [deadlineStatus, setDeadlineStatus] = useState('')
    const [playoffDeadlineStatus, setPlayoffDeadlineStatus] = useState('')
    const [afterDeadline, setAfterDeadline] = useState(false)
    const [afterPlayoffDeadline, setAfterPlayoffDeadline] = useState(false)
    const [urgentDeadline, setUrgentDeadline] = useState(false)
    const [currentTime, setCurrentTime] = useState(moment().toISOString(true))

    useEffect(() => {
        let timerId = setInterval(() => setCurrentTime(moment().toISOString(true)), 5000)
        return () => clearInterval(timerId)
    }, [])

    useEffect(() => { checkDeadlines() }, [pickDeadline, playoffPickDeadline, currentTime])

    useEffect(() => { reRouteChecker() }, [afterDeadline, afterPlayoffDeadline])

    const reRouteChecker = () => {
        if (seasonMode === 'Regular' && afterDeadline &&
            location.pathname === `/football/regular-season/${seasonName}/enter-picks`) {
            navigate(`/football/regular-season/${seasonName}`, { replace: true })
        } else if (seasonMode === 'Postseason' && afterPlayoffDeadline &&
            location.pathname === `/football/postseason/${seasonName}/enter-picks`) {
            navigate(`/football/postseason/${seasonName}`, { replace: true })
        }
    }

    const checkDeadlines = () => {
        const _afterDeadline = pickDeadline ? moment().isSameOrAfter(moment(pickDeadline)) : false
        const _afterPlayoffDeadline = playoffPickDeadline ? moment().isSameOrAfter(moment(playoffPickDeadline)) : false
        if (pickDeadline) {
            setDeadlineStatus(pickDeadlineStatus(pickDeadline))
            setAfterDeadline(_afterDeadline)
        }

        if (playoffPickDeadline) {
            setPlayoffDeadlineStatus(pickDeadlineStatus(playoffPickDeadline))
            setAfterPlayoffDeadline(_afterPlayoffDeadline)
        }

        // set urgency
        if (pickDeadline && !_afterDeadline) {
            var _deadline = moment(pickDeadline)
            var _duration = moment.duration(_deadline.diff(moment()))
            setUrgentDeadline(_duration.asMinutes() <= 60)
        } else if (playoffPickDeadline && !_afterPlayoffDeadline) {
            var _playoffDeadline = moment(playoffPickDeadline)
            var _playoffDuration = moment.duration(_playoffDeadline.diff(moment()))
            setUrgentDeadline(_playoffDuration.asMinutes() <= 60)
        }
    }

    const pickDeadlineStatus = date => {
        if (!date || !moment(date).isValid() || !timeZone) return '---'

        var deadline = moment(date)
        var duration = moment.duration(deadline.diff(moment()))
        var iscurrentDate = deadline.isSame(moment(), 'day')

        var status = '---'
        if (!moment().isBefore(deadline)) {
            status = 'Passed'
        } else if (duration.asMinutes() <= 60) {
            status = deadline.fromNow()
        } else if (iscurrentDate) {
            status = deadline.tz(timeZone).format('h:mm a')
        } else {
            status = deadline.tz(timeZone).format('M/D/YYYY h:mm a')
        }
        return status
    }

    return (
        <div className={s.statusBar}>
            <div className={s.container}>

                {isFetching ?
                    <FontAwesomeIcon name='circle-notch' spin className={s.spinner} />
                : null}

                <span className={s.details}>
                    <b><FontAwesomeIcon name='leaf' /> <span className={s.hideSmall}>Season:</span></b>
                    {' '}
                    <span className={s.hideSmall}>{seasonName}</span> {seasonMode}
                </span>

                {seasonMode === 'Regular' ?
                    <span className={s.details}>
                        <b><FontAwesomeIcon name='calendar' /> Week:</b>
                        {' '}
                        {week}
                    </span>
                : null}

                {seasonMode === 'Regular' ?
                    <span className={s.details + ' ' + (urgentDeadline ? s.urgent : null)}>
                        <b><FontAwesomeIcon name='clock' /> <span className={s.hideSmall}>Pick Deadline:</span></b>
                        {' '}
                        {deadlineStatus}
                    </span>
                : null}

                {seasonMode === 'Postseason' ? <span className={s.details}><b><FontAwesomeIcon name='calendar' /> Week:</b> {playoffWeek}</span> : null}
                {seasonMode === 'Postseason' ? <span className={s.details + ' ' + (urgentDeadline ? s.urgent : null)}><b><FontAwesomeIcon name='clock' /> <span className={s.hideSmall}>Pick Deadline:</span></b> {playoffDeadlineStatus}</span> : null}
            </div>
        </div>
    )
}

export default FootballMenuStatusBar
