import React, { useEffect } from 'react'
import { Card, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import NCAATeam from '../NCAATeam/NCAATeam'
import * as s from './ScoreboardBestPossiblePick.scss'
import { sumBy } from 'lodash'

const ScoreboardBestPossiblePick = ({ bestPossible = [] }) => {

    return (
        <div className={s.container}>
            <ListGroup variant='flush'>
                {bestPossible && bestPossible.map(t => 
                <ListGroup.Item key={`best-possible-modal-${t.teamId}`}
                    style={{ borderColor: 'transparent', padding: '0px' }}>
                    <NCAATeam name={t.teamName} seed={t.seed} 
                        size='lg' points={t.points} isAlive={t.isAlive} 
                        showAvatar avatarUrl={t.avatarUrl}
                        showPoints={true} />                    
                </ListGroup.Item>
                )}
                <ListGroup.Item style={{ borderColor: 'transparent', padding: '0px' }}><h4>Points: {sumBy(bestPossible, 'points')}</h4></ListGroup.Item>
            </ListGroup>
        </div>
    )
}

export default ScoreboardBestPossiblePick
