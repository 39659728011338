import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Row, Col, Button } from 'react-bootstrap'
import * as s from './EliminatorEnterPickGame.scss'
import NflTeamListItem from '../Football/NflTeamListItem'
import moment from 'moment-timezone'
import { animateScroll } from 'react-scroll'

const GameButtonContainer = ({ isPicked, teamId, teamName, city, mascot, record, onClick }) => (
<div className={s.gameButtonContainer}>
    <NflTeamListItem className={s.gameButton + ` ${(isPicked ? s.isPicked : s.isNotPicked)}`}
        teamId={teamId} teamName={teamName}
        city={city} mascot={mascot} record={record}
        disabled={isPicked} onClick={() => onClick(teamId)} />
</div>
)

const EliminatorEnterPickGame = ({ game = {}, index = -1, savePick = () => alert('!') }) => {

    const makePick = teamId => {
        savePick(teamId)
        animateScroll.scrollToTop()
    }

    return (
        <div className={s.container}>
            <GameButtonContainer teamId={game.visitor} teamName={game.visitorTeam} 
                city={game.visitorCity}
                mascot={game.visitorMascot} record={game.visitorRecord} 
                isPicked={game.pickedVisitor} 
                onClick={() => makePick(game.visitor)} />
            <div className={s.atContainer}>@</div>
            <GameButtonContainer teamId={game.home} teamName={game.homeTeam} 
                city={game.homeCity}
                mascot={game.homeMascot} record={game.homeRecord} 
                isPicked={game.pickedHome} 
                onClick={() => makePick(game.home)} />
            <div className={s.gameTime}>{moment(game.date).format('h:mm A')}</div>
        </div>
    )
}

export default EliminatorEnterPickGame