import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useMatch, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Alert, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './AvatarNeedsEditingAlert.scss'
import CloudinaryImage from '../../Cloudinary/CloudinaryImage'
import { endsWith } from 'lodash'

const AvatarNeedsEditingAlert = ({ _id = null, name = '', avatarUrl = null, isTeam = true }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { pathname } = useLocation()
    const { isMyProfileLoaded, isAuthenticated } = useSelector(state => state.account)
    const [show, setShow] = useState(true)
    const _match = useMatch(`/:appName/*`)
    const appName = _match?.params?.appName || null
    const _isPlayerProfilePath = pathname.toLowerCase().indexOf('/edit-profile') > -1

    return (
        !avatarUrl && isAuthenticated && isMyProfileLoaded && 
        show && appName && (isTeam || !_isPlayerProfilePath) ?
        <Alert className={s.container} variant='warning' onDismiss={() => setShow(false)}>
            <CloudinaryImage publicId={avatarUrl} className={s.avatar} />
            <div className={s.message}>Your {isTeam && name ? `${name} ` : ''}{isTeam ? 'team ' : ''}avatar is blank.{!isTeam ? ` We would really appreciate it if you could add an avatar to your profile.` : ``}</div>
            <div className={s.controls}>
                <Button variant='warning' size='sm' onClick={() => navigate(isTeam ? `/${appName}/edit-team-profile/${_id}` : `/${appName}/user-profile/edit-profile`)}><FontAwesomeIcon name='pencil-alt' /> edit</Button>
            </div>
        </Alert>
        : null
    )
}

export default AvatarNeedsEditingAlert