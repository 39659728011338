import React, { useEffect, useState, useRef, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { FormGroup, FormControl, Badge, InputGroup, FormLabel, Button, Alert, Tooltip, Overlay } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './PreseasonRegistrationStep2.scss'
import { checkTeamNameAvailability } from '@severed-links/common.redherrings-reducers/football'

const PreseasonRegistrationStep2 = forwardRef(({ jumpToStep }, _ref) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const seasonName = params.seasonName || null
    const seasons = useSelector(state => state.football.seasons)
    const playerId = useSelector(state => state.account._id)
    const userName = useSelector(state => state.account.userName)
    const season = seasons.find(i => i.seasonName === seasonName)
    const seasonId = (season || {}).seasonId || null
    const teamRegistration = useSelector(state => state.football.teamRegistration)
    const preseason = useSelector(state => state.football.preseasons)[`${seasonId}`] || {}
    const { nflKickoff, spotsLeft, divisions, entryFee } = preseason
    const [teamName, setTeamName] = useState('')
    const _isValid = (teamName.length >=4 && teamRegistration.isAvailable) || false
    const _msg = teamName.length && teamName.length < 4 ? 'Team name is too short.'
        : !teamRegistration.isAvailable ? `${teamName} is not available` : ''
    const _showTooltip = !!_msg
    const _inputRef = useRef(null)

    useEffect(() => { setTeamName('') }, [])
    useEffect(() => { dispatch(checkTeamNameAvailability(seasonId, teamName)) }, [seasonId, teamName])

    const handleChange = e => setTeamName(e.target.value)

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValid
    }))

    return (
        <Alert variant='primary' className={s.container}>
            <FormGroup className={s.formGroup}>
                <InputGroup>
                    <Button variant='light' onClick={() => jumpToStep(0)}><FontAwesomeIcon name='chevron-left' /> Cancel</Button>
                    <FormControl autoFocus autoComplete='off' type='text' 
                        placeholder='Enter your team name...' name='teamName' 
                        value={teamName} 
                        className={s.input}
                        required isValid={_isValid}
                        onChange={e => handleChange(e)}
                        ref={_inputRef} />
                    <Button variant='primary' 
                        disabled={!_isValid}
                        onClick={() => _isValid ? jumpToStep(2) : void(0)}><FontAwesomeIcon name='chevron-right' /> Next</Button>
                </InputGroup>
            </FormGroup>
            <Overlay target={_inputRef.current} show={_showTooltip} placement='top'>
            {props => <Tooltip className={s.errorTooltip} id='preseason-team-reg-step-2-tooltip' {...props}>{_msg}</Tooltip>}
        </Overlay>

        </Alert>
    )
})

export default PreseasonRegistrationStep2