import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, FormGroup, FormLabel, FormControl, InputGroup, Alert, ProgressBar } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { trim } from 'lodash'
import * as s from '../SiteRegistration/SignUp.scss'
import { checkPasswordStrength, setUserInfo, verifyResetCode } from '@severed-links/common.redherrings-reducers/forgotPassword'
import { PASSWORD_VALIDATOR, PASSWORD_SCORE, MINIMUM_PASSWORD_SCORE } from '@severed-links/common.redherrings-constants'
import PasswordValidationRules from './PasswordValidationRules'

const ForgotPasswordEnterNew = forwardRef(({ jumpToStep }, _ref) => {

    const dispatch = useDispatch()
    const fp = useSelector(state => state.forgotPassword)
    const [password, setPassword] = useState('')
    const [progressBar, setProgressBar] = useState({ length: 0, style: 'danger' })
    const [failedRules, setFailedRules] = useState([])
    const _isValid = fp.enteredTokenIsValid && fp.verificationCode && fp.passwordIsValid
    const _progressBarLength = PASSWORD_SCORE(password.length, fp.passwordStrength.score)
    
    useEffect(() => { setPassword('') }, [])
    useEffect(() => { setProgressBar({ length: 0, style: 'danger' }) }, [])
    useEffect(() => {
        if (fp.enteredTokenIsValid && !fp.verificationCode) {
            var postData = { tokenType: 'password_reset', token: fp.enteredToken }
            dispatch(verifyResetCode(postData))
            .then(action => {
                dispatch(setUserInfo({ nextDisabled: true }))
                if (action.payload.messageType === 'success') {

                } else {
                    dispatch(createNotification({ ...action.payload, headline: action.payload.headline || 'Code not valid' }))
                    jumpToStep(1)
                }
            })
        }
    }, [fp.enteredTokenIsValid, fp.verificationCode])

    useEffect(() => { updateProgressBar(_progressBarLength) }, [_progressBarLength])

    useEffect(() => {
        if (fp.nextDisabled !== !_isValid) dispatch(setUserInfo({ nextDisabled: !_isValid }))
    }, [fp.nextDisabled, !_isValid])

    useEffect(() => { validatePassword() }, [password])

    const validatePassword = () => setFailedRules(password ? PASSWORD_VALIDATOR.validate(password, { list: true }) : [])

    const updateProgressBar = _score => {
        var style = 'danger'
        if (_score >= MINIMUM_PASSWORD_SCORE) style = 'success'
        else if (_score >= MINIMUM_PASSWORD_SCORE / 2.0) style = 'warning'
        setProgressBar({ length: _score, style })
        dispatch(setUserInfo({ passwordIsValid: _score >= MINIMUM_PASSWORD_SCORE }))
    }

    const handleChange = e => {
        const data = { password: trim(e.target.value) }
        setPassword(data.password)
        dispatch(setUserInfo(data))
        dispatch(checkPasswordStrength(data.password))
    }

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValid
    }))

    return (
        <div>
            {!fp.tokenRequested && !fp.tokenSent ? 
            <Alert variant='info' style={{ marginTop: '40px' }}>Requesting reset token...</Alert>
            : null}
            {fp.tokenRequested && fp.tokenError ?
            <Alert variant='danger' style={{ marginTop: '40px' }}>{fp.tokenErrorMessage}</Alert>
            : null}
            {fp.verificationCode && fp.verificationCodeError ?
            <Alert variant='danger' style={{ marginTop: '40px' }}>{fp.verificationCodeErrorMessage}</Alert>
            : null}
            {fp.verificationCode && !fp.verificationCodeError ?
            <>
            <FormGroup>
                <FormLabel>Enter your new password</FormLabel>
                <FormControl autoFocus autoComplete='off' type='text' 
                    name='password' size='lg'
                    value={password} 
                    required isValid={fp.passwordIsValid}
                    onChange={e => handleChange(e)} />
                <FormControl.Feedback />
            </FormGroup>
            <ProgressBar now={progressBar.length} variant={progressBar.style} />
            <FormGroup>
                <PasswordValidationRules show={!!password} 
                    failedRules={failedRules} 
                    score={progressBar.length} />
            </FormGroup>
            </>
            : null}
        </div>
    )
})

export default ForgotPasswordEnterNew
