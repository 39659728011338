import React, { useEffect, useState } from 'react'
import { ListGroup, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import PlayerListItem from '../Player/PlayerListItem'
import NCAATeam from '../Cinderella/NCAATeam/NCAATeam'
import Line from '../Football/Line'
import CaptureStatusLabel from './CaptureStatusLabel'
import * as s from './CaptureScoreboardList.scss'
import { filter, range, some, reject, indexOf, sortBy } from 'lodash'
import FormCheck from 'react-bootstrap/FormCheck'
import NCAATeamIcon from '../Cinderella/NCAATeam/NCAATeamIcon'

const CaptureScoreboardPlayerTeamLists = ({ isMobile = false, currentTeamList = [], originalTeamList = [] }) => (
    <div className={s.teamsContainer}>
        <div className={s.iconList}>
        {currentTeamList && sortBy(currentTeamList, 'teamName').map(t =>
            <NCAATeamIcon className={s.iconItem} key={`current-team-${t.teamId}`} size='lg' {...t} isAlive />
        )}
        </div>
        <div className={s.iconList + ' ' + s.originalTeams}>
        {originalTeamList && sortBy((originalTeamList).filter(t => !(currentTeamList || []).some(z => z.teamId === t.teamId)), 'teamName').map(t =>
            <NCAATeamIcon className={s.iconItem} key={`current-team-${t.teamId}`} size='sm' {...t} isAlive={false} />
        )}
        </div>
    </div>

)

const CaptureScoreboardList = ({ scoreboard = [] }) => {

    const [showIndices, setShowIndices] = useState([])

    const showAll = () => setShowIndices(range(scoreboard.length))
    
    const hideAll = () => setShowIndices([])

    const toggle = _index => {
        var _showIndices = [...showIndices]
        if (indexOf(_showIndices, _index) === -1) {
            _showIndices.push(_index)
        } else {
            _showIndices.splice(_showIndices.indexOf(_index), 1)
        }
        setShowIndices(_showIndices)
    }

    return (
        <div className={s.container}>
            <ListGroup className={s.scoreboardList}>
            {scoreboard && scoreboard.map((x, index) =>
            <PlayerListItem player={x} className={s.scoreboardItem}
                rightDetail={<div className={s.rightDetail}>
                    <CaptureScoreboardPlayerTeamLists currentTeamList={x.currentTeamList} originalTeamList={x.originalTeamList} isMobile={false} />
                    <div className={s.wonLossRecord}>
                        <div className={s.heading}>Wins/Losses</div>
                        <div className={s.record}>{x.wins}-{x.losses}</div>
                        {x.capturesWon + x.capturesLost > 0 ? <div className={s.footer}>Captures: {x.capturesWon}-{x.capturesLost}</div> : null}
                    </div>
                </div>}
                key={`capture-scoreboard-player-item-${x.playerId}`} />
            )}
            {!scoreboard || !scoreboard.length ? <ListGroup.Item className={s.scoreboardItem}>No records located.</ListGroup.Item> : null}
            </ListGroup>
        </div>
    )
}

export default CaptureScoreboardList