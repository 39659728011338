import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { ListGroup, Card, Badge } from 'react-bootstrap'
import { connect } from 'react-redux'
import moment from 'moment-timezone'
import PlayerTeam from '../PlayerTeam'
import PlayerTeamListItem from '../PlayerTeamListItem'
import * as s from './LeagueScoreboard.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { sample } from 'lodash'
import numeral from 'numeral'
import pluralize from 'pluralize'

const LeagueScoreboardGame = ({ game = {}, seasonName = '', isLarge = false, highScoreFirst = true, showHeader = true, showFooter = true, showSeed = false }) => {

    const navigate = useNavigate()

    const mapTeamFromGame = (_game, _teamNumber = 1) => ({
        _id: _game[`_id${_teamNumber}`],
        avatarUrl: _game[`avatarUrl${_teamNumber}`],
        cow: _game[`cow${_teamNumber}`],
        cowVariant: _game[`cow${_teamNumber}`] === 1.000 ? 'success' : _game[`cow${_teamNumber}`] === 0.000 ? 'danger' : 'info',
        showCow: _game[`cow${_teamNumber}`] > 0,
        division: _game[`division${_teamNumber}`],
        _id: _game[`_id${_teamNumber}`] || _game[`_id${_teamNumber}`],
        teamName: _game[`name${_teamNumber}`],
        playerId: _game[`playerId${_teamNumber}`],
        record: _game[`record${_teamNumber}`],
        score: _game[`score${_teamNumber}`],
        seed: _game[`seed${_teamNumber}`],
        tiebreaker: _game[`tiebreaker${_teamNumber}`],
    })
    
    if (!game || !game.game || !game._id1 || !game._id2) return null
    const { cot, game: gameNumber, week, seasonId, headline, 
        winningTeamIndex, losingTeamIndex, name1, name2, score1, score2,
        afterDeadline } = game
    const _teamMap = [
        mapTeamFromGame(game, highScoreFirst && score2 > score1 ? 2 : 1),
        mapTeamFromGame(game, highScoreFirst && score2 > score1 ? 1 : 2),
    ]
    const _matchUpUrl = `/football/regular-season/match-up/${seasonName}/week/${week}/game/${gameNumber}/` + 
        `${(name1 || '').toLowerCase().replace(/\s+/gi, '-')}` + 
        '-vs-' + 
        `${(name2 || '').toLowerCase().replace(/\s+/gi, '-')}`
    return (
        <Card className={s.gameContainer + (isLarge ? ` ${s.large}` : '')}>
            {showHeader ?
            <Card.Header className={s.gameHeader} onClick={() => navigate(_matchUpUrl)}>
                <div>{headline || 'View matchup'}</div>
                <FontAwesomeIcon name='chevron-right' />
            </Card.Header>
            : null}
        {_teamMap && _teamMap.length === 2 ? 
        <ListGroup className={s.teamList} variant='flush'>
        {_teamMap.map(t => 
        <PlayerTeamListItem 
            teamId={`${t._id}`}
            playerId={`${t.playerId}`}
            teamName={t.teamName} 
            teamNameRaw={t.teamName}
            size={isLarge ? 'lg' : 'sm'}
            showAvatar avatarUrl={t.avatarUrl}
            detail={<div className={s.teamDetail}>
                {t.record ? <div className={s.record} title='Regular season record'>{t.record}</div> : null}
                {t.showCow ? <div className={s.cow} title='Chances of winning this game'><Badge bg={t.cowVariant} text={['light','warning'].includes(t.cowVariant) ? 'dark' : 'light'}>{numeral(t.cow).format('0.00%')}</Badge></div> : null}
                {t.seed && showSeed ? <div className={s.seed} title='Playoff seed'><Badge bg='warning' text='dark'>{t.seed}</Badge></div> : null}
                {t.tiebreaker && showSeed && afterDeadline ? <Badge className={s.tiebreaker} bg='dark'>{pluralize('point',t.tiebreaker, true)}</Badge> : null}
            </div>}
            right={<div className={s.score + (isLarge ? ` ${s.largeScore}` : ``)}>{t.score}</div>} 
            showPlayoffSeed={false}
            key={`league-scoreboard-game-${seasonId}-week-${week}-game-${game}-team-${t._id}`} />
        )}
        </ListGroup>
        : null}
        {showFooter && cot ?
        <Card.Footer className={s.gameFooter}>
            <div>{numeral(Math.round(cot * 100.0) / 100.0).format('0.00%')} chance of tie</div>
        </Card.Footer>
        : null}
        </Card>
    )
}

export default LeagueScoreboardGame
