import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Alert, FormControl, Button, Badge, FormLabel, 
    InputGroup, FormGroup, ListGroup } from 'react-bootstrap'
import moment from 'moment-timezone'
import pluralize from 'pluralize'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import SeasonSelector from '../SeasonSelector'
import NflTeamListItem from '../NflTeamListItem'
import FormCheck from 'react-bootstrap/FormCheck'
import Modal from '@severed-links/common.modal'
import { getNflScheduler, saveNflSchedule, scrapeNflSchedule, getUnscheduledSeasons } from '@severed-links/common.redherrings-reducers/footballAdmin'
import { getNflTeams } from '@severed-links/common.redherrings-reducers/football'
import { find, orderBy, forEach, uniq, filter, startsWith, endsWith, flatten, defaults } from 'lodash'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import * as s from './NflScheduler.scss'
import { FRIENDLY_SHORT_TIME_FORMAT, FRIENDLY_DATE_FORMAT, 
    FRIENDLY_SHORT_DATE_FORMAT, FRIENDLY_LONG_DATE_FORMAT, nflTeamImage } from '@severed-links/common.redherrings-constants'
import NflTeam from '../NflTeam'

const ByeWeekTeams = ({ teams = [] }) => (
    teams.length ?
    <ListGroup.Item className={s.nflScheduleGameListItem + ' ' + s.byeWeekTeams}>
        <div className={s.title}>Bye teams</div>
        <div className={s.teams}>
        {teams.map(t => <NflTeam {...t} roundedLogo showMascot teamId={t._id} showTeamName={false} className={s.team} key={`bye-week-${t._id}`} />)}
        </div>
    </ListGroup.Item>
    : null
)

const NflScheduler = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const params = useParams()
    const { seasons, seasonName: defaultSeasonName } = useSelector(state => state.football)
    const seasonName = params.seasonName || null
    const season = seasons.find(i => i.seasonName === seasonName)
    const seasonId = (season || {}).seasonId || null
    const _nflSchedulersList = useSelector(state => state.footballAdmin.nflSchedulers) || []
    const nflScheduler = _nflSchedulersList.find(i => i.seasonName === seasonName) || {}
    const unscheduledSeasons = useSelector(state => state.footballAdmin.unscheduledSeasons) || {}
    const _nflTeams = useSelector(state => state.football.nflTeams) || []

    const [timeZone, setTimeZone] = useState(moment.tz.guess())
    const [nflTeams, setNflTeams] = useState([])
    const [scheduleText, setScheduleText] = useState('')
    const [scheduleGames, setScheduleGames] = useState([])
    const [targetGameCount, setTargetGameCount] = useState(0)
    const [showSave, setShowSave] = useState(false)
    const [deleteExisting, setDeleteExisting] = useState(false)
    const [saveToDatabase, setSaveToDatabase] = useState(false)
    const [showConfirmModal, setShowConfirmModal] = useState(false)
    const [postData, setPostData] = useState({})
    const [isScraping, setScraping] = useState(false)
    const [isSaving, setSaving] = useState(false)

    useEffect(() => { dispatch(getNflTeams()) }, [])
    useEffect(() => { dispatch(getUnscheduledSeasons()) }, [])
    useEffect(() => { getSchedulerData() }, [seasonId])
    useEffect(() => { rerouteOnNoSeasonName() }, [seasonName, defaultSeasonName, unscheduledSeasons.nextUnscheduledNflSeasonName])
    useEffect(() => { analyzeSchedule() }, [_nflTeams.length, scheduleText, targetGameCount])
    useEffect(() => { runScheduleCountForNflTeams() }, [nflScheduler.updatedAt, _nflTeams.length, scheduleGames.length, targetGameCount])

    const rerouteOnNoSeasonName = () => {
        if (!seasonName && unscheduledSeasons.nextUnscheduledNflSeasonName) {
            navigate(`/football/admin/preseason/nfl-scheduler/${unscheduledSeasons.nextUnscheduledNflSeasonName}`)
        } else if (!seasonName && defaultSeasonName) {
            navigate(`/football/admin/preseason/nfl-scheduler/${defaultSeasonName}`)
        }
    }

    const getSchedulerData = () => {
        if (seasonId) {
            dispatch(getNflScheduler(seasonId))
        }
    }

    const scrapeEspnSchedule = () => {
        const _seasonParts = (seasonName || '').split('-')
        if (_seasonParts && _seasonParts.length > 0) {
            const _year = _seasonParts[0]
            setScraping(true)
            dispatch(scrapeNflSchedule(_year, timeZone))
            .then(action => {
                setScheduleText(action.payload.lines.join('\n'))
                setTargetGameCount(action.payload.targetGameCount || 0)
                setScraping(false)           
            })
        }

    }

    const analyzeSchedule = () => {
        if (!season || !season.seasonName) return null
        setScheduleGames([])
        var lines = scheduleText.split(/\n\r|\r\n|\n|\r/gi)
        var currentWeek = 0
        var gameNumber = 1
        var index = 0
        var currentDate = moment()
        var reWeek = /week\s+(\d+)/i
        var reNflMascots = new RegExp(_nflTeams.map(t => t.mascot.toLowerCase()).join('|'), 'gi')
        var reGameTime = /(\d+)(\:\d+)?\s?(a(m?)|p(m?)|a\.m\.|p\.m\.)/i
        var _scheduleGames = []
        const years = season.seasonName.split('-').map(i => parseInt(i))
        forEach(filter(lines, l => !startsWith(l, 'Bye')), line => {
            //detect week
            if (reWeek.test(line)) {
                currentWeek = parseInt(line.match(reWeek)[1], 10)
                gameNumber = 1
            } else if (moment(line).isValid()) {
                //detect date
                currentDate = moment(line)
                if (years.length === 2 && !years.some(year => currentDate.year() === year)) {
                    currentDate.year(currentDate.month() <= 6 ? years[1] : years[0])
                }
            }
            //detect game
            if (reNflMascots.test(line)) {
                var matchedTeams = line.match(reNflMascots)
                if (matchedTeams.length === 2) {
                    var visitor = find(nflTeams, { mascot: matchedTeams[0] })
                    var home    = find(nflTeams, { mascot: matchedTeams[1] })
                    var gameTime = ''
                    if (reGameTime.test(line)) {
                        var gameTimeMatches = line.match(reGameTime)
                        if (gameTimeMatches && gameTimeMatches.length >= 4) {
                            var gameTimeText = currentDate.format('M/D/YYYY') + ' ' + gameTimeMatches[1] + (gameTimeMatches[2] || ':00') + ' ' + gameTimeMatches[3].replace(/\./gi, '').toUpperCase()
                            if (gameTimeText.indexOf(':') === -1) gameTimeText = gameTimeText.replace(/(\d+)\s+(AM?|PM?)/i, '$1:00 $2')
                            if (!endsWith(gameTimeText, 'M')) gameTimeText += 'M'
                            gameTime = moment(gameTimeText).tz(timeZone)
                        }
                    }
                    _scheduleGames.push({ 
                        index: index, 
                        week: currentWeek, 
                        game: gameNumber, 
                        visitor: visitor, 
                        home: home, 
                        gameDate: gameTime, 
                        gameTime: moment(gameTime).format(FRIENDLY_DATE_FORMAT), 
                        isOnSameDay: index > 0 && gameTime && gameTime.isValid() && gameTime.isSame(_scheduleGames[_scheduleGames.length - 1].gameDate, 'day'),
                        isValid: gameTime && moment(gameTime).isValid() && !!visitor && !!home 
                    })
                    gameNumber++
                    index++
                }
            }
        })
        setScheduleGames(_scheduleGames)
    }

    const runScheduleCountForNflTeams = () => {
        const _nflTeamsWithCount = _nflTeams.map(t => ({
            ...t, 
            count: filter(scheduleGames, g => (g.home || {}).teamId === t._id || (g.visitor || {}).teamId === t._id).length
        }))
        const _showSave = scheduleGames.length && _nflTeamsWithCount.length && _nflTeamsWithCount.filter(i => i.count).length > 0 && _nflTeamsWithCount.filter(i => i.count).every(i => i.count === targetGameCount)
        setNflTeams(_nflTeamsWithCount)
        setShowSave(_showSave)
    }

    const handleChange = e => setScheduleText(e.target.value)

    const handleGameCountChange = e => setTargetGameCount(parseInt(e.target.value))

    const handleSave = () => {
        setPostData({ 
            seasonId: nflScheduler.seasonId,
            seasonName: nflScheduler.seasonName,
            deleteExisting,
            saveToDatabase,
            timeZone,
            schedule: scheduleGames && scheduleGames.map(g => ({
                seasonId: nflScheduler.seasonId, 
                week: g.week, 
                game: g.game,
                date: g.gameDate.toISOString(),
                gameDate: g.gameDate,
                gameTime: g.gameDate.format(FRIENDLY_SHORT_TIME_FORMAT),
                visitor: g.visitor.teamId,
                visitorTeam: g.visitor.nflTeam,
                visitorMascot: g.visitor.mascot,
                home: g.home.teamId,
                homeTeam: g.home.nflTeam,
                homeMascot: g.home.mascot,
                rawText: '',
                comment: '',
            }))
        })
        setShowConfirmModal(true)
    }

    const postSave = () => {
        if (postData) {
            setSaving(true)
            dispatch(saveNflSchedule(postData))
            .then(action => { 
                setShowConfirmModal(false)
                setPostData({})
                setSaving(false)
                dispatch(createNotification({
                    type: action.payload.messageType, 
                    headline: 'Save NFL Season Schedule', 
                    message: action.payload.message, 
                    timeout: 6000 
                }))
            })
        }
    }

    const handleTimeZoneChange = e => setTimeZone(e.target.value)

    const areAllGamesValid = () => {
        if (!scheduleGames || scheduleGames.length === 0) return false
        return filter(scheduleGames, g => g.isValid).length === scheduleGames.length
    }

    const getByeWeekTeams = _week => {
        const _weekGames = scheduleGames.filter(i => i.week === _week)
        const _playingTeams = flatten([..._weekGames.map(g => g.visitor), ..._weekGames.map(g => g.home)]) 
        return _nflTeams.filter(t => !_playingTeams.some(pt => pt._id === t._id))
    }

    return (
        season ?
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <div className={s.heading}>
                        <h2 className={s.header}>{seasonName} NFL Scheduler</h2>
                        <div className={s.controls}>
                            <Button variant='light' className={s.editNflTeamsButton} onClick={() => navigate('/football/admin/preseason/edit-nfl-teams')}><FontAwesomeIcon name='flag' /> edit NFL teams</Button>
                            <div className={s.seasonSelector}>
                                <SeasonSelector seasons={seasons} season={season} />
                            </div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    <FormGroup style={{ marginBottom: '1.5rem' }}>
                        <FormLabel>Time Zone for Schedule Dates</FormLabel>
                        <FormControl as='select' 
                            value={timeZone}
                            placeholder='Select a timezone...' 
                            onChange={(e) => handleTimeZoneChange(e)}>
                        {moment.tz.names().map(tz => 
                        <option value={tz} key={tz}>{tz}</option>
                        )}
                        </FormControl>
                    </FormGroup>
                    <FormGroup style={{ marginBottom: '1.5rem' }}>
                        <FormLabel style={{ width: '100%' }}>
                            <div className={s.scheduleHeading}>
                                <div>Schedule Text to Parse</div>
                                <Button size='sm' variant='info' onClick={() => scrapeEspnSchedule()}><FontAwesomeIcon name='bolt' spin={isScraping} /> {isScraping ? `fetching...` : `fetch from ESPN.com`}</Button>
                            </div>
                        </FormLabel>
                        <InputGroup>
                            <FormControl as='textarea' value={scheduleText}
                                placeholder='Paste NFL season schedule text here...' 
                                onChange={(e) => handleChange(e)} />
                            <Button variant='light' onClick={() => analyzeSchedule()}>go <FontAwesomeIcon name='chevron-right' /></Button>
                        </InputGroup>
                    </FormGroup>
                </Col>
            </Row>
            <Row>
                <Col md={8}>
                    <h4>
                        Generated NFL Team Schedule
                    </h4>
                    {showSave ?
                    <div>
                        {areAllGamesValid() ? <Alert variant='success'>All loaded games valid.</Alert> : scheduleGames.length > 0 ? <Alert variant='danger'>Invalid games located: {filter(scheduleGames, g => !g.isValid).length}.</Alert> : null}
                        <FormGroup className={s.formGroup}>
                            <FormLabel>Save to database as NFL season?</FormLabel>
                            <FormCheck type='switch' onChange={e => setSaveToDatabase(e.target.checked)} checked={saveToDatabase || false} />
                        </FormGroup>
                        <FormGroup className={s.formGroup}>
                            <FormLabel>Delete existing NFL Schedule records?</FormLabel>
                            <FormCheck type='switch' onChange={e => setDeleteExisting(e.target.checked)} checked={deleteExisting} />
                        </FormGroup>
                        <FormGroup className={s.formGroup}>
                            <Button variant='primary' onClick={() => handleSave()}><FontAwesomeIcon name='check' /> save schedule</Button>
                        </FormGroup>
                        <Modal heading={'Confirm Season Save?'} size={null}
                            show={showConfirmModal}
                            onClose={() => setShowConfirmModal(false)}
                            actionButtonOnClick={() => postSave()}
                            actionButtonText={isSaving ? 'saving...' : 'save'}
                            actionButtonDisabled={isSaving}>
                            <span>Proceed with saving {postData.seasonName} NFL season schedule?</span>
                        </Modal>

                    </div>
                    : null}
                    {scheduleGames && uniq(scheduleGames.map(g => g.week)).map(w =>
                        <div key={'week-' + w} className={s.weekContainer}>
                            <h4>Week {w} ({pluralize('game', scheduleGames.filter(i => i.week === w).length, true)})</h4>
                            <ListGroup className={s.nflScheduleGameList}>
                            {scheduleGames && filter(scheduleGames, g => g.week === w).map(g => [
                                !g.isOnSameDay ? <h6 key={`week-${w}-date-${g.index}`}>{g.gameDate && g.gameDate.isValid() ? g.gameDate.format(FRIENDLY_LONG_DATE_FORMAT) : 'TBD'}</h6> : null,
                                <ListGroup.Item className={s.nflScheduleGameListItem + ' ' + (g.isValid ? s.valid : s.invalid)} key={`week-${w}-game-${g.index}`}>
                                    <div className={s.game}><div className={s.gameHeading}>Game</div><div className={s.gameNumber}>{g.game}</div></div>
                                    <div className={s.teams}>
                                        <div className={s.avatar} style={{ backgroundImage: `url(${nflTeamImage((g.visitor || {}).mascot)})` }} />
                                        <div className={s.teamName}><div className={s.city}>{(g.visitor || {}).city}</div><div className={s.mascot}>{(g.visitor || {}).mascot}</div></div>
                                        <div className={s.versus}><FontAwesomeIcon name='at' /></div>
                                        <div className={s.teamName + ' ' + s.home}><div className={s.city}>{(g.home || {}).city}</div><div className={s.mascot}>{(g.home || {}).mascot}</div></div>
                                        <div className={s.avatar} style={{ backgroundImage: `url(${nflTeamImage((g.home || {}).mascot)})` }} />
                                    </div>
                                    <div className={s.timeContainer}><div className={s.time}>{moment(g.gameTime, FRIENDLY_DATE_FORMAT).format('h:mm')}</div><div className={s.meridian}>{moment(g.gameTime, FRIENDLY_DATE_FORMAT).format('A')}</div></div>
                                    <div className={s.validIcon + ' ' + (g.isValid ? s.valid : s.invalid)}><FontAwesomeIcon name={g.isValid ? 'check' : 'times'} /></div>
                                </ListGroup.Item>
                            ])}
                            <ByeWeekTeams teams={getByeWeekTeams(w)} />
                            </ListGroup>
                        </div>
                    )}
                    {!scheduleGames || (scheduleGames && scheduleGames.length == 0) ? 
                    <Alert variant='warning'>No schedule games loaded.</Alert>
                    : null}
                </Col>
                <Col md={4}>
                    <h4>NFL Team Schedule Count <FormControl className={s.targetGameCount} size='small' value={targetGameCount} onChange={e => handleGameCountChange(e)} /></h4>
                    <ListGroup className={s.nflTeamList}>
                    {nflTeams && nflTeams.map(t => 
                        <NflTeamListItem key={`nfl-team-schedule-summary-${t._id}`} 
                            teamId={t._id} teamName={t.nflTeam} city={t.city} mascot={t.mascot}
                            right={<div className={s.rightContainer}>
                                <div className={s.count + ' ' + (targetGameCount && t.count === targetGameCount ? s.valid : s.invalid)}>{t.count}</div>
                                <div className={s.icon + ' ' + (targetGameCount && t.count === targetGameCount ? s.valid : s.invalid)}>
                                    <FontAwesomeIcon name={targetGameCount && t.count === targetGameCount ? 'check' : 'times'} />
                                </div>
                            </div>} />
                    )}
                    </ListGroup>
                    {!nflTeams || (nflTeams && nflTeams.length == 0) ? 
                    <Alert variant='warning'>No NFL Teams loaded.</Alert>
                    : null}
                </Col>
            </Row>
        </div>
        : null
    )
}

export default NflScheduler