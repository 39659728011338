import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { FormGroup, FormLabel, FormControl, Button, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { isEmpty } from 'validator'
import * as s from '../ForgotPassword/ForgotPassword.scss'
import { resetForgotUsername, setUserInfo, requestUsername } from '@severed-links/common.redherrings-reducers/forgotUsername'

const ForgotUsernameConfirmation = forwardRef(({ jumpToStep }, _ref) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const fu = useSelector(state => state.forgotUsername)
    const _isValid = fu.userNameIsSent

    useEffect(() => {
        if ((fu.sendBy === 'Email' && fu.emailIsValid) || (fu.sendBy === 'Text' && fu.cellPhoneIsValid)) {
            dispatch(requestUsername(fu.sendBy, fu.sendBy === 'Email' ? fu.email : fu.sendBy === 'Text' ? fu.cellPhone : null))
        }
    }, [fu.sendBy, fu.emailIsValid, fu.cellPhoneIsValid])


    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValid
    }))

    return (
        <div>
            {fu.userNameIsSent ?
            <Alert variant='success'>
                <div>
                    <h4>Your user name has been sent.</h4>
                    <p>Click <a style={{ cursor: 'pointer' }} onClick={() => navigate('/login')}>here</a> to log in.</p>
                </div>
            </Alert>
            :null}
            {!fu.userNameIsSent && fu.userNameError ?
            <Alert variant='danger'>
                <div>
                    <h4>There was an error sending your user name.</h4>
                    <p>{fu.userNameErrorMessage}</p>
                </div>
            </Alert>
            :null}
        </div>
    )
})

export default ForgotUsernameConfirmation
