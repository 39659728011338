import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { getPayoutChecklist, updatePayoutChecklist } from '@severed-links/common.redherrings-reducers/footballAdmin'
import * as s from './PayoutChecklist.scss'
import PlayerTeamListItem from '../PlayerTeamListItem'
import numeral from 'numeral'
import { CURRENCY_FORMAT } from '@severed-links/common.redherrings-constants'

const PayoutChecklist = ({ seasonId }) => {

    const dispatch = useDispatch()
    const payoutChecklist = useSelector(state => state.footballAdmin.payoutChecklists.find(i => i.seasonId === seasonId) || { seasonId })

    useEffect(() => { seasonId ? dispatch(getPayoutChecklist(seasonId)) : null }, [seasonId])

    const handleClick = (teamId, playerId, isPaid) => {
        dispatch(updatePayoutChecklist(seasonId, teamId, playerId, isPaid))
        .then(action => {
            if (action.payload.messageType === 'success') {
                dispatch(getPayoutChecklist(seasonId))
            }
        })
    }

    if (!payoutChecklist) return null
    const { items } = payoutChecklist
    return (
        <div className={s.container}>
            <ListGroup className={s.list}>
            {items && items.map((i, _index) => 
            <PlayerTeamListItem {...i} className={s.item}
                detail={i.emailAddress} clickDisabled
                right={
                    <div className={s.rightContainer}>
                        <div className={s.amount +  ' ' + (i.isPaid ? s.isPaid : s.notPaid)}>{numeral(i.amount).format(CURRENCY_FORMAT)}</div>
                        <div className={s.paymentIcon +  ' ' + (i.isPaid ? s.isPaid : s.notPaid)}
                            onClick={() => handleClick(i.teamId, i.playerId, !i.isPaid)}>
                            <FontAwesomeIcon name={i.isPaid ? 'check-circle' : 'circle'} />
                        </div>
                    </div>
                }
                key={`payout-${i.playerId}-${_index}`} />
            )}
            </ListGroup>
        </div>
    )
}

export default PayoutChecklist
