import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Card, FormControl, FloatingLabel, FormGroup, Button, FormLabel, Alert, ListGroup } from 'react-bootstrap'
import { getProfile, saveProfile, checkProfile, getTimezonesByCountryCode, updateMyAvatar } from '@severed-links/common.redherrings-reducers/account'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { find, some, reject, omit, isEqual, first, keysIn } from 'lodash'
import { v4 as uuidv4 } from 'uuid'
import * as s from './EditMyProfile.scss'
import CloudinaryUploader from '../Cloudinary/CloudinaryUploader'
import validator from 'validator'
import Picker from '@severed-links/common.picker'
import { nflTeamImage } from '@severed-links/common.redherrings-constants'

const EditProfileField = ({ 
    type = 'text', label = '', fieldName = '', placeholder = '', 
    value = '', onChange, width = '100%', required = false, validate = false, 
    isValid = false, pickerOptions = null, openPicker = null,
    isColumn = false,
}) => (
    type === 'text' ?
    <FloatingLabel controlId={`label-${fieldName}`} label={label} 
        className={s.editProfileField + (!!pickerOptions ? ` ${s.isPicker}` : '')} 
        style={!isColumn ? { flex: `0 0 ${width}` } : null}>
        <FormControl type={type} placeholder={placeholder} 
            className={s.input} value={value}
            readOnly={!!pickerOptions}
            required={required} {...(validate ? { isValid, isInvalid: !isValid } : {})}
            onChange={e => onChange(e.target.value)}
            onClick={!!pickerOptions ? () => openPicker(pickerOptions) : null} />
    </FloatingLabel>
    : null
)

const EditProfilePanel = ({ title = '', fields = [], isColumn = false, onClickAccountDeletion }) => (
    <Card className={s.panel}>
        <Card.Header>{title}</Card.Header>
        {fields.length ?
        <Card.Body className={s.panelBody + (isColumn ? ` ${s.isColumn}` : '')}>
        {fields.map(x =>
        <EditProfileField {...x} isColumn={isColumn} />
        )}
        </Card.Body>
        : null}
        {onClickAccountDeletion ?
        <Card.Body className={s.panelBody + (isColumn ? ` ${s.isColumn}` : '')}>
            <Button className={s.accountDeletionButton} variant='outline-danger' size='sm' onClick={() => onClickAccountDeletion()}>Enter account deletion portal</Button>
        </Card.Body>
        : null}
    </Card>
)

const EditMyProfile = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const profile = useSelector(state => state.account.profile)
    const isFetching = useSelector(state => state.account.isFetching)
    const isAuthenticated = useSelector(state => state.account.isAuthenticated)
    const uploadedImages = useSelector(state => state.images)
    const hasCellPhone = useSelector(state => state.account.hasCellPhone)
    const hasEmail = useSelector(state => state.account.hasEmail)
    const hasAvatar = useSelector(state => state.account.hasAvatar)
    const isProfileValid = useSelector(state => state.account.isProfileValid)
    const playerAvatarFolder = useSelector(state => state.cloudinary.playerAvatarFolder)
    const [isLoading, setLoading] = useState(false)
    const [timezones, setTimezones] = useState([])
    const [_profile, setProfile] = useState({ _id: null, firstName: '', lastName: '', avatarUrl: null })
    const [_picker, setPickerOptions] = useState({ showPicker: false, pickerHeading: ``, onPickerSelect: null, pickerItems: [], pickerDefaultValue: `` })
    const { pickerHeading, showPicker, onPickerSelect, pickerItems, pickerDefaultValue } = _picker
    const { avatarUrl, firstName, lastName, address, city, state, 
        stateAbbreviation, states, zip, emailAddress, cellPhone, 
        occupation, timezone, favoriteProTeamId,
        nflTeams, isFootballPlayer,
        country, countryCode, countries } = _profile

    useEffect(() => { isAuthenticated ? loadProfile() : null }, [isAuthenticated])
    useEffect(() => { countryCode ? handleCountryCodeChange() : null }, [countryCode])

    const loadProfile = () => {
        setLoading(true)
        dispatch(checkProfile())
        dispatch(getProfile())
        .then(response => {
            setProfile({ ..._profile, ...response.payload })
            setLoading(false)
            dispatch(getTimezonesByCountryCode(response.payload.countryCode || 'US'))
            .then(action => setTimezones(action.payload))
        })
    }

    const updateAvatarUrl = (_uploadResults = {}) => {
        const { public_id: _avatarUrl } = _uploadResults
        if (_avatarUrl) {
            setProfile({ ..._profile, avatarUrl: _avatarUrl })
            dispatch(updateMyAvatar(_avatarUrl))
        }
    }

    const handleChange = e => handleFieldChange(e.target.name, e.target.value)

    const handleCountryCodeChange = () => {
        dispatch(getTimezonesByCountryCode(countryCode))
        .then(action => {
            const timezones = action.payload || []
            const _firstTimezone = (_.first(timezones) || {}).value || null
            setTimezones(timezones)
            if (!timezones.some(i => i.value === timezone)) {
                setProfile({ ..._profile, timezone: _firstTimezone })
            }
        })
    }

    const handleFieldChange = (fieldName, value) => {
        var _stateUpdate = { [fieldName]: value }
        if (fieldName === 'stateAbbreviation') {
            const _state = states.find(i => i.value === value)
            if (_state) {
                _stateUpdate.state = _state.text
            }
        } else if (fieldName === 'countryCode') {
            const _country = countries.find(i => i.value === value)
            if (_country) {
                _stateUpdate.country = _country.text
            }
        }        
        setProfile({ ..._profile, ..._stateUpdate })
    }

    const noteOnSave = () => {
        var _saveProfile = omit({ ..._profile }, ['avatarUrl','isFootballPlayer','nflTeams', 'states','timezones','countries'])
        dispatch(saveProfile(_saveProfile))
        .then(response => { 
            dispatch(createNotification({ type: response.payload.messageType || 'warning', headline: 'Profile Save', message: response.payload.message || 'Unknown response', timeout: 7500 }))
            dispatch(checkProfile())
        })
    }

    const handleAccountDeletionClick = () => {
        navigate(`/${params.appName || 'main'}/user-profile/account-deletion`)
    }

    const _firstNameIsValid = !validator.isEmpty(firstName || '')
    const _lastNameIsValid = !validator.isEmpty(lastName || '')
    const _emailAddressIsValid = validator.isEmail(emailAddress || '')
    const _cellPhoneIsValid = validator.isNumeric(cellPhone || '') && 
        validator.isLength(cellPhone || '', { min: 10, max: 10 })

    const profileIsValid = () => _firstNameIsValid && _lastNameIsValid &&
        _emailAddressIsValid && _cellPhoneIsValid            

    const openPicker = (_pickerOptions = {}) => setPickerOptions({ ..._picker, ..._pickerOptions, showPicker: true })

    const closePicker = () => setPickerOptions({ ..._picker, showPicker: false })

    const _nameFields = [
        { label: 'First name', fieldName: 'firstName', value: firstName, required: true, validate: true, isValid: _firstNameIsValid, width: `48%` },
        { label: 'Last name', fieldName: 'lastName', value: lastName, required: true, validate: true, isValid: _lastNameIsValid, width: `48%` },
        { label: 'Street address', fieldName: 'address', value: address, required: false, validate: false },
        { label: 'City', fieldName: 'city', value: city, required: false, validate: false, width: `50%` },
        countryCode === 'US' ? { label: 'State', fieldName: 'stateAbbreviation', value: state, required: false, validate: false, width: `20%`, pickerOptions: { pickerHeading: `Select a state...`, pickerDefaultValue: stateAbbreviation, pickerItems: (states || []).map(st => ({ ...st, title: st.text, icon: 'map-marker' })), onPickerSelect: _item => handleFieldChange('stateAbbreviation', _item.value) } } : null,
        { label: countryCode === 'US' ? 'Zip' : 'Postal Code', fieldName: 'zip', value: zip, required: false, validate: false, width: `25%` },
    ].filter(i => i)
    const _contactFields = [
        { label: 'Email address', fieldName: 'emailAddress', value: emailAddress, required: true, validate: true, isValid: _emailAddressIsValid, width: `100%` },
        { label: 'Cell phone (10 digits only)', fieldName: 'cellPhone', value: cellPhone, required: true, validate: true, isValid: _cellPhoneIsValid, width: `100%` },
    ].filter(i => i)
    const _locationFields = [
        { label: 'Time zone', fieldName: 'timezone', value: timezone, required: false, validate: false, width: `100%`, pickerOptions: { pickerHeading: `Select a time zone...`, pickerDefaultValue: timezone, pickerItems: (timezones || []).map(tz => ({ ...tz, title: tz.text, icon: 'clock' })), onPickerSelect: _item => handleFieldChange('timezone', _item.value) } },
        { label: 'Country', fieldName: 'countryCode', value: country, required: false, validate: false, width: `100%`, pickerOptions: { pickerHeading: `Select a country...`, pickerDefaultValue: countryCode, pickerItems: (countries || []).map(c => ({ ...c, title: c.text, icon: 'globe' })), onPickerSelect: _item => handleFieldChange('countryCode', _item.value) } },
    ].filter(i => i)
    const _detailFields = [
        { label: 'Occupation', fieldName: 'occupation', value: occupation, required: false, validate: false },
        isFootballPlayer ? { label: 'Favorite NFL team', fieldName: 'favoriteProTeamId', value: (nflTeams.find(x => favoriteProTeamId && x.value === favoriteProTeamId) || {}).nflTeam || '[Not selected]', required: false, validate: false, width: `100%`, pickerOptions: { pickerHeading: `Choose a team...`, pickerDefaultValue: favoriteProTeamId, pickerItems: (nflTeams || []).map(t => ({ ...t, title: t.nflTeam, url: nflTeamImage(t.mascot) })), onPickerSelect: _item => handleFieldChange('favoriteProTeamId', _item.value) } } : null,
    ].filter(i => i)
    const _panels = [
        { title: `Name and Address`, fields: _nameFields },
        { title: `Contact Details`, fields: _contactFields },
        { title: `Location Details`, fields: _locationFields },
        { title: `Various Details`, fields: _detailFields },
        { title: `Account Deletion`, fields: [], showAccountDeletionButton: true },
    ].map(i => ({ ...i, fields: i.fields.map(x => ({ ...x, type: x.type || 'text', placeholder: x.placeholder || `[${i.label}]`, onChange: x.onChange || (_value => handleFieldChange(x.fieldName, _value)), openPicker: _pickerOptions => openPicker(_pickerOptions), key: `edit-profile-field-${x.fieldName}` })), isColumn: i.fields.every(x => !x.width || x.width === '100%') }))
    return (
        <div className={s.container}>
            {isLoading ?
            <div className={s.spinner}>
                <FontAwesomeIcon name='circle-notch' spin size='3x' /> 
            </div>
            :
            <div>
                <div className={s.heading}>
                    <div className={s.avatarContainer}>
                        <CloudinaryUploader originalId={avatarUrl} 
                            folder={playerAvatarFolder}
                            showButton={false}
                            showClickLabel={false}
                            showAccessory
                            transformationName='large_avatar_200'
                            onUploadSuccess={updateAvatarUrl} />
                    </div>
                    <h2 className={s.title}>Edit My Profile</h2>
                    <div className={s.controls}>
                        <Button size='lg' 
                            variant='light' onClick={() => navigate(-1)}>
                            <FontAwesomeIcon name='times' />
                            <span className={s.label}>cancel</span>
                        </Button>
                        <Button size='lg' disabled={!profileIsValid()}
                            variant='primary' onClick={() => noteOnSave()}>
                            <FontAwesomeIcon name={!isFetching ? 'check' : 'circle-notch' } spin={isFetching} />
                            <span className={s.label}>{!isFetching ? 'save' : 'saving...'}</span>                            
                        </Button>
                    </div> 
                </div>

                {!isProfileValid ? 
                    <Alert variant='info'>
                        {!hasAvatar ? <p>We would love it if you would update your avatar to give the site a more personal touch for each player.</p> : null}
                        {!hasCellPhone || !hasEmail ? <p>Please make sure your cell phone and email address are up-to-date.  The "forgot password" page will rely on these items being accurate so you can obtain a reset link if you ever forget your password.</p> : null}
                        <p>Thanks!</p>
                    </Alert>
                : null}

                <div className={s.panelContainer}>
                {_panels && _panels.map(i => 
                    <EditProfilePanel {...i} 
                        onClickAccountDeletion={i.showAccountDeletionButton ? handleAccountDeletionClick : null}
                        key={`profile-panel-${i.title}`} />
                )}
                </div>

            <Picker show={showPicker} onClose={() => closePicker()}
                heading={pickerHeading}
                items={pickerItems}
                onSelect={onPickerSelect}
                defaultValue={pickerDefaultValue} />

            </div>
            }
            </div>
    )
}

export default EditMyProfile
