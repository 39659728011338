import React, { useEffect } from 'react'
import { Pagination } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { createUltimatePagination } from 'react-ultimate-pagination'
import * as s from './RedHerringsPagination.scss'

const WrapperComponent = ({children}) => (
    <Pagination className={s.container}>{children}</Pagination>
)
  
const Page = ({value, isActive, onClick}) => (
    <Pagination.Item className={s.pageItem + (isActive ? ' active' : '')} onClick={onClick}>{value}</Pagination.Item>
)
       
const Ellipsis = ({onClick}) => (
    <Pagination.Ellipsis onClick={onClick}>...</Pagination.Ellipsis>
)

const FirstPageLink = ({isActive, onClick}) => (
    <Pagination.First onClick={onClick}><FontAwesomeIcon name='angle-double-left' /></Pagination.First>
)

const PreviousPageLink = ({isActive, onClick}) => (
    <Pagination.Prev onClick={onClick}><FontAwesomeIcon name='angle-left' /></Pagination.Prev>
)

const NextPageLink = ({isActive, onClick}) => (
    <Pagination.Next onClick={onClick}><FontAwesomeIcon name='angle-right' /></Pagination.Next>
)

const LastPageLink = ({isActive, onClick}) => (
    <Pagination.Last onClick={onClick}><FontAwesomeIcon name='angle-double-right' /></Pagination.Last>
)

const itemTypeToComponent = {
    'PAGE': Page,
    'ELLIPSIS': Ellipsis,
    'FIRST_PAGE_LINK': FirstPageLink,
    'PREVIOUS_PAGE_LINK': PreviousPageLink,
    'NEXT_PAGE_LINK': NextPageLink,
    'LAST_PAGE_LINK': LastPageLink
}

const RedHerringsPagination = createUltimatePagination({itemTypeToComponent, WrapperComponent})

export default RedHerringsPagination