import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FormCheck, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { isEmpty } from 'validator'
import * as s from './SignUp.scss'
import { setUserInfo } from '@severed-links/common.redherrings-reducers/siteRegistration'

const InfoConfirmStep5 = forwardRef(({ jumpToStep }, _ref) => {

    const dispatch = useDispatch()
    const siteRegistration = useSelector(state => state.siteRegistration)
    const [allFieldsValid, setAllFieldsValid] = useState(false)
    const [confirmed, setConfirmed] = useState(false)
    const _isValid = allFieldsValid && siteRegistration.infoIsConfirmed

    useEffect(() => {
        if (siteRegistration.nextDisabled !== !_isValid) {
            dispatch(setUserInfo({ nextDisabled: !_isValid }))
        }
    }, [siteRegistration.nextDisabled, _isValid])

    useEffect(() => {
        setConfirmed(siteRegistration.infoIsConfirmed || false)
    }, [])

    useEffect(() => { checkAll() }, [siteRegistration.passphraseIsValid, siteRegistration.contest, siteRegistration.userNameIsAvailable, siteRegistration.passwordIsValid, siteRegistration.firstName, siteRegistration.lastName, siteRegistration.emailIsValid, siteRegistration.emailIsAvailable])

    const handleChange = e => {
        const _isChecked = e.target.checked || false
        setConfirmed(_isChecked)
        dispatch(setUserInfo({ infoIsConfirmed: _isChecked }))
    }

    const checkAll = () => {
        setAllFieldsValid(siteRegistration.passphraseIsValid &&
            !isEmpty(siteRegistration.contest) &&
            siteRegistration.userNameIsAvailable &&
            siteRegistration.passwordIsValid &&
            !isEmpty(siteRegistration.firstName) &&
            !isEmpty(siteRegistration.lastName) &&
            siteRegistration.emailIsValid &&
            siteRegistration.emailIsAvailable
        )
    }

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValid
    }))

    if (!siteRegistration) return null
    const { infoIsConfirmed } = siteRegistration
    const confirmData = [
        { step: 0, name: 'Contest', value: `${siteRegistration.passphrase} (${siteRegistration.contest})`, isValid: siteRegistration.passphraseIsValid && !isEmpty(siteRegistration.contest) },
        { step: 1, name: 'Username/Password', value: `${siteRegistration.userName} (Password: ${'\u25CF'.repeat(siteRegistration.password.length)})`, isValid: siteRegistration.userNameIsAvailable && siteRegistration.passwordIsValid },
        { step: 3, name: 'Name', value: `${siteRegistration.firstName} ${siteRegistration.lastName}`, isValid: !isEmpty(siteRegistration.firstName) && !isEmpty(siteRegistration.lastName) },
        { step: 3, name: 'Email', value: `${siteRegistration.email}`, isValid: siteRegistration.emailIsValid && siteRegistration.emailIsAvailable },
    ]
    return (
        <div className={s.step5 + ' ' + s.step}>
            <ListGroup className={s.list}>
            {confirmData && confirmData.map((i, index) =>
                <ListGroup.Item className={s.item} variant={i.isValid ? 'success' : 'danger'} key={`registration-confirm-${index}`}>
                    <div className={s.name}>{i.name}</div>
                    <div className={s.value}>{i.value}</div>
                </ListGroup.Item>
            )}
            {!allFieldsValid ? 
                <ListGroup.Item className={s.item} variant='danger'>
                    <p>Your information is not valid.</p>
                </ListGroup.Item>
            :
                <ListGroup.Item className={s.item} variant='success'>
                    <FormCheck type='checkbox' 
                        checked={infoIsConfirmed} 
                        label={infoIsConfirmed ? 'My information is correct, click Register to create your account!' : 'Click to confirm your account information.'}
                        onChange={e => handleChange(e)} />
                </ListGroup.Item>
            }
            </ListGroup>
        </div>
    )
})

export default InfoConfirmStep5
