import React, { useEffect, useState } from 'react'
import { Table, Alert } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import * as s from './PreseasonCountdown.scss'
import moment from 'moment-timezone'
import { isEqual } from 'lodash'
import { useDispatch } from 'react-redux'

const PreseasonCountdown = ({ nflKickoff = '', prefix = '', smallPrefix = '', elapsed = '' }) => {

    const dispatch = useDispatch()
    const [countdown, setCountdown] = useState({})
    const [isElapsed, setElapsed] = useState(false)
    const [initialLoad, setInitialLoad] = useState(false)

    useEffect(() => {
        let timerId = setInterval(() => updateCountdown(), 1000)
        return () => clearInterval(timerId)
    }, [])

    const updateCountdown = () => {
        if (nflKickoff && moment(nflKickoff).isValid()) {
            var kickoff = moment(nflKickoff)
            const duration = moment.duration(kickoff.diff(moment()))
            setInitialLoad(true)
            setElapsed(duration._milliseconds <= 0)
            setCountdown(duration._data)
        }
    }

    if (!initialLoad) return null
    return (
        <div className={s.container}>
        {!isElapsed ?
            <div>
            {countdown ?
            <>
            <div className={s.contentContainer}>
                <div className={s.content}>
                    {prefix ? <h4 className={s.prefix}>{prefix}</h4> : null}
                    {smallPrefix ? <h4 className={s.smallPrefix}>{smallPrefix}</h4> : null}
                </div>
            </div>
            <div className={s.contentContainer}>
                <div className={s.content}>
                    {countdown.years ? <div className={s.item}><div className={s.heading}>Years</div><div className={s.data}>{countdown.years}</div></div>: null}
                    {countdown.months ? <div className={s.item}><div className={s.heading}>Months</div><div className={s.data}>{countdown.months}</div></div>: null}
                    {countdown.days ? <div className={s.item}><div className={s.heading}>Days</div><div className={s.data}>{countdown.days}</div></div>: null}
                    <div className={s.item}><div className={s.heading}>Hours</div><div className={s.data}>{countdown.hours}</div></div>
                    <div className={s.item}><div className={s.heading}>Minutes</div><div className={s.data}>{countdown.minutes}</div></div>
                    <div className={s.item}><div className={s.heading}>Seconds</div><div className={s.data}>{countdown.seconds}</div></div>
                </div>
            </div>
            </>
            : null}
            </div>
        : <Alert variant='success' className={s.alertDefault}>{elapsed}</Alert>}            
        </div>
    )
}

export default PreseasonCountdown