import React, { useEffect } from 'react'
import { ListGroup } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import DivisionStandingsRow from './DivisionStandingsRow'
import * as s from './DivisionStandings.scss'

const DivisionStandings = ({ division = {}, playerId = null, shortForm = false }) => {

    const d = division
    if (!d) return null
    return (
        <div key={d.divisionCode} className={s.container}>
            <h4>{d.divisionName} Division</h4>
            <ListGroup>
                {d.standings && d.standings.map(s => 
                <DivisionStandingsRow key={s.teamId} 
                    divisionItem={s} 
                    divisionCode={d.divisionCode} showPlayoffSeed={false}
                    playerId={playerId} 
                    shortForm={shortForm} />
                )}
                {!d.standings || !d.standings.length ?
                <ListGroup.Item>
                    No teams located for this division.
                </ListGroup.Item>
                : null}
            </ListGroup>
        </div>
    )
}

export default DivisionStandings