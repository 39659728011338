import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './ScoreboardTitleEntryInfo.scss'

const ScoreboardTitleEntryInfo = ({ totalEntries = 0, yourItems = [], show = false, goToEntry }) => {

    const yourItemsLegend = yourItems.length === 1 ? 'Your entry ranks: ' : 'Your entries rank: '
    const MooseHunter = '[MooseHunter]'
    return (
        <h5 className={s.container + (show ? '' : ' ' + s.hide)}>
            <span style={{ marginRight: '10px' }}>{totalEntries} {totalEntries !== 1 ? 'entries' : 'entry' }</span>
            {yourItems.length > 0 ? 
                <span>
                    {yourItemsLegend}
                    <span style={{ whiteSpace: 'nowrap' }}>
                    {yourItems.map(i => 
                        <Button key={`your-entry-${i._id}`} variant='link' 
                            style={{ padding: '0px 0px 0px 3px' }}
                            onClick={() => goToEntry(i._id)}>
                            {i.rankDisplay}
                        </Button>
                    )}
                    </span>
                </span>
            : null}
        </h5>
    )
}

export default ScoreboardTitleEntryInfo