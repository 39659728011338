import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useMatch } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'
import { getPoolGeographyReport } from '@severed-links/common.redherrings-reducers/football'
import SeasonSelector from '../SeasonSelector'
import * as s from './FootballLeagueReport.scss'
import { State } from 'react-stateface'

const PoolGeographyReport = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const _match = useMatch(`/football/reports/:reportId/*`)
    const seasonName = params.seasonName || null
    const reportId = ((_match || {}).params || {}).reportId || null
    const seasons = useSelector(state => state.football.seasons)
    const playerId = useSelector(state => state.account._id)
    const reports = useSelector(state => state.football.reports)
    const season = seasons.find(i => i.seasonName === seasonName) || {}
    const report = reports.find(i => i.seasonId === season.seasonId && i.reportId === reportId)

    useEffect(() => { fetchReport() }, [seasonName])

    const fetchReport = () => seasonName ? dispatch(getPoolGeographyReport(seasonName)) : null

    if (!report) return null
    return (
            season && season.seasonName && report && report.items ? 
            <div className={s.container}>
                <div className={s.titleBar}>
                    <h2 className={s.title}>{season.seasonName} Player Geography</h2>
                    <div className={s.seasonSelector}><SeasonSelector season={season} seasons={seasons} /></div>
                </div>
                <ListGroup className={s.reportList}>
                {report && report.items && report.items.map(i => 
                    <ListGroup.Item className={s.item} key={`pool-geography-${i.countryCode}-${i.stateAbbreviation}`}>
                        <div className={s.stateIconContainer}>{i.stateAbbreviation ? <State state={i.stateAbbreviation} /> : null}</div>
                        <div className={s.stateName}>{i.state || (!i.isUS && i.country) || '[Not provided]'}</div>
                        <div className={s.value}>{i.score}</div>
                    </ListGroup.Item>
                )}
                {!report.items || report.items.length === 0 ?  
                    <ListGroup.Item>No records located.</ListGroup.Item>
                : null}
                </ListGroup>
            </div>
            : null
    )
}

export default PoolGeographyReport