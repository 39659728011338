import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './EliminatorWinners.scss'
import pluralize from 'pluralize'

const EliminatorWinnerCardFooter = ({ isFinished, week, seasonType, playerCount, ...props }) => (
    <Card.Footer className={s.footer}>
        <div className={s.throughWeek}>
            {isFinished ? 'Finished in' : 'Through'}
            {' '}
            {seasonType === 'PW' ? 'Postseason' : ''}
            {' '}
            Week {week}
        </div>
        <div className={s.playerCountContainer}>
            <div className={s.playerCount}>{playerCount}</div>
            <div className={s.playerCountText}>{pluralize('player', playerCount, false)}</div>
        </div>
    </Card.Footer>
)

export default EliminatorWinnerCardFooter