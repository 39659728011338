// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TeamDataEditTeam__container___jg7Dl .TeamDataEditTeam__formGroup___rIQbZ{margin-bottom:1rem}.TeamDataEditTeam__container___jg7Dl .TeamDataEditTeam__formGroup___rIQbZ .TeamDataEditTeam__teamDataAvatarContainer___bhoxF{display:flex;flex-flow:column;justify-content:flex-end;align-items:stretch}.TeamDataEditTeam__container___jg7Dl .TeamDataEditTeam__formGroup___rIQbZ .TeamDataEditTeam__teamDataAvatarContainer___bhoxF .TeamDataEditTeam__avatarContainer___LaXtR{background-repeat:no-repeat;background-size:contain;background-position:center center;height:80px}.TeamDataEditTeam__container___jg7Dl .TeamDataEditTeam__formGroup___rIQbZ .TeamDataEditTeam__teamDataAvatarContainer___bhoxF .TeamDataEditTeam__teamDataAvatarButton___yAogP{align-self:flex-end}`, "",{"version":3,"sources":["webpack://./src/routes/Football/Admin/TeamDataEditTeam.scss"],"names":[],"mappings":"AACI,0EACI,kBAAA,CAEA,6HACI,YAAA,CACA,gBAAA,CACA,wBAAA,CACA,mBAAA,CAEA,wKACI,2BAAA,CACA,uBAAA,CACA,iCAAA,CACA,WAAA,CAGJ,6KACI,mBAAA","sourcesContent":[".container {\n    .formGroup { \n        margin-bottom: 1rem;\n\n        .teamDataAvatarContainer {\n            display: flex;\n            flex-flow: column;\n            justify-content: flex-end;\n            align-items: stretch;\n\n            .avatarContainer {\n                background-repeat: no-repeat;\n                background-size: contain;\n                background-position: center center;\n                height: 80px;\n            }\n\n            .teamDataAvatarButton {\n                align-self: flex-end;\n            }\n        }\n    }\n\n}"],"sourceRoot":""}]);
// Exports
export var container = `TeamDataEditTeam__container___jg7Dl`;
export var formGroup = `TeamDataEditTeam__formGroup___rIQbZ`;
export var teamDataAvatarContainer = `TeamDataEditTeam__teamDataAvatarContainer___bhoxF`;
export var avatarContainer = `TeamDataEditTeam__avatarContainer___LaXtR`;
export var teamDataAvatarButton = `TeamDataEditTeam__teamDataAvatarButton___yAogP`;
export default ___CSS_LOADER_EXPORT___;
