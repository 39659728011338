import React, { useEffect, useState } from 'react'
import { Button, Badge, Table, ButtonGroup } from 'react-bootstrap'
import * as s from './EnterPicksGameItem.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'

import EnterPicksGameMatchupTable from './EnterPicksGameMatchupTable'
import EnterPicksRecentGame from './EnterPicksRecentGame'
import FavoriteUnderdogButton from './FavoriteUnderdogButton'
import LineContainer from './LineContainer'
import RankButtonsContainer from './RankButtonsContainer'
import { getEnterPicksNFLMatchUp } from '@severed-links/common.redherrings-reducers/football'
import { useDrag } from 'react-dnd'
import { DRAG_AND_DROP_ITEM_TYPES } from '@severed-links/common.redherrings-constants'
import { useDispatch } from 'react-redux'

const EnterPicksGameItem = ({ game = {}, pickGameData, seasonName, addCommand, ranks = [] }) => {

    const dispatch = useDispatch()
    const [showGameData, setShowGameData] = useState(false)
    const [showRankOptions, setShowRankOptions] = useState(false)

    const [{ isDragging }, _dragRef, _previewRef] = useDrag(() => ({
        type: DRAG_AND_DROP_ITEM_TYPES.REGULAR_SEASON_GAME,
        collect: (monitor, props) => ({ isDragging: monitor.isDragging() }),
        item: () => ({ game: game.game }),
        end: (item, monitor) => {
            const dropResult = monitor.getDropResult()
            if (dropResult && addCommand) {
                addCommand({ type: 'changeRank', ...dropResult, game: game.game, oldRank: game.rank })
            }    
        }
    }), [game.game, game.rank])

    const handleClick = (game, pick) => dispatch(addCommand({ type: 'makePick', game, pick }))

    const getGameData = () => {
        setShowGameData(true)
        if (!pickGameData) {
            dispatch(getEnterPicksNFLMatchUp(seasonName, game.week, game.game))
        }
    }

    const hideGameData = () => setShowGameData(false)

    const toggleMiniRankMenu = (e, _showRankOptions) => {
        setShowRankOptions(_showRankOptions)
        e.stopPropagation()
    }

    const handleMiniRankClick = (e, newRank) => {
        toggleMiniRankMenu(e, false)
        addCommand({ type: 'changeRank', ...{ newRank }, game: game.game, oldRank: game.rank })
    }

    const isMini = window.innerWidth <= 768
    return (
        <div ref={_previewRef} className={s.container + (isDragging ? ' ' + s.isDragging : '') + (showGameData ? ' ' + s.showPickGameDataContainer : '')}>

            <div ref={_dragRef} className={s.sortHandleContainer} onClick={e => toggleMiniRankMenu(e, !showRankOptions)}>
                <FontAwesomeIcon name='sort' />
            </div>

            <RankButtonsContainer ranks={ranks} 
                game={game}
                show={showRankOptions} 
                onClick={handleMiniRankClick} 
                onClose={toggleMiniRankMenu} />
            <FavoriteUnderdogButton game={game} pick='Favorite' isMini={isMini} onClick={handleClick} />
            <LineContainer game={game} isMini={isMini} />
            <FavoriteUnderdogButton game={game} pick='Underdog' isMini={isMini} onClick={handleClick} />
            <div className={s.gameInfoContainer} onClick={showGameData ? () => hideGameData() : () => getGameData()}>
                <FontAwesomeIcon name={showGameData ? 'chevron-circle-down' : 'info-circle'} />
            </div>
            {pickGameData && showGameData ?
            <div className={s.pickGameDataContainer}>
                <EnterPicksGameMatchupTable matchUp={pickGameData.matchUp} homeFavorite={game.homeFavorite} />
                <div>
                    <div>
                        <div className={s.recentGameContainer}>
                        {pickGameData.recentGames && pickGameData.recentGames.map((r, index) => 
                            <EnterPicksRecentGame game={r} index={index} key={`recent-game-item-${index}`} />
                        )}
                        </div>
                    </div>
                </div>
            </div>
            : null}
        </div>
    )
}
    
export default EnterPicksGameItem
    