import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Card, FormGroup, InputGroup, FormControl, Button, ListGroup, 
    Popover, Overlay, Tooltip, Image, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './EditNflPlayoffGame.scss'
import NflTeamListItem from '../NflTeamListItem'
import Line from '../Line'
import OverUnder from '../OverUnder'
import { FRIENDLY_DATE_FORMAT, FRIENDLY_SHORT_DATE_FORMAT, 
    FRIENDLY_SHORT_TIME_FORMAT, nflTeamImage, img } from '@severed-links/common.redherrings-constants'
import Datetime from 'react-datetime'
import moment from 'moment-timezone'
import { setNflPlayoffGameTime, saveNflPlayoffGameTime, saveNflPlayoffGameTeam, 
    saveNflPlayoffGameHomeFavorite, saveNflPlayoffGameValue } from '@severed-links/common.redherrings-reducers/footballAdmin'
import { filter, keysIn, startsWith, trim } from 'lodash'
import FormCheck from 'react-bootstrap/FormCheck'
import numeral from 'numeral'
import CloudinaryImage from '../../Cloudinary/CloudinaryImage'
import Repeatable from 'react-repeatable'
import { capitalCase } from 'change-case'
import Modal from '@severed-links/common.modal'

const RepeatableButton = ({ type = 'line', icon, value, offset, onClick }) => (
    <Repeatable tag='button' type='button' 
        className={s.offsetButton + ' btn btn-primary'}
        onClick={() => onClick(type, value, offset)}
        onHold={() => onClick(type, value, offset)}>
        <FontAwesomeIcon name={icon} />
    </Repeatable>
)

const BlankNflPlayoffSlot = ({ homeVisitor = 'home', onClick }) => (
    <ListGroup.Item className={s.nflPlayoffsBlankItem} action onClick={() => onClick(homeVisitor)}>
        <div className={s.teamAvatar}><CloudinaryImage publicId={img.FootballBlankHelmet} /></div>
        <div className={s.teamInsertText}>[Click to choose the {homeVisitor.toLowerCase()} team...]</div>
    </ListGroup.Item>
)

const NflTeamSelectorButton = ({ icon, teamId, mascot, onClick }) => (
    <Button className={s.nflTeamSelectorButton + (icon === 'trash' ? ` ${s.delete}` : ``) + (icon === 'times' ? ` ${s.close}` : ``)} 
        style={mascot ? { backgroundImage: `url(${nflTeamImage(mascot)})` } : null}
        onClick={() => onClick(teamId)}>
        {icon ? <FontAwesomeIcon name={icon} className={s.icon} /> : null}
    </Button>
)

const NflTeamSelector = ({ nflTeams, onSelect, onClose, homeVisitor }) => (
<ListGroup.Item className={s.nflTeamSelectorItem}>
    <h4 style={{ marginRight: '1rem' }}><Badge variant='info'>{capitalCase(homeVisitor)}</Badge></h4>
    {nflTeams && nflTeams.map(t => 
    <NflTeamSelectorButton key={`nfl-team-selector-button-${t._id}`} 
        teamId={t._id} mascot={t.mascot} icon={false}
        onClick={() => onSelect(t._id)} />
    )}
    <NflTeamSelectorButton key={`nfl-team-selector-button-delete`}
        icon={'trash'} onClick={() => onSelect(null)} />
    <NflTeamSelectorButton key={`nfl-team-selector-button-close`}
        icon={'times'} onClick={() => onClose()} />
</ListGroup.Item>
)

const SAVE_DELAY_SECONDS = 0.5

const EditNflPlayoffGame = ({ game = null }) => {

    const dispatch = useDispatch()
    const seasonId = game.seasonId || null
    const nflPlayoffGames = (useSelector(state => state.footballAdmin.nflPlayoffGames)[`${seasonId}`] || {}).playoffGames || []
    const nflTeams = useSelector(state => state.football.nflTeams)
    const [homeVisitor, setHomeVisitor] = useState(null)
    const [showHome, setShowHome] = useState(false)
    const [showVisitor, setShowVisitor] = useState(false)
    const [line, setLine] = useState(`${(game?.line || 0).toFixed(1)}`)
    const [lineTimeoutId, setLineTimeoutId] = useState(null)
    const [ou, setOu] = useState(`${(game?.ou || 0).toFixed(1)}`)
    const [ouTimeoutId, setOuTimeoutId] = useState(null)
    const [editHeadlineMode, setEditHeadlineMode] = useState(false)
    const [editHeadline, setEditHeadline] = useState('')

    useEffect(() => { game.line ? setLine(`${game.line.toFixed(1)}`) : null }, [game.line])
    useEffect(() => { game.ou ? setOu(`${game.ou.toFixed(1)}`) : null }, [game.ou])
    useEffect(() => {
        if (parseFloat(line) !== game.line) {
            if (lineTimeoutId) clearTimeout(lineTimeoutId)
            setLineTimeoutId(setTimeout(() => handleLineOuSave('line', line), SAVE_DELAY_SECONDS * 1000))
        }
    }, [line])
    useEffect(() => {
        if (parseFloat(ou) !== game.ou) {
            if (ouTimeoutId) clearTimeout(ouTimeoutId)
            setOuTimeoutId(setTimeout(() => handleLineOuSave('ou', ou), SAVE_DELAY_SECONDS * 1000))
        }
    }, [ou])
    useEffect(() => { setEditHeadline((game || {}).headline || '') }, [(game || {}).headline || ''])

    const handleLineOuSave = (fieldName, value) => dispatch(saveNflPlayoffGameValue(game._id, fieldName, value, seasonId))

    const handleGameDateChange = dt => dispatch(setNflPlayoffGameTime(game._id, dt, seasonId))

    const saveGameDate = _id => {
        const _gameItem = nflPlayoffGames.find(g => g._id === _id)
        if (_gameItem) {
            dispatch(saveNflPlayoffGameTime(_id, moment(_gameItem.date)))
        }
    }

    const saveHomeFavorite = _homeFavorite => dispatch(saveNflPlayoffGameHomeFavorite(game._id, _homeFavorite))

    const saveGameTeam = teamId => {
        dispatch(saveNflPlayoffGameTeam(game._id, homeVisitor === 'home', teamId))
        setHomeVisitor(null)
    }

    const closeNflTeamPopover = isHome => (isHome ? setShowHome : setShowVisitor)(false)

    const handleFloatButton = (fieldName, amount, offset) => {
        const _newValue = parseFloat(amount) + offset
        if (_newValue >= 0.0) {
            if (fieldName === 'line') setLine(`${_newValue.toFixed(1)}`)
            else if (fieldName === 'ou') setOu(`${_newValue.toFixed(1)}`)
        }
    } 

    const handleScoreChange = (e, homeVisitor) => dispatch(saveNflPlayoffGameValue(game._id, `${homeVisitor}Score`, !isNaN(score) ? (e.target.value || null, seasonId) : null))

    const createTeamMap = () => {
        const _keys = keysIn(game)
        var _visitor = { _id: game.visitor, homeVisitor: 'visitor' }
        var _home = { _id: game.home, homeVisitor: 'home' }
        _keys.filter(_key => _key !== 'home' && _key !== 'visitor' && _key !== 'homeFavorite' 
            && (startsWith(_key, 'visitor') || startsWith(_key,'home'))).forEach(_key => {
            const _fieldName = _key.replace(/home|visitor/i, '').toLowerCase();
            (startsWith(_key, 'home') ? _home : _visitor)[_fieldName] = game[_key]
        })
        return [_visitor,_home]
    }

    const doSaveHeadline = () => {
        const _headline = trim(editHeadline)
        dispatch(saveNflPlayoffGameValue(game._id, 'headline', _headline, seasonId))
        closeHeadlineEditor()
    }

    const openHeadlineEditor = () => setEditHeadlineMode(true)
    const closeHeadlineEditor = () => setEditHeadlineMode(false)

    if (!game) return null
    const { game: _game, gameTime, date, _id, week, visitor, home, homeFavorite, favoriteMascot, headline } = game
    const _nflTeams = week <= 2 ? nflTeams : filter(nflTeams, t => filter(nflPlayoffGames, { week: week - 1 }).map(g => g.home).indexOf(t.teamId) > -1 || filter(nflPlayoffGames, { week: week - 1 }).map(g => g.visitor).indexOf(t.teamId) > -1)
    const _teams = createTeamMap()
    const _showControls = _teams.every(t => t._id)
    return (
        <Card className={s.container}>
            <Card.Header className={s.header}>
                <div className={s.gameNumber}>Game #{_game}</div>
                <div className={s.lineSummary}>{favoriteMascot ? <span>{favoriteMascot} by <Line line={parseFloat(line)} showSign={false} />, O/U: <Line line={parseFloat(ou)} showSign={false} /></span> : null}</div>
                <div className={s.gameTimeContainer}>
                    <Datetime value={moment(date).format(FRIENDLY_DATE_FORMAT)}
                        title={'Game time'} 
                        dateFormat={FRIENDLY_SHORT_DATE_FORMAT} 
                        timeFormat={FRIENDLY_SHORT_TIME_FORMAT}
                        className={s.gameTime}
                        inputProps={{ className: s.dateBox }}
                        timeConstraints={{ minutes: { step: 5 } }}
                        onClose={() => saveGameDate(_id)}
                        onChange={dt => handleGameDateChange(dt)} />                

                </div>
            </Card.Header>
            <ListGroup variant='flush' className={s.nflTeamList}>
            {homeVisitor ? 
            <NflTeamSelector nflTeams={_nflTeams} homeVisitor={homeVisitor} 
                onSelect={saveGameTeam} onClose={() => setHomeVisitor(null)} />
            :
            _teams && _teams.map((t, _index) =>
                t._id ? 
                <NflTeamListItem key={`edit-nfl-playoff-game-${_id}-${t._id}`}
                    teamId={t._id} teamName={t.team} city={t.city} mascot={t.mascot}
                    onClick={() => setHomeVisitor(t.homeVisitor)}
                    left={<div className={s.homeFavoriteContainer} onClick={e => e.stopPropagation()}>
                        <Button className={s.homeFavoriteButton + (homeFavorite === (t.homeVisitor === 'home') ? ` ${s.isSelected}` : ``)} 
                            onClick={() => saveHomeFavorite(t.homeVisitor === 'home')} tabIndex={-1}>
                            <FontAwesomeIcon name='check' />
                        </Button>
                    </div>}
                    right={_showControls ? <div className={s.rightControls}>
                        <div className={s.lineContainer} onClick={e => e.stopPropagation()}>
                            <div className={s.heading}>{t.homeVisitor === 'home' ? 'O/U' : 'Line'}</div>
                            <div className={s.buttonBox} onClick={e => e.stopPropagation()}>
                                <RepeatableButton icon='minus' type={t.homeVisitor === 'home' ? 'ou' : 'line'} value={t.homeVisitor === 'home' ? ou : line} offset={-0.5} onClick={handleFloatButton} />
                                <div className={s.line}><Line showSign={false} line={parseFloat(t.homeVisitor === 'home' ? ou : line)} /></div>
                                <RepeatableButton icon='plus' type={t.homeVisitor === 'home' ? 'ou' : 'line'} value={t.homeVisitor === 'home' ? ou : line} offset={0.5} onClick={handleFloatButton} />
                            </div>
                        </div>
                        <div className={s.scoreContainer} onClick={e => e.stopPropagation()}>
                            <div className={s.heading}>&nbsp;</div>
                            <FormControl value={t.score || ''} className={s.scoreBox}
                                onChange={e => handleScoreChange(e, t.homeVisitor)} />
                        </div>
                    </div>: null} />
                :
                <BlankNflPlayoffSlot homeVisitor={t.homeVisitor} key={`edit-nfl-playoff-game-${_id}-${_index}`} 
                    onClick={() => setHomeVisitor(t.homeVisitor)} />
            )}
            </ListGroup>
            <Card.Footer className={s.footer}>
                <div className={s.headlineContainer}>{headline}</div>
                <div className={s.headlineControls}>
                    <Button className={s.button} size='sm' onClick={() => openHeadlineEditor()}><FontAwesomeIcon name={'pencil-alt'} /></Button>
                </div>
            </Card.Footer>
            <Modal show={editHeadlineMode}
                heading={`Edit Game ${_game} Headline`}
                actionButtonText={'save headline'}
                actionButtonIcon='newspaper'
                actionButtonDisabled={!editHeadline}
                actionButtonOnClick={() => doSaveHeadline()}
                onClose={() => setEditHeadlineMode(false)}>
            <div className={s.headlineEditorContainer}>
                <h4>
                    <FormControl multiple rows={3} 
                        as='textarea'
                        value={editHeadline || ''} 
                        onChange={e => setEditHeadline(e.target.value || '')} />
                </h4>
            </div>
            </Modal>
        </Card>
    )
}

export default EditNflPlayoffGame