import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Row, Col, Alert, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import LeagueScoreboardGame from '../RegularSeason/LeagueScoreboardGame'
import { first, last } from 'lodash'
import * as s from './PostseasonThisWeek.scss'
import pluralize from 'pluralize'

const PostseasonThisWeek = ({ week = 0, round = {}, season = {}, afterDeadline = false }) => {

    const navigate = useNavigate()
    const matchups = round.matchups

    const convertToLeagueGame = m => {
        var t1 = first(m.teams) || {}
        var t2 = last(m.teams) || {}
        var game = {
            afterDeadline,
            avatarUrl1: t1.avatarUrl,
            avatarUrl2: t2.avatarUrl,
            cot: 0.00,
            cow1: 0.00,
            cow2: 0.00,
            division1: '',
            division2: '',
            game: m.game,
            headline: '',
            _id1: t1.teamId,
            _id2: t2.teamId,
            id1: t1.teamId,
            id2: t2.teamId,
            name1: t1.name,
            name2: t2.name,
            name1Mobile: t1.name,
            name2Mobile: t2.name,
            record1: '',
            record2: '',
            score1: t1.score,
            score2: t2.score,
            seasonMode: 'Postseason',
            seed1: t1.displaySeed,
            seed2: t2.displaySeed,
            tiebreaker1: t1.tiebreaker || 0,
            tiebreaker2: t2.tiebreaker || 0,
            showTiebreaker: t1.score === t2.score && week === 4,
            seasonName: season.seasonName,
            seasonId: season.seasonId,
            week,
        }
        return game
    }

    return (
        <div className={s.container}>
            <div className={s.heading + (afterDeadline ? ` ${s.afterDeadline}` : '')}>
                <h3 className={s.title}>{week < 4 ? `This Week's ${pluralize('Matchup', matchups.length, false)}` : 'The Matchup For All the Marbles!'}</h3>
                {afterDeadline ? 
                <div className={s.controls}>
                    <Button onClick={() => navigate(`/football/postseason/${season.seasonName}/playoff-week/${week}`)}>
                        view {pluralize('matchup', matchups.length, false)}
                        {' '}
                        <FontAwesomeIcon name='chevron-right' />
                    </Button>
                </div>
                : null}
            </div>
            {!matchups || matchups.length === 0 ? 
            <Alert variant='info'>No matchups located for this week.</Alert>
            : null}
            <div className={s.matchupsContainer}>
            {matchups && matchups.map(i => {
                const game = convertToLeagueGame(i)
                return [
                    <LeagueScoreboardGame seasonName={season.seasonName} 
                        key={`matchup-this-week-${round.round}-${i.game}`}
                        game={game}
                        isLarge={false} highScoreFirst showSeed
                        showFooter={false} showHeader={false} />,
                    game.showTiebreaker && game.afterDeadline ? 
                        <Alert key={`postseason-this-week-alert-${round.round}-${i.game}`} style={{ display: 'block' }} variant='info'>* winner decided on total points (TP) tiebreaker</Alert>
                    : null
                ]
            })}
            </div>
        </div>
    )
}

export default PostseasonThisWeek