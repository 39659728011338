// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.PreseasonRegistrationStep1__container___x9axM{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center}.PreseasonRegistrationStep1__container___x9axM .PreseasonRegistrationStep1__content___yP7ZB{flex:1 1 auto}.PreseasonRegistrationStep1__container___x9axM .PreseasonRegistrationStep1__button___m79MF{flex:0 0 0;margin-left:1.5rem;white-space:nowrap}`, "",{"version":3,"sources":["webpack://./src/routes/Football/Preseason/PreseasonRegistrationStep1.scss"],"names":[],"mappings":"AAEA,+CACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA,CACA,4FACI,aAAA,CAEJ,2FACI,UAAA,CACA,kBAAA,CACA,kBAAA","sourcesContent":["@use '../../../styles/variables/colors' as colors;\n\n.container {\n    display: flex;\n    flex-flow: row nowrap;\n    justify-content: space-between;\n    align-items: center;\n    .content {\n        flex: 1 1 auto;\n    }\n    .button {\n        flex: 0 0 0;\n        margin-left: 1.5rem;\n        white-space: nowrap;\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `PreseasonRegistrationStep1__container___x9axM`;
export var content = `PreseasonRegistrationStep1__content___yP7ZB`;
export var button = `PreseasonRegistrationStep1__button___m79MF`;
export default ___CSS_LOADER_EXPORT___;
