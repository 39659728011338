import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './MobileApps.scss'
import { getMobileApps } from '@severed-links/common.redherrings-reducers/mobileApps'
import pluralize from 'pluralize'
import { isAndroid, isIOS } from 'react-device-detect'

const MobileApps = () => {

    const dispatch = useDispatch()
    const isAuthenticated = useSelector(state => state.account.isAuthenticated)
    const apps = useSelector(state => state.mobileApps.apps)

    useEffect(() => {
        if (isAuthenticated) {
            dispatch(getMobileApps())
        }
    }, [isAuthenticated])

    if (!apps || !apps.length) return null
    const _apps = apps.filter(i => (isIOS && i.appStore === 'iOS') || (isAndroid && i.appStore === 'Android') || (!isIOS && !isAndroid))
    return (
        <div className={s.container}>
            <h4 className={s.title}>Download our Mobile {pluralize('App', _apps.length, false)}</h4>
            <div className={s.linkContainer}>
            {_apps && _apps.map(i =>
                <a className={s.link} key={`app-download-${i.appStore}`} href={i.url} target='_blank'>
                    <img className={s.img} src={i.icon} />
                </a>
            )}
            </div>
        </div>
    )
}

export default MobileApps