import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useMatch } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'
import { getTopScoreOddsReport } from '@severed-links/common.redherrings-reducers/football'
import { find, startsWith, isEqual } from 'lodash'
import SeasonSelector from '../SeasonSelector'
import * as s from './FootballLeagueReport.scss'
import PlayerTeamListItem from '../PlayerTeamListItem'
import { take } from 'lodash'
import { teamUrl } from '@severed-links/common.redherrings-constants'

const PickTrendsReport = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const _match = useMatch(`/football/reports/:reportId/*`)
    const seasonName = params.seasonName || null
    const reportId = ((_match || {}).params || {}).reportId || null
    const week = parseInt(params.week || '0')
    const seasons = useSelector(state => state.football.seasons)
    const playerId = useSelector(state => state.account._id)
    const reports = useSelector(state => state.football.reports)
    const season = seasons.find(i => i.seasonName === seasonName) || {}
    const report = reports.find(i => i.seasonId === season.seasonId && 
        i.reportId === `${reportId}-week-${week}`)

    useEffect(() => { fetchReport() }, [seasonName, week])

    const fetchReport = () => seasonName && week ? dispatch(getTopScoreOddsReport(seasonName, week)) : null

    if (!report) return null

    return (
            season && report ? 
            <div className={s.container}>
                <div className={s.titleBar}>
                    <h2 className={s.title}>{season.seasonName} Odds to Win Top Score for Week {week}</h2>
                    <div className={s.seasonSelector}><SeasonSelector season={season} seasons={seasons} /></div>
                </div>
                <ListGroup className={s.topScoreOddsList}>
                {report.items && report.items.map(i =>
                    i.teams && i.teams.length === 1 ? 
                    <PlayerTeamListItem className={s.topScoreOddsListItem} {...{ ...i.teams[0], teamId: i.teams[0]._id }} 
                        showDivision right={<div className={s.scoreContainer}>{(i.cow * 100.0).toFixed(2)}%</div>}
                        key={`top-score-odds-${i.teamIds.map(x => x).join('-')}`} />
                    :
                    <ListGroup.Item className={s.topScoreOddsListItem} key={`top-score-odds-${i.teamIds.map(x => x).join('-')}`}>
                        <div className={s.content}>
                            <div className={s.avatarContainer}>
                            {i.teams && take(i.teams, Math.min(i.teams.length, 4)).map(x => 
                            <div className={s.avatar + ' ' + s[`avatar${Math.min(i.teams.length, 4)}`]}>
                                <img src={teamUrl(x.avatarUrl)} />
                            </div>
                            )}
                            </div>
                            <div className={s.winnerStringContainer}>{i.winnerString}</div>
                            <div className={s.scoreContainer}>{(i.cow * 100.0).toFixed(2)}%</div>
                        </div>
                    </ListGroup.Item>
                )}
                {!report.items || report.items.length === 0 ?
                    <ListGroup.Item>No records located.</ListGroup.Item>
                : null}
                </ListGroup>
            </div>
            : null
    )
}

export default PickTrendsReport