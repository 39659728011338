import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Card, ListGroup, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './EnterOscarsPicksCategory.scss'

const EnterOscarsPicksCategory = ({ afterDeadline, category = '', num = 0, choices = [], pick, year, isTransitioning = false, isSavingPick = false, savePick = () => void(0), createNotification = () => void(0), setTransitionState = () => void(0) }) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()

    const makePick = choice => {
        dispatch(savePick({ year, num, pick: choice.choice }))
        .then(action => {
            setTimeout(() => {
                setTransitionState(true)
            }, 1000)
            setTimeout(() => {
                navigate(`/oscars/enter-picks/${action.payload.nextStep}`)
                setTransitionState(false)
            }, 2000)
        })
    }
    
    return (
        <div className={s.container}>
            <Card className={s.pickCard + (isTransitioning ? ' ' + s.isTransitioning : '')}>
                <Card.Header className={s.header} as='h3'>{category}</Card.Header>
                <ListGroup variant='flush' className={s.picks}>
                {choices && choices.map(c =>
                    <ListGroup.Item key={`category-${num}-choice-${c.choice}`}
                        className={s.pick + (c.isPicked ? ' ' + s.picked : '')}
                        action onClick={() => makePick(c)}>
                        <div className={s.content}>
                            <div className={s.choice}>{c.choiceText}</div>
                            <div className={s.isPicked}>
                            {c.isPicked ?
                                <Badge bg='info' text='light'>
                                {!isSavingPick ? 
                                <span>Your pick!</span>
                                : 
                                <FontAwesomeIcon name='circle-notch' spin />
                                }
                                </Badge>
                            : <span>&nbsp;</span>}
                            </div>
                        </div>
                    </ListGroup.Item>
                )}
                </ListGroup>
            </Card>
        </div>
    )
}
    
export default EnterOscarsPicksCategory