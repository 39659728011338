import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Row, Col, Button, InputGroup, FormGroup, FormControl, Badge } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import HtmlEditor from '../../Editors/HtmlEditor'
import * as s from './CinderellaRecapEditor.scss'
import { setActiveRecap, getRecaps, saveRecap, deleteRecap } from '@severed-links/common.redherrings-reducers/cinderellaAdmin'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import moment from 'moment-timezone'
import FormCheck from 'react-bootstrap/FormCheck'
import Confirm from '@severed-links/common.confirm'
import { FRIENDLY_DATE_FORMAT } from '@severed-links/common.redherrings-constants'
import CinderellaRecapImages from './CinderellaRecapImages'
import CinderellaRecapPreview from './CinderellaRecapPreview'
import { stateToHTML } from 'draft-js-export-html'

const CinderellaRecapEditor = ({ isNew = false, recapId = null, onCancelEditRecap, onSetActiveRecap }) => {

    const dispatch = useDispatch()
    const year = useSelector(state => state.cinderella.year)
    const _blankArticle = { _id: null, articleText: '', title: '', lastEdited: moment().utc().toISOString(), active: false, isNew: true }
    const _editRecap = useSelector(state => state.cinderellaAdmin.recaps.find(i => i._id === recapId)) || _blankArticle
    const cinderellaRecapEditor = useSelector(state => state.editors.cinderellaRecapEditor)
    const [_article, setArticle] = useState(_blankArticle)
    const [isSaving, setSaving] = useState(false)

    useEffect(() => { setArticle({ ..._editRecap }) }, [recapId, isNew, !isNew && _editRecap.lastEdited])

    const handleChange = e => setArticle({ ..._article, [e.target.name]: e.target.value })

    const doSetActive = () => {
        setArticle({ ..._article, active: true })
        onSetActiveRecap(_article._id)
    }

    const save = () => {
        setSaving(true)
        const lastEdited = moment().utc().toISOString()
        dispatch(saveRecap({ 
            ..._article, 
            lastEdited, 
            year,
            articleText: cinderellaRecapEditor, 
            active: _article._id ? _article.active : false 
        })).then(action => {
            setSaving(false)
            dispatch(createNotification({ headline: action.payload.subject, message: action.payload.message, type: action.payload.messageType, timeout: 5000 }))
            onCancelEditRecap(action.payload._id)
        })
    }

    const doDeleteRecap = () => {
        dispatch(deleteRecap(_article._id))
        .then(action => {
            dispatch(createNotification({ headline: action.payload.subject, message: action.payload.message, type: action.payload.messageType, timeout: 5000 }))
            onCancelEditRecap()
        })
    }

    return (
        <div className={s.container}>
        <FormGroup className={s.formGroup}>
            <InputGroup className={s.inputGroup}>
                <InputGroup.Text>Title</InputGroup.Text>
                <FormControl name='title' value={_article.title || ''} 
                    onChange={e => handleChange(e)} disabled={isSaving} />
                {_article._id ?
                <Button disabled={_article.active}
                    variant={_article.active ? 'light' : 'primary'}
                    onClick={() => doSetActive()}>
                    <FontAwesomeIcon name='check' /> set active
                </Button>
                : null}
            </InputGroup>
        </FormGroup>
        <FormGroup className={s.formGroup + ' ' + s.editorContainer}>
            <HtmlEditor content={_article.articleText}
                isTall noBorder
                name={'cinderellaRecapEditor'}
                showToolbar showImageButton
                placeholder={'Recap content...'} />
        </FormGroup>
        <FormGroup className={s.formGroup + ' ' + s.buttonsContainer}>
            {_article._id ?
            <Confirm variant='danger' onConfirm={() => doDeleteRecap()}
                title={'Delete Recap'}
                confirmText='confirm delete recap'
                body={'Are you sure you want to delete this recap?'}
                buttonIcon='trash' disabled={isSaving} buttonText='delete recap' />
            : null}
            {_article._id ?
            <CinderellaRecapPreview recapId={_article._id}
                title={_article.title || ''} 
                recapHtml={cinderellaRecapEditor}
                author={_article.author}
                lastEdited={_article.lastEdited} />
            : null}
            <Button variant='light' className={s.cancelButton}
                onClick={() => onCancelEditRecap()}>
                <FontAwesomeIcon name='times' />
                <span className={s.buttonLabel}>cancel</span>
            </Button>
            <Button variant='primary' className={s.saveButton}
                disabled={!_article.title || !cinderellaRecapEditor || isSaving}
                onClick={() => save()}>
                <FontAwesomeIcon name='check' />
                <span className={s.buttonLabel}>save recap</span>
            </Button>
        </FormGroup>
        <CinderellaRecapImages recapId={_article._id} />
        {_article._id ?
        <div className={s.badgesContainer}>
            <Badge className={s.badge}>_id: {_article._id}</Badge>
            <Badge className={s.badge}>Last saved: {moment(_article.lastEdited).local().format(FRIENDLY_DATE_FORMAT)}</Badge>
        </div>
        : null}
        </div>
    )
}

export default CinderellaRecapEditor