// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.ChangePassword__container___mX6Co{margin-bottom:.5rem}.ChangePassword__container___mX6Co .ChangePassword__formGroup___TSalu{margin-bottom:1rem}.ChangePassword__container___mX6Co .ChangePassword__formGroup___TSalu .ChangePassword__progressBar___RXb_2{height:.25rem}`, "",{"version":3,"sources":["webpack://./src/routes/Main/ChangePassword.scss"],"names":[],"mappings":"AAAA,mCACI,mBAAA,CAEA,sEACI,kBAAA,CAEA,2GACI,aAAA","sourcesContent":[".container {\n    margin-bottom: 0.5rem;\n\n    .formGroup {\n        margin-bottom: 1rem;\n        \n        .progressBar {\n            height: 0.25rem;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `ChangePassword__container___mX6Co`;
export var formGroup = `ChangePassword__formGroup___TSalu`;
export var progressBar = `ChangePassword__progressBar___RXb_2`;
export default ___CSS_LOADER_EXPORT___;
