import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useMatch } from 'react-router-dom'
import { ListGroup } from 'react-bootstrap'
import { getFavoriteNflTeamsReport } from '@severed-links/common.redherrings-reducers/football'
import { find, startsWith } from 'lodash'
import SeasonSelector from '../SeasonSelector'
import * as s from './FootballLeagueReport.scss'
import NflTeamListItem from '../NflTeamListItem'

const FavoriteNflTeamsReport = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const _match = useMatch(`/football/reports/:reportId/*`)
    const seasonName = params.seasonName || null
    const reportId = ((_match || {}).params || {}).reportId || null
    const seasons = useSelector(state => state.football.seasons)
    const playerId = useSelector(state => state.account._id)
    const reports = useSelector(state => state.football.reports)
    const season = seasons.find(i => i.seasonName === seasonName) || {}
    const report = reports.find(i => i.seasonId === season.seasonId && i.reportId === reportId)
    
    useEffect(() => { fetchReport() }, [seasonName])

    const fetchReport = () => seasonName ? dispatch(getFavoriteNflTeamsReport(seasonName)) : null

    if (!report) return null
    return (
            season && season.seasonName && report && report.items ? 
            <div className={s.container}>
                <div className={s.titleBar}>
                    <h2 className={s.title}>{season.seasonName} Favorite NFL Teams</h2>
                    <div className={s.seasonSelector}><SeasonSelector season={season} seasons={seasons} /></div>
                </div>
                <ListGroup className={s.reportList}>
                {report && report.items && report.items.map((i, index) => 
                    <NflTeamListItem teamId={i.teamId} teamName={i.nflTeam} 
                        city={i.city} mascot={i.mascot}
                        right={i.score}
                        key={`favorite-nfl-teams-report-${i.teamId}`} />
                )}

                {!report.items || report.items.length === 0 ?  
                    <ListGroup.Item className={s.item}>No records located.</ListGroup.Item>
                : null}
                </ListGroup>
            </div>
            : null
    )
}

export default FavoriteNflTeamsReport