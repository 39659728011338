import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useNavigate, useLocation } from 'react-router-dom'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import BaseAuthenticatedMenu from './BaseAuthenticatedMenu'
import { Nav } from 'react-bootstrap'
import { img } from '@severed-links/common.redherrings-constants'
import CloudinaryImage from '../routes/Cloudinary/CloudinaryImage'
import { getEliminatorDetails, getSeasons, getNflTeams, getMainPageData, getStandings } from '@severed-links/common.redherrings-reducers/eliminator'
import moment from 'moment-timezone'
import * as s from './Menu.scss'

const EliminatorAdminMenu = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const location = useLocation()
    const isAuthenticated = useSelector(state => state.account.isAuthenticated)
    const seasonId = useSelector(state => state.eliminator.seasonId)
    const seasonName = useSelector(state => state.eliminator.seasonName)
    const seasonMode = useSelector(state => state.eliminator.seasonMode)
    const week = useSelector(state => state.eliminator.week)
    const playoffWeek = useSelector(state => state.eliminator.playoffWeek)
    const pickDeadline = useSelector(state => state.eliminator.pickDeadline)
    const playoffPickDeadline = useSelector(state => state.eliminator.playoffPickDeadline)
    const isHome = location.pathname.toLowerCase() === '/eliminator/admin'

    useEffect(() => { fetchData() }, [isAuthenticated, seasonMode, seasonName, week, playoffWeek])

    const fetchData = () => {
        if (isAuthenticated) {    
            dispatch(getSeasons())
            dispatch(getNflTeams())
            dispatch(getEliminatorDetails())
            dispatch(getMainPageData())
            .then(() => {
                if (seasonName) dispatch(getStandings(seasonName))
            })
        }
    }

    const navbarBrand = (       
        <Link to={{ pathname: '/eliminator/admin' }}>
            <CloudinaryImage publicId={img.EliminatorHeader} />
        </Link>
    )

    const menuItems = [
        <Nav.Item className={s.item} key={1}><Nav.Link eventKey={1} title='Back to Eliminator' onClick={() => navigate('/eliminator')}>
            <FontAwesomeIcon name='chevron-left' />
        </Nav.Link></Nav.Item>,
        !isHome ? <Nav.Item className={s.item} key={2}><Nav.Link eventKey={2} title='Back to Eliminator Dashboard' onClick={() => navigate('/eliminator/admin')}>
            <FontAwesomeIcon name='home' />
        </Nav.Link></Nav.Item> : null
    ]
    return (
        <BaseAuthenticatedMenu backgroundImageUrl={img.CloudinaryBaseUrl + img.EliminatorHeaderBackground}
            navbarBrand={navbarBrand} 
            menuItems={menuItems} />
    )
}

export default EliminatorAdminMenu
