import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './EditTeamProfileTeamList.scss'
import { getTeamsByPlayer } from '@severed-links/common.redherrings-reducers/football'
import PlayerTeam from '../PlayerTeam'

const EditTeamProfileTeamList = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const _path = `/football/edit-team-profile/:teamId`
    const teamId = params.teamId || null
    const playerId = useSelector(state => state.account._id)
    const teams = useSelector(state => state.football.editProfileTeams)
        .map(i => ({ ...i, isActiveTeam: i.teamId === teamId }))

    useEffect(() => { getTeamList() }, [playerId])

    const getTeamList = () => playerId ? dispatch(getTeamsByPlayer(playerId)) : null

    const onSelectTeam = e => navigate(_path.replace(':teamId', e))

    return (
        <div className={s.container}>
            <h4>Your Teams {teams.length > 0 ? '(' + teams.length + ')' : null}</h4>
            <ListGroup className={s.teamList}>
            {teams && teams.map(t =>
            <ListGroup.Item className={s.pastTeamItem + (t.isActiveTeam ? ` ${s.active}` : '')}
                action onClick={() => onSelectTeam(t.teamId)}
                key={`edit-profile-team-${t.teamId}`}>
                <div>
                    <PlayerTeam teamId={t.teamId} teamName={t.seasonName + ' ' + t.teamName} avatarUrl={t.avatarUrl} />
                </div>
                {t.isActiveTeam ? <FontAwesomeIcon name='chevron-right' className={s.icon} /> : null}
            </ListGroup.Item>
            )}
            </ListGroup>
        </div>
    )
}

export default EditTeamProfileTeamList
