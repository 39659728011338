// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.BroadcastMessage__container___tWbfR .BroadcastMessage__formGroup___bxFon{margin-bottom:1rem}.BroadcastMessage__container___tWbfR .BroadcastMessage__formGroup___bxFon .BroadcastMessage__formLabel___lw2dT{display:flex;flex-flow:row nowrap;justify-content:space-between;align-items:center}`, "",{"version":3,"sources":["webpack://./src/routes/Football/Admin/BroadcastMessage.scss"],"names":[],"mappings":"AACI,0EACI,kBAAA,CAEA,+GACI,YAAA,CACA,oBAAA,CACA,6BAAA,CACA,kBAAA","sourcesContent":[".container {\n    .formGroup {\n        margin-bottom: 1rem;\n\n        .formLabel {\n            display: flex;\n            flex-flow: row nowrap;\n            justify-content: space-between;\n            align-items: center;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `BroadcastMessage__container___tWbfR`;
export var formGroup = `BroadcastMessage__formGroup___bxFon`;
export var formLabel = `BroadcastMessage__formLabel___lw2dT`;
export default ___CSS_LOADER_EXPORT___;
