import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useLocation } from 'react-router-dom'
import { DropdownButton, Dropdown } from 'react-bootstrap'
import { find, orderBy, findIndex, startsWith, isEqual } from 'lodash'

const WeekSelector = ({ 
    season = {}, seasons = [], week = '', showPreseasonWeeks = false,
    showPlayoffWeeks = false, showRegularSeasonWeeks = true,
    onSelect = null
}) => {

    const navigate = useNavigate()
    const params = useParams()
    const location = useLocation()
    const [_week, setWeek] = useState(1)
    const [isPlayoffWeek, setIsPlayoffWeek] = useState(false)

    useEffect(() => { updateSelectorState() }, [seasons.length, season.seasonName, season.seasonId, week, params.week])

    const updateSelectorState = () => {
        setIsPlayoffWeek(startsWith(week, 'PW'))
        setWeek(parseInt(week.replace(/\D/gi, '')))
    }

    const onItemSelect = e => {
        if (onSelect) {
            onSelect(e)
        } else if (/\d+/.test(e)) {
            const _newWeek = parseInt(e.replace(/\D/gi, ''))
            const _newWeekPrefix = startsWith(e, 'PW') ? 'playoff-week' : 'week'
            const _oldWeekPrefix = isPlayoffWeek ? 'playoff-week' : 'week'
            const _replace = `${_oldWeekPrefix}/${params.week}`
            const _with = `${_newWeekPrefix}/${_newWeek}`
            const _newPath = location.pathname.replace(_replace, _with)
            navigate(_newPath)
        }
    }

    return (
        <DropdownButton variant='light' title={season && week ? (!isPlayoffWeek ? 'Week' : 'Playoff Week') + ' ' + _week : <span>[Week...]</span>} 
            id='WeekSelector-dropdown' onSelect={e => onItemSelect(e)} onClick={e => e.stopPropagation()}>
        {showPreseasonWeeks && season && season.preseasonWeeks && orderBy(season.preseasonWeeks, ['week'], ['desc']).map(w =>
            <Dropdown.Item key={w.week} eventKey={'Pre' + w.week}>Preseason Week {w.week}</Dropdown.Item>
        )}
        {showPlayoffWeeks && season && season.playoffWeeks && orderBy(season.playoffWeeks, ['week'], ['desc']).map(w =>
            <Dropdown.Item key={w.week} eventKey={'PW' + w.week}>Playoff Week {w.week}</Dropdown.Item>
        )}
        {showRegularSeasonWeeks && season && season.weeks && orderBy(season.weeks, ['week'], ['desc']).map(w =>
            <Dropdown.Item key={w.week} eventKey={'W' + w.week}>Week {w.week}</Dropdown.Item>
        )}
        </DropdownButton>
    )
}

export default WeekSelector
