import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, FormGroup, FormLabel, FormControl, InputGroup, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { trim } from 'lodash'
import * as s from '../ForgotPassword/ForgotPassword.scss'
import validator from 'validator'
import { setUserInfo } from '@severed-links/common.redherrings-reducers/forgotUsername'
import { PatternFormat } from 'react-number-format'

const ForgotUsernameStep2 = forwardRef(({ jumpToStep }, _ref) => {

    const dispatch = useDispatch()
    const fu = useSelector(state => state.forgotUsername)
    const [email, setEmail] = useState('')
    const [cellPhone, setCellPhone] = useState('')
    const _isValid = (fu.sendBy === 'Email' && fu.emailIsValid) ||
        (fu.sendBy === 'Text' && fu.cellPhoneIsValid)

    useEffect(() => {
        setEmail(fu.email)
        setCellPhone(fu.cellPhone)
        dispatch(setUserInfo({ nextDisabled: true }))
    }, [])

    useEffect(() => {
        if (fu.nextDisabled !== !_isValid) dispatch(setUserInfo({ nextDisabled: !_isValid }))
    }, [fu.nextDisabled, _isValid])

    const handleEmailChange = e => {
        var data = { email: trim(e.target.value || '') }
        setEmail(data.email)
        dispatch(setUserInfo({ email: data.email, emailIsValid: !validator.isEmpty(data.email || '') && validator.isEmail(data.email || '') }))
    }

    const handleCellPhoneChange = _values => {
        var data = { cellPhone: trim(_values.formattedValue || '') }
        setCellPhone(data.cellPhone)
        dispatch(setUserInfo({ cellPhone: data.cellPhone, cellPhoneIsValid: !validator.isEmpty(_values.value || '') && validator.isMobilePhone(_values.value || '', 'en-US') }))
    }

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValid
    }))    

    return (
        <div>
            {fu.sendBy == 'Email' ? 
            <FormGroup>
                <Col sm={{ span: 10, offset: 1 }}>
                    <FormLabel>Email address (you must have updated your Redherrings profile with this email)</FormLabel>
                    <InputGroup size='lg'>
                        <InputGroup.Text><FontAwesomeIcon name='envelope' /></InputGroup.Text>
                        <FormControl autoFocus autoComplete='off' 
                            type='text' name='email' 
                            value={email} 
                            required isValid={_isValid}
                            isInvalid={!_isValid}
                            onChange={(e) => handleEmailChange(e)} />
                        <FormControl.Feedback />
                    </InputGroup>
                    <div className={s.alertContainer}>
                        {_isValid ?
                        <Alert variant='success'>Press 'Send' to send your user name to your selected location.</Alert>
                        : null}
                    </div>
                </Col>
            </FormGroup>
            : null}
            {fu.sendBy == 'Text' ? 
            <FormGroup>
                <Col sm={{ span: 8, offset: 2 }}>
                    <FormLabel>Cell phone (you must have updated your Redherrings profile with this cell phone number)</FormLabel>
                    <InputGroup size='lg'>
                        <InputGroup.Text><FontAwesomeIcon name='mobile' /> +1</InputGroup.Text>
                        <PatternFormat customInput={FormControl} autoFocus autoComplete='off' 
                            name='cellPhone' type='tel' format='(###) ###-####' mask='_'
                            value={cellPhone}                                         
                            required isValid={_isValid}
                            isInvalid={!_isValid}
                            onValueChange={(values, sourceInfo) => handleCellPhoneChange(values)} />
                        <FormControl.Feedback />
                    </InputGroup>
                    <div className={s.alertContainer}>
                        {_isValid ?
                        <Alert variant='success'>Press 'Send' to send your use rname to your selected location.</Alert>
                        : null}
                    </div>
                </Col>
            </FormGroup>
            : null}
        </div>
    )
})

export default ForgotUsernameStep2
