import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import { getNflStandings } from '@severed-links/common.redherrings-reducers/football'
import { find } from 'lodash'
import NFLStandingsDivision from './NFLStandingsDivision'
import SeasonSelector from '../../SeasonSelector'
import * as s from './NFLStandings.scss'
import { getFootballUrlParams } from '@severed-links/common.redherrings-constants'

const NFLStandings = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const seasons = useSelector(state => state.football.seasons)
    const nflStandings = useSelector(state => state.football.nflStandings)
    const c = getFootballUrlParams(params, seasons)
    const standings = find(nflStandings, x => c.season && x.seasonId === c.season.seasonId)

    useEffect(() => { updateStandings() }, [c.seasonId])

    const updateStandings = () => c.seasonId ? dispatch(getNflStandings(c.seasonId)) : null

    const _conferenceKeys = ['nfc','afc']
    return (
            c.season ? 
            <div>
                <Row>
                    <Col xs={12}>
                        <span className={s.seasonSelector}><SeasonSelector seasons={seasons} season={c.season} /></span>
                        <h2>{c.season.seasonName} NFL Standings</h2>
                    </Col>
                </Row>
                <Row>
                {_conferenceKeys.map(x =>
                    <Col sm={6} xs={12} key={`conference-${x}`}>
                    {standings && standings[x] && standings[x].map(d => 
                        <NFLStandingsDivision division={d} 
                            seasonName={c.season.seasonName} 
                            key={`nfc-${d.division}`} />
                    )}
                    </Col>
                )}
                </Row>
            </div>
            : null
    )
}

export default NFLStandings