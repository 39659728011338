import React, { useEffect } from 'react'
import { Button } from 'react-bootstrap'
import * as s from './EnterPicks.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { useDrop } from 'react-dnd'
import { DRAG_AND_DROP_ITEM_TYPES } from '@severed-links/common.redherrings-constants'

const EnterPicksGameContainer = ({ rank = 0, children = null }) => {

    const [{ isOver, isOverCurrent }, _dropRef] = useDrop(() => ({
        accept: DRAG_AND_DROP_ITEM_TYPES.REGULAR_SEASON_GAME,
        collect: (monitor, props) => ({ isOver: monitor.isOver(), isOverCurrent: monitor.isOver({ shallow: true }) }),
        drop: (item, monitor) => ({ newRank: rank }),
    }), [rank])
    
    return (
        <li ref={_dropRef} key={rank} className={s.pickListItem}>
            <div className={s.rankIdentifier + ' ' + (isOver ? s.isOverRank : null)}>{rank}</div>
            {children}
        </li>
    )
}
    
export default EnterPicksGameContainer
    