import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CloudinaryImage from '../Cloudinary/CloudinaryImage'
import * as s from './NflTeamListItem.scss'
import { ListGroup } from 'react-bootstrap'
import { trim } from 'lodash'
import Line from './Line'
import OverUnder from './OverUnder'
import { img } from '@severed-links/common.redherrings-constants'

const NflTeamListItem = ({ 
    teamId, teamName, city = null, mascot = null, onClick, 
    left, right, detail, 
    record, line = null, overUnder = null, showLine = false, 
    showOverUnder = false, homeFavorite = true, 
    seasonName, miniHeader, variant = null, className = null, 
    alignRight = false, showSignOnLine = false, disabled = false
}) => {

    const navigate = useNavigate()
    const _filterWords = ['undefined', undefined, 'null', null]
    if (_filterWords.includes(city)) city = null
    if (_filterWords.includes(mascot)) mascot = null
    const _imgUrl = mascot ? `/football/nfl-team-small-avatars/${(mascot || '').toLowerCase()}` : img.FootballBlankHelmet
    return (
        <ListGroup.Item tabIndex={-1} disabled={disabled}
            className={s.container + (className ? ` ${className}` : ``) + (alignRight ? ` ${s.alignRight}` : '')} 
            action={onClick || seasonName} 
            title={teamName} 
            variant={variant}
            onClick={() => onClick ? onClick() : seasonName ? navigate(`/football/nfl-team-analysis/${seasonName}/${(mascot || '').toLowerCase()}`) : void(0)}>
            {miniHeader ? <div className={s.miniHeaderContainer}>{miniHeader}</div> : null}
            <div className={s.content}>
                {left ? <div className={s.leftContainer}>{left}</div> : null}
                {!alignRight ? 
                <div className={s.avatarContainer}>
                    <CloudinaryImage publicId={_imgUrl} />
                </div> 
                : null}
                <div className={s.teamNameContainer}>
                    {city ? <div className={s.city}>{trim(city || (teamName || '').replace(mascot, ''))}</div> : null}
                    <div className={s.mascotContainer}>
                        {record && alignRight ? <span className={s.record}>{record}</span> : null}
                        {mascot ? <div className={s.mascot}>{mascot}</div> : null}
                        {record && !alignRight ? <span className={s.record}>{record}</span> : null}
                    </div>
                </div>
                {showLine ? <div className={s.lineContainer}><Line line={line} showSign={showSignOnLine} /></div> : null}
                {showOverUnder ? <div className={s.ouContainer}><OverUnder overUnder={overUnder} /></div> : null}
                {right ? <div className={s.rightContainer}>{right}</div> : null}
                {alignRight ?
                <div className={s.avatarContainer}>
                    <CloudinaryImage publicId={_imgUrl} />
                </div>
                : null}
            </div>
            {detail ? <div className={s.detailContainer}>{detail}</div> : null}
        </ListGroup.Item>
    )
}

export default NflTeamListItem