// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@media screen and (max-width: 575px){.CinderellaRecapPreview__container___fp7B9 .CinderellaRecapPreview__someNumbersHeading___qV6q7{margin-top:2rem 0px 1rem 0px}}.CinderellaRecapPreview__previewButton___fkdHw{margin:0px 1rem}.CinderellaRecapPreview__wideModal___Xndub{width:80vw}.CinderellaRecapPreview__recapPreviewContainer___J4zwX{max-height:65vh;overflow:auto}`, "",{"version":3,"sources":["webpack://./src/routes/Cinderella/Recap/RecapView.scss","webpack://./src/routes/Cinderella/Admin/CinderellaRecapPreview.scss"],"names":[],"mappings":"AAKI,qCACI,+FACI,4BAAA,CAAA,CCLZ,+CACI,eAAA,CAIJ,2CACI,UAAA,CAIJ,uDACI,eAAA,CACA,aAAA","sourcesContent":["@use '../../../styles/variables/colors' as colors;\n@use '../../../styles/variables/breakpoints' as breakpoints;\n\n.container {\n\n    @media screen and (max-width: breakpoints.$screen-xs-max) {\n        .someNumbersHeading {\n            margin-top: 2rem 0px 1rem 0px;\n        }\n    }\n}","@use '../Recap/RecapView.scss';\n\n.previewButton {\n    margin: 0px 1rem;\n\n}\n\n.wideModal {\n    width: 80vw;\n\n}\n\n.recapPreviewContainer {\n    max-height: 65vh;\n    overflow: auto;\n}\n"],"sourceRoot":""}]);
// Exports
export var container = `CinderellaRecapPreview__container___fp7B9`;
export var someNumbersHeading = `CinderellaRecapPreview__someNumbersHeading___qV6q7`;
export var previewButton = `CinderellaRecapPreview__previewButton___fkdHw`;
export var wideModal = `CinderellaRecapPreview__wideModal___Xndub`;
export var recapPreviewContainer = `CinderellaRecapPreview__recapPreviewContainer___J4zwX`;
export default ___CSS_LOADER_EXPORT___;
