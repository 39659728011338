// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../.yarn/__virtual__/css-loader-virtual-c5c0d65534/0/cache/css-loader-npm-7.1.2-7540f12884-ddde22fb10.zip/node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TeamDataAddNewTeam__container___X_aFw{margin-bottom:1rem}.TeamDataAddNewTeam__container___X_aFw .TeamDataAddNewTeam__formGroup___paTSN{margin-bottom:1rem}.TeamDataAddNewTeam__container___X_aFw .TeamDataAddNewTeam__formGroup___paTSN.TeamDataAddNewTeam__saveButton___QjHTp{text-align:right}`, "",{"version":3,"sources":["webpack://./src/routes/Football/Admin/TeamDataAddNewTeam.scss"],"names":[],"mappings":"AAAA,uCACI,kBAAA,CAEA,8EACI,kBAAA,CAEA,qHACI,gBAAA","sourcesContent":[".container {\n    margin-bottom: 1rem;\n\n    .formGroup {\n        margin-bottom: 1rem;\n\n        &.saveButton {\n            text-align: right;\n        }\n    }\n}"],"sourceRoot":""}]);
// Exports
export var container = `TeamDataAddNewTeam__container___X_aFw`;
export var formGroup = `TeamDataAddNewTeam__formGroup___paTSN`;
export var saveButton = `TeamDataAddNewTeam__saveButton___QjHTp`;
export default ___CSS_LOADER_EXPORT___;
