import React, { useEffect } from 'react'
import ls from 'local-storage'
import { useSelector, useDispatch } from 'react-redux'
import moment from 'moment-timezone'
import { API_AUTH_TOKEN_NAME, EMPTY_TOKEN_RESPONSE } from '@severed-links/common.redherrings-constants'
import { setAccountState } from '@severed-links/common.redherrings-reducers/account'

const TokenWatcher = () => {

    const dispatch = useDispatch()
    const { isAuthenticated, hasCheckedToken, token_type, access_token, expirationDate, expires_in } = useSelector(state => state.account)

    useEffect(() => {
        // load token on load
        var _token = {}
        try {
            _token = JSON.parse(ls.get(API_AUTH_TOKEN_NAME) || '{}')
        } catch (e) {
            _token = EMPTY_TOKEN_RESPONSE
        }
        const _update = { 
            hasCheckedToken: true, 
            lastTokenCheck: moment().toISOString(true), 
            ..._token, 
            isAuthenticated: checkCookieAuth(_token), 
        }
        dispatch(setAccountState(_update))
    }, [])

    useEffect(() => {
        if (isAuthenticated && access_token && expires_in) {
            const _update = { token_type, access_token, expires_in, expirationDate: moment(moment().add(expires_in || -30, 'seconds')).toISOString(true) }
            ls.set(API_AUTH_TOKEN_NAME, JSON.stringify(_update))
            dispatch(setAccountState(_update))
        } else if (access_token && !isAuthenticated) {
            ls.remove(API_AUTH_TOKEN_NAME)
            dispatch(setAccountState({ ...EMPTY_TOKEN_RESPONSE, access_token: null, expirationDate: null, expires_in: -30 }))
        }
    }, [isAuthenticated, access_token, expires_in])

    const checkCookieAuth = (_token = {}) => !!_token.access_token && !!_token.expirationDate && moment(_token.expirationDate).isValid() && moment().isBefore(_token.expirationDate)

    return null
}

export default TokenWatcher
