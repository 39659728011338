import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { Badge } from 'react-bootstrap'
import * as styles from './DivisionStandingsRow.scss'
import PlayerTeamListItem from './PlayerTeamListItem'

const DivisionStandingsRow = ({ divisionCode = null, divisionItem = {}, playerId = null, shortForm = false, showPlayoffSeed = false }) => {

    if (!divisionItem) return null
    const s = divisionItem
    const right = <div className={styles.right + ' ' + (shortForm ? styles.sm : styles.md)}>
        <div className={styles.item}>{s.wins}-{s.losses}{s.ties ? `-${s.ties}` : ``}</div>
    </div>
    const detail = <div className={styles.detail + ' ' + (shortForm ? styles.sm : styles.md)}>
        {!shortForm ?
        <>
        <div className={styles.item}>{s.winPctStr}</div>
        <div className={styles.item}>PF: {s.pf}</div>
        <div className={styles.item}>PA: {s.pa}</div>
        </> : null}
    </div>
    return (
        <PlayerTeamListItem key={`division-standings-item-${divisionCode}-${s.teamId}`} 
            size={shortForm ? 'sm' : 'md'}
            teamId={s.teamId} 
            playoffSeed={s.playoffSeed}
            showPlayoffSeed={showPlayoffSeed}
            teamNameRaw={s.teamName}
            teamName={<span>{s.teamName} {s.feagles ? <Badge bg='danger'>Feagles!</Badge> : null}</span>} 
            avatarUrl={s.avatarUrl}
            right={right} detail={detail} />
    )
}

export default DivisionStandingsRow