import React, { useEffect } from 'react'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import CoreLayout from '../layouts/CoreLayout/CoreLayout'
import * as Sentry from '@sentry/react'

import { DndProvider } from 'react-dnd'
import { HTML5Backend } from 'react-dnd-html5-backend'
import { isMobile } from 'react-device-detect'

import { library } from '@fortawesome/fontawesome-svg-core'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { faCheck, faTimes, faRotate, faTrash, faLock, faUnlock, faSignOutAlt, faUser, faUsers, faDollarSign,
  faHome, faClock, faPlus, faPencilAlt, faPenSquare, faBan, faExclamationTriangle, faDownload,
  faUniversity, faEnvelope, faPhone, faBriefcase, faGraduationCap, faGavel, 
  faBalanceScale, faBook, faBookmark, faTrophy, faSearch, faChevronLeft, faChevronRight,
  faFastBackward, faFastForward, faCircleNotch, faChevronDown, faChevronCircleDown,
  faCalendar, faPlay, faBirthdayCake, faMobile, faGlobe, faQuestion, faQuestionCircle, faCamera, faUpload, faFrown,
  faCog, faAddressBook, faInfoCircle, faList, faBolt, faFilter, faExclamation, faCalendarAlt,
  faStar, faSort, faLink, faArrowRight, faArrowLeft, faInfo, faComment, faBold, faUnderline, faItalic,
  faListOl, faListUl, faParagraph, faHeading, faFolder, faFile, faCopy, faExchangeAlt, faArrowCircleLeft, faArrowCircleRight,
  faDesktop, faHdd, faArchive, faThumbsUp, faThumbsDown, faBars, faFax, faEye, faPercent, faUndo,
  faCreditCard, faKey, faAngleDoubleRight, faAngleDoubleLeft, faAngleRight, faAngleLeft, faFlag, faCircle,
  faArrowsAltH, faArrowsAltV, faPlusCircle, faMap, faMapPin, faMapMarker, faMapMarkerAlt, faLeaf,
  faMoneyBill, faMoneyBillAlt, faUserPlus, faICursor, faSortAlphaUp, faSortAlphaDown, faBug, faClone,
  faArrowUp, faArrowDown, faStop, faSignInAlt, faThList, faListAlt, faFileAlt, faExternalLinkAlt, faAt, faMinus,
  faCheckCircle, faExclamationCircle, faCode, faHourglassHalf, faNewspaper, faBasketball, 
  faImage, faFaceLaugh, faFaceAngry, faSms,
} from '@fortawesome/free-solid-svg-icons'

import { 
  faThumbsUp as faRegularThumbsUp, 
  faThumbsDown as faRegularThumbsDown, 
  faFaceLaugh as faRegularFaceLaugh, 
  faFaceAngry as faRegularFaceAngry, 
  faCircle as faRegularCircle, 
} from '@fortawesome/free-regular-svg-icons'

library.add(fab, faCheck, faTimes, faRotate, faTrash, faLock, faUnlock, faSignOutAlt, faUser, faUsers, faDollarSign,
  faHome, faClock, faPlus, faPencilAlt, faPenSquare, faBan, faExclamationTriangle, faDownload, 
  faUniversity, faEnvelope, faPhone, faBriefcase, faGraduationCap, faGavel, 
  faBalanceScale, faBook, faBookmark, faTrophy, faSearch, faChevronLeft, faChevronRight,
  faFastBackward, faFastForward, faCircleNotch, faChevronDown, faChevronCircleDown,
  faCalendar, faPlay, faBirthdayCake, faMobile, faGlobe, faQuestion, faQuestionCircle, faCamera, faUpload, 
  faFrown, faCog, faAddressBook, faInfoCircle, faList, faBolt, faFilter, faExclamation, faCalendarAlt,
  faStar, faSort, faLink, faArrowRight, faArrowLeft, faInfo, faComment, faBold, faUnderline, faItalic,
  faListOl, faListUl, faParagraph, faHeading, faFolder, faFile, faCopy, faExchangeAlt, faArrowCircleLeft, faArrowCircleRight,
  faDesktop, faHdd, faArchive, faThumbsUp, faThumbsDown, faBars, faFax, faEye, faPercent, faUndo,
  faCreditCard, faKey, faAngleDoubleRight, faAngleDoubleLeft, faAngleRight, faAngleLeft, faFlag, faCircle,
  faArrowsAltH, faArrowsAltV, faPlusCircle, faMap, faMapPin, faMapMarker, faMapMarkerAlt, faLeaf,
  faMoneyBill, faMoneyBillAlt, faUserPlus, faICursor, faSortAlphaUp, faSortAlphaDown, faBug, faClone,
  faArrowUp, faArrowDown, faStop, faSignInAlt, faThList, faListAlt, faFileAlt, faExternalLinkAlt, faAt, faMinus, 
  faCheckCircle, faExclamationCircle, faCode, faHourglassHalf, faNewspaper, faBasketball,
  faImage, faFaceLaugh, faFaceAngry, faRegularFaceAngry, faRegularFaceLaugh, faRegularThumbsDown, faRegularThumbsUp, 
  faRegularCircle, faSms, 
)


Sentry.init({
  dsn: 'https://41886760ec4a181caa6d7467f432b098@o1333782.ingest.us.sentry.io/4508751174500352',
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration(),
  ],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ['localhost', /^https:\/\/redherrings\.com/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
})

const AppContainer = ({ store }) => {

  return (
    <Provider store={store}>
        <BrowserRouter>
          <DndProvider backend={HTML5Backend}>
            <CoreLayout />
          </DndProvider>
      </BrowserRouter>
    </Provider>
  )
}

export default AppContainer