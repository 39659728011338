import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Alert, Button } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './CaptureTeamDraw.scss'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { filter, find, map } from 'lodash'
import NCAATeam from '../../Cinderella/NCAATeam/NCAATeam'
import Confirm from '@severed-links/common.confirm'

const TeamDrawPlayerAssignments = ({ 
    selectPlayer = () => void(0),
    selectedPlayer = {},
    removePlayer = () => void(0),
    removeTeam = () => void(0),
}) => {

    const dispatch = useDispatch()
    const teamDraw = useSelector(state => state.captureAdmin.teamDraw)

    if (!teamDraw) return null
    const { players, teams, picks } = teamDraw
    return (
        <div>
            <Row>
            {players && players.map(p => 
                <Col md={3} sm={6} xs={12} key={p._id}>
                    <div className={s.teamDrawPlayerContainer + (selectedPlayer && selectedPlayer._id === p._id ? ' ' + s.selectedPlayer : '')} 
                        key={p._id}>
                        <div className={s.playerHeader + (selectedPlayer && selectedPlayer._id === p._id ? ' ' + s.selectedPlayer : '')} 
                            onClick={() => selectPlayer(p)}>
                            <div className={s.playerName}>{p.playerName}</div>
                            <div className={'hidden-print'} onClick={e => e.stopPropagation()}>
                                <Confirm variant={'danger'} 
                                    onConfirm={e => removePlayer(p._id)}
                                    title={`Delete Player`}
                                    size='sm' clear buttonClassName={s.deletePlayerButton}
                                    confirmText='delete player'
                                    body={`Are you sure you want to delete this player and the associated team selections?`}
                                    buttonIcon='trash' />
                            </div>
                        </div>
                        <div className={s.playerPickContainer}>
                            {filter(teams, t => map(filter(picks, pick => pick.capturePlayerId == p._id), i => i.teamId).indexOf(t.teamId) > -1).map(t => 
                                <div className={s.playerTeamContainer} key={t.teamId} onClick={e => e.stopPropagation()}>
                                    <div className={s.playerTeamItem}>
                                        <NCAATeam teamId={t.teamId} name={t.teamName} 
                                            showAvatar avatarUrl={t.avatarUrl}
                                            seed={t.seed} size='sm' />
                                    </div>
                                    <Button className={s.removeTeamContainer + ' hidden-print'}
                                        onClick={() => removeTeam(p, t)}>
                                        <FontAwesomeIcon name={'times'} />
                                    </Button>
                                </div>
                            )}
                        </div>
                    </div>
                </Col>
            )}
            {players && players.length === 0 ? 
                <Col xs={12}>
                    <Alert variant='info'>No players entered.  Use the add players box above to add players so you can assign teams.</Alert>
                </Col>
            : null}
            </Row>
        </div>
    )
}

export default TeamDrawPlayerAssignments