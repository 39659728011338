import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector} from 'react-redux'
import moment from 'moment-timezone'
import { FRIENDLY_DATE_FORMAT } from '@severed-links/common.redherrings-constants'
import * as s from './ConfigSummaryServerTime.scss'

const ConfigSummaryServerTime = () => {

    const dispatch = useDispatch()
    const [currentTime, setCurrentTime] = useState(moment())

    useEffect(() => {
        let timerId = setInterval(() => setCurrentTime(moment()), 1000)
        return () => clearInterval(timerId)
    }, [])

    return (
        <div className={s.container}>{currentTime.format(FRIENDLY_DATE_FORMAT)}</div>
    )
}

export default ConfigSummaryServerTime