import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { ListGroup } from 'react-bootstrap'
import * as s from './PickReport.scss'
import NflTeamListItem from '../Football/NflTeamListItem'
import { CircularProgressbarWithChildren } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

const PickReport = () => {

    const pickReport = useSelector(state => (state.eliminator.mainPage || {}).pickReport || [])

    return (
        <div className={s.container}>       
            <h4>Picks This Season</h4>
            <ListGroup className={s.pickReportList}>
            {pickReport && pickReport.map((r, index) =>
            <NflTeamListItem key={`eliminator-pick-report-${r.teamId}`} {...r}
                right={<div className={s.graphContainer}>
                    {[{ type: 'For', label: `${r.pickedRecord}`, value: `${r.pickedPct * 100.0}` },{ type: 'Against', label: `${r.againstRecord}`, value: `${r.againstPct * 100.0}` }].map(_bar =>
                    <div className={s.graph + (_bar.value ? ` ${s[_bar.type.toLowerCase()]}` : '')} key={`eliminator-pick-report-${r.teamId}-${_bar.type}`}>
                        <CircularProgressbarWithChildren value={_bar.value} text={_bar.label}>
                            <div className='CircularProgressbar-text'>
                                <div>{_bar.type}:</div>
                                <div>{_bar.label}</div>
                            </div>
                        </CircularProgressbarWithChildren>
                    </div>
                    )}
                </div>} />
            )}
            </ListGroup>
        </div>
    )
}

export default PickReport