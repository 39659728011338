import React, { useEffect } from 'react'
import { Link } from 'react-router-dom'
import { Badge } from 'react-bootstrap'
import * as s from './Preseason.scss'
import PlayerTeamListItem from '../PlayerTeamListItem'
import Player from '../../Player/Player'
import moment from 'moment-timezone'

const PreseasonDivisionRow = ({ divisionItem = {}, divisionCode = null, playerId = null }) => {

    if (!divisionItem) return null
    const { teamId, teamName, avatarUrl, 
        registrationDate, isPaid, playerAvatarUrl,
        playerFullName, firstName, lastName } = divisionItem
    const notPaid = (divisionItem.playerId === playerId) && !isPaid
    const isNew = moment(registrationDate).isValid() && moment().diff(moment(registrationDate), 'day') <= 1
    const hasLabel = notPaid || isNew
    return (
        <PlayerTeamListItem key={`preseason-${divisionCode}-${teamId}`}
            teamId={teamId} teamName={teamName} teamNameRaw={teamName} avatarUrl={avatarUrl}
            right={<div className={s.badge}>
            {notPaid ?
                <span onClick={e => e.stopPropagation()}>
                    <Link to={`/football/edit-team-profile/${teamId}`}>
                        <Badge bg='danger'>Pay!</Badge>
                    </Link>
                </span>
            : isNew ? 
                <Badge bg='info'>New!</Badge>
            : null}
            </div>}
            detail={<Player playerId={playerId} avatarUrl={playerAvatarUrl} firstName={firstName} lastName={lastName} />} 
        />
    )
}

export default PreseasonDivisionRow