import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, FormGroup, FormControl, InputGroup, Button } from 'react-bootstrap'
import * as s from './EliminatorAdmin.scss'
import Modal from '@severed-links/common.modal'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { setActiveSeason, getConfig } from '@severed-links/common.redherrings-reducers/eliminatorAdmin'
import { getEliminatorDetails } from '@severed-links/common.redherrings-reducers/eliminator'
import { find, first, some, isEqual } from 'lodash'

const EliminatorSetActiveSeason = () => {

    const dispatch = useDispatch()
    const seasonId = useSelector(state => state.eliminator.seasonId)
    const seasonName = useSelector(state => state.eliminator.seasonName)
    const seasons = useSelector(state => state.eliminator.seasons)
    const [activeSeason, setActiveSeasonId] = useState('')

    useEffect(() => { checkInitialSeasonId() }, [seasons.length, seasonId])

    const doSetActiveSeason = () => {
        dispatch(setActiveSeason(activeSeason))
        .then(resp => {
            dispatch(createNotification({ 
                type: resp.payload.messageType, 
                headline: 'Set Active Season', 
                message: resp.payload.message, 
                timeout: 6000 
            }))
            if (resp.payload.messageType === 'success') {
                const season = seasons.find(i => i.seasonId === activeSeason)
                dispatch(getEliminatorDetails())
                .then(() => getConfig(season.seasonId))
            }
        })
    }

    const handleChange = e => setActiveSeasonId(e.target.value)

    const checkInitialSeasonId = () => {
        if (seasons && seasons.some(i => i.seasonId === seasonId)) {
            setActiveSeasonId(seasonId)
        } else if (seasons && !activeSeason) {
            setActiveSeasonId(first(seasons).seasonId)
        }
    }


    return (
        <Row>
            <Col xs={12}>
                <FormGroup>
                    <InputGroup>
                        <FormControl as='select' placeholder='select' 
                            onChange={e => handleChange(e)}
                            value={activeSeason}>
                        {seasons && seasons.map(s => 
                            <option key={s.seasonId} value={s.seasonId}>{s.seasonName}</option>
                        )}
                        </FormControl>
                        <Button variant='primary' onClick={() => doSetActiveSeason()}><FontAwesomeIcon name='check' /> set</Button>
                    </InputGroup>
            </FormGroup>
            </Col>
        </Row>
    )
}

export default EliminatorSetActiveSeason