import React, { useEffect, useState } from 'react'
import { ListGroup, Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import { PASSWORD_VALIDATION_RULES, MINIMUM_PASSWORD_SCORE } from '@severed-links/common.redherrings-constants'
import * as s from './PasswordValidationRules.scss'

const PasswordValidationRules = ({ show = false, failedRules = [], score = 0 }) => {

    const _showAlert = show && (failedRules.length || score < MINIMUM_PASSWORD_SCORE)
    return (
        _showAlert ?
        <Alert variant='danger' className={s.container}>
            <p>Password failed validation for the following reasons:</p>
            <ListGroup className={s.ruleList}>
            {PASSWORD_VALIDATION_RULES.filter(r => failedRules.some(f => f === r.code)).map(i => 
                <ListGroup.Item className={s.ruleItem} key={i.code}>
                    <div className={s.icon}><FontAwesomeIcon name='chevron-right' /></div>
                    <div className={s.text}>{i.description}</div>
                </ListGroup.Item>
            )}
            {score && score < MINIMUM_PASSWORD_SCORE ?
            <ListGroup.Item className={s.ruleItem}>
                <div className={s.icon}><FontAwesomeIcon name='chevron-right' /></div>
                <div className={s.text}>Password is not strong enough.</div>
            </ListGroup.Item>
        : null}
            </ListGroup>
        </Alert> 
    : null
    )

}

export default PasswordValidationRules