import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'
import { connect } from 'react-redux'
import { Alert } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './Postseason.scss'
import PostseasonPastMatchup from './PostseasonPastMatchup'

const PostseasonPastResult = ({ week = 0, round = null, season = {} }) => {

    const navigate = useNavigate()
    
    if (!round) return null
    const matchups = round.matchups
    return (
        <div>
            <h4>
                <FontAwesomeIcon name='chevron-right' style={{ cursor: 'pointer', float: 'right' }} onClick={() => navigate(`/football/postseason/${season.seasonName}/playoff-week/${week}`)} /> 
                Postseason Week {week}
            </h4>
            {!matchups || matchups.length === 0 ? <Alert>No matchups located for this week.</Alert> : null}
            {matchups && matchups.map(i =>
                <PostseasonPastMatchup key={`matchup-${round.round}-${i.game}`} matchup={i} />
            )}
        </div>
    )
}

export default PostseasonPastResult