import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { Row, Col, Button, ListGroup, Image, FormGroup, FormControl, FormLabel } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './EditNflTeams.scss'
import { getNflTeams } from '@severed-links/common.redherrings-reducers/football'
import { saveNflTeam, getNflScheduler } from '@severed-links/common.redherrings-reducers/footballAdmin'
import Modal from '@severed-links/common.modal'
import NflTeam from '../NflTeam'
import NflTeamListItem from '../NflTeamListItem'
import { find, orderBy, forEach, uniq, filter, startsWith, endsWith, keysIn } from 'lodash'
import CloudinaryImage from '../../Cloudinary/CloudinaryImage'
import validator from 'validator'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'

const EditNflTeams = () => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const params = useParams()
    const seasons = useSelector(state => state.football.seasons || [])
    const nflTeams = useSelector(state => orderBy(state.football.nflTeams || [], ['nflTeam'], ['asc']))
    const conferences = [
        { name: 'AFC', divisions: ['East', 'Central', 'South', 'West'] },
        { name: 'NFC', divisions: ['East', 'Central', 'South', 'West'] },
    ]
    const newTeam = { city: '', conference: 'NFC', division: 'East', espnAbbreviation: '', mascot: '', nflTeam: '', nflComAbbreviation: '', teamId: null }
    const [editTeam, setEditTeam] = useState(null)

    useEffect(() => { dispatch(getNflTeams()) }, [])

    const addNewTeam = () => setEditTeam({ ...newTeam })

    const handlePress = _team => setEditTeam({ ..._team })

    const handleFieldChange = (fieldName, value) => setEditTeam({ ...editTeam, [fieldName]: value, ...(fieldName === 'city' ? { nflTeam: `${value} ${editTeam.mascot}` } : {}), ...(fieldName === 'mascot' ? { nflTeam: `${editTeam.city} ${value}` } : {}) })

    const save = () => {
        const _postData = { ...editTeam }
        dispatch(saveNflTeam(_postData))
        .then(action => {
            dispatch(createNotification(action.payload))
            dispatch(getNflTeams())
            setEditTeam(null)
        })        
    }

    const _showEditor = !!editTeam
    const _fields = _showEditor ? [
        { type: 'text', fieldName: 'city', label: 'City', value: editTeam.city, choices: [] },
        { type: 'text', fieldName: 'mascot', label: 'Mascot', value: editTeam.mascot, choices: [] },
        { type: 'text', fieldName: 'nflTeam', label: 'Team name (full)', value: editTeam.nflTeam, choices: [] },
        { type: 'select', fieldName: 'conference', label: 'Conference', value: editTeam.conference, choices: conferences.map(i => i.name) },
        { type: 'select', fieldName: 'division', label: 'Division', value: editTeam.division, choices: (conferences.find(i => i.name === editTeam.conference) || {}).divisions || [] },
        { type: 'text', fieldName: 'espnAbbreviation', label: 'ESPN.com Abbreviation', value: editTeam.espnAbbreviation, choices: [] },
        { type: 'text', fieldName: 'nflComAbbreviation', label: 'NFL.com Abbreviation', value: editTeam.nflComAbbreviation, choices: [] },
    ].filter(i => i.fieldName) : []
    return (
        <div className={s.container}>
            <div className={s.heading}>
                <Button className={s.backButton} variant='light' onClick={() => navigate(-1)}><FontAwesomeIcon name='chevron-left' /></Button>
                <h2 className={s.header}>Edit NFL Teams</h2>
                <Button className={s.addNewTeamButton} variant='light' onClick={() => addNewTeam()}><FontAwesomeIcon name='plus' /></Button>
            </div>
            <ListGroup className={s.nflTeamList}>
            {nflTeams && nflTeams.map(i =>
            <NflTeamListItem {...i} right={<div className={s.right}>{i.conference} {i.division}</div>}
                onClick={() => handlePress(i)} 
                key={`edit-nfl-team-item-${i.teamId}`} />
            )}
            </ListGroup>
            <Modal heading={`Edit NFL Team: ${(editTeam || {}).nflTeam}`}
                show={_showEditor} showFooter
                actionButtonText='save NFL team'
                actionButtonIcon='check'
                actionButtonOnClick={save}
                onClose={() => setEditTeam(null)}>
            {_showEditor ?
            <div>
            <Row>
            {_fields.map(i =>
            <Col xs={12} sm={12} md={['nflTeam'].indexOf(i.fieldName) > -1 ? 12 : 6} key={`edit-nfl-team-modal-field-${i.fieldName}`}>
                <FormGroup>
                    <FormLabel>{i.label}</FormLabel>
                    {i.type === 'text' ? 
                    <FormControl value={i.value} onChange={e => handleFieldChange(i.fieldName, e.target.value || '')} />
                    : i.type === 'select' ? 
                    <FormControl as='select' value={i.value} onChange={e => handleFieldChange(i.fieldName, e.target.value || '')}>
                    {i.choices && i.choices.map(x =>
                        <option key={`edit-nfl-team-${i.fieldName}-choice-${x}`} value={x}>{x}</option>
                    )}
                    </FormControl>
                    : null}
                </FormGroup>
            </Col>                    
            )}
            </Row>
            <Row>
            {[{ label: 'Team Avatar', prefix: '/football/nfl-team-avatars/' },{ label: 'Small Avatar', prefix: '/football/nfl-team-small-avatars/' }].map(i =>
            <Col xs={12} sm={6}>
                <div style={{ textAlign: 'center' }}><strong>{i.label}</strong></div>
                <div style={{ textAlign: 'center' }}><CloudinaryImage publicId={`${i.prefix}${(editTeam.mascot || '').toLowerCase()}`} /></div>
            </Col>
            )}
            </Row>
            </div>
            : null}
            </Modal>
        </div>
    )
}

export default EditNflTeams