import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { Row, Col } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './CaptureTeamDraw.scss'
import CaptureContestPicker from './CaptureContestPicker'
import { getTeamDraw, teamDrawAddPlayer, teamDrawDeletePlayer, teamDrawPickTeam, teamDrawDeleteSelection } from '@severed-links/common.redherrings-reducers/captureAdmin'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import TeamDrawAvailableNCAATeams from './TeamDrawAvailableNCAATeams'
import CaptureTeamDrawAddPlayer from './CaptureTeamDrawAddPlayer'
import TeamDrawPlayerAssignments from './TeamDrawPlayerAssignments'

const CaptureTeamDraw = () => {

    const dispatch = useDispatch()
    const params = useParams()
    const teamDraw = useSelector(state => state.captureAdmin.teamDraw)
    const [selectedPlayer, setSelectedPlayer] = useState(null)
    const contestId = params.contestId || null

    useEffect(() => { loadData() }, [contestId])

    const loadData = () => {
        dispatch(getTeamDraw(contestId))
        setSelectedPlayer(null)
    }

    const pickAvailableTeam = team => {
        if (selectedPlayer) {
            dispatch(teamDrawPickTeam({ contestId, playerId: selectedPlayer._id, teamId: team.teamId }))
            .then(() => dispatch(getTeamDraw(contestId)))
        } else {
            dispatch(createNotification({ type: 'warning', message: 'No player is selected -- click on a player\'s name to assign teams to that player.', headline: 'Assign Team to Player', timeout: 2000 }))
        }
    }

    const addPlayer = playerName => {
        dispatch(teamDrawAddPlayer(teamDraw.contest._id, playerName))
        .then(() => dispatch(getTeamDraw(contestId)))
    }
    
    const removePlayer = playerId => {
        dispatch(teamDrawDeletePlayer(playerId))
        .then(() => dispatch(getTeamDraw(contestId)))
    }

    const selectPlayer = player => {
        if (selectedPlayer && selectedPlayer._id === player._id)
            setSelectedPlayer(null)
        else {
            setSelectedPlayer(player)
        }
    }

    const removeTeam = (player, team) => {
        dispatch(teamDrawDeleteSelection({ contestId, playerId: player._id, teamId: team.teamId }))
        .then(() => dispatch(getTeamDraw(contestId)))
    }

    if (!teamDraw || !teamDraw.contest) return null
    const { contest, regions, teams, players, picks } = teamDraw
    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <h2>
                        <span style={{ float: 'right' }}><CaptureContestPicker /></span>
                        Draw For Round 1 Teams: {contest.contestName}
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col md={10} sm={9} xs={12}>
                    <Row style={{ marginBottom: '1.5rem' }}>
                        <Col xs={6}>
                            <h4>Player Teams</h4>
                        </Col>
                        <Col xs={6} className='hidden-print'>
                            <CaptureTeamDrawAddPlayer addPlayer={addPlayer} />
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <TeamDrawPlayerAssignments selectedPlayer={selectedPlayer}
                                removeTeam={removeTeam}
                                removePlayer={removePlayer}
                                selectPlayer={selectPlayer} />
                        </Col>
                    </Row>
                </Col>
                <Col md={2} sm={3} xs={12} className='hidden-print'>
                    <Row>
                        <Col xs={12}>
                            <h4>Available Teams</h4>
                        </Col>
                    </Row>
                    <Row>
                        <Col xs={12}>
                            <TeamDrawAvailableNCAATeams pickTeam={pickAvailableTeam} />
                        </Col>
                    </Row>
                </Col>
            </Row>
        </div>
    )
}

export default CaptureTeamDraw
