import React, { useEffect, useState, forwardRef, useImperativeHandle } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Row, Col, FormGroup, FormLabel, FormControl, Alert } from 'react-bootstrap'
import { trim } from 'lodash'
import { isEmpty, isEmail } from 'validator'
import * as s from './SignUp.scss'
import validator from 'validator'
import { setUserInfo, checkEmail } from '@severed-links/common.redherrings-reducers/siteRegistration'

const NameAndEmailStep4 = forwardRef(({ jumpToStep }, _ref) => {

    const dispatch = useDispatch()
    const navigate = useNavigate()
    const siteRegistration = useSelector(state => state.siteRegistration)
    const [firstName, setFirstname] = useState('')
    const [lastName, setLastname] = useState('')
    const [email, setEmail] = useState('')
    const _isValid = siteRegistration.emailIsValid &&
        siteRegistration.emailIsAvailable && 
        !isEmpty(siteRegistration.firstName || '') &&
        !isEmpty(siteRegistration.lastName || '')

    useEffect(() => {
        if (siteRegistration.nextDisabled !== !_isValid) {
            dispatch(setUserInfo({ nextDisabled: !_isValid }))
        }
    }, [siteRegistration.nextDisabled, _isValid])

    useEffect(() => {
        setFirstname(siteRegistration.firstName || '')
        setLastname(siteRegistration.lastName || '')
        setEmail(siteRegistration.email || '')
    }, [])

    const handleChange = e => {
        const fieldName = e.target.name
        const value = trim(e.target.value)
        var data = { [fieldName]: value, ...(fieldName === 'email' ? { emailIsValid: isEmail(value || '') } : {}) }
        if (fieldName === 'firstName') setFirstname(value)
        else if (fieldName === 'lastName') setLastname(value)
        else if (fieldName === 'email') setEmail(value)
        dispatch(setUserInfo(data))
        if (data.email && data.emailIsValid) {
            dispatch(checkEmail(data.email))
        }
    }

    useImperativeHandle(_ref, () => ({
        isValidated: () => _isValid
    }))

    if (!siteRegistration) return null
    return (
        <div className={s.step4 + ' ' + s.step}>
            <div className={s.detailsContainer}>
                <Row>
                    <Col xs={6}>
                        <FormGroup>
                            <FormLabel>First name</FormLabel>
                            <FormControl size='lg' autoFocus autoComplete='off' type='text' 
                                name='firstName' value={siteRegistration.firstName} 
                                required isValid={!isEmpty(siteRegistration.firstName)}
                                isInvalid={isEmpty(siteRegistration.firstName)}
                                onChange={e => handleChange(e)} />
                            <FormControl.Feedback />
                        </FormGroup>
                    </Col>
                    <Col xs={6}>
                        <FormGroup>
                            <FormLabel>Last name</FormLabel>
                            <FormControl size='lg' autoComplete='off' type='text' 
                                name='lastName' value={siteRegistration.lastName} 
                                required isValid={!isEmpty(siteRegistration.lastName)}
                                isInvalid={isEmpty(siteRegistration.lastName)}
                                onChange={e => handleChange(e)} />
                            <FormControl.Feedback />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <FormGroup>
                            <FormLabel>Email address</FormLabel>
                            <FormControl size='lg' autoComplete='off' type='email' 
                                name='email' value={email} 
                                required isValid={siteRegistration.emailIsValid && siteRegistration.emailIsAvailable}
                                isInvalid={!siteRegistration.emailIsValid || !siteRegistration.emailIsAvailable}
                                onChange={e => handleChange(e)} />
                            <FormControl.Feedback />
                        </FormGroup>
                    </Col>
                </Row>
                <Row>
                    <Col xs={12}>
                        <div className={s.alertContainer}>
                            {_isValid ?
                            <Alert variant='success'>Your information is valid.</Alert>
                            : null}
                            {siteRegistration.emailIsValid && !siteRegistration.emailIsAvailable ?
                            <Alert variant='warning'>
                                This email is already registered to a redherrings account.  
                                {' '}
                                Click <a style={{ cursor: 'pointer' }} onClick={() => navigate('/forgotpassword')}>here</a> to recover your password or 
                                {' '}
                                click <a style={{ cursor: 'pointer' }} onClick={() => navigate('/login')}>here</a> to log in.</Alert>
                            : null}
                        </div>
                    </Col>
                </Row>                
            </div>
        </div>
    )
})

export default NameAndEmailStep4
