import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, FormGroup, FormControl, FormLabel, InputGroup, Button, Popover, OverlayTrigger } from 'react-bootstrap'
import * as s from './EliminatorAdmin.scss'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import HtmlEditor from '../../Editors/HtmlEditor'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import { getEliminatorBroadcastMessageData, sendEliminatorBroadcastMessage } from '@severed-links/common.redherrings-reducers/eliminatorAdmin'
import { trim, endsWith } from 'lodash'

const EliminatorBroadcastMessage = () => {

    const dispatch = useDispatch()
    const seasonId = useSelector(state => state.eliminator.seasonId)
    const seasonName = useSelector(state => state.eliminator.seasonName)
    const seasons = useSelector(state => state.eliminator.seasons)
    const broadcastMessage = useSelector(state => state.eliminatorAdmin.broadcastMessage)
    const editorContent = useSelector(state => state.editors.eliminatorAdminEmailMessageBody)
    const [_seasonName, setSeasonName] = useState('')
    const [messageType, setMessageType] = useState('-2')
    const [subject, setSubject] = useState('')
    const [columns, setColumns] = useState([])
    const [messageTypes, setMessageTypes] = useState([])
    const [body, setBody] = useState(null)
    const [isSending, setSending] = useState(false)

    useEffect(() => { getData() }, [seasonName])

    const getData = () => {
        dispatch(getEliminatorBroadcastMessageData(seasonId))
        .then(action => {
            setMessageType(action.payload.messageType || '-2')
            setSubject(action.payload.subject || '')
            setBody(action.payload.body || '')
            setColumns(action.payload.columns || [])
            setMessageTypes(action.payload.messageTypes || [])
        })       
    }

    const doBroadcastMessage = () => {
        var emailMessage = { messageType: parseInt(messageType), seasonId, subject, body: editorContent }
        setSending(true)
        dispatch(sendEliminatorBroadcastMessage(emailMessage))
        .then(action => {
            dispatch(createNotification({
                type: action.payload.messageType, 
                headline: 'Broadcast Message', 
                message: action.payload.message, 
                timeout: 6000 
            }))
            setSending(false)
        })
    }

    const insertFieldName = _column => {
        var _editorContent = editorContent
        _editorContent = trim(_editorContent)
        if (endsWith(_editorContent, '</p>')) {
            _editorContent = _editorContent.replace(/\<\/p\>$/g, '')
        }
        _editorContent += `${_column.columnNameInsert}</p>`
        setBody(_editorContent)
    }

    const popoverClick = (
        <Popover id='message-fields-popover' title='Insert a field name' 
            className={s.fieldNamePopover}>
            <div className={s.fieldNamePopoverButtonContainer}>
            {columns && columns.map((c, index) =>
            <Button key={`field-name-popover-${c.columnName}`} size='sm' title={c.columnNameDisplay} 
                tabIndex={-1} variant='light' 
                className={s.fieldNamePopoverButton}
                onClick={() => insertFieldName(c)}>
                {c.columnNameDisplay}
            </Button>
            )}
            </div>
        </Popover>
    )

    return (
        messageTypes ? 
            <Row>
            <Col xs={12}>

                <FormGroup>
                    <FormLabel>Recipients</FormLabel>
                    <FormControl as='select' placeholder='Message type...' tabIndex={-1} 
                        onChange={e => setMessageType(e.target.value)} name='messageType'
                        value={messageType}>
                    {messageTypes.map(m => 
                        <option key={m.value} value={m.value}>{m.text}</option>
                    )}
                    </FormControl>
                </FormGroup>

                <FormGroup>
                    <FormLabel>Subject</FormLabel>
                    <FormControl placeholder='Subject' autoCapitalize='words' name='subject'
                        onChange={e => setSubject(e.target.value)} autoFocus
                        value={subject} />
                </FormGroup>

                <FormGroup>
                    <FormLabel style={{ width: '100%' }}>
                        <OverlayTrigger trigger='click' placement='top' overlay={popoverClick}>
                            <Button style={{ float: 'right' }} size='sm' tabIndex={-1}><FontAwesomeIcon name='i-cursor' /><span className='d-none d-sm-inline'>{' '}insert field</span></Button>
                        </OverlayTrigger>
                        Message body
                    </FormLabel>
                    <div>
                        <HtmlEditor content={body} 
                            name={'eliminatorAdminEmailMessageBody'}
                            showToolbar
                            placeholder={'Email message body...'} />
                    </div>
                </FormGroup>

                <FormGroup>
                    <Button variant='primary' 
                        disabled={isSending}
                        onClick={() => doBroadcastMessage()}>
                        <FontAwesomeIcon spin={isSending} name={!isSending ? 'envelope' : 'circle-notch'} /> {!isSending ? 'send' : 'sending...'}
                    </Button>
                </FormGroup>

            </Col>
        </Row>
        : null
    )
}

export default EliminatorBroadcastMessage