import React, { useEffect } from 'react'
import { FormGroup, InputGroup, Button, FormControl, Image } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import * as s from './ScoreboardTeamFilter.scss'

const ScoreboardFilterTeamItem = ({ teamName, avatarUrl, teamId, onClear }) => (
    <div className={s.scoreboardFilterTeamItem}>
        {avatarUrl ? <div className={s.avatarUrl}><Image src={avatarUrl} /></div> : null}
        {teamName ? <div className={s.teamName}>{teamName}</div> : null}
        {avatarUrl || teamName ? <div className={s.clearTeamItem} onClick={() => onClear()}><FontAwesomeIcon name='times' /></div> : null}
    </div>
)

const ScoreboardTeamFilter = ({ filterTeams = [], ncaaTeams = [], removeTeamFromFilter = () => void(0), clearTeamFilter = () => void(0) }) => {

    return (
        <div className={s.container}>
            {filterTeams && filterTeams.map(t => 
                <ScoreboardFilterTeamItem {...t}
                    key={`scoreboard-filter-team-${t.teamId}`} 
                    onClear={() => removeTeamFromFilter(t)} />
            )}

            {filterTeams.length > 0 ? <FontAwesomeIcon name='times' className={s.clear} 
                onClick={() => clearTeamFilter()} /> : null}

            {filterTeams.length == 0 ? <span className={s.placeholder}>Click on teams to filter...</span> : null}
        </div>
    )
}

export default ScoreboardTeamFilter
