import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { Row, Col, Alert, Button, Badge, Form, FormGroup, ListGroup, FormLabel, FormControl, FormControlFeedback } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import Player from '../Player/Player'
import EditTicket from './EditTicket'
import TicketListItem from './TicketListItem'
import moment from 'moment-timezone'
import { startsWith, startCase, filter, orderBy } from 'lodash'
import { getMyTickets, getOthersTickets, getHelperRoles, toggleTicketModal, setActiveTicket, 
    updateActiveTicket, saveTicket } from '@severed-links/common.redherrings-reducers/help'
import { createNotification } from '@severed-links/common.redherrings-reducers/notifications'
import * as s from './HelpCenter.scss'
import Modal from '@severed-links/common.modal'

const HelpCenter = ({ showAllTickets = false, }) => {

    const dispatch = useDispatch()
    const params = useParams()
    const { tickets, otherTickets, activeTicket, showActiveTicket, helperRoles } = useSelector(state => state.help)
    const { _id: playerId } = useSelector(state => state.account)
    const appName = showAllTickets ? 'RedHerrings' : (params.appName || '').toLowerCase()

    useEffect(() => { loadTickets() }, [])

    const loadTickets = () => {
        dispatch(getHelperRoles())
        dispatch(getMyTickets())
        dispatch(getOthersTickets())
    }

    const closeModal = () => {
        dispatch(toggleTicketModal(false))
        loadTickets()
    }

    const newTicketItem = { 
        _id: null, 
        app: appName,
        ticketType: '',
        description: '', 
        createDate: null,
        updateDate: null,
        isCompletedTicket: false,
        isDeletedTicket: false,
        player: { _id: null },
        sortOrder: 1,
        comments: [],
        commentCount: 0, 
        commentUnreadCount: 0 
    }

    const newTicket = () => {
        dispatch(setActiveTicket({ ...newTicketItem, player: { _id: playerId } }))
        dispatch(toggleTicketModal(true))
    }

    return (
        <div className={s.container}>
            <Row>
                <Col xs={12}>
                    <h2>
                        <Button style={{ float: 'right' }} variant='primary' onClick={() => newTicket()}><FontAwesomeIcon name='plus-circle' /> add new issue</Button>
                        RedHerrings Help Center
                    </h2>
                </Col>
            </Row>
            <Row>
                <Col xs={12}>
                    {!tickets || tickets.length === 0 ?
                        <Alert>You have no open issues.</Alert>
                    :
                        <div>
                            <h4>Your Open Issues</h4>
                            <ListGroup className={s.ticketList}>
                            {tickets && orderBy(tickets, ['sortOrder','updateDate'], ['asc','desc']).map(t => <TicketListItem key={t._id} t={t} />)}
                            </ListGroup>
                        </div>
                    }
                </Col>
            </Row>
            {helperRoles?.length ?
            <Row>
                <Col xs={12}>
                    <div>
                        <h4>Open Issues From Others</h4>
                        <ListGroup className={s.ticketList}>
                        {otherTickets && orderBy(otherTickets, ['sortOrder','updateDate'], ['asc','desc']).map(t => <TicketListItem key={t._id} t={t} />)}
                        </ListGroup>
                        {!otherTickets || otherTickets.length === 0 ?
                            <Alert>You have no open issues to help others with.</Alert>
                        : null}

                    </div>
                </Col>
            </Row> : null}
            

            <Modal heading={(activeTicket && activeTicket.app === 0 ? activeTicket.app + ' ' : 'Help ') + (activeTicket && !activeTicket._id ? 'New ' : '' ) + 'Ticket'}
                show={showActiveTicket}
                onClose={() => closeModal()}
                showFooter={false}>
                <EditTicket />
            </Modal>

        </div>
    )
}

export default HelpCenter