import React, { useEffect, useState } from 'react'
import { Table, ListGroup } from 'react-bootstrap'
import FontAwesomeIcon from '@severed-links/common.font-awesome-icon'
import NCAATeam from '../Cinderella/NCAATeam/NCAATeam'
import NCAATeamIcon from '../Cinderella/NCAATeam/NCAATeamIcon'
import Line from '../Football/Line'
import CaptureStatusLabel from './CaptureStatusLabel'
import { filter } from 'lodash'
import FormCheck from 'react-bootstrap/FormCheck'
import * as s from './CaptureLog.scss'

const CaptureLog = ({ captureLog = [] }) => {

    const [onlyCaptures, setOnlyCaptures] = useState(false)

    const handleOnlyCapturesSwitch = (el, checkedState) => setOnlyCaptures(checkedState)
    const _filteredItems = onlyCaptures ? filter(captureLog, c => c.captureStatus === 'Capture') : captureLog
    return (
    <div className={s.container}>
        <ListGroup className={s.list}>
            <ListGroup.Item className={s.item + ' ' + s.miniHeader}>
                <div>Only show captures?</div>
                <div>
                    <FormCheck type='switch' onChange={e => handleOnlyCapturesSwitch(e, e.target.checked)}
                        name='onlyCaptures' checked={onlyCaptures} />
                </div>
            </ListGroup.Item>
            {_filteredItems && _filteredItems.map(i =>
            <ListGroup.Item className={s.item} key={`game-log-${i._id}`}>
                <div className={s.itemHeader}>
                    <div className={s.round}>Round {i.round}</div>
                    <div className={s.lineContainer}>
                    {i.favoriteTeam && i.favoriteTeam.avatarUrl && !isNaN(i.line) ? 
                        <>
                        <NCAATeamIcon teamId={i.favoriteTeam.teamId} seed={i.favoriteTeam.seed} 
                            name={i.favoriteTeam.teamName} size='sm'
                            showAvatar avatarUrl={i.favoriteTeam.avatarUrl}
                            style={{ backgroundColor: 'transparent', height: '16px' }} />
                        <span className={s.separator}>by</span>
                        <Line line={i.line} showSign={false} />
                        </>
                    : null}
                    </div>
                    <div className={s.captureStatus}><CaptureStatusLabel status={i.captureStatus} /></div>
                </div>
                <div className={s.teamsContainer}>
                    <div className={s.teamContainer}>
                        <NCAATeam teamId={i.winningTeam.teamId} seed={i.winningTeam.seed} 
                            showAvatar avatarUrl={i.winningTeam.avatarUrl}
                            name={i.winningTeam.teamName} gameScore={i.winningTeamScore}
                            showGameScore={true} />
                    </div>
                    <div className={s.teamContainer}>
                        <NCAATeam teamId={i.losingTeam.teamId} seed={i.losingTeam.seed} 
                            name={i.losingTeam.teamName} gameScore={i.losingTeamScore}
                            showAvatar avatarUrl={i.losingTeam.avatarUrl}
                            showGameScore={true} />
                    </div>
                </div>
                <div className={s.itemFooter}>
                    <div className={s.playerContainer + ' ' + s.winner}>
                        <FontAwesomeIcon name='thumbs-up' className={s.icon} />
                        <span>{i.capturingPlayerName}</span>
                    </div>
                    <div className={s.playerContainer + ' ' + s.loser + ' ' + s.end}>
                        <span>{i.losingPlayerName}</span>
                        <FontAwesomeIcon name='thumbs-down' className={s.icon} />
                    </div>
                </div>
            </ListGroup.Item>
            )}
            {!_filteredItems || !_filteredItems.length ?
            <ListGroup.Item className={s.item}>No entries.</ListGroup.Item>
            : null}
        </ListGroup>
    </div>
    )
}

export default CaptureLog